export default {
  connections: {
    'minimum-term-disclaimer': `Rate limit cannot be decreased below the selected speed at time of terming.`,
    'transit-configuration': `Connection Details for ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}`,
    'enable-bgp': `Enable BGP`,
    'ix-details': `Connection Details for ${process.env.VUE_APP_EN_PRODUCTNAME_IX} Service`,
    'request-higher-speed': `Request higher speed`,
    'assigned-ipv4': `Assigned IPv4 Address`,
    'assigned-ipv6': `Assigned IPv6 Address`,
    'gateway-ipv4': `Gateway IPv4 Address`,
    'gateway-ipv6': `Gateway IPv6 Address`,
    'assigned-bgp-ipv4': `Assigned BGP IPv4 Address`,
    'assigned-bgp-ipv6': `Assigned BGP IPv6 Address`,
    'megaport-bgp-ipv4': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} BGP IPv4 Address`,
    'megaport-bgp-ipv6': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} BGP IPv6 Address`,
    'megaport-asn': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} ASN`,
    'route-set': `Advertised Route Set`,
    'allowed-prefixes': `Allowed Prefixes`,
  },
  general: {
    'open-new-tab': ' - Opens in a new tab',
    'g2-review': `We would appreciate a few moments of your time to review ${process.env.VUE_APP_EN_COMPANYINFO_NAME} through the G2 review service. We will share your email address with G2. See G2’s <a href="https://www.g2.com/static/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.g2.com/static/terms" target="_blank">Terms of Use</a>.`,
    'g2-review-title': `Review ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    review: 'Review',
    'dont-review': `Don't Review`,
    'access-denied': 'Access Denied',
    'creation-date': 'Creation Date',
    about: `About`,
    contact: `Contact`,
    filter: `Filter`,
    'name-ascending': `Name (A-Z)`,
    'name-descending': `Name (Z-A)`,
    'creation-date-ascending': `Creation Date (Oldest first)`,
    'creation-date-descending': `Creation Date (Newest first)`,
    'location-ascending': `Location (A-Z)`,
    'location-descending': `Location (Z-A)`,
  },
  aws: {
    'connection-details': `Connection Details for AWS Service`,
  },
  menu: {
    'quoting-tool': 'Quoting Tool',
    'in-app-tools': 'In-App Tools',
    'additional-tools': 'Additional Tools',
    'bug-report': 'Bug Report',
  },
  'billing-markets': {
    'change-registration-number-info': 'Contact your Account Manager or Customer Success Manager to change your Company Registration Number associated with the purchasing entity.',
    'change-legal-name-info': 'Contact your Account Manager or Customer Success Manager to change your Company Legal Name associated with the purchasing entity.',
    'view-megaport-contacts': 'View Megaport Contact Details',
  },
  tooltips: {
    'transit-bgp-config': `Enable this option to enter BGP routing information for the B-End of your ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} connection.`,
    'transit-prefixes': 'List of publicly assigned IPv4 or IPv6 networks to be advertised.',
    'assigned-ipv6': `Any IPv6 address in the assigned range may be used, except the ${process.env.VUE_APP_EN_COMPANYINFO_NAME} gateway address.`,
    bfd: `Bidirectional Forwarding Detection allows for rapid fault detection on this BGP connection. When connectivity is disrupted, the BGP session is torn down without waiting for the BGP hold timer to expire. Use of BFD can improve failover time when multiple routes are available. Note: Default settings will be used for all BGP connections on this VXC, where BFD is enabled.`,
    'bfd-group': `Note: Default settings will be used for all BGP connection on this interface where BFD is enabled`,
    'bfd-tooltip': `The default BFD Settings are not editable, the default values configured when BFD is enabled are Transmit Interval: 300ms, Receive Interval: 300ms, Multiplier: 3`,
  },
  alibaba: {
    'connection-details': `Connection Details for Alibaba Service`,
  },
  amsix: {
    'connection-details': `Connection Details for AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX} Service`,
  },
  salesforce: {
    configuration: `Connection Details for Salesforce Service`,
  },
  outscale: {
    'connection-details': `Connection Details for Outscale Service`,
  },
  ibm: {
    'connection-details': `Connection Details for IBM Cloud Service`,
  },
  'service-status': {
    'start-timezone': 'Start ({thing})',
    'end-timezone': 'End ({thing})',
  },
  'cancel-reason': {
    'proof-of-concept': 'Proof of Concept / Test',
    other: `Reason not Listed`,
    'moved-dc': `Changing Data Centers`,
  },
  marketplace: {
    'destinations-count': `1 Destination | {count} Destinations`,
    'destination-count': `Destination Count`,
    'pluralize-destinations': `Destination | Destinations`,
    'create-vxc': `Create a new ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} to this destination`,
    'no-services-title': `No available services`,
    'no-services-message': `You have no deployed services available in this region. Typically this could be because of a network region mismatch or because services that would otherwise be eligible have untagged connections. Click OK to return to your services and create one.`,
    'no-deployed-services': `You have no deployed services available in this region. Click to return to your services and create one.`,
    'select-services-destination': `Select services that this destination can provide`,
    'private-destinations': `Private services will not be visible to others viewing your profile. To enable them, change the ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} visibility option for the service.`,
    'duplicate-name-warning': `This can cause confusion for people wanting to connect to your service. Are you sure you want to save?`,
    'select-source': 'Select Source',
    facebook: 'Facebook',
    x: 'X',
    linkedin: 'LinkedIn',
    'back-to-marketplace': `Back to ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'your-profile': 'Your Profile',
    connect: 'Connect',
    'no-services': `No services found`,
    'no-services-filter': `No services found with the current filter`,
    grid: `Grid`,
    list: `List`,
    'create-profile': `Create Profile`,
    'profile-settings': `Profile Settings`,
    'profile-visibility': `Profile Visibility`,
    'profile-visible': `Profile is visible on ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'profile-hidden': `Profile not visible on ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'company-logo': `Company Logo`,
    preview: `Preview`,
    'edit-about': 'Edit About',
    'edit-contact': 'Edit Contact',
    'edit-service': 'Edit Service',
    'logo-notice': 'New logo may take a few minutes to appear.',
    'hidden-from-marketplace': `Hidden from ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'visible-on-marketplace': `Visible on ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'set-up-profile': 'Set Up Profile',
    'services-warning': `Removing the {providerTypes} provider type(s) will remove all associated service types in the following services:`,
    'thing-details': '{thing} Details',
    'public-service-warning': 'By selecting this option, you understand and agree that information about the {productType} (such as its {limitType} and location) will be publicly accessible. You can change the setting for this {productType} back to Private at any time.',
    size: 'size',
    speed: 'speed',
    'private-services-warning': `Your profile has no visible services. To go public, enable a service by changing its ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT} visibility.`,
    'publish-disclaimer': `You can publish this service on the ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}. To configure, go to your ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} profile.`,
    'publish-disclaimer-link': `You can publish this service on the ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}. To configure, go to your {link}`,
  },
  validations: {
    'valid-marketplace-name': `Please enter a valid ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} display name`,
    'description-too-long': `Too many words in the service description`,
  },
  services: {
    'resource-tags': 'Resource Tags',
    'add-tags': 'Add Tags',
    'manage-tags': 'Manage Tags',
    'add-new-tag': 'Add New Tag',
    'no-resource-tags': 'No Resource Tags',
    key: 'Key',
    value: 'Value',
    'resource-tags-info': 'A tag is custom metadata that you apply to a Megaport resource. Tags can help you identify and manage your resources.',
    'no-tag-desc': 'No tags are currently available. To get started, click the "Add New Tag" button.',
    'tags-max-limit': `You can add up to 1 tag | You can add up to {count} tags`,
    'tag-exceeded-limit': 'You have exceeded the allowable limit for tags!',
    'search-tag-placeholder': 'Type to search resource tags',
    'empty-resource-tag-table': 'No tags are currently available',
    'invalid-tag-key': 'Key must be lowercase and contain only - . _ : /',
    'invalid-tag-value': 'Value can contain only - . _ : ; / @ +',
    'tags-added-count': `1 Tag added | {count} Tags added`,
    'duplicate-key-error': 'Resource tag key must be unique',
  },
}
