<template>
  <el-header height="61px"
    class="header">
    <!-- Megaport Logo -->
    <router-link to="/dashboard">
      <header-logo />
    </router-link>

    <!-- Navigation -->
    <div class="full-height d-inline-block">
      <!-- Hamburger Button -->
      <el-button v-if="showHamburger"
        id="hamburgerTriggerButton"
        class="hamburger-button"
        @click="showHamburgerMenu = !showHamburgerMenu">
        <i class="fas fa-bars fs-1-6rem"
          aria-hidden="true" />
      </el-button>
      <!-- Navigation Menu -->
      <el-menu v-if="showHamburgerMenu || !showHamburger"
        ref="menu"
        :mode="showHamburger ? 'vertical' : 'horizontal'"
        :default-active="activeIndex"
        class="mainMenu"
        :style="showHamburger ? '' : 'background-color: transparent;'"
        :class="showHamburger ? 'hamburger' : ''"
        @select="handleNavSelect">
        <with-root :show="showHamburger">
          <el-submenu id="hamburger"
            index="hamburger">
            <template v-if="showHamburger"
              #title>
              <i class="fas fa-bars"
                aria-hidden="true" />
            </template>
            <!-- Dashboard -->
            <el-menu-item v-if="showDashboardMenu"
              index="/dashboard"
              data-nav="dashboard">
              {{ $t('menu.dashboard') }}
            </el-menu-item>
            <!-- Partner Admin -->
            <el-menu-item v-if="isPartnerAccount"
              index="/partner"
              data-nav="partner">
              {{ $t('menu.partner-admin') }}
            </el-menu-item>
            <!-- Services -->
            <el-menu-item v-if="allowsServices"
              id="intro-step-0"
              index="/services"
              data-nav="services">
              {{ $t('general.services') }}
            </el-menu-item>
            <!-- Company Submenu -->
            <el-submenu v-if="showCompanySubmenu"
              index="company"
              data-nav="company">
              <template #title>
                {{ $t('menu.company') }}
              </template>
              <!-- Company Profile -->
              <el-menu-item v-if="showCompanyProfileMenu"
                index="/company/profile"
                data-nav="company-profile">
                {{ $t('menu.company-profile') }}
              </el-menu-item>
              <!-- Megaport Marketplace Profile -->
              <el-menu-item v-if="hasCompany && showMpMarketplaceMenu"
                index="/marketplace/profile"
                data-nav="marketplace-profile">
                {{ $t('menu.marketplace-profile') }}
              </el-menu-item>
              <!-- Billing Markets -->
              <el-menu-item v-if="hasCompany && showBillingMarketsMenu"
                index="/company/markets"
                data-nav="company-markets">
                {{ $t('menu.billing-markets') }}
              </el-menu-item>
              <!-- Manage Users -->
              <el-menu-item v-if="hasCompany && showManageUsersMenu"
                index="/company/users"
                data-nav="company-users">
                {{ $t('menu.manage-users') }}
              </el-menu-item>
              <!-- Security Settings -->
              <el-menu-item v-if="hasCompany && showSecuritySettingsMenu"
                index="/company/security-settings"
                data-nav="company-security-settings">
                {{ $t('menu.company-security-settings') }}
              </el-menu-item>
              <!-- Account Contacts -->
              <el-menu-item v-if="hasCompany && showCompanySuppMenu"
                index="/company/support"
                data-nav="company-support">
                {{ $t('menu.account-contacts') }}
              </el-menu-item>
              <!-- Company Entitlements -->
              <el-menu-item v-if="hasCompany && showEntitlementsMenu"
                index="/company/entitlements"
                data-nav="company-entitlements">
                {{ $t('menu.entitlements') }}
              </el-menu-item>
            </el-submenu>
            <!-- Tools Submenu-->
            <el-submenu index="tools"
              popper-class="tools-popover"
              data-nav="tools">
              <template #title>
                {{ $t('menu.tools') }}
              </template>
              <!-- In-App Tools -->
              <el-menu-item-group :title="$t('menu.in-app-tools')">
                <!-- IX Telemetry -->
                <el-menu-item v-if="allowsIXItems"
                  index="/tools/ix-flows"
                  data-nav="ix-flows">
                  {{ $t('menu.ix-telemetry') }}
                </el-menu-item>
                <!-- MCR Looking Glass -->
                <el-menu-item v-if="allowsMCRLookingGlass"
                  index="/tools/mcr-looking-glass"
                  data-nav="mcr-looking-glass">
                  {{ $t('menu.mcr-looking-glass') }}
                </el-menu-item>
                <!-- API Key Generator -->
                <el-menu-item v-if="allowsApiKeys"
                  index="/tools/api-keys"
                  data-nav="api-keys">
                  {{ $t('menu.api-key-generator') }}
                </el-menu-item>
              </el-menu-item-group>
              <!-- Additional Tools -->
              <el-menu-item-group :title="$t('menu.additional-tools')">
                <!-- MegaIX Looking Glass -->
                <el-menu-item v-if="allowsIXItems"
                  index="https://lg.megaport.com/"
                  data-nav="looking-glass">
                  {{ $t('menu.ix-looking-glass') }}
                  <external-link-indicator />
                </el-menu-item>
                <!-- Quoting Tool -->
                <el-menu-item v-if="!isDistributorAccount && !isManagedAccount"
                  index="quoting-tool"
                  data-nav="quoting-tool">
                  {{ $t('menu.quoting-tool') }}
                  <external-link-indicator />
                </el-menu-item>
                <!-- Documentation -->
                <el-menu-item v-if="!disabledFeatures.knowledgeBase"
                  index="https://docs.megaport.com/"
                  data-nav="documentation">
                  {{ $t('general.documentation') }}
                  <external-link-indicator />
                </el-menu-item>
                <!-- API Documentation -->
                <el-menu-item index="https://dev.megaport.com/"
                  data-nav="api-documentation">
                  {{ $t('menu.api-documentation') }}
                  <external-link-indicator />
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- Megaport Marketplace -->
            <el-menu-item v-if="allowsServices"
              index="/marketplace"
              data-nav="marketplace">
              {{ $t('productNames.marketplace') }}
            </el-menu-item>
            <!-- Service Status -->
            <el-menu-item v-if="allowsServices"
              index="/service-status"
              data-nav="service-status">
              <template #default>
                <mu-mega-icon v-if="!showHamburger"
                  icon="outages"
                  width="22"
                  aria-hidden="true" />
                <span :class="{ 'sr-only': !showHamburger }">
                  {{ $t('service-status.maintenance-outages') }}
                </span>
                <new-badge class="ml-1" />
              </template>
            </el-menu-item>
            <!-- Event Log -->
            <el-menu-item v-if="allEvents.length"
              index="/event-log"
              data-nav="event-log">
              <lazy-popover :placement="showHamburger ? 'left' : 'bottom'"
                :title="$t('menu.event-log')"
                width="400"
                trigger="hover">
                <div>
                  <el-alert v-for="item in allEvents.slice(0,5)"
                    :key="item.hid"
                    :title="item.title"
                    :type="item.type"
                    :description="item.message"
                    :closable="false"
                    class="mb-5-px"
                    show-icon />
                  <template v-if="allEvents.length > 5">
                    <hr>
                    <div class="text-align-center vertical-align-middle">
                      <p class="d-inline-block mr-5-px">
                        {{ $t('menu.more-events') }}
                      </p>
                      <el-button class="d-inline-block"
                        @click="$router.push('/event-log', () => {})">
                        {{ $t('menu.view-event-log') }}
                      </el-button>
                    </div>
                  </template>
                </div>
                <template #reference>
                  <span>
                    {{ showHamburger ? `${ $t('menu.events') }: ` : '' }}
                  </span>
                  <span v-if="successEvents.length"
                    class="fas fa-check-circle color-success fs-1-4rem" />
                  <span v-if="successEvents.length"
                    class="color-success event-text">{{ successEvents.length }}</span>
                  <span v-if="warningEvents.length"
                    class="fas fa-exclamation-triangle color-warning fs-1-4rem" />
                  <span v-if="warningEvents.length"
                    class="color-warning event-text">{{ warningEvents.length }}</span>
                  <span v-if="errorEvents.length"
                    class="fas fa-times-octagon color-danger fs-1-4rem" />
                  <span v-if="errorEvents.length"
                    class="color-danger event-text">{{ errorEvents.length }}</span>
                </template>
              </lazy-popover>
            </el-menu-item>
            <!-- Bug Report -->
            <el-menu-item index="/debug"
              data-nav="debug">
              <i v-if="!showHamburger"
                class="fas fa-bug"
                aria-hidden="true" />
              <span :class="{ 'sr-only': !showHamburger }">
                {{ $t('menu.bug-report') }}
              </span>
            </el-menu-item>
            <!-- User Submenu -->
            <el-submenu id="userProfileMenu"
              index="user"
              data-nav="user-profile-menu">
              <template #title>
                {{ fullName }}
                <i class="fas fa-user vertical-align-baseline"
                  aria-hidden="true" />
              </template>
              <!-- User Profile -->
              <el-menu-item v-if="!isManagedContext"
                index="/user-profile"
                data-nav="user-profile">
                {{ $t('menu.user-profile') }}
              </el-menu-item>
              <!-- Notification Settings -->
              <el-menu-item v-if="allowsNotification"
                index="/notifications"
                data-nav="notifications">
                {{ $t('menu.notification-settings') }}
              </el-menu-item>
              <!-- Send Feedback -->
              <el-menu-item :index="feedbackUrl"
                data-nav="feedback">
                {{ $t('menu.feedback') }}
                <external-link-indicator />
              </el-menu-item>
              <!-- Log Out -->
              <el-menu-item index="logout"
                data-nav="logout">
                {{ $t('menu.logout') }}
              </el-menu-item>
            </el-submenu>

            <!-- Language Submenu -->
            <!-- Unable to use language dropdown component as it
              doesn't scale correctly in mobile menu -->
            <el-submenu index="language"
              data-nav="language">
              <template #title>
                <span class="sr-only">
                  {{ $t('general.languages') }}
                </span>
                <mu-mega-icon icon="Globe"
                  class="dropdown-image"
                  aria-hidden="true" />
              </template>
              <el-menu-item v-for="(language, key) in languages"
                :key="key"
                index="language"
                @click="changeLanguage(language)">
                <span :class="{ 'color-primary': language.iso === $i18n.locale }">
                  {{ language.name }}
                </span>
              </el-menu-item>
            </el-submenu>
          </el-submenu>
        </with-root>
      </el-menu>
    </div>
  </el-header>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { setActiveLanguage, languageSelections } from '@/i18n/SetupI18n.js'
import { getSurveyLink } from '@/utils/surveys.js'

import LazyPopoverComponent from '@/components/ui-components/LazyPopover.vue'
import WithRootComponent from '@/components/extensions/WithRoot.js'
import HeaderLogoComponent from '@/components/ui-components/HeaderLogo.vue'
import NewBadge from '@/components/ui-components/NewBadge.vue'
import ExternalLinkIndicator from '@/components/ui-components/ExternalLinkIndicator.vue'

import { ROLE_TYPE_COMPANY_ADMIN } from '@/Globals'

export default {
  name: 'HeaderMenu',

  components: {
    'with-root': WithRootComponent,
    'lazy-popover': LazyPopoverComponent,
    'header-logo': HeaderLogoComponent,
    'new-badge': NewBadge,
    'external-link-indicator': ExternalLinkIndicator,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  data() {
    return {
      languages: languageSelections,
      showHamburgerMenu: false,
      windowWidth: 0, // Set to the size required for the horizontal menu (needs to respect internationalization)
    }
  },

  computed: {
    ...mapState('Auth', ['data']),
    ...mapState('UserProfile', ['profile']),
    ...mapGetters('ApplicationContext', ['isManagedContext', 'isAdminContext']),
    ...mapGetters('Auth', ['hasFeatureFlag', 'hasAuth', 'isPartnerAccount', 'isDistributorAccount', 'isManagedAccount', 'isAdminCompanyAccount', 'isDirectAccount', 'isPartnerSupported', 'isPartnerVantage']),
    ...mapGetters('Company', ['hasCompany']),
    ...mapGetters('Marketplace', ['marketplaceUserProfile']),
    ...mapGetters('Notifications', ['reversedEventLog']),
    /**
     * Specify breakpoint to show normal or hamburger menu
     */
    menuBreakpoint() {
      return this.isPartnerAccount ? 1440 : 1280
    },
    /**
     * Determine whether to show the dashboard page
     */
    showDashboard() {
      return !this.disabledFeatures.dashboard && this.isFeatureEnabled('DASHBOARD_ENABLED')
    },
    /**
     * Determine whether to show the hamburger menu or normal menu.
     */
    showHamburger() {
      return this.windowWidth < this.menuBreakpoint
    },
    /**
     * Get the event information and split it into the different types.
     */
    allEvents() {
      return this.reversedEventLog
    },
    successEvents() {
      return this.allEvents.filter(event => {
        return event.type === 'success'
      })
    },
    warningEvents() {
      return this.allEvents.filter(event => {
        return event.type === 'warning'
      })
    },
    errorEvents() {
      return this.allEvents.filter(event => {
        return event.type === 'error'
      })
    },
    /**
     * Work out which menu item is selected.
     */
    activeIndex() {
      return this.$route.path.includes('/partner') ? '/partner' : this.$route.path
    },
    fullName() {
      return this.profile.firstName && this.profile.lastName
        ? `${this.profile.firstName} ${this.profile.lastName}`
        : this.data.fullName
    },
    showBillingMarketsMenu() {
      return this.hasAuth('financials') && this.isFeatureEnabled('PAYS_BILL') && !this.isManagedContext
    },
    showCompanySubmenu() {
      return this.showCompanyProfileMenu || (this.hasCompany && this.showMpMarketplaceMenu) || (this.hasCompany && this.showBillingMarketsMenu) || (this.hasCompany && this.showManageUsersMenu) || (this.hasCompany && this.showCompanySuppMenu)
    },
    showCompanyProfileMenu() {
      return this.isFeatureEnabled('COMPANY_PROFILE_ENABLED') && !this.isManagedContext
    },
    showDashboardMenu() {
      return !this.disabledFeatures.dashboard && this.isFeatureEnabled('DASHBOARD_ENABLED')
    },
    showManageUsersMenu() {
      return this.hasAuth('company_admin')
    },
    showSecuritySettingsMenu() {
      return this.hasAuth('company_admin')
    },
    showCompanySuppMenu() {
      return (((this.isPartnerAccount || this.isPartnerSupported || this.isManagedAccount) && !this.isManagedContext) || this.isDirectAccount)
    },
    showEntitlementsMenu() {
      return this.hasAuth('company_admin') && this.hasFeatureFlag('aws_marketplace_portal')
    },
    showMpMarketplaceMenu() {
      if (this.marketplaceUserProfile?.companyName) {
        return true // we have a populated profile
      }

      // if we have no existing marketplace profile we need to be an admin to create one
      return this.hasAuth('company_admin') && this.isFeatureEnabled('MARKETPLACE_PROFILE_ENABLED') && !this.isManagedContext
    },
    allowsApiKeys() {
      return this.hasFeatureFlag('m2mauthentication') && [ROLE_TYPE_COMPANY_ADMIN].includes(this.profile.position)
    },
    allowsServices() {
      return !this.isDistributorAccount
    },
    allowsIXItems() {
      return !this.disabledFeatures.productIX && !this.isDistributorAccount
    },
    allowsMCRLookingGlass() {
      return !this.isDistributorAccount
    },
    allowsNotification() {
      if (this.isDistributorAccount || this.isManagedContext) return false
      if (this.isManagedAccount) return this.isFeatureEnabled('NOTIFICATIONS_ENABLED')
      return true
    },
    feedbackUrl () {
      return getSurveyLink('portalFeedback', { partyId: this.profile.partyId })
    },
  },

  mounted() {
    this.$nextTick(function() {
      window.addEventListener('resize', this.updateWindowWidth)
      window.addEventListener('click', this.userClicked)
      this.updateWindowWidth()
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth)
    window.removeEventListener('click', this.userClicked)
  },

  methods: {
    /**
     * Process the selection from the menu. It operates on the "index" field.
     */
    handleNavSelect(index) {
      // If routing was cancelled in beforeRouteLeave, without this
      // the active index would be incorrect.
      if (index !== this.$route.path) {
        this.$refs.menu.activeIndex = this.$route.path
      }

      this.showHamburgerMenu = false

      if (index.startsWith('/')) {
        this.$router.push(index, () => {
          // empty function is intentional
        })
      } else if (index.startsWith('http')) {
        window.open(index, '_blank')
      } else if (index === 'logout') {
        this.$store.dispatch('Auth/logout')
      } else if (index === 'quoting-tool') {
        window.open(this.$appConfiguration.quotingTool.url, '_blank')
      }
    },
    /**
     * When the user resizes the menu, make a note of the size so we can determine whether
     * to show the normal menu or the hamburger one.
     */
    updateWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth
    },
    /**
     * A generic click handler so that we can close the hamburger menu if it was displayed
     * and the user clicked on somewhere other than the menu.
     */
    userClicked(event) {
      if (!this.showHamburger) {
        return false
      }
      let hamburger = false
      // ComposedPath is now the standard, but if it's not there, then fall back to the old path, and
      // just for Edge, we have to fall back all the way to building it ouselves manually. Hopefully this
      // will no longer be necessary with the Chromium based Edge.
      const pathArray = event.composedPath?.() || event.path || this.buildEventPath(event)
      for (const item of pathArray) {
        if (item.id && (item.id === 'hamburger' || item.id === 'hamburgerTriggerButton')) {
          hamburger = true
          break
        }
      }
      if (!hamburger) {
        if (this.showHamburgerMenu) {
          this.showHamburgerMenu = false
        }
      }
    },
    buildEventPath(event) {
      const path = []
      let currentElem = event.target
      while (currentElem) {
        path.push(currentElem)
        currentElem = currentElem.parentElement
      }
      if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
        path.push(document)
      }
      if (path.indexOf(window) === -1) {
        path.push(window)
      }
      return path
    },
    changeLanguage(lang) {
      setActiveLanguage(lang)
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-image {
  height: 22px;
  width: 22px;
  color: var(--color-primary);
}
.el-header.header {
  padding: 0;
  background-color: var(--mp-header-background-color);
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--card-border-color);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0 0px 9px 2px rgb(0 0 0 / 55%);
}

.el-menu-item {
  font-size: 1.6rem;
  padding: 0 15px;
}

.event-text {
  padding: 0 5px 0 3px;
}

.hamburger-button {
  color: var(--color-text-secondary);
  font-size: 1rem;
  position: absolute;
  right: 11px;
  top: 11px;
  padding: 10px;
  border: none;
}
.hamburger-button:hover {
  color: var(--color-text-primary);
}

.mr-5-px {
  margin-right: 5px;
}
.mb-5-px {
  margin-bottom: 5px;
}
</style>

<style lang="scss">
.mainMenu .el-submenu__title {
  font-size: 1.6rem;
}
.mainMenu .el-submenu__icon-arrow {
  font-size: 1.6rem;
}

#hamburger > div > i.el-submenu__icon-arrow {
  display: none;
}

#hamburger > div.el-submenu__title {
  text-align: right;
  padding: 0 10px;
}

.hamburger .el-menu {
  border: 1px solid var(--card-border-color);
}

#hamburger ul.el-menu {
  height: fit-content;
  max-height: calc(100vh - 7rem);
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.tools-popover {
  .el-menu-item-group {
    .el-menu-item-group__title {
      padding-left: 1rem !important;
      color: var(--color-text-regular);
      text-transform: uppercase;
      font-weight: 700;
    }

    [role="menuitem"] {
      padding-left: 1.5rem !important;
    }
  }
}
</style>
