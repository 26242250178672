export default {
  productNames: {
    port: `${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    ports: `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    mcr: `${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    mcrs: `${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}`,
    mcrLong: `${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG}`,
    mve: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    mveLong: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE_LONG}`,
    vxc: `${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    vxcs: `${process.env.VUE_APP_EN_PRODUCTNAME_VXCS}`,
    ix: `${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    ixs: `${process.env.VUE_APP_EN_PRODUCTNAME_IXS}`,
    megaIx: `${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX}`,
    lag: `${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    lags: `${process.env.VUE_APP_EN_PRODUCTNAME_LAGS}`,
    lagLong: `${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG}`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    marketplaceShort: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    transitVxc: `${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}`,
    mves: `${process.env.VUE_APP_EN_PRODUCTNAME_MVES}`,
  },
  companyInfo: {
    name: `${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    portalUrl: `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_URL}`,
    salesEmail: `${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}`,
    arGlobalEmail: `${process.env.VUE_APP_EN_COMPANYINFO_AR_GLOBAL_EMAIL}`,
    portal: `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}`,
  },
  services: {
    'loading-services': `サービスを読み込んでいます...`,
    'no-matching': `一致するサービスは見つかりませんでした。`,
    'diversity-zone': `冗長化ゾーン`,
    'create-diverse-pair': `冗長化ペアの作成`,
    'red-zone': `赤ゾーン`,
    'blue-zone': `青ゾーン`,
    'invoice-reference': `サービス レベル参照`,
    'invoice-reference-explanation': `サービス レベル参照は、関連するプロダクト/サービスの請求書明細行レベルに表示されます`,
    'rate-limit': `帯域制限`,
    'select-rate-limit': `帯域制限の選択`,
    'max-rate-limit': `最大帯域制限`,
    'service-key': `サービス キー`,
    'service-deleted': `このサービスはデータから削除されました。サービスにリダイレクトしています`,
    'configuration-details': `設定の詳細`,
    'service-logs': `サービス ログ`,
    reloading: `サービス情報を再読み込みしています。しばらくお待ちください。`,
    'initial-bgp-state': `初期 BGP 状態`,
    saved: `設定が保存されました。`,
    'service-id': `サービス ID`,
    'provisioning-status': `プロビジョニング ステータス`,
    'loading-status': `サービス ステータスを読み込んでいます...`,
    'unknown-status': `このサービスのステータスを特定できません`,
    media: `メディア`,
    speed: `速度`,
    allocated: `割り当て済み`,
    demarcation: `責任分界`,
    up: `サービスは稼働しています`,
    down: `サービスがダウンしています`,
    'check-logs': `詳細については、ログを確認してください`,
    'product-name': `プロダクト名`,
    'rate-limit-fixed': `注意:このタイプのサービスについては、構築後に帯域制限を変更できません。`,
    'fixed-rate': `(帯域固定)`,
    'service-status': `サービス ステータス`,
    'service-information': `サービス情報`,
    'loading-resources': `リソースを読み込んでいます。しばらくお待ちください。`,
    'loading-issue': `データの読み込み中に問題が発生しました。サポートにお問い合わせください`,
    'identifier-copy': `サービス ID - クリックしてコピー`,
    decommissioned: `このサービスは既に廃止されました`,
    filter: `サービスのフィルタリング`,
    'expand-collapse': `サービスをすべて展開または折りたたみ`,
    'some-speeds-disabled': `注意:一部の速度が無効になる場合があります。アタッチされたサービスがその速度を超えているためです。`,
    'diversity-option': `冗長化オプション`,
    'loading-state-info': `更新されたサービス情報を読み込み中です。`,
    'waiting-on-deploy': `サービス構築中`,
    'no-company': `利用可能な会社がありません`,
    'cancelled-when': `キャンセルされたサービスは {when} に終了します`,
    'click-restore': `クリックしてサービスを復元します`,
    'cancel-unknown': `キャンセルされたサービスが終了する日付は不明です。`,
    'pending-internal-speed-approval': `{where} からの速度変更リクエストについては承認待ちです`,
    'pending-internal-connection-approval': `{where} からの接続については承認待ちです`,
    'pending-external-connection-approval': `{where} からの接続については外部承認待ちです`,
    'pending-3rd-party-speed-approval': `{where} の速度変更については外部承認待ちです`,
    'pending-3rd-party-connection-approval': `{where} からの接続については外部承認待ちです`,
    'gci-waiting-configure': `受信 BGP セッションを待機中です。`,
    'waiting-for-resources': `このサービスが開始されるのを待っています。`,
    'in-design-not-deployed': `まだ構築されていません。詳細を編集して、構築を完了します。`,
    'in-design-not-ordered': `まだ注文されていません。左側のサイド バーを使って注文します。`,
    'approve-connections': `接続の承認`,
    'approve-connections-list': `次の接続が承認待ちです:`,
    'best-route': `最適な経路`,
    'next-hop-vxc': `ネクスト ホップ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    bgp: `BGP`,
    'type-locked': `この {type} はロックされています`,
    'type-being-deployed': `この {type} は構築中です`,
    'type-unlock': `クリックして、この {type} をロック解除し、編集できるようにします`,
    'type-unlock-notlive': `この {type} はライブ状態ではないため、ロック解除できません`,
    'type-locked-admin': `この {type} は管理者によってロックされているため、編集できません`,
    'type-lock': `クリックして、この {type} をロックして、編集や削除ができないようします`,
    'type-lock-notlive': `この {type} はライブ状態ではないため、ロックできません`,
    'type-unlocked-admin': `この {type} はロック解除されており、管理者によってのみロックできます`,
    'type-locked-megaport': `この {type} は ${process.env.VUE_APP_EN_COMPANYINFO_NAME} によってロックされているため、編集できません。詳細については、${process.env.VUE_APP_EN_COMPANYINFO_NAME} サポートにお問い合わせください。`,
    'type-cancelled': `この {type} はキャンセルされました`,
    'terminate-count': `該当せず | はい、1 件のサービスを解約します | はい、{count} 件のサービスを解約します`,
    'service-locked': `注意:このサービスは ${process.env.VUE_APP_EN_COMPANYINFO_NAME} によってロックされているため、編集や削除はできません。詳細については、${process.env.VUE_APP_EN_COMPANYINFO_NAME} サポートにお問い合わせください。`,
    'service-admin-locked': `注意:このサービスはロックされているため、編集や削除はできません。サービスをロック解除できるのは会社管理者のみです。`,
    'edit-permission-denied': `注意:このサービスを編集する権限がありません。`,
    'ip-address': `IP アドレス`,
    'peer-ip-address': `ピア IP アドレス`,
    'creation-date': `作成日`,
    'replace-configured': `設定済みサービスの置き換え`,
    'replace-confirmation': `これにより、現在設定されている項目が削除され、この保存されたセットに置き換えられます。続行しますか?`,
    'delete-configuration': `保存された設定の削除`,
    // name は port/mcr/mve の名前であり、productType は更新されたポートのようなもののタイプになります (Port、MCR、MVE)。
    'port-like-updated-title': `{name} {productType} を更新しました`,
    'port-like-updated-message': `{productType}「{name}」の保存された更新`,
    'click-setup': `クリックして設定します`,
    'new-mve-message': `ご注意ください!${process.env.VUE_APP_EN_PRODUCTNAME_MVE} の画像/ソフトウェアの検証にはインターネット接続が必須です。${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} を有効化するか、ご利用のインターネット アクセスに接続するかを選択できます。`,
    'connection-denied': `{destination} への ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} が拒否されました`,
    'connection-approved': `{destination} への ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} が承認されました`,
    'amsix-contact-phone': `AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX} サービスの {contactType} には電話番号が必要です。`,
    'gbps-ix': `Gbps ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'gbps-vxc': `Gbps ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    'ibm-request-fail': `このポートのレート制限を取得できません。もう一度お試しください。`,
    'type-state': `{type} の状態`,
    'shutdown-dialog-title': `このサービスをシャット ダウンしてもよろしいですか?`,
    'shutdown-dialog-message': `このサービスをシャット ダウンすると、トラフィックはこのサービスを経由しなくなります。${process.env.VUE_APP_EN_COMPANYINFO_NAME} ネットワーク上でダウンしているかのように動作します。`,
    'shutdown-dialog-note': `注意:このサービスに対する請求は引き続き有効であり、この接続に対しても引き続き請求されます。`,
    'sub-uid': `サブ UID`,
    'type-move-notlive': `この {type} はライブ状態ではないため、移動できません`,
    'port-move-notlive': `この {type} はライブ状態ではないため、接続を移動できません`,
    'no-movable-type': `この {type} には移動できる接続がありません`,
    'move-permission-denied': `接続を移動する権限がありません`,
    'cant-move-multiple-vnics': `複数の vNIC を持つ ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} から接続を移動できません`,
    'cant-move-no-ports': `同じメトロ内に他の ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} はありません`,
    'cant-move-no-mcrs': `同じメトロ内に他の ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} はありません`,
    'cant-move-no-mves': `同じメトロ内に他の ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} はありません`,
    'awaiting-approval': `保留中の変更の承認または拒否を待っています`,
    'shutdown-confirm-message': `シャット ダウンするには、「確認」、「保存」の順番でクリックしてください。`,
    'view-additional-filters': `その他のフィルターを表示`,
    'clear-filters': `フィルターのクリア`,
    'service-type': `サービス タイプ`,
    'contract-term': `契約期間`,
    'change-sort-direction': `並べ替えの順序を変更する`,
    'matching-services': `一致するサービス 0 件 ({total} 合計) | 一致するサービス 1 件 ({total} 合計) | 一致するサービス {count} 件 ({total} 合計)`,
    'diversity-location-tooltip': `利用可能な多様性ゾーンで場所をフィルタリングします。「Auto」 (オート) のままにしておくと、多様性ゾーンが選択されます。詳細については、{link} を参照してください。`,
    'diversity-config-tooltip': `このサービスが割り当てられる多様性ゾーン。`,
    'auto-zone': `オート ゾーン`,
    'download-inventory-report': `インベントリ・レポートのダウンロード`,
    'expand-all': `すべてのサービスを展開`,
    'collapse-all': `すべてのサービスを閉じる`,
    'extra-actions': `追加アクション`,
    'type-status': `タイプとステータス`,
    'has-ongoing-maintenance': `進行中のメンテナンス イベント。`,
    'has-ongoing-outage': `進行中のサービス停止イベント。`,
  },
  app: {
    'environment-unrecognized': `環境が認識されません`,
    'unrecognized-url': `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_URL} または認識された開発 URL からのアクセス`,
  },
  general: {
    'delete-type': `{type} の削除`,
    'edit-type': `{type} の編集`,
    'remove-type': `{type} の削除`,
    'new-type': `新規 {type}`,
    'add-type': `{type} の追加`,
    'update-type': `{type} の更新`,
    'type-details': `{type} の詳細`,
    'type-configuration': `{product} の設定`,
    'edit-configured-type': `設定済み{type} の編集`,
    'select-type-location': `{type} ロケーションの選択`,
    'delete-permission-denied': `この {type} を削除する権限がありません`,
    'type-not-found': `{type} が見つかりません`,
    'type-not-found-redirect': `この {type} はデータには見つかりませんでした。サービスにリダイレクトしています`,
    'success-count': `成功 0 | 成功 1 | 成功 {count}`,
    'warning-count': `警告 0 | 警告 1 | 警告 {count}`,
    'error-count': `エラー 0 | エラー 1 | エラー {count}`,
    'count-months': `0 か月 | 1 か月 | {count} か月`,
    'locations-count': `ロケーション 0 | ロケーション 1 | ロケーション {count}`,
    'last-log': `最後に受け取ったログ {last}`,
    'max-rate-limit': `最大:{maxRateLimit} Mbps`,
    'speed-gbps': `{speed} Gbps`,
    'missing-billing-market': `ビリング マーケットがありません「{country}」`,
    'create-thing': `{thing} の作成`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    welcome: `${process.env.VUE_APP_EN_COMPANYINFO_NAME} へようこそ`,
    save: `保存`,
    revert: `元に戻す`,
    close: `閉じる`,
    cancel: `キャンセル`,
    back: `戻る`,
    next: `次へ`,
    done: `完了`,
    loading: `読み込んでいます...`,
    services: `サービス`,
    service: `サービス`,
    price: `価格`,
    summary: `概要`,
    copy: `コピー`,
    'copy-to-clipboard': `クリップボードにコピー`,
    'characters-copied': `0 文字をコピー | 1 文字をコピー | {count} 文字をコピー`,
    version: `バージョン`,
    'first-name': `名`,
    'last-name': `姓`,
    phone: `電話`,
    email: `E メール`,
    password: `パスワード`,
    ok: `OK`,
    'details-help': `詳細については、次のヘルプ トピックを参照してください:`,
    documentation: `ドキュメント`,
    saving: `保存しています...`,
    public: `パブリック`,
    private: `プライベート`,
    unspecified: `未指定`,
    'x-end': `{end} エンド`,
    hide: `非表示`,
    show: `表示`,
    'technical-contact': `テクニカル　コンタクト`,
    'noc-contact': `NOC (運用) 担当者`,
    'contact-phone': `担当者の電話番号`,
    'select-type': `タイプの選択`,
    size: `サイズ`,
    'step-unavailable': `前のステップを完了するまで、このオプションは利用できません。`,
    'not-available': `利用不可`,
    'configure-thing': `{thing} の設定`,
    'modification-delay': `注意:適用された変更が表示されるまでに最長 2 分かかる場合があります。`,
    billing: `請求`,
    'processing-approval': `承認を処理しています...`,
    'processing-denial': `拒否されたリクエストを処理しています...`,
    unknown: `不明`,
    yes: `はい`,
    no: `いいえ`,
    untitled: `無題`,
    'no-location': `ロケーションが選択されていません`,
    'select-location': ` ロケーションの選択`,
    'unknown-location': `ロケーションが不明です`,
    configure: `設定`,
    'no-matching-countries': `一致する国がありません`,
    'no-matching-locations': `一致するロケーションがありません`,
    'country-filter': `国フィルター`,
    search: `検索`,
    'pre-order': `予約注文`,
    details: `詳細`,
    name: `名前`,
    success: `成功`,
    disabled: `無効`,
    enabled: `有効`,
    'shut-down': `シャットダウン`,
    term: `期間`,
    red: `赤`,
    blue: `青`,
    none: `なし`,
    new: `新規`,
    general: `一般`,
    status: `ステータス`,
    'status-unknown': `ステータスが不明です`,
    'created-by': `作成者`,
    'privacy-policy': `プライバシー ポリシー`,
    'site-terms': `サイトの条件`,
    gsa: `グローバル サービス契約 (GSA)`,
    'acceptable-use': `利用規定`,
    'please-select': `選択`,
    'important-information': `重要な情報`,
    'agree-terms': `利用規約に同意します`,
    'view-details': `詳細の表示`,
    'action-confirmation': `これを行ってもよろしいですか?`,
    'unknown-error': `不明なエラー`,
    edit: `編集`,
    remove: `削除`,
    'request-failed': `リクエストに失敗しました`,
    'company-name': `会社名`,
    active: `アクティブ`,
    inactive: `非アクティブ`,
    na: `該当なし`,
    disable: `無効化`,
    pricing: `価格設定`,
    note: `注意`,
    website: `ウェブサイト`,
    'click-to-visit': `クリックしてアクセス`,
    all: `すべて`,
    ipv4: `IPv4`,
    ipv6: `IPv6`,
    you: `お客様`,
    'your-company-admin': `会社の管理者`,
    date: `日付`,
    mbps: `Mbps`,
    start: `開始`,
    end: `エンド`,
    duration: `期間`,
    'loading-details': `詳細を読み込んでいます...`,
    'details-load-failed': `詳細の読み込みに失敗しました`,
    prefix: `プレフィックス`,
    'next-hop': `ネクスト ホップ`,
    'load-failed': `読み込みに失敗しました`,
    to: `宛先`,
    from: `差出人`,
    message: `メッセージ`,
    send: `送信`,
    sort: `並べ替え`,
    'hide-details': `クリックして詳細を非表示`,
    'show-details': `クリックして詳細を表示`,
    'logs-label': `ログ`,
    'usage-label': `使用状況`,
    'configuration-label': `設定`,
    actions: `アクション`,
    confirm: `確認`,
    delete: `削除`,
    'reload-data': `データの再読み込み`,
    network: `ネットワーク`,
    location: `ロケーション`,
    selection: `選択`,
    'choose-file': `ファイルの選択`,
    label: `ラベル`,
    'single-use': `シングル ユース`,
    'multi-use': `マルチ ユース`,
    add: `追加`,
    'select-date': `日付の選択`,
    'loading-data': `データを読み込んでいます`,
    description: `説明`,
    'click-copy': `クリックしてコピー`,
    token: `トークン`,
    notice: `通知`,
    'end-date': `終了日`,
    existing: `既存`,
    'no-info': `これ以上の情報はありません`,
    'error-updating': `{thing} を更新中にエラーが発生しました。`,
    'click-enable': `クリックして有効化します`,
    'updating-details': `詳細を更新しています...`,
    approve: `承認`,
    view: `表示`,
    'save-as': `名前を付けて保存`,
    order: `注文`,
    'show-actions': `アクションの表示`,
    'hide-actions': `アクションの非表示`,
    'items-per-page': `ページあたり {count} 項目`,
    'show-all': `項目をすべて表示`,
    origin: `オリジン`,
    communities: `コミュニティ`,
    updated: `更新済み`,
    'all-types': `すべてのタイプ`,
    static: `静的`,
    connected: `接続済み`,
    local: `ローカル`,
    file: `ファイル`,
    clear: `クリア`,
    'no-match-found': `一致項目が見つかりません`,
    'list-not-available': `リストがありません`,
    'list-select': `リストを選択します`,
    'password-toggle': `切り替えてパスワードを表示`,
    'exact-label': `完全一致`,
    'position-label': `位置`,
    'min-label': `最小`,
    'max-label': `最大`,
    'operations-label': `運用`,
    'drag-position-label': `クリック & ドラッグして配置`,
    'example-abbr': `例`,
    star: `*`,
    'standard-label': `標準`,
    'advanced-label': `高度`,
    'filters-label': `フィルター`,
    'on-label': `オン`,
    'off-label': `オフ`,
    'default-label': `デフォルト`,
    'deny-label': `拒否`,
    'permit-label': `許可`,
    'required-field-label': `必須フィールド`,
    'warning-label': `警告`,
    'type-name': `{type} の名前`,
    'save-error': `変更を保存できませんでした: `,
    'copy-thing-to-clipboard': `クリップボードに {thing} をコピー`,
    'error-creating': `{thing} を作成中にエラーが発生しました。`,
    'error-deleting': `{thing} を削除中にエラーが発生しました。`,
    'success-creating': `{thing} が正常に作成されました。`,
    'success-updating': `{thing} が正常に更新されました。`,
    'success-deleting': `{thing} が正常に削除されました。`,
    'created-thing': `正常に作成された {thing}`,
    'updated-thing': `正常に更新された {thing}`,
    'word-counter': `{count} 文字/ {maxWords}`,
    user: `ユーザー`,
    load: `読み込み`,
    update: `更新`,
    'server-error': `API サーバーとの通信中にエラーが発生しました`,
    'context-switching-title': `アカウントを切り替えてもよろしいですか?`,
    'context-switching-message': `{accountTypeFrom} <strong>{fromCompany}</strong> から {accountTypeTo} <strong>{toCompany}</strong> に変更しようとしています`,
    'feature-flag-access-error': `マネージド アカウントから {pageName} にアクセスすることはできません。パートナー アカウントを選択して {pageName} にアクセスします。`,
    'confirm-password-mismatch': `パスワードが上で提供されたパスワードと一致しません`,
    'open-sidebar': `サイドバーを開きます`,
    'learn-megaport': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} サービスの詳細を確認するか、${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL} にアクセスします。`,
    'visit-documentation': `${process.env.VUE_APP_EN_COMPANYINFO_DOCUMENTATION_PORTAL} に移動`,
    'visit-portal': `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL} にアクセス`,
    'login-trouble': `ログインに問題がありますか? {link}`,
    'site-disclaimer': `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}/ランディング ページにアクセスすることにより、${process.env.VUE_APP_EN_COMPANYINFO_NAME} のプライバシー ポリシー、サイト利用規約、利用規定に拘束されます。`,
    'partner-deals': `パートナー ディール`,
    'cancel-reterm': `何もせず、契約期間終了時に定価で契約期間なしにロールオーバーしたい場合は、[Cancel] (キャンセル) をクリックします。`,
    'deleted-thing': `{thing} は正常に削除されました`,
    'unexpected-error': `予期しないエラーが発生しました。後でもう一度お試しください。このエラーが続く場合は、${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL} までご連絡ください。`,
    'unsaved-changes-confirm-message': `保存されていない変更はすべて失われます`,
    'unsaved-changes-confirm-title': `保存せずにページから移動しますか?`,
    'unsaved-changes-confirm-cancel': `ページにとどまる`,
    'unsaved-changes-confirm-ok': `ページから移動`,
    'not-now': `今は行わない`,
    up: `上へ`,
    down: `下へ`,
    beta: `ベータ`,
    languages: `言語`,
    expand: `展開`,
    collapse: `折りたたむ`,
    here: `ここ`,
    true: `正しい`,
    false: `誤り`,
    'get-help': `問い合わせ`,
    'type-shutdown-state': `注意:この {type} はシャット ダウン状態です`,
    continue: `続行`,
    accept: `承認`,
    decline: `拒否`,
    'terms-and-conditions': `利用規約`,
    'lag-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_LAGS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_LAGS}`,
    'mcr-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}`,
    'mve-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES}`,
    connection: `接続`,
    connections: `接続`,
    'move-to': `移動先`,
    'not-submitted': `未送信`,
    processing: `処理中`,
    failure: `失敗`,
    move: `移動`,
    'select-all': `すべて選択`,
    'deselect-all': `すべて選択解除`,
    'non-prod': `これは非実稼働環境です`,
    auto: `オート`,
    'preparing-download': `ダウンロードを準備しています...`,
    'download-failed-title': `ダウンロードに失敗しました`,
    'download-failed': `ファイルをダウンロードできませんでした。後でもう一度お試しください。`,
    mobile: `モバイル`,
    activate: `アクティブ化`,
    deactivate: `非アクティブ化`,
    valid: `有効`,
    invalid: `無効`,
    type: `タイプ`,
    expired: `期限切れ`,
    comments: `コメント`,
    refresh: `更新`,
    'pre-release': `プレリリース`,
    'logs-count': `過去 24 時間に受け取ったログは 1 エントリです | 過去 24 時間に受け取ったログは {count} エントリです`,
    'most-recent-n-events': `最新のイベント {count} 件を表示`,
    'no-logs': `このサービスのログはありません`,
  },
  tour: {
    'start-tour': `ツアーの開始`,
    'find-location': `ロケーションの検索`,
    'location-selected': `選択されたロケーション`,
    'add-a-service': `サービスの追加`,
    'actions-panel': `アクション パネル`,
    'live-chat': `24/7 ライブ チャット`,
    'next-steps': `次のステップ`,
    start: `ここでは、${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}、 ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}、 ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS}、 ${process.env.VUE_APP_EN_PRODUCTNAME_IXS} などのサービスの構築や管理を行えます。<br/><br/>「次へ」をクリックして続行します。`,
    'intro-port': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} は、 ${process.env.VUE_APP_EN_COMPANYINFO_NAME} インフラストラクチャや多数のクラウド、インターネット エクスチェンジ、およびその他のエンドポイントへのゲートウェイへの接続です。<br/><br/>「次へ」をクリックして、 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を作成します。`,
    'select-port': `最初のステップは ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} のロケーションを選択することです。<br/><br/>「次へ」をクリックして、ロケーションを選択します。`,
    'location-selected-content': `ロケーションを選択したら、${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を設定します。<br/><br/>「次へ」をクリックして、 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を設定します。`,
    'port-configuration': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の設定`,
    'configure-port': `必要な情報を入力して、${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を設定します。<br/><br/>「次へ」をクリックして、${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の情報を追加します。`,
    price: `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 情報を指定したら、価格が表示されます。<br/><br/>「次へ」をクリックして、次の画面に移動します。`,
    summary: `これは、${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を設定する最後のステップです。注文するまで請求されることはありません。<br/><br/>「次へ」をクリックして、${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を追加します。`,
    'port-configured': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 設定済み`,
    'configured-port': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 情報が提供されました。<br/><br/>「Next (次へ)」をクリックして次の画面に移動します。`,
    'add-service': `次のステップは、新しく設定した ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} にサービスを追加することです。${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の右側の「+ 接続」をクリックするか、以下から選択します。`,
    'actions-panel-content': `このデモの間は、設定された ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} が稼働に必要な他のアクションとともに、ここに表示されます。<br/><br/>まだ完了していない保留のアクションがある場合は、サイドバーに表示されます。`,
    chat: `サポートが必要ですか?ここをクリックして、${process.env.VUE_APP_EN_COMPANYINFO_NAME} のスペシャリストとリアルタイムのチャットを開始します。`,
    'next-steps-no-kb': `これでツアーは終了です!<br/><br/>「サービス」ページにアクセスして、最初の ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を作成する準備が整いました。`,
    'next-steps-kb-html': `これでツアーは終了です!  さらにサポートが必要な場合は、<br/><br/>当社の[ドキュメント『](https://docs.megaport.com/)』または <a href="https://www.youtube.com/channel/UCdVx98wdrhcTCIuw8UE5hIA" target="_blank">YouTube チャンネル</a> にアクセスしてください。<br/><br/>「サービス」ページにアクセスして、最初の ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を作成する準備が整いました。`,
    'next-steps-partial-html': `これでツアーは終了です!  さらにサポートが必要な場合は、<br/><br/>当社の[ドキュメント『](https://docs.megaport.com/)』または <a href="https://www.youtube.com/channel/UCdVx98wdrhcTCIuw8UE5hIA" target="_blank">YouTube チャンネル</a> にアクセスしてください。<br/><br/> 最初の ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を作成する準備が整いました。`,
    exit: `ツアーの終了`,
  },
  about: {
    why: `${process.env.VUE_APP_EN_COMPANYINFO_NAME} を使用する理由`,
    provisioning: `わずか 59 秒で ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ネットワーク上で接続をプロビジョニングします。`,
    scalable: `スケーラブルな接続`,
    'scalable-content': `ビジネスの需要の変化に応じて、随時、帯域幅を容易に増減できます。`,
    'real-time': `リアルタイムのプロビジョニング`,
    api: `パワフルな API 統合`,
    'api-content': `パワフルな API を使用して、接続を自律的に管理し、ワークフローを最適化します。`,
    'about-megaport': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} について`,
    'about-megaport-video': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} ビデオについて`,
    'megaport-features': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} の機能`,
  },
  authentication: {
    'about-company': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} について`,
    'password-reset': `アカウントでパスワードをリセットする必要があります。ここで新規パスワードを設定して、ログインします。`,
    'setting-up': `アカウントを設定しています...`,
    'password-strength': `パスワードの強度`,
    'very-weak': `非常に脆弱`,
    weak: `脆弱`,
    moderate: `中程度`,
    good: `強力`,
    excellent: `非常に強力`,
    'good-password': `これは強力なパスワードです`,
    'excellent-password': ` これは非常に強力なパスワードです`,
    agreement: `同意`,
    'agree-to': `次に同意する `,
    'create-account': `アカウントの作成`,
    google: `Google でサインイン`,
    'have-account': `既にアカウントをお持ちですか?`,
    'log-in': `ログイン`,
    'one-time-token': `1 回限りのトークン`,
    'new-password': `新規パスワード`,
    'target-username': `ターゲット ユーザー名`,
    forgot: `パスワードを忘れた場合`,
    register: `今すぐ登録`,
    'processing-reset': `リセットのリクエストを処理しています...`,
    'send-reset': `リセットの送信`,
    resetting: `パスワードをリセットしています...`,
    reset: `パスワードのリセット`,
    'trying-login': `ログインを試行しています...`,
    'please-login': `ログイン`,
    'failed-login': `既存の資格情報によるログインに失敗しました。再度ログインします。`,
    'password-reset-title': `パスワードのリセット`,
    'missing-reset-token': `パスワードのリセット トークンがありません。「ログイン」ページに移動し、パスワードを忘れた場合の新規リクエストを送信します。`,
    'logging-in': `ログインしています...`,
    'need-to-reset': `パスワードをリセットする必要があります。必須フィールドでフォームが更新されました。`,
    failed: `ログインに失敗しました`,
    'error-registering': `登録中にエラーが発生しました`,
    'unknown-error-registering': `登録の試行中に不明なエラーが発生しました`,
    'reset-email-sent': `パスワードをリセットするための E メールを送信しました。`,
    'reset-request-failed': `パスワードのリセット - リクエストに失敗しました`,
    'unknown-error-reset': `パスワードのリセットを試行中に不明なエラーが発生しました`,
    'password-change-successful': `パスワードが正常に変更されました。これで、新規パスワードを使ってログインできます。`,
    'password-reset-failed': `パスワードのリセットに失敗しました。再度リセットするには、「ログイン」ページに移動し、パスワードを忘れた場合の新規リクエストを送信します。`,
    'auth-failed': `認証に失敗しました`,
    'auth-failed-message': `アカウントの認証に失敗しました`,
    'confirm-email-label': `E メール アドレスを確認しています`,
    confirming: `確認しています。しばらくお待ちください`,
    success: `E メールが確認されました`,
    'failed-confirm': `E メール アドレスの確認に失敗しました。後でもう一度お試しください。`,
    'return-portal': `ポータルに戻る`,
    'registered-company': `登録された会社: {name}`,
    'updated-company': `更新された会社 {name}`,
    'login-success-title': `ログインが成功しました!`,
    'login-success-message': `{name} がログインしました`,
    'login-failed-token': `既存のトークンによるログインに失敗しました。再度ログインしてください。`,
    'use-security-code': `ユーザー セキュリティ コード`,
    'temp-password': `一時パスワード`,
    'confirm-password': `パスワードの確認`,
    'login-error': `ログイン エラー`,
    'login-error-msg': `認証情報を使ってログインできません。ログインに問題がありますか?<a href="https://www.megaport.com/contact/support/" target="_blank">問い合わせ</a>`,
    submit: `送信`,
    'vendor-onboarding': {
      cisco: {
        'intro-header': `Cisco SD-WAN Cloud と ${process.env.VUE_APP_EN_COMPANYINFO_NAME} との相互接続へようこそ`,
        'intro-content': `ご利用いただき誠にありがとうございます。このプロダクトが素晴らしい体験をもたらすことを願っております。わずか数個のステップで vManage を介してクラウド環境への専用接続を作成し、${process.env.VUE_APP_EN_COMPANYINFO_NAME} の高可用性アンダーレイ ネットワークの上にオーバーレイされたCiscoSD-WAN を利用できるようになります。`,
        'temp-password-info': `<b>E メールと一時パスワードを入力します。</b>パスワード変更後は、 ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL} を使用する代わりに、Cisco vManage バージョン 20.5 以降に認証情報を入力することで、MVE の発注、MVE から Megaport ネットワーク上の他の宛先への VXC の作成および削除を実行できます。`,
        'new-password-info': `<b>新規パスワードを入力します。</b>パスワード変更後は、 ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL} を使用する代わりに、Cisco vManage バージョン 20.5 以降に認証情報を入力することで、MVE の発注、MVE から Megaport ネットワーク上の他の宛先への VXC の作成および削除を実行できます。`,
        'password-changed-info': `次の手順これらの認証情報を Cisco vManage バージョン 20.5 以降に入力して、${process.env.VUE_APP_EN_PRODUCTNAME_MVES} をプロビジョニングするか、${process.env.VUE_APP_EN_PRODUCTNAME_MVES} とクラウド サービス プロバイダー間で ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} を作成します。`,
        'temp-password-intro': `E メールと一時パスワードを入力します。 `,
        'new-password-intro': `新規パスワードを入力します。 `,
        'password-changed-intro': `パスワードが変更されました。 `,
        'password-info': `パスワードを変更したら、${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL} を使用する代わりに、Cisco vManage バージョン 20.5 以降に認証情報を入力して、${process.env.VUE_APP_EN_PRODUCTNAME_MVE} を注文したり、${process.env.VUE_APP_EN_PRODUCTNAME_MVES} から ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ネットワーク上の他の宛先に向かう ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} を作成したり、削除したりできます。`,
      },
    },
    'verify-email-heading': `メールをご確認ください`,
    'verify-email-subheading': `アカウント確認のためのリンクを送信しました`,
    'verify-email-description': `メールが届いていない場合念のため、迷惑メール フォルダーをご確認ください。`,
    'verify-email-button': `メール再送信`,
    'verify-email-resent-successful': `確認メールが正常に再送信されました。`,
    'verify-email-resent-unsuccessful': `確認メールの再送中に問題が発生しました。`,
    'password-must-include': `パスワードの条件`,
    'at-least-8-char': `最低 8 文字であること`,
    'at-least-one-number': `最低 1 個の数字があること`,
    'at-least-one-symbol': `最低 1 文字の記号があること`,
    'at-least-one-lower-case-letter': `最低 1 文字の小文字があること`,
    'at-least-one-upper-case-letter': `最低 1 文字の大文字があること`,
    'create-an-account': `アカウントを作成する`,
    or: `または`,
    'signup-email': `メールで登録する`,
    'signup-google': `Google で登録する`,
    'login-to-your-account': `アカウントにログインする`,
    'signup-success': `正常に登録されました。`,
    'signup-failed': `登録に失敗しました。もう一度お試しください。`,
    login: `ログイン`,
    'login-google': `Google でログインする`,
    'setting-company': `注意:新しい会社の商号を保存すると、セッションはログアウトされます。新しい会社に追加するためには、再度ログインする必要があります。`,
    'reset-message': `既存のアカウントの場合は、パスワードをリセットするためのメールが送信されています。`,
    'signup-google-instructions': `会社の商号を入力し、以下の条件を確認して続行してください。`,
    signup: `登録`,
    'create-account-email': `メールでアカウントを作成`,
    'confirm-new-password': `パスワードを確認`,
    'new-password-no-your': `新規パスワード`,
    'reset-expired-dialog-title': `使用されたリセット リンクが無効になっています。`,
    'reset-expired-dialog-desc': `E メールで使用されているリンクの有効期限が切れているか、無効です。「リンクを再送信」をクリックするか、${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL} までご連絡ください。`,
    'resend-link': `リンクを再送信`,
    'unexpected-error-dialog-title': `予期しないエラーが発生しました。後でもう一度お試しください。`,
    'unexpected-error-dialog-desc': `このエラーが続く場合は、${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL} までご連絡ください。`,
    'login-megaport': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} へのログイン`,
    'email-verified': `E メールが確認されました`,
    'invalid-link': `このリンクは無効になりました`,
    'request-link': `ボタンをクリックして、登録済みの E メールへの新しいリンクを受け取ります。`,
    'verified-account': `このアカウントはすでに確認されています`,
    'verified-email': `メール アドレスはすでに確認されています`,
    'continue-to-megaport': `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL} に進む`,
    'company-trading-name-instructions': `会社の商号を入力し、以下の条件を確認して続行してください。`,
    'change-email-desc': `新しい E メール アドレスを入力し、「Get Verification Code」 (確認コードを取得) をクリックします。以下に入力する確認コードが、新しいメール アドレスに送信されます。このウィンドウを閉じないでください。`,
    'change-email-warning': `E メール アドレスを変更すると再度ログインが必要となるため、ご注意ください。`,
    'verification-code': `確認コード`,
    'get-verification-code': `確認コードを取得`,
    'verify-email-taken': `この E メールは、別のユーザーによってすでに使用されています。`,
    'verification-code-expired': `確認コードの有効期限が切れています。新しい確認 E メールをリクエストしてください`,
    'verification-code-sent': `一意の確認コードを送信しました`,
    'verification-code-failed': `確認コードの取得中に問題が発生しました`,
    'verification-code-wrong': `確認コードが間違っています`,
    'setup-mfa-cancel-confirm': `キャンセルするとログイン画面に戻ります。MFA が設定されるまで、ログインできません。`,
    'setup-mfa-reset': `会社の管理者が多要素認証 (MFA) コードをリセットし、無効化しました。MFA を再設定し、ログインしてください。`,
    'setup-mfa-enforced': `会社の管理者が多要素認証 (MFA) を強制しました。これが設定されるまで、ログインできません。`,
    'enabling-mfa': `MFA を有効化しています`,
    'mfa-token-invalid': `ワンタイム コード {code} が無効です。失敗した試行回数が 5 回を超えると、アカウントがロックされます。`,
    'mfa-long': `多要素認証`,
    'mfa-enter-token': `アカウントで MFA が有効化されました。トークンを入力してログインしてください。`,
    verify: `検証`,
    'mfa-verify-token': `トークンを検証しています`,
    'mfa-instructions': `スマホの多要素認証アプリで、上の画像をスキャンします。バーコードを使用できない場合は、アプリに | このテキスト コード | を入力します。`,
    'enable-mfa-failed': `多要素認証の有効化に失敗しました`,
    'mfa-token-required': `多要素認証のワンタイム トークンが必要です。必須フィールドでフォームが更新されました。`,
    'login-cognito-unexpected-failure': `認証中に予期しないエラーが発生しました。もう一度お試しいただくか、組織にお問い合わせください。`,
    'login-sso-assistance': `サポートが必要な場合は、{docs} サポートチームにお問い合わせください。`,
    contact: `連絡先`,
    'docs-portal': `ドキュメント・ポータル`,
    'create-one': `1 つ作成`,
    'login-sso-help-reset-password': `E メールとパスワードでログインできない場合は、以下の「Forgot Password (パスワードを忘れた場合)」のリンクからパスワードの再設定をお試しください。`,
    'login-sso-help-idp-config': `シングル サインオン (SSO) が有効になっているのにログインできない場合は、IdP 構成エラーであるか、IdP が応答していない可能性があります。「会社の管理者」または SSO がオプションに設定されている場合は、${process.env.VUE_APP_EN_COMPANYINFO_NAME} ユーザー名とパスワードを使用して直接ログインできる場合があります。`,
    'login-help': `サイン インのヘルプ`,
    'login-temp-password-expired': `一時パスワードの有効期限が切れています。一時パスワードのリセットをご希望の場合は、${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL} までお問い合わせください。`,
    continue: `続行`,
    'create-new-account': `新しいアカウントを作成する`,
    'join-company-account': `会社アカウントに参加する`,
    'error-resetting-password': `パスワードのリセット エラー`,
    'reset-password-sent-if-confirmed': `その Eメール アドレスが当社のシステムにあり、アカウントが以前に確認されている場合は、パスワードをリセットするための E メールが送信されます。`,
    'sso-first-login': `会社の管理者があなたのアカウントで SSO を有効にしました。認証のために会社の ID プロバイダーにリダイレクトされます。`,
    'sso-enforced-no-access': `会社の管理者がシングル サインオンを強制しているため、SSO 経由でのみログインする必要があります。SSO アクセスに関するサポートが必要な場合は、会社の管理者にお問い合わせください。`,
    'sso-email-mapping-error': `SSO 設定の E メール属性マッピングに問題があります。この問題を解決するには、会社の管理者に連絡し、E メール属性のマッピングを確認してもらうことをお勧めします。この問題を解決するには、<a href="https://docs.megaport.com/troubleshooting/sso-faq/" target="_blank">ドキュメント ポータル</a> の SSO の FAQ ページを参照してください。`,
    'email-query-missing': `メール クエリ パラメータがありません`,
    'email-not-sso': `メールが SSO 用に設定されていません`,
  },
  validations: {
    required: `{thing} が必要です`,
    failed: `検証に失敗しました`,
    'correct-issues': `表示された問題を訂正します。`,
    'ix-selection-required': `${process.env.VUE_APP_EN_PRODUCTNAME_IX} の選択が必要です`,
    'asn-validation': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} AS 番号は 2 から {maxAsn} の範囲である必要があります (デフォルト値は {defaultAsn} です)`,
    'asn-validation-plain': `AS 番号は{minAsn} から {maxAsn} の範囲でなければなりません`,
    'asn-validation-range': `AS 番号の範囲 {minAsn}-{maxAsn} または {minAsn2}-{maxAsn2}`,
    'mbps-value-range': `{min} から {max} Mbps の範囲の値を入力します`,
    'value-too-high': `許可されている最大値を超えています。{max} 以下の値を入力します`,
    asn: `AS 番号は「AS」か「ASN」で開始するか、数値になります`,
    'min-length': `最小の長さ 0 文字 | 最小の長さ 1 文字 | 最小の長さ {min} 文字`,
    'max-length': `最大の長さ 0 文字 | 最大の長さ 1 文字 | 最大の長さ {max} 文字`,
    'min-max-length': `{min} から {max} 文字の範囲でなければなりません`,
    'weak-password': `パスワードの強度が不十分です`,
    'invalid-mac': `有効な MAC アドレスを入力します`,
    'terms-conditions': `利用規約に同意する必要があります`,
    'new-password': `新規パスワードを入力します。`,
    'password-different': `新規パスワードは旧パスワードとは異なる必要があります`,
    'email-invalid': `有効な E メール アドレスを入力します`,
    'key-invalid': `キーの検証に失敗しました`,
    'service-key-invalid': `有効なサービス キーを入力します`,
    'use-service-key': `接続を作成するには、サービス キーを使用する必要があります`,
    'nutanix-key-format': `形式: オプションの 3 文字とハイフンの後に UUID`,
    'azure-key-format': `Azure サービス キーは 32-36 文字です`,
    'invalid-service-key': `サービス キーが無効です`,
    'destination-type': `宛先タイプを選択します`,
    'aws-connection-type': `AWS 接続方式を選択する必要があります`,
    'destination-provider': `宛先プロバイダーを選択します`,
    'connection-name': `接続の名前を入力します`,
    'connection-name-min': `名前は、文字で始まる 1 つ以上の単語である必要があります`,
    'select-peering': `ルート サーバーとピアリングするかどうかを選択します。`,
    'please-select': `{thing} を選択してください`,
    'please-enter': `{thing} を入力します`,
    'phone-format': `国際形式の例: +61 4321 1234`,
    'aws-account': `AWS アカウントは 12 桁の数字です`,
    'ip-cidr-format': `無効な IP アドレス形式 (例: 192.0.1.0/30)`,
    'ip-list': `IP リストの例: 191.0.2.0/24,191.0.2.0/30`,
    'select-peer-type': `少なくとも 1 つのピア タイプを選択します`,
    'pairing-key-format-invalid': `ペアリング キーは Google の仕様と一致する必要があります`,
    'virtual-circuit-format-invalid': `有効な仮想回線 ID を入力します`,
    'pairing-key-invalid': `ペアリング キーが無効です`,
    'virtual-circuit-invalid': `仮想回線 ID が無効です`,
    'google-pairing-key': `Google 接続の注文を完了するには、有効なペアリング キーを入力します`,
    'invalid-key': `キーが無効です`,
    'enter-valid-key': `有効なキーを入力します`,
    'vlan-required': `VLAN 値が必要です`,
    'rate-limit': `帯域制限は指定された最大値を超えることはできません。`,
    'select-location': `ロケーションを選択する必要があります`,
    'enter-name': `名前を入力します`,
    'select-rate-limit': `帯域制限を選択します`,
    ge: `{value} 以上の値を入力します`,
    'mve-final-check': `${process.env.VUE_APP_EN_PRODUCTNAME_MVE} VLAN の最終チェックは、構築中に実行されます`,
    'vlan-unavailable': `VLAN 利用不可`,
    'vlan-available': `VLAN が利用可能です`,
    'vlan-auto-assign': `VLAN を空白のままにすると、VLAN がランダムに割り当てられます`,
    'vlan-no-untagged': `この VLAN をタグなしに設定することはできません`,
    'vlan-untagged-warning': `VLAN のタグをなしにすると、${process.env.VUE_APP_EN_PRODUCTNAME_PORT} は単一の接続に制限されます。`,
    'vlan-range': `VLAN 値の範囲は {minVlan} から {maxVlan} までです`,
    'vlan-in-use': `この ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} には、この VLAN を使用して設計された接続が既に存在します`,
    'agree-terms': `規約に同意する必要があります`,
    'insufficient-privileges': `権限が不十分です`,
    'bio-too-long': `会社情報の単語数が多すぎます`,
    'url-format': `有効な {site} の URL を入力します`,
    'valid-company': `有効な会社名が必要です`,
    'description-too-long': `説明の単語数が多すぎます`,
    'ip-in-list': `この IP は既にリストにあります`,
    'invalid-network': `アドレスが有効な IPv4、IPv6、またはネットワーク アドレスではありません`,
    'not-valid-network': `アドレスが有効なネットワーク アドレスではありません`,
    'no-subnet': `IP アドレスにはサブネット マスクが含まれている必要があります`,
    'no-file-selected': `ファイルが選択されていません`,
    'wrong-file-type': `ファイル タイプが間違っています`,
    'select-text-file': `アップロードするテキスト ファイルを選択します`,
    'file-too-long': `ファイル名が長すぎます`,
    'file-max-length': `エンコードされたファイルの長さは、{max} kb 未満である必要があります。`,
    'file-read-error': `ファイルの読み取り中にエラーが発生しました`,
    'new-password-retype': `新規パスワードを再入力します`,
    'new-password-different': `新規パスワードと再入力したパスワードは一致する必要があります`,
    'fill-details': `これらの詳細を入力します`,
    'characters-not-allowed': `次の文字は禁止されています: {chars}`,
    'minimum-payment': `最低支払い額は {value} です`,
    'payment-description-none': `支払いの説明には、少なくとも 1 つの英文字が必要です`,
    'payment-enter-amount': `有効な額を入力します`,
    'type-used': `{thing} は既に使用されています`,
    'cannot-validate-type': `タイプ: {thing} の値を検証できません`,
    'ip-family-required': `IP アドレス ファミリの値を指定する必要があります`,
    'ip-address-used': `このアドレスは既に使用されています`,
    'ip-address-subnet-required': `IP アドレスにはサブネット マスクが含まれている必要があります`,
    'ip-address-subnet-not-required': `サブネット マスクは不要です`,
    'ip-address-overlap': `重複する IP はインターフェイスで利用できません`,
    'network-broadcast-exception': `アドレスがブロードキャスト アドレスです。 ホスト アドレスが必要です`,
    'network-address-exception': `アドレスがネットワーク アドレスです。 ホスト アドレスが必要です`,
    'local-peer-ip-same-network': `ローカル IP アドレスとピア IP アドレスが同じ IP ネットワークにありません`,
    'peer-network-address': `ピア IP アドレスをネットワーク アドレスにすることはできません`,
    'peer-broadcast-address': `ピア IP アドレスをブロードキャスト アドレスにすることはできません`,
    'peer-same-local-address': `ピア IP アドレスをローカル IP アドレスと同一にすることはできません`,
    'ipv4-mismatch': `IPv6 アドレスを IPv4 フィルター リストに追加できません`,
    'ipv6-mismatch': `IPv4 アドレスを IPv6 フィルター リストに追加できません`,
    'number-required': `値は数字である必要があります`,
    'range-min': `範囲は {min} から始まります`,
    'range-max': `範囲は {max} で終わります`,
    'range-min-exceed-max': `最小値が最大値を超えることはできません`,
    'range-max-exceed-min': `最大値を最小値より低くすることはできません `,
    'range-min-exceeds-cidr': `最小値を CIDR より小さくすることはできません`,
    'thing-between-mix-max': `{thing} は {min} から {max} の範囲でなければなりません`,
    'value-min': `最小値は {min} です`,
    'value-max': `最大値は {max} です`,
    'value-required': `値を指定する必要があります`,
    'value-integer': `値は整数になる必要があります`,
    'value-positive': `正の値が必要です`,
    'value-decimal-places': `値の小数点以下の桁数は {places} です`,
    'broadcast-address': `ネクスト ホップをブロードキャストアドレスにすることはできません`,
    'next-hop-same-ip-family': `ネクスト ホップは、プレフィックスと同じ IP ファミリに属している必要があります`,
    'next-hop-same-network': `ネクスト ホップは、インターフェイス IP の 1 つと同じネットワークに属している必要があります`,
    'next-hop-not-interface-address': `ネクスト ホップをインターフェイス IP に利用できません`,
    'next-hop-network-address': `ネクスト ホップがネットワーク アドレスです。ホスト アドレスが必要です`,
    'bgp-password': `パスワードには、英数字と特殊文字 (！@＃$％^＆* +-= _) のみを含めることができます`,
    'bpg-password-length': `パスワードは 0 から 25 文字の範囲でなければなりません`,
    'min-max-range': `値は {mix} から {max} の範囲でなければなりません`,
    'bpg-no-connections': `BGP 接続が見つかりませんでした`,
    'bfd-transmit': `送信間隔は 300 から 9000 の範囲でなければなりません`,
    'bfd-receive': `受信間隔は 300 から 9000 の範囲でなければなりません`,
    'bfd-multiplier': `3 の最小乗数が必要です`,
    'name-already-used': `この名前は使用中です`,
    'address-family-required': `アドレス ファミリが必要です`,
    'vlan-unique': `VLAN ID は一意で、{max} 以下である必要があります`,
    'invalid-address-domain': `有効な IP アドレスまたはドメインを入力してください`,
    'vendor-image': `ベンダー画像を選択してください`,
    'email-invalid-seperators': `単一の E メール アドレスまたは E メール アドレスのコンマ区切りリストのいずれかを入力します`,
    'no-approval-price-title': `価格の変更を承認する権限がありません`,
    'no-approval-price-message': `サービスの価格に影響を与える変更を加えることができるのは、注文権限のあるユーザーのみです`,
    'enter-valid-auth-key': `有効な {thing} を入力します`,
    '128-chars': `128 文字未満である必要があります。`,
    'min-number': `最低 1 個の数字が必要 | 最低 {min} 個の数字が必要`,
    'min-upper-case': `最低 1 文字の大文字が必要 | 最低 {min} 文字の大文字が必要`,
    'min-lower-case': `最低 1 文字の小文字が必要 | 最低 {min} 文字の小文字が必要`,
    'min-symbol': `最低 1 文字の記号文字が必要 | 最低 {min} 文字の記号文字が必要`,
    'as-path-prepend': `0～10 までの AS パス追加件数を入力してください`,
    'api-keys-expiry': `5 から 1440 の数値を入力してください`,
    'api-keys-expiry-decimal': `有効期限は整数にする必要があります`,
    'min-max-alphanumeric': `{min}〜{max} の英数字でなければいけません`,
    'mfa-token': `MFA トークンを入力します`,
    vnic: `vNIC Plane の選択が必要です`,
    'bgp-password-valid-characters': `有効な文字: a-z A-Z 0-9 ! @ # ? . $ % ^ & * + = -`,
    'ix-peer-invalid-characters': `文字、数字、コロン、アンダースコア、ダッシュのみ`,
    'ix-peer-invalid-format': `AS プレフィックスを付けた AS 番号のセットをコロンで区切って入力してください`,
    'asn-validation-range-triple': `AS 番号の範囲 {minAsn}-{maxAsn}、 {minAsn2}-{maxAsn2} または {minAsn3}-{maxAsn3} (デフォルトは {defaultAsn})`,
    'required-pattern': `値はパターン {thing} である必要があります`,
    'rfc-number-example': `{total} 文字である必要があります ({start} 英数字、次に 6 数字、次に 3 英数字)`,
    'idp-name-invalid-characters': `プロバイダー名には小文字、数字、ダッシュのみを含める必要があります`,
    alphanumeric: `{thing} は英数字のみである必要があります`,
    'reverse-dns-invalid-characters': `有効な文字: a-z A-Z 0-9 . -`,
    'invalid-field': `有効な {thing} を入力してください`,
  },
  connections: {
    'choose-type': `宛先タイプを選択します`,
    cloud: `クラウド`,
    'internet-exchange': `インターネット エクスチェンジ`,
    csp: `クラウド サービス プロバイダーへの ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} 接続`,
    private: `独自の ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}、 ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}、 ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} への ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} 接続`,
    'private-vxc': `プライベート ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    ix: `${process.env.VUE_APP_EN_COMPANYINFO_NAME} ${process.env.VUE_APP_EN_PRODUCTNAME_IX} に接続`,
    marketplace: `パートナー ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} への ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} 接続`,
    'enter-service-key': `サービスキーの入力`,
    'service-key': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} サービス キーが与えられている場合は、このオプションを選択します`,
    'select-destination-port': `宛先 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を選択します`,
    'awaiting-deployment': `接続は構築待ちです。構築に通常より長い時間がかかる場合は、${process.env.VUE_APP_EN_COMPANYINFO_NAME} サポートにお問い合わせください。`,
    'approval-error': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} 承認エラーです。`,
    'deny-error': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} 接続拒否エラーです。`,
    'untag-vlan-tooltip': `VLAN ID なしでこのサービスを設定します。これによって ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} が単一の仮想サービスに制限されます。`,
    'used-vlan': `この ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} には、既にこの VLAN ID を使用して構築された接続があります。続行すると、VLAN  ID がランダムに割り当てられます。`,
    'asn-from-mcr': `AS 番号は、自動的に ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} AS 番号を使用します`,
    'azure-ports': `利用可能な Azure ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} から選択します`,
    'select-destination-port-title': `宛先 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を選択します`,
    'no-available-target-ports': `利用可能なターゲット ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} がありません`,
    'please-select-port': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を選択してください`,
    'no-available-ports-key': `このサービスキーに利用可能な ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} はありません。別の項目を試してください。`,
    'all-ports-used-key': `このサービス キーに関連付けられたすべての ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} は他のサービスで消費されています。別の項目を試してください。`,
    'port-in-use-title': `使用中の ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'port-in-use-message': `この ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} は既に使用中のため選択することはできません。`,
    'google-ports': `利用可能な Google ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} から選択します`,
    'no-google-services': `選択した ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} のリージョンで利用可能な Google Cloud サービスはありません。`,
    'no-google-ports': `このペアリング キーで利用可能な ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} はありません。別の項目を試してください。`,
    'nutanix-ports': `利用可能な Nutanix ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} から選択します`,
    'oracle-ports': `利用可能な Oracle ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} から選択します`,
    'no-oracle-ports': `この仮想回路で利用可能な ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} はありません。別の項目を試してください。`,
    'all-oracle-ports-taken': `この仮想回路のすべての ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} は他のサービスで消費されています。別の項目を試します。`,
    'list-all-oracle-ports': `利用可能なすべての Oracle ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} のリストについては、| ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} プロファイルを参照します`,
    'ix-details': `${process.env.VUE_APP_EN_PRODUCTNAME_IX} サービスの接続の詳細`,
    'ix-asn-message': `注意:${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX} サービスについては、構築後に AS 番号を変更できません。`,
    'mcr-config': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} {end} エンド接続の詳細`,
    'mcr-override-asn': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} AS 番号のオーバーライド`,
    'mcr-override-asn-tooltip': `ローカルAS 番号を使用して、この BGP ピアの ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} の AS 番号をオーバーライドできます。ほとんどの場合、デフォルトのままにしておく必要があります。`,
    'mcr-setup-tooltip': `この ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} に接続されている ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} ごとに、1 つ以上のインターフェイス、IP アドレス、BGP 接続、または静的経路を設定できます。ほどんどの ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} は、1 つのインターフェイスを使用します。ただし、各インターフェイスに内部 VLAN タグを選択することで、「Q-in-Q」を使って複数のインターフェイスを設定できます。TPID は 0x8100 に設定されます。`,
    'mcr-ip-address-tooltip': `このインターフェイスで設定する IP アドレスと関連するサブネットマスクです。<br /><br />複数のアドレスを設定できます。IPv4 と IPv6 の両方がサポートされています。<br /><br />このフィールドを空白のままにすると、${process.env.VUE_APP_EN_PRODUCTNAME_VXC} がプロビジョニングされるまでインターフェイスの設定を延期できます。`,
    'aws-diversity-tooltip': `機器の冗長化が必要な場合は、別のカラー ゾーンに対して ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} を注文します。`,
    'no-matching-ports': `一致するターゲット ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} がありません`,
    'service-key-id': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} サービス キー ID`,
    'service-key-unavailable': `サービス キーは現在のソース {product} 用であるため、使用できません。`,
    'port-asn': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} AS 番号`,
    'port-ip': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} IP アドレス`,
    'aws-default-asn': `デフォルトの AWS ピア AS 番号。この ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} が Direct Connect ゲートウェイにアタッチされている場合、AS 番号はオーバーライドされます。`,
    'vxc-is-down': `この ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} サービスはダウンしています`,
    'vxc-is-up': `この ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} サービスは稼働しています`,
    'vxc-status-unknown': `この ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} サービスのステータスは不明です`,
    'ix-is-down': `この ${process.env.VUE_APP_EN_PRODUCTNAME_IX} サービスはダウンしています`,
    'ix-is-up': `この ${process.env.VUE_APP_EN_PRODUCTNAME_IX} サービスは稼働しています`,
    'ix-status-unknown': `この ${process.env.VUE_APP_EN_PRODUCTNAME_IX} サービスのステータスは不明です`,
    'speed-req-external-approval': `注意:この ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} は、別の会社が所有しているため、速度の変更についてはその会社の承認が必要です。`,
    'ix-service': `${process.env.VUE_APP_EN_PRODUCTNAME_IX} サービス`,
    'new-vxc': `新規 ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} 接続`,
    'preferred-end-vlan': `優先 {title} VLAN `,
    'amsix-contact-phone': `AMS-IX サービスには {contactType} の電話番号が必要です。`,
    'cancel-a-end': `このサービスの A エンドをキャンセルできるのは ${process.env.VUE_APP_EN_COMPANYINFO_NAME} のみです。<b>{companyName}</b> から引き続き請求されないように、直接 <b>{companyName}</b> に連絡して、サービスをキャンセルしてください。`,
    'no-matching-source-port': `一致するソース ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} がありません`,
    'no-selected-source-port': `ソース ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を選択します。`,
    'non-edit-reason': `注意: | {reason} のため、接続の詳細を編集できません`,
    'pending-approval': `接続は {approvalFrom} からの外部承認を待っています。`,
    'pending-speed-approval': `接続は、速度の変更について {approvalFrom} からの外部承認を待っています。`,
    'pending-speed-approval-to': `{newSpeed} Mbps への帯域制限の変更は、{approvalFrom} からの承認を待っています。`,
    'connection-denied': `{destination} への VXC が拒否されました`,
    'connection-approved': `{destination} への VXC が承認されました`,
    'peer-config': `{type} ピアの設定`,
    'min-bfg-tx-interval': `最小送信間隔は {min}ms です。`,
    'min-bfg-rx-interval': `最小受信間隔は {min}ms です。`,
    'min-bfg-multiplier': `最小乗数は {min} です`,
    'key-expired': `このサービス キーは無効になりました。この問題を解決するには、{company} にお問い合わせください`,
    'key-used': `このシングル ユースのサービス キーは既に使用されています。この問題を解決するには、{company} にお問い合わせください`,
    'key-invalid': `このサービス キーは使用できません。この問題を解決するには、{company} にお問い合わせください`,
    'ix-rate-limit-help': `${process.env.VUE_APP_EN_PRODUCTNAME_IX} の帯域制限は、メトロ接続の場合は ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の総速度に、非メトロ接続の場合は10Gbps に制限されます。`,
    'vxc-rate-limit-help': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} の最大レート制限は、各エンドの最低速度とルート上のネットワーク容量によって設定されます。`,
    'max-ip-addresses': `{max} IP アドレスの最大数`,
    'max-static-routes': `{max} 静的経路の最大数`,
    'max-bgp-connections': `{max} BGP 接続の最大数`,
    'mcr-end-visibility': `クリックして {operation} ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} {end} エンドの詳細を表示`,
    'mcr-end-details': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR}{end} エンドの詳細`,
    'mcr-end': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR}{end} エンド`,
    'missing-azure-service-key': `この Azure ExpressRouter サービスにはサービス キーがありません。このサービスを注文するには、「${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の選択」画面にサービス キーを入力します。`,
    'missing-google-pairing-key': `この Google サービスにはペアリング キーがありません。このサービスを注文するには、「${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の選択」画面にサービス キーを入力します。`,
    'new-bgp-state': `新規作成の BGP 接続は {state} になります。`,
    'google-key-format': `Google キーの形式:`,
    'bgp-peer-asn': `ピア AS 番号`,
    'connection-details': `接続の詳細`,
    'connection-name': `接続の名前`,
    'vlan-fixed': `注意:このタイプのサービスについては、構築後に VLAN ID を変更できません。`,
    'configure-azure-vlan': `単一 Azure ピアリング VLAN の設定`,
    'azure-vlan': `Azure ピアリング VLAN`,
    'enter-vlan': `VLAN の入力`,
    'preferred-vlan': `優先 VLAN`,
    asn: `AS 番号`,
    'asn-full': `自律システム番号`,
    'mac-address': `MAC アドレス`,
    'mac-address-format': `次のいずれかの形式で MAC アドレスを入力します -  3c22.fb80.749c または 3c:22:fb:80:74:9c`,
    'bgp-password': `BGP パスワード`,
    'peer-macro': `ピア マクロ`,
    'graph-visibility': `グラフの可視性`,
    untagged: `タグなし`,
    shutdown: `シャットダウン`,
    enabled: `有効`,
    'end-not-configured': `{end}-エンドはまだ設定されていません。`,
    'interface-vlan': `インターフェイス VLAN`,
    'ip-addresses': `IP アドレス`,
    'static-routes': `静的経路`,
    'bgp-connections': `BGP 接続`,
    'nat-ip-addresses': `NAT IP アドレス`,
    'b-vlan-service-key': `B エンド VLAN (サービス キーから)`,
    'hide-cloud-details': `クリックしてクラウドの詳細を非表示`,
    'show-cloud-details': `クリックしてクラウドの詳細を表示`,
    'cloud-details': `クラウドの詳細`,
    'peering-policy': `ピアリング ポリシー`,
    'policy-url': `ポリシー URL`,
    'peering-email': `ピアリング E メール`,
    'company-url': `会社 URL`,
    'company-email': `会社 E メール`,
    'street-address': `住所`,
    city: `市町村`,
    'state-province': `都道府県`,
    'postcode-zip': `郵便番号`,
    country: `国`,
    type: `タイプ`,
    'aws-connection-name': `AWS 接続名`,
    'aws-owner-acct-id': `AWS 所有者アカウント ID`,
    'customer-ip': `顧客 IP アドレス`,
    'amazon-ip': `Amazon IP アドレス`,
    'alibaba-account': `Alibaba アカウント ID`,
    'pairing-key': `ペアリング キー`,
    'virtual-circuit-id': `仮想回線 ID`,
    deleted: `接続が削除されました`,
    'select-destination': `宛先の選択`,
    'configure-a-end': `A エンドの設定`,
    'configure-b-end': `B エンドの設定`,
    'vlan-value': `VLAN 値`,
    save: `接続の保存`,
    'approve-prompt': `この接続を承認しますか?`,
    'approve-connection': `接続の承認`,
    'deny-connection': `接続リクエストの拒否`,
    configuration: `接続の設定`,
    'awaiting-approval': `この接続は承認待ちです。`,
    'approve-question': `このリクエストを承認してもよろしいですか?`,
    'approve-title': `リクエストの承認`,
    'deny-question': `このリクエストを拒否してもよろしいですか?`,
    'deny-title': `リクエストの拒否`,
    'cancel-question': `このリクエストをキャンセルしてもよろしいですか?`,
    'cancel-title': `リクエストのキャンセル`,
    'change-approved-title': `速度の変更が承認されました`,
    'change-approved-message': `新規帯域制限が承認されました。`,
    'change-denied-title': `速度の変更が正常に拒否されました`,
    'change-denied-message': `帯域制限の変更リクエストが拒否されました。`,
    'change-cancelled-title': `速度の変更が正常にキャンセルされました`,
    'change-cancelled-message': `帯域制限の変更リクエストがキャンセルされました。`,
    'approved-title': `接続が承認されました`,
    'denied-title': `接続が正常に拒否されました`,
    untag: `タグなし`,
    'auto-assigned': `自動割り当て済み`,
    'auto-assign': `VLAN の自動割り当て`,
    'vlan-checking': `VLAN の可用性を確認しています...`,
    'add-connection': `接続の追加`,
    open: `開`,
    closed: `閉`,
    vlan: `VLAN`,
    'valid-service-key': `有効なサービス キー`,
    'verifying-key': `キーを検証しています`,
    'select-provider': `プロバイダーの選択`,
    'no-matching-providers': `一致するプロバイダーがありません`,
    'select-connection-type': `接続タイプの選択`,
    'bgp-statuses': `BGP ステータス`,
    'end-vlan': `{end} エンドの VLAN`,
    'route-servers': `ルート サーバー`,
    'bgp-down': `BGP はダウンしています`,
    'bgp-up': `BGP は稼働しています`,
    'bgp-unknown': `BGP ステータスは不明です`,
    interface: `インターフェイス`,
    'old-speed': `旧速度`,
    'new-speed': `新規速度`,
    'approve-speed-question': `この速度の変更を承認しますか?`,
    'deny-speed-change': `速度の変更リクエストを拒否`,
    'approve-speed-change': `速度の変更を承認`,
    'cancel-speed-request': `速度の変更リクエストをキャンセル`,
    'connections-html': `詳細については、{link} を参照してください`,
    'speed-change-pending-your-approval': `お客様の承認待ち:この接続について速度の変更がリクエストされました。`,
    'speed-change-pending-company-approval': `会社の承認待ち:この接続について速度の変更がリクエストされました (会社の承認が必要です)。`,
    connection: `接続`,
    'lag-full': `この ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} については、新規接続は受け入れられません。`,
    'port-full': `この ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} については、新規接続は受け入れられません。`,
    'show-connections': `クリックして接続を表示`,
    'hide-connections': `クリックして接続を非表示`,
    'service-key-vlan-range': `VLAN 2-4094 (マルチ ユースのサービス キーについては無効化されています)`,
    'third-party-owner': `サードパーティの所有者`,
    'b-end': `別の会社が所有するこの接続の B エンドにいます`,
    'shut-down-by-user': `ユーザーによってシャットダウンされました`,
    'b-end-no-delete': ` では接続を削除できません`,
    'speed-change-from': `次の速度の変更`,
    'new-connection-from': `新規接続ソース`,
    'best-route-explanation': `チェック マークは、同じ宛先への 2 つの経路から選択するときに、${process.env.VUE_APP_EN_PRODUCTNAME_MCR} がこの経路を優先することを示します。`,
    'as-path': `AS パス`,
    'local-preference': `ローカル プリファレンス`,
    med: `Multi-Exit Discriminator`,
    metric: `メトリック`,
    protocol: `プロトコル`,
    distance: `距離`,
    'gateway-ipv4': `ゲートウェイ IP アドレス (IPv4)`,
    'gateway-ipv6': `ゲートウェイ IP アドレス (IPv6)`,
    'match-label': `一致`,
    'dont-match-label': `不一致`,
    'delete-route-label': `経路の削除`,
    'connected-port-removed': `このサービスが接続されていたこの ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} または ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} は、データから削除されました。サービスにリダイレクトしています`,
    approving: `承認しています`,
    denying: `拒否しています`,
    'approval-issues': `問題 {action} ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}: {errorStr}`,
    'confirm-bgp-state-change': `これによって BGP 状態が {newState} に変更され、このサービスに対して設定された未保存の変更が確定します。変更が反映されるまでに最長 2 分かかる場合があります。`,
    'bgp-state-change-prompt': `BGP 状態の変更を確認しますか?`,
    'awaiting-approval-or-denial': `この接続を承認/拒否するのを待っています`,
    'azure-key-unused-key': `このロケーションで利用可能`,
    'azure-key-used-key': `一意の Azure ピアリング VLAN を指定することで複数回使用できます`,
    'azure-key-unused': `この ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} はこのサービス キーでは使用されていません`,
    'azure-key-used': `この ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} は一意の Azure ピアリング VLAN を指定することで複数回使用できます`,
    'outscale-connection-name': `Outscale 接続名`,
    'outscale-account': `Outscale 所有者アカウント ID`,
    'outscale-ip': `Outscale IP アドレス`,
    transit: `${process.env.VUE_APP_EN_COMPANYINFO_NAME} が提供する接続を介した Transit Gateway アクセス`,
    'vxc-name-default': `{aEnd}～{bEnd}`,
    'customer-asn': `顧客 AS 番号`,
    vnic: `vNIC`,
    'reverse-dns': `リバース DNS`,
    'virtual-interfaces-vnics': `仮想インターフェイス (vNIC)`,
    'vnic-plane': `vNIC-{index} {plane} Plane`,
    'end-vnic': `{end} エンド vNIC`,
    'create-ip-transit-vxc': `${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} の作成`,
    'mcr-full': `この ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} は新しい接続を受け付けない`,
    'mve-full': `この ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} は新しい接続を受け付けない`,
    'provider-ip': `プロバイダー IP アドレス`,
    'ibm-account': `IBM Cloud アカウント ID`,
    'mve-untag-vlan-tooltip': `VLAN ID なしでこのサービスを設定します。`,
    'no-transit-port-title': `${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} の ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} が見つかりません`,
    'no-transit-port-message': `${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} が見つかりませんでした。${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL} までお問い合わせください。`,
    'a-end-vnic-help': `仮想インターフェイス サービスの開始`,
    'b-end-vnic-help': `仮想インターフェイス サービスの終了`,
    'no-description': `説明なし`,
    'select-connections': `1 つ以上の接続を選択`,
    'move-connection': `接続を移動`,
    'select-new-end': `新規エンドを選択`,
    'move-connection-confirm': `続行することを確認し、選択した接続を {currentEnd} から {newEnd} に移動してください。`,
    'move-connection-diversity': `注意:多様性ゾーンが {currentDiv} から {newDiv} に変更しています。新しい多様性に影響が及ぼされる可能性があります。`,
    'move-connection-disclaimer': `移動中は、接続できなくなります。移動が完了するまでに最大 2 分かかる場合があります。`,
    'no-valid-connections': `有効な接続が見つかりません`,
    'no-valid-destinations': `有効な宛先が見つかりません`,
    'invalid-move-existing-end': `ターゲット {type} は選択した接続にすでに接続されている`,
    'invalid-move-multiple-vnics': `ターゲット {type} に複数の vNIC がある`,
    'invalid-move-too-small': `ターゲット {type} の容量が不十分`,
    'invalid-move-new-untagged': `ターゲット {type} はタグなし接続を受け付けない`,
    'invalid-move-untagged': `ターゲット {type} にタグなしの接続がある`,
    'invalid-move-vlan-taken': `ターゲット {type} で VLAN を使用できない`,
    'search-for-providers': `その他のプロバイダーを表示`,
    'termed-delete-etf': `Megaport との契約条件に従って期間接続を解除する場合、早期解約手数料 (ETF) が適用されます。`,
    'avoid-etf': `ETF を回避するには、設定の詳細が正確になるまで「最低利用期間なし」を選択してください。接続を更新して、いつでも希望の期間で登録することができます。`,
    'rate-limit-change-disallowed': `この接続は期間接続であるため、接続が更新され、帯域制限が引き上げられない限り、帯域制限の変更はできません。`,
    'rate-limit-change-term': `この接続は期間接続であり、速度制限は接続の申請時に選択された速度の {termSpeed} Mbps を下回ることはできません。`,
    'private-vxc-no-valid-target': `プライベート接続を確立するための有効なサービスがありません`,
    'vnic-details': `vNIC の詳細`,
    'new-vnic-details': `新しい vNIC の詳細`,
    'new-end-vnic': `新しい {end}-エンド vNIC`,
    'new-preferred-end-vlan': `新しい優先 {title} VLAN`,
    'move-connection-vnic-warning': `注意:宛先 ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} の vNIC と VLAN も指定された値に変更されます。`,
    'confirm-move': `移動の確認`,
    'speed-req-external-approval-a-end': `注意:この ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} は別の会社が所有しており、 ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} の速度は変更できません。`,
    'speed-req-external-approval-b-end': ` 注意:この ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} は、別の会社に接続されているため、速度を変更するにはその会社の承認が必要です。`,
  },
  ports: {
    'port-deleted': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} は削除されました`,
    'select-port': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の選択`,
    'port-speed': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 速度`,
    'select-port-speed': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 速度の選択`,
    'mcr-explanation': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MCR}) は、世界中のクラウド ホットスポットで ${process.env.VUE_APP_EN_COMPANYINFO_NAME} によってホストされるレイヤー 3 対応の仮想ルーターです。物理的な ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を必要としませんが、必要に応じてポートに接続できます。`,
    'port-main-tooltip': `お手元の機器を当社の機器に接続する場合は、物理的な ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} が必要です。${process.env.VUE_APP_EN_COMPANYINFO_NAME} の接続ロケーションでは、1G、10G、100G のファイバー インターフェイスをサポートしています (通常は、デュプレックス SMOF です。サイト固有の要件については、${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 構築中に取得した LOA を確認してください)。`,
    'diverse-from-existing': `既存 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} からの冗長化`,
    'ports-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'ports-type-count': `0 {type} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} | 1 {type} ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | {count} {type} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'pluralize-ports': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} | ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'services-attached-count': `0 サービスをアタッチ |1 サービスをアタッチ | {count} サービスをアタッチ`,
    'percent-allocated': `{percent}% 割り当て済み`,
    'awaiting-resources': `この {type} は、リソースの割り当て待ちです。`,
    'add-connection-to': `この {type} に新規接続を追加します`,
    'no-mcr-speed-change': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} の帯域制限は、サービスの期間中は固定されています。`,
    'lacp-ports': `LACP & ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'lacp-on-port': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 上の LACP`,
    'add-to-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_LAG} に追加する ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'ports-in-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_LAG} 内の ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} 数`,
    'mcr-asn': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} AS 番号`,
    'summary-adding-to-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} を ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} に追加しています`,
    'summary-ports-in-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_LAG} 内の${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'no-slow-ports-in-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_LAG} 内に 10Gbps 未満の${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 速度はありません`,
    'mcr-rate-limit': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} の帯域制限`,
    'allocated-tooltip': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 速度とそのサービスの合計累積帯域制限`,
    'port-removed': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} はデータから削除されました。サービスにリダイレクトしています`,
    'contract-terms': `契約期間は、${process.env.VUE_APP_EN_PRODUCTNAME_PORT} がライブ状態になると表示されます`,
    statuses: `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ステータス`,
    'configure-details': `追加された ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} の詳細を設定します`,
    'add-ports-to-lag': `この ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} に新規 ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} を追加します`,
    'lag-full': `この ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} については、これ以上 ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} は受け入れられません。`,
    'max-speed-tooltip': `Mbps 単位でのレート制限。{speed} 以下である必要があります。これを空白のままにすると、最大 {productType} 速度に制限されます。`,
    'vxc-speed-positive-integer': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} 速度は、正の整数になる必要があります。`,
    'vxc-speed-max': `最高 ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} 速度は {speed} Mbps です`,
    'lag-primary-deletion-locked': `${process.env.VUE_APP_EN_PRODUCTNAME_LAG} のプライマリ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} は、${process.env.VUE_APP_EN_PRODUCTNAME_LAG} にロックされた ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} がある間は削除できません`,
    'deletion-insufficient-capacity': `この ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を削除すると、${process.env.VUE_APP_EN_PRODUCTNAME_LAG} の現在の接続をサポートするのに十分な容量が残りません。`,
    'link-aggregation': `{portName} のリンク アグリゲーション`,
    'attached-vxc-locked': `アタッチされた ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} はロックされています`,
    'attached-ix-locked': `アタッチされた ${process.env.VUE_APP_EN_PRODUCTNAME_IX} はロックされています`,
    'terminate-date': `このサービスは {date} に終了します`,
    'autoconfig-mcr': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} の接続設定は、自動的に生成されます。サービスがライブ状態になると、設定を変更できます。`,
    'count-port-lag': `- | (1 ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}) | ({count} ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS})`,
    'live-lag-disclaimer': `注意:${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} をライブ状態の${process.env.VUE_APP_EN_PRODUCTNAME_LAG} に追加する場合、新規 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} または ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} が他の ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} と同じロケーション、速度、${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} 可視性が自動的に割り当てられます。`,
    'lag-explanation': ` この ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を LACP プロトコルを有効化して作成し、${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_LAG}) で使用し、${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 数を指定して ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} グループに含めることができるようにします。`,
    'add-lag-ports-disclaimer': `これによって、複数の ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} がこの ${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_LAG}) に追加されます。表示される価格設定は、${process.env.VUE_APP_EN_PRODUCTNAME_LAG} に追加されるすべての ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} 用です。`,
    'port-diversity': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の多様性`,
    'diversity-unavailable-location': `この設定については、現在、冗長化は利用できません。冗長化要件に関するご相談については、<a href="mailto:${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}">${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}</a> にお問い合わせください。`,
    'diversity-explanation-html': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の多様性により、同じロケーションにある ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} が異なる多様性ゾーンに存在することが可能になります。冗長な ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} を多様性ゾーンに作成することで、単一の機器に影響を与えるイベントが原因でサービスが中断するリスクが軽減されます。詳細については、{link} を参照してください`,
    'select-lag-speed': `LACP を有効化する最低 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 速度は 10Gbps です`,
    'select-speed-diversity': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 速度を選択して、冗長化オプションを表示します。`,
    'diversity-lag-add': `- | 新規 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} が ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} の冗長化ゾーンに追加されます。- | 新規 ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} が ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} の冗長化ゾーンに追加されます。`,
    'diverse-pair': `2 つの {things} が設定され、異なる冗長化ゾーンに追加されます。`,
    'diverse-from': `{port} からの冗長化`,
    'port-diverse-from': `冗長化の元となる${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'first-port-name': `最初の {thing} の名前`,
    'second-port-name': `2 番目の {thing} の名前`,
    'delete-diverse-from': `- | 次の ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を、終了する ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} から冗長化するように設定します:| 次の ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} を、終了する ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} から冗長化するように設定します:`,
    'reset-diversity': `- | ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を終了する場合は、${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 冗長化設定が失われ、指定した冗長化ゾーンがなくなります。| ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を終了する場合は、一覧表示した ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} 冗長化設定が失われ、指定した冗長化ゾーンがなくなります。`,
    'mcr-prefix-filter': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} プレフィックス フィルター機能はインバウンドとアウトバウンドのプレフィックス ベースの BGP 経路フィルターをサポートしています。<br/><br/> <strong>サービスの設定時に、プレフィックス リストを追加して、設定できます。</strong>`,
    'bgp-default-state-tooltip': `新規作成の BGP 接続をデフォルトで有効化するかシャットダウンするかを設定します。設定を保存してすぐに設定した新規 BGP セッションをライブ状態にする場合は、デフォルトの「有効」を選択します。設定を保存するときに、設定した新規 BGP セッションをシャットダウン状態のままにする場合は、「シャットダウン」を選択します。`,
    'mve-software': `${process.env.VUE_APP_EN_PRODUCTNAME_MVE} ソフトウェア`,
    'new-prefix-filter-list': `新規`,
    'open-port': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を開く`,
    'prefix-filter-list': `プレフィックス フィルターのリスト`,
    'prefix-list-learn-more': `プレフィックス フィルターのリストの詳細`,
    'prefix-list-loaded-success': `選択したプレフィックス リストが正常に読み込まれました`,
    'prefix-list-loaded-fail': `選択したプレフィックス リストの読み込みに失敗しました`,
    'lacp-enabled': `LACP 対応`,
    'diverse-pair-label': `冗長化ペア`,
    'diverse-from-label': `次からの冗長化`,
    'download-loa': `LOA のダウンロード`,
    failed: `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の更新に失敗しました`,
    vendor: `ベンダー`,
    'mve-file-upload': `ファイルのアップロード`,
    'cloud-init-vmanage': `vManage システムからクラウド初期化ファイルを生成してアップロードします`,
    'mve-init': `初期化ファイル`,
    waiting: `構内配線を待機しています。`,
    'key-valid-until': `有効期限`,
    'service-keys': `サービス キー`,
    'lag-up': `この ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} サービスは稼働しています`,
    'lag-down': `この ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} サービスはダウンしています`,
    'key-saved': `サービス キーが正常に保存されました。`,
    'key-added': `サービス キーが正常に追加されました。`,
    'key-saved-message': `サービス キー [{description}] が保存されました。`,
    'key-add-error': `サービス キーを作成中にエラーが発生しました。`,
    'key-update-error': `サービス キーを更新中にエラーが発生しました。`,
    'minimum-term': `最低利用期間`,
    // CannotBeEdited セクションは太字であり、編集できない文字列を参照します
    'uneditable-asn-html': `この ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} の自律システム番号 (AS 番号) を指定するか、${process.env.VUE_APP_EN_COMPANYINFO_NAME} によって提供されるパブリック AS 番号 {defaultAsn} を使用します。AS 番号は、この ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} に接続されているすべての ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} での BGP ピアリング セッションに使用されます。${process.env.VUE_APP_EN_PRODUCTNAME_MCR} が注文されたら、{cannotBeEdited}。一部のパブリック クラウド サービスではパブリック AS 番号の使用が必須となっています。デフォルト値をオーバーライドする前に、お使いのクラウド プロバイダーに関するドキュメントを参照してください。`,
    'bgp-default-state': `BGP デフォルト状態`,
    'site-deployment': `このロケーションはまもなくライブ状態になります。今すぐ予約注文して、入手可能になったらすぐに LOA を受け取れるようにしてください。詳細やご相談については、担当のアカウント マネージャーにお問い合わせください。`,
    terms: `契約条件`,
    'end-date-display': `{productName} がライブ状態になると、契約終了日が表示されます。`,
    'change-term': `契約期間の変更`,
    'term-info': `{productName} の契約条件の詳細`,
    monthly: `月ごと`,
    'existing-lag': `既存の${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'thing-enabled': `{thing} 有効`,
    'thing-disabled': `{thing} 無効`,
    'address-family-label': `アドレス ファミリ`,
    'local-ip-label': `ローカル IP`,
    'peer-ip-label': `ピア IP`,
    'local-asn-label': `ローカル AS 番号`,
    'bgp-state-label': `BGP 状態`,
    'bgp-peer-filter-label': `BGP ピア フィルター`,
    'bgp-prefix-filter-import-label': `プレフィックス フィルターのインポート`,
    'bgp-prefix-filter-export-label': `プレフィックス フィルターのエクスポート`,
    'bgp-confirm-delete': `この IP アドレスは、BGP 接続または NAT IP アドレスで使用されています。削除すると、それらの構成も削除されます。続行しますか?`,
    'permit-list-label': `リストの許可`,
    'deny-list-label': `リストの拒否`,
    'new-list-label': `新規リスト`,
    'duplicate-list-label': `リストの複製`,
    'delete-list-label': `リストの削除`,
    'delete-list-confirm': `このリストを削除してもよろしいですか?`,
    'filter-advertise-default-label': `デフォルトによる広報`,
    'filter-advertise-exception-label': `例外による広報`,
    'bfd-switch-label': `BFD の使用`,
    'bfd-label': `BFD - 双方向フォワーディング検出`,
    'inbound-label': `インバウンド`,
    'outbound-label': `アウトバウンド`,
    'med-label': `MED (メトリック) - Multi-Exit Discriminator`,
    'bgp-prefix-filter-label': `BGP プレフィックス フィルター`,
    'bgp-connection-filter-label': `BGP 接続フィルター`,
    'no-connection-found': `{thing} 接続が見つかりませんでした`,
    'address-placeholder': `IP アドレス (IPv4/IPv6) を入力します`,
    'network-address-placeholder': `{thing} アドレスと CIDR を入力します`,
    'subnet-mask-range': `サブネット マスクの範囲`,
    'select-address': `アドレスの選択`,
    'prefix-list-label': `プレフィックス リスト`,
    'mve-explanation': `${process.env.VUE_APP_EN_PRODUCTNAME_MVE_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MVE}) 世界中のクラウド ホットスポットのロケーションに隣接する ${process.env.VUE_APP_EN_COMPANYINFO_NAME} によって提供およびホストされるパートナー SD-WAN ゲートウェイの BYOL インスタンスです。これにより、インターネットを介して ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ネットワーク リソースに制御されたアクセスができるようになります。物理的な ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を必要としませんが、希望する場合はポートに接続できます。すべてのライセンスと管理は、パートナーのソリューションを介して処理されます。`,
    'ssh-key': `SSH キー`,
    'ssh-key-rsa-help': `2048 ビットの RSA SSH 公開鍵`,
    'mve-appliance-licence': `アプライアンス ライセンス`,
    'mve-activation-code': `アクティベーション コード`,
    'mve-address': `Orchestratorアドレス`,
    // FQDN - 完全修飾ドメイン名
    'mve-address-placeholder': `IPv4/IPv6 または FQDN`,
    'mve-select-vendor': `ベンダー / 製品の選択`,
    'mve-cloud-init-file-upload': `Cloud-init ファイル`,
    'mve-cloud-init-file-upload-help': `Cloud-init ファイルの生成方法については、 {title} 製品ドキュメントを参照してください。`,
    'mve-appliance-licence-help': `アプライアンス ライセンス`,
    'key-valid': `有効`,
    'prefix-list-count': `{count} リスト | {count} リスト`,
    'mve-director-address': `Director アドレス`,
    'mve-controller-address': `Controller アドレス`,
    'mve-local-auth': `ローカル認証`,
    'mve-local-auth-help': `ローカル ID 文字列または E メール`,
    'mve-remote-auth': `リモート認証`,
    'mve-remote-auth-help': `リモート ID 文字列または E メール`,
    'serial-number': `シリアル番号`,
    'prefix-list-save-confirm': `プレフィックス リストの変更を保存しますか?`,
    'how-to-create-port-text': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の作成方法`,
    'how-to-create-port-paragraph': `{0} の手順については、${process.env.VUE_APP_EN_COMPANYINFO_NAME} のドキュメントをご覧ください。`,
    'aruba-orchestrator-name': `オーケストレーター アカウント名`,
    'aruba-orchestrator-name-tt': `Aruba Orchestrator インスタンスに関連付けられているアカウント名`,
    'aruba-account-key': `アカウント キー`,
    'aruba-account-key-tt': `上記のアカウント名にリンクされているアカウント キー`,
    'aruba-system-tag': `システム タグ`,
    'aruba-system-tag-tt': `Aruba Cloud Portal と Aruba Orchestrator に登録するときに使用されます`,
    'as-path-prepend-label': `AS パス追加`,
    'unbundled-mve-size-info': `このサイズには、{cpuCount} 個の vCPU、{ram} GB の RAM、{storage} GB のストレージが含まれます。`,
    'mve-appliance-mode': `アプライアンス モード`,
    'mve-appliance-mode-tooltip': `<p>アプライアンス モードによって構築の構成と管理方法が決まります。</p>
      <ul>
      <li><strong>自律モード</strong>: コマンドライン インターフェイス (CLI) 経由</li>
      <li><strong>SD-WAN モード</strong>: vManage 経由</li>
      </ul>
      <p>${process.env.VUE_APP_EN_COMPANYINFO_NAME} は、構築後のアプライアンス モードへの変更をサポートしていません。これによって構成の損失が引き起こされる可能性があります。</p>
      `,
    'mve-license-data': `ライセンス データ`,
    'mve-admin-password': `管理者パスワード`,
    'mve-admin-password-tooltip': `<p>仮想デバイスにアクセスするには、管理者パスワードを作成する必要があります。管理者パスワードは、</p>
      <ul>
      <li> 8 文字以上、</li>
      <li>大文字 1 文字以上、</li>
      <li>小文字 1 文字以上、</li>
      <li>数字 1 文字以上、</li>
      <li>記号 1 文字以上を含む必要があります</li>
      </ul>
      <p>管理者パスワードは安全に保管し、誰とも共有しないようにしてください。必要に応じて、設定されたデバイスへの初回ログイン時に管理者パスワードをリセットできます。</p>
      <p><strong>注意</strong>: ${process.env.VUE_APP_EN_COMPANYINFO_NAME} は顧客のパスワードを保管しません。</p>
      `,
    'mve-vnic-prompt-info': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} は、${process.env.VUE_APP_EN_PRODUCTNAME_MVE} の基本構成が完了すると、初期起動と構成のために ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} を注文するように求めます。`,
    'mve-vendor-vnic-count': `{vendor} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} には 1 つの vNIC があります。| {vendor} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} には 2 つの vNIC があります (vNIC 0 と vNIC 1)。| {vendor} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} には複数の vNIC があります。`,
    'mve-ip-transit-vxc-prompt-success': `CPU、RAM、ストレージなどの {vendorName} ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} のコンピューティング コンポーネントが正常に構成されました。`,
    'mve-ip-transit-vxc-prompt-recommend': `{vendorName} ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} の構成を完了させるためには、${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} 接続を作成することをお勧めします。`,
    'mve-ip-transit-vxc-prompt-docs': `詳細については、{link} を参照してください。`,
    'mcr-prefix-filter-support': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} プレフィックス フィルター機能はインバウンドとアウトバウンドのプレフィックス ベースの BGP 経路フィルターをサポートしています。`,
    'mcr-prefix-filter-configure': `サービスを構成するときに、プレフィックス リストを追加および構成できます。`,
    'cannot-edit': `編集不可`,
    'move-connections': `接続を移動`,
    'megaport-internet-restricted-location-reason': `この {productType} には、1 つ以上の ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} が関連付けられています。この {productType} と、関連する ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} は、同じリージョン内で設定する必要があります。`,
    'megaport-internet-restricted-location-workaround': `{productType} を別の地域で設定する場合は、最初に関連する ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} を削除してください。`,
    'mve-manage-locally': `ローカルで管理`,
    'mve-manage-locally-tooltip': `この ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} インスタンスが Firewall Management Center (ファイアウォール管理センター) に電話をかけるかどうかを示します。`,
    'mve-fmc-ip-address': `FMC IP アドレス`,
    'mve-fmc-ip-address-tooltip': `Cisco Secure FMC ネットワーク アドレス。これは、ホスト名、IPv4、IPv6 アドレスのいずれかになります。`,
    'mve-fmc-registration-key': `FMC 登録キー`,
    'mve-nat-id': `NAT ID`,
    'mve-nat-id-tooltip': `Firewall Management Controller (ファイアウォール管理コントローラー) を直接アドレス指定できない場合は、NAT ID を指定します。`,
    'mve-ion-key': `ION キー`,
    'mve-secret-key': `シークレット・キー`,
    'unbundled-mve-size-info-no-storage': `このサイズには、 {cpuCount} vCPU と {ram} GB の RAM が含まれます。`,
    'service-key-name': `{name} サービス キー`,
    'mve-cisco-vmanage': `vManage`,
    'mve-aviatrix-secure-edge': `Aviatrix Secure Edge`,
    'mve-select-version': `バージョンの選択`,
    'mve-version': `バージョン {version}`,
    'mve-service-details': `{vendor} サービスの詳細`,
  },
  'port-term': {
    'funky-1': `このサービスの期間を自動更新することはできません。`,
    'funky-2': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} の営業担当者にお問い合わせください。`,
    title: `{productType} 契約期間の設定`,
    choose: `{productType} 契約期間の選択`,
    'update-failure': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 期間の更新に失敗しました`,
    'update-error': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 期間の更新試行中にエラーが発生しました`,
    'update-unavailable': `選択された ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 期間は利用できません。`,
    'update-success': `更新された {productType} 期間`,
    'reterm-success': `- | ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の「{portName}」の期間を 1 か月に設定します | ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}の「{portName}」の期間を {count} か月に設定します`,
    reterm: `このサービスの新しい契約期間 (最低利用期間) はすぐに有効になり、新しい期間の割引は翌暦月の請求に適用されます。最低利用期間中の早期解約には、当社との合意に従って早期解約料金が発生します`,
    'confirm-new-term': `新規契約期間の開始`,
    'no-change': `変更なし`,
    'funky-3': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} パートナーにお問い合わせください。`,
    'no-minimum-term': `最低利用期間なし`,
    'minimum-term-tooltip-ports': `最低利用期間は、サービスの稼働後か、サービスの設定から 14 日後に開始されます`,
    'minimum-term-tooltip': `最低利用期間は注文時に開始`,
    'reterm-immediate': `このサービスの新規契約期間 (最低利用期間) は、すぐに有効になります。最低利用期間は、{start} に開始され、{end} に終了します。最低利用期間中の早期解約には、当社との合意に従って早期解約料金が発生します`,
    'option-price': `- | 毎月: {cost} /月 | {count} か月: {cost} /月`,
  },
  dashboard: {
    navigation: `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL} をナビゲートする基本情報と、最初の ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を作成する方法を学習します。`,
    'visit-marketplace': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT} にアクセス`,
    'deep-dive': `当社のサービスや、技術文書や記事を使用してネットワーク、クラウド接続、および ${process.env.VUE_APP_EN_PRODUCTNAME_IX} ソリューションを確立する方法を学習します。`,
    'ql-overview': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} イントロダクション`,
    'ql-vxc': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} で接続を設定する方法`,
    'ql-cloud': `クラウド サービス プロバイダーに接続する`,
    'ql-mcr': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} - レイヤー 3 接続の仮想ルーティング`,
    'marketplace-marketing': `サービス プロバイダー パートナーと企業顧客が集結し、サービスを共有するオンライン ハブ。${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} では、新しいリソースを発見し、新規顧客を開拓することができます。`,
    'marketplace-image': `クリックして、会社の ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT} プロファイルの画像を選択します。`,
    'get-started': `はじめに`,
    'quick-links': `クイック リンク:`,
    documentation: `${process.env.VUE_APP_EN_COMPANYINFO_NAME} ドキュメント`,
    video: `ビデオを見る`,
    'video-error': `ビデオを再生できません`,
    'video-error-message': `ブラウザーでビデオの再生がブロックされたようです。これは、調査のために開発チームに報告されました。ご不便をおかけして申し訳ありません。`,
  },
  menu: {
    dashboard: `ダッシュボード`,
    company: `会社`,
    'ix-looking-glass': `${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX} Looking glass`,
    'ix-telemetry': `${process.env.VUE_APP_EN_PRODUCTNAME_IX} テレメトリー`,
    'marketplace-profile': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} プロファイル`,
    'mcr-looking-glass': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} Looking glass`,
    'more-events': `追加イベントが利用可能です:`,
    'company-profile': `会社プロファイル`,
    'billing-markets': `ビリング マーケット`,
    'manage-users': `ユーザーの管理`,
    tools: `ツール`,
    'api-documentation': `API ドキュメント`,
    'api-key-generator': `API キー ジェネレータ`,
    'event-log': `イベント ログ`,
    'view-event-log': `イベント ログの表示`,
    events: `イベント`,
    'bug-report': `バグ レポートの送信`,
    'user-profile': `ユーザー プロファイル`,
    'notification-settings': `通知設定`,
    logout: `ログアウト`,
    'partner-admin': `パートナー管理者`,
    'company-security-settings': `セキュリティ設定`,
    'technical-support-details': `技術サポートの詳細`,
    'account-contacts': `アカウントの連絡先`,
    entitlements: `会社のエンタイトルメント`,
    feedback: `フィードバックの送信`,
  },
  marketplace: {
    'pluralize-provider-type': `プロバイダー タイプ |プロバイダー タイプ |プロバイダー タイプ`,
    'pluralize-locations': `ロケーション | ロケーション | ロケーション`,
    profile: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT} プロファイル`,
    'currently-private': `お客様の ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} プロファイルは現在プライベートになっているため、当社のグローバルな顧客ネットワークがお客様を見つけて、そのサービスに接続することができません。`,
    'no-profile': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT} プロファイルが現在設定されていません。`,
    visible: `プロファイルは、${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} に表示されます。`,
    hidden: `プロファイルは、${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} で他者には非表示になります。`,
    services: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} サービス`,
    'create-vxc': `この ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} に ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} を新規作成します`,
    'display-name': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} 表示名`,
    'contact-company': `{company} へのお問い合せ`,
    'email-sent-to': `E メールが {recipient} に送信されました`,
    'not-ready-publishing': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} プロファイルを公開する準備ができていないようです。「プロファイルの作成」をクリックしてページを完成させ、当社のグローバルな顧客ネットワークがお客様を見つけて、そのサービスに接続できるようにしてください。`,
    'easy-get-connected': `接続するのは簡単です。「プロファイルの編集」をクリックして、${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} で利用可能にします。`,
    'marketplace-intro': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} は、お客様のビジネスを当社のグローバルな顧客ネットワークに繋げます。このプラットフォームを使用すると、新規マーケットを活用して、これまで達成できなかった収益源を特定することができます。プロファイルを完成させて最新の状態に保つことで、販売の可能性と、購入意欲のある顧客へのブランド露出度が大幅に向上します。`,
    'duplicate-name-warning': `これによって、お客様の ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} に接続を希望する人々に混乱を引き起こす可能性があります。<br/><br/>保存してもよろしいですか?`,
    sending: `メッセージを送信しています...`,
    'general-info': `一般サービス情報`,
    'send-copy': `メッセージ コピーの受信を希望する`,
    message: `メッセージを入力します`,
    sent: `メッセージが送信されました`,
    'send-error': `メッセージを送信中にエラーが発生しました`,
    target: `接続ターゲット`,
    'connection-from': `接続ソース`,
    filter: `プロバイダー タイプでフィルタリング`,
    'select-types': `必要なタイプとサブタイプを選択します`,
    'clear-filter': `フィルターのクリア`,
    'hide-message': `このメッセージを非表示`,
    'hide-today': `今日だけ`,
    'hide-week': `1 週間`,
    'hide-forever': `永久`,
    'edit-profile': `プロファイルの編集`,
    'save-profile': `プロファイルの保存`,
    'create-profile': `プロファイルの作成`,
    'sort-alpha': `A - Z`,
    'sort-location-count': `ロケーションのカウント`,
    'grid-display': `グリッド表示`,
    'list-display': `リスト表示`,
    'company-name-filter': `会社名フィルター`,
    'select-speeds': `速度の選択`,
    'select-provider-types': `プロバイダー タイプの選択`,
    'select-countries': `国の選択`,
    'loading-companies': `会社を読み込んでいます`,
    'all-services': `すべて`,
    'selected-services': `選択されたサービス`,
    'profile-message-hidden': `プロファイル メッセージが非表示になりました`,
    'hidden-day': `このメッセージは 1 日、非表示になっています。`,
    'hidden-week': `このメッセージは 1 週間、非表示になっています。`,
    'hidden-message': `このメッセージは本画面には表示されなくなりますが、「会社」> 「${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} プロファイル」メニュー オプションで ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT} プロファイルを作成または編集できます。`,
    'hide-profile': `プロファイル メッセージを非表示`,
    'hidden-permanently': `このメッセージは完全に非表示になっています。`,
    'upload-logo': `ロゴのアップロード`,
    'change-logo': `ロゴの変更`,
    social: `ソーシャル`,
    headquarters: `本社`,
    'address-1': `住所 1`,
    'address-2': `住所 2`,
    city: `市町村`,
    postcode: `郵便番号`,
    'point-click': `ポイント、クリック、接続。`,
    'no-edit-permission': `プロファイルを編集する権限がありません。編集するには、会社の管理者ユーザーにお問い合わせください。`,
    'loading-editor': `プロファイル エディターを読み込んでいます...`,
    'no-create-permission': `プロファイルを作成する権限がありません。プロファイルを作成するには、会社の管理者にお問い合わせください。`,
    'profile-url': `プロファイルは次の URL で利用可能です:`,
    contact: `連絡先`,
    visibility: `プロファイルの可視性`,
    'company-bio': `会社情報`,
    'bio-length': `会社情報 (200 文字)`,
    'contact-email': `連絡先 E メール`,
    'recently-viewed': `最近閲覧したプロバイダー`,
    'similar-providers': `類似したサービス プロバイダー`,
    'duplicated-names': `次のサービス表示名が重複しています:`,
    'duplicate-name': `重複した表示名`,
    description: `サービスの説明 (200 文字)`,
    'service-contact-email': `サービスの連絡先 E メール`,
    'service-types': `サービス タイプ`,
    'company-profile': `会社 {company} の ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} 会社プロファイル`,
    'live-time': `{time} のライブ`,
    'company-at-location': `{location} の{company}`,
    state: `都道府県`,
    country: `国`,
  },
  'mcr-looking-glass': {
    'no-services-message': `構成された ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MCR}) サービスがありません。`,
    'no-bgp-sessions': `この ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} には BGP セッションがありません`,
    'no-services': `サービスが見つかりません`,
    selection: `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} の選択`,
    'routes-table': `経路テーブル`,
    'bgp-table': `BGP テーブル`,
    'all-reachable': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} ルーティング テーブル内のすべての到達可能なネットワーク。`,
    'all-routes': `すべての経路`,
    'routes-by-ip': `IP 別の経路`,
    find: `経路の検索`,
    loading: `経路を読み込んでいます`,
    'enter-ip': `IP かネットワーク アドレスを入力して、経路を探します`,
    'no-routes': `この IP アドレスには経路がありません`,
    'no-bgp': `このインターフェイスには BGP 接続がアタッチされていません`,
    neighbour: `近隣経路`,
    'bgp-sessions': `BGP セッション`,
    'bgp-sessions-tooltip': `選択した ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} の ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} で設定されたすべての BGP セッション。`,
    'filter-sessions': `文字列を入力して、セッションをフィルタリングします`,
    'toggle-details': `追加詳細の切り替え`,
    'total-routes': `経路合計`,
    'filter-routes': `テキストを入力して、経路をフィルタリングします`,
    advertised: `広報済み`,
    received: `受信済み`,
    'sort-by-thing': `{thing} で並べ替え`,
    'clear-filter-routes': `経路フィルタリングのクリア`,
    'contact-support': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} がもたらす利点についてのご相談は、サポート チームにお問い合わせください。`,
    'service-could-not-be-loaded': `サービスを読み込めませんでした ({errorStatus})`,
    'service-loading-timeout': `申し訳ございません。この情報の読み込み中に問題が発生したようです。問題が解決しない場合は、サポートチーム (${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}) までご連絡ください。`,
  },
  'ix-flows': {
    'no-ix-found': `${process.env.VUE_APP_EN_PRODUCTNAME_IX} サービスが見つかりません`,
    'no-ix-message': `${process.env.VUE_APP_EN_PRODUCTNAME_IX} サービスがありません。${process.env.VUE_APP_EN_COMPANYINFO_NAME} ${process.env.VUE_APP_EN_PRODUCTNAME_IX} サービスにご興味がある場合は、営業担当者にお問い合せください。`,
    'date-select': `選択された日付`,
    'select-peer': `ピアを選択します`,
    'no-data': `このピアにデータはありません`,
    'flow-type': `フロー タイプ`,
    'loading-peers': `ピアを読み込んでいます`,
    'sort-by': `並べ替え基準`,
    'filter-by-name': `名前でフィルタリング`,
    'total-peers': `合計ピア: {count}`,
    'top-5': `上位 5 ピア ({direction})`,
    'volume-in': `ボリューム (イン)`,
    'volume-out': `ボリューム (アウト)`,
    'load-error': `このデータの読み込みに問題があります。再読み込みして、もう一度お試しください。`,
  },
  checkout: {
    'minimum-term-months': `最低利用期間なし | 月単位 | 最低利用期間:{count} か月`,
    'unknown-validation-error': `不明な検証エラー: 「{message}」`,
    'hide-promo': `非表示`,
    'add-promo': `コードの追加`,
    order: `注文サービス`,
    'set-up-company': `会社を設定します`,
    'register-markets': `マーケットの登録`,
    'order-now': `今すぐ注文`,
    'need-setup': `サービスを注文する前に、アカウントの設定を完了する必要があります。`,
    'need-company': `会社情報を設定する必要があります。`,
    'need-company-permissions': `会社情報が設定されていませんが、設定する十分な権限がありません。会社の管理者にお問い合わせください。`,
    'need-markets': `必要な会社マーケットを有効化する必要があります。`,
    'need-markets-permissions': `会社マーケットが有効化されていませんが、有効化する十分な権限がありません。会社の管理者にお問い合わせください。`,
    validating: `注文を検証しています`,
    deploying: `構築しています...`,
    'no-term': `期間なし`,
    promo: `プロモーション`,
    'add-promo-code': `プロモーション コードの追加`,
    'promo-code': `プロモーション コード`,
    'checking-promo': `プロモーション コードを確認しています`,
    'promo-validated': `コードが検証されました`,
    'no-deploy': `注意:カートを作成して保存することはできますが、アカウントのアクセス権限により、サービスの注文が制限されます。`,
    'invalid-code': `コードが無効です`,
    'code-not-confirmed': `プロモーション コードを確認できませんでした`,
    'save-failed': `設定済みサービスの保存に失敗しました`,
    'error-deploying': `サービスを構築中にエラーが発生しました。`,
    'unknown-error': `構築を試行中に不明なエラーが発生しました`,
    deployed: `サービスが正常に構築されました。`,
    success: `注文 ID {orderId} によって正常にサービスが構築されました:`,
    'null-code': `プロモーション コードを入力してください`,
    'capture-tech-support-details': `技術サポートの詳細のキャプチャ`,
    'set-up-tech-support-details': `技術サポートの詳細`,
    'need-tech-support-details': `ご注文の前に、技術サポートの連絡先が必要です。`,
    'need-tech-support-details-by-admin': `技術サポートの連絡先は、会社の管理者が入力する必要があります。`,
    'missing-tech-support-details': `技術サポートの連絡先が不明です`,
    'deleting-configuration': `保存した設定を削除しています...`,
    'refreshing-services': `サービスを更新しています...`,
  },
  sidebar: {
    'enable-market': `{country} マーケットの有効化`,
    'configured-services': `設定済みサービス`,
    'save-configured-services': `設定済みサービスの保存`,
    'save-configured-services-later': `これらの設定済みサービスを後日利用できるように保存します。`,
    'saved-configurations-alert': `保存された設定は全社で利用可能になるため、社内の誰でも見ることができます。`,
    'existing-configuration-warning': `この名前の設定は、既に存在します。同名で保存すると、以前に保存した設定が上書きされます。`,
    'clear-configured-services': `設定済みサービスをすべてクリア`,
    'save-configured-name': `設定の名前を入力します`,
    'saved-configurations': `保存された設定`,
    'config-saved-title': `設定 {name} が保存されました`,
    'config-saved-message': `設定 {name} ({serviceOrder}) が正常にサーバーに保存されました。`,
    'config-save-error-title': `設定 {name} を保存中にエラーが発生しました`,
    'config-deleted-title': `設定は削除されました`,
    'config-delete-error-title': `設定 {name} を削除できません。`,
  },
  'billing-markets': {
    'vat-characters': `VAT を {length} 文字で入力します`,
    'purchase-order-market': `請求書発注書番号は、請求書生成時に {country} マーケットに関連するすべての請求書のヘッダーに表示されます。`,
    'due-date-date': `期日: {date}`,
    'remove-card-ending': `{numbers} で終了するカードを削除`,
    'edit-card-ending': `{numbers} で終了するカードの編集オプション`,
    'pay-card-ending': `{numbers} で終了する登録済みカードを使って支払う`,
    'remove-card-confirmation': `{numbers} で終了するクレジット カードを ${process.env.VUE_APP_EN_COMPANYINFO_NAME} {marketName} ビリング マーケットから削除してもよろしいですか?`,
    'dont-accept-currency': `このマーケットでは、現在、クレジット カードによる {currency} 支払いを受け付けていません。その他の支払い方法については、請求書を参照してください。`,
    title: `会社のビリング マーケット`,
    enabled: `有効化されたマーケット`,
    none: `有効化されたマーケットがありません`,
    options: `オプション`,
    'view-invoices': `請求書の表示`,
    payment: `支払う`,
    edit: `マーケットの編集`,
    view: `マーケットの表示`,
    'download-invoices': `請求書をすべてダウンロード`,
    'download-json': `JSON としてダウンロード`,
    'download-csv': `CSV のダウンロード`,
    'enable-bm': `ビリング マーケットの有効化`,
    'none-available': `マーケットがありません`,
    enable: `有効化`,
    market: `マーケット`,
    invoices: `請求書`,
    'next-invoice': `次の請求書`,
    disclaimer: `(速度の変更がないことを想定、税抜き)`,
    balance: `アカウント残高`,
    'inc-tax': `(税込み)`,
    'download-statement': `ステートメントのダウンロード`,
    'no-bills': `このマーケットには、請求されたサービスはありません。`,
    'invoice-no': `請求書 #`,
    'issue-date': `発行日`,
    total: `合計`,
    payments: `支払い`,
    'no-stripe': `Stripe 支払いは現在利用できません`,
    'cc-history': `クレジット カードの支払い履歴`,
    'no-payments': `支払いの記録がありません`,
    description: `説明`,
    'payment-date': `支払い日`,
    'unavailable-market': `このマーケットでは、クレジット カードによる支払いを受け付けていません。その他の支払い方法については、請求書を参照してください。`,
    'billing-currency': `請求通貨`,
    'currency-fixed': `請求通貨を変更できません`,
    'no-currency-change': `マーケットの設定後は、請求通貨を変更できません`,
    language: `請求書言語`,
    'language-future-invoices': `注意:請求書言語の変更は、将来の請求書のみに影響します。`,
    'billing-name': `請求先の名前`,
    'billing-phone': `請求先の電話`,
    'phone-format': `国際電話番号例: +61 4321 1234`,
    'billing-email': `連絡先の E メール`,
    'email-format': `単一の E メール アドレスまたは E メール アドレスのコンマ区切りリストのいずれかになります`,
    'billing-address': `請求先の住所`,
    'address-2': `住所 2`,
    city: `市町村`,
    province: `地方`,
    state: `都道府県`,
    postcode: `郵便番号`,
    'select-or-type': `フィルター オプションの選択または入力`,
    'company-registration': `法人番号`,
    'company-tooltip': `購入する法人に関連付けられた会社登録番号。`,
    'vat-registration': `VAT に登録していますか?`,
    'vat-id': `VAT ID 番号`,
    'vat-number': `登録された VAT ID 番号。`,
    po: `請求書発注書番号`,
    currency: `マーケット通貨`,
    'company-identifier': `会社 ID`,
    'contact-name': `連絡先の名前`,
    'phone-number': `電話番号`,
    address: `住所`,
    'due-unavailable': `利用不可`,
    vat: `VAT`,
    'register-card': `カードの登録`,
    'register-card-status': `カード設定ステータス: {status}`,
    'register-card-edit': `登録済みカードの編集`,
    'register-card-fail': `カードの登録に失敗しました`,
    'register-card-success': `カードが正常に登録されました。`,
    'register-card-fail-message': `カードの登録に失敗しました。`,
    'register-card-message': `カードを ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ビリング マーケットに登録して、自動支払い (請求書の発行後) または 1 回限りの手動支払いを行います。`,
    'registering-card': `カードを登録しています...`,
    'remove-card': `カードの削除`,
    'removing-card': `カードを削除しています...`,
    'remove-card-fail': `カードを削除中にエラーが発生しました`,
    'remove-card-fail-message': `カードの削除に失敗しました: {error}`,
    'update-card': `カードの更新`,
    'update-card-success': `カードが正常に更新されました。`,
    'update-card-fail': `カードを更新中にエラーが発生しました`,
    'update-card-fail-message': `カードの更新に失敗しました: {error}`,
    'remove-card-success': `カードが正常に削除されました。`,
    'save-card-fail': `カードの保存に失敗しました`,
    'save-card-fail-message': `カード情報を保存できませんでした: {error}`,
    'payment-once': `1 回限りの支払いを行う`,
    'payment-once-make': `アカウントに 1 回限りの支払いを行います。`,
    'payment-processing': `支払いを処理しています`,
    'payment-confirm-fail': `支払い設定の確認に失敗しました`,
    'payment-error': `支払いの予期せぬエラーが発生しました`,
    'payment-success': `支払いが成功しました。`,
    'payment-success-message': `{amount} {currency} がマーケット [{market}] に支払われました`,
    'card-number': `カード番号`,
    'card-expiry': `有効期限`,
    'card-cvc': `CVC`,
    'auto-payments-on': `自動支払いオン`,
    'auto-payments-off': `自動支払いオフ`,
    'payment-amount': `支払い額`,
    'payment-description': `支払いの説明`,
    'payment-failed': `支払いに失敗しました`,
    'payment-create-failed': `支払い方法の作成に失敗しました`,
    'payment-create-failed-message': `Stripe 支払い方法を作成できませんでした: {error}`,
    'payment-attempt-failed': `支払いの試行に失敗しました:{error}`,
    'payment-market-fail': `マーケット [{market}] に行われた支払いはありません。{error}`,
    'processing-fees': `クレジット カードの決済手数料は支払い金額に加算され、次の請求書に反映されます。${process.env.VUE_APP_EN_COMPANYINFO_NAME} では、サービス プロバイダーが徴収する手数料をそのままお知らせします。`,
    'account-balance': `現在のアカウント残高は | です (税込み)。`,
    'enable-markets': `マーケットの有効化`,
    'services-no-market': `まだ有効化されていないマーケットのサービスが設定されました。`,
    'enable-markets-admin': `会社の管理者が次のビリング マーケットを有効化する必要があります:`,
    'no-payment-method': `支払い方法のデータを受信していません。`,
    'stripe-error': `Stripe エラー`,
    'update-success': `マーケット「{country}」への供給が正常に更新されました。`,
    supply: `マーケット供給`,
    'create-success': `マーケット「{country}」への供給が正常に作成されました。`,
    'payment-docs-html': `クレジット カード決済手数料に関する追加情報は、当社のサービス プロバイダーである Stripe ({link}) から入手できます。`,
    'cc-autopay': `自動支払い処理は、毎月 20 日にアカウントの未払い残高に対して行われます。`,
    'cc-support-html': `クレジット カードの支払いに関してご質問がある場合は、{email} までお問い合わせください。`,
    download: `ダウンロード`,
    'due-date': `期日`,
    'invoice-date': `請求書の日付`,
    'invoice-amount': `請求書の金額`,
    'open-balance': `期首残高`,
    'date-range': `日付範囲`,
    'download-pdf': `PDF のダウンロード`,
    'download-invoices-info': `注意:「請求書をすべてダウンロード」機能はサポートされなくなりました。「請求書の表示」アイコンをクリックして日付範囲を選択し、個々の請求書をダウンロードしてください。`,
    'billing-address-entry-rules': `この住所は、${process.env.VUE_APP_EN_COMPANYINFO_NAME} のサービスを調達し、利用するための重要な意思決定者がいる事業の場所にする必要があります`,
    UK_MOBQ: `英国で当社のサービスを利用しますか?`,
    UK_MOBQ_TOOLTIP: `これらのサービスを使用する担当者が英国にいるかどうかを確認してください`,
    AU_MOBQ: `オーストラリアの GST に登録していますか?`,
    AU_MOBQ_TOOLTIP: `サービスを利用する法人または支店 (${process.env.VUE_APP_EN_COMPANYINFO_NAME} が請求書を発効する法人の関連会社など) がオーストラリア GST に登録されているかどうかを確認してください`,
    SG_MOBQ: `シンガポールの GST に登録していますか?`,
    SG_MOBQ_TOOLTIP: `サービスを利用する法人または支店 (${process.env.VUE_APP_EN_COMPANYINFO_NAME} が請求書を発効する法人の関連会社など) がシンガポール GST に登録されているかどうかを確認してください`,
    'company-type-mexico': `会社タイプ`,
    'company-type-mexico-tooltip': `納税者の種類 (個人、法人、外国法人)`,
    'tax-regime-code-mexico': `税制`,
    'tax-regime-code-mexico-tooltip': `税制 - メキシコ税務管理局 (SAT) に登録されている現在の財政制度コードを選択します。`,
    'sat-purpose-mexico': `課税目的`,
    'sat-purpose-mexico-tooltip': `課税目的`,
    'rfc-mexico': `RFC 番号`,
    'rfc-mexico-tooltip': `メキシコ税務管理局 (SAT) が発行した現在の Federal Taxpayer Registry を割り当てます。`,
    'fusf-interstate-traffic-certification': `連邦 USF 州間交通証明書 *`,
    'fusf-recommended-text': `* 詳しい内容についてはツールチップをお読みいただくことを強くお勧めします`,
    'fusf-tooltip-content': `顧客が別途確認しない限り、同じ州内にサービス エンドポイントがあるデータ伝送製品* の場合、Megaport は連邦通信委員会 (FCC) によって、顧客のトラフィックが最終的に顧客のより広範なネットワークの一部として州境を越えると想定する義務があります。<br/><br/>
      チェックボックスをオンにすることで、顧客は Megaport から購入したすべての当該製品に関して、トラフィックの <strong>10% 以上</strong>が州をまたがるものであることを証明します。この場合、サービスは連邦ユニバーサル サービス基金 (FUSF) 料金の対象となります。<br/><br/>
      チェックボックスを選択しないことにより、顧客は当該製品を通じて送信されるトラフィックの <strong>10% 以下</strong>が州をまたがるものであることを証明し、FUSF は適用されません。<br/><br/>
      上記の証明を提供したアカウント ユーザーの氏名および役職の電子記録が Megaport の記録として作成され、監査目的で使用される場合があります。<br /><br />
       *Megaport のデータ伝送製品は、VXC、Megaport Internet、Internet Exchange で構成されています。`,
  },
  users: {
    'configure-destination-notifications': `{destination} 通知の設定`,
    'editing-user-name-you': `ユーザー {firstName} {lastName} {you} を編集しています`,
    'user-not-found-redirect': `ユーザー {userId} が見つかりませんでした「ユーザーの管理」ページにリダイレクトしています。`,
    'position-not-found': `このユーザーには、システムでサポートされなくなったロール ({position}) があります。利用可能なオプションから選択します。`,
    'invalid-email': `{email} は有効な E メール アドレスではありません`,
    deactivated: `非アクティブ化されたユーザーの表示`,
    notifications: `通知`,
    destinations: `利用可能な宛先`,
    'no-destinations': `宛先がありません`,
    'detailed-notifications': `詳細通知の表示`,
    filter: `フィルター`,
    'no-match': `一致するユーザーがありません`,
    active: `ユーザー アカウントはアクティブです`,
    disabled: `ユーザー アカウントは無効化されています`,
    you: `(お客様)`,
    new: `新規ユーザー`,
    'logged-in-as-user': `現在、このユーザーとしてログインしています。「ユーザー プロファイル」ページでユーザーの詳細を変更できます。`,
    state: `ユーザーの状態`,
    'technical-admin': `テクニカル　アドミン`,
    finance: `財務`,
    'financial-contact': `財務担当者`,
    role: `ユーザーのロール`,
    'role-not-found': `ロールが見つかりません`,
    'user-not-found': `ユーザーが見つかりません`,
    'missing-info': `情報がありません`,
    'change-email': `E メールの変更`,
    'changing-email': `E メールを変更しています...`,
    'new-email': `新規 E メール`,
    'email-preferences': `E メール設定`,
    'saving-email-preferences': `E メール設定を保存しています...`,
    'receive-newsletter': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} ニュースレターを受け取る`,
    'receive-promotional-material': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} 販促資料を受け取る`,
    'change-password': `パスワードの変更`,
    'changing-password': `パスワードを変更しています...`,
    'old-password': `旧パスワード`,
    'new-password': `新規パスワード`,
    'retype-new-password': `新規パスワードを再入力します`,
    'saving-user-profile': `ユーザー プロファイルを保存しています`,
    'account-password': `アカウント パスワード`,
    username: `ユーザー名`,
    'loading-qr-code': `QR コードを読み込んでいます`,
    'account-activity': `アカウントのアクティビティ`,
    'account-activity-none': `アクティビティ データがありません`,
    'account-activity-loading': `アクティビティ データを読み込んでいます...`,
    'activity-data-load-fail': `アクティビティ データの読み込みに失敗しました`,
    'save-failed': `保存に失敗しました`,
    'save-user-profile-failed': `ユーザー プロファイルの保存に失敗しました`,
    'save-email-failed': `E メールの変更に失敗しました`,
    'save-email-preference-failed': `E メール設定の保存に失敗しました`,
    'profile-saved': `プロファイルが正常に更新されました。`,
    'email-change-success': `E メール設定が変更されました。`,
    'save-password-failed': `パスワードの保存に失敗しました`,
    'save-password-failed-message': `更新されたパスワードの保存に失敗しました`,
    'saving-user-profile-error': `プロファイルを更新中にエラーが発生しました`,
    profile: `プロファイル`,
    'user-name': `ユーザー {firstName} {lastName}`,
    'verification-email': `確認 E メールが新規アドレスに送信されました`,
    'verification-message': ` E メールにある指示に従います。`,
    'user-role': `ユーザー ロール`,
    'viewing-user-name-you': `ユーザー {firstName} {lastName} {you} を表示中`,
    'cant-edit-invite-pending': `このユーザー プロファイルは、ユーザーが招待を受け入れるまで編集できません。すぐに変更が必要な場合は、ユーザーを削除して再度招待してください。`,
    'deleting-user': `ユーザーを削除しています...`,
    'disable-mfa': `MFA の無効化`,
    'enable-mfa': `MFA の有効化`,
    'reset-mfa': `MFA のリセット`,
    'disable-mfa-dialog': `多要素認証の無効化`,
    'enable-mfa-dialog': `多要素認証の有効化`,
    reset: `リセット`,
    'mfa-long': `多要素認証`,
    'reset-mfa-dialog': `多要素認証のリセット`,
    'reset-mfa-success': `多要素認証が正常にリセットされました`,
    'reset-mfa-failed': `多要素認証のリセット中にエラーが発生しました`,
    'reset-mfa-dialog-desc': `多要素認証のシークレットをリセットしようとしています。QR コードをスキャンして確認すると、新しいシークレットがアクティブになり、デバイス上の古いシークレットはすべて無効となります。`,
    'mfa-enforced-disable-tooltip': `会社の管理者が MFA を強制するグローバル ポリシーを設定しました。この状態で MFA を無効化することはできません。`,
    'position-header': `ロール`,
    'mfa-header': `MFA`,
    'mfa-tooltip-not-set': `ユーザーが多要素認証を設定していません`,
    'mfa-tooltip-set': `ユーザーが多要素認証を設定しました`,
    set: `設定`,
    'not-set': `未設定`,
    enforced: `強制`,
    optional: `オプション`,
    'partner-only': `パートナーのみ`,
    'partner-only-tooltip': `パートナー アカウントにのみ関連付けられた E メール通知を構成します`,
    'partner-and-managed': `パートナーとマネージド`,
    'partner-and-managed-tooltip': `パートナーおよびすべてのマネージド アカウントに関連付けられた E メール通知を構成します`,
    'company-admin-reset-mfa-confirm-title': `多要素認証をリセットしてもよろしいですか?`,
    'company-admin-reset-mfa-confirm-message': `リセットをリクエストする {username} が実際の人物であり、マシンツーマシン統合に使用されるアカウントでないことを確認してください。MFA をリセットすると、ユーザーはログイン時に資格情報をリセットするよう求められます。`,
    'company-admin-reset-help-text': `このユーザーがアカウントをリセットできるようにします。この場合、ユーザーは新規デバイスを登録する必要があります`,
    'mfa-alt-tag': `カメラを使用して、QR コードをスキャンします`,
    'mfa-app-suggestion': `Google Authenticator は当社が推奨する認証アプリです。`,
    'mfa-token': `生成されたワンタイム トークンを入力します`,
    'mfa-loading-failed': `QR コードの読み込みに失敗しました、`,
    'mfa-disabled': `多要素認証が無効化されました`,
    'enable-mfa-success': `多要素認証が正常に有効化されました。`,
    'disable-mfa-fail': `多要素認証を無効化する際にエラーが発生しました`,
    'view-user-actions': `すべてのユーザー アクションを表示`,
    'mfa-warning-1': `多要素認証アプリに登録したアカウントが削除されていることを確認します。`,
    'mfa-warning-2': `無効にするとログアウトされます。`,
    'loading-users': `会社ユーザーを読み込んでいます...`,
    'sso-compatibility': `SSO の互換性`,
    'sso-enabled-tooltip': `ユーザーの E メール ドメインは SSO にリンクされており、ログインにはデフォルトで SSO が利用されます`,
    'sso-enforced-no-access-tooltip': `SSO が強制されており、ユーザーは SSO と互換性がありません。アクセス禁止`,
    'sso-optional-can-login-tooltip': `ユーザーの E メール ドメインは SSO と互換性がありませんが、ユーザーは他の方法でログインできます`,
    'active-api-keys-found': `アクティブな API キーが見つかりました`,
    'active-api-keys-inactive-if-continue': `このユーザーのアクティブな API キーが見つかりました。続行すると、これらは非アクティブになります。すべての API キーは、API キー ジェネレータのツールで確認できます。`,
    'active-api-keys-input-label': `続行するには、「Inactivate」と入力して「Continue (続行)」をクリックするか、「Cancel(キャンセル)」をクリックして中断します。`,
    'active-api-keys-error': `「Inactivate」と入力して続行します`,
    'active-api-keys-review': `このユーザーによって生成された API キーが、会社と ${process.env.VUE_APP_EN_COMPANYINFO_NAME} 間の重要なシステム統合で使用されているかどうかを確認することをお勧めします。`,
  },
  'api-keys': {
    'description-intro': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} API に直接アクセスする必要がある場合は、API キーが必要です。`,
    'description-html': `API キーは、それらが生成された環境でのみ有効です。現在、{environment} 環境にログインしているため、ここで作成され、一覧表示されたキーは {environment} 環境で機能します。別の環境にアクセスするためにキーが必要な場合は、その環境で ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_SHORT} にログインし、そこでキーを生成します。`,
    'learn-more-html': `環境の詳細については、{link} を参照してください`,
    forbidden: `ビリング マーケットを有効化して設定された会社の管理者でない限り、API キーを作成したり、管理したりすることはできません`,
    'key-description': `API キーは、使用状況と請求の目的でリクエストを認証する固有の識別子です。キーを使用して、API リクエストを認証するためのトークンを生成します。`,
    'key-generated-message': `API キー {key} が生成されました`,
    'key-updated-message': `API キー {key} が更新されました`,
    'key-deleted-message': `API キー {key} が削除されました`,
    'key-regenerated-message': `API キー {key} の新規シークレットが生成されました`,
    'key-dialog-description': `API キーが正常に作成されました。新規 API キーと API キーのシークレットをコピーし、それらを使用して API トークンを生成します`,
    'role-change': ` <strong>{oldRole}</strong> のロールを <strong>{newRole}</strong> に変更してもよろしいですか?<br/><br/>会社の管理者は完全な API アクセス権を持ち、サービスを作成、管理、キャンセルできます。読み取り専用では、使用状況データと設定の詳細を確認できますが、変更を行うことはできません。`,
    'delete-key': `このキーを削除してもよろしいですか?削除されると、キーを使って新規トークンを生成できなくなります。`,
    'regenerate-secret': `シークレットを再生成すると、旧シークレットを使用したすべてのセッションがキャンセルされます。このキーを使用するには、新規シークレットを使用してトークンを生成する必要がありますシークレットを再生成してもよろしいですか?`,
    'api-keys': `API キー`,
    'read-only': `読み取り専用は最も制限的な役割です。読み取り専用ユーザーは、安全性と機密性を保ちたいサービスの詳細を表示できることに注意してください。`,
    admin: `会社の管理者`,
    'unknown-role': `不明なロール`,
    'your-api-key': `お客様の API キー`,
    'api-key': `API キー`,
    secret: `API キー シークレット`,
    'secret-message': `このシークレットを再生成したり再表示したりすることはできません。安全な場所に保管するようにしてください。`,
    history: `API キー履歴`,
    'history-order': `最新の変更が一番上にあります`,
    'edited-by': `編集者`,
    'update-date': `最終更新`,
    role: `ロール`,
    created: `キーが作成されました`,
    updated: `キー が更新されました`,
    regenerated: `シークレットが再生成されました`,
    'unknown-action': `不明なキーのアクション`,
    'unknown-user': `不明なユーザー`,
    'unable-to-read': `API キーを読み取れません`,
    key: `キー`,
    'generate-key': `キーの生成`,
    'generate-label': `API キーの生成`,
    generating: `API キーを生成しています`,
    'read-only-impersonate': `別のユーザーになりすましているため、アカウントのキーの読み取り専用アクセス権しかありません。`,
    'success-generate': `API キーが正常に生成されました。`,
    'failed-generate': `API キーの生成に失敗しました`,
    'success-update': `API キーが正常に更新されました。`,
    'failed-update': `API キーの更新に失敗しました`,
    'success-delete': `API キーが正常に削除されました。`,
    'failed-delete': `API キーの削除に失敗しました`,
    'active-keys': `アクティブな API キー`,
    'no-active-keys': `現在、アクティブなキーはありません。キーを生成して開始します。`,
    'active-tooltip': `アクセス トークンを生成するには、キーとシークレットが必要です。キーを生成したとき、またはキーの新規シークレットを生成したときに、一度だけシークレットが表示されます。`,
    'date-created': `作成日`,
    'added-by': `追加者`,
    'loading-user-data': `ユーザー データを読み込んでいます`,
    'loading-api-keys': `既存の API キーを読み込んでいます`,
    'deleting-api-keys': `API キーを削除しています`,
    'regenerating-secret': `シークレットを生成しています`,
    'saving-changes': `変更を保存しています`,
    'regenerate-secret-label': `シークレットの再生成`,
    'view-history': `履歴の表示`,
    'confirm-role-change': `ロール変更の確認`,
    'delete-key-label': `キーの削除`,
    'company-admin': `会社管理者ユーザーは、すべてのユーザー権限にアクセスできます。会社管理者ユーザー数は完全なアクセスを必要とするユーザーのみに制限することをお勧めしますが、冗長性のために少なくとも 2 人を定義します。`,
    'technical-admin': `この役割は、注文の作成と承認の方法を熟知している技術ユーザーを対象としています。`,
    'technical-contact': `この役割は、サービスの設計と変更の方法を熟知しているが、注文を承認する権限のない技術ユーザーを対象としています。`,
    finance: `財務ユーザーは、サービスを削除または承認した場合のアクションの結果を理解し、組織内で財務上の責任を負う必要があります。{note}`,
    'financial-contact': `このユーザー ロールは財務の役割に似ていますが、注文の発注と承認、サービスの削除、またはサービス キーの管理はできません。`,
    'read-only-short': `読み取り専用`,
    'token-expiry': `トークンの有効期限 (分)`,
    'expiry-min': `最小 5 分間`,
    'expiry-max': `最大 24 時間 (1440 分間)`,
    'api-resource': `API リソース`,
    action: `アクション`,
    'name-min': `最小 3 文字`,
    'name-max': `最大 90 文字`,
    'name-chars': `ダッシュとアンダースコア以外の特殊文字は使用できません`,
    'name-invalid': `名前が無効です`,
    'api-documentation': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} API ドキュメント`,
    'finance-note': `注意:直接顧客は、サービスの作成中に、構築に先立ちサービスのコストを確認できます。${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_SHORT} でサービスを選択し、「Details (詳細)」タブを表示して、構築済みサービスのコストを確認することもできます。`,
    'api-role': `API`,
    'key-active-tooltip': `キーはアクティブです`,
    'key-inactive-tooltip': `ユーザーが非アクティブ化されたため、キーは非アクティブです。このキーを作成したユーザーが再度アクティブにされない限り、このキーは使用できません。このキーを削除し、アクティブなユーザーに対して新しい API キーを作成することを検討してください。`,
  },
  debug: {
    information: `デバッグ情報`,
    'include-info': `チケットを作成する予定の場合は、上記の情報を含めます。`,
  },
  pricebook: {
    product: `プロダクト`,
    'monthly-rate': `月額料金`,
    'excludes-tax': `税抜き価格`,
    'contact-sales': `カスタム価格設定については、営業にお問い合わせください。`,
    failed: `サービス {product} の価格を取得できませんでした`,
    'monthly-charges-excl-taxes': `税抜きの月額料金`,
    'price-change-1': `このサービスの現在の月額定期料金は {currentMonthlyPrice} です (税抜き)。さらに速度変更がない場合、このサービスの現在の請求期間の料金は {totalPrice} (税抜き) になります。これは、{deltaPrice} の {changeDirectionText} です (税抜き)。このサービスの新規推定月額定期料金は {longTermMonthlyPrice} です (税抜き)。`,
    'price-change-2': `このサービスの変更により、価格が変更されます。さらに速度変更がない場合、このサービスの現在の請求期間の料金は {totalPrice} (税抜き) になります。これは、{deltaPrice} の {changeDirectionText} です (税抜き)。`,
    'price-change-3': `このサービスの新規月額定期料金は {longTermMonthlyPrice} です (税抜き)。これらの変更を確認します。`,
  },
  'price-change': {
    detected: `料金変更が検出されました`,
    cancel: `いいえ、変更をキャンセルします`,
    accept: `はい、変更を受け入れます`,
    'current-month': `現在の月額料金`,
    'new-monthly': `新規月額料金`,
    'this-period': `この請求期間`,
    change: `変更`,
    'pricing-model': `このサービスの変更により、新規価格設定モデルの更新に従って、価格が変更されます。`,
    'confirm-model-change': `この変更を受け入れると、以前の価格に戻すことはできなくなり、次回発行の新しい請求書にこれが反映されます。これらの変更を受け入れることを確認します。`,
    increase: `<strong>増加</strong>`,
    decrease: `<strong>減少</strong>`,
    'current-monthly': `現在の月額料金`,
  },
  cancel: {
    immediate: `即時キャンセル`,
    'remove-configured': `設定済みサービスの削除`,
    terminate: `サービスの終了`,
    'keep-configured-service': `いいえ、設定済みサービスを保持します`,
    'keep-configured-services': `いいえ、設定済みサービスを保持します`,
    'remove-configuration': `はい、設定を削除します`,
    'keep-services': `いいえ、サービスを保持します`,
    'clear-configured': `設定済みサービスをすべてクリアしてもよろしいですか?`,
    'remove-from-configured': `設定済みサービスからこのサービスを削除してもよろしいですか?`,
    'end-of-contract': `契約終了時に解約`,
    later: `後日`,
    'terminate-immediately': `即時解約`,
    now: `今すぐ`,
    'now-confirmation': `「Terminate Services」 (サービスの終了) を選択すると、解約プロセスが確認されます。これらのサービスは即時に削除されます。以下のサービスには、早期解約料 (ETF) が適用されます。`,
    'later-confirmation': `「Terminate Services」 (サービスの終了) を選択すると、解約プロセスが確認されます。これらのサービスは、以下のサービスに記載されている日付まで通常どおり運用されます。以下のサービスには、早期解約料 (ETF) が適用されます。`,
    unable: `キャンセルできません:`,
    better: `当社の対応で改善すべき点は他にありますか?`,
    'no-permission': `サービスを削除する権限がありません。`,
    error: `キャンセル データを読み込み中にエラーが発生しました`,
    diversity: `冗長化の関係が見つかりました`,
    'issues-terminating': `0 | 1 件のサービスを解約中に問題が発生しました。| {count} 件のサービスを解約中に問題が発生しました。`,
    terminating: `0 | 1 件のサービスを解約しています。| {count} 件のサービスを解約しています。`,
    terminated: `0 | 1 件のサービスが解約されました。| {count} 件のサービスが解約されました。`,
    pending: `解約は保留中です`,
    'do-not-restore': `いいえ、サービスを復元しません`,
    restore: `はい、サービスを復元します`,
    restoring: `サービスを復元しています...`,
    'cancel-termination-confirmation': `このサービスは解約される予定です。この解約リクエストをキャンセルして、サービスを復元しますか?`,
    restored: `解約保留中のサービスが復元されました。`,
    'error-uncancelling': `サービスのキャンセル解除中にエラーが発生しました。`,
  },
  canada: {
    ab: `アルバータ州`,
    bc: `ブリティッシュ コロンビア州`,
    mb: `マニトバ州`,
    nb: `ニューブランズウィック州`,
    nl: `ニューファンドランド・ラブラドール州`,
    nt: `ノースウェスト準州`,
    ns: `ノバスコシア州`,
    nu: `ヌナブト準州`,
    on: `オンタリオ州`,
    pe: `プリンス・エドワード島`,
    qc: `ケベック州`,
    sk: `サスカチュワン州`,
    yt: `ユーコン州`,
  },
  company: {
    'trading-name': `商号`,
    'view-markets': `会社マーケットの表示`,
    'company-trading-name': `会社の商号`,
    'company-legal-name': `会社の法的名称`,
  },
  alibaba: {
    'connection-details': `Alibaba サービスの接続の詳細`,
    'account-id': `Alibaba Cloud アカウント ID`,
    'account-tooltip': `これは Alibaba アカウントから取得します`,
    connection: `Alibaba の接続`,
    'connection-id': `Alibaba の接続 ID`,
  },
  amsix: {
    'connection-details': `AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX} サービスの接続の詳細`,
    peer: `ルート サーバーとピアリングしますか?`,
    selective: `選択`,
    'noc-contact': `NOC 担当者`,
    'url-tooltip': `AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX} サービスには、${process.env.VUE_APP_EN_PRODUCTNAME_IX} で会社情報を広報するためのウェブ URL が必要です`,
    'email-tooltip': `AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX} サービスには、${process.env.VUE_APP_EN_PRODUCTNAME_IX} で会社情報を広報するための E メールが必要です`,
    'select-country': `国の選択`,
    'admin-phone-tooltip': `電話番号は、会社の管理者が「ユーザーの管理」画面で追加することも、ユーザーがユーザー プロファイルに追加することもできます。`,
    'phone-tooltip': `ユーザー プロファイルで電話番号を更新できます。 それ以外の場合は、会社の管理者にお問い合わせください。`,
  },
  aws: {
    'connection-details': `AWS サービスの接続の詳細`,
    'edit-warning': `このサービスで AWS の詳細を編集すると、再構築が開始され、受信アカウントの AWS コンソールを介して新規 VIF 接続リクエストとして受け入れる必要があります。変更を行う前に確認します。`,
    'warning-button': `了解しました`,
    'start-editing': `上のボタンをクリックして、編集を開始します`,
    'no-edit-hosted': `注意:ホスト型接続 AWS の詳細は編集できません。`,
    'connection-name': `AWS 接続の名前`,
    'connection-name-tooltip': `AWS でサービスにラベルを付けます`,
    'account-id': `AWS アカウントID`,
    'account-id-tooltip': `AWS コンソールの AWS アカウント設定からこれを取得します`,
    'bgp-asn': ` パブリックまたはプライベートの BGP AS 番号`,
    'amazon-asn': `Amazon AS 番号`,
    'default-amazon-asn': `デフォルトを使用するには、空白のままにします`,
    'bgp-key': `BGP 認証キー`,
    'bgp-key-tooltip': `BGP 認証キー/パスワード (6-24 文字、空白なし) を入力します。これを指定しない場合は、生成されます`,
    'amazon-prefixes': `Amazon プレフィックス`,
    'amazon-prefixes-tooltip': `AWS にアナウンスするためのプレフィックス、RIR に割り当てられた IPv4 アドレス範囲のみ`,
    'connection-type': `AWS 接続タイプ`,
    'hosted-vif': `ホスト型 VIF`,
    'hosted-vif-description': `ホスト型 VIF (ホスト型仮想インターフェイス) は、パブリックまたはプライベートの AWS クラウドサービスに接続できます。`,
    'hosted-connection': `ホスト型接続`,
    'documentation-html': `AWS 接続オプションの詳細については、{link} をクリックしてください。`,
    'diversity-html': `詳細については、{link} を参照してください`,
    'vif-connection': `AWS VIF 接続`,
    'vif-id': `VIF ID`,
    label: `AWS ラベル`,
    'customer-address': `顧客アドレス`,
    'amazon-address': `Amazon アドレス`,
    'aws-hosted-connection': `AWS ホスト型接続`,
    'aws-vif': `AWS VIF`,
    'connection-id': `AWS 接続 ID`,
    'manual-intervention': `注意:パブリック VIF は Amazon からの手動介入を必要とするため、最大 48 時間かかる場合があります。`,
    'hosted-connection-description': `各ホスト型接続 ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} は、パブリック、プライベート、またはトランジット ゲートウェイへの単一の AWS VIF をサポートします。`,
    'key-features': `主な機能は次のとおりです。`,
    'aws-vif-feature-list-1': `AWS ポート料金は ${process.env.VUE_APP_EN_COMPANYINFO_NAME} 接続に含まれます。`,
    'aws-vif-feature-list-2': `共有顧客帯域を管理および監視します。`,
    'aws-vif-feature-list-3': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} 速度を変更する機能。`,
    'aws-vif-feature-list-4': `1 Mbps ～ 5 Gbps の間で 1 Mbps ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} 刻みで構成可能。`,
    'aws-vif-feature-list-5': `接続された ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG} の自動構成。`,
    'aws-hosted-feature-list-1': `AWS ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 料金は Amazon 経由で請求されます。`,
    'aws-hosted-feature-list-2': `専用の帯域幅。`,
    'aws-hosted-feature-list-3': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} の速度を 50 Mbps から 10 Gbps の増分で設定します。`,
    'aws-hosted-feature-list-4': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} の速度は変更されません。`,
    'aws-hosted-feature-list-5': `回復力を高めるための多様な ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} のサポート。多様な ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} を色分けしてハイライト。`,
    'documentation-link': `AWS 接続の概要 - ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ドキュメント`,
    'readonly-warning': `このサービスで AWS の詳細を編集すると、再構築が開始され、受信アカウントの AWS コンソールを介して新規 VIF 接続リクエストとして受け入れる必要があります。`,
    onboarding: `AWS オンボーディング`,
    'onboarding-reachout': `1 ～ 2 営業日以内に、 ${process.env.VUE_APP_EN_COMPANYINFO_NAME} の担当者がオンボーディングをサポートするためにご連絡いたします。`,
    'onboarding-questions': `ご不明な点がございましたら、 {email} までお問い合わせください`,
    'onboarding-info': `AWS のサービスまたは契約 ID など、できるだけ多くの情報をご提供ください。`,
    'onboarding-success-title': `AWS オンボーディングの成功`,
    'onboarding-success': `ご提出いただきありがとうございました。1 ～ 2 営業日以内に、 ${process.env.VUE_APP_EN_COMPANYINFO_NAME} の担当者がオンボーディングをサポートするためにご連絡いたします。`,
  },
  azure: {
    'primary-subnet': `プライマリ サブネット`,
    'primary-subnet-placeholder': `プライマリ サブネット、例: 192.0.1.32/30`,
    'secondary-subnet': `セカンダリ サブネット`,
    'secondary-subnet-placeholder': `セカンダリ サブネット、例: 192.0.1.32/30`,
    'public-prefixes': `パブリック プレフィックス`,
    'public-prefixes-placeholder': `パブリック プレフィックス、例: 192.0.2.0/24,192.0.2.0/30`,
    'shared-key': `共有キー`,
    'update-peer': `ピアの更新`,
    'editing-message': `このサービスの接続の詳細は、Azure 側から編集する必要があります。`,
    'no-peers': `ピアが定義されていません`,
    'peer-type': `ピア タイプ`,
    'disable-peering-type': `このピアリング タイプを無効化してもよろしいですか?`,
    'disable-peer-type': `ピア タイプの無効化`,
    configuration: `Azure 設定`,
    'express-route-html': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} は、ExpressRoute サービスを Q in Q (ダブルタグ、または 802.1ad) としてプライマリまたはセカンダリ Microsoft ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} に配信します。Microsoft サイトで {link} に精通するようにしてください。`,
    'peering-html': `パブリック、プライベート、および Microsoft のピアリング タイプは、単一の ExpressRoute サービス キーを使用するこの方法で使用できます。これらのピアリング タイプと可用性に関する情報については、{link} を参照してください。`,
    'get-service-key': `Azure ExpressRoute サービス キーの取得方法。`,
    'azure-service-key': `Microsoft Azure サービス キー`,
    'peering-type': `ピアリング タイプ`,
    'peering-type-explanation': `ピアリング タイプは、別のサービスのこのサービス キーに既に設定されています。`,
    microsoft: `Microsoft`,
    'back-to-service-key': `サービス キーに戻り、キーを入力して、接続を作成します。`,
    interface: `Azure インターフェイス`,
    'q-in-q': `Q-in-Q インターフェイス定義`,
    'ms-doc': `Microsoft ドキュメント`,
  },
  google: {
    'cloud-configuration': `Google Cloud 設定`,
    'gci-configuration': `GCI 設定`,
    'partner-peering-key': `Google パートナー ペアリング キー`,
    'show-all-locations': `Google パートナー ロケーションをすべて表示`,
    'back-to-enter-peering': `戻ってペアリング キーを入力`,
    interface: `GCI インターフェイス`,
    connection: `Google 接続`,
  },
  ix: {
    'ecix-asn-fixed': `注意:ECIX サービスについては、構築後に AS 番号を変更できません。`,
    'bgp-password-tooltip': `設定する場合は、BGP パスワードを入力します。`,
    'bgp-password-warning': `注意:構築後に BGP パスワードを変更することはできません。`,
    'looking-glass-graphs': `使用状況グラフは、Looking Glass に表示されます。`,
    'no-looking-glass-graphs': `使用状況グラフは、Looking Glass に表示されません。`,
  },
  'mcr-config': {
    'enable-qinq': `QinQ を有効化しますか?`,
    invalid: `無効`,
    'add-vlan': `VLAN の追加`,
    'virtual-lan': `仮想 LAN`,
    'unique-id': `VLAN に固有の ID を指定します`,
    'vlan-id': `VLAN ID`,
    'delete-vlan': `VLAN の削除`,
    'interface-ip-addresses': `インターフェイス IP アドレス`,
    'ip-subnet': `IP アドレスとサブネット マスク`,
    nat: `ネットワークアドレス変換 (NAT)`,
    'nat-source': `NAT 送信元 IP アドレス`,
    'nat-source-tooltip': `このインターフェイスで送信元 NAT を有効化するには、IPv4 アドレスを選択します。このインターフェイスを離れるすべてのパケットの送信元アドレスは、選択したアドレスに書き換えられます。`,
    'nat-enabled': `NAT 対応`,
    'bgp-connections-description': `このインターフェイスの BGP ピアリング関係 - 最大 5 つ。インターフェイス IP アドレスを作成する必要があります。`,
    'bgp-add': `BGP 接続の追加`,
    'bfd-settings': `双方向フォワーディング検出 (BFD) 設定`,
    'no-bfd-enabled': `BFD 対応の BGP 接続がありません`,
    'transmit-interval': `送信間隔`,
    'receive-interval': `受信間隔`,
    multiplier: `乗数`,
    'bgp-connection': `BGP 接続`,
    'disable-qinq-message': `Q-in-Q を無効化すると、すべての設定が削除されます。`,
    confirm: `続行してもよろしいですか?`,
    'delete-vlan-message': `これによって、この VLAN とそのすべての設定が削除されます。続行しますか?`,
    'nat-is-enabled': `NAT が有効になっています`,
    'no-prefix-filter': `プレフィックス フィルターなし`,
  },
  nutanix: {
    configuration: `Nutanix の設定`,
    'service-key': `Nutanix サービスキー`,
    'service-key-notice': `Nutanix ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} に接続するには、Nutanix サービス キーが必要です。サービス キーがない場合は、{link} にアクセスして取得してください。`,
    interface: `Nutanix インターフェイス`,
    'accept-speed-change': `注意:Nutanix サービス キーの速度設定が更新されました。「保存」をクリックして、新規帯域制限を受け入れます。`,
    nutanix: `Nutanix`,
  },
  oracle: {
    configuration: `Oracle の設定`,
    'virtual-circuit-id': `Oracle 仮想回線 ID`,
  },
  salesforce: {
    configuration: `Salesforce サービスの接続の詳細`,
    approvals: `Salesforce 接続の承認には、最長 2 営業日かかる場合があります。`,
    'provide-password': `BGP パスワードを入力します。指定しない場合は、生成されます。`,
    prefixes: `プレフィックス`,
    'prefixes-tooltip': `Salesforce にアナウンスするためのプレフィックス、RIR に割り当てられた IPv4 アドレス範囲のみ。`,
    connection: `Salesforce 接続`,
    'permitted-prefixes': `許可された BGP プレフィックス`,
  },
  'target-select': {
    'no-bio': `会社情報がありません。`,
    'marketplace-html': `詳しくは {link} をご覧ください。`,
    'hide-info': `情報パネルを非表示`,
    'show-info': `オプションに関する情報を表示します`,
  },
  'target-service-key': {
    'destination-found': `宛先が見つかりました`,
    'key-name': `キーの名前`,
    'max-speed': `最大速度`,
  },
  events: {
    session: `セッション イベント ログ`,
    clear: `イベントをすべてクリア`,
    cleared: `セッション イベント ログがクリアされました。`,
  },
  graph: {
    private: `この会社のデータはプライベートです`,
    'load-error': `グラフ データの読み込みに失敗しました`,
    'no-data': `グラフ データがありません`,
    'in-mbps': `受信 Mbps`,
    'out-mbps': `送信 Mbps`,
    'billing-month': `請求月:`,
    'a-end-not-owned': `この接続の A エンドは別の会社が所有しているため、請求情報はありません。`,
    'load-failed': `必要なデータの読み込みに失敗しました`,
    'no-billing-data': `請求データがありません`,
    estimated: `このサービスの次回の推定請求額 (税抜き) 。`,
    calculated: `このサービスの請求額 (税抜き) 。`,
    'too-many-changes': `グラフの速度変更が多すぎます。CSV をダウンロードして、データを表示します。`,
    'configured-speed': `設定済み速度`,
    'billing-details': `請求の詳細`,
    'estimated-next': `次回の推定請求額 (税抜き) 、サービスに変更がないことを想定。`,
    speed: `速度 (Mbp)`,
    download: `CSV のダウンロード`,
    'service-graph': `サービス グラフ`,
    'date-range': `日付範囲:`,
    'last-12-h': `過去 12 時間`,
    'last-24-h': `過去 24 時間`,
    'last-7-d': `過去 7 日`,
    'last-4-w': `過去 4 週間`,
    metrics: `メトリック:`,
    source: `ソース:`,
    timezone: `タイムゾーン:`,
    'nothing-selected': `グラフには何も選択されていません`,
    'date-time': `日付/時間`,
    in: `イン`,
    out: `アウト`,
    'last-hour': `該当なし | 過去 1 時間 |過去 {count} 時間`,
    'last-week': `該当なし |先週 | 過去 {count} 週間`,
    'last-month': `該当なし |先月 | 過去 {count} か月`,
    // 協定世界時
    utc: `UTC`,
    'export-csv': `CSV のエクスポート`,
    'downloading-csv': `CSV のダウンロード`,
  },
  'looking-glass': {
    'select-ix': `${process.env.VUE_APP_EN_PRODUCTNAME_IX} の選択`,
    title: `Looking Glass`,
    select: `インターネット エクスチェンジの選択`,
    filter: `入力して、名前または AS 番号でフィルタリング`,
    'no-peers': `利用可能なピアがありません`,
    'loading-peers': `ピア リストを読み込んでいます...`,
    rs1: `RS1`,
    'route-server-1': `ルート サーバー 1`,
    rs2: `RS2`,
    'route-server-2': `ルート サーバー 2`,
    'extended-details': `拡張ピアの詳細`,
    routing: `ルーティングの情報`,
    routes: `経路`,
    imported: `インポート済み`,
    best: `最適`,
    filtered: `フィルタリング済み`,
    loading: `グラフ データを読み込んでいます...`,
    'loading-routes': `経路を読み込んでいます...`,
    'route-load-failed': `経路データの読み込みに失敗しました`,
    'no-routes': `利用可能な経路がありません`,
    'local-pref': `ローカル プリファレンス`,
    'origin-asn': `オリジン AS 番号`,
    'asn-path': `AS 番号パス`,
    since: `以降`,
    'route-data-load-failed': `経路データの読み込みに失敗しました`,
    'detail-data-load-failed': `詳細データの読み込みに失敗しました`,
    'ip-unavailable': `IPv4 と IPv6 は利用できません。`,
    'protocol-unavailable': `{protocol} は利用できません。`,
    'ip-available': `IPv4 と IPv6 は利用可能です。`,
    asn: `AS 番号:`,
  },
  'image-upload': {
    select: `画像の選択`,
    crop: `クロップ画像`,
    'drop-image': `ここに画像ファイルをドロップするか、クリックしてアップロードします`,
    clear: `画像のクリア`,
  },
  'no-services': {
    message: `数回クリックするだけで、高速で柔軟な相互接続ができます。`,
    action: `接続しましょう`,
    instructions: `開始するには、上のボタンから ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} を作成するか、「ツアーの開始」をクリックして、注文方法に関するクィック ウォークスルーを実行します。`,
  },
  'google-login': {
    unavailable: `Google ログインを利用できません`,
    loading: `Google サービスはまだ読み込まれています。しばらくしてからもう一度お試しください。`,
    problem: `Google 認証サービスを読み込み中に問題が発生しました。この問題は当社の開発者に報告されました。現時点では、ユーザー名とパスワードの認証を使用してください。`,
    cancelled: `Google ログインがキャンセルされました`,
    'cancelled-message': `Google ログインがユーザ－によってキャンセルされました。`,
    blocked: `Google ログインがブロックされました`,
    'blocked-message': `Google ログインに必要なポップアップ ウィンドウがブラウザーによってブロックされています。このサイトでポップアップを許可してください。`,
    'cookies-blocked-message': `お使いのブラウザーは、サードパーティの Cookie をブロックするように設定されています。Google ログインを使用するには、サードパーティの Cookie を許可した上で、ページを再度読み込みます。`,
    'browser-blocked-message': `お使いのブラウザーで Google ログインを実行できませんでした詳細は次のとおりです: {details}`,
    failed: `Google ログインに失敗しました`,
    'failed-message': `Google を使ったログインに失敗しました。`,
    'token-received': `Google トークンを受け取りました、ログインを試行しています...`,
    'no-details': `その他の詳細はありません。`,
    'token-failed-message': `Google ログイン トークンの確認中にエラーが発生しました。問題が解決しない場合は、再度ログインするか、ブラウザの Cookie をクリアしてください。`,
  },
  'whitelabel-dlr': {
    welcome: `DLR へようこそ`,
    'uptimes-blog': `Uptimes Blog`,
    twitter: `Twitter でフォロー`,
    linkedin: `Linkedin でフォロー`,
    conditions: `利用規約とプライバシー条件を見る`,
    contact: `お問い合わせとサポート`,
    careers: `採用情報 - 人材を募集しています`,
  },
  tooltips: {
    'route-prefix': `CIDR 表記による IPv4 または IPv6 のネットワーク アドレス`,
    'route-next-hop': `ネクスト ホップ ルーターの IP アドレス。`,
    'required-item-start': `次のマーク:`,
    'required-item-end': `がある項目は必須フィールドです。`,
    'local-ip-address': `BGP ピアとの通信に使用するこのインターフェイスの IPv4 または IPv6 アドレス。`,
    'peer-ip-address': `BGP ピアの IP アドレス。`,
    'peer-asn': `リモート BGP ピアの AS 番号。4-byte AS 番号は、asplain 形式でサポートされています。`,
    'bgp-password': `最大 25 文字の BGP ピアの認証に使用される共有キー。`,
    'bgp-description': `最大 100 文字の BGP 接続の説明。`,
    'bgp-state': `デフォルトで BGP 接続が有効化されており、ピアへの接続を積極的に試行します。BGP セッションを削除せずに一時的に無効にするには、シャットダウンを選択します。これは、フェイルオーバー シナリオのトラブルシューティングまたはテストに役立つ場合があります。`,
    'med-inbound': `MED は、この BGP 接続で受信されたすべての経路に適用されます。BGP ピアから受信した値を使用するには、空白のままにします。最小値の経路が優先されます。`,
    'med-outbound': `MED は、この BGP 接続で送信されるすべての経路に適用されます。隣接する自律システムが、その裁量で最小値を優先する場合があります。`,
    bfd: `双方向フォワーディング検出により、この BGP 接続での迅速な障害検出が可能になります。接続が中断されると、BGP 保留タイマーの期限が切れるのを待たずに BGP セッションが切断されます。BFD を使用すると、複数の経路が利用可能な場合、フェイルオーバーの時間が改善される可能性があります。注意:これらの設定は、BFD が有効になっているこの VXC 上のすべての BGP 接続に使用されます。`,
    'bfd-group': `注意:これらの設定は、BFD が有効になっているこのインターフェイスのすべての BGP 接続に使用されます。`,
    'bfd-transmit': `BFD パケットをネイバーに送信する間の最小時間。サポートされる範囲は 300ms から 9000ms です。`,
    'bfd-receive': `ネイバーが送信する BFD パケット間の最小時間。サポートされる範囲は 300ms から 9000ms です。`,
    'bfd-multiplier': `この多数の連続した BFD パケットをネイバーから受信しないと、BGP セッションは切断されます。`,
    med: `Multi-Exit Discriminator を使用すると、同じ宛先の複数経路が利用可能な場合、経路のプリファレンスに影響を与えることができます。これは通常、特定のプライマリ パスとセカンダリ パスを優先するために使用されます。0 から 4,294,967,295 の範囲の値がサポートされています。`,
    'bgp-config-description': `この BGP ピアから受信する経路は、この ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} <br /> 上で他の BGP ピアに広報されます。デフォルトですべてのピアに経路を広報して、特定ピアへの経路をフィルタリングすることができます。または、デフォルトですべてのピアへの経路をフィルタリングし、特定ピアへの経路を広報することもできます。`,
    'bgp-prefix-filter': `<p>事前定義されたプレフィックス フィルターをこの BGP ピアに適用して、ピアから受信する、またはピアに広報される経路のセットを制限します。</p>
      <ul>
      <li>プレフィックス フィルターがないと、すべてのルートが許可されます。経路はフィルタリングされません。</li>
      <li>「Permit List (リストの許可)」は、プレフィックス リストに一致する経路のみを許可します。その他の経路はフィルタリングされます。</li>
      <li>「Deny List (リストの拒否)」は、選択したプレフィックス リストに一致するものを除くすべての経路を許可します。</li>
      </ul>
      <p>プレフィックス フィルターのリストを定義するには、${process.env.VUE_APP_EN_PRODUCTNAME_MCR}「Configuration (設定)」ページで「プレフィックス フィルターのリスト」タブを選択します。</p>
      `,
    'bgp-prefix-editor': `
          <p>
          ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} プレフィックス フィルターのリストは、IP ネットワーク アドレスの名前が付いたリストです。一致させるプレフィックス フィルターのリストを作成し、経路のプレフィックスに基づいて経路をフィルタリングし、そのフィルターを受信元の
          経路に適用するか、またはこの ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} 上の BGP ピアに広報します。
          ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} はリストの条件に対して、上から下に一度に 1 つずつ一致させながら各経路を比較して、経路を拒否または広報します。
          </p>
          <ul>
          <li>プレフィックス フィルターのリストは、異なる BGP ピアに複数回適用できます。</li>
          <li>最大 50 のプレフィックス フィルターのリストを各 ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} で設定できます。</li>
          <li>各リストには、200 までエントリを含めることができます。</li>
          </ul>
         `,
    'company-trading-name': `お客様のアカウント名、会社名、または組織名。これは後で変更できます。`,
    'as-path-prepend-tooltip': `ローカル ASN は、発信 BGP ルート通知の AS パスの先頭に追加されます。0 から最大 10 までの数字を入力してください。`,
    'shutdown-state': `構成された状態をシャット ダウンに更新して、サービスがダウンした場合に ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ネットワーク上でサービスがどのように動作するかをテストします。`,
    'move-connection-limits': `同じプロダクト タイプで、同じメトロ内のポートのみを選択できます。`,
    'move-connection-vnic': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} A エンドまたは B エンドの指定は、 ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} の作成時に決定されました。`,
  },
  time: {
    min: `最小`,
    s: `秒`,
    ms: `ミリ秒`,
    µs: `マイクロ秒`,
  },
  'page-titles': {
    login: `ログイン`,
    'user-profile': `ユーザー プロファイル`,
    'user-notifications': `ユーザー通知`,
    services: `サービス`,
    dashboard: `ダッシュボード`,
    'create-port': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の作成`,
    mve: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'create-connection': `接続の作成`,
    'edit-port': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} の編集`,
    'edit-connection': `接続の編集`,
    'company-profile': `会社プロファイル`,
    'company-users': `会社ユーザー`,
    'email-confirmation': `E メールの確認`,
    'marketplace-profile': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} プロファイル`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    debug: `デバッグ情報`,
    'looking-glass': `Looking Glass`,
    'mcr-looking-glass': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} Looking glass`,
    'event-log': `セッション イベント ログ`,
    'ix-flows': `${process.env.VUE_APP_EN_PRODUCTNAME_IX} フロー`,
    'api-keys': `API キー`,
    'partner-admin': `パートナー管理者`,
    'managed-account': `マネージド アカウント`,
    'billing-markets': `ビリング マーケット`,
    onboard: `オンボード`,
    signup: `登録`,
    'signup-google': `Google 登録`,
    admin: `管理者`,
    'logging-in': `ログイン中`,
    'company-security-settings': `会社のセキュリティ設定`,
    'company-support': `技術サポートの連絡先`,
    'setup-mfa': `多要素認証設定`,
    mfa: `多要素認証`,
    'create-mcr': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} の作成`,
    'edit-mcr': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} の編集`,
    'create-mve': `${process.env.VUE_APP_EN_PRODUCTNAME_MVE} の作成`,
    'edit-mve': `${process.env.VUE_APP_EN_PRODUCTNAME_MVE} の編集`,
    'move-connections': `接続を移動`,
    'aws-entitlements': `AWS エンタイトルメント`,
    'aws-onboarding': `AWS オンボーディング`,
    'service-status': `サービス ステータス`,
  },
  partner: {
    'learn-more': `マネージド アカウントの管理については、パートナーのドキュメントをご覧ください`,
    'view-documentation': `ドキュメントの表示`,
    'managed-accounts': `マネージド アカウント`,
    'create-managed-account': `マネージド アカウントの作成`,
    'view-services': `サービスの表示`,
    edit: `編集`,
    filter: `アカウントのフィルタリング`,
    'no-managed-accounts': `マネージド アカウントはありません。「Create Managed Account (マネージド アカウントの作成)」ボタンをクリックして、最初のアカウントを作成します。`,
    'invalid-company-uid': `UID に一致するマネージド アカウントが見つかりませんでした。`,
    'no-match': `一致するアカウントがありません。`,
    'account-name': `アカウント名`,
    'company-uid': `会社 UID`,
    'managed-account-ref': `マネージド アカウント UID`,
    'managed-account-ref-placeholder': `一意の会社参照`,
    actions: `アクション`,
    'managed-account': `マネージド アカウント`,
    'managed-learn-more': `マネージド アカウントの作成については、パートナーのドキュメントをご覧ください`,
    save: `保存`,
    create: `作成`,
    cancel: `キャンセル`,
    'account-created': `マネージド アカウントが作成されました。`,
    'account-saved': `マネージド アカウントが保存されました。`,
    'create-account-error': `マネージド アカウントの作成中にエラーが発生しました。`,
    'update-account-error': `マネージド アカウントの更新中にエラーが発生しました。`,
    error: `エラー`,
    'error-retrieving-accounts': `マネージド アカウントを取得できませんでした。`,
    'managed-accounts-info': `アカウントのリストを表示し、権限を設定し、サービスを管理します。`,
    'edit-users': `ユーザーの編集`,
    'edit-users-text': `この会社のユーザーとその権限の完全なリストを表示および管理します。`,
    'new-managed': `新規マネージド アカウント`,
    'view-services-text': `この会社の新規サービスを設定、表示、注文します。`,
    'manage-services': `サービスの管理`,
    'diversity-unavailable-location': `現在、この構成では多様性は使用できません。多様性の要件については、${process.env.VUE_APP_EN_COMPANYINFO_NAME} パートナーにお問い合わせください。`,
  },
  'partner-admin': {
    'partner-account': `パートナー アカウント`,
    'managed-account': `マネージド アカウント | マネージド アカウント`,
    'managed-accounts-uid': `マネージド アカウント UID`,
    'select-account': `アカウントの選択`,
    'account-filter': `名前または参照でアカウントをフィルタリングします`,
    partner: `パートナー`,
    'viewing-as': `次で表示:`,
  },
  'partner-vantage': {
    subscription: `サブスクリプション`,
    'subscription-tooltip-ports': `サブスクリプションは、サービスの稼働後か、サービスの設定から 14 日後に開始されます`,
    'subscription-tooltip': `注文と同時にサブスクリプションが開始されます`,
    'no-subscription': `サブスクリプションなし`,
    'change-subscription': `サブスクリプションを変更`,
    'subscription-title': `{productType} のサブスクリプションを設定`,
    'subscription-choose': `{productType} のサブスクリプションを選択`,
    'confirm-new-subscription': `新しいサブスクリプションを開始`,
    'subscription-months': `サブスクリプションなし | サブスクリプション:1 か月 | サブスクリプション:{count} か月`,
    'subscription-reterm': `この注文の契約期間 (最低利用期間) はすぐに有効になり、期間割引は翌暦月の請求に適用されます。契約期間の早期解約には、当社との合意に従って早期解約料金が発生します。`,
    'cancel-subscription': `現在の契約期間終了時に何もせず、定価でサブスクリプションなしにロールオーバーする場合は、「Cancel」 (キャンセル) をクリックします。`,
    'subscription-term': `サブスクリプション期間`,
  },
  outscale: {
    connection: `Outscale の接続`,
    'connection-details': `Outscale サービスの接続の詳細`,
    'connection-name': `Outscale 接続名`,
    'connection-name-tooltip': `Outscale でサービスにラベル付け`,
    'account-id': `Outscale アカウント ID`,
    'account-id-tooltip': `Outscale 所有者のアカウント ID を入力してください`,
  },
  'company-security-settings': {
    'security-settings': `セキュリティ設定`,
    'mfa-long': `多要素認証`,
    'info-enforced': `強制後は、会社のすべてのユーザーがログイン時に MFA の設定を求められます。`,
    'info-optional': `オプションになった場合でも、会社のすべてのユーザーのユーザー プロファイルに MFA を有効化するオプションが残ります。`,
    'mfa-now-enforced': `MFA が強制されました`,
    'mfa-now-optional': `MFA がオプションになりました`,
    'enforce-mfa-all-users': `社内のすべてのユーザーに多要素認証 (MFA) を強制します`,
    'error-updating-mfa': `MFA 設定の更新中にエラーが発生しました`,
    'enforce-mfa-tooltip-on': `MFA が強制 (オン) の場合、すべてのユーザーはアカウントにログインする際に MFA を使用する必要があります。ユーザーが現在 MFA を有効化していない場合、次回ログイン時に MFA を設定するよう促されます。MFA を設定するまで、ユーザーは${process.env.VUE_APP_EN_COMPANYINFO_NAME} 内で会社にアクセスできません。`,
    'enforce-mfa-tooltip-off': `MFA がオプション (オフ) の場合、ユーザーは自分のアカウントで MFA を有効化するかどうかを選択できます。`,
    'enforce-mfa-tooltip-title': `MFA を強制しています`,
    mfa: `MFA`,
    on: `オン`,
    off: `オフ`,
    sso: `SSO`,
    'sso-long': `シングル サインオン`,
    'provider-identity': `プロバイダー・ID`,
    type: `タイプ`,
    'identity-provider-info': `ID・プロバイダー情報`,
    'provider-name': `プロバイダー名`,
    domains: `ドメイン`,
    metadata: `メタデータ URL`,
    'integration-details': `統合の詳細`,
    'audience-uri': `オーディエンス URI`,
    'idp-response-url': `IdP 応答 URL`,
    'sso-fail': `SSO 構成の取得に失敗しました。`,
    'sso-no-idps': `構成された ID プロバイダーがありません。`,
    'sso-attribute-mapping': `属性マッピング`,
    'sso-user-pool-attribute': `ユーザー プール属性`,
    'sso-saml-attribute': `SAML 属性`,
    'integration-details-desc': `SAML 2.0 プロバイダーを ${process.env.VUE_APP_EN_COMPANYINFO_NAME} に接続するには、オーディエンス URI と IdP 応答 URL を ID プロバイダーに提供する必要があります。`,
    'details-for': `{thing} の詳細`,
    'accepts-csv-values': `カンマで区切られた値のリストを受け入れる`,
    'enforce-sso-all-users': `社内のすべてのユーザーにシングル サインオン (SSO) を強制する`,
    'enforce-sso-tooltip-title': `SSO を強制しています`,
    'enforce-sso-tooltip-on': `SSO の強制がオンの場合、すべてのユーザーはデフォルトで構成された IdP を使用して認証する必要があります。IdP ユーザー プールに関連付けられていないユーザーは、アクセスがブロックされます。(例外:会社の管理者は、SSO に関連付けられたドメインを持たない会社の管理者も含め、他の方法でログインすることが引き続き許可されます)`,
    'enforce-sso-tooltip-off': `SSO の強制がオフの場合、ユーザー名/パスワード、Google、または SSO などのさまざまな認証方法をユーザーは使用できます。`,
    'enforce-sso-info-enforced': `SSO の強制をオンにすると、すべてのユーザーはデフォルトで構成された IdP を使用して認証する必要があります。IdP ユーザー プールに関連付けられていないユーザーは、アクセスがブロックされます。(例外:会社の管理者は、SSO に関連付けられたドメインを持たない会社の管理者も含め、他の方法でログインすることが引き続き許可されます)`,
    'enforce-sso-info-optional': `SSO の強制をオフにすると、ユーザー名/パスワード、Google、または SSO などのさまざまな認証方法をユーザーは使用できます。社内のすべてのアクティブ ユーザーが再度ログインできるようになります。`,
    'enforce-sso': `SSO の強制`,
    'sso-connections': `SSO 接続`,
    'error-fetching-sso': `SSO 構成の取得時に予期しないエラーが発生しました`,
    'error-updating-sso': `SSO 構成の更新時に予期しないエラーが発生しました`,
    'configure-saml-idp': `SAML 2.0 ID プロバイダー (IdP) の構成`,
    'configure-saml-description': `シングル サインオン (SSO) を使用するように ${process.env.VUE_APP_EN_COMPANYINFO_NAME} アカウントを構成できます。SSO を有効にすると、ユーザーは ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL} にアクセスしようとしたときにパスワードの入力を求められる代わりに、外部の ID プロバイダー (IdP) にリダイレクトされて認証されます。SSO によりログイン エクスペリエンスが簡素化され、ユーザーが複数のログイン認証情報を記憶する必要がなくなるため、よりユーザー フレンドリーであり、管理者の管理も容易になります。`,
    'add-saml-connection': `SAML 接続の追加`,
    'register-saml-provider': `SAML プロバイダーへの ${process.env.VUE_APP_EN_COMPANYINFO_NAME} の登録`,
    'register-saml-provider-description': `次の詳細 (ID プロバイダーが使用するメタデータ URL を含む) を指定して、SAML ID プロバイダーを ${process.env.VUE_APP_EN_COMPANYINFO_NAME} で構成します。`,
    'sso-update-success': `{name} の SSO 設定の更新`,
    'maximum-domains-error': `最大ドメイン数は {max} です`,
    'invalid-domains': `無効なドメインが見つかりました {domains}`,
    'duplicate-domains': `重複したドメインが見つかりました {domains}`,
    'idp-delete-confirmation-title': `プロバイダーの削除`,
    'idp-delete-confirmation-message': `<strong>このアクションを続行すると、次のことを承認したことになります。</strong><ul><li>この接続に関連付けられているすべてのユーザーは、外部 IdP を使用してログインできなくなります。</li><li>SSO 強制が有効になっている場合は、無効になります。</li><li>会社のすべてのアクティブなユーザーは、他の認証方法でログインできるようになります。</li></ul>`,
    'sso-now-enforced': `SSO が強制されました`,
    'sso-now-optional': `SSO がオプションになりました`,
    'new-user-non-sso-domain-warning': `現在、シングル サインオン (SSO) がアカウントに適用されています。任意のユーザーをアカウントに招待できますが、招待されたユーザーは SSO ドメインに関連付けられていなければ認証情報を使用できません。例外として、ユーザーが会社の管理者の場合は、SSO ドメインに関連付けられていない場合でもアカウントにアクセスできます。`,
    'sso-enforced-inactive-warning': `現在、シングル サインオン (SSO) がアカウントに適用されています。SSO ドメインに関連付けられていないユーザー アカウントを再度有効にしても、ユーザーは引き続きブロックされ、ログインできません。例外として、ユーザーが会社の管理者の場合は、SSO ドメインに関連付けられていない場合でもアカウントにアクセスできます。`,
    'sso-new-docs-message': `このアカウントに SSO を追加する前に、{docs} ページを確認してください。ここでは、セットアップに関するガイダンスと、シングル サインオンに関する重要な情報を提供しています。`,
    'sso-features-and-instructions-for-use': `SSO の機能と使用方法`,
    'sso-add-settings-for-account': `このアカウントに SSO 設定が追加されました。`,
    'sso-ensure-config-complete': `構成が完了していることを確認するには、IdP を以下のオーディエンス URI および応答 URL と統合する必要があります。これらは、SAML 認証が正しく機能するために必要な必須コンポーネントです。`,
    'sso-info-accessed-again-at-any-time': `この情報には、SSO 接続の横にある「編集」ボタンをクリックすることで、いつでもアクセスできます`,
    'can-also-be-known-as': `次のようにも呼ばれます。`,
    'entity-id': `エンティティ ID`,
    audience: `オーディエンス`,
    'assertion-consumer-service': `アサーション・コンシューマー・サービス (ACS) URL`,
    'single-sign-on-url': `シングル サインオン URL`,
    'application-callback-url': `アプリケーション・コールバック URL`,
    'reply-url': `返信 URL`,
  },
  tscd: {
    'tech-supp-phone': `技術サポート電話番号`,
    'tech-supp-phone-num': `技術サポート チーム電話番号`,
    'tech-supp-phone-placeholder': `顧客の社内サポート チーム電話番号`,
    'tech-supp-email': `技術サポート チーム E メール`,
    'tech-supp-email-placeholder': `顧客の社内サポート チーム E メール アドレス`,
  },
  'company-support': {
    'tech-supp-phone': `技術サポート チーム電話番号`,
    'tech-supp-phone-placeholder': `貴社の社内サポート チーム連絡先電話番号`,
    'tech-supp-email': `技術サポート チーム E メール`,
    'tech-supp-email-placeholder': `貴社の社内サポート チーム連絡先 E メール`,
    'admin-warning': `会社の管理者であるユーザーは、技術サポート チームの詳細を入力および編集できます`,
    'customer-supp-heading': `顧客の連絡先`,
    'megaport-supp-heading': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} の連絡先`,
    'account-manager': `アカウント・マネージャー`,
    'customer-success-manager': `カスタマー・サクセス・マネージャー`,
    'click-chat-icon-msg': `チャット アイコンをクリックして会話を始めます`,
  },
  images: {
    'datacentre-logo': `データセンター ロゴ`,
    'megaport-logo': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} ロゴ`,
    'megaport-cloud-providers-logo': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} クラウド プロバイダー`,
    'loading-logo': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} 読み込み中のロゴ`,
    'role-icon': `ロール アイコン`,
    'docs-icon': `ドキュメンテーション・アイコン`,
    'company-logo': `会社のロゴ`,
    'resize-handle-icon': `サイズ変更処理アイコン`,
    'marketplace-logo': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} ロゴ`,
    'marketplace-profile-logo': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} プロファイル ロゴ`,
    'provider-logo': `プロバイダー ロゴ`,
    'copy-icon': `コピー値アイコン`,
    'partner-vantage-logo': `Partner Vantage ロゴ`,
    'profile-logo': `プロファイル ロゴ`,
    'sx-logo': `Service Exchange ロゴ`,
  },
  ibm: {
    connection: `IBM 接続`,
    'connection-details': `IBM Cloud Service の接続の詳細`,
    'account-id': `IBM アカウント ID`,
    'account-id-tooltip': `IBM ポータルの IBM アカウント設定から取得します。`,
    'customer-asn-tooltip': `パブリックまたはプライベートの BGP AS 番号`,
    'account-id-validation': `32 桁の 16 進数`,
    'modal-title': `IBM ポータル接続`,
    'order-request-sent': `リクエストが IBM ポータル アカウントに送信されました。`,
    'order-request-reminder': `これは、IBM ポータル経由の接続リクエストを承認/拒否するためのリマインダーです。`,
    'visit-portal': `IBM ポータルにアクセス`,
    'edit-modal-updated-api': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} は、IBM との API 統合を更新しました。`,
    'edit-modal-recommendation': `更新された機能にアクセスし、既存の接続を終了するために、ユーザーは IBM Cloud ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} を再注文することをお勧めします。`,
    'edit-modal-docs': `詳細については、{link} を参照してください。`,
  },
  'mexico-billing-market': {
    'company-type-mexico-01-legal-entity': `01:法的実体`,
    'company-type-mexico-02-legal-person': `02: 法人`,
    'company-type-mexico-03-foreign-company': `03: 外資系企業`,
    'tax-regime-code-mexico-601': `601 - 法律法人一般`,
    'tax-regime-code-mexico-603': `603 - 非営利法人`,
    'tax-regime-code-mexico-605': `605 - 賃金・給与および賃金と同化した所得`,
    'tax-regime-code-mexico-606': `606 - リース`,
    'tax-regime-code-mexico-607': `607 - 物品の譲渡または取得の規制`,
    'tax-regime-code-mexico-608': `608 - その他の収入`,
    'tax-regime-code-mexico-610': `610 - メキシコに恒久的施設を持たない海外居住者`,
    'tax-regime-code-mexico-611': `611 - 配当収入 (パートナーおよび株主)`,
    'tax-regime-code-mexico-612': `612 - ビジネスおよびプロフェッショナルな活動を行う個人`,
    'tax-regime-code-mexico-614': `614 - 受取利息`,
    'tax-regime-code-mexico-615': `615 - 賞金所得制度`,
    'tax-regime-code-mexico-616': `616 - 納税義務なし`,
    'tax-regime-code-mexico-620': `620 - 所得の繰り延べを選択する生産協同組合`,
    'tax-regime-code-mexico-621': `621 - 税の組み込み`,
    'tax-regime-code-mexico-622': `622 - 農業、畜産業、林業、漁業活動`,
    'tax-regime-code-mexico-623': `623 - 企業グループ向けオプション`,
    'tax-regime-code-mexico-624': `624 - コーディネート`,
    'tax-regime-code-mexico-625': `625 - 技術的プラットフォームを通じた収入を伴う事業活動形態`,
    'tax-regime-code-mexico-626': `626 - 簡易信託形態`,
    'sat-purpose-mexico-cn01': `CN01 - 給与`,
    'sat-purpose-mexico-cp01': `CP01 - 支払い`,
    'sat-purpose-mexico-d01': `D01 - 医療費、歯科治療費、入院費`,
    'sat-purpose-mexico-d02': `D02 - 能力障害による医療費`,
    'sat-purpose-mexico-d03': `D03 - 葬儀費用`,
    'sat-purpose-mexico-d04': `D04 - 寄付`,
    'sat-purpose-mexico-d05': `D05 - 住宅ローンの実質支払利息 (住宅)`,
    'sat-purpose-mexico-d06': `D06 - SAR への自発的拠出`,
    'sat-purpose-mexico-d07': `D07 - 医療費保険料`,
    'sat-purpose-mexico-d08': `D08 - 義務教育学校の交通費`,
    'sat-purpose-mexico-d09': `D09 - 預金、年金制度に基づく保険料`,
    'sat-purpose-mexico-d10': `D10 - 教育サービスへの支払い (学費)`,
    'sat-purpose-mexico-g01': `G01 - 商品の取得`,
    'sat-purpose-mexico-g02': `G02 - 返金、割引、ボーナス`,
    'sat-purpose-mexico-g03': `G03 - 経費全般`,
    'sat-purpose-mexico-i01': `I01 - 建物`,
    'sat-purpose-mexico-i02': `I02 - 投資用のオフィス家具および設備`,
    'sat-purpose-mexico-i03': `I03 - 輸送機器`,
    'sat-purpose-mexico-i04': `I04 - コンピューター機器および付属品`,
    'sat-purpose-mexico-i05': `I05 - 金型、ダイス、型打ち機、工具`,
    'sat-purpose-mexico-i06': `I06 - 電話通信`,
    'sat-purpose-mexico-i07': `I07 - 衛星通信`,
    'sat-purpose-mexico-i08': `I08 - その他の機械設備`,
    'sat-purpose-mexico-s01': `S01 - 税効果なし (納税義務なし)`,
  },
  'survey-banner': {
    'customer-survey-link': `フィードバックをお寄せください。こちらをクリックして、お客様アンケートにご協力ください。`,
  },
  'mp-one': {
    'platform-orchestrate-network-services': `クラウド サービスをオーケストレーション、ネットワーク化、有効化する、ONE プラットフォーム。`,
    'try-megaport-one': `Megaport ONE を試す`,
    'learn-more': `詳細を見る`,
    'click-here-to-try-mp-one': `Megaport ONE ベータ版のお試しはこちらをクリックしてください。クラウド・ソリューションのオーケストレーション、接続、立ち上げのためのプラットフォーム。`,
    'terms-and-conditions': `現在、MegaportONE ポータルにアクセスしています。nnMegaportONE ポータルにアクセスすると、次の利用規約に拘束されることに同意したものとみなされます。nnMegaportONE ポータルで提供される情報およびサービスを、ユーザーは現在無料で利用できます。nnMegaport は、将来、特定の機能またはサービスに対して手数料を導入する権利を留保します。nnそのような料金や手数料が発生する場合は、事前にユーザーに明確に通知されます。nnMegaportONE ポータルは現在ベータ テスト中であり、すべての機能やサービスを常時使用できるとは限りません。nnMegaport は、事前に通知することなく、ウェブサイトまたはその機能の一部を変更、一時停止、または中止する権利を留保します。nnMegaportONE ポータルへのアクセスが中断されないこと、またはエラーが発生しないことは保証されていません。`,
  },
  'view-mode': {
    tooltip: `表形式またはマップ ビューでサービスを表示`,
    tabular: `表形式`,
    map: `マップ`,
  },
  map: {
    'all-locations': `すべてのロケーションを表示`,
    'occupied-locations': `サービスが提供されているロケーションのみを表示`,
    'filtered-locations': `サービスが提供されていないロケーションをフィルター表示`,
    'thing-available': `{thing} 利用可能`,
    'dc-provider': `データセンター・プロバイダー`,
    'locations-filtered': `表示ロケーションはフィルタリングされています`,
    'zoom-in': `ズーム イン`,
    'zoom-in-lots': `大幅にズーム イン`,
    'zoom-out': `ズーム アウト`,
    'zoom-out-lots': `大幅にズーム アウト`,
    settings: `設定`,
    key: `マップ キー`,
    help: `ヘルプとガイド`,
    'help-action': `アクション`,
    'help-description': `説明`,
    'help-items': {
      'zoom-in-title': `ズーム イン`,
      'zoom-in-action-1': `ダブル クリック`,
      'zoom-in-action-2': `= キー`,
      'zoom-in-action-3': `+ キー`,
      'zoom-in-action-4': `⌘/CTRL キーとマウス ホイールを押したままにします`,
      'zoom-in-action-5': `Alt/Option キーを押したまま下にドラッグ`,
      'zoom-in-action-6': `ピンチ`,
      'double-zoom-in-title': `倍速ズーム イン`,
      'double-zoom-in-action-1': `Shift = キー`,
      'double-zoom-in-action-2': `Shift + キー`,
      'zoom-out-title': `ズーム アウト`,
      'zoom-out-action-1': `Shift ダブル クリック`,
      'zoom-out-action-2': `- キー`,
      'zoom-out-action-3': `⌘/CTRL キーとマウス ホイールを押したままにします`,
      'zoom-out-action-4': `Alt/Option キーを押したまま上にドラッグ`,
      'zoom-out-action-5': `ピンチ`,
      'double-zoom-out-title': `倍速ズーム アウト`,
      'double-zoom-out-action-1': `Shift - キー`,
      'area-zoom-in-title': `エリアをズーム イン`,
      'area-zoom-in-action-1': `Shift ドラッグ エリア`,
      'pan-title': `マップをパンする`,
      'pan-action-1': `ドラッグ`,
      'pan-action-2': `矢印キー`,
      'orientation-title': `マップの向きを変える`,
      'orientation-action-1': `2 本指でドラッグ`,
      'orientation-action-2': `Shift ←、→ で回転、Shift ↑ でリセット`,
    },
    'ix-count': `0 IXs | 1 IX | {count} IXs`,
    'location-cluster': `このクラスターは {locationString} を表しています。`,
    'location-ix-cluster': `このクラスターは {locationString} と {ixString} を表しています。`,
    'click-expand': `クリックして展開します。`,
    'click-empty-location': `クリックして詳細を表示し、サービスを追加します`,
    'supported-products': `このロケーションでは、次の製品がサポートされています。`,
    'products-location': `このロケーションには次の製品があります。`,
    'click-details': `クリックして詳細を表示`,
    'line-connections-count': `- | この線は 1 つの接続を表します。| この線は {count} 個の接続を表します`,
    'location-deployment': `このロケーションのサービスがまもなく開始されます。現在、サービスの予約注文を受け付けています。`,
    'view-in-map': `このサービスをマップで見る`,
    'navigate-to': `{serviceName} に移動`,
    'search-tips': `検索できる項目: <ul><li>あらゆる製品タイプ 例: VXC</li><li>あらゆるサービス名</li><li>プロダクト ID</li><li>Megaport が有効なロケーション (マップ設定でオフにしていない場合)</li><li>または世界中のその他のロケーション</li></ul>`,
    'key-items': {
      'occupied-location': `サービスが提供されている単一のロケーション。これらのピンをクリックすると、そのロケーションで利用できるサービスの詳細が表示され、そこで追加サービスを注文できます。`,
      spiderified: `サービスが提供されているロケーションをクリックすると展開され、そのロケーションにあるすべての ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}、${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}、${process.env.VUE_APP_EN_PRODUCTNAME_MVES} と、そのロケーションが表示されます。サービスが稼働中か停止中かを示すために、アイコンは色分けされています。接続されているサード パーティのポートは黒いアイコンで表示されます。アイコンをクリックすると詳細が表示されます。`,
      'occupied-location-cluster': `サービスが提供されているロケーションのクラスター。周囲のリングは、クラスター内で開始される接続のうち、アップ状態またはダウン状態にある接続の割合を示す色で表示されます。クリックして展開します。`,
      'unoccupied-location': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} サービスを利用できる単一のロケーションですが、現在契約されているサービスはありません。クリックするとロケーションの詳細が表示され、そこでサービスを注文できます。マップの設定で、使用しないロケーションの表示をオフにできます。`,
      'unoccupied-location-cluster': `サービスが提供されていないロケーションのクラスター。クリックして展開します。マップの設定で、使用しないロケーションの表示をオフにできます。`,
      'ix-pin': `その都市圏の ${process.env.VUE_APP_EN_PRODUCTNAME_IX} を表す仮想ピン。`,
      'mid-connection': `2 つのロケーション間の接続、またはロケーション クラスターを表すマーカー。1 本の線が複数の接続を表す場合もあります。すべての接続がアップしている場合はマーカーが緑、すべての接続がダウンしている場合は赤、一部のみがアップしている場合は半分ずつになります。マーカーをクリックすると、すべての接続が表示されます。`,
      connections: `ロケーション間の線は接続を表しています。線の色は、接続のアッまたはダウンを示しています。この場合、3 つのアップ接続と 1 つのダウン接続があります。`,
    },
    'partner-count': `- | このロケーションには 1 つのサード パーティの ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 接続があります。- | このロケーションには {count} 個のサード パーティの ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} 接続があります。`,
    'ix-incoming-count': `- | 1 つの受信 ${process.env.VUE_APP_EN_PRODUCTNAME_IX} 接続があります。| {count} 個の受信 ${process.env.VUE_APP_EN_PRODUCTNAME_IX} 接続があります。`,
    fullscreen: `全画面モード`,
    big: `大型ディスプレイ (サービス ウィジェットの表示なし)`,
    small: `小型ディスプレイ (サービス ウィジェットの表示あり)`,
    'fullscreen-error-title': `全画面モードへの移行に失敗`,
    'fullscreen-error-message': `全画面モードを有効にしようとするとエラーが発生します: {errorMessage} ({errorName})`,
    'not-ready-title': `マップの準備ができていません`,
    'not-ready-message': `一部のマップ要素をロード中です。数秒後にもう一度お試しください`,
    'initialization-error': `サービス マップを表示できません: {message}`,
    globe: `地球儀として表示`,
    mercator: `平面 (メルカトル) 投影法として表示`,
  },
  'cancel-reason': {
    label: `キャンセルの理由`,
    'moved-dc': `データ センターから移動した`,
    'order-error': `誤って注文または再注文`,
    'switch-provider': `別のプロバイダーへの切り替え`,
    'proof-of-concept': `概念実証/テスト`,
    other: `理由がリストにない`,
    'project-ended': `プロジェクトの終了`,
    'cloud-native-move': `クラウド・ネイティブ・ソリューションへの移行`,
    pricing: `価格`,
    'product-performance': `製品のパフォーマンス`,
    'feedback-prompt': `キャンセルの理由を詳しくお聞かせください`,
  },
  entitlements: {
    description: `このページには、AWS マーケットプレイスを通じて購入された AWS エンタイトルメントが表示されます。このリストをフィルタリングして、有効期限が切れたエンタイトルメントを表示/非表示にできます。{inUse} のエンタイトルメントにのみサービスが関連付けられていることに注意してください。`,
    'in-use': `使用中`,
    loading: `エンタイトルメントのロード`,
    'no-match': `一致するエンタイトルメントがありません`,
    'service-id': `サービス ID`,
    'agreement-id': `AWS 契約 ID`,
    'vendor-agreement-id': `ベンダー契約 ID`,
    'vendor-account-id': `ベンダー アカウント ID`,
    sku: `SKU`,
    'start-date': `契約開始日`,
    'end-date': `契約終了日`,
    status: `ステータス`,
    'product-type': `プロダクト タイプ`,
    failed: `エンタイトルメントのロードに失敗しました`,
    available: `利用可能`,
    expired: `期限切れ`,
  },
  'service-status': {
    maintenance: `メンテナンス`,
    outages: `障害`,
    timezone: `タイムゾーン`,
    sort: `並べ替え`,
    filter: `フィルター`,
    'search-event-placeholder': `テキストを入力してイベントをフィルターする`,
    status: `ステータス`,
    'start-date': `開始日`,
    ascending: `昇順`,
    descending: `降順`,
    ongoing: `進行中`,
    scheduled: `スケジュール済み`,
    completed: `完了済み`,
    cancelled: `キャンセル済み`,
    resolved: `解決済み`,
    'in-last-7-days': `過去 7 日間`,
    'no-data-text': `選択した条件に一致する結果は見つかりませんでした。フィルターの条件を調整してください。`,
    'start-utc': `開始 (UTC):`,
    'end-utc': `終了 (UTC):`,
    duration: `期間`,
    'resolution-details': `解決策の詳細`,
    'root-cause': `根本的な原因:`,
    resolution: `解決策:`,
    'mitigation-actions': `緩和措置:`,
    'update-notifications': `更新通知`,
    'impacted-services': `影響を受けるサービス`,
    'hide-updates': `過去の更新を非表示`,
    'show-updates': `過去の更新を表示`,
    more: `もっと見る`,
    less: `表示を減らす`,
    'incident-notification': `インシデント通知`,
    'status-update': `次 - インシデント通知`,
    'resolved-status': `解決済み - インシデント通知`,
    'latest-status': `最新 - インシデント通知`,
    'next-update-etr': `次回の更新／ETR:`,
    'notification-description': `現在、サービスに障害が発生しています。サービスの回復を最優先事項として、修復に努めております。`,
    'next-update-text': `サービスの利用が可能になり次第、お知らせいたします。ご迷惑をお掛けして申し訳ありませんが、復旧までしばらくお待ちください。`,
    'maintenance-outages': `メンテナンスと障害`,
    'refresh-error': `イベントを更新できません`,
    'type-label': `タイプ`,
    'name-label': `名前`,
    'id-label': `ID`,
    'actions-label': `アクション`,
    details: `詳細`,
    map: `マップ`,
  },
}
