<template>
  <section id="intro-step-6"
    class="sidebar flex-resize"
    :class="sidebarRootClasses"
    data-testid="portal-sidebar">
    <!-- Checkout Modal -->
    <checkout-modal v-if="showCheckoutModal"
      :visible.sync="showCheckoutModal" />

    <template v-if="hasSidebarItems && !loading">
      <div class="sidebar-content flex-resize">
        <!-- List of Configured Services -->
        <configured-items v-if="configuredItems.length"
          :items="configuredItems"
          :saved-configurations="savedConfigurations" />

        <!-- List of Saved Configurations -->
        <saved-configurations v-if="savedConfigurations.length"
          :saved-configurations="savedConfigurations"
          :configured-items="configuredItems" />

        <!-- Missing Company Warning -->
        <needs-company v-if="sidebarItems.needsCompany" />

        <!-- Missing Billing Markets Warning -->
        <missing-markets v-if="hasMissingMarkets"
          :missing-markets="missingMarkets" />

        <!-- Pending Approval Warning -->
        <pending-approval v-if="pendingApproval.length"
          :pending-approval="pendingApproval" />

        <!-- Missing Technical Support Details Warning -->
        <missing-tech-support-details v-if="showMissingTechSupportDetailsComponent" />
      </div>

      <!-- Expand/Collapse Button -->
      <div class="expander-tab flex-resize"
        @click="setSidebar(!showSidebar)">
        <el-tooltip placement="right"
          :content="showSidebar ? $t('general.hide-actions') : $t('general.show-actions')"
          :open-delay="500"
          class="sidebar-thumb">
          <svg width="25"
            height="120"
            viewBox="0 0 25 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M 0 0 Q 0 8 8 16 L 17 25 Q 25 33 25 41 L 25 79 Q 25 87 17 95 L 8 104 Q 0 112 0 120 L 0 0 Z"
              :fill="expanderFillColor" />
            <path d="M 0 0 Q 0 8 8 16 L 17 25 Q 25 33 25 41 L 25 79 Q 25 87 17 95 L 8 104 Q 0 112 0 120"
              :stroke="expanderBorderColor" />
            <path v-if="showSidebar"
              d="M 15 52 L 7.5 60 L 15 68"
              stroke="black"
              stroke-width="2" />
            <path v-else
              d="M 7.5 52 L 15 60 L 7.5 68"
              stroke="black"
              stroke-width="2" />
          </svg>
        </el-tooltip>
      </div>
    </template>
  </section>
</template>

<script>
// External tools
import Vue from 'vue'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { isEmpty, escape } from 'lodash'
// Internal tools
import { readCssVar } from '@/utils/CssVars.js'
// Components
import CheckoutComponent from '@/components/cart/Checkout.vue'
import ConfiguredItemsComponent from '@/components/sidebar/ConfiguredItems.vue'
import SavedConfigurationsComponent from '@/components/sidebar/SavedConfigurations.vue'
import NeedsCompanyComponent from '@/components/sidebar/NeedsCompany.vue'
import MissingMarketsComponent from '@/components/sidebar/MissingMarkets.vue'
import PendingApprovalComponent from '@/components/sidebar/PendingApproval.vue'
import MissingTechSupportDetailsComponent from '@/components/sidebar/MissingTechSupportDetails.vue'

export default Vue.extend({
  name: 'SideBar',

  components: {
    'checkout-modal': CheckoutComponent,
    'configured-items': ConfiguredItemsComponent,
    'saved-configurations': SavedConfigurationsComponent,
    'needs-company': NeedsCompanyComponent,
    'missing-markets': MissingMarketsComponent,
    'pending-approval': PendingApprovalComponent,
    'missing-tech-support-details': MissingTechSupportDetailsComponent,
  },

  inject: ['disabledFeatures'],

  data() {
    return {
      showCheckoutModal: false,
      hasSidebarBeenToggled: false,
      loading: false,
    }
  },

  computed: {
    ...mapState(['showSidebar']),
    ...mapState('Services', ['services', 'locations', 'serverCarts']),
    ...mapState('Marketplace', ['marketplaceData']),
    ...mapState('IXTypes', ['ixTypes']),
    ...mapGetters('Services', ['findService', 'getLocationById']),
    ...mapGetters('Company', ['companyUid', 'techSupportDetails', 'companySupplyRegions']),
    ...mapGetters('ApplicationContext', ['companyContextLoading', 'isManagedContext']),
    ...mapGetters('Auth', ['isManagedAccount', 'hasAuth', 'isDirectAccount', 'hasOrderedServices', 'hasCompany']),
    ...mapGetters('Markets', ['markets', 'supplyRegions']),

    expanderFillColor() {
      return this.showSidebar ? 'white' : readCssVar('--color-warning-light-8')
    },

    expanderBorderColor() {
      return this.showSidebar ? readCssVar('--card-border-color') : readCssVar('--color-warning')
    },

    sidebarItems() {
      const items = {}
      if (this.companyContextLoading) return items

      // Configured (shopping cart)
      if (this.configuredItems.length) {
        items.configured = this.configuredItems
      }

      // Saved configurations
      if (this.savedConfigurations.length) {
        items.savedConfigurations = this.savedConfigurations
      }

      // No company set up yet. Note that during loading, the company data may not have been loaded yet, so
      // just check that there is a company id set, which will be the case if the data is loaded (the company
      // uid will be the public company if they don't have a company)
      if (this.companyUid && !this.hasCompany) {
        items.needsCompany = true
      }

      // Missing markets
      if (this.hasMissingMarkets) {
        items.missingMarkets = this.missingMarkets
      }

      // Items pending approval:
      if (this.pendingApproval.length) {
        items.pending = this.pendingApproval
      }

      return items
    },

    hasSidebarItems() {
      return !isEmpty(this.sidebarItems)
    },

    hasMissingMarkets() {
      return !isEmpty(this.missingMarkets)
    },

    hasMissingTechSupportDetails() {
      const missing = ['', null]
      if (this.techSupportDetails) {
        const { techSupportPhone = '', techSupportEmail = '' } = this.techSupportDetails
        return missing.includes(techSupportPhone) || missing.includes(techSupportEmail)
      } else {
        return true
      }
    },

    showMissingTechSupportDetailsComponent() {
      return this.isDirectAccount && this.hasMissingTechSupportDetails && !this.hasOrderedServices
    },

    configuredItems() {
      if (this.disabledFeatures.configuredServices) return []

      const listOfCartItems = []

      for (const service of this.services) {
        // Handle the service itself (Port, MCR, or MVE)
        if (service.provisioningStatus === this.G_PROVISIONING_DESIGN) {
          const isMve = service.productType === this.G_PRODUCT_TYPE_MVE
          const isPort = service.productType === this.G_PRODUCT_TYPE_MEGAPORT
          const serviceTypeUrlParam = isPort ? 'port' : 'mcr'

          listOfCartItems.push({
            productUid: service.productUid,
            productName: service.productName,
            productType: service.productType,
            editLink: isMve ? `/mve/${service.productUid}` : `/create-megaport/${serviceTypeUrlParam}/${service.productUid}`,
            displayDetails: (() => {
              const locationName = this.getLocationById(service.locationId)?.name
              const htmlReadyLocationName = escape(locationName)
              const details = [htmlReadyLocationName]
              if (service.config?.diversityZone) {
                details.push(`<span title="${this.$t(`services.${service.config.diversityZone}-zone`)}"> - ${this.$t('services.diversity-zone')} <i class="far fa-dot-circle diversity-color-${service.config.diversityZone}"></i></span>`)
              }
              return details.join(' ')
            })(),
            service,
            lagPortCount: service.lagPortCount,
          })
        }

        const inDesignConnections = [...service.associatedVxcs, ...service.associatedIxs]
          .filter(connection => connection.provisioningStatus === this.G_PROVISIONING_DESIGN)

        for (const connection of inDesignConnections) {
          // We can have the connection in there from both ends, so make sure we only add it once.
          if (listOfCartItems.find(item => item.productUid === connection.productUid)) continue

          listOfCartItems.push({
            productUid: connection.productUid,
            productName: connection.productName,
            productType: connection.productType,
            editLink: `/create-connection/${connection.productUid}`,
            displayDetails: (() => {
              if (connection.productType === this.G_PRODUCT_TYPE_IX) {
                if (!this.ixTypes[connection.locationId]) {
                  this.getIxType(connection.locationId)
                } else {
                  const foundInfo = this.ixTypes[connection.locationId]
                    .find(loc => loc.name === connection.networkServiceType)
                  if (foundInfo) {
                    return escape(foundInfo.description || foundInfo.name)
                  }
                }
                return escape(connection.networkServiceType)
              }

              const from = this.findService(connection.aEnd.productUid)
              const to = this.findService(connection.bEnd.productUid)

              if (from && to) {
                const fromTitle = from.productName
                const toTitle = connection.connectType === 'TRANSIT'
                  ? to.title
                  : to.productName || `${to.companyName} ${to.title}`
                return escape(`${fromTitle} => ${toTitle}`)
              }

              return ''
            })(),
            service: connection,
            portUid: connection.productUid,
          })
        }
      }

      return listOfCartItems
    },

    savedConfigurations() {
      if (this.disabledFeatures.configuredServices) return []

      let savedConfigurations = this.serverCarts.filter(cart => !cart.deploying)

      const serviceOrderUid = localStorage.getItem(`_mpCartId_${this.companyUid}`)
      if (serviceOrderUid) {
        savedConfigurations = savedConfigurations.filter(config => {
          return config.serviceOrderUid !== serviceOrderUid
        })
      }

      return savedConfigurations.sort((a, b) => {
        return a.title.toLowerCase().localeCompare(b.title.toLowerCase())
      })
    },

    missingMarkets() {
      const missingMarkets = {}
      if (this.companyContextLoading
        || this.isManagedAccount
        || (this.isManagedContext && !this.hasAuth('financials'))) {
        return missingMarkets
      }

      for (const port of this.services) {
        const location = this.locations.find(loc => port.locationId === loc.id)
        if (
          location &&
          location.market &&
          !this.supplyRegions.includes(location.market) &&
          !this.companySupplyRegions.includes(location.market)
        ) {
          const market = this.markets.find(m => {
            return m.market === location.market
          })
          if (market) {
            missingMarkets[market.market] = { location, market }
          }
        }
      }

      return missingMarkets
    },

    pendingApproval() {
      const pending = []
      this.services.map(port => port.associatedVxcs.map(connection => {
        if (connection.vxcApproval && connection.vxcApproval.status === this.G_PROVISIONING_PENDING_INTERNAL) {
          let message = ''
          if (connection.vxcApproval.type === 'SPEED_CHANGE') {
            message = this.$t('connections.speed-change-from')
          } else {
            message = this.$t('connections.new-connection-from')
          }
          // As per Conflict.vue all internal approvals should just say the service owner
          message += ` ${connection.companyName ? connection.companyName : this.$t('general.unknown')}`

          // If only the top level services have been loaded, we need to trigger a load of the full data for the service.
          if (!connection.companyUid) {
            // eslint-disable-next-line vue/no-async-in-computed-properties
            this.fetchServiceOrConnection({ productUid: connection.productUid })
              .catch(() => { /* empty function is intentional */ })
          }
          const marketplaceProfile = this.marketplaceData.find(mp => connection.companyUid === mp.companyUid) || {}
          pending.push({
            ...connection,
            request: message,
            link: `/edit-connection/${connection.productUid}`,
            marketplaceProfile,
          })
        }
      }))
      return pending
    },

    sidebarRootClasses() {
      return {
        'sidebar-collapsed': !this.showSidebar && this.hasSidebarItems && !this.loading,
        'no-sidebar': !this.hasSidebarItems || this.loading,
      }
    },
  },

  watch: {
    services: {
      handler() {
        this.storeNetworkDesignLocally()
      },
      deep: true,
    },
    configuredItems(newValue) {
      if (newValue.length) this.initialShowPanel()
    },
    pendingApproval(newValue) {
      if (newValue.length) this.initialShowPanel()
    },
  },

  created() {
    this.$root.$on('showActionAside', () => {
      this.setSidebar(true)
    })
    // Need to leave this at this level rather than moving it to the configured items since we still
    // want to show it even when the configured services option is turned off so the configured items
    // panel wouldn't show.
    this.$root.$on('showOrderPanel', () => {
      this.showCheckoutModal = true
    })
  },

  beforeDestroy() {
    this.$root.$off('showActionAside')
    this.$root.$off('showOrderPanel')
  },

  methods: {
    ...mapMutations(['setSidebar']),
    ...mapActions('Services', ['fetchServiceOrConnection', 'storeNetworkDesignLocally']),
    ...mapActions('IXTypes', ['getIxType']),

    /**
     * Check if sidebar has already been opened once
     * Side effect from watching configuredItems
     */
    initialShowPanel() {
      this.loading = true
      if (!this.hasSidebarBeenToggled) {
        this.hasSidebarBeenToggled = true
        this.setSidebar(true)
      }
      this.loading = false
    },
  },
})
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex: 0 0 280px;
  position: relative;
  background-color: var(--color-white);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 18px 12px;
  z-index: 5;

  .el-button {
    width: 100%;
  }

  h4 {
    text-align: center;
    background-color: var(--color-info-light-9);
    padding: 10px 0;
  }
}

.expander-tab {
  cursor: pointer;
  position: absolute;
  top: calc(50vh - 40px);
  left: 300px;
  width: 25px;
  height: 120px;
  z-index: 5;
}
.sidebar-content {
  width: 280px;
  padding: 10px;
  border-right: 1px solid var(--card-border-color);
}
.sidebar-collapsed {
  flex: 0 0 0px;
  width: 0px;

  .sidebar-content {
    transform: translateX(-300px);
    border-right: none;
  }

  .expander-tab {
    left: 0px;
  }
}

.no-sidebar {
  flex: 0 0 0px;
  border-right: none;
}
</style>
