export default {
  productNames: {
    port: `${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    ports: `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    mcr: `${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    mcrs: `${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}`,
    mcrLong: `${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG}`,
    mve: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    mveLong: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE_LONG}`,
    vxc: `${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    vxcs: `${process.env.VUE_APP_EN_PRODUCTNAME_VXCS}`,
    ix: `${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    ixs: `${process.env.VUE_APP_EN_PRODUCTNAME_IXS}`,
    megaIx: `${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX}`,
    lag: `${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    lags: `${process.env.VUE_APP_EN_PRODUCTNAME_LAGS}`,
    lagLong: `${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG}`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    marketplaceShort: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    transitVxc: `${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}`,
    mves: `${process.env.VUE_APP_EN_PRODUCTNAME_MVES}`,
  },
  companyInfo: {
    name: `${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    portalUrl: `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_URL}`,
    salesEmail: `${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}`,
    arGlobalEmail: `${process.env.VUE_APP_EN_COMPANYINFO_AR_GLOBAL_EMAIL}`,
    portal: `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}`,
  },
  services: {
    'loading-services': `Cargando Servicios...`,
    'no-matching': `No se encontraron servicios coincidentes.`,
    'diversity-zone': `Zona de Diversidad`,
    'create-diverse-pair': `Crear Par Diverso`,
    'red-zone': `Zona Roja`,
    'blue-zone': `Zona Azul`,
    'invoice-reference': `Referencia de nivel de servicio`,
    'invoice-reference-explanation': `La referencia de nivel de servicio aparecerá en un nivel de línea de la factura para el producto/servicio asociado`,
    'rate-limit': `Límite de Velocidad`,
    'select-rate-limit': `Seleccionar Límite de Velocidad`,
    'max-rate-limit': `Límite de Velocidad Máxima`,
    'service-key': `Clave de Servicio`,
    'service-deleted': `Este servicio se ha quitado de los datos. Redireccionando a los servicios`,
    'configuration-details': `Detalles de configuración`,
    'service-logs': `Registros de Servicio`,
    reloading: `Se está volviendo a cargar la información del servicio. Espere un momento.`,
    'initial-bgp-state': `Estado BGP Inicial`,
    saved: `Se ha guardado la configuración.`,
    'service-id': `ID del servicio`,
    'provisioning-status': `Estado de Aprovisionamiento`,
    'loading-status': `Cargando estado del servicio...`,
    'unknown-status': `No se puede determinar el estado de este servicio`,
    media: `Soporte físico`,
    speed: `Velocidad`,
    allocated: `Asignado`,
    demarcation: `Demarcación`,
    up: `El servicio está activo`,
    down: `El servicio está inactivo`,
    'check-logs': `Consulte los registros para obtener más información`,
    'product-name': `Nombre del producto`,
    'rate-limit-fixed': `Nota: El límite de velocidad no se puede modificar para este tipo de servicio tras la implementación.`,
    'fixed-rate': `(velocidad fija)`,
    'service-status': `Estado del Servicio`,
    'service-information': `Información del Servicio`,
    'loading-resources': `Cargando recursos. Espere.`,
    'loading-issue': `Se ha producido un problema al cargar los datos. Póngase en contacto con Soporte Técnico`,
    'identifier-copy': `Identificador del Servicio; haga clic para copiarlo`,
    decommissioned: `Este servicio ya está dado de baja`,
    filter: `Filtrar Servicios`,
    'expand-collapse': `Expandir o contraer todos los servicios`,
    'some-speeds-disabled': `Nota: Algunas velocidades pueden deshabilitarse porque un servicio vinculado supere esa velocidad.`,
    'diversity-option': `Opción de Diversidad`,
    'loading-state-info': `Cargando información del servicio actualizado.`,
    'waiting-on-deploy': `Implementación del servicio en curso`,
    'no-company': `Ninguna empresa disponible.`,
    'cancelled-when': `El servicio cancelado terminará el {when}.`,
    'click-restore': `Haga clic para restaurar el servicio`,
    'cancel-unknown': `El servicio cancelado terminará en una fecha desconocida.`,
    'pending-internal-speed-approval': `En espera de su aprobación para una solicitud de cambio de velocidad de {where}`,
    'pending-internal-connection-approval': `En espera de su aprobación para una conexión de {where}`,
    'pending-external-connection-approval': `En espera de aprobación externa para una conexión de {where}`,
    'pending-3rd-party-speed-approval': `En espera de aprobación externa para un cambio de velocidad de {where}`,
    'pending-3rd-party-connection-approval': `En espera de aprobación externa para una conexión de {where}`,
    'gci-waiting-configure': `En espera de una sesión BGP entrante`,
    'waiting-for-resources': `Este servicio está a la espera de iniciarse por primera vez.`,
    'in-design-not-deployed': `No implementado aún. Edite los datos y complete la implementación.`,
    'in-design-not-ordered': `No solicitado aún. Haz el pedido utilizando la barra lateral de la izquierda.`,
    'approve-connections': `Aprobar Conexiones`,
    'approve-connections-list': `Tiene las siguientes conexiones pendientes de aprobación:`,
    'best-route': `Mejor Ruta`,
    'next-hop-vxc': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} de Siguiente Salto`,
    bgp: `BGP`,
    'type-locked': `Este {type} está bloqueado`,
    'type-being-deployed': `Este {type} se está implementando`,
    'type-unlock': `Haga clic para desbloquear este {type} y permitir la edición`,
    'type-unlock-notlive': `Este {type} no está activo y no se puede desbloquear`,
    'type-locked-admin': `Este {type} está bloqueado por un administrador y no es editable`,
    'type-lock': `Haga clic para bloquear este {type} y evitar que lo editen y eliminen`,
    'type-lock-notlive': `Este {type} no está activo y no se puede bloquear`,
    'type-unlocked-admin': `Este {type} está desbloqueado y solo lo puede bloquear un administrador`,
    'type-locked-megaport': `Este {type} lo ha bloqueado ${process.env.VUE_APP_EN_COMPANYINFO_NAME} y no es editable. Póngase en contacto con Soporte Técnico de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} para obtener más información.`,
    'type-cancelled': `Este {type} se ha cancelado`,
    'terminate-count': `N/D | Sí, Terminar 1 Servicio | Sí, Terminar {count} Servicios`,
    'service-locked': `Nota: Este servicio está bloqueado por ${process.env.VUE_APP_EN_COMPANYINFO_NAME} y no se puede editar ni eliminar. Póngase en contacto con el Soporte Técnico de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} para obtener más información.`,
    'service-admin-locked': `Nota: Este servicio está bloqueado y no se puede editar ni eliminar. Solo puede desbloquear este servicio un Administrador de Empresa.`,
    'edit-permission-denied': `Nota: No tiene permiso para editar este servicio.`,
    'ip-address': `Dirección IP`,
    'peer-ip-address': `Dirección IP del Par`,
    'creation-date': `Fecha de Creación`,
    'replace-configured': `Sustituir servicios configurados`,
    'replace-confirmation': `Los elementos configurados actualmente se eliminarán y serán reemplazados por este conjunto guardado. ¿Quieres continuar?`,
    'delete-configuration': `Eliminar configuración guardada`,
    // El nombre es el nombre del puerto/mcr/mve y el tipo de producto (productType) es el tipo de objeto tipo puerto que se ha actualizado (puerto, MCR, MVE)
    'port-like-updated-title': `{name} {productType} actualizado`,
    'port-like-updated-message': `Actualizaciones guardadas para {productType} "{name}"`,
    'click-setup': `Haga clic para configurar`,
    'new-mve-message': `¡Atención! La conexión a Internet es obligatoria para la validación de la imagen o el software${process.env.VUE_APP_EN_PRODUCTNAME_MVE}. Puedes activar ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} o conectar tu propio acceso a Internet.`,
    'connection-denied': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} a {destination} fue denegado`,
    'connection-approved': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} a {destination} fue aprobado`,
    'amsix-contact-phone': `Es necesario un número de teléfono para el {contactType} para un servicio AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX}.`,
    'gbps-ix': `Gbps ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'gbps-vxc': `Gbps ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    'ibm-request-fail': `No se han podido recuperar los límites de velocidad para este puerto, inténtalo de nuevo.`,
    'type-state': `Estado de {type}`,
    'shutdown-dialog-title': `¿Seguro que quieres apagar este servicio?`,
    'shutdown-dialog-message': `El tráfico no fluirá a través de este servicio si se apaga. Se comportará como si estuviera caído en la red de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'shutdown-dialog-note': `Nota: La facturación de este servicio seguirá activa y se te seguirá cobrando por esta conexión.`,
    'sub-uid': `Sub-UID`,
    'type-move-notlive': `Este {type} no está activo y no se puede mover`,
    'port-move-notlive': `Este {type} no está activo y sus conexiones no se pueden mover`,
    'no-movable-type': `No hay conexiones que se puedan mover en este {type}`,
    'move-permission-denied': `No tienes permiso para mover conexiones`,
    'cant-move-multiple-vnics': `No se pueden mover conexiones desde un ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} con varios vNIC`,
    'cant-move-no-ports': `No hay más ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} en la misma área metropolitana`,
    'cant-move-no-mcrs': `No hay más ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} en la misma área metropolitana`,
    'cant-move-no-mves': `No hay más ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} en la misma área metropolitana`,
    'awaiting-approval': `Esperando aprobación o rechazo de los cambios pendientes`,
    'shutdown-confirm-message': `Para apagarlo, haga clic en "Confirm" (Confirmar) y luego en "Save" (Guardar).`,
    'view-additional-filters': `Ver filtros adicionales`,
    'clear-filters': `Borrar filtros`,
    'service-type': `Tipo de servicio`,
    'contract-term': `Período de vigencia del contrato`,
    'change-sort-direction': `Cambiar la dirección de ordenación`,
    'matching-services': `0 servicios coincidentes ({total} en total) | 1 servicio coincidente ({total} en total) | {count} servicios coincidentes ({total} en total)`,
    'diversity-location-tooltip': `Filtrar ubicaciones por zonas de diversidad disponibles. Si lo deja en "Auto" (Automático), seleccionaremos una zona de diversidad para usted. Para obtener más información, consulte nuestro {link}`,
    'diversity-config-tooltip': `Zona de diversidad a la que se destinará este servicio.`,
    'auto-zone': `Zona automática`,
    'download-inventory-report': `Descargar informe del inventario`,
    'expand-all': `Expandir todos los servicios`,
    'collapse-all': `Contraer todos los servicios`,
    'extra-actions': `Acciones adicionales`,
    'type-status': `Tipo y estado`,
    'has-ongoing-maintenance': `Evento de mantenimiento en curso.`,
    'has-ongoing-outage': `Evento de interrupción de servicio en curso.`,
  },
  app: {
    'environment-unrecognized': `Entorno no reconocido`,
    'unrecognized-url': `Acceso desde ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_URL} o una URL de desarrollo reconocida`,
  },
  general: {
    'delete-type': `Eliminar {type}`,
    'edit-type': `Editar {type}`,
    'remove-type': `Quitar {type}`,
    'new-type': `Nuevo {type}`,
    'add-type': `Añadir {type}`,
    'update-type': `Actualizar {type}`,
    'type-details': `Detalles de {type}`,
    'type-configuration': `Configuración de {product}`,
    'edit-configured-type': `Editar {type} configurado`,
    'select-type-location': `Seleccionar Ubicación de {type}`,
    'delete-permission-denied': `No tiene permiso para eliminar este {type}`,
    'type-not-found': `{type} no encontrado`,
    'type-not-found-redirect': `No se ha encontrado este {type} en los datos. Redireccionando a los servicios`,
    'success-count': `0 operaciones correctas | 1 operación correcta | {count} operaciones correctas`,
    'warning-count': `0 advertencias | 1 advertencia | {count} advertencias`,
    'error-count': `0 errores | 1 error | {count} errores`,
    'count-months': `0 meses | 1 mes | {count} meses`,
    'locations-count': `0 ubicaciones | 1 ubicación | {count} ubicaciones`,
    'last-log': `Último registro recibido: {last}`,
    'max-rate-limit': `Máx.: {maxRateLimit} Mbps`,
    'speed-gbps': `{speed} Gbps`,
    'missing-billing-market': `Falta el mercado de facturación de "{country}".`,
    'create-thing': `Crear {thing}`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    welcome: `Bienvenido a ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    save: `Guardar`,
    revert: `Revertir`,
    close: `Cerrar`,
    cancel: `Cancelar`,
    back: `Atrás`,
    next: `Siguiente`,
    done: `Listo`,
    loading: `Cargando...`,
    services: `Servicios`,
    service: `Servicio`,
    price: `Precio`,
    summary: `Resumen`,
    copy: `Copiar`,
    'copy-to-clipboard': `Copiar al Portapapeles`,
    'characters-copied': `0 caracteres copiados | 1 carácter copiado | {count} caracteres copiados`,
    version: `Versión`,
    'first-name': `Nombre`,
    'last-name': `Apellidos`,
    phone: `Teléfono`,
    email: `Correo electrónico`,
    password: `Contraseña`,
    ok: `Aceptar`,
    'details-help': `Para obtener más detalles, consulte este tema de ayuda:`,
    documentation: `Documentación`,
    saving: `Guardando...`,
    public: `Público`,
    private: `Privado`,
    unspecified: `No especificado`,
    'x-end': `Extremo {end}`,
    hide: `ocultar`,
    show: `mostrar`,
    'technical-contact': `contacto técnico`,
    'noc-contact': `contacto NOC`,
    'contact-phone': `número de teléfono de contacto`,
    'select-type': `Seleccionar Tipo`,
    size: `Tamaño`,
    'step-unavailable': `Esta opción no está disponible hasta que complete los pasos anteriores.`,
    'not-available': `No disponible`,
    'configure-thing': `Configurar {thing}`,
    'modification-delay': `Nota: Es posible que las modificaciones aplicadas tarden hasta 2 minutos en aparecer.`,
    billing: `Facturación`,
    'processing-approval': `Procesando aprobación...`,
    'processing-denial': `Procesando denegación de solicitud...`,
    unknown: `Desconocido`,
    yes: `Sí`,
    no: `No`,
    untitled: `Sin título`,
    'no-location': `No se ha seleccionado ninguna ubicación`,
    'select-location': `Seleccionar Ubicación`,
    'unknown-location': `Ubicación Desconocida`,
    configure: `Configurar`,
    'no-matching-countries': `No coincide ningún país`,
    'no-matching-locations': `No coincide ninguna ubicación`,
    'country-filter': `Filtro de países`,
    search: `Buscar`,
    'pre-order': `Pedir por adelantado`,
    details: `Detalles`,
    name: `Nombre`,
    success: `Correcto`,
    disabled: `Deshabilitado`,
    enabled: `Habilitado`,
    'shut-down': `Apagar`,
    term: `Periodo de vigencia`,
    red: `Rojo`,
    blue: `Azul`,
    none: `Ninguno`,
    new: `Nuevo`,
    general: `General`,
    status: `Estado`,
    'status-unknown': `Estado desconocido`,
    'created-by': `Creado Por`,
    'privacy-policy': `Política de Privacidad`,
    'site-terms': `Términos del Sitio`,
    gsa: `Acuerdo de Servicios Globales`,
    'acceptable-use': `Política de Uso Aceptable`,
    'please-select': `Seleccionar`,
    'important-information': `Información Importante`,
    'agree-terms': `Acepto los términos y condiciones`,
    'view-details': `Ver Detalles`,
    'action-confirmation': `¿Seguro que quiere seguir?`,
    'unknown-error': `Error desconocido`,
    edit: `Editar`,
    remove: `Quitar`,
    'request-failed': `Error de Solicitud`,
    'company-name': `Nombre de la Empresa`,
    active: `Activa`,
    inactive: `Inactiva`,
    na: `N/D`,
    disable: `Deshabilitar`,
    pricing: `Precios`,
    note: `Nota`,
    website: `Sitio web`,
    'click-to-visit': `Haga clic para acceder`,
    all: `Todo`,
    ipv4: `IPv4`,
    ipv6: `IPv6`,
    you: `usted`,
    'your-company-admin': `el administrador de su empresa`,
    date: `Fecha`,
    mbps: `Mbps`,
    start: `Inicio`,
    end: `Fin`,
    duration: `Duración`,
    'loading-details': `Cargando detalles...`,
    'details-load-failed': `Error al cargar los detalles`,
    prefix: `Prefijo`,
    'next-hop': `Siguiente Salto`,
    'load-failed': `Error al Cargar`,
    to: `Para`,
    from: `De`,
    message: `Mensaje`,
    send: `Enviar`,
    sort: `Ordenar`,
    'hide-details': `Haga clic para ocultar los detalles`,
    'show-details': `Haga clic para mostrar los detalles`,
    'logs-label': `Registros`,
    'usage-label': `Uso`,
    'configuration-label': `Configuración`,
    actions: `Acciones`,
    confirm: `Confirmar`,
    delete: `Eliminar`,
    'reload-data': `Volver a cargar datos`,
    network: `Red`,
    location: `Ubicación`,
    selection: `Selección`,
    'choose-file': `Escoger archivo`,
    label: `Etiqueta`,
    'single-use': `Único uso`,
    'multi-use': `Multiuso`,
    add: `Añadir`,
    'select-date': `Seleccionar Fecha`,
    'loading-data': `Cargando datos`,
    description: `Descripción`,
    'click-copy': `Haga clic para copiar`,
    token: `Token`,
    notice: `Aviso`,
    'end-date': `Fecha de Terminación`,
    existing: `existente`,
    'no-info': `No hay más información disponible`,
    'error-updating': `Error al actualizar {thing}.`,
    'click-enable': `Haga clic para habilitar`,
    'updating-details': `Actualizando detalles...`,
    approve: `Aprobar`,
    view: `Ver`,
    'save-as': `Guardar como`,
    order: `Realizar pedido`,
    'show-actions': `Mostrar acciones`,
    'hide-actions': `Ocultar acciones`,
    'items-per-page': `{count} elementos por página`,
    'show-all': `Mostrar todos los elementos`,
    origin: `Origen`,
    communities: `Comunidades`,
    updated: `Actualizado`,
    'all-types': `Todos los tipos`,
    static: `Estático`,
    connected: `Conectado`,
    local: `Local`,
    file: `Archivo`,
    clear: `Borrar`,
    'no-match-found': `No se ha encontrado ninguna coincidencia`,
    'list-not-available': `No hay lista disponible`,
    'list-select': `Seleccione una lista`,
    'password-toggle': `Alterne para que se muestre la contraseña`,
    'exact-label': `Exacta`,
    'position-label': `Posición`,
    'min-label': `Mín.`,
    'max-label': `Máx.`,
    'operations-label': `Operaciones`,
    'drag-position-label': `Haga clic y arrastre hasta la posición`,
    'example-abbr': `P. ej.`,
    star: `asterisco`,
    'standard-label': `Estándar`,
    'advanced-label': `Avanzado`,
    'filters-label': `Filtros`,
    'on-label': `Activado`,
    'off-label': `Desactivado`,
    'default-label': `Predeterminado`,
    'deny-label': `Denegar`,
    'permit-label': `Permitir`,
    'required-field-label': `Campo obligatorio`,
    'warning-label': `Advertencia`,
    'type-name': `Nombre de {type}`,
    'save-error': `No se han podido guardar los cambios: `,
    'copy-thing-to-clipboard': `Copiar {thing} al Portapapeles`,
    'error-creating': `Error al crear {thing}.`,
    'error-deleting': `Error al eliminar {thing}.`,
    'success-creating': `{thing} se ha creado correctamente.`,
    'success-updating': `{thing} se ha actualizado correctamente.`,
    'success-deleting': `{thing} se ha eliminado correctamente.`,
    'created-thing': `Se creó {thing} correctamente`,
    'updated-thing': `Se actualizó {thing} correctamente`,
    'word-counter': `{count} palabras de {maxWords}`,
    user: `Usuario`,
    load: `Cargar`,
    update: `Actualizar`,
    'server-error': `Error al comunicar con servidor de API`,
    'context-switching-title': `¿Seguro que quiere cambiar cuentas?`,
    'context-switching-message': `Está a punto de cambiar de su {accountTypeFrom} <strong>{fromCompany}</strong> a {accountTypeTo} <strong>{toCompany}</strong>`,
    'feature-flag-access-error': `No se puede acceder a {pageName} desde una cuenta gestionada. Seleccione su cuenta de socio para acceder a {pageName}.`,
    'confirm-password-mismatch': `La contraseña no coincide con la contraseña indicada anteriormente`,
    'open-sidebar': `Abrir barra lateral`,
    'learn-megaport': `Obtén más información sobre los servicios de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} o visita el ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
    'visit-documentation': `Ve al ${process.env.VUE_APP_EN_COMPANYINFO_DOCUMENTATION_PORTAL}`,
    'visit-portal': `Visita el ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}`,
    'login-trouble': `¿Tiene problemas para iniciar sesión? {link}`,
    'site-disclaimer': `Al acceder al ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL} / la página de inicio, debes respetar la política de privacidad, las condiciones del sitio y la política de uso aceptable de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'partner-deals': `Ofertas para socios`,
    'cancel-reterm': `Haga clic en Cancelar si no desea realizar ninguna acción y pasar a no tener plazo de contrato al precio de lista al final del plazo de contrato.`,
    'deleted-thing': `{thing} se eliminó con éxito`,
    'unexpected-error': `Se ha producido un error inesperado. Inténtalo de nuevo más tarde. Si este error persiste, ponte en contacto con ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'unsaved-changes-confirm-message': `Se perderán todos los cambios no guardados`,
    'unsaved-changes-confirm-title': `¿Deseas salir de la página sin guardar?`,
    'unsaved-changes-confirm-cancel': `Permanecer en la página`,
    'unsaved-changes-confirm-ok': `Salir de la página`,
    'not-now': `Ahora no`,
    up: `Arriba`,
    down: `Abajo`,
    beta: `Beta`,
    languages: `Idiomas`,
    expand: `Expandir`,
    collapse: `Contraer`,
    here: `aquí`,
    true: `verdadero`,
    false: `falso`,
    'get-help': `Recibir ayuda`,
    'type-shutdown-state': `Nota: Este {type} está en estado de apagado`,
    continue: `Continuar`,
    accept: `Aceptar`,
    decline: `Rechazar`,
    'terms-and-conditions': `Términos y condiciones`,
    'lag-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_LAGS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_LAGS}`,
    'mcr-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}`,
    'mve-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES}`,
    connection: `Conexión`,
    connections: `Conexiones`,
    'move-to': `Ir a`,
    'not-submitted': `No enviado`,
    processing: `Procesando`,
    failure: `Fallo`,
    move: `Mover`,
    'select-all': `Seleccionar todo`,
    'deselect-all': `Deseleccionar todo`,
    'non-prod': `Este es un entorno de no producción`,
    auto: `Automático`,
    'preparing-download': `Preparando la descarga...`,
    'download-failed-title': `Error de descarga`,
    'download-failed': `No se pudo descargar el archivo, vuelva a intentarlo más tarde.`,
    mobile: `Móvil`,
    activate: `Activar`,
    deactivate: `Desactivar`,
    valid: `Válido`,
    invalid: `No válido`,
    type: `Tipo`,
    expired: `Vencido`,
    comments: `Comentarios`,
    refresh: `Actualizar`,
    'pre-release': `Prelanzamiento`,
    'logs-count': `1 entrada de registro recibida en las últimas 24 horas | {count} entradas de registro recibidas en las últimas 24 horas`,
    'most-recent-n-events': `{count} eventos más recientes mostrados`,
    'no-logs': `No hay registros para este servicio`,
  },
  tour: {
    'start-tour': `Iniciar Recorrido`,
    'find-location': `Buscar una ubicación`,
    'location-selected': `Ubicación Seleccionada`,
    'add-a-service': `Añadir un Servicio`,
    'actions-panel': `Panel de Acciones`,
    'live-chat': `Chat en Directo Ininterrumpido`,
    'next-steps': `Siguientes Pasos`,
    start: `Aquí es donde puede crear, implementar, y administrar servicios como ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}, ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}, ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} y ${process.env.VUE_APP_EN_PRODUCTNAME_IXS}.<br/><br/>Haga clic en Siguiente para continuar.`,
    'intro-port': `Un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} es su conexión a la infraestructura de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} y la puerta de enlace a una multitud de puntos de conexión Cloud, Internet Exchange, y otros.<br/><br/>Haga clic en Siguiente para crear un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'select-port': `El primer paso es seleccionar la ubicación del ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.<br/><br/>Haga clic en Siguiente para seleccionar una ubicación.`,
    'location-selected-content': `Una vez seleccionada la ubicación, configure el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.<br/><br/>Haga clic en Siguiente para configurar el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'port-configuration': `Configuración de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'configure-port': `Configure su ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} introduciendo la información necesaria.<br/><br/>Haga clic en Siguiente para añadir información sobre el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    price: `Cuando haya proporcionado información sobre el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, se mostrará el precio.<br/><br/>Haga clic en Siguiente para ir a la siguiente pantalla.`,
    summary: `Este es el paso final de la configuración de su ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}. No se le emitirá ningún cargo hasta que realice el pedido.<br/><br/>Haga clic en Siguiente para añadir el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'port-configured': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} configurado`,
    'configured-port': `Información sobre ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} proporcionada.<br/><br/>Haga clic en Next (Siguiente) para ir a la siguiente pantalla.`,
    'add-service': `El siguiente paso es añadir un servicio al ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} que acaba de configurar. Haga clic en el icono +Conexión que encontrará a la derecha del ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} o elija una de las siguientes.`,
    'actions-panel-content': `Durante esta demostración, se muestra aquí el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} configurado junto con cualquier otra acción necesaria para que funcione. <br/><br/>Si tiene alguna acción pendiente de completar, aparecerá en la barra lateral.`,
    chat: `¿Necesita ayuda? Haga clic aquí para iniciar una conversación en tiempo real con un experto de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'next-steps-no-kb': `Ya ha llegado al final del recorrido. <br/><br/>Ahora ya está preparado para visitar la página de servicios y crear su primer ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'next-steps-kb-html': `Ya ha llegado al final del recorrido. <br/><br/>No se olvide de visitar nuestra [Documentación](https://docs.megaport.com/) o nuestro [canal de YouTube](https://www.youtube.com/channel/UCdVx98wdrhcTCIuw8UE5hIA) para obtener ayuda.<br/><br/>Ahora ya está preparado para visitar la página de Servicios y crear su primer ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'next-steps-partial-html': `Ya ha llegado al final del recorrido. <br/><br/>No se olvide de visitar nuestra [Documentación](https://docs.megaport.com/) o nuestro [canal de YouTube](https://www.youtube.com/channel/UCdVx98wdrhcTCIuw8UE5hIA) para obtener ayuda.<br/><br/>Ahora ya está preparado para crear su primer ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    exit: `Salir del Recorrido`,
  },
  about: {
    why: `¿Por qué ${process.env.VUE_APP_EN_COMPANYINFO_NAME}?`,
    provisioning: `Aprovisiona conexiones en 59 segundos en la red ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    scalable: `Conectividad Escalable`,
    'scalable-content': `Aumente o reduzca el ancho de banda en cualquier momento para adaptarse a las exigencias de su empresa.`,
    'real-time': `Aprovisionamiento en Tiempo Real`,
    api: `Potente Integración de API`,
    'api-content': `Administre su conectividad de forma autónoma y optimice su flujo de trabajo aprovechando nuestra potente API.`,
    'about-megaport': `Acerca de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'about-megaport-video': `Vídeo Acerca de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'megaport-features': `Características de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
  },
  authentication: {
    'about-company': `Acerca de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'password-reset': `Es necesario que restablezca la contraseña de su cuenta. Crea aquí una nueva contraseña para iniciar sesión.`,
    'setting-up': `Configurando su cuenta...`,
    'password-strength': `Seguridad de la Contraseña`,
    'very-weak': `Muy Débil`,
    weak: `Débil`,
    moderate: `Moderada`,
    good: `Buena`,
    excellent: `Excelente`,
    'good-password': `Es una contraseña buena`,
    'excellent-password': `Es una contraseña excelente`,
    agreement: `Acuerdo`,
    'agree-to': `Acepto `,
    'create-account': `Crear Cuenta`,
    google: `Iniciar sesión con Google`,
    'have-account': `¿Ya tiene una cuenta?`,
    'log-in': `Iniciar Sesión`,
    'one-time-token': `Token de un Solo Uso`,
    'new-password': `Su nueva contraseña`,
    'target-username': `Nombre de Usuario Objetivo`,
    forgot: `He Olvidado la Contraseña`,
    register: `Regístrese Ahora`,
    'processing-reset': `Procesando petición de reinicio...`,
    'send-reset': `Enviar Restablecimiento`,
    resetting: `Restableciendo Contraseña...`,
    reset: `Restablecer Contraseña`,
    'trying-login': `Intentando iIniciar Sesión...`,
    'please-login': `Iniciar Sesión`,
    'failed-login': `Error al iniciar sesión con las credenciales existentes. Inicie sesión de nuevo.`,
    'password-reset-title': `Restablecimiento de Contraseña`,
    'missing-reset-token': `Falta el token de restablecimiento de contraseña. Diríjase a la página de inicio de sesión para enviar una nueva solicitud de contraseña olvidada.`,
    'logging-in': `Iniciando sesión...`,
    'need-to-reset': `Tendrá que restablecer la contraseña. Se ha actualizado el formulario con el campo obligatorio.`,
    failed: `Error al Iniciar Sesión`,
    'error-registering': `Error al Registrar`,
    'unknown-error-registering': `Error desconocido al tratar de registrar`,
    'reset-email-sent': `Le hemos enviado un correo electrónico para que restablezca la contraseña.`,
    'reset-request-failed': `Restablecimiento de Contraseña - Error de Solicitud`,
    'unknown-error-reset': `Error desconocido al tratar de restablecer la contraseña`,
    'password-change-successful': `Hemos cambiado correctamente la contraseña. Ahora ya puede iniciar sesión con la nueva.`,
    'password-reset-failed': `Error al tratar de restablecer la contraseña. Para restablecerla de nuevo, diríjase a la página de inicio de sesión y envíe una nueva solicitud de contraseña olvidada.`,
    'auth-failed': `Error de Autenticación`,
    'auth-failed-message': `Error al autenticar su cuenta`,
    'confirm-email-label': `Confirmando la dirección de correo electrónico`,
    confirming: `Confirmando. Espere`,
    success: `Se ha confirmado su correo electrónico`,
    'failed-confirm': `Error al confirmar su dirección de correo electrónico. Inténtelo de nuevo más tarde.`,
    'return-portal': `Volver al Portal`,
    'registered-company': `Empresa registrada: {name}`,
    'updated-company': `Empresa actualizada: {name}`,
    'login-success-title': `Inicio de Sesión Correcto`,
    'login-success-message': `{name} ha iniciado sesión`,
    'login-failed-token': `Error al iniciar sesión con tu token existente. Por favor, inicia sesión de nuevo.`,
    'use-security-code': `Utilizar código de seguridad`,
    'temp-password': `Su contraseña temporal`,
    'confirm-password': `Confirmar contraseña`,
    'login-error': `Error de inicio de sesión`,
    'login-error-msg': `No podemos iniciar sesión con sus credenciales. ¿Tiene problemas para iniciar sesión? <a href="https://www.megaport.com/contact/support/" target="_blank">Recibir ayuda</a>`,
    submit: `Enviar`,
    'vendor-onboarding': {
      cisco: {
        'intro-header': `Bienvenido a Cisco SD-WAN Cloud Interconnect con ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
        'intro-content': `Nos alegra verte por aquí, y esperamos que tengas una magnífica experiencia utilizando el producto. Unos cuantos pasos más, y ya podrás crear conexiones específicas para tus entornos de nube a través de vManage, y aprovechar Cisco SD-WAN superpuesto encima de la red subyacente de alta disponibilidad de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
        'temp-password-info': `<b>Introduzca su correo electrónico y su contraseña temporal.</b> Tras cambiar su contraseña, puede introducir sus credenciales en Cisco vManage, versión 20.5 o superior, para realizar pedidos para MVE, para crear VXC de MVE a otros destinos en la red de Megaport y para eliminarlos, en lugar de utilizar el ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
        'new-password-info': `<b>Introduzca su nueva contraseña.</b> Tras cambiar su contraseña, puede introducir sus credenciales en Cisco vManage, versión 20.5 o superior, para realizar pedidos para MVE, para crear VXC de MVE a otros destinos en la red de Megaport y para eliminarlos, en lugar de utilizar el ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
        'password-changed-info': `¿Y ahora qué? Introduce estas credenciales en Cisco vManage, versión 20.5 o superior para aprovisionar ${process.env.VUE_APP_EN_PRODUCTNAME_MVES}, o para crear ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} entre distintos ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} y proveedores de servicios de nube.`,
        'temp-password-intro': `Introduce tu correo electrónico y tu contraseña temporal. `,
        'new-password-intro': `Introduce tu nueva contraseña. `,
        'password-changed-intro': `Tu contraseña ha sido cambiada. `,
        'password-info': `Tras cambiar tu contraseña, puedes introducir tus credenciales en Cisco vManage, versión 20.5 o superior, para realizar pedidos para ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}, crear ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} de ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} a otros destinos en la red de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}, y para eliminarlos, en lugar de utilizar el ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
      },
    },
    'verify-email-heading': `Consulte su correo electrónico`,
    'verify-email-subheading': `Le hemos enviado un enlace para que confirme su cuenta`,
    'verify-email-description': `¿No ha recibido un correo electrónico? Consulte su carpeta de correo no deseado para asegurarse de que no está ahí.`,
    'verify-email-button': `Reenviar correo electrónico`,
    'verify-email-resent-successful': `El correo electrónico de confirmación se ha reenviado correctamente.`,
    'verify-email-resent-unsuccessful': `Algo ha ido mal al reenviar tu correo de confirmación.`,
    'password-must-include': `Su contraseña debe`,
    'at-least-8-char': `Tener al menos 8 caracteres`,
    'at-least-one-number': `Tener al menos un número`,
    'at-least-one-symbol': `Tener al menos un símbolo`,
    'at-least-one-lower-case-letter': `Tener al menos una letra minúscula`,
    'at-least-one-upper-case-letter': `Tener al menos una letra mayúscula`,
    'create-an-account': `Cree una cuenta`,
    or: `o`,
    'signup-email': `Regístrese con el correo electrónico`,
    'signup-google': `Regístrese con Google`,
    'login-to-your-account': `Inicie sesión en su cuenta`,
    'signup-success': `Se ha registrado correctamente.`,
    'signup-failed': `Se ha producido un error durante el registro, inténtelo de nuevo.`,
    login: `Inicio de sesión`,
    'login-google': `Inicio de sesión con Google`,
    'setting-company': `Nota: Al guardar el nuevo nombre comercial de la empresa, se cerrará la sesión. Tendrá que volver a iniciar sesión para que se añada a la nueva empresa.`,
    'reset-message': `Si esta cuenta ya existe, se ha enviado un correo electrónico para restablecer la contraseña.`,
    'signup-google-instructions': `Introduzca un nombre comercial de la empresa y revise los términos que aparecen a continuación para continuar.`,
    signup: `Regístrese`,
    'create-account-email': `Crear cuenta con correo electrónico`,
    'confirm-new-password': `Confirmar nueva contraseña`,
    'new-password-no-your': `Nueva Contraseña`,
    'reset-expired-dialog-title': `El enlace de restablecimiento utilizado ya no es válido.`,
    'reset-expired-dialog-desc': `El enlace utilizado en el correo electrónico ha caducado o no es válido. Haz clic en "Resend Link" (Reenviar enlace) o ponte en contacto con ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'resend-link': `Reenviar enlace`,
    'unexpected-error-dialog-title': `Se ha producido un error inesperado. Inténtelo de nuevo más tarde.`,
    'unexpected-error-dialog-desc': `Si este error persiste, ponte en contacto con ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'login-megaport': `Inicia sesión en ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'email-verified': `Se ha verificado su correo electrónico`,
    'invalid-link': `Este enlace ya no es válido`,
    'request-link': `Haga clic en el botón para recibir un nuevo enlace en su correo electrónico registrado.`,
    'verified-account': `Esta cuenta ya está verificada`,
    'verified-email': `Su correo electrónico ya ha sido verificado`,
    'continue-to-megaport': `Continua hasta ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}`,
    'company-trading-name-instructions': `Introduzca un nombre comercial de la empresa y revise los términos que aparecen a continuación para continuar.`,
    'change-email-desc': `Introduzca su nueva dirección de correo electrónico y haga clic en Obtener código de verificación. Se enviará un código de verificación a su nueva dirección de correo electrónico para que lo introduzca a continuación. No cierre esta ventana.`,
    'change-email-warning': `Tenga en cuenta que tendrá que volver a iniciar sesión después de cambiar su correo electrónico.`,
    'verification-code': `Código de verificación`,
    'get-verification-code': `Obtener código de verificación`,
    'verify-email-taken': `Este correo electrónico ya está siendo utilizado por otro usuario.`,
    'verification-code-expired': `El código de verificación ha caducado, solicite un nuevo correo electrónico de verificación`,
    'verification-code-sent': `Le hemos enviado un código de verificación único`,
    'verification-code-failed': `Se ha producido un error al recuperar un código de verificación`,
    'verification-code-wrong': `El código de verificación es incorrecto`,
    'setup-mfa-cancel-confirm': `Si cancelas el proceso, volverás a la pantalla de inicio de sesión. No podrás iniciar sesión hasta que se haya configurado la MFA.`,
    'setup-mfa-reset': `El administrador de tu empresa ha restablecido y desactivado tu código de autenticación multifactor (MFA). Vuelve a configurar la MFA para iniciar sesión.`,
    'setup-mfa-enforced': `El administrador de tu empresa ha impuesto la autenticación multifactor (MFA). No podrás iniciar sesión hasta que se haya configurado.`,
    'enabling-mfa': `Activación de la MFA`,
    'mfa-token-invalid': `El código de un solo uso {code} no es válido. Después de 5 intentos fallidos consecutivos, tu cuenta se bloqueará.`,
    'mfa-long': `Autenticación multifactor`,
    'mfa-enter-token': `La MFA está activada para tu cuenta. Introduce un token para iniciar sesión.`,
    verify: `Verificar`,
    'mfa-verify-token': `Verificando token`,
    'mfa-instructions': `Escanea la imagen anterior con la aplicación de autenticación multifactor de tu teléfono. Si no puedes utilizar un código de barras, introduce | este código de texto | en la aplicación.`,
    'enable-mfa-failed': `Error al activar la autenticación multifactor`,
    'mfa-token-required': `Se requiere un token de autenticación multifactor de un solo uso. Se ha actualizado el formulario con el campo obligatorio.`,
    'login-cognito-unexpected-failure': `Se ha producido un error inesperado durante la autenticación. Por favor, inténtalo de nuevo, o ponte en contacto con tu organización para obtener ayuda.`,
    'login-sso-assistance': `Si necesitas ayuda {docs} nuestro equipo de soporte.`,
    contact: `contacto`,
    'docs-portal': `Portal de documentación`,
    'create-one': `crear uno`,
    'login-sso-help-reset-password': `Si tienes problemas para iniciar sesión con tu correo electrónico y contraseña, intenta restablecer tu contraseña utilizando el enlace "Forgot Password" (He olvidado mi contraseña) que aparece a continuación.`,
    'login-sso-help-idp-config': `Si tienes activado el inicio de sesión único (SSO) pero no puedes iniciar sesión, es posible que haya un error de configuración del IdP o que tu IdP no responda. Es posible que puedas iniciar sesión directamente con tu nombre de usuario y contraseña de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} si eres administrador de la empresa o si tienes configurado el SSO como Opcional.`,
    'login-help': `Ayuda para iniciar sesión`,
    'login-temp-password-expired': `Tu contraseña temporal ha caducado. Ponte en contacto con nuestro equipo de soporte en ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL} para establecer una contraseña temporal.`,
    continue: `Continuar`,
    'create-new-account': `Crear una nueva cuenta`,
    'join-company-account': `Únete a tu cuenta de empresa`,
    'error-resetting-password': `Error al restablecer la contraseña`,
    'reset-password-sent-if-confirmed': `Si esa dirección de correo electrónico está en nuestro sistema, y la cuenta ha sido confirmada previamente, te enviaremos un correo electrónico para restablecer tu contraseña.`,
    'sso-first-login': `El administrador de tu empresa ha activado el SSO en tu cuenta. Ahora se te redirigirá al proveedor de identidades de tu empresa para autenticarte.`,
    'sso-enforced-no-access': `Un administrador de la empresa ha impuesto el inicio de sesión único (SSO) y solo debes iniciar sesión por SSO. Ponte en contacto con el administrador de tu empresa si necesitas ayuda con el acceso por SSO.`,
    'sso-email-mapping-error': `Hay un problema con la asignación de atributos de correo electrónico de tu configuración de SSO. Para solucionarlo, te recomendamos ponerte en contacto con el administrador de tu empresa y pedirle que revise la asignación de atributos de correo electrónico. Si necesitas ayuda para corregir este problema, consulta nuestra página de preguntas frecuentes sobre SSO en el <a href="https://docs.megaport.com/troubleshooting/sso-faq/" target="_blank">portal de documentación</a>`,
    'email-query-missing': `Falta el parámetro de consulta de correo electrónico`,
    'email-not-sso': `El correo electrónico no está configurado para SSO`,
  },
  validations: {
    required: `{thing} es obligatorio`,
    failed: `Error de Validación`,
    'correct-issues': `Corrija los problemas indicados.`,
    'ix-selection-required': `La selección de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} es obligatoria`,
    'asn-validation': `El ASN de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} debe estar entre 2 y {maxAsn} (el valor predeterminado es {defaultAsn})`,
    'asn-validation-plain': `El ASN debe estar entre {minAsn} y {maxAsn}`,
    'asn-validation-range': `Rango de ASN {minAsn}-{maxAsn} o {minAsn2}-{maxAsn2}`,
    'mbps-value-range': `Introduzca un valor entre {min} y {max} Mbps`,
    'value-too-high': `Mayor que el máximo permitido. Introduzca un valor inferior o igual a {max}`,
    asn: `Los ASN comienzan por "AS" o "ASN" o son numéricos`,
    'min-length': `Longitud mínima 0 caracteres | Longitud mínima 1 carácter | Longitud mínima {min} caracteres`,
    'max-length': `Longitud máxima 0 caracteres | Longitud máxima 1 carácter | Longitud máxima {max} caracteres`,
    'min-max-length': `Debe tener entre {min} y {max} caracteres`,
    'weak-password': `La contraseña no es lo suficientemente segura`,
    'invalid-mac': `Introduzca una dirección MAC válida`,
    'terms-conditions': `Debe aceptar los términos y condiciones`,
    'new-password': `Introduzca la nueva contraseña`,
    'password-different': `La nueva contraseña debe ser distinta de la anterior`,
    'email-invalid': `Introduzca una dirección de correo electrónico válida`,
    'key-invalid': `La clave no superó la validación`,
    'service-key-invalid': `Introduzca una clave de servicio válida`,
    'use-service-key': `Debe usar una clave de servicio para crear una conexión`,
    'nutanix-key-format': `Formato: tres caracteres y guión opcionales, después UUID`,
    'azure-key-format': `Las claves de servicio de Azure tienen entre 32 y 36 caracteres`,
    'invalid-service-key': `Clave de servicio no válida`,
    'destination-type': `Seleccione el tipo de destino`,
    'aws-connection-type': `Debe seleccionar el tipo de conexión de AWS`,
    'destination-provider': `Seleccione el proveedor de destino`,
    'connection-name': `Introduzca un nombre para la conexión`,
    'connection-name-min': `El nombre debe tener una o más palabras que empiecen por una letra`,
    'select-peering': `Seleccione si quiere realizar un emparejamiento con servidores de ruta`,
    'please-select': `Seleccione el {thing}`,
    'please-enter': `Introduzca el {thing}`,
    'phone-format': `Formato internacional, p. ej. +61 4321 1234`,
    'aws-account': `Las cuentas de AWS son números de 12 dígitos`,
    'ip-cidr-format': `Formato de dirección IP no válido, por ejemplo 192.0.1.0/30`,
    'ip-list': `Lista de IP, por ejemplo 191.0.2.0/24,191.0.2.0/30`,
    'select-peer-type': `Seleccione al menos un tipo de par`,
    'pairing-key-format-invalid': `Las claves de emparejamiento deben coincidir con la especificación de Google.`,
    'virtual-circuit-format-invalid': `Introduzca un ID de circuito virtual válido`,
    'pairing-key-invalid': `Clave de emparejamiento no válida`,
    'virtual-circuit-invalid': `ID de circuito virtual no válido`,
    'google-pairing-key': `Para completar el pedido de una conexión de Google, introduzca una clave de emparejamiento válida.`,
    'invalid-key': `Clave No Válida`,
    'enter-valid-key': `Introduzca una clave válida`,
    'vlan-required': `El valor VLAN es obligatorio`,
    'rate-limit': `El límite de velocidad no puede superar el máximo indicado.`,
    'select-location': `Debe seleccionar una ubicación`,
    'enter-name': `Introduzca un nombre`,
    'select-rate-limit': `Seleccione el límite de velocidad`,
    ge: `Introduzca un valor superior o igual a {value}`,
    'mve-final-check': `La comprobación final de la VLAN de ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} se llevará a cabo durante la implementación`,
    'vlan-unavailable': `VLAN no disponible`,
    'vlan-available': `VLAN disponible`,
    'vlan-auto-assign': `Si no especifica ninguna VLAN, se asignará una aleatoriamente`,
    'vlan-no-untagged': `Esta VLAN no se puede establecer como no etiquetada`,
    'vlan-untagged-warning': `Quitar la etiqueta de una VLAN limita el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} a una única conexión`,
    'vlan-range': `Los valores VLAN oscilan entre {minVlan} y {maxVlan}`,
    'vlan-in-use': `Ya hay una conexión designada en este ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} que utiliza esta VLAN`,
    'agree-terms': `Debe aceptar los términos`,
    'insufficient-privileges': `Privilegios insuficientes`,
    'bio-too-long': `Demasiadas palabras en la biografía de la empresa`,
    'url-format': `Introduzca una URL de {site} válida`,
    'valid-company': `Es obligatorio introducir un nombre de empresa válido`,
    'description-too-long': `Demasiadas palabras en la descripción`,
    'ip-in-list': `Esta IP ya existe en la lista`,
    'invalid-network': `La dirección no es una IPv4 o IPv6 válida ni una dirección de red`,
    'not-valid-network': `La dirección no es una dirección de red válida`,
    'no-subnet': `La dirección IP debe contener una máscara de subred`,
    'no-file-selected': `No ha seleccionado ningún archivo`,
    'wrong-file-type': `Tipo de archivo incorrecto`,
    'select-text-file': `Seleccione un archivo de texto para cargar`,
    'file-too-long': `Archivo Demasiado Largo`,
    'file-max-length': `La longitud del archivo codificado debe ser inferior a {max} kb.`,
    'file-read-error': `Error al leer el archivo`,
    'new-password-retype': `Vuelva a escribir la nueva contraseña`,
    'new-password-different': `La nueva contraseña y la contraseña que ha vuelto a escribir deben coincidir`,
    'fill-details': `Complete estos datos`,
    'characters-not-allowed': `No se permiten los siguientes caracteres: {chars}`,
    'minimum-payment': `El importe mínimo de pago es {value}`,
    'payment-description-none': `La descripción del pago debe tener al menos un carácter alfanumérico`,
    'payment-enter-amount': `Introduzca un importe válido`,
    'type-used': `{thing} ya está en uso`,
    'cannot-validate-type': `No se puede validar el valor de tipo: {thing}`,
    'ip-family-required': `Debe proporcionar un valor de familia de direcciones IP`,
    'ip-address-used': `Esta dirección ya está en uso`,
    'ip-address-subnet-required': `La dirección IP debe contener una máscara de subred`,
    'ip-address-subnet-not-required': `La máscara de subred no es obligatoria`,
    'ip-address-overlap': `Las IP superpuestas no están permitidas en la interfaz`,
    'network-broadcast-exception': `La dirección es una dirección de difusión; se espera una dirección de host`,
    'network-address-exception': `La dirección es una dirección de red; se espera una dirección de host`,
    'local-peer-ip-same-network': `La dirección IP local y la dirección IP del par no están en la misma red IP`,
    'peer-network-address': `La dirección IP del par no puede ser la dirección de red`,
    'peer-broadcast-address': `La dirección IP del par no puede ser la dirección de difusión`,
    'peer-same-local-address': `La dirección IP del par no puede ser la misma que la dirección IP local`,
    'ipv4-mismatch': `Las direcciones IPv6 no pueden añadirse a la lista de filtros IPv4`,
    'ipv6-mismatch': `Las direcciones IPv4 no pueden añadirse a la lista de filtros IPv6`,
    'number-required': `El valor debe ser un número`,
    'range-min': `El rango debe empezar en {min}`,
    'range-max': `El rango debe finalizar en {max}`,
    'range-min-exceed-max': `El valor mínimo no puede ser superior al máximo`,
    'range-max-exceed-min': `El valor máximo no puede ser inferior al mínimo`,
    'range-min-exceeds-cidr': `El valor mínimo no puede ser inferior al CIDR`,
    'thing-between-mix-max': `{thing} debe estar entre {min} y {max}`,
    'value-min': `El valor mínimo es {min}`,
    'value-max': `El valor máximo es {max}`,
    'value-required': `Debe proporcionar un valor`,
    'value-integer': `El valor debe ser un entero`,
    'value-positive': `Es obligatorio proporcionar un valor positivo`,
    'value-decimal-places': `Un valor puede tener {places} decimales`,
    'broadcast-address': `El siguiente salto no puede ser una dirección de difusión`,
    'next-hop-same-ip-family': `El siguiente salto debe estar en la misma familia IP que el prefijo`,
    'next-hop-same-network': `El siguiente salto debe estar en la misma red que una de las IP de la interfaz`,
    'next-hop-not-interface-address': `El siguiente salto no puede aparecer en las direcciones IP de la interfaz`,
    'next-hop-network-address': `El siguiente salto es una dirección de red. Se espera una dirección de host`,
    'bgp-password': `La contraseña solo puede incluir caracteres alfanuméricos y especiales (!@#$%^&*+-=_)`,
    'bpg-password-length': `La contraseña debe tener entre 0 y 25 caracteres`,
    'min-max-range': `Los valores deben estar entre {mix} y {max}`,
    'bpg-no-connections': `No se ha encontrado ninguna conexión BGP`,
    'bfd-transmit': `El intervalo de transmisión debe estar entre 300 y 9000`,
    'bfd-receive': `El intervalo de recepción debe estar entre 300 y 9000`,
    'bfd-multiplier': `El multiplicador mínimo de 3 es obligatorio`,
    'name-already-used': `Este nombre ya está en uso`,
    'address-family-required': `La familia de direcciones es obligatoria`,
    'vlan-unique': `El ID de VLAN debe ser exclusivo y no mayor que {max}`,
    'invalid-address-domain': `Por favor, introduce una dirección IP o un dominio válido`,
    'vendor-image': `Por favor, selecciona la imagen del proveedor`,
    'email-invalid-seperators': `Introduzca una única dirección de correo electrónico o una lista de direcciones de correo electrónico separadas por comas`,
    'no-approval-price-title': `No tiene permiso para aprobar cambios de precios`,
    'no-approval-price-message': `Solo los usuarios con permiso para hacer pedidos pueden realizar cambios que afecten al precio de un servicio`,
    'enter-valid-auth-key': `Introduzca {thing} válido`,
    '128-chars': `Debe tener menos de 128 caracteres.`,
    'min-number': `Debe tener al menos 1 número | Debe tener al menos {min} números`,
    'min-upper-case': `Debe tener al menos 1 mayúscula | Debe tener al menos {min} mayúsculas`,
    'min-lower-case': `Debe tener al menos 1 minúscula | Debe tener al menos {min} minúsculas`,
    'min-symbol': `Debe tener al menos 1 símbolo | Debe tener al menos {min} símbolos`,
    'as-path-prepend': `Introduzca la cuenta de previsión de ruta de acceso AS de 0 a 10`,
    'api-keys-expiry': `Introduzca un número de 5 a 1440`,
    'api-keys-expiry-decimal': `El valor debe ser un número entero`,
    'min-max-alphanumeric': `Debe tener entre {min} y {max} caracteres alfanuméricos`,
    'mfa-token': `Introduce tu token MFA`,
    vnic: `La selección de plano vNIC es obligatoria`,
    'bgp-password-valid-characters': `Los caracteres válidos son: a-z A-Z 0-9 ! @ # ? . $ % ^ & * + = -`,
    'ix-peer-invalid-characters': `Solo letras, números, dos puntos, guiones bajos y guiones`,
    'ix-peer-invalid-format': `Introduce un conjunto ASN con prefijo AS separado por dos puntos`,
    'asn-validation-range-triple': `Rango de ASN {minAsn}-{maxAsn}, {minAsn2}-{maxAsn2} o {minAsn3}-{maxAsn3} (por defecto es {defaultAsn})`,
    'required-pattern': `El valor debe estar en el patrón {thing}`,
    'rfc-number-example': `Debe tener {total} caracteres ({start} alfanuméricos, luego 6 numéricos y luego 3 alfanuméricos)`,
    'idp-name-invalid-characters': `El nombre del proveedor solo debe contener letras minúsculas, números y guiones`,
    alphanumeric: `{thing} solo debe tener caracteres alfanuméricos`,
    'reverse-dns-invalid-characters': `Los caracteres válidos son: a-z A-Z 0-9 . -`,
    'invalid-field': `Introduzca un {thing} válido`,
  },
  connections: {
    'choose-type': `Escoja un tipo de destino`,
    cloud: `Cloud`,
    'internet-exchange': `Internet Exchange`,
    csp: `Conexión de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} a un proveedor de servicios en la nube`,
    private: `Conexión de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} a su propio ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} o ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'private-vxc': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} privado`,
    ix: `Conéctese a un ${process.env.VUE_APP_EN_PRODUCTNAME_IX} de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    marketplace: `Conexión de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} a un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de asociado`,
    'enter-service-key': `Introduzca la Clave de Servicio`,
    'service-key': `Seleccione esta opción si le han proporcionado una Clave de Servicio ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'select-destination-port': `Seleccione el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de destino`,
    'awaiting-deployment': `La conexión está pendiente de implementar. Si la implementación tarda más de lo habitual, póngase en contacto con el Soporte de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'approval-error': `Error de aprobación de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'deny-error': `Error de denegación de conexión de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'untag-vlan-tooltip': `Configure este servicio sin un ID de VLAN. Esto limitará el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} a un único servicio virtual.`,
    'used-vlan': `Ya hay una conexión designada en este ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} que utiliza esta VLAN. Si continúa, se le asignará una VLAN aleatoriamente.`,
    'asn-from-mcr': `El ASN utiliza automáticamente el ASN de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.`,
    'azure-ports': `Escoja entre los ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} de Azure disponibles.`,
    'select-destination-port-title': `Seleccionar ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de Destino`,
    'no-available-target-ports': `No hay ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} objetivo disponibles`,
    'please-select-port': `Seleccione el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'no-available-ports-key': `No hay ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} disponibles en esta Clave de Servicio. Pruebe con otra.`,
    'all-ports-used-key': `Otros servicios ya han consumido todos los ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} asociados a esta clave de servicio. Pruebe con otra.`,
    'port-in-use-title': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} en uso`,
    'port-in-use-message': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} no está disponible para su selección porque ya está en uso.`,
    'google-ports': `Escoja entre los ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} de Google disponibles.`,
    'no-google-services': `No hay servicios de Google Cloud disponibles en la región del ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} seleccionado.`,
    'no-google-ports': `No hay ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} disponibles en esta clave de emparejamiento. Pruebe con otra.`,
    'nutanix-ports': `Escoja entre los ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} de Nutanix disponibles.`,
    'oracle-ports': `Escoja entre los ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} de Oracle disponibles.`,
    'no-oracle-ports': `No hay ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} disponibles en este circuito virtual. Pruebe con otra.`,
    'all-oracle-ports-taken': `Otros servicios ya han consumido todos los ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} de este circuito virtual. Pruebe con otro.`,
    'list-all-oracle-ports': `Para obtener una lista de todos los ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} de Oracle disponibles, consulte su | Perfil de ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'ix-details': `Detalles de conexión para el servicio de ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'ix-asn-message': `Nota: El ASN no se puede modificar para los servicios de ${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX} tras la implementación.`,
    'mcr-config': `Detalles de conexión de extremo {end} de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'mcr-override-asn': `Anular ASN de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'mcr-override-asn-tooltip': `El ASN local puede utilizarse para anular el ASN del ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} para este par BGP. En la mayoría de los casos, debe dejarse el valor predeterminado.`,
    'mcr-setup-tooltip': `Por cada ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} conectada a este ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}, puede configurar una o más interfaces, direcciones IP, conexiones BGP o rutas estáticas. La mayoría de las ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} utilizará una interfaz, sin embargo, puede configurar varias mediante "Q-in-Q" seleccionando una etiqueta VLAN "interna" para cada interfaz. El TPID se establecerá en 0x8100.`,
    'mcr-ip-address-tooltip': `La dirección IP y la máscara de subred asociada que se configurarán en esta interfaz.<br /><br />Se permiten varias direcciones. Son compatibles tanto IPv4 como IPv6.<br /><br />Puede dejar este campo en blanco para aplazar la configuración de la interfaz hasta después del aprovisionamiento de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'aws-diversity-tooltip': `Solicite ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} para distintas zonas de color si quiere diversidad de dispositivos.`,
    'no-matching-ports': `No coincide ningún ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} objetivo`,
    'service-key-id': `ID de Clave de Servicio de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'service-key-unavailable': `La clave de servicio es para el {product} de origen actual y no puede utilizarse.`,
    'port-asn': `ASN de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'port-ip': `Dirección IP de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'aws-default-asn': `ASN del par de AWS predeterminado. El ASN se anulará si se vincula esta ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} a una puerta de enlace de Direct Connect.`,
    'vxc-is-down': `Este servicio de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} está inactivo`,
    'vxc-is-up': `Este servicio de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} está activo`,
    'vxc-status-unknown': `El estado de este servicio de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} es desconocido`,
    'ix-is-down': `Este servicio de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} está inactivo`,
    'ix-is-up': `Este servicio de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} está activo`,
    'ix-status-unknown': `El estado de este servicio de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} es desconocido`,
    'speed-req-external-approval': `Nota: El propietario de esta ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} es otra empresa y es necesario que ellos aprueben cualquier cambio de velocidad.`,
    'ix-service': `Servicio de ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'new-vxc': `Nueva Conexión de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    'preferred-end-vlan': `VLAN {title} Preferida`,
    'amsix-contact-phone': `Es necesario un número de teléfono para el {contactType} para un servicio AMS-IX.`,
    'cancel-a-end': `Tenga en cuenta que ${process.env.VUE_APP_EN_COMPANYINFO_NAME} solo puede cancelar el Extremo A de este servicio. Para evitar cargos continuados de <b>{companyName}</b>, asegúrese de cancelar el servicio directamente con <b>{companyName}</b>.`,
    'no-matching-source-port': `No coincide ningún ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} de origen`,
    'no-selected-source-port': `Seleccione el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de origen.`,
    'non-edit-reason': `Nota: Los datos de conexión no son editables dado que | {reason}`,
    'pending-approval': `La conexión está pendiente de aprobación externa por parte de {approvalFrom}.`,
    'pending-speed-approval': `La conexión está pendiente de aprobación externa por parte de {approvalFrom} para un cambio de velocidad.`,
    'pending-speed-approval-to': `El cambio de límite de velocidad pendiente a {newSpeed} Mbps está pendiente de aprobar por parte de {approvalFrom}.`,
    'connection-denied': `Se ha denegado la VXC con {destination}`,
    'connection-approved': `Se ha aprobado la VXC con {destination}`,
    'peer-config': `Configuración del par de {type}`,
    'min-bfg-tx-interval': `El intervalo de transmisión mínimo es de {min} ms`,
    'min-bfg-rx-interval': `El intervalo de recepción mínimo es de {min} ms`,
    'min-bfg-multiplier': `El multiplicador mínimo es {min}`,
    'key-expired': `Esta clave de servicio ya no es válida. Póngase en contacto con {company} para resolver este problema.`,
    'key-used': `Esta clave de servicio de un solo uso ya se ha utilizado. Póngase en contacto con {company} para resolver este problema.`,
    'key-invalid': `Esta clave de servicio no se puede utilizar. Póngase en contacto con {company} para resolver este problema.`,
    'ix-rate-limit-help': `El límite de velocidad de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} está restringido a la velocidad acumulada de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} para las conexiones metropolitanas y a 10 Gbps para las demás.`,
    'vxc-rate-limit-help': `El límite de velocidad máxima para un ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} lo establece la velocidad más baja en cada extremo y la capacidad de la red en la ruta.`,
    'max-ip-addresses': `Máximo de {max} direcciones IP`,
    'max-static-routes': `Máximo de {max} rutas estáticas`,
    'max-bgp-connections': `Máximo de {max} conexiones BGP`,
    'mcr-end-visibility': `Haga clic en {operation} para que se muestren los detalles del Extremo {end} de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'mcr-end-details': `Detalles del Extremo {end} de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'mcr-end': `Extremo {end} de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'missing-azure-service-key': `A este servicio ExpressRoute de Azure le falta la clave de servicio. Para solicitar este servicio, proporcione la clave de servicio en la pantalla "Seleccionar ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}".`,
    'missing-google-pairing-key': `A este servicio de Google le falta la clave de emparejamiento. Para solicitar este servicio, proporcione la clave de servicio en la pantalla "Seleccionar ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}".`,
    'new-bgp-state': `Las conexiones BGP recién creadas tendrán el estado {state}.`,
    'google-key-format': `Las claves de Google tienen el siguiente formato:`,
    'bgp-peer-asn': `ASN del par`,
    'connection-details': `Detalles de la conexión`,
    'connection-name': `Nombre de la conexión`,
    'vlan-fixed': `Nota: La VLAN no se puede modificar para este tipo de servicio tras la implementación.`,
    'configure-azure-vlan': `Configure una única VLAN de emparejamiento de Azure`,
    'azure-vlan': `VLAN de emparejamiento de Azure`,
    'enter-vlan': `Introduzca una VLAN`,
    'preferred-vlan': `VLAN preferida`,
    asn: `ASN`,
    'asn-full': `Número del sistema autónomo`,
    'mac-address': `Dirección MAC`,
    'mac-address-format': `Introduzca una dirección MAC en uno de los siguientes formatos: 3c22.fb80.749c o 3c:22:fb:80:74:9c`,
    'bgp-password': `Contraseña de BGP`,
    'peer-macro': `Macro del par`,
    'graph-visibility': `Visibilidad del Gráfico`,
    untagged: `Sin etiquetar`,
    shutdown: `apagado`,
    enabled: `habilitado`,
    'end-not-configured': `El extremo {end} aún no se ha configurado.`,
    'interface-vlan': `VLAN de la Interfaz`,
    'ip-addresses': `Direcciones IP`,
    'static-routes': `Rutas Estáticas`,
    'bgp-connections': `Conexiones BGP`,
    'nat-ip-addresses': `Direcciones IP de NAT`,
    'b-vlan-service-key': `VLAN del Extremo B (de la clave de servicio)`,
    'hide-cloud-details': `Haga clic para ocultar los detalles de la nube`,
    'show-cloud-details': `Haga clic para mostrar los detalles de la nube`,
    'cloud-details': `Detalles de la Nube`,
    'peering-policy': `Política de emparejamiento`,
    'policy-url': `URL de la política`,
    'peering-email': `Correo electrónico de emparejamiento`,
    'company-url': `URL de la empresa`,
    'company-email': `Correo electrónico de la empresa`,
    'street-address': `Dirección postal`,
    city: `Ciudad`,
    'state-province': `Estado o provincia`,
    'postcode-zip': `Código postal`,
    country: `País`,
    type: `tipo`,
    'aws-connection-name': `Nombre de la conexión de AWS`,
    'aws-owner-acct-id': `ID de la cuenta del propietario de AWS`,
    'customer-ip': `Dirección IP del cliente`,
    'amazon-ip': `Dirección IP de Amazon`,
    'alibaba-account': `ID de la cuenta de Alibaba`,
    'pairing-key': `Clave de emparejamiento`,
    'virtual-circuit-id': `ID de circuito virtual`,
    deleted: `Conexión Eliminada`,
    'select-destination': `Seleccionar Destino`,
    'configure-a-end': `Configurar Extremo A`,
    'configure-b-end': `Configurar Extremo B`,
    'vlan-value': `Valor de VLAN`,
    save: `Guardar Conexión`,
    'approve-prompt': `¿Quiere aprobar esta conexión?`,
    'approve-connection': `Aprobar Conexión`,
    'deny-connection': `Denegar Solicitud de Conexión`,
    configuration: `Configuración de la Conexión`,
    'awaiting-approval': `Esta conexión está pendiente de aprobación por su parte.`,
    'approve-question': `¿Seguro que quiere aprobar esta solicitud?`,
    'approve-title': `Aprobar Solicitud`,
    'deny-question': `¿Seguro que quiere denegar esta solicitud?`,
    'deny-title': `Denegar Solicitud`,
    'cancel-question': `¿Seguro que quiere cancelar esta solicitud?`,
    'cancel-title': `Cancelar Solicitud`,
    'change-approved-title': `Cambio de Velocidad Aprobado`,
    'change-approved-message': `Se ha aprobado el nuevo límite de velocidad.`,
    'change-denied-title': `Cambio de Velocidad Denegado Correctamente`,
    'change-denied-message': `Se ha denegado la solicitud de cambio del límite de velocidad.`,
    'change-cancelled-title': `Cambio de velocidad cancelado correctamente`,
    'change-cancelled-message': `Se ha cancelado la solicitud de cambio del límite de velocidad.`,
    'approved-title': `Conexión aprobada`,
    'denied-title': `Conexión denegada correctamente`,
    untag: `Quitar etiqueta`,
    'auto-assigned': `Asignado automáticamente`,
    'auto-assign': `VLAN asignada automáticamente`,
    'vlan-checking': `Comprobando la disponibilidad de la LAN...`,
    'add-connection': `Añadir Conexión`,
    open: `Abierto`,
    closed: `Cerrado`,
    vlan: `VLAN`,
    'valid-service-key': `Clave de Servicio válida`,
    'verifying-key': `Verificando clave`,
    'select-provider': `Seleccionar proveedor`,
    'no-matching-providers': `No coincide ningún proveedor`,
    'select-connection-type': `Seleccionar Tipo de Conexión`,
    'bgp-statuses': `Estados de BGP`,
    'end-vlan': `VLAN de extremo {end}`,
    'route-servers': `Servidores de ruta`,
    'bgp-down': `BGP está inactivo`,
    'bgp-up': `BGP está activo`,
    'bgp-unknown': `El estado de BGP es desconocido`,
    interface: `Interfaz`,
    'old-speed': `Velocidad anterior`,
    'new-speed': `Velocidad nueva`,
    'approve-speed-question': `¿Quiere aprobar este cambio de velocidad?`,
    'deny-speed-change': `Denegar solicitud de cambio de velocidad`,
    'approve-speed-change': `Aprobar cambio de velocidad`,
    'cancel-speed-request': `Cancelar solicitud de cambio de velocidad`,
    'connections-html': `Para obtener más detalles, consulte el {link}`,
    'speed-change-pending-your-approval': `Pendiente de aprobación por su parte: Se ha solicitado un cambio de velocidad para esta conexión.`,
    'speed-change-pending-company-approval': `Pendiente de aprobación por parte de la empresa: Se ha solicitado un cambio de velocidad para esta conexión (es necesaria la aprobación por parte de su empresa).`,
    connection: `Conexión`,
    'lag-full': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} no puede aceptar ninguna conexión nueva`,
    'port-full': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} no puede aceptar ninguna conexión nueva`,
    'show-connections': `Haga clic para mostrar las conexiones`,
    'hide-connections': `Haga clic para ocultar las conexiones`,
    'service-key-vlan-range': `VLAN 2-4094 (deshabilitada para claves de servicio multiuso)`,
    'third-party-owner': `propietario de terceros`,
    'b-end': `Se encuentra en el Extremo B de esta conexión, que es propiedad de otra empresa`,
    'shut-down-by-user': `apagado por el usuario`,
    'b-end-no-delete': ` el extremo de la conexión no puede eliminar la conexión`,
    'speed-change-from': `Cambio de velocidad de`,
    'new-connection-from': `Nueva conexión de`,
    'best-route-explanation': `Una marca de comprobación indica que ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} prefiere esta ruta al elegir entre dos que llevan al mismo destino.`,
    'as-path': `Ruta de acceso AS`,
    'local-preference': `Preferencia Local`,
    med: `Discriminador de Salida Múltiple`,
    metric: `Métrica`,
    protocol: `Protocolo`,
    distance: `Distancia`,
    'gateway-ipv4': `Dirección IP de la puerta de enlace (IPv4)`,
    'gateway-ipv6': `Dirección IP de la puerta de enlace (IPv6)`,
    'match-label': `Coinciden`,
    'dont-match-label': `No coinciden`,
    'delete-route-label': `Eliminar ruta`,
    'connected-port-removed': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} o ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} al que estaba conectado este servicio se ha quitado de los datos. Redireccionando a los servicios`,
    approving: `aprobando`,
    denying: `denegando`,
    'approval-issues': `Problemas {action} ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}: {errorStr}`,
    'confirm-bgp-state-change': `Esta acción cambiará el Estado de BGP a {newState} y confirmará los cambios no guardados realizados en este servicio. Los cambios pueden tardar hasta dos minutos en reflejarse.`,
    'bgp-state-change-prompt': `¿Confirmar Cambio de Estado de BGP?`,
    'awaiting-approval-or-denial': `En espera de su aprobación/rechazo de esta conexión`,
    'azure-key-unused-key': `Disponible en esta ubicación`,
    'azure-key-used-key': `Puede utilizarse varias veces especificando una única VLAN de emparejamiento de Azure.`,
    'azure-key-unused': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} no se ha utilizado con esta clave de servicio.`,
    'azure-key-used': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} puede utilizarse varias veces especificando una única VLAN de emparejamiento de Azure.`,
    'outscale-connection-name': `Nombre de la conexión a Outscale`,
    'outscale-account': `ID de la cuenta del propietario de Outscale`,
    'outscale-ip': `Dirección IP de Outscale`,
    transit: `Acceso a puerta de enlace de tránsito con una conexión proporcionada por ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'vxc-name-default': `{aEnd} a {bEnd}`,
    'customer-asn': `ASN del cliente`,
    vnic: `vNIC`,
    'reverse-dns': `DNS inverso`,
    'virtual-interfaces-vnics': `Interfaces virtuales (vNIC)`,
    'vnic-plane': `{index} vNIC plano {plane}`,
    'end-vnic': `Extremo {end} vNIC`,
    'create-ip-transit-vxc': `Crear ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}`,
    'mcr-full': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} no puede aceptar nuevas conexiones`,
    'mve-full': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} no puede aceptar nuevas conexiones`,
    'provider-ip': `Dirección IP del proveedor`,
    'ibm-account': `ID de cuenta de IBM Cloud`,
    'mve-untag-vlan-tooltip': `Configure este servicio sin un ID de VLAN.`,
    'no-transit-port-title': `No se ha podido localizar ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} para ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}`,
    'no-transit-port-message': `No se ha podido encontrar un ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}. Ponte en contacto con ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'a-end-vnic-help': `Interfaz virtual del servicio de iniciación`,
    'b-end-vnic-help': `Interfaz virtual de servicio de terminación`,
    'no-description': `Sin descripción`,
    'select-connections': `Seleccione una o varias conexiones`,
    'move-connection': `Mover conexión`,
    'select-new-end': `Seleccionar nuevo extremo`,
    'move-connection-confirm': `Confirma que deseas continuar y mover la o las conexiones seleccionada(s) de {currentEnd} a {newEnd}.`,
    'move-connection-diversity': `Nota: La zona de diversidad está cambiando de {currentDiv} a {newDiv}. Esto puede afectar a tu nueva diversidad.`,
    'move-connection-disclaimer': `Durante el traslado, la conexión o conexiones no estarán disponibles. El movimiento puede tardar hasta 2 minutos en completarse.`,
    'no-valid-connections': `No se han encontrado conexiones válidas`,
    'no-valid-destinations': `No se han encontrado destinos válidos`,
    'invalid-move-existing-end': `{type} de destino ya adjuntado a la conexión seleccionada`,
    'invalid-move-multiple-vnics': `El {type} de destino tiene varias vNIC`,
    'invalid-move-too-small': `Capacidad insuficiente en {type} de destino`,
    'invalid-move-new-untagged': `El {type} de destino no puede aceptar una conexión sin etiquetar`,
    'invalid-move-untagged': `El {type} de destino tiene una conexión sin etiquetar`,
    'invalid-move-vlan-taken': `VLAN no disponible en {type} de destino`,
    'search-for-providers': `Ver más proveedores`,
    'termed-delete-etf': `Se aplica una tasa de cancelación anticipada (ETF) al eliminar una conexión con período de vigencia de acuerdo con los términos de su acuerdo con Megaport.`,
    'avoid-etf': `Para evitar la ETF, seleccione "No Minimum Term" (Sin período de vigencia mínimo) hasta que los detalles de configuración sean precisos. Puede actualizar la conexión en cualquier momento para optar por un período de vigencia.`,
    'rate-limit-change-disallowed': `Esta es una conexión con período de vigencia, no se permiten cambios en el límite de velocidad a menos que se cambie el período de vigencia de la conexión y se aumente el límite de velocidad.`,
    'rate-limit-change-term': `Esta es una conexión con período de vigencia, el límite de velocidad no puede reducirse por debajo de {termSpeed} Mbps, la velocidad seleccionada en el momento de finalización del período de vigencia.`,
    'private-vxc-no-valid-target': `No dispone de ningún servicio válido para establecer una conexión privada con`,
    'vnic-details': `Detalles de vNIC`,
    'new-vnic-details': `Nuevos detalles de vNIC`,
    'new-end-vnic': `Nuevo extremo {end} de vNIC`,
    'new-preferred-end-vlan': `Nueva {title} VLAN preferida`,
    'move-connection-vnic-warning': `Nota: La vNIC y la VLAN en el destino ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} también cambiarán a los valores especificados.`,
    'confirm-move': `Confirmar movimiento`,
    'speed-req-external-approval-a-end': `Nota: El propietario de esta ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} es otra empresa y no se le permite realizar cambios de velocidad en su ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'speed-req-external-approval-b-end': ` Nota: Esta ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} está conectada a otra empresa y es necesario que ellos aprueben también cualquier cambio de velocidad.`,
  },
  ports: {
    'port-deleted': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} Eliminado`,
    'select-port': `Seleccionar ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'port-speed': `Velocidad de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'select-port-speed': `Seleccionar velocidad de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'mcr-explanation': `Un ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MCR}) es una instancia de enrutamiento virtual compatible con la Capa 3 proporcionada y alojada por ${process.env.VUE_APP_EN_COMPANYINFO_NAME} en ubicaciones de punto de acceso en la nube a nivel mundial. No requiere un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} físico, pero puede conectarse a uno si quiere.`,
    'port-main-tooltip': `Un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} físico es necesario si va a conectar su equipo al nuestro. Admitimos interfaces de fibra de 1 G, 10 G y 100 G (por lo general SMOF dúplex; compruebe la LOA recuperada durante la implementación de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} para conocer los requisitos específicos del sitio) en ubicaciones conectadas de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'mve-explanation': `Un ${process.env.VUE_APP_EN_PRODUCTNAME_MVE_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MVE}) es una instancia de BYOL de una puerta de enlace SD-WAN de un asociado proporcionada y alojada por ${process.env.VUE_APP_EN_COMPANYINFO_NAME} adyacente a ubicaciones de punto de acceso en la nube a nivel mundial. Permite el acceso controlado a los recursos de red de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} a través de Internet. No requiere un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} físico, pero puede conectarse a uno si quiere. Las tareas relacionadas con las licencias y la administración se gestionan mediante soluciones de asociados.`,
    'diverse-from-existing': `Distinto del ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} existente`,
    'ports-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'ports-type-count': `0 {type} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} | 1 {type} ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | {count} {type} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'pluralize-ports': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} | ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'services-attached-count': `0 servicios vinculados | 1 servicio vinculado | {count} servicios vinculados`,
    'percent-allocated': `{percent} % asignado`,
    'awaiting-resources': `Este {type} está pendiente de la asignación de recursos.`,
    'add-connection-to': `Añadir nueva conexión a este {type}`,
    'no-mcr-speed-change': `El límite de velocidad de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} está fijado por toda la duración del servicio`,
    'lacp-ports': `LACP y ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} del ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'lacp-on-port': `LACP en ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'add-to-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} que añadir al ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'ports-in-lag': `Número de ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} en ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'mcr-asn': `ASN de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'summary-adding-to-lag': `Añadiendo ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} a ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'summary-ports-in-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} en ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'no-slow-ports-in-lag': `Ninguna velocidad de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} es inferior a 10 Gbps en ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'mcr-rate-limit': `Límite de Velocidad de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'allocated-tooltip': `La velocidad de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} y el límite de velocidad acumulada total de sus servicios`,
    'port-removed': `El ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} se ha quitado de los datos. Redireccionando a los servicios`,
    'contract-terms': `Los términos contractuales se mostrarán cuando el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} esté activo`,
    statuses: `Estados de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'configure-details': `Configure los detalles para los ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} de ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} añadidos`,
    'add-ports-to-lag': `Añada nuevos ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} a este ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'lag-full': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} no puede aceptar ningún ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} más`,
    'max-speed-tooltip': `Límite de velocidad en Mbps. Debe ser igual o inferior a {speed}. Si se deja en blanco se limitará a la velocidad máxima disponible del {productType}.`,
    'vxc-speed-positive-integer': `La velocidad de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} debe ser un entero positivo`,
    'vxc-speed-max': `La velocidad máxima de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} es {speed} Mbps.`,
    'lag-primary-deletion-locked': `El ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} Primario de ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} no se puede eliminar mientras haya ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} bloqueados en el ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'deletion-insufficient-capacity': `Si elimina este ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, la capacidad resultante sería insuficiente para sustentar las conexiones actuales en el ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'link-aggregation': `Adición de enlaces para {portName}`,
    'attached-vxc-locked': `El ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} vinculado está bloqueado`,
    'attached-ix-locked': `El ${process.env.VUE_APP_EN_PRODUCTNAME_IX} vinculado está bloqueado`,
    'terminate-date': `Este servicio terminará el {date}`,
    'autoconfig-mcr': `La configuración de conexión de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} se generará automáticamente. Una vez que el servicio esté activo, puede modificar la configuración.`,
    'count-port-lag': `- | (1 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}) | ({count} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} de ${process.env.VUE_APP_EN_PRODUCTNAME_LAG})`,
    'live-lag-disclaimer': `Nota: Al añadir ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} a un ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} activo, al nuevo ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} o a los nuevos ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} se les asignan automáticamente la misma ubicación, velocidad y visibilidad en el ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} que al resto del ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'lag-explanation': `Crea este ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} con el protocolo LACP habilitado de modo que pueda utilizarse en un ${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_LAG}) y le permite especificar el número de ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} que puede incluir en su grupo de ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'add-lag-ports-disclaimer': `De este modo, se añadirán varios ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} a este ${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_LAG}). La tarificación mostrada corresponde a todos los ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} que se añaden al ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'port-diversity': `Diversidad de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'diversity-unavailable-location': `La diversidad no está actualmente disponible para esta configuración. Póngase en contacto con <a href="mailto:${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}">${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}</a> para analizar sus requisitos de diversidad.`,
    'diversity-explanation-html': `La diversidad de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} permite que los ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} de la misma ubicación estén en zonas de diversidad distintas. Crear ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} redundantes en zonas diversas reduce el riesgo de sufrir una interrupción del servicio por un evento que afecte a un único dispositivo. Para obtener más detalles, consulta {link}`,
    'select-lag-speed': `Velocidad mínima de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} para permitir que LACP sea 10 Gbps`,
    'select-speed-diversity': `Seleccione una velocidad de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} para ver las opciones de diversidad.`,
    'diversity-lag-add': `- | El nuevo ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} se añadirá a la zona de diversidad del ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}. | El nuevo ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} se añadirá a la zona de diversidad del ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'diverse-pair': `Se configurarán dos {things} y se añadirán a zonas de diversidad distintas.`,
    'diverse-from': `Distinto de: {port}`,
    'port-diverse-from': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} será distinto de`,
    'first-port-name': `Nombre del primer {thing}`,
    'second-port-name': `Nombre del segundo {thing}`,
    'delete-diverse-from': `- | El siguiente ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} está configurado para que sea distinto del ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} que está terminando: | Los siguientes ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} están configurados para que sean distintos del ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} que está terminando:`,
    'reset-diversity': `- | Si termina el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} perderá su configuración de diversidad y ya no tendrá una zona de diversidad especificada. | Si termina el ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, los ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} indicados perderán su configuración de diversidad y ya no tendrán una zona de diversidad especificada.`,
    'mcr-prefix-filter': `La función Filtro de prefijo de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} admite filtros de ruta BGP basados en prefijos entrantes y salientes. <br/><br/> <strong>Puede añadir y configurar listas de prefijos cuando tenga el servicio configurado.</strong>`,
    'bgp-default-state-tooltip': `Establezca si las conexiones BGP recién creadas se habilitan o apagan de forma predeterminada. Seleccione Habilitado (opción predeterminada) si quiere que cualquier sesión BGP que configure se muestre activa en cuanto guarde la configuración. Seleccione Apagado si quiere que cualquier sesión BGP que configure se quede apagada cuando guarde la configuración.`,
    'mve-software': `Software de ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'new-prefix-filter-list': `Nuevo`,
    'open-port': `Abrir ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'prefix-filter-list': `Listas de Filtros de Prefijos`,
    'prefix-list-learn-more': `Obtenga más información sobre las listas de filtros de prefijos`,
    'prefix-list-loaded-success': `Se ha cargado correctamente la lista de prefijos seleccionada`,
    'prefix-list-loaded-fail': `Error al cargar la lista de prefijos seleccionada`,
    'lacp-enabled': `Compatible con LACP`,
    'diverse-pair-label': `Par Diverso`,
    'diverse-from-label': `Distinto de`,
    'download-loa': `Descargar LOA`,
    failed: `Error al actualizar ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    vendor: `Proveedor`,
    'mve-file-upload': `Carga de Archivo`,
    'cloud-init-vmanage': `Generar y cargar un archivo ”cloud init” desde su sistema vManage`,
    'mve-init': `Archivo ”Cloud init”`,
    waiting: `Pendiente de la conexión cruzada.`,
    'key-valid-until': `Válido Hasta`,
    'service-keys': `Claves de Servicio`,
    'lag-up': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} está activo`,
    'lag-down': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} está inactivo`,
    'key-saved': `Clave de servicio guardada correctamente.`,
    'key-added': `Clave de servicio añadida correctamente.`,
    'key-saved-message': `Se ha guardado la clave de servicio [{description}].`,
    'key-add-error': `Error al crear la clave de servicio.`,
    'key-update-error': `Error al actualizar la clave de servicio.`,
    'minimum-term': `Periodo de vigencia mínimo`,
    // la sección cannotBeEdited está en negrita, hará referencia a una cadena cannot-edit
    'uneditable-asn-html': `Puedes configurar el Número del Sistema Autónomo (ASN) de este ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} o utilizar el ASN público {defaultAsn} proporcionado por ${process.env.VUE_APP_EN_COMPANYINFO_NAME}. El ASN se utilizará para sesiones de emparejamiento BGP en cualquier ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} conectada a este ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}. No se puede editar {cannotBeEdited} una vez que se haya solicitado el ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}. Ten en cuenta que algunos servicios de nube públicos requieren el uso de un ASN público. Consulta la documentación relativa a tu proveedor de nube antes de anular este valor por defecto.`,
    'bgp-default-state': `Estado Predeterminado de BGP`,
    'site-deployment': `Esta ubicación pronto estará ACTIVA. Realice ahora un pedido por adelantado para recibir su LOA en cuanto esté disponible. Para obtener más información o analizar este asunto más a fondo, póngase en contacto con su gestor de cuenta.`,
    terms: `Términos contractuales`,
    'end-date-display': `La fecha de terminación del contrato se mostrará una vez que el {productName} esté activo.`,
    'change-term': `Modificar el periodo de vigencia del contrato`,
    'term-info': `Más información sobre los términos contractuales de {productName}`,
    monthly: `Mes a mes`,
    'existing-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_LAG} existente`,
    'thing-enabled': ` {thing} habilitado`,
    'thing-disabled': `{thing} deshabilitado`,
    'address-family-label': `Familia de direcciones`,
    'local-ip-label': `IP local`,
    'peer-ip-label': `IP del par`,
    'local-asn-label': `ASN Local`,
    'bgp-state-label': `Estado de BGP`,
    'bgp-peer-filter-label': `Filtro de Pares BGP`,
    'bgp-prefix-filter-import-label': `Filtro de prefijos para importar`,
    'bgp-prefix-filter-export-label': `Filtro de prefijos para exportar`,
    'bgp-confirm-delete': `La dirección IP está en uso en las conexiones BGP o las direcciones IP de NAT. Si la elimina, se quitará de esas configuraciones. ¿Quiere continuar?`,
    'permit-list-label': `Lista de permitidos`,
    'deny-list-label': `Lista de denegación`,
    'new-list-label': `Nueva lista`,
    'duplicate-list-label': `Duplicar lista`,
    'delete-list-label': `Eliminar lista`,
    'delete-list-confirm': `¿Seguro que quiere eliminar esta lista?`,
    'filter-advertise-default-label': `Anunciar de forma predeterminada`,
    'filter-advertise-exception-label': `Anunciar de forma excepcional`,
    'bfd-switch-label': `Utilizar BFD`,
    'bfd-label': `BFD: Detección de Reenvío Bidireccional`,
    'inbound-label': `Entrante`,
    'outbound-label': `Saliente`,
    'med-label': `MED (Métrica): Discriminador de Salida Múltiple`,
    'bgp-prefix-filter-label': `Filtro de prefijos BGP`,
    'bgp-connection-filter-label': `Filtro de conexiones BGP`,
    'no-connection-found': `No se ha encontrado ninguna conexión {thing}`,
    'address-placeholder': `Introduzca una dirección IP (IPv4/IPv6)`,
    'network-address-placeholder': `Introduzca una dirección {thing} y CIDR`,
    'subnet-mask-range': `Rango de máscara de subred`,
    'select-address': `Seleccionar dirección`,
    'prefix-list-label': `Lista de Prefijos`,
    'ssh-key': `Clave SSH`,
    'ssh-key-rsa-help': `Clave pública SSH RSA de 2048 bits`,
    'mve-appliance-licence': `Licencia del Dispositivo`,
    'mve-activation-code': `Código de Activación`,
    'mve-address': `Dirección de Orchestrator`,
    // FQDN - Nombre de Dominio Cualificado Completo
    'mve-address-placeholder': `IPv4/IPv6 o FQDN`,
    'mve-select-vendor': `Seleccionar proveedor/producto`,
    'mve-cloud-init-file-upload': `Archivo cloud-init`,
    'mve-cloud-init-file-upload-help': `Consulte la documentación del producto {title} para generar un archivo cloud-init.`,
    'mve-appliance-licence-help': `Licencia del dispositivo`,
    'key-valid': `Válido`,
    'prefix-list-count': `{count} lista | {count} listas`,
    'mve-director-address': `Dirección de Director`,
    'mve-controller-address': `Dirección del controlador`,
    'mve-local-auth': `Autenticación local`,
    'mve-local-auth-help': `Cadena de ID local o correo electrónico`,
    'mve-remote-auth': `Autenticación remota`,
    'mve-remote-auth-help': `Cadena de ID remota o correo electrónico`,
    'serial-number': `Número de serie`,
    'prefix-list-save-confirm': `¿Desea guardar los cambios en la lista de prefijos?`,
    'how-to-create-port-text': `cómo crear un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'how-to-create-port-paragraph': `Para ver instrucciones sobre {0}, visite la documentación de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'aruba-orchestrator-name': `Nombre de la cuenta de Orchestrator`,
    'aruba-orchestrator-name-tt': `El nombre de cuenta asociado a la instancia de Aruba Orchestrator.`,
    'aruba-account-key': `Clave de cuenta`,
    'aruba-account-key-tt': `La clave de cuenta vinculada al nombre de cuenta anterior.`,
    'aruba-system-tag': `Etiqueta del sistema`,
    'aruba-system-tag-tt': `Se utiliza al registrarse en el portal de Aruba Cloud y en Aruba Orchestrator.`,
    'as-path-prepend-label': `Previsión de ruta de acceso AS`,
    'unbundled-mve-size-info': `Este tamaño incluirá {cpuCount} vCPU, {ram} GB de RAM y {storage} GB de almacenamiento.`,
    'mve-appliance-mode': `Modo dispositivo`,
    'mve-appliance-mode-tooltip': `<p>El modo dispositivo determinará cómo se configura y administra tu implementación.</p>
      <ul>
      <li><strong>Modo autónomo</strong>: mediante la interfaz de la línea de comandos (CLI)</li>
      <li><strong>Modo SD-WAN</strong>: mediante vManage</li>
      </ul>
      <p>${process.env.VUE_APP_EN_COMPANYINFO_NAME} no admite cambios en los modos dispositivo después de la implementación, ya que pueden provocar la pérdida de la configuración.</p>
      `,
    'mve-license-data': `Datos de licencia`,
    'mve-admin-password': `Contraseña de administrador`,
    'mve-admin-password-tooltip': `<p>Tendrás que crear una contraseña de administrador para acceder al dispositivo virtual. La contraseña de administrador debe contener:</p>
      <ul>
      <li>un mínimo de ocho caracteres</li>
      <li>al menos una letra mayúscula</li>
      <li>al menos una letra minúscula</li>
      <li>al menos un número</li>
      <li>al menos un símbolo</li>
      </ul>
      <p>Guarda la contraseña de administrador en un lugar seguro y no la compartas con nadie. Si es necesario, la contraseña de administrador puede restablecerse al iniciarse sesión por primera vez en el dispositivo configurado.</p>
      <p><strong>NOTA</strong>: ${process.env.VUE_APP_EN_COMPANYINFO_NAME} no almacena las contraseñas de los clientes.</p>
      `,
    'mve-vnic-prompt-info': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} le pedirá que solicite un ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} para el arranque y la configuración iniciales una vez que haya finalizado la configuración básica de su ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}.`,
    'mve-vendor-vnic-count': `{vendor} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} tienen un vNIC. | {vendor} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} tienen dos vNIC (vNIC 0 y vNIC 1). | {vendor} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} tienen varias vNIC.`,
    'mve-ip-transit-vxc-prompt-success': `Has configurado correctamente el componente de computación de tu {vendorName} ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}, que incluye CPU, RAM y almacenamiento.`,
    'mve-ip-transit-vxc-prompt-recommend': `Para finalizar la configuración de tu {vendorName} ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}, te recomendamos crear una conexión ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}.`,
    'mve-ip-transit-vxc-prompt-docs': `Para más información, consulta nuestro {link}.`,
    'mcr-prefix-filter-support': `La función Filtro de prefijo de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} admite filtros de ruta BGP basados en prefijos entrantes y salientes.`,
    'mcr-prefix-filter-configure': `Puedes añadir y configurar listas de prefijos cuando tengas el servicio configurado.`,
    'cannot-edit': `no se puede editar`,
    'move-connections': `Mover conexiones`,
    'megaport-internet-restricted-location-reason': `Tiene uno o más ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} asociados a este {productType}. Este {productType} y sus ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} asociados${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} deben configurarse dentro de la misma región.`,
    'megaport-internet-restricted-location-workaround': `Si desea configurar su {productType} en otra región, elimine su ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} asociado primero.`,
    'mve-manage-locally': `Administrar localmente`,
    'mve-manage-locally-tooltip': `Indica si esta instancia de ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} telefonea a Firewall Management Center o no.`,
    'mve-fmc-ip-address': `Dirección IP de FMC`,
    'mve-fmc-ip-address-tooltip': `Dirección de red de Cisco Secure FMC. Puede ser uno de los siguientes: nombre de host, dirección IPv4 o dirección IPv6.`,
    'mve-fmc-registration-key': `Clave de registro de FMC`,
    'mve-nat-id': `ID de NAT`,
    'mve-nat-id-tooltip': `Proporcione el ID de NAT si el controlador de administración de cortafuegos no es directamente direccionable.`,
    'mve-ion-key': `Clave ION`,
    'mve-secret-key': `Clave secreta`,
    'unbundled-mve-size-info-no-storage': `Este tamaño incluirá {cpuCount} vCPU y {ram} GB de RAM.`,
    'service-key-name': `Claves de servicio de {name}`,
    'mve-cisco-vmanage': `vManage`,
    'mve-aviatrix-secure-edge': `Aviatrix Secure Edge`,
    'mve-select-version': `Seleccionar versión`,
    'mve-version': `Versión {version}`,
    'mve-service-details': `Detalles del servicio {vendor}`,
  },
  'port-term': {
    'funky-1': `El periodo de vigencia de este servicio no se puede actualizar automáticamente.`,
    'funky-2': `Póngase en contacto con su comercial de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    title: `Establezca el periodo de vigencia del contrato del {productType}`,
    choose: `Escoja el periodo de vigencia del contrato del {productType}`,
    'update-failure': `Error al actualizar el periodo de vigencia del ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'update-error': `Se ha producido un error al tratar de actualizar el periodo de vigencia del ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'update-unavailable': `El periodo de vigencia del ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} seleccionado no está disponible.`,
    'update-success': `Términos de {productType} actualizados`,
    'reterm-success': `- | Establezca el periodo de vigencia del ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} '{portName}' en 1 mes | Establezca el periodo de vigencia del ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} '{portName}' en {count} meses`,
    reterm: `El nuevo periodo de vigencia del contrato para este servicio (el periodo de vigencia mínimo) entrará en vigor inmediatamente y el descuento del nuevo periodo se aplicará en la factura del próximo mes natural. Una rescisión del contrato anterior a la finalización del periodo mínimo ocasionará gastos según lo acordado por ambas partes.`,
    'confirm-new-term': `Iniciar un Nuevo Periodo de Contrato`,
    'no-change': `Sin Cambios`,
    'funky-3': `Póngase en contacto con su socio de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'no-minimum-term': `Sin periodo de vigencia mínimo`,
    'minimum-term-tooltip-ports': `El periodo de vigencia mínimo empezará una vez que el servicio entre en funcionamiento o 14 días después de la configuración del servicio.`,
    'minimum-term-tooltip': `El plazo mínimo comenzará a partir del pedido`,
    'reterm-immediate': `El nuevo periodo de vigencia del contrato para este servicio (el periodo de vigencia mínimo) entrará en vigor con carácter inmediato. El periodo de vigencia mínimo comenzará el {start} y terminará el {end}. Una rescisión del contrato anterior a la finalización del periodo mínimo ocasionará gastos según lo acordado por ambas partes.`,
    'option-price': `- | Mensual: {cost} al mes | {count} meses: {cost} al mes`,
  },
  dashboard: {
    navigation: `Conozca los conceptos básicos que necesita para navegar por el ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL} y aprenda a crear su primer ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'visit-marketplace': `Visite ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'deep-dive': `Descubra nuestros servicios y cómo establecer una conexión de red, conectividad en la nube y soluciones de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} gracias a los artículos y documentos técnicos.`,
    'ql-overview': `Una introducción a ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'ql-vxc': `Cómo establecer una conexión con una ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    'ql-cloud': `Cómo establecer una conexión con proveedores de servicios en la nube`,
    'ql-mcr': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} - Enrutamiento virtual para una conectividad de Capa 3 privada`,
    'marketplace-marketing': `La plataforma en línea que reúne a nuestros socios proveedores de servicio y a clientes empresariales y donde pueden compartir servicios. En el ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}, puede descubrir nuevos recursos y darse a conocer a nuevos clientes.`,
    'marketplace-image': `Haga clic para seleccionar una imagen para el perfil del ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT} de su empresa.`,
    'get-started': `Introducción`,
    'quick-links': `Enlaces Rápidos`,
    documentation: `Documentación de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    video: `Ver el Vídeo`,
    'video-error': `No se puede reproducir el vídeo`,
    'video-error-message': `Parece que la reproducción de vídeos está bloqueada en su navegador. Se lo hemos notificado al equipo de desarrollo para que lo investigue. Lamentamos las molestias.`,
  },
  menu: {
    dashboard: `Panel`,
    company: `Empresa`,
    'ix-looking-glass': `Looking Glass de ${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX}`,
    'ix-telemetry': `Telemetría de ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'marketplace-profile': `Perfil de ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'mcr-looking-glass': `Looking Glass de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'more-events': `Hay más eventos disponibles:`,
    'company-profile': `Perfil de la empresa`,
    'billing-markets': `Mercados de Facturación`,
    'manage-users': `Administrar Usuarios`,
    tools: `Herramientas`,
    'api-documentation': `Documentación sobre API`,
    'api-key-generator': `Generador de Clave de API`,
    'event-log': `Registro de Eventos`,
    'view-event-log': `Ver Registro de Eventos`,
    events: `Eventos`,
    'bug-report': `Enviar Informe de Errores`,
    'user-profile': `Perfil de Usuario`,
    'notification-settings': `Configuración de notificaciones`,
    logout: `Cerrar sesión`,
    'partner-admin': `Administrador de socios`,
    'company-security-settings': `Ajustes de seguridad`,
    'technical-support-details': `Detalles de la asistencia técnica`,
    'account-contacts': `Contactos de la cuenta`,
    entitlements: `Derechos de la empresa`,
    feedback: `Enviar comentarios`,
  },
  marketplace: {
    'pluralize-provider-type': `Tipos de proveedores | Tipo de proveedor | Tipos de proveedores`,
    'pluralize-locations': `Ubicaciones | Ubicación | Ubicaciones`,
    profile: `Perfil de ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'currently-private': `Su perfil de ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} es privado actualmente, lo que significa que nuestra red global de clientes no podrá encontrarle ni conocer sus servicios.`,
    'no-profile': `Actualmente no tiene un perfil de ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT} configurado.`,
    visible: `Su perfil estará visible en el ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    hidden: `Su perfil estará oculto en el ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    services: `Servicios de ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'create-vxc': `Cree nuevos ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} para este ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'display-name': `Nombre de visualización en ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'contact-company': `Contacto con {company}`,
    'email-sent-to': `Hemos enviado su correo electrónico a {recipient}.`,
    'not-ready-publishing': `Parece que su perfil de ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} no está listo para su publicación. Haga clic en Crear Perfil para completar su página y que nuestra red global de clientes pueda encontrarle y conocer sus servicios.`,
    'easy-get-connected': `Conectarse es muy sencillo. Haga clic en Editar Perfil y hágalo visible en nuestro ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}.`,
    'marketplace-intro': `El ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} da a conocer su empresa a nuestra red global de clientes. Esta plataforma le permite adentrarse en nuevos mercados para identificar canales de ingresos anteriormente inalcanzables. Si completa su perfil y lo mantiene actualizado, mejorará en gran medida su potencial de venta y la exposición de su marca ante clientes dispuestos a comprar.`,
    'duplicate-name-warning': `Esto puede causar cierta confusión a los usuarios que quieran conectarse a sus ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}.<br/><br/> ¿Está seguro de que quiere guardar?`,
    sending: `Enviando mensaje...`,
    'general-info': `Información deServicio General`,
    'send-copy': `Enviarme una copia del mensaje`,
    message: `Introduzca su mensaje`,
    sent: `Mensaje enviado`,
    'send-error': `Error al enviar el mensaje`,
    target: `Destino de la Conexión`,
    'connection-from': `Origen de la Conexión`,
    filter: `Filtrar por Tipo de Proveedor`,
    'select-types': `Seleccione los tipos y subtipos necesarios`,
    'clear-filter': `Borrar Filtro`,
    'hide-message': `Ocultar eEste Mensaje`,
    'hide-today': `SoloHoy`,
    'hide-week': `Una Semana`,
    'hide-forever': `Siempre`,
    'edit-profile': `Editar Perfil`,
    'save-profile': `Guardar Perfil`,
    'create-profile': `Crear Perfil`,
    'sort-alpha': `A – Z`,
    'sort-location-count': `Recuento de Ubicaciones`,
    'grid-display': `Vista de cuadrícula`,
    'list-display': `Vista de lista`,
    'company-name-filter': `Filtro de Nombre de Empresa`,
    'select-speeds': `Seleccionar Velocidades`,
    'select-provider-types': `Seleccionar Tipos de Proveedores`,
    'select-countries': `Seleccionar Países`,
    'loading-companies': `Cargando empresas`,
    'all-services': `todos`,
    'selected-services': `los servicios seleccionados`,
    'profile-message-hidden': `Mensaje de Perfil Oculto`,
    'hidden-day': `Este mensaje lleva un día oculto.`,
    'hidden-week': `Este mensaje lleva una semana oculto.`,
    'hidden-message': `Este mensaje ya no se mostrará en esta pantalla, pero puedes crear o editar tu perfil de ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT} en la opción de menú "Empresa > Perfil de ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}".`,
    'hide-profile': `Ocultar Mensaje del Perfil`,
    'hidden-permanently': `Este mensaje se ha ocultado de forma permanente.`,
    'upload-logo': `Cargar Logotipo`,
    'change-logo': `Cambiar Logotipo`,
    social: `Redes Sociales`,
    headquarters: `Sede Ccentral`,
    'address-1': `Dirección Postal 1`,
    'address-2': `Dirección Postal 2`,
    city: `Ciudad/Municipio`,
    postcode: `Código Postal`,
    'point-click': `Elija, Haga Clic , y Conéctese.`,
    'no-edit-permission': `No tiene permiso para editar el perfil. Póngase en contacto con un usuario administrador de la empresa para editarlo.`,
    'loading-editor': `Cargando el editor del perfil...`,
    'no-create-permission': `No tiene permisos para crear un perfil. Póngase en contacto con el administrador de su empresa para crear un perfil.`,
    'profile-url': `Perfil disponible en la URL:`,
    contact: `Contacto`,
    visibility: `Visibilidad del Perfil`,
    'company-bio': `Biografía de la Empresa`,
    'bio-length': `Biografía de la Empresa (200 palabras)`,
    'contact-email': `Correo Electrónico de Contacto`,
    'recently-viewed': `Proveedores Vistos Recientemente`,
    'similar-providers': `Proveedores de Servicios Similares`,
    'duplicated-names': `Los siguientes nombres de visualización de servicios están duplicados:`,
    'duplicate-name': `Nombre de visualización duplicado`,
    description: `Descripción del Servicio (200 palabras)`,
    'service-contact-email': `Correo Electrónico de Contacto del Servicio`,
    'service-types': `Tipos de Servicios`,
    'company-profile': `Perfil de empresa de ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} de la empresa {company}`,
    'live-time': `Activo durante {time}`,
    'company-at-location': `{company} en {location}`,
    state: `Estado`,
    country: `País`,
  },
  'mcr-looking-glass': {
    'no-services-message': `No tienes ningún servicio de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MCR}) configurado.`,
    'no-bgp-sessions': `No hay ninguna sesión BGP para este ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'no-services': `No se han encontrado servicios`,
    selection: `Selección de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'routes-table': `Tabla de Rutas`,
    'bgp-table': `Tabla de BGP`,
    'all-reachable': `Todas las redes al alcance en la tabla de enrutamiento del ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.`,
    'all-routes': `Todas las Rutas`,
    'routes-by-ip': `Rutas por IP`,
    find: `Buscar rutas`,
    loading: `Cargando rutas`,
    'enter-ip': `Introduzca una IP o dirección de red para buscar rutas`,
    'no-routes': `No existen rutas para esta dirección IP`,
    'no-bgp': `No existen conexiónes BGP vinculadas a esta interfaz`,
    neighbour: `Rutas Vecinas`,
    'bgp-sessions': `Sesiones BGP`,
    'bgp-sessions-tooltip': `Todas las sesiones BGP configuradas en las ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} para el ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} seleccionado.`,
    'filter-sessions': `Introduzca textopara filtrar las sesiones`,
    'toggle-details': `Alterne los detalles adicionales`,
    'total-routes': `Numero Total de Rutas `,
    'filter-routes': `Introduzca texto para filtrar rutas`,
    advertised: `Anunciadas`,
    received: `Recibidas`,
    'sort-by-thing': `Ordenar por {thing}`,
    'clear-filter-routes': `Borrar rutas de filtro`,
    'contact-support': `Ponte en contacto con nuestro equipo de Soporte Técnico para analizar cómo ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} puede beneficiarte.`,
    'service-could-not-be-loaded': `No se ha podido cargar el servicio ({errorStatus})`,
    'service-loading-timeout': `Disculpa las molestias, pero parece que ha habido un problema al cargar esta información. Si el problema persiste, ponte en contacto con nuestro equipo de asistencia en ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
  },
  'ix-flows': {
    'no-ix-found': `Servicio de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} no encontrado`,
    'no-ix-message': `No tienes ningún servicio de ${process.env.VUE_APP_EN_PRODUCTNAME_IX}. Ponte en contacto con tu comercial si te interesan los servicios de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ${process.env.VUE_APP_EN_PRODUCTNAME_IX}.`,
    'date-select': `Selección de fecha`,
    'select-peer': `Seleccione un par`,
    'no-data': `No hay datos disponibles para este par`,
    'flow-type': `Tipo de flujo`,
    'loading-peers': `Cargando pares`,
    'sort-by': `Ordenar por`,
    'filter-by-name': `Filtrar por nombre`,
    'total-peers': `Total de pares: {count}`,
    'top-5': `5 pares principales ({direction})`,
    'volume-in': `Volumen (entrada)`,
    'volume-out': `Volumen (salida)`,
    'load-error': `Hay un problema al cargar estos datos. Cargue de nuevo para intentarlo otra vez.`,
  },
  checkout: {
    'minimum-term-months': `Sin período de vigencia mínimo | Mes a mes | Período de vigencia mínimo: {count} meses`,
    'unknown-validation-error': `Error de validación desconocido: '{message}'`,
    'hide-promo': `Ocultar`,
    'add-promo': `Añadir Código`,
    order: `Pedir Servicios`,
    'set-up-company': `Configurar su empresa`,
    'register-markets': `Registrar mercados`,
    'order-now': `Pedir Ahora`,
    'need-setup': `Para poder solicitar servicios, debe completar la configuración de la cuenta.`,
    'need-company': `Debe completar la información de su empresa.`,
    'need-company-permissions': `La información de su empresa está incompleta, pero no tiene privilegios suficientes. Póngase en contacto con el administrador de su empresa.`,
    'need-markets': `Debe habilitar los mercados de la empresa necesarios.`,
    'need-markets-permissions': `Los mercados de la empresa no están habilitados, pero no tiene privilegios suficientes. Póngase en contacto con el administrador de su empresa.`,
    validating: `Validando su pedido`,
    deploying: `Implementando...`,
    'no-term': `Sin periodo de vigencia`,
    promo: `Promoción`,
    'add-promo-code': `Añadir código promocional`,
    'promo-code': `Código promocional`,
    'checking-promo': `Comprobando código promocional`,
    'promo-validated': `Código validado`,
    'no-deploy': `Nota: Aunque puede crear y guardar carritos, los permisos de su cuenta le impiden solicitar servicios.`,
    'invalid-code': `Código no válido`,
    'code-not-confirmed': `No se ha podido confirmar el código promocional`,
    'save-failed': `Error al guardar los servicios configurados`,
    'error-deploying': `Error al implementar los servicios.`,
    'unknown-error': `Se ha producido un error desconocido al tratar de realizar la implementación`,
    deployed: `Se han implementado correctamente los servicios.`,
    success: `El Id. de pedido {orderId} ha implementado correctamente los servicios:`,
    'null-code': `Introduzca un código promocional`,
    'capture-tech-support-details': `Detalles de la asistencia técnica de Capture`,
    'set-up-tech-support-details': `Detalles de la asistencia técnica`,
    'need-tech-support-details': `Los datos de contacto del servicio de asistencia técnica son necesarios para poder enviar los pedidos.`,
    'need-tech-support-details-by-admin': `Los datos de contacto del servicio de asistencia técnica deberán ser cumplimentados por un administrador de la empresa.`,
    'missing-tech-support-details': `Faltan datos de contacto del servicio de asistencia técnica`,
    'deleting-configuration': `Eliminando configuraciones guardadas...`,
    'refreshing-services': `Actualizando servicios...`,
  },
  sidebar: {
    'enable-market': `Habilitar mercado de {country}`,
    'configured-services': `Servicios configurados`,
    'save-configured-services': `Guardar servicios configurados`,
    'save-configured-services-later': `Guardar estos servicios configurados para otro momento.`,
    'saved-configurations-alert': `Las configuraciones guardadas conciernen a toda la empresa y serán visibles para todos los usuarios de su organización.`,
    'existing-configuration-warning': `Ya existe una configuración con este nombre. Si guarda con el mismo nombre, se sobrescribirá la configuración guardada anteriormente.`,
    'clear-configured-services': `Borrar todos los servicios configurados`,
    'save-configured-name': `Introduzca un nombre para la configuración`,
    'saved-configurations': `Configuraciones Guardadas`,
    'config-saved-title': `Se ha guardado la configuración {name}`,
    'config-saved-message': `Configuración {name} ({serviceOrder}) guardada con éxito en el servidor.`,
    'config-save-error-title': `Error al guardar la configuración {name}.`,
    'config-deleted-title': `Configuración eliminada`,
    'config-delete-error-title': `No ha sido posible eliminar la configuración {name}.`,
  },
  'billing-markets': {
    'vat-characters': `Introduzca un número de IVA con {length} caracteres`,
    'purchase-order-market': `El número de pedido de compra de la factura aparecerá en el encabezado de la factura para el mercado {country} cuando se genere una factura`,
    'due-date-date': `Fecha de vencimiento: {date}`,
    'remove-card-ending': `Quitar la tarjeta que acaba en {numbers}`,
    'edit-card-ending': `Editar opciones para la tarjeta que acaba en {numbers}`,
    'pay-card-ending': `Realice un pago utilizando la tarjeta registrada que acaba en {numbers}.`,
    'remove-card-confirmation': `¿Seguro que quiere quitar la tarjeta de crédito que acaba en {numbers} del mercado de facturación {marketName} de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}?`,
    'dont-accept-currency': `Actualmente no aceptamos pagos en {currency} a través de tarjeta de crédito en este mercado. Consulte su factura para conocer otros métodos de pago.`,
    title: `Mercados de facturación de la empresa`,
    enabled: `Mercados habilitados`,
    none: `No hay mercados habilitados`,
    options: `Opciones`,
    'view-invoices': `Ver facturas`,
    payment: `Realizar pago`,
    edit: `Editar mercado`,
    view: `Ver mercado`,
    'download-invoices': `Descargar todas las facturas`,
    'download-json': `Descargar como JSON`,
    'download-csv': `Descargar CSV`,
    'enable-bm': `Habilite un mercado de facturación`,
    'none-available': `No hay mercados disponibles`,
    enable: `Habilitar`,
    market: `Mercado`,
    invoices: `Facturas`,
    'next-invoice': `Siguiente factura`,
    disclaimer: `(suponiendo que no hay cambios de velocidad y sin incluir los impuestos)`,
    balance: `Saldo de cuenta`,
    'inc-tax': `(impuestos incluidos)`,
    'download-statement': `Descargar extracto`,
    'no-bills': `Aún no se han facturado servicios en este mercado.`,
    'invoice-no': `Núm. de factura`,
    'issue-date': `Fecha de emisión`,
    total: `Total`,
    payments: `Pagos`,
    'no-stripe': `Los pagos por Stripe aún no están disponibles`,
    'cc-history': `Historial de pagos con tarjeta de crédito`,
    'no-payments': `No hay ningún pago registrado`,
    description: `Descripción`,
    'payment-date': `Fecha del pago`,
    'unavailable-market': `No se aceptan pagos con tarjeta de crédito en este mercado. Consulte su factura para conocer otros métodos de pago`,
    'billing-currency': `Divisa de facturación`,
    'currency-fixed': `No se puede cambiar la divisa de facturación`,
    'no-currency-change': `No se puede cambiar la divisa de facturación una vez que se ha configurado el mercado`,
    language: `Idioma de la factura`,
    'language-future-invoices': `Nota: Los cambios de idioma de la factura solo afectarán a las facturas futuras.`,
    'billing-name': `Nombre de contacto de facturación`,
    'billing-phone': `Teléfono de contacto de facturación`,
    'phone-format': `Número de teléfono internacional. Ejemplo: +61 4321 1234`,
    'billing-email': `Correo electrónico de contacto de facturación`,
    'email-format': `Una única dirección de correo electrónico o una lista de direcciones de correo electrónico separadas por comas`,
    'billing-address': `Dirección de facturación`,
    'address-2': `Línea de dirección 2`,
    city: `Ciudad`,
    province: `Provincia`,
    state: `Estado`,
    postcode: `Código postal`,
    'select-or-type': `Seleccione o escriba para filtrar las opciones`,
    'company-registration': `Núm. de registro de la empresa`,
    'company-tooltip': `Número de registro de la empresa asociado a la entidad compradora.`,
    'vat-registration': `¿Está registrado a efectos de IVA?`,
    'vat-id': `Núm. de identificación de IVA`,
    'vat-number': `Número de identificación de IVA registrado.`,
    po: `Número de pedido de compra de la factura`,
    currency: `Divisa del mercado`,
    'company-identifier': `Identificador de la empresa`,
    'contact-name': `Nombre de contacto`,
    'phone-number': `Número de teléfono`,
    address: `Dirección`,
    'due-unavailable': `No disponible`,
    vat: `IVA`,
    'register-card': `Registrar tarjeta`,
    'register-card-status': `Estado de configuración de la tarjeta: {status}`,
    'register-card-edit': `Editar Tarjeta Registrada`,
    'register-card-fail': `Error de registro de tarjeta`,
    'register-card-success': `La tarjeta se ha registrado correctamente.`,
    'register-card-fail-message': `Error al registrar la tarjeta.`,
    'register-card-message': `Registra una tarjeta con un mercado de facturación de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} para realizar pagos automáticos (una vez emitida la factura) o pagos manuales puntuales.`,
    'registering-card': `Registrando tarjeta...`,
    'remove-card': `Quitar tarjeta`,
    'removing-card': `Quitando tarjeta...`,
    'remove-card-fail': `Error al quitar la tarjeta`,
    'remove-card-fail-message': `Error al quitar la tarjeta: {error}`,
    'update-card': `Actualizar Tarjeta`,
    'update-card-success': `La tarjeta se ha actualizado correctamente.`,
    'update-card-fail': `Error al actualizar la tarjeta`,
    'update-card-fail-message': `Error al actualizar la tarjeta: {error}`,
    'remove-card-success': `La tarjeta se ha quitado correctamente.`,
    'save-card-fail': `Error al guardar la tarjeta`,
    'save-card-fail-message': `No se puede guardar la información de la tarjeta: {error}`,
    'payment-once': `Realizar pago puntual`,
    'payment-once-make': `Realizar un único pago puntual en su cuenta.`,
    'payment-processing': `Procesando el pago`,
    'payment-confirm-fail': `Error al confirmar la configuración del pago`,
    'payment-error': `Error de pago inesperado`,
    'payment-success': `Pago realizado correctamente.`,
    'payment-success-message': `Se ha realizado un pago de {amount} {currency} al mercado [{market}]`,
    'card-number': `Número de tarjeta`,
    'card-expiry': `Caducidad`,
    'card-cvc': `CVC`,
    'auto-payments-on': `Pagos Automáticos Activados`,
    'auto-payments-off': `Pagos Automáticos Desactivados`,
    'payment-amount': `Importe del Pago`,
    'payment-description': `Descripción del Pago`,
    'payment-failed': `Error de Pago`,
    'payment-create-failed': `Error al Crear el Método de Pago`,
    'payment-create-failed-message': `No se puede crear el método de pago con Stripe: {error}`,
    'payment-attempt-failed': `Error al intentar realizar el pago: {error}`,
    'payment-market-fail': `No se ha realizado ningún pago al mercado [{market}]. {error}`,
    'processing-fees': `Las comisiones por transacción con tarjeta de crédito se añaden al importe del pago y se reflejan en tu siguiente factura. ${process.env.VUE_APP_EN_COMPANYINFO_NAME} pasa estas comisiones directamente desde nuestro proveedor de servicios.`,
    'account-balance': `El saldo actual de su cuenta es de | (impuestos incluidos).`,
    'enable-markets': `Habilitar Mercados`,
    'services-no-market': `Ha configurado servicios para mercados que aún no ha habilitado.`,
    'enable-markets-admin': `Es necesario que un administrador de la empresa habilite los siguientes mercados de facturación:`,
    'no-payment-method': `No se ha recibido ningún método de pago.`,
    'stripe-error': `Error de Stripe`,
    'update-success': `Se actualizó correctamente el suministro del mercado de "{country}".`,
    supply: `suministro del mercado`,
    'create-success': `Se creó correctamente el suministro del mercado de "{country}".`,
    'payment-docs-html': `Puedes obtener información adicional sobre las comisiones por transacciones con tarjeta de crédito de nuestro proveedor de servicios, Stripe, {link}`,
    'cc-autopay': `Los pagos automáticos se procesarán el día 20 de cada mes por el saldo pendiente de su cuenta.`,
    'cc-support-html': `Si tienes alguna pregunta relacionada con los pagos con tarjeta de crédito, ponte en contacto con {email}`,
    download: `Descargar`,
    'due-date': `Fecha de vencimiento`,
    'invoice-date': `Fecha de factura`,
    'invoice-amount': `Importe de la factura`,
    'open-balance': `Saldo pendiente`,
    'date-range': `Rango de fechas`,
    'download-pdf': `Descargar PDF`,
    'download-invoices-info': `Le rogamos que tenga en cuenta lo siguiente: La función Download All Invoices (Descargar todas las facturas) ya no se admite. Descargue facturas individuales desde el icono View Invoices (Ver facturas) de arriba y seleccionando un intervalo de fechas.`,
    'billing-address-entry-rules': `Esta dirección debe ser el lugar de trabajo donde se encuentran los principales responsables de la toma de decisiones en relación con la contratación de los servicios de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} y su puesta en marcha.`,
    UK_MOBQ: `¿Utiliza nuestros servicios en el Reino Unido?`,
    UK_MOBQ_TOOLTIP: `Confirme si tiene personal en el Reino Unido que utiliza estos servicios`,
    AU_MOBQ: `¿Está registrado/a en el GST australiano?`,
    AU_MOBQ_TOOLTIP: `Confirma si la entidad legal o la sucursal que utiliza los servicios está registrada en el GST australiano (podría ser afiliada de la entidad facturada por ${process.env.VUE_APP_EN_COMPANYINFO_NAME}).`,
    SG_MOBQ: `¿Está registrado/a en el GST Singapur?`,
    SG_MOBQ_TOOLTIP: `Confirma si la entidad legal o la sucursal que utiliza los servicios está registrada en el GST de Singapur (podría ser afiliada de la entidad facturada por ${process.env.VUE_APP_EN_COMPANYINFO_NAME}).`,
    'company-type-mexico': `Tipo de empresa`,
    'company-type-mexico-tooltip': `Tipo de contribuyente (persona física, persona jurídica o entidad extranjera)`,
    'tax-regime-code-mexico': `Régimen fiscal`,
    'tax-regime-code-mexico-tooltip': `Régimen Fiscal - Selecciona el código de Régimen Fiscal vigente registrado ante el Servicio de Administración Tributaria (SAT) de México.`,
    'sat-purpose-mexico': `Finalidad fiscal`,
    'sat-purpose-mexico-tooltip': `Finalidad fiscal`,
    'rfc-mexico': `Número RFC`,
    'rfc-mexico-tooltip': `Asigna el número de Registro Federal de Contribuyentes vigente - expedido por el Servicio de Administración Tributaria (SAT).`,
    'fusf-interstate-traffic-certification': `Certificación federal de tráfico interestatal del USF*`,
    'fusf-recommended-text': `* Se recomienda encarecidamente leer la información en pantalla para obtener más contexto.`,
    'fusf-tooltip-content': `Para los productos de transmisión de datos* con puntos finales de servicio dentro del mismo estado, Megaport está obligado por la Comisión Federal de Comunicaciones (FCC) a dar por hecho que el tráfico del cliente en última instancia cruza las fronteras estatales como parte de la red más amplia del cliente, a menos que el cliente confirme lo contrario.<br/><br/>
      Al activar la casilla de verificación, el cliente certifica que <strong>mas del 10 %</strong> del tráfico es de naturaleza interestatal en relación con todos los productos adquiridos a Megaport. Cuando este sea el caso, los servicios estarán sujetos a los cargos del Fondo de Servicio Universal Federal (FUSF).<br/><br/>
      Al no activar la casilla de verificación, el cliente certifica que el <strong>10 % o menos</strong> del tráfico transmitido a través de dichos productos cruza las fronteras estatales, lo que significa que no se aplicará el FUSF.<br/><br/>
      Se generará un registro electrónico del nombre y el título del usuario de la cuenta que haya proporcionado la certificación anterior para los registros de Megaport y podrá usarse para fines de auditoría.<br /><br />
      *Los productos de transmisión de datos de Megaport incluyen VXC, Megaport Internet e Internet Exchange.`,
  },
  users: {
    'configure-destination-notifications': `Configurar notificaciones de {destination}`,
    'editing-user-name-you': `Editando Usuario {firstName} {lastName} {you}`,
    'user-not-found-redirect': `No se ha encontrado el usuario {userId}. Redirigiéndole a la página Administrar usuarios.`,
    'position-not-found': `Este usuario tiene un rol ({position}), que ya no es compatible con el sistema. Seleccione entre las opciones disponibles.`,
    'invalid-email': `{email} no es una dirección de correo electrónico válida`,
    deactivated: `Mostrar usuarios desactivados`,
    notifications: `Notificaciones`,
    destinations: `Destinos Disponibles`,
    'no-destinations': `No Hay Destinos Disponibles`,
    'detailed-notifications': `Mostrar Notificaciones Detalladas`,
    filter: `Filtrar`,
    'no-match': `Ningún Usuario Coincidente`,
    active: `La cuenta de usuario está activa`,
    disabled: `La cuenta de usuario está deshabilitada`,
    you: `(Usted)`,
    new: `Nuevo Usuario`,
    'logged-in-as-user': `Ha iniciado sesión con este usuario. Puede modificar los datos de su usuario en la página Perfil de Usuario.`,
    state: `Estado del Usuario`,
    'technical-admin': `Administrador Técnico`,
    finance: `Finanzas`,
    'financial-contact': `Contacto Financiero`,
    role: `Rol del usuario`,
    'role-not-found': `Rol No Encontrado`,
    'user-not-found': `Usuario No Encontrado`,
    'missing-info': `Información Que Falta`,
    'change-email': `Cambiar Correo Electrónico`,
    'changing-email': `Cambiando Correo Electrónico...`,
    'new-email': `Nuevo Correo Electrónico`,
    'email-preferences': `Preferencias de Correo Electrónico`,
    'saving-email-preferences': `Guardando Preferencias de Correo Electrónico...`,
    'receive-newsletter': `Recibir Boletín de Noticias de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'receive-promotional-material': `Recibir Material Promocional de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'change-password': `Cambiar Contraseña`,
    'changing-password': `Cambiando Contraseña...`,
    'old-password': `Contraseña Anterior`,
    'new-password': `Nueva Contraseña`,
    'retype-new-password': `Volver a Escribir la Nueva Contraseña`,
    'saving-user-profile': `Guardando Perfil de Usuario`,
    'account-password': `Contraseña de la Cuenta`,
    username: `Nombre de usuario`,
    'loading-qr-code': `Cargando código QR`,
    'account-activity': `Actividad de la Cuenta`,
    'account-activity-none': `No hay datos de actividad`,
    'account-activity-loading': `Cargando datos de actividad...`,
    'activity-data-load-fail': `Error al cargar datos de actividad`,
    'save-failed': `Error al Guardar`,
    'save-user-profile-failed': `Error al guardar el perfil de usuario`,
    'save-email-failed': `Error al cambiar el correo electrónico`,
    'save-email-preference-failed': `Error al guardar las preferencias de correo electrónico`,
    'profile-saved': `El perfil se ha actualizado correctamente.`,
    'email-change-success': `Preferencias del correo electrónico modificadas.`,
    'save-password-failed': `Error al Guardar la Contraseña`,
    'save-password-failed-message': `Error al guardar la contraseña actualizada`,
    'saving-user-profile-error': `Error al actualizar el perfil`,
    profile: `Perfil`,
    'user-name': `Usuario {firstName} {lastName}`,
    'verification-email': `Hemos enviado un correo electrónico de verificación a su nueva dirección.`,
    'verification-message': `Siga las instrucciones de dicho correo electrónico.`,
    'user-role': `Rol de usuario`,
    'viewing-user-name-you': `Visualizando usuario {firstName} {lastName} {you}`,
    'cant-edit-invite-pending': `Este perfil de usuario no puede ser editado hasta que el usuario haya aceptado la invitación. Elimine el usuario y vuelva a invitarlo si se necesitan cambios inmediatamente.`,
    'deleting-user': `Eliminando usuario...`,
    'disable-mfa': `Desactivar MFA`,
    'enable-mfa': `Activar MFA`,
    'reset-mfa': `Restablecer MFA`,
    'disable-mfa-dialog': `Desactivar autenticación multifactor`,
    'enable-mfa-dialog': `Activar autenticación multifactor`,
    reset: `Restablecer`,
    'mfa-long': `Autenticación multifactor`,
    'reset-mfa-dialog': `Restablecer autenticación multifactor`,
    'reset-mfa-success': `Autenticación multifactor restablecida correctamente`,
    'reset-mfa-failed': `Error al restablecer autenticación multifactor`,
    'reset-mfa-dialog-desc': `Estás a punto de restablecer tu secreto de autenticación multifactor. Una vez que hayas escaneado el código QR y lo hayas confirmado, los nuevos secretos se activarán y todos los secretos anteriores de los dispositivos quedarán invalidados.`,
    'mfa-enforced-disable-tooltip': `El administrador de tu empresa ha establecido una política global que impone la MFA. No puedes desactivar la MFA mientras dicha política esté en vigor.`,
    'position-header': `Rol`,
    'mfa-header': `MFA`,
    'mfa-tooltip-not-set': `El usuario no ha configurado la autenticación multifactor`,
    'mfa-tooltip-set': `El usuario ha configurado la autenticación multifactor`,
    set: `Establecida`,
    'not-set': `No establecida`,
    enforced: `Impuesta`,
    optional: `Opcional`,
    'partner-only': `Solo socios`,
    'partner-only-tooltip': `Configura las notificaciones por correo electrónico asociadas únicamente a tu cuenta de socio`,
    'partner-and-managed': `De socio y administradas`,
    'partner-and-managed-tooltip': `Configura las notificaciones por correo electrónico asociadas a tu cuenta de socio y a todas tus cuentas administradas`,
    'company-admin-reset-mfa-confirm-title': `¿Seguro que quieres restablecer la autenticación multifactor?`,
    'company-admin-reset-mfa-confirm-message': `Asegúrate de que {username}, para quien solicitas el restablecimiento, sea una persona real, y no una cuenta utilizada para integraciones máquina a máquina. Una vez restablecida la MFA, se le pedirá al usuario que restablezca sus credenciales al iniciar sesión.`,
    'company-admin-reset-help-text': `Ayuda a este usuario a restablecer su cuenta. Esto requerirá que el usuario inscriba un nuevo dispositivo`,
    'mfa-alt-tag': `Utiliza la cámara para escanear el código QR`,
    'mfa-app-suggestion': `Google Authenticator es nuestra aplicación de autenticación recomendada.`,
    'mfa-token': `Introduce el token de un solo uso generado`,
    'mfa-loading-failed': `Error al cargar el código QR,`,
    'mfa-disabled': `Autenticación multifactor desactivada`,
    'enable-mfa-success': `Autenticación multifactor activada correctamente.`,
    'disable-mfa-fail': `Error al desactivar la autenticación multifactor`,
    'view-user-actions': `Ver todas las acciones del usuario`,
    'mfa-warning-1': `Asegúrate de que la cuenta registrada en tu aplicación de autenticación multifactor esté eliminada.`,
    'mfa-warning-2': `Su sesión se cerrará una vez que la deshabilite.`,
    'loading-users': `Cargando usuarios de la empresa...`,
    'sso-compatibility': `Compatibilidad SSO`,
    'sso-enabled-tooltip': `El dominio de correo electrónico de los usuarios está vinculado a SSO y utilizarán SSO por defecto para iniciar sesión`,
    'sso-enforced-no-access-tooltip': `SSO está impuesto y el usuario NO es compatible con SSO. Acceso prohibido`,
    'sso-optional-can-login-tooltip': `El dominio de correo electrónico de los usuarios NO es compatible con SSO, aunque el usuario puede iniciar sesión por otros métodos`,
    'active-api-keys-found': `Claves de API activas encontradas`,
    'active-api-keys-inactive-if-continue': `Se han encontrado claves de API activas para este usuario; se desactivarán si continúas. Todas las claves de API pueden revisarse en Tools (Herramientas) - API Key Generator (Generador de clave de API)`,
    'active-api-keys-input-label': `Para continuar, escribe Inactivate (desactivar) y haz clic en Continue (Continuar), o en Cancel (Cancelar) para abortar la operación.`,
    'active-api-keys-error': `Escribe Inactivate (desactivar) para continuar`,
    'active-api-keys-review': `Se recomienda revisar si las claves de API generadas por este usuario se utilizan en una integración crítica del sistema entre tu empresa y ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
  },
  'api-keys': {
    'description-intro': `Si necesitas acceso directo a la API de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}, necesitas una clave de API.`,
    'description-html': `Las claves de API solo son válidas en el entorno en el que se hayan generado. Actualmente has iniciado sesión en el entorno {environment}, por lo que las claves generadas y expuestas aquí solo funcionarán en el entorno {environment}. Si necesitas claves para acceder a un entorno distinto, inicia sesión en el ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_SHORT} de ese entorno y genera allí las claves.`,
    'learn-more-html': `Más información sobre entornos en el {link}`,
    forbidden: `No puede crear ni administrar claves de API a menos que sea administrador de una empresa configurada con un mercado de facturación habilitado.`,
    'key-description': `La clave de API es un identificador único que autentica las solicitudes con fines de uso y facturación. Utilice la clave para generar un token que autentique las solicitudes de API.`,
    'key-generated-message': `Se ha generado la clave de API {key}`,
    'key-updated-message': `Se ha actualizado la clave de API {key}`,
    'key-deleted-message': `Se ha eliminado la clave de API {key}`,
    'key-regenerated-message': `Se ha generado un nuevo secreto para la clave de API {key}`,
    'key-dialog-description': `Su clave de API se ha creado correctamente. Copie su nueva clave de API y el secreto de la clave de API y utilícelos para generar un token de API.`,
    'role-change': `¿Seguro que quiere cambiar el rol <strong>{oldRole}</strong> a <strong>{newRole}</strong>?<br/><br/>El administrador de la empresa tiene acceso completo a la API y puede crear, administrar y cancelar servicios. Solo lectura puede ver los datos de uso y los detalles de la configuración, pero no puede realizar ningún cambio.`,
    'delete-key': `¿Seguro que quiere eliminar esta clave? Una vez eliminada, no puede usar la clave para generar nuevos tokens.`,
    'regenerate-secret': `Si vuelve a generar el secreto, se cancelarán todas las sesiones que utilicen el secreto anterior. Necesitará utilizar el secreto nuevo para generar tokens que le permitan utilizar esta clave. ¿Seguro que quiere volver a generar el secreto?`,
    'api-keys': `Claves de API`,
    'read-only': `Read Only (Solo Lectura) es el rol más restrictivo. Tenga en cuenta que un usuario con el rol Read Only (Solo Lectura) puede ver detalles del servicio que quizá usted quiera proteger y ocultar.`,
    admin: `Administrador de la empresa`,
    'unknown-role': `Rol desconocido`,
    'your-api-key': `Su clave de API`,
    'api-key': `Clave de API`,
    secret: `Secreto de la clave de API`,
    'secret-message': `No podrá volver a generar o ver este secreto. Asegúrese de guardarlo en un lugar seguro.`,
    history: `Historial de claves de API`,
    'history-order': `Los cambios más recientes se muestran en la parte superior`,
    'edited-by': `Editado por`,
    'update-date': `Última actualización`,
    role: `Rol`,
    created: `Clave creada`,
    updated: `Clave actualizada`,
    regenerated: `Secreto generado de nuevo`,
    'unknown-action': `Acción de clave desconocida`,
    'unknown-user': `Usuario desconocido`,
    'unable-to-read': `No se pueden leer las claves de API`,
    key: `Clave`,
    'generate-key': `Generar clave`,
    'generate-label': `Generar claves de API`,
    generating: `Generando clave de API`,
    'read-only-impersonate': `Puesto que está suplantando a otro usuario, tiene acceso de solo lectura a cualquier clave de la cuenta.`,
    'success-generate': `Clave de API generada correctamente.`,
    'failed-generate': `Error de generación de la clave de API`,
    'success-update': `Clave de API actualizada correctamente.`,
    'failed-update': `Error de actualización de la clave de API.`,
    'success-delete': `Clave de API eliminada correctamente.`,
    'failed-delete': `Error de eliminación de la clave de API.`,
    'active-keys': `Activar claves de API`,
    'no-active-keys': `Actualmente no tiene ninguna clave activa. Genere una clave para comenzar.`,
    'active-tooltip': `Necesitarás la clave y el secreto para generar el token de acceso. Se te mostró el secreto una vez cuando generaste la clave o un nuevo secreto para la clave.`,
    'date-created': `Fecha creada`,
    'added-by': `Añadido por`,
    'loading-user-data': `Cargando datos del usuario`,
    'loading-api-keys': `Cargando claves de API existentes`,
    'deleting-api-keys': `Eliminando clave de API`,
    'regenerating-secret': `Volviendo a generar el secreto`,
    'saving-changes': `Guardando cambios`,
    'regenerate-secret-label': `Volver a generar el secreto`,
    'view-history': `Ver historial`,
    'confirm-role-change': `Confirmar cambio de rol`,
    'delete-key-label': `Eliminar clave`,
    'company-admin': `Los usuarios Company Admin (Administrador de la Empresa) tienen acceso a todos los privilegios de usuario. Recomendamos limitar el número de usuarios Company Admin (Administrador de la Empresa) a las personas que necesiten acceso completo. Sin embargo, recomendamos definir al menos dos usuarios de este tipo para garantizar la redundancia.`,
    'technical-admin': `Este rol es para usuarios técnicos que saben crear y aprobar pedidos.`,
    'technical-contact': `Este rol es para usuarios técnicos que saben diseñar y modificar servicios, pero no tienen autoridad para aprobar pedidos.`,
    finance: `Los usuarios Finance (Financiero) deben tener una responsabilidad financiera dentro de la organización y comprender las consecuencias de sus acciones si eliminan o aprueban servicios. {note}`,
    'financial-contact': `Este rol de usuario es similar al rol Finance (Financiero), pero sin la capacidad de realizar y aprobar pedidos, eliminar servicios o administrar claves de servicio.`,
    'read-only-short': `Solo lectura`,
    'token-expiry': `Caducidad del token (minutos)`,
    'expiry-min': `Mínimo 5 minutos`,
    'expiry-max': `Máximo 24 horas (1440 minutos)`,
    'api-resource': `Recurso de la API`,
    action: `Acción`,
    'name-min': `Mínimo 3 caracteres`,
    'name-max': `Máximo 90 caracteres`,
    'name-chars': `Sin caracteres especiales, excepto guiones y guiones bajos`,
    'name-invalid': `El nombre no es válido`,
    'api-documentation': `Documentación sobre API de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'finance-note': `Nota: Al crear servicios, los clientes directos pueden ver el coste del servicio antes de implementar. Para ver el coste de los servicios implementados, selecciona un servicio en el ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_SHORT} y abre la pestaña Details (Detalles).`,
    'api-role': `API`,
    'key-active-tooltip': `La clave está activa`,
    'key-inactive-tooltip': `La clave está inactiva debido a la desactivación del usuario. A menos que el usuario que creó esta clave vuelva a estar activo, esta clave no se puede usar. Puedes considerar eliminar esta clave y crear una nueva clave de API contra un usuario activo.`,
  },
  debug: {
    information: `Información de depuración`,
    'include-info': `Incluya la siguiente información si tiene previsto crear una incidencia.`,
  },
  pricebook: {
    product: `Producto`,
    'monthly-rate': `Tarifa mensual`,
    'excludes-tax': `Impuestos no incluidos en el precio`,
    'contact-sales': `Póngase en contacto con el departamento comercial para obtener tarifas personalizadas.`,
    failed: `Error al obtener el precio del servicio {product}`,
    'monthly-charges-excl-taxes': `Cargos mensuales, impuestos no incluidos`,
    'price-change-1': `El cargo recurrente mensual actual correspondiente a este servicio es de {currentMonthlyPrice} (impuestos no incluidos). Siempre que no realice cambios de velocidad, este servicio tendrá un coste de {totalPrice} (impuestos no incluidos) en el periodo de facturación actual. Se trata de {changeDirectionText} de {deltaPrice} (impuestos no incluidos). El nuevo cargo recurrente mensual estimado correspondiente a este servicio será de {longTermMonthlyPrice} (impuestos no incluidos).`,
    'price-change-2': `Los cambios que realice a este servicio se traducirán en un cambio de precio. Si no realiza cambios de velocidad, el coste de este servicio será de {totalPrice} (impuestos no incluidos) en el periodo de facturación actual. Se trata de {changeDirectionText} de {deltaPrice} (impuestos no incluidos).`,
    'price-change-3': `El nuevo cargo mensual correspondiente a este servicio será de {longTermMonthlyPrice} (impuestos no incluidos. Impuestos). Confirme estos cambios.`,
  },
  'price-change': {
    detected: `Cambio de tarifa detectado`,
    cancel: `No, cancelar cambios`,
    accept: `Sí, aceptar cambios`,
    'current-month': `Cargo mensual actual`,
    'new-monthly': `Nuevo cargo mensual`,
    'this-period': `Este periodo de facturación`,
    change: `Cambiar`,
    'pricing-model': `Los cambios que realice a este servicio se traducirán en un alteración del precio, de acuerdo con nuestro nuevo modelo actualizado de tarificación.`,
    'confirm-model-change': `Una vez aceptado el cambio, no será posible volver a la tarificación anterior y esto se reflejará en la siguiente factura que se emita. Confirme que quiere aceptar estos cambios.`,
    increase: `aumento`,
    decrease: `disminución`,
    'current-monthly': `Cargo mensual actual`,
  },
  cancel: {
    immediate: `Cancelación inmediata`,
    'remove-configured': `Quitar los servicios configurados`,
    terminate: `Terminar servicios`,
    'keep-configured-service': `No, mantener el servicio configurado`,
    'keep-configured-services': `No, mantener los servicios configurados`,
    'remove-configuration': `Sí, quitar configuración`,
    'keep-services': `No, mantener los servicios`,
    'clear-configured': `¿Seguro que quiere borrar todos los servicios configurados?`,
    'remove-from-configured': `¿Seguro que quiere quitar este servicio de los servicios configurados?`,
    'end-of-contract': `Terminar al final del contrato`,
    later: `En otro momento`,
    'terminate-immediately': `Terminar de inmediato`,
    now: `Ahora`,
    'now-confirmation': `Si selecciona "Terminar servicios", confirmará el proceso de Terminación. Estos servicios se quitarán de inmediato. Se aplicará una tasa de cancelación anticipada (ETF) a los siguientes servicios:`,
    'later-confirmation': `Si selecciona "Terminar servicios", confirmará el proceso de Terminación. Estos servicios continuarán funcionando de la forma habitual hasta la fecha indicada en el servicio a continuación. Se aplicará una tasa de cancelación anticipada (ETF) a los siguientes servicios:`,
    unable: `No se puede cancelar:`,
    better: `¿Alguna sugerencia de mejora?`,
    'no-permission': `No tiene permiso para eliminar servicios`,
    error: `Error al cargar los datos de cancelación`,
    diversity: `Relación de diversidad encontrada`,
    'issues-terminating': `0 | Problemas terminando 1 servicio. | Problemas terminando {count} servicios.`,
    terminating: `0 | Terminando 1 servicio. | Terminando {count} servicios.`,
    terminated: `0 | 1 servicio terminado. | {count} servicios terminados.`,
    pending: `Pendiente de terminación`,
    'do-not-restore': `No, no restaurar el servicio`,
    restore: `Sí, restaurar el servicio`,
    restoring: `Restaurando servicio...`,
    'cancel-termination-confirmation': `Se ha programado la terminación de este servicio. ¿Le gustaría cancelar esta solicitud de terminación y restaurar el servicio?`,
    restored: `Restauración pendiente del servicio terminado.`,
    'error-uncancelling': `Se ha producido un error al eliminar la cancelación del servicio.`,
  },
  canada: {
    ab: `Alberta`,
    bc: `Columbia Británica`,
    mb: `Manitoba`,
    nb: `Nuevo Brunswick`,
    nl: `Terranova y Labrador`,
    nt: `Territorios del Noroeste`,
    ns: `Nueva Escocia`,
    nu: `Nunavut`,
    on: `Ontario`,
    pe: `Isla del Príncipe Eduardo`,
    qc: `Quebec`,
    sk: `Saskatchewan`,
    yt: `Yukón`,
  },
  company: {
    'trading-name': `Nombre comercial`,
    'view-markets': `Ver mercados de la empresa`,
    'company-trading-name': `Nombre comercial de la empresa`,
    'company-legal-name': `Nombre legal de la empresa`,
  },
  alibaba: {
    'connection-details': `Detalles de conexión para el servicio de Alibaba`,
    'account-id': `ID de cuenta de Alibaba Cloud`,
    'account-tooltip': `Obténgala de su cuenta de Alibaba`,
    connection: `Conexión de Alibaba`,
    'connection-id': `ID de conexión de Alibaba`,
  },
  amsix: {
    'connection-details': `Detalles de conexión para el servicio AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    peer: `¿Quiere realizar un emparejamiento con servidores de ruta?`,
    selective: `Selectivo`,
    'noc-contact': `Contacto NOC`,
    'url-tooltip': `Los servicios AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX} requieren una URL web para anunciar la información de la empresa sobre la ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'email-tooltip': `Los servicios AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX} requieren un correo electrónico para anunciar la información de la empresa en ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'select-country': `Seleccionar país`,
    'admin-phone-tooltip': `El número de teléfono lo puede añadir el administrador de una empresa en la pantalla Administrar usuarios, o el usuario en el perfil de usuario.`,
    'phone-tooltip': `Puede actualizar su número de teléfono en su perfil de usuario, o bien ponerse en contacto con el administrador de su empresa.`,
  },
  aws: {
    'connection-details': `Detalles de conexión para el servicio de AWS`,
    'edit-warning': `Editar los detalles de AWS en este servicio inicia una recompilación y requiere la aceptación como nueva solicitud de conexión VIF a través de la consola de AWS de la cuenta de recepción. Confirme antes de realizar cambios.`,
    'warning-button': `Acepto`,
    'start-editing': `Haga clic en el botón de arriba para comenzar a editar`,
    'no-edit-hosted': `Nota: Los detalles de AWS de conexión alojada no son editables.`,
    'connection-name': `Nombre de la conexión de AWS`,
    'connection-name-tooltip': `Etiquetar el servicio en AWS`,
    'account-id': `Id. de la cuenta de AWS`,
    'account-id-tooltip': `Obténgalo de la configuración de su cuenta de AWS en la consola de AWS`,
    'bgp-asn': `ASN de BGP público o privado`,
    'amazon-asn': `ASN de Amazon`,
    'default-amazon-asn': `Déjelo en blanco para utilizar el valor predeterminado`,
    'bgp-key': `Clave de autenticación de BGP`,
    'bgp-key-tooltip': `Introduzca una clave o contraseña de autenticación de BGP (6-24 caracteres, sin espacio en blanco) Si no la proporciona, generaremos una`,
    'amazon-prefixes': `Prefijos de Amazon`,
    'amazon-prefixes-tooltip': `Prefijos para anunciarse en AWS, solo rangos de direcciones IPv4 asignadas a RIR`,
    'connection-type': `Tipo de conexión de AWS`,
    'hosted-vif': `VIF alojada`,
    'hosted-vif-description': `Las VIF alojadas (interfaces virtuales alojadas) se pueden conectar a servicios en la nube de AWS públicos o privados.`,
    'hosted-connection': `Conexión alojada`,
    'documentation-html': `Para obtener más información sobre las opciones de conexión a AWS, haz clic aquí: {link}`,
    'diversity-html': `Para obtener más detalles, consulte el {link}`,
    'vif-connection': `Conexión VIF de AWS`,
    'vif-id': `Id. de VIF`,
    label: `Etiqueta de AWS`,
    'customer-address': `Dirección del cliente`,
    'amazon-address': `Dirección de Amazon`,
    'aws-hosted-connection': `Conexión alojada de AWS`,
    'aws-vif': `VIF de AWS`,
    'connection-id': `Id. de conexión de AWS`,
    'manual-intervention': `Nota: Las VIF públicas requieren la intervención manual de Amazon y pueden tardar hasta 48 horas.`,
    'hosted-connection-description': `Cada ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} de conexión alojada admite una única VIF de AWS para una puerta de enlace pública, privada o de tránsito.`,
    'key-features': `Algunas de las funciones clave son:`,
    'aws-vif-feature-list-1': `La tasa del puerto AWS está incluida en la conexión ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'aws-vif-feature-list-2': `Gestión y supervisión del ancho de banda compartido de los clientes.`,
    'aws-vif-feature-list-3': `Posibilidad de cambiar la velocidad de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'aws-vif-feature-list-4': `Configurable en incrementos de 1 Mbps ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} entre 1 Mbps - 5 Gbps.`,
    'aws-vif-feature-list-5': `Configuración automática de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG} adjunto.`,
    'aws-hosted-feature-list-1': `La tasa de AWS ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} se factura a través de Amazon.`,
    'aws-hosted-feature-list-2': `Ancho de banda específico.`,
    'aws-hosted-feature-list-3': `Establece incrementos de velocidad de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} de 50 Mbps a 10 Gbps.`,
    'aws-hosted-feature-list-4': `Sin cambios de velocidad de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'aws-hosted-feature-list-5': `Soporte para diversos ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} para una mayor resiliencia. El código de colores resalta los diversos ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}.`,
    'documentation-link': `Descripción general de la conexión a AWS - Documentación de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'readonly-warning': `Editar los detalles de AWS en este servicio inicia una recompilación y requiere la aceptación como nueva solicitud de conexión VIF a través de la consola de AWS de la cuenta de recepción.`,
    onboarding: `Incorporación a AWS`,
    'onboarding-reachout': `Un miembro de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} se pondrá en contacto con usted para ayudarle con la incorporación en los próximos 1 o 2 días laborables.`,
    'onboarding-questions': `Si tiene alguna pregunta mientras tanto, puede ponerse en contacto con nosotros en la dirección siguiente: {email}`,
    'onboarding-info': `Proporcione tanta información como sea posible, incluida la oferta de AWS o el ID del acuerdo.`,
    'onboarding-success-title': `Incorporación a AWS correcta`,
    'onboarding-success': `Gracias por el envío. Un miembro de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} se pondrá en contacto con usted para ayudarle con la incorporación en los próximos 1 o 2 días laborables.`,
  },
  azure: {
    'primary-subnet': `Subred principal`,
    'primary-subnet-placeholder': `Subred principal, por ejemplo 192.0.1.32/30`,
    'secondary-subnet': `Subred secundaria`,
    'secondary-subnet-placeholder': `Subred secundaria, por ejemplo 192.0.1.32/30`,
    'public-prefixes': `Prefijos públicos`,
    'public-prefixes-placeholder': `Prefijos públicos, por ejemplo 192.0.2.0/24,192.0.2.0/30`,
    'shared-key': `Clave compartida`,
    'update-peer': `Actualizar par`,
    'editing-message': `Es necesario editar los datos de conexión de este servicio desde Azure.`,
    'no-peers': `No se ha definido ningún par`,
    'peer-type': `Tipo de par`,
    'disable-peering-type': `¿Seguro que quiere deshabilitar este tipo de emparejamiento?`,
    'disable-peer-type': `Deshabilitar tipo de par`,
    configuration: `Configuración de Azure`,
    'express-route-html': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} ofrece servicios ExpressRoute a ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} de Microsoft principales o secundarios como Q-in-Q (con doble etiqueta o 802.1ad). Familiarízate con {link} en el sitio de Microsoft.`,
    'peering-html': `Los tipos de emparejamiento público, privado y de Microsoft están disponibles a través de este método utilizando una única clave de servicio ExpressRoute. Consulta {link} para obtener más información sobre estos tipos de emparejamiento y su disponibilidad.`,
    'get-service-key': `Cómo obtener una clave del servicio ExpressRoute de Azure.`,
    'azure-service-key': `Clave del servicio de Microsoft Azure`,
    'peering-type': `Tipo de emparejamiento`,
    'peering-type-explanation': `Ya se ha establecido el tipo de emparejamiento para esta clave de servicio en otro servicio.`,
    microsoft: `Microsoft`,
    'back-to-service-key': `Regrese a Clave de servicio para introducir su clave y crear la conexión.`,
    interface: `Interfaz de Azure`,
    'q-in-q': `Definiciones de interfaz Q-in-Q`,
    'ms-doc': `Documentación de Microsoft`,
  },
  google: {
    'cloud-configuration': `Configuración de Google Cloud`,
    'gci-configuration': `Configuración de GCI`,
    'partner-peering-key': `Clave de emparejamiento de Google Partner`,
    'show-all-locations': `Ver todas las ubicaciones de Google Partner`,
    'back-to-enter-peering': `Vuelva a introducir la clave de emparejamiento`,
    interface: `Interfaz de GCI`,
    connection: `Conexión a Google`,
  },
  ix: {
    'ecix-asn-fixed': `Nota: El ASN no se puede modificar para los servicios de ECIX tras la implementación.`,
    'bgp-password-tooltip': `Introduzca una contraseña de BGP si quiere establecer una.`,
    'bgp-password-warning': `Nota: La contraseña de BGP no se puede modificar tras la implementación.`,
    'looking-glass-graphs': `Los gráficos de uso podrán verse en Looking Glass.`,
    'no-looking-glass-graphs': `Los gráficos de uso no podrán verse en Looking Glass.`,
  },
  'mcr-config': {
    'enable-qinq': `¿Quiere habilitar Q-in-Q?`,
    invalid: `No válido`,
    'add-vlan': `Añadir VLAN`,
    'virtual-lan': `LAN virtual`,
    'unique-id': `Proporcione un Id. único para la VLAN`,
    'vlan-id': `Id. de VLAN`,
    'delete-vlan': `Eliminar VLAN`,
    'interface-ip-addresses': `Direcciones IP de la interfaz`,
    'ip-subnet': `Dirección IP y máscara de subred`,
    nat: `Traducción de direcciones de red (NAT)`,
    'nat-source': `Dirección IP de origen de NAT`,
    'nat-source-tooltip': `Seleccione una dirección IPv4 para habilitar NAT de origen en esta interfaz. Todos los paquetes que salgan de la interfaz cambiarán su dirección de origen por la dirección seleccionada.`,
    'nat-enabled': `NAT habilitado`,
    'bgp-connections-description': `Relaciones de emparejamiento BGP para esta interfaz (máximo de cinco). Requiere la creación de una dirección IP de la interfaz.`,
    'bgp-add': `Añadir conexión BGP`,
    'bfd-settings': `Configuración de Detección de reenvío bidireccional (BFD)`,
    'no-bfd-enabled': `Ninguna conexión BGP es compatible con BFD`,
    'transmit-interval': `Intervalo de transmisión`,
    'receive-interval': `Intervalo de recepción`,
    multiplier: `Multiplicador`,
    'bgp-connection': `Conexión BGP`,
    'disable-qinq-message': `Si deshabilita Q-in-Q se eliminará toda la configuración.`,
    confirm: `¿Seguro que quiere continuar?`,
    'delete-vlan-message': `Eliminará esta VLAN y toda su configuración. ¿Quiere continuar?`,
    'nat-is-enabled': `NAT activada`,
    'no-prefix-filter': `Sin filtro de prefijos`,
  },
  nutanix: {
    configuration: `Configuración de Nutanix`,
    'service-key': `Clave de servicio de Nutanix`,
    'service-key-notice': `Necesitarás una clave de servicio de Nutanix para conectarte a un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de Nutanix. Si no tienes una clave de servicio, visita {link} para obtener una.`,
    interface: `Interfaz de Nutanix`,
    'accept-speed-change': `Nota: Se ha actualizado la configuración de velocidad en su clave de servicio de Nutanix. Haga clic en Guardar para aceptar el nuevo límite de velocidad.`,
    nutanix: `Nutanix`,
  },
  oracle: {
    configuration: `Configuración de Oracle`,
    'virtual-circuit-id': `Id. de circuito virtual de Oracle`,
  },
  salesforce: {
    configuration: `Detalles de conexión para el servicio de Salesforce`,
    approvals: `Las aprobaciones de la conexión de Salesforce pueden tardar hasta dos días laborables en EE. UU.`,
    'provide-password': `Introduzca una contraseña de BGP. Si no la proporciona, generaremos una.`,
    prefixes: `Prefijos`,
    'prefixes-tooltip': `Prefijos para anunciarse en Salesforce, solo rangos de direcciones IPv4 asignadas a RIR.`,
    connection: `Conexión de Salesforce`,
    'permitted-prefixes': `Prefijos de BGP permitidos`,
  },
  'target-select': {
    'no-bio': `Biografía de empresa no disponible.`,
    'marketplace-html': `Obtén más información en su {link}.`,
    'hide-info': `Ocultar panel de información`,
    'show-info': `Mostrar información sobre las opciones`,
  },
  'target-service-key': {
    'destination-found': `Destino encontrado`,
    'key-name': `Nombre de clave`,
    'max-speed': `Velocidad máxima`,
  },
  events: {
    session: `Registro de eventos de sesión`,
    clear: `Borrar todos los eventos`,
    cleared: `Se ha borrado el registro de eventos de sesión.`,
  },
  graph: {
    private: `Los datos de esta empresa son privados`,
    'load-error': `Error al cargar los datos del gráfico`,
    'no-data': `No hay datos de gráficos disponibles`,
    'in-mbps': `Mbps entrantes`,
    'out-mbps': `Mbps salientes`,
    'billing-month': `Mes de Facturación:`,
    'a-end-not-owned': `No hay información de facturación, ya que el extremo A de esta conexión es propiedad de otra empresa.`,
    'load-failed': `Error al cargar los datos necesarios`,
    'no-billing-data': `No hay datos de facturación disponibles`,
    estimated: `Estimación de la próxima factura (impuestos no incluidos) para este servicio.`,
    calculated: `Factura (impuestos no incluidos) para este servicio.`,
    'too-many-changes': `Demasiados cambios de velocidad para representar en gráfico. Descargue el CSV para ver los datos.`,
    'configured-speed': `Velocidad Configurada`,
    'billing-details': `Detalles de Facturación`,
    'estimated-next': `Estimación de la próxima factura (impuestos no incluidos), suponiendo que no hay cambios de servicio.`,
    speed: `Velocidad (Mbps)`,
    download: `Descargar CSV`,
    'service-graph': `Gráfico del Servicio`,
    'date-range': `Intervalo de Fechas:`,
    'last-12-h': `Últimas 12 Horas`,
    'last-24-h': `Últimas 24 Horas`,
    'last-7-d': `Últimos 7 Días`,
    'last-4-w': `Últimas 4 Semanas`,
    metrics: `Métricas:`,
    source: `Origen:`,
    timezone: `Zona Horaria:`,
    'nothing-selected': `No hay nada seleccionado para representar en gráfico`,
    'date-time': `Fecha/Hora`,
    in: `Entrada`,
    out: `Salida`,
    'last-hour': `N/D | Última hora | Últimas {count} horas`,
    'last-week': `N/D | Última semana | Últimas {count} semanas`,
    'last-month': `N/D | Último mes | Últimos {count} meses`,
    // Tiempo universal coordinado
    utc: `UTC`,
    'export-csv': `Exportar CSV`,
    'downloading-csv': `Descargando CSV`,
  },
  'looking-glass': {
    'select-ix': `Seleccionar ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    title: `Looking Glass`,
    select: `Seleccionar Internet Exchange`,
    filter: `Escriba para filtrar por nombre o ASN`,
    'no-peers': `No hay pares disponibles`,
    'loading-peers': `Cargando lista de pares...`,
    rs1: `RS1`,
    'route-server-1': `Servidor de Rutas 1`,
    rs2: `RS2`,
    'route-server-2': `Servidor de Rutas 2`,
    'extended-details': `Detalles del par ampliados`,
    routing: `Información de Enrutamiento`,
    routes: `Rutas`,
    imported: `Importado`,
    best: `Mejor`,
    filtered: `Filtrado`,
    loading: `Cargando datos del gráfico...`,
    'loading-routes': `Cargando rutas...`,
    'route-load-failed': `Error al cargar los datos de las rutas`,
    'no-routes': `No hay rutas disponibles`,
    'local-pref': `Preferencias Locales`,
    'origin-asn': `ASN de Origen`,
    'asn-path': `Ruta de ASN`,
    since: `Desde`,
    'route-data-load-failed': `Error al cargar los datos de la ruta`,
    'detail-data-load-failed': `Error al cargar los datos de los detalles`,
    'ip-unavailable': `IPv4 e IPv6 no disponibles.`,
    'protocol-unavailable': `{protocol} no está disponible.`,
    'ip-available': `IPv4 e IPv6 están disponibles.`,
    asn: `ASN:`,
  },
  'image-upload': {
    select: `Seleccionar Imagen`,
    crop: `Recortar Imagen`,
    'drop-image': `Coloque un archivo de imagen aquí o haga clic para cargarlo`,
    clear: `Borrar Imagen`,
  },
  'no-services': {
    message: `Interconectividad rápida y flexible con tan solo unos clics.`,
    action: `Vamos a conectarte a la red`,
    instructions: `Para empezar, cree un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} con los botones de arriba o haga clic en 'Iniciar Recorrido' para acceder a una guía rápida sobre cómo realizar un pedido.`,
  },
  'google-login': {
    unavailable: `Inicio de sesión con Google no disponible`,
    loading: `Los servicios de Google aún se están cargando. Pruebe de nuevo en unos instantes.`,
    problem: `Se ha producido un problema al cargar los servicios de autorización de Google. Hemos notificado este problema a nuestros desarrolladores. Por favor ingrese por ahora nombre de usuario y contraseña para la autenticación.`,
    cancelled: `Inicio de sesión con Google cancelado`,
    'cancelled-message': `El intento de inicio de sesión con Google ha sido cancelado por usted.`,
    blocked: `Inicio de sesión con Google bloqueado`,
    'blocked-message': `El inicio de sesión con Google requiere una ventana emergente que su navegador ha bloqueado. Permita las ventanas emergentes en este sitio.`,
    'cookies-blocked-message': `Su navegador está configurado para bloquear las cookies de terceros. Para usar el inicio de sesión de Google, permita las cookies de terceros y, a continuación, vuelva a cargar la página.`,
    'browser-blocked-message': `No se pudo llevar a cabo el inicio de sesión con Google en su navegador. Estos son los detalles: {details}`,
    failed: `Error de inicio de sesión con Google`,
    'failed-message': `Error al iniciar sesión con Google.`,
    'token-received': `Token de Google recibido, intentando iniciar sesión...`,
    'no-details': `No hay detalles adicionales.`,
    'token-failed-message': `Se ha producido un error al verificar su token de inicio de sesión en Google. Intente iniciar sesión de nuevo o borre las cookies de su navegador si el problema persiste.`,
  },
  'whitelabel-dlr': {
    welcome: `Le damos la bienvenida a DLR`,
    'uptimes-blog': `Blog Uptimes`,
    twitter: `Síguenos en Twitter`,
    linkedin: `Síguenos en Linkedin`,
    conditions: `Consulta nuestros términos y condiciones de privacidad`,
    contact: `Contacto y asistencia`,
    careers: `Empleo - Estamos contratando`,
  },
  tooltips: {
    'route-prefix': `Una dirección de red IPv4 o IPv6 en notación CIDR.`,
    'route-next-hop': `La dirección IP del enrutador del siguiente salto.`,
    'required-item-start': `Los elementos marcados con un`,
    'required-item-end': `son campos obligatorios.`,
    'local-ip-address': `La dirección IPv4 o IPv6 de esta interfaz que se utiliza para la comunicación con el par BGP.`,
    'peer-ip-address': `La dirección IP del par BGP.`,
    'peer-asn': `El ASN del par BGP remoto. Se admiten números AS de 4 bytes con el formato asplain.`,
    'bgp-password': `Una clave compartida que se utiliza para autenticar el par BGP, hasta 25 caracteres.`,
    'bgp-description': `Una descripción para la conexión BGP, hasta 100 caracteres.`,
    'bgp-state': `De forma predeterminada, las conexiones BGP están habilitadas y tratarán activamente de conectarse al par. Opte por apagarlas para deshabilitar temporalmente la sesión BGP sin quitarla. Esto puede resultar útil para la resolución de problemas o para realizar pruebas en distintos escenarios.`,
    'med-inbound': `El MED se aplicará a todas las rutas recibidas en esta conexión BGP. Dejarlo en blanco para utilizar el valor recibido del par BGP. Se preferirá la ruta con el valor más bajo.`,
    'med-outbound': `El MED se aplicará a todas las rutas transmitidas en esta conexión BGP. Es posible que el sistema autónomo vecino prefiera el valor más bajo según su criterio.`,
    bfd: `La Detección de Reenvío Bidireccional permite la identificación rápida de fallos en esta conexión BGP. Cuando se interrumpe la conectividad, la sesión BGP se revoca sin esperar a que expire el temporizador de suspensión BGP. El uso de BFD puede mejorar el tiempo de conmutación por error cuando hay varias rutas disponibles. Nota: Esta configuración se utilizará para todas las conexiones BGP de esta VXC, donde el BFD está habilitado.`,
    'bfd-group': `Nota: Esta configuración se utilizará para todas las conexiones BGP de esta interfaz donde el BFD está habilitado.`,
    'bfd-transmit': `El tiempo mínimo entre envíos de paquetes BFD al vecino. El rango admitido es de 300 ms a 9000 ms.`,
    'bfd-receive': `El tiempo mínimo entre paquetes BFD que un vecino debe enviar. El rango admitido es de 300 ms a 9000 ms.`,
    'bfd-multiplier': `La sesión BGP no se revocará si no se reciben del vecino tantos paquetes BFD consecutivos.`,
    med: `El Discriminador de Salida Múltiple se puede utilizar para influir en la preferencia de ruta cuando hay disponibles varias rutas a la misma dirección. Se utiliza habitualmente para rutas principales y secundarias con preferencias específicas. Se admiten valores entre 0 y 4 294 967 295.`,
    'bgp-config-description': `Las rutas recibidas de este par BGP se anunciarán a otros pares BGP en este ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} <br /> Puede anunciar rutas a todos los pares de forma predeterminada y filtrar rutas a pares específicos. También puede filtrar rutas a todos los pares de forma predeterminada y anunciar rutas a pares específicos.`,
    'bgp-prefix-filter': `<p>Aplique un filtro de prefijos predefinidos a este par BGP para limitar el conjunto de rutas que se recibirán del par o que se anunciarán a este.</p>
      <ul>
      <li>El filtro sin prefijo permite todas las rutas. No se filtrarán rutas.</li>
      <li>Una lista de permitidos solo admite las rutas que coincidan con la lista de prefijos. Las demás rutas se filtrarán.</li>
      <li>Una lista de denegación permite todas las rutas, excepto las que coincidan con la lista de prefijos seleccionada.</li>
      </ul>
      <p>Para definir listas de filtros de prefijos, seleccione la pestaña "Prefix Filter Lists" (Listas de filtros de prefijos) en la página de configuración de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.</p>
      `,
    'bgp-prefix-editor': `
          <p>
          La lista de filtros de prefijos de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} es una lista con nombres asociados a direcciones de red IP. Cree una lista de filtros de prefijos para buscar coincidencias entre las rutas y filtrarlas en función del prefijo de la ruta y aplicar el filtro a 
          las rutas que se reciban de los pares BGP o que se anuncien a ellos en este ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.
          El ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} compara cada prefijo de ruta con las condiciones de la lista, una coincidencia cada vez, desde arriba hacia abajo para denegar o anunciar la ruta.
          </p>
          <ul>
          <li>La lista de filtros de prefijos puede aplicarse en varias ocasiones a pares BGP distintos.</li>
          <li>Se pueden configurar hasta 50 listas de filtros de prefijos en cada ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.</li>
          <li>Cada lista puede contener hasta 200 entradas.</li>
          </ul>
         `,
    'company-trading-name': `El nombre de tu cuenta, empresa u organización. Puedes cambiarlo más adelante.`,
    'as-path-prepend-tooltip': `El ASN local se antepondrá a la ruta de AS de los anuncios de ruta BGP salientes. Introduzca un número desde 0 hasta el máximo de 10 previsiones.`,
    'shutdown-state': `Actualiza el estado configurado a Shut Down (Apagado) para probar cómo se comportaría el servicio en la red de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} si el servicio estuviera caído.`,
    'move-connection-limits': `Solo se pueden seleccionar puertos del mismo tipo de producto y dentro de la misma área metropolitana.`,
    'move-connection-vnic': `La designación del extremo A o del extremo B de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} se determinó al crearse ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
  },
  time: {
    min: `min`,
    s: `s`,
    ms: `ms`,
    µs: `µs`,
  },
  'page-titles': {
    login: `Inicio de sesión`,
    'user-profile': `Perfil de usuario`,
    'user-notifications': `Notificaciones de usuario`,
    services: `Servicios`,
    dashboard: `Panel`,
    'create-port': `Crear ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    mve: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'create-connection': `Crear conexión`,
    'edit-port': `Editar ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'edit-connection': `Editar conexión`,
    'company-profile': `Perfil de la empresa`,
    'company-users': `Usuarios de la empresa`,
    'email-confirmation': `Confirmación de correo electrónico`,
    'marketplace-profile': `Perfil de ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    debug: `Información de depuración`,
    'looking-glass': `Looking Glass`,
    'mcr-looking-glass': `Looking Glass de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'event-log': `Session Event Log (registro de eventos de sesión)`,
    'ix-flows': `Flujos de ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'api-keys': `Claves de API`,
    'partner-admin': `Administrador de socios`,
    'managed-account': `Cuenta gestionada`,
    'billing-markets': `Mercados de Facturación`,
    onboard: `Incorporación`,
    signup: `Registro`,
    'signup-google': `Registro con Google`,
    admin: `Administrador`,
    'logging-in': `Iniciando sesión`,
    'company-security-settings': `Ajustes de seguridad de la empresa`,
    'company-support': `Datos de contacto del servicio de asistencia técnica`,
    'setup-mfa': `Configuración de la autenticación multifactor`,
    mfa: `Autenticación multifactor`,
    'create-mcr': `Crear ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'edit-mcr': `Editar ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'create-mve': `Crear ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'edit-mve': `Editar ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'move-connections': `Mover conexiones`,
    'aws-entitlements': `Derechos de AWS`,
    'aws-onboarding': `Incorporación a AWS`,
    'service-status': `Estado del servicio`,
  },
  partner: {
    'learn-more': `Obtenga más información sobre la administración de cuentas gestionadas en nuestra documentación de socios`,
    'view-documentation': `Ver documentación`,
    'managed-accounts': `Cuentas gestionadas`,
    'create-managed-account': `Crear cuenta gestionada`,
    'view-services': `Ver servicios`,
    edit: `Editar`,
    filter: `Filtrar cuentas`,
    'no-managed-accounts': `No hay ninguna cuenta gestionada. Haga clic en el botón 'Crear cuenta gestionada' para crear su primera cuenta.`,
    'invalid-company-uid': `No se ha encontrado ninguna cuenta gestionada que coincida con el UID.`,
    'no-match': `No hay ninguna cuenta que coincida.`,
    'account-name': `Nombre de la cuenta`,
    'company-uid': `UID de la empresa`,
    'managed-account-ref': `UID de la cuenta gestionada`,
    'managed-account-ref-placeholder': `Referencia única de la empresa`,
    actions: `Acciones`,
    'managed-account': `Cuenta gestionada`,
    'managed-learn-more': `Obtenga más información sobre la creación de cuentas gestionadas en nuestra documentación de socios`,
    save: `Guardar`,
    create: `Crear`,
    cancel: `Cancelar`,
    'account-created': `Cuenta gestionada creada.`,
    'account-saved': `Cuenta gestionada guardada.`,
    'create-account-error': `Error al crear cuenta gestionada.`,
    'update-account-error': `Error al actualizar cuenta gestionada.`,
    error: `Error`,
    'error-retrieving-accounts': `No se han podido recuperar las cuentas gestionadas.`,
    'managed-accounts-info': `Ver una lista de cuentas, establecer permisos y gestionar servicios.`,
    'edit-users': `Editar usuarios`,
    'edit-users-text': `Ver y gestionar una lista completa de usuarios y sus permisos para esta empresa.`,
    'new-managed': `Nueva cuenta gestionada`,
    'view-services-text': `Configurar, ver y solicitar nuevos servicios para esta empresa.`,
    'manage-services': `Gestionar servicios`,
    'diversity-unavailable-location': `La diversidad no está actualmente disponible para esta configuración. Ponte en contacto con tu socio de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} para analizar tus requisitos de diversidad.`,
  },
  'partner-admin': {
    'partner-account': `Cuenta de socio`,
    'managed-account': `Cuenta gestionada | Cuentas gestionadas`,
    'managed-accounts-uid': `UID de la cuenta gestionada`,
    'select-account': `Seleccionar cuenta`,
    'account-filter': `Filtrar cuentas por nombre o referencia`,
    partner: `Asociado`,
    'viewing-as': `Se muestra de la siguiente manera:`,
  },
  'partner-vantage': {
    subscription: `Suscripción`,
    'subscription-tooltip-ports': `La suscripción empezará una vez que el servicio entre en funcionamiento o 14 días después de la configuración del servicio.`,
    'subscription-tooltip': `La suscripción se iniciará en el momento de realizar el pedido`,
    'no-subscription': `No hay suscripción`,
    'change-subscription': `Cambiar la suscripción`,
    'subscription-title': `Establecer suscripción {productType}`,
    'subscription-choose': `Elegir suscripción {productType}`,
    'confirm-new-subscription': `Iniciar una nueva suscripción`,
    'subscription-months': `Sin suscripción | Suscripción: 1 mes | Suscripción: {count} meses`,
    'subscription-reterm': `El periodo de este pedido (el periodo mínimo) entrará en vigor inmediatamente y el descuento del periodo se aplicará en la factura del próximo mes natural. Una rescisión del contrato anterior al periodo ocasionará gastos según lo acordado por ambas partes.`,
    'cancel-subscription': `Haga clic en Cancelar si no desea realizar ninguna acción y pasar a no tener suscripción al precio de lista al final de la suscripción actual.`,
    'subscription-term': `Período de vigencia de la suscripción`,
  },
  outscale: {
    connection: `Conexión a Outscale`,
    'connection-details': `Detalles de conexión para el servicio de Outscale`,
    'connection-name': `Nombre de la conexión a Outscale`,
    'connection-name-tooltip': `Etiquetar el servicio en Outscale`,
    'account-id': `ID de la cuenta de Outscale`,
    'account-id-tooltip': `Introduzca el ID de la cuenta del propietario de Outscale`,
  },
  'company-security-settings': {
    'security-settings': `Ajustes de seguridad`,
    'mfa-long': `Autenticación multifactor`,
    'info-enforced': `Si se impone, se les pedirá a todos los usuarios de tu empresa que configuren la MFA al iniciar sesión.`,
    'info-optional': `Si es opcional, todos los usuarios de tu empresa seguirán teniendo la opción de activar la MFA en su perfil de usuario.`,
    'mfa-now-enforced': `La MFA se ha impuesto`,
    'mfa-now-optional': `La MFA es opcional`,
    'enforce-mfa-all-users': `Impón la autenticación multifactor (MFA) para todos los usuarios de tu empresa`,
    'error-updating-mfa': `Error al actualizar la configuración de MFA`,
    'enforce-mfa-tooltip-on': `Si se impone la MFA (ON), todos los usuarios deben utilizar la MFA al iniciar sesión en su cuenta. Si un usuario no tiene activada la MFA, se le pedirá que configure la MFA en su próximo inicio de sesión. Los usuarios no podrán acceder a tu empresa dentro de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} hasta que configuren la MFA.`,
    'enforce-mfa-tooltip-off': `Si la MFA es opcional (OFF), los usuarios deciden si quieren activar la MFA en su cuenta.`,
    'enforce-mfa-tooltip-title': `Imposición de la MFA`,
    mfa: `MFA`,
    on: `ON`,
    off: `OFF`,
    sso: `SSO`,
    'sso-long': `Inicio de sesión único`,
    'provider-identity': `Identidad del proveedor`,
    type: `Tipo`,
    'identity-provider-info': `Información sobre el proveedor de identidad`,
    'provider-name': `Nombre del proveedor`,
    domains: `Dominios`,
    metadata: `URL de metadatos`,
    'integration-details': `Detalles de la integración`,
    'audience-uri': `URI de audiencia`,
    'idp-response-url': `URL de respuesta IdP`,
    'sso-fail': `No se han podido recuperar las configuraciones de SSO.`,
    'sso-no-idps': `No hay proveedores de identidad configurados.`,
    'sso-attribute-mapping': `Asignación de atributos`,
    'sso-user-pool-attribute': `Atributo del conjunto de usuarios`,
    'sso-saml-attribute': `Atributo SAML`,
    'integration-details-desc': `Para conectar un proveedor SAML 2.0 a ${process.env.VUE_APP_EN_COMPANYINFO_NAME}, debes proporcionar el URI de audiencia y la URL de respuesta IdP a tu proveedor de identidades`,
    'details-for': `Detalles de {thing}`,
    'accepts-csv-values': `Acepta una lista de valores separados por comas`,
    'enforce-sso-all-users': `Impón el inicio de sesión único (SSO) para todos los usuarios de tu empresa`,
    'enforce-sso-tooltip-title': `Imposición del SSO`,
    'enforce-sso-tooltip-on': `Si la imposición del SSO está activada, todos los usuarios deben autenticarse utilizando el IdP configurado por defecto. Se bloqueará el acceso a cualquier usuario que no esté asociado al conjunto de usuarios de IdP. (Excepción: Los administradores de empresa podrán seguir iniciando sesión con otros métodos, también los administradores de empresa que no tengan un dominio asociado a SSO).`,
    'enforce-sso-tooltip-off': `Si la imposición del SSO está desactivada, los usuarios pueden utilizar diferentes métodos de autenticación, como nombre de usuario/contraseña, Google o SSO.`,
    'enforce-sso-info-enforced': `Una vez que la imposición de SSO esté activada, todos los usuarios deberán autenticarse utilizando el IdP configurado por defecto. Se bloqueará el acceso a cualquier usuario que no esté asociado al conjunto de usuarios de IdP. (Excepción: Los administradores de empresa podrán seguir iniciando sesión con otros métodos, también los administradores de empresa que no tengan un dominio asociado a SSO).`,
    'enforce-sso-info-optional': `Una vez que la imposición del SSO esté desactivada, los usuarios podrán utilizar diferentes métodos de autenticación, como nombre de usuario/contraseña, Google o SSO. Todos los usuarios activos de tu empresa podrán iniciar sesión de nuevo.`,
    'enforce-sso': `Imponer SSO`,
    'sso-connections': `Conexiones SSO`,
    'error-fetching-sso': `Error inesperado al buscar la configuración de SSO`,
    'error-updating-sso': `Error inesperado al actualizar la configuración de SSO`,
    'configure-saml-idp': `Configurar un proveedor de identidad (IdP) SAML 2.0`,
    'configure-saml-description': `Puedes configurar tu cuenta de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} para utilizar el inicio de sesión único (SSO). Cuando se habilita el SSO, los usuarios son redirigidos a un proveedor de identidad (IdP) externo para autenticarse, en lugar de pedírseles que introduzcan una contraseña cuando intentan acceder al ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}. El SSO simplifica la experiencia de inicio de sesión y elimina la necesidad de que los usuarios recuerden varias series de credenciales de inicio de sesión, lo que hace que sea más cómodo para los usuarios y más fácil de gestionar para los administradores.`,
    'add-saml-connection': `Añadir conexión SAML`,
    'register-saml-provider': `Registra ${process.env.VUE_APP_EN_COMPANYINFO_NAME} con tu proveedor SAML`,
    'register-saml-provider-description': `Configura su proveedor de identidad SAML con ${process.env.VUE_APP_EN_COMPANYINFO_NAME} proporcionando los siguientes detalles (incluida la url de metadatos utilizada por tu proveedor de identidad)`,
    'sso-update-success': `Ajustes de SSO actualizados para {name}`,
    'maximum-domains-error': `El número máximo de dominios es {max}`,
    'invalid-domains': `Dominios no válidos encontrados {domains}`,
    'duplicate-domains': `Dominios duplicados encontrados {domains}`,
    'idp-delete-confirmation-title': `Eliminar proveedor`,
    'idp-delete-confirmation-message': `<strong>Al continuar con esta acción, reconoces que:</strong><ul><li>Todos los usuarios asociados con esta conexión ya no podrán iniciar sesión utilizando su IdP externo.</li><li>Si la imposición de SSO está habilitada, se deshabilitará.</li><li>Todos los usuarios activos de tu empresa podrán iniciar sesión con otros métodos de autenticación.</li></ul>`,
    'sso-now-enforced': `El SSO está impuesto ahora`,
    'sso-now-optional': `El SSO es ahora opcional`,
    'new-user-non-sso-domain-warning': `Ten en cuenta que el inicio de sesión único (SSO) está impuesto actualmente para tu cuenta. Aunque puedes invitar a cualquier usuario a tu cuenta, los usuarios invitados no podrán utilizar sus credenciales si no están asociados a un dominio SSO. Como excepción, si el usuario es un administrador de empresa, podrá acceder a tu cuenta aunque no esté asociado a un dominio SSO.`,
    'sso-enforced-inactive-warning': `Ten en cuenta que el inicio de sesión único (SSO) está impuesto actualmente en tu cuenta. Si vuelves a habilitar una cuenta de usuario que no está asociada a un dominio SSO, el usuario seguirá bloqueado y no podrá iniciar sesión. Como excepción, si el usuario es un administrador de empresa, podrá acceder a su cuenta aunque no esté asociado a un dominio SSO.`,
    'sso-new-docs-message': `Antes de añadir SSO a esta cuenta, revisa la página {docs}. En ella se ofrece orientación sobre la configuración, así como información importante sobre el inicio de sesión único.`,
    'sso-features-and-instructions-for-use': `Características e instrucciones de uso del SSO`,
    'sso-add-settings-for-account': `Has añadido ajustes de SSO para esta cuenta.`,
    'sso-ensure-config-complete': `Para asegurarte de que tu configuración está completa, ahora tendrás que integrar tu IdP con el URI de audiencia y la URL de respuesta que se indican a continuación. Estos componentes son esenciales y necesarios para que la autenticación SAML funcione correctamente:`,
    'sso-info-accessed-again-at-any-time': `Puedes volver a acceder a esta información en cualquier momento haciendo clic en el botón Edit (Editar) situado junto a tu conexión SSO.`,
    'can-also-be-known-as': `También se puede conocer como:`,
    'entity-id': `ID de entidad`,
    audience: `Audiencia`,
    'assertion-consumer-service': `URL de servicio del consumidor de afirmaciones (ACS)`,
    'single-sign-on-url': `URL de inicio de sesión único`,
    'application-callback-url': `URL de devolución de llamada de aplicación`,
    'reply-url': `URL de respuesta`,
  },
  tscd: {
    'tech-supp-phone': `Teléfono de asistencia técnica`,
    'tech-supp-phone-num': `Número de teléfono del equipo de asistencia técnica`,
    'tech-supp-phone-placeholder': `Número de teléfono de los equipos de soporte internos del cliente`,
    'tech-supp-email': `Correo electrónico del equipo de asistencia técnica`,
    'tech-supp-email-placeholder': `Dirección de correo electrónico de los equipos de soporte internos del cliente`,
  },
  'company-support': {
    'tech-supp-phone': `Número de teléfono del equipo de asistencia técnica`,
    'tech-supp-phone-placeholder': `Número de teléfono de contacto de tu equipo de soporte interno`,
    'tech-supp-email': `Correo electrónico del equipo de asistencia técnica`,
    'tech-supp-email-placeholder': `Correo electrónico de contacto de tu equipo de soporte interno`,
    'admin-warning': `Los usuarios administradores de la empresa pueden introducir y editar los datos del equipo de asistencia técnica.`,
    'customer-supp-heading': `Contactos de clientes`,
    'megaport-supp-heading': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} contactos`,
    'account-manager': `Gerente de cuentas`,
    'customer-success-manager': `Gerente de éxito del cliente`,
    'click-chat-icon-msg': `Haga clic en el icono de chat para iniciar una conversación`,
  },
  images: {
    'datacentre-logo': `Logotipo del centro de datos`,
    'megaport-logo': `Logotipo de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'megaport-cloud-providers-logo': `Proveedores de nube de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'loading-logo': `Logotipo de carga de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'role-icon': `Icono de rol`,
    'docs-icon': `Icono de documentación`,
    'company-logo': `Logotipo de la empresa`,
    'resize-handle-icon': `Cambiar el tamaño del icono de gestión`,
    'marketplace-logo': `Logotipo de ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'marketplace-profile-logo': `Logotipo de perfil de ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'provider-logo': `Logotipo del proveedor`,
    'copy-icon': `Icono de copiar valor`,
    'partner-vantage-logo': `Logotipo de socio Vantage`,
    'profile-logo': `Logotipo del perfil`,
    'sx-logo': `Logotipo de Service Exchange`,
  },
  ibm: {
    connection: `Conexión IBM`,
    'connection-details': `Detalles de conexión para servicio IBM Cloud`,
    'account-id': `ID de cuenta de IBM`,
    'account-id-tooltip': `Obtenlo de la configuración de tu cuenta de IBM en el portal de IBM`,
    'customer-asn-tooltip': `ASN de BGP público o privado`,
    'account-id-validation': `Número hexadecimal de 32 dígitos`,
    'modal-title': `Conexión al portal de IBM`,
    'order-request-sent': `La solicitud se ha enviado a tu cuenta del portal de IBM.`,
    'order-request-reminder': `Esto es un recordatorio para aceptar/rechazar la solicitud de conexión a través del portal de IBM.`,
    'visit-portal': `Visita el portal de IBM`,
    'edit-modal-updated-api': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} ha actualizado las integraciones API con IBM.`,
    'edit-modal-recommendation': `Recomendamos a los usuarios que vuelvan a solicitar un ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} de IBM Cloud para acceder a las funciones actualizadas y finalizar la conexión existente.`,
    'edit-modal-docs': `Para más información, consulta el {link}.`,
  },
  'mexico-billing-market': {
    'company-type-mexico-01-legal-entity': `01: Entidad legal`,
    'company-type-mexico-02-legal-person': `02: persona jurídica`,
    'company-type-mexico-03-foreign-company': `03: empresa extranjera`,
    'tax-regime-code-mexico-601': `601 - General de personas morales legales`,
    'tax-regime-code-mexico-603': `603 - Entidades legales sin ánimo de lucro`,
    'tax-regime-code-mexico-605': `605 - Sueldos y salarios e ingresos asimilados a sueldos`,
    'tax-regime-code-mexico-606': `606 - Arrendamiento`,
    'tax-regime-code-mexico-607': `607 - Régimen de enajenación o adquisición de bienes`,
    'tax-regime-code-mexico-608': `608 - Otros ingresos`,
    'tax-regime-code-mexico-610': `610 - Residentes en el Extranjero sin establecimiento permanente en México`,
    'tax-regime-code-mexico-611': `611 - Ingresos por dividendos (socios y accionistas)`,
    'tax-regime-code-mexico-612': `612 - Personas con actividades empresariales y profesionales`,
    'tax-regime-code-mexico-614': `614 - Ingresos por intereses`,
    'tax-regime-code-mexico-615': `615 - Régimen de ingresos por obtención de premios`,
    'tax-regime-code-mexico-616': `616 - Sin obligaciones fiscales`,
    'tax-regime-code-mexico-620': `620 - Sociedades cooperativas de producción que optan por diferir sus ingresos`,
    'tax-regime-code-mexico-621': `621 - Incorporación fiscal`,
    'tax-regime-code-mexico-622': `622 - Actividades agrícolas, ganaderas, forestales y pesqueras`,
    'tax-regime-code-mexico-623': `623 - Opcional para grupos de empresas`,
    'tax-regime-code-mexico-624': `624 - Coordinado`,
    'tax-regime-code-mexico-625': `625 - Régimen de actividades empresariales con ingresos a través de plataformas tecnológicas`,
    'tax-regime-code-mexico-626': `626 - Régimen fiduciario simplificado`,
    'sat-purpose-mexico-cn01': `CN01 - Nómina`,
    'sat-purpose-mexico-cp01': `CP01 - Pagos`,
    'sat-purpose-mexico-d01': `D01 - Gastos médicos, dentales y hospitalarios`,
    'sat-purpose-mexico-d02': `D02 - Gastos médicos por incapacidad o invalidez`,
    'sat-purpose-mexico-d03': `D03 - Gastos funerarios`,
    'sat-purpose-mexico-d04': `D04 - Donaciones`,
    'sat-purpose-mexico-d05': `D05 - Intereses reales pagados efectivamente por préstamos hipotecarios (vivienda)`,
    'sat-purpose-mexico-d06': `D06 - Contribuciones voluntarias al SAR`,
    'sat-purpose-mexico-d07': `D07 - Primas de seguro de gastos médicos`,
    'sat-purpose-mexico-d08': `D08 - Gastos de transporte escolar obligatorio`,
    'sat-purpose-mexico-d09': `D09 - Depósitos en cuentas de ahorro, primas basadas en planes de pensiones`,
    'sat-purpose-mexico-d10': `D10 - Pagos por servicios educativos (tasas escolares)`,
    'sat-purpose-mexico-g01': `G01 - Adquisición de mercancías`,
    'sat-purpose-mexico-g02': `G02 - Devoluciones, descuentos o bonificaciones`,
    'sat-purpose-mexico-g03': `G03 - Gastos en general`,
    'sat-purpose-mexico-i01': `I01 - Edificios`,
    'sat-purpose-mexico-i02': `I02 - Mobiliario y equipamiento de oficina para inversiones`,
    'sat-purpose-mexico-i03': `I03 - Equipos de transporte`,
    'sat-purpose-mexico-i04': `I04 - Equipos y accesorios informáticos`,
    'sat-purpose-mexico-i05': `I05 - Matrices, moldes y utillaje`,
    'sat-purpose-mexico-i06': `I06 - Comunicaciones telefónicas`,
    'sat-purpose-mexico-i07': `I07 - Comunicaciones por satélite`,
    'sat-purpose-mexico-i08': `I08 - Otras máquinas y equipos`,
    'sat-purpose-mexico-s01': `S01 - Sin efectos fiscales (sin obligaciones fiscales)`,
  },
  'survey-banner': {
    'customer-survey-link': `Nos encantaría conocer tu opinión. Haz clic aquí para rellenar nuestra encuesta a clientes.`,
  },
  'mp-one': {
    'platform-orchestrate-network-services': `UNA plataforma para organizar, conectar en red y habilitar servicios en la nube.`,
    'try-megaport-one': `Prueba Megaport ONE`,
    'learn-more': `Más información`,
    'click-here-to-try-mp-one': `Haz clic aquí para probar Megaport ONE Beta. Una plataforma para organizar, conectar en red y lanzar soluciones en la nube.`,
    'terms-and-conditions': `Estás entrando en el portal MegaportONE.nnAl entrar en el Portal MegaportONE, aceptas los siguientes términos y condiciones.nnLa información y los servicios proporcionados en el Portal MegaportONE están actualmente disponibles para los usuarios de forma gratuita.nnMegaport se reserva el derecho a introducir tasas o cargos por determinadas funciones o servicios en el futuro.nnCualquier tasa o cargo se comunicará claramente a los usuarios con antelación.nnTenga en cuenta que el portal MegaportONE se encuentra actualmente en fase de pruebas beta y es posible que no todas las funciones o servicios estén disponibles en todo momento.nnMegaport se reserva el derecho a modificar, suspender o interrumpir cualquier parte del sitio web o su funcionalidad sin previo aviso.nnNo garantizamos un acceso ininterrumpido o libre de errores al portal MegaportONE.`,
  },
  'view-mode': {
    tooltip: `Visualiza tus servicios en vista tabular o de mapa`,
    tabular: `Tabular`,
    map: `Mapa`,
  },
  map: {
    'all-locations': `Mostrar todas las ubicaciones`,
    'occupied-locations': `Mostrar solo las ubicaciones donde tengo servicios`,
    'filtered-locations': `Filtrar la visualización de ubicaciones donde no tengo servicios`,
    'thing-available': `{thing} Disponible`,
    'dc-provider': `Proveedor de centro de datos`,
    'locations-filtered': `Las ubicaciones mostradas están filtradas`,
    'zoom-in': `Ampliar`,
    'zoom-in-lots': `Ampliar lotes`,
    'zoom-out': `Alejar`,
    'zoom-out-lots': `Alejar lotes`,
    settings: `Ajustes`,
    key: `Clave del mapa`,
    help: `Ayuda e instrucciones`,
    'help-action': `Acción`,
    'help-description': `Descripción`,
    'help-items': {
      'zoom-in-title': `Ampliar`,
      'zoom-in-action-1': `Doble clic`,
      'zoom-in-action-2': `Tecla =`,
      'zoom-in-action-3': `Tecla +`,
      'zoom-in-action-4': `Mantén pulsada la tecla ⌘/CTRL y la rueda del ratón`,
      'zoom-in-action-5': `Mantén pulsada la tecla Alt/Opción y arrastra hacia abajo`,
      'zoom-in-action-6': `Pellizcar`,
      'double-zoom-in-title': `Ampliar a doble velocidad`,
      'double-zoom-in-action-1': `Tecla Mayús =`,
      'double-zoom-in-action-2': `Tecla Mayús +`,
      'zoom-out-title': `Alejar`,
      'zoom-out-action-1': `Mayús doble clic`,
      'zoom-out-action-2': `Tecla -`,
      'zoom-out-action-3': `Mantén pulsada la tecla ⌘/CTRL y la rueda del ratón`,
      'zoom-out-action-4': `Mantén pulsada la tecla Alt/Opción y arrastra hacia arriba`,
      'zoom-out-action-5': `Pellizcar`,
      'double-zoom-out-title': `Alejar a doble velocidad`,
      'double-zoom-out-action-1': `Tecla Mayús -`,
      'area-zoom-in-title': `Ampliar un área`,
      'area-zoom-in-action-1': `Desplazar el área de arrastre`,
      'pan-title': `Recorrer el mapa`,
      'pan-action-1': `Arrastras`,
      'pan-action-2': `Teclas de flecha`,
      'orientation-title': `Cambiar la orientación del mapa`,
      'orientation-action-1': `Arrastrar con dos dedos`,
      'orientation-action-2': `Mayús ←, → para rotar, Mayús ↑ para reiniciar`,
    },
    'ix-count': `0 IXs | 1 IX | {count} IXs`,
    'location-cluster': `Este grupo representa {locationString}.`,
    'location-ix-cluster': `Este grupo representa {locationString} y {ixString}.`,
    'click-expand': `Haz clic para ampliar.`,
    'click-empty-location': `Haz clic para ver los detalles y añadir servicios`,
    'supported-products': `Esta ubicación admite los siguientes productos:`,
    'products-location': `Tienes los siguientes productos en esta ubicación:`,
    'click-details': `Haz clic para ver los detalles`,
    'line-connections-count': `- | Esta línea representa 1 conexión: | Esta línea representa {count} conexiones`,
    'location-deployment': `Esta ubicación pronto estará activa. Ya puedes reservar tus servicios.`,
    'view-in-map': `Ver este servicio en un mapa`,
    'navigate-to': `Navegar a {serviceName}`,
    'search-tips': `Puedes buscar:<ul><li>Cualquier tipo de producto, por ejemplo, VXC</li><li>Cualquier nombre de servicio</li><li>ID de producto</li><li>Ubicaciones habilitadas para Megaport (si no lo tienes desactivado en tus ajustes del mapa)</li><li>O cualquier otro lugar del mundo</li></ul>`,
    'key-items': {
      'occupied-location': `Una única ubicación donde dispones de servicios. Puedes hacer clic en estas chinchetas para ver detalles de los servicios que tienes en esa ubicación, y para solicitar servicios adicionales allí.`,
      spiderified: `Cuando hagas clic en una ubicación en la que tengas servicios, se ampliará para mostrar todos los ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}, ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} y ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} que tengas en esa ubicación, así como la propia ubicación. Los iconos están codificados por colores para indicar si el servicio está activo o inactivo. Los puertos de terceros a los que estás conectado se representan con iconos negros. Haz clic en un icono para ver los detalles.`,
      'occupied-location-cluster': `Un conjunto de ubicaciones donde dispones de servicios. El anillo que lo rodea está coloreado para indicar qué proporción de las conexiones que comienzan en el grupo están en estado activo o inactivo. Haz clic para ampliar.`,
      'unoccupied-location': `Una única ubicación en la que hay servicios de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} disponibles, pero actualmente no tienes ningún servicio. Haz clic para ver los detalles de la ubicación y solicitar servicios allí. Puedes desactivar la visualización de ubicaciones desocupadas en los ajustes del mapa.`,
      'unoccupied-location-cluster': `Un grupo de ubicaciones donde no tienes servicios. Haz clic para ampliar. Puedes desactivar la visualización de ubicaciones desocupadas en los ajustes del mapa.`,
      'ix-pin': `Una chincheta virtual que representa un ${process.env.VUE_APP_EN_PRODUCTNAME_IX} en esa área metropolitana.`,
      'mid-connection': `Un marcador que representa las conexiones entre dos ubicaciones o grupos de ubicaciones. Una sola línea puede representar varias conexiones. El marcador es verde si todas las conexiones están activas, rojo si todas las conexiones están desactivadas, y mitad y mitad si solo algunas están activas. Haz clic en el marcador para ver todas las conexiones representadas.`,
      connections: `Las líneas entre ubicaciones representan conexiones. El color de la línea indica si la conexión está activa o inactiva. En este caso hay tres conexiones activas y una inactiva.`,
    },
    'partner-count': `- | Tienes 1 conexión ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de terceros en esta ubicación. | Tienes {count} conexiones ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de terceros en esta ubicación.`,
    'ix-incoming-count': `- | Tienes 1 conexión ${process.env.VUE_APP_EN_PRODUCTNAME_IX} entrante. | Tienes {count} conexiones ${process.env.VUE_APP_EN_PRODUCTNAME_IX} entrantes.`,
    fullscreen: `Modo de pantalla completa`,
    big: `Pantalla grande (widgets de servicio no visibles)`,
    small: `Pantalla pequeña (widgets de servicio visibles)`,
    'fullscreen-error-title': `No se ha podido entrar en el modo de pantalla completa`,
    'fullscreen-error-message': `Error al intentar habilitar el modo de pantalla completa: {errorMessage} ({errorName})`,
    'not-ready-title': `Mapa no listo`,
    'not-ready-message': `Algunos elementos del mapa aún se están cargando. Vuelve a intentarlo en unos segundos`,
    'initialization-error': `No se puede mostrar el mapa de servicios: {message}`,
    globe: `Ver como globo`,
    mercator: `Vista como proyección plana (Mercator)`,
  },
  'cancel-reason': {
    label: `Motivo de la cancelación`,
    'moved-dc': `Traslado fuera del centro de datos`,
    'order-error': `Pedido erróneo o reordenación`,
    'switch-provider': `Cambiar a proveedor alternativo`,
    'proof-of-concept': `Prueba de concepto / ensayo`,
    other: `Motivo no indicado`,
    'project-ended': `Proyecto finalizado`,
    'cloud-native-move': `Pasar a una solución nativa en la nube`,
    pricing: `Precios`,
    'product-performance': `Rendimiento del producto`,
    'feedback-prompt': `Ayúdenos a mejorar proporcionándonos más detalles sobre el motivo de su cancelación`,
  },
  entitlements: {
    description: `Esta página muestra los derechos de AWS que se han adquirido a través del mercado de AWS. Puede filtrar esta lista para mostrar u ocultar los derechos que hayan vencido. Tenga en cuenta que solo los derechos que están {inUse} tienen un servicio asociado a ellos.`,
    'in-use': `En uso`,
    loading: `Cargando derechos`,
    'no-match': `No hay derechos coincidentes`,
    'service-id': `ID del servicio`,
    'agreement-id': `ID del acuerdo de AWS`,
    'vendor-agreement-id': `ID del acuerdo del proveedor`,
    'vendor-account-id': `ID de la cuenta del proveedor`,
    sku: `SKU`,
    'start-date': `Fecha de inicio del contrato`,
    'end-date': `Fecha de finalización del contrato`,
    status: `Estado`,
    'product-type': `Tipo de producto`,
    failed: `No se pudieron cargar los derechos`,
    available: `Disponible`,
    expired: `Vencido`,
  },
  'service-status': {
    maintenance: `Mantenimiento`,
    outages: `Interrupciones`,
    timezone: `Zona horaria`,
    sort: `Ordenar`,
    filter: `Filtrar`,
    'search-event-placeholder': `Introduzca texto para filtrar eventos`,
    status: `Estado`,
    'start-date': `Fecha de inicio`,
    ascending: `Ascendente`,
    descending: `Descendente`,
    ongoing: `En curso`,
    scheduled: `Programado`,
    completed: `Completado`,
    cancelled: `Cancelado`,
    resolved: `Resuelto`,
    'in-last-7-days': `en los últimos 7 días`,
    'no-data-text': `No se encontraron resultados que coincidan con los criterios seleccionados. Pruebe a ajustar los criterios de filtrado.`,
    'start-utc': `Inicio (UTC):`,
    'end-utc': `Fin (UTC):`,
    duration: `Duración:`,
    'resolution-details': `Detalles de la solución`,
    'root-cause': `Causa principal:`,
    resolution: `Solución:`,
    'mitigation-actions': `Acciones de mitigación:`,
    'update-notifications': `Notificaciones de actualización`,
    'impacted-services': `Servicios afectados`,
    'hide-updates': `Ocultar actualizaciones anteriores`,
    'show-updates': `Mostrar actualizaciones anteriores`,
    more: `Ver más`,
    less: `Ver menos`,
    'incident-notification': `Notificación de incidentes`,
    'status-update': `Siguiente - Notificación de incidentes`,
    'resolved-status': `Resuelto - Notificación de incidentes`,
    'latest-status': `Más reciente - Notificación de incidentes`,
    'next-update-etr': `Próxima actualización/ETR:`,
    'notification-description': `Le informamos de que una interrupción está afectando actualmente a su servicio. Estamos trabajando activamente para restablecer los servicios como nuestra máxima prioridad.`,
    'next-update-text': `Le informaremos tan pronto como vuelvan a estar disponibles. Le agradecemos su paciencia mientras trabajamos para resolver esta interrupción.`,
    'maintenance-outages': `Mantenimiento e interrupciones`,
    'refresh-error': `No se pueden actualizar los eventos`,
    'type-label': `Tipo`,
    'name-label': `Nombre`,
    'id-label': `ID`,
    'actions-label': `Acciones`,
    details: `Detalles`,
    map: `Mapa`,
  },
}
