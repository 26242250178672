import {
  MEGAPORT_COMPANY_ID,
  MEGAPORT_LAB_COMPANY_ID,
  ACCOUNT_TYPE_DIRECT_CUSTOMER,
  ACCOUNT_TYPE_PARTNER,
  ACCOUNT_TYPE_MANAGED_ACCOUNT,
  ACCOUNT_TYPE_ADMIN,
  ACCOUNT_TYPE_DISTRIBUTOR,
} from '@/Globals.js'
import { isFeatureEnabled, updateRuntimeFeatureFlag } from '@/providers/runtime.js'
import sdk from '@megaport/api-sdk'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'
import integrations from '@/third-party-integrations/integrations'

// Initial state
const coreState = {
  loggingIn: false,
  loggedIn: null,
  googleSignInReady: false,
  freshLogin: false,
  redirect: '',
  // this is the cached data from the login payloads, before it succeeds
  userLoginPayload: {},
  // user data once logged in is stored here
  data: {},
}

const coreGetters = {
  // For DLR support
  sessionToken: state => state.data.token,
  accessToken: state => state.data.accessToken || state.data?.oAuthToken?.accessToken,
  accountType: state => state.data.accountType,

  homePage: (state, getters) => {
    if (getters.requiresMfaSetup) return '/setup-mfa'
    if (state.redirect) return state.redirect
    if (getters.isDistributorAccount) return '/company/markets'
    return resolveServicesPage()
  },

  /**
   * Determine whether the user has the required permissions.
   * @param {string} reqPerm: permission name string
   */
  hasAuth: state => reqPerm => {
    if (!reqPerm || !state.data.authorities || !state.data.companyUid) {
      return false
    }

    const auths = Object.keys(state.data.authorities[state.data.companyUid]).map(key => {
      if (state.data.authorities[state.data.companyUid][key]) return key
    })

    return auths.includes(reqPerm)
  },
  // loggedIn is set to true once the login action in this module is called,
  // but after that the initiating code still loads required data to show the home page, while loggingIn is set to true
  // so that's why we have this getter, as we need to wait until the auth endpoint is called and all required data is fetched.
  // (could be cleaned up a bit, but not while I'm working on something entirely separate!)
  isLoggedIn: state => {
    return state.loggedIn && !state.loggingIn
  },
  /**
   * Whether the user has logged in using loginAs
   */
  isLoggedInAs: state => {
    return state.data.supportOverride
  },
  /**
   * Determine whether the user has the requested feature flag
   * @param {string} reqFlag: the name of the flag to look for
   *
   * Note: we treat all feature flags as case insensitive
   */
  hasFeatureFlag: state => reqFlag => {
    if (!reqFlag || !state.data.featureFlags || !state.data.featureFlags.length) {
      return false
    }
    const index = state.data.featureFlags.findIndex(flag => {
      return flag.toLowerCase() === reqFlag.toLowerCase()
    })
    return index !== -1
  },
  /**
   * People classify as a new user if they have just registered, or if they
   * don't have a company associated with them.
   */
  isNewUser: (_state, _getters, _rootState, rootGetters) => {
    return window.newRegister || !rootGetters['Company/hasCompany']
  },
  /**
   * People classify as a first time user if they have just registered and
   * they don't have a company associated with them.
   */
  isFirstTimeUser: (_state, _getters, _rootState, rootGetters) => {
    const hasCompany = rootGetters['Company/hasCompany']
    return window.newRegister && !hasCompany
  },

  hasMfaEnabled: state => {
    return state.data.requireTotp
  },

  requiresMfaSetup: (state, getters) => {
    return (state.data.mfaReset || !getters.hasMfaEnabled && state.data.companyEnforcedMFA)
  },

  /**
   * Get the personUid of the logged in user
   * @param {object} state Store state
   * @returns {string} The personUid of the logged in user
   */
  personUid: state => state.data.personUid,

  /*******************************************************************************************************/
  /*                                          Account Types                                              */
  /* For almost all circumstances, this information is determined from two attributes in the Auth data:  */
  /* accountType and rootAccountType. There is one exception to this and that is when a Partner is       */
  /* switched into a Managed account context. This operation does not update the Auth data, but does     */
  /* update the company data, which also contains the accountType. We therefore look to the Company      */
  /* when determining whether they are operating as a managed account.                                   */
  /*******************************************************************************************************/


  // Users in MEGAPORT or MEGAPORT LAB company are considered admin.
  isAdminCompanyAccount: state => state.data.companyId === MEGAPORT_COMPANY_ID || state.data.companyId === MEGAPORT_LAB_COMPANY_ID,
  // Either a user who registered or someone who was registered within their company
  isOrdinaryAccount: state => state.data.accountType === ACCOUNT_TYPE_ADMIN || state.data.accountType === ACCOUNT_TYPE_DIRECT_CUSTOMER,
  // User is a Direct Customer
  isDirectAccount: state => state.data.accountType === ACCOUNT_TYPE_DIRECT_CUSTOMER,
  // Any sort of managed account - they have someone above them in the hierarchy but they have nobody under them.
  isManagedAccount: (_state, _getters, rootState) => rootState.Company.data.accountType === ACCOUNT_TYPE_MANAGED_ACCOUNT,
  // A managed account that is managed by a partner
  isPartnerManagedAccount: (state, _getters, rootState) => rootState.Company.data.accountType === ACCOUNT_TYPE_MANAGED_ACCOUNT && state.data.rootAccountType === ACCOUNT_TYPE_PARTNER,
  // A managed account that is managed by a distributor directly
  isDistributorManagedAccount: (state, _getters, rootState) => rootState.Company.data.accountType === ACCOUNT_TYPE_MANAGED_ACCOUNT && state.data.rootAccountType === ACCOUNT_TYPE_DISTRIBUTOR,
  // Any sort of partner account
  isPartnerAccount: state => state.data.accountType === ACCOUNT_TYPE_PARTNER,
  // A partner account not managed by a distributor
  isDirectPartnerAccount: state => state.data.accountType === ACCOUNT_TYPE_PARTNER && state.data.rootAccountType === ACCOUNT_TYPE_PARTNER,
  // A partner account that is managed by a distributor
  isDistributorPartnerAccount: state => state.data.accountType === ACCOUNT_TYPE_PARTNER && state.data.rootAccountType === ACCOUNT_TYPE_DISTRIBUTOR,
  // A distributor
  isDistributorAccount: state => state.data.accountType === ACCOUNT_TYPE_DISTRIBUTOR,
  // Check if user support is managed by the partner or by Megaport
  isPartnerSupported: (_state, getters) => getters.isManagedAccount && isFeatureEnabled('PARTNER_SUPPORTED'),
  hasCompany: state => !state.data.companyName?.includes('no name recorded'),
  isMegaportSupported: () => isFeatureEnabled('MEGAPORT_SUPPORTED'),
  hasOrderedServices: state => state.data.companyHasOrderedServices ?? false,
  isPartnerVantage: (_state, getters) => (isFeatureEnabled('VANTAGE_PARTNER') || isFeatureEnabled('VANTAGE_MANAGED_ACCOUNT'))
    && (getters.isPartnerAccount || getters.isManagedAccount),
}

// Helper method
const processSuccessfulLogin = async (context, data) => {
  // Tell Sentry (or whoever else needs to know) that the user is logged in.
  context.dispatch(
    'Users/updateLoggingServices',
    { ...data, status: 'login' },
    { root: true }
  )

  // if any cached login data, clear
  context.commit('setUserLoginPayload', {})
  // Save the user data locally
  context.commit('login', data)
  // Record that we are logged in
  context.commit('setLoggedIn', true)

  // Tell Sentry (or whoever else needs to know) that the user is logged in.
  context.dispatch(
    'Users/updateLoggingServices',
      { ...data, status: 'login' },
      { root: true }
  )

  // Set accessToken and idToken cookie for MP1
  // NOTE: DLR logins don't return oAuthToken currently, so need to wrap in this if block.
  if (data.oAuthToken) {
    const eightHours = (60 * 60) * 8
    document.cookie = `accessToken=${data.oAuthToken.accessToken}; domain=megaport.com; max-age=${eightHours}; path=/;`
    document.cookie = `idToken=${data.oAuthToken.idToken}; domain=megaport.com; max-age=${eightHours}; path=/;`
  }

  // Update the runtime configs from the response
  const [first, second] = ['companyConfiguration', 'consolidatedSettings']
  const consolidatedSettings = data?.[first]?.[second]
  if (consolidatedSettings) {
    consolidatedSettings.forEach(({ key, value }) => updateRuntimeFeatureFlag([first, second, key], value))
  }

  // If requiresMfaSetup is true, dont call onLogin actions, as we're technically logged in, but all endpoints
  // other than the setup MFA endpoints will return 401, this is because MFA is enforced and required to access the portal.
  // The user will get redirected to the /setup-mfa screen, then we manually call the onLogin root action
  // again after they've successfully enabled MFA.
  if (!context.getters.requiresMfaSetup) {
    // Get all the other actions that run on login to run
    await context.dispatch('onLogin', null, {
      root: true,
    })
  }
}

// Asynchronous actions
const actions = {
  /**
   * This is the single place login to the app is handled.
   *
   * @param {object} context (store context)
   * @param {object} payload - data used to authenticate (see megaport.js and api)
   * @param {Boolean} setLoginState - whether to set the login state locally
   */
  async login(context, { payload, setLoginState = true }) {
    context.commit('setLoggedIn', null)

    try {
      const credentials = await sdk.instance.auth(payload)
      // If we get to here, the login was successful
      // Store whether it was a fresh login rather than a reload
      context.commit('setFreshLogin', !(payload.session || payload.accessToken))

      if (setLoginState) {
        await processSuccessfulLogin(context, credentials)
      } else {
        context.commit('setUserLoginPayload', payload)
      }
      return credentials

    } catch (error) {
      context.commit('setUserLoginPayload', payload)
      context.commit('setLoggedIn', false)
      throw error
    }
  },
  /**
   * This is the single place to log out. It cleans up the megaport.js layer
   * and calls the parent action which causes all the individual modules to
   * clean up their state. It then redirects to the login screen.
   *
   * @param {object} context (store context)
   */
  async logout(context) {
    try {
      integrations.salesforceChat.logout()
      // Leaving this commented out, details in ENG-20339
      // TLDR: this was creating problems in SSO, no workarounds, not needed as only cleared localStorage
      // await integrations.awsAmplify.logout()
      await sdk.instance.logout()

      context.dispatch(
        'Users/updateLoggingServices',
          { status: 'logout' },
          { root: true }
      )
    } finally {
      context.dispatch('onLogout', null, {
        root: true,
      })
    }
  },
}


// Direct mutations
const mutations = {
  /**
   * PRIVATE method to set the state based on the object passed in.
   *
   * @param {*} state - module state
   * @param {*} payload - the data to save
   */
  login(state, payload) {
    state.data = {
      ...payload,
    }

    // source field will be IDP if logged in via SSO or Google
    if (payload.source === 'IDP') {
      localStorage.setItem('_isSSOAccount', JSON.stringify(true))
    }

    state.loggedIn = true

    // access token exists assume valid login.
    if (process.env.VUE_APP_BYPASS_AUTHENTICATION === 'false') {
      const accessToken = payload?.oAuthToken?.accessToken
      if (accessToken) {
        state.data.accessToken = accessToken
        localStorage.setItem('_accessToken', accessToken)
      }
    } else {
      state.data.token = payload?.token
    }
  },

  /**
   * PRIVATE method to update the state and clean up from a logout operation
   * @param {*} state - module state
   */
  logout(state) {
    // Wipe out our auth state and record that we are not logged in
    state.data = {}
    state.loggedIn = false
    state.loggingIn = false
    state.redirect = ''

    // expire cookies setup for jumping between portals
    document.cookie = 'mp1AccessToken=; domain=megaport.com; max-age=-1; path=/;'
    document.cookie = 'accessToken=; domain=megaport.com; max-age=-1; path=/;'
    document.cookie = 'idToken=; domain=megaport.com; max-age=-1; path=/;'

    // Read all the keys we want to persist after logout into an object
    const persistentKeys = [
      '_hideMpOneOffer',
      '_hideSurveyBanner',
      '_username',
      '_mpSearch_',
      '_mpCart_',
      'diversity_order',
      '_mpMarketplaceBanner_',
    ]
    for (const key of Object.getOwnPropertyNames(localStorage)) {
      if (!persistentKeys.find(pKey => key.startsWith(pKey))) {
        localStorage.removeItem(key)
      }
    }
  },
  /**
   * PRIVATE method to update the logged in status (used to both determine
   * whether the user is logged in, or if null, there is a login operation
   * in progress).
   *
   * @param {*} state
   * @param {boolean} tfn
   */
  setLoggedIn(state, tfn) {
    state.loggedIn = tfn
  },

  setLoggingIn(state, tfn) {
    state.loggingIn = tfn
  },

  setGoogleSignInReady(state, tf) {
    state.googleSignInReady = tf
  },

  setUserLoginPayload(state, payload) {
    state.userLoginPayload = payload
  },

  setFreshLogin(state, tf) {
    state.freshLogin = tf
  },

  /**
   * PRIVATE method for assigning an account type (used for testing purposes)
   * @param {*} state
   * @param {string} accountType
   */
  setAccountType(state, accountType) {
    state.data = {
      accountType: accountType,
    }
  },

  setMfaEnabled(state) {
    state.data.requireTotp = true
  },

  setMfaReset(state) {
    state.data.mfaReset = false
  },

  setHasOrderedServicesTrue(state) {
    state.data.companyHasOrderedServices = true
  },

  setRedirect(state, redirect) {
    state.redirect = redirect
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters: coreGetters,
  actions,
  mutations,
}
