export default {
  productNames: {
    port: `${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    ports: `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    mcr: `${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    mcrs: `${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}`,
    mcrLong: `${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG}`,
    mve: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    mveLong: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE_LONG}`,
    vxc: `${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    vxcs: `${process.env.VUE_APP_EN_PRODUCTNAME_VXCS}`,
    ix: `${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    ixs: `${process.env.VUE_APP_EN_PRODUCTNAME_IXS}`,
    megaIx: `${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX}`,
    lag: `${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    lags: `${process.env.VUE_APP_EN_PRODUCTNAME_LAGS}`,
    lagLong: `${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG}`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    marketplaceShort: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    transitVxc: `${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}`,
    mves: `${process.env.VUE_APP_EN_PRODUCTNAME_MVES}`,
  },
  companyInfo: {
    name: `${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    portalUrl: `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_URL}`,
    salesEmail: `${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}`,
    arGlobalEmail: `${process.env.VUE_APP_EN_COMPANYINFO_AR_GLOBAL_EMAIL}`,
    portal: `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}`,
  },
  services: {
    'loading-services': `Chargement des services...`,
    'no-matching': `Services correspondants introuvables.`,
    'diversity-zone': `Zone de diversité`,
    'create-diverse-pair': `Créer un pair diversifié`,
    'red-zone': `Zone rouge`,
    'blue-zone': `Zone bleue`,
    'invoice-reference': `Référence de niveau de service`,
    'invoice-reference-explanation': `La référence de niveau de service apparaîtra sur un niveau de ligne de facture pour le produit/service associé`,
    'rate-limit': `Débit maximal`,
    'select-rate-limit': `Sélectionner le débit maximal`,
    'max-rate-limit': `Débit maximal`,
    'service-key': `Clé de service`,
    'service-deleted': `Ce service a été supprimé des données. Redirection vers les services`,
    'configuration-details': `Détails de la configuration`,
    'service-logs': `Journaux de service`,
    reloading: `Les informations de service sont en cours de chargement. Veuillez patienter.`,
    'initial-bgp-state': `État BGP initial`,
    saved: `Votre configuration a été enregistrée.`,
    'service-id': `ID de service`,
    'provisioning-status': `Statut de provisionnement`,
    'loading-status': `Chargement du statut du service...`,
    'unknown-status': `Impossible de déterminer le statut de ce service`,
    media: `Support`,
    speed: `Vitesse`,
    allocated: `Alloué`,
    demarcation: `Démarcation`,
    up: `Le service est actif.`,
    down: `Le service est indisponible`,
    'check-logs': `Consultez les journaux pour plus d’informations.`,
    'product-name': `Nom de produit`,
    'rate-limit-fixed': `Remarque : Le débit maximal ne peut pas être modifié pour ce type de service après le déploiement.`,
    'fixed-rate': `(débit fixe)`,
    'service-status': `Statut du service`,
    'service-information': `Informations du service`,
    'loading-resources': `Chargement de ressources. Veuillez patienter.`,
    'loading-issue': `Il y a un problème de chargement des données. Veuillez contacter l'assistance.`,
    'identifier-copy': `Identificateur du service - cliquez pour copier`,
    decommissioned: `Ce service est déjà désactivé.`,
    filter: `Filtrer les services`,
    'expand-collapse': `Développer ou réduire tous les services`,
    'some-speeds-disabled': `Remarque : Certaines vitesses peuvent être désactivées parce qu'un service associé est supérieur à cette vitesse.`,
    'diversity-option': `Option de diversité`,
    'loading-state-info': `Chargement des informations du service actualisées.`,
    'waiting-on-deploy': `Déploiement du service en cours`,
    'no-company': `Aucune entreprise disponible.`,
    'cancelled-when': `Le service annulé prendra fin le {when}.`,
    'click-restore': `Cliquez pour restaurer le service`,
    'cancel-unknown': `Le service annulé prendra fin à une date inconnue.`,
    'pending-internal-speed-approval': `En attente de votre approbation concernant une demande de changement de vitesse provenant de {where}`,
    'pending-internal-connection-approval': `En attente de votre approbation concernant une connexion provenant de {where}`,
    'pending-external-connection-approval': `En attente d'une approbation externe concernant une connexion provenant de {where}`,
    'pending-3rd-party-speed-approval': `En attente d’une approbation externe concernant un changement de vitesse provenant de {where}`,
    'pending-3rd-party-connection-approval': `En attente d'une approbation externe concernant une connexion provenant de {where}`,
    'gci-waiting-configure': `En attente d'une session BGP entrante.`,
    'waiting-for-resources': `Ce service attend d'être activé pour la première fois.`,
    'in-design-not-deployed': `Pas encore déployé. Modifiez les détails et terminez le déploiement.`,
    'in-design-not-ordered': `Pas encore commandé. Commandez via la barre latérale à gauche.`,
    'approve-connections': `Approuver les connexions`,
    'approve-connections-list': `Vous avez les connexions suivantes en attente d'approbation :`,
    'best-route': `Meilleure route`,
    'next-hop-vxc': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} de saut suivant`,
    bgp: `BGP`,
    'type-locked': `Ce {type} est verrouillé.`,
    'type-being-deployed': `Ce {type} est en cours de déploiement.`,
    'type-unlock': `Cliquez pour déverrouiller ce {type} et autoriser sa modification.`,
    'type-unlock-notlive': `Ce {type} n'est pas actif et ne peut pas être déverrouillé.`,
    'type-locked-admin': `Ce {type} est verrouillé par un administrateur et n'est pas modifiable.`,
    'type-lock': `Cliquez pour verrouiller ce {type} et empêcher sa modification et sa suppression.`,
    'type-lock-notlive': `Ce {type} n'est pas actif et ne peut pas être verrouillé.`,
    'type-unlocked-admin': `Ce {type} est déverrouillé et peut être verrouillé uniquement par un administrateur.`,
    'type-locked-megaport': `Ce {type} a été verrouillé par ${process.env.VUE_APP_EN_COMPANYINFO_NAME} et n'est pas modifiable. Contactez l'assistance ${process.env.VUE_APP_EN_COMPANYINFO_NAME} pour plus d’informations.`,
    'type-cancelled': `Ce {type} a été annulé.`,
    'terminate-count': `S. O. | Oui, mettre fin à 1 service | Oui, mettre fin à {count} services`,
    'service-locked': `Remarque : Ce service est verrouillé par ${process.env.VUE_APP_EN_COMPANYINFO_NAME} et ne peut pas être modifié ni supprimé. Contactez l'assistance ${process.env.VUE_APP_EN_COMPANYINFO_NAME} pour plus d’informations.`,
    'service-admin-locked': `Remarque : Ce service est verrouillé et ne peut pas être modifié ni supprimé. Seul un Administrateur de l'entreprise peut déverrouiller le service.`,
    'edit-permission-denied': `Remarque : Vous n'êtes pas autorisé à modifier ce service.`,
    'ip-address': `Adresse IP`,
    'peer-ip-address': `Adresse IP de pair`,
    'creation-date': `Date de création`,
    'replace-configured': `Remplacer les services configurés`,
    'replace-confirmation': `Cela supprimera vos éléments actuellement configurés et les remplacera par cet ensemble sauvegardé. Continuer ?`,
    'delete-configuration': `Supprimer la configuration enregistrée`,
    // Le nom est le nom du port/mcr/mve et le productType est le type de port qui a été mis à jour (Port, MCR, MVE).
    'port-like-updated-title': `{name} {productType} mis à jour`,
    'port-like-updated-message': `Mises à jour enregistrées pour {productType} '{name}'`,
    'click-setup': `Cliquez pour configurer`,
    'new-mve-message': `Attention ! La connectivité Internet est obligatoire pour que l’image/le logiciel ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} puisse être validé(e). Vous pouvez choisir d’activer ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ou d’utiliser votre propre accès Internet.`,
    'connection-denied': `Le ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} vers {destination} a été refusé.`,
    'connection-approved': `Le ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} vers {destination} a été approuvé.`,
    'amsix-contact-phone': `Un numéro de téléphone est obligatoire pour le {contactType} d’un service AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX}.`,
    'gbps-ix': `Gbits/s ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'gbps-vxc': `Gbits/s ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    'ibm-request-fail': `Impossible de récupérer les limites de débit de ce port ; réessayez.`,
    'type-state': `État {type}`,
    'shutdown-dialog-title': `Voulez-vous vraiment fermer ce service ?`,
    'shutdown-dialog-message': `Le trafic ne passera pas par ce service, s’il est fermé. Il se comportera comme s’il était fermé sur le réseau ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'shutdown-dialog-note': `Remarque : La facturation de ce service restera active et vous continuerez à être facturé(e) pour cette connexion.`,
    'sub-uid': `Sous-UID`,
    'type-move-notlive': `Ce {type} n’est pas actif et ne peut pas être déplacé.`,
    'port-move-notlive': `Ce {type} n’est pas actif et ses connexions ne peuvent pas être déplacées.`,
    'no-movable-type': `Il n’existe pas de connexions déplaçables sur ce {type}.`,
    'move-permission-denied': `Vous n’êtes pas autorisé(e) à déplacer des connexions.`,
    'cant-move-multiple-vnics': `Impossible de déplacer les connexions d’un ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} avec plusieurs vNIC`,
    'cant-move-no-ports': `Aucun autre ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} dans la même zone métropolitaine`,
    'cant-move-no-mcrs': `Aucun autre ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} dans la même zone métropolitaine`,
    'cant-move-no-mves': `Aucun autre ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} dans la même zone métropolitaine`,
    'awaiting-approval': `En attente de l’approbation ou du rejet des modifications en cours`,
    'shutdown-confirm-message': `Pour fermer, cliquez sur « Confirmer », puis sur « Enregistrer ».`,
    'view-additional-filters': `Afficher des filtres supplémentaires`,
    'clear-filters': `Effacer les filtres`,
    'service-type': `Type de service`,
    'contract-term': `Durée du contrat`,
    'change-sort-direction': `Changer le sens de tri`,
    'matching-services': `0 service correspondant ({total} au total) | 1 service correspondant ({total} au total) | {count} services correspondants ({total} au total)`,
    'diversity-location-tooltip': `Filtrez les emplacements par zones de diversité disponibles. Si vous laissez cette option définie sur « Auto », nous sélectionnerons une zone de diversité pour vous. Pour plus d’informations, consultez notre {link}`,
    'diversity-config-tooltip': `Zone de diversité à laquelle ce service sera attribué.`,
    'auto-zone': `Zone Auto`,
    'download-inventory-report': `Télécharger le rapport d’inventaire`,
    'expand-all': `Développer tous les services`,
    'collapse-all': `Réduire tous les services`,
    'extra-actions': `Actions supplémentaires`,
    'type-status': `Type et statut`,
    'has-ongoing-maintenance': `Événement de maintenance en cours.`,
    'has-ongoing-outage': `Événement de panne de service en cours.`,
  },
  app: {
    'environment-unrecognized': `Environnement non reconnu`,
    'unrecognized-url': `Accès depuis ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_URL} ou une URL de développement reconnue`,
  },
  general: {
    'delete-type': `Supprimer {type}`,
    'edit-type': `Modifier {type}`,
    'remove-type': `Supprimer {type}`,
    'new-type': `Nouveau {type}`,
    'add-type': `Ajouter {type}`,
    'update-type': `Mettre à jour {type}`,
    'type-details': `Détails de {type}`,
    'type-configuration': `Configuration de {product}`,
    'edit-configured-type': `Modifier le {type} configuré`,
    'select-type-location': `Sélectionner l'emplacement de {type}`,
    'delete-permission-denied': `Vous n'êtes pas autorisé à supprimer ce {type}.`,
    'type-not-found': `{type} introuvable`,
    'type-not-found-redirect': `Ce {type} est introuvable dans les données. Redirection vers les services`,
    'success-count': `0 succès | 1 succès | {count} succès`,
    'warning-count': `0 avertissement | 1 avertissement | {count} avertissements`,
    'error-count': `0 erreur | 1 erreur | {count} erreurs`,
    'count-months': `0 mois | 1 mois | {count} mois`,
    'locations-count': `0 emplacement | 1 emplacement | {count} emplacements`,
    'last-log': `Dernier journal reçu {last}`,
    'max-rate-limit': `MAXI : {maxRateLimit} Mbits/s`,
    'speed-gbps': `{speed} Gbits/s`,
    'missing-billing-market': `Pays de facturation manquant « {country} ».`,
    'create-thing': `Créer {thing}`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    welcome: `Bienvenue chez ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    save: `Enregistrer`,
    revert: `Rétablir`,
    close: `Fermer`,
    cancel: `Annuler`,
    back: `Retour`,
    next: `Suivant`,
    done: `Terminé`,
    loading: `Chargement...`,
    services: `Services`,
    service: `Service`,
    price: `Prix`,
    summary: `Résumé`,
    copy: `Copier`,
    'copy-to-clipboard': `Copier dans le presse-papiers`,
    'characters-copied': `0 caractère copié | 1 caractère copié | {count} caractères copiés`,
    version: `Version`,
    'first-name': `Prénom`,
    'last-name': `Nom de famille`,
    phone: `Téléphone`,
    email: `E-mail`,
    password: `Mot de passe`,
    ok: `OK`,
    'details-help': `Pour plus de détails, voir cette rubrique d'aide :`,
    documentation: `Documentation`,
    saving: `Enregistrement...`,
    public: `Public`,
    private: `Privé`,
    unspecified: `Non spécifié`,
    'x-end': `{end}-End`,
    hide: `Masquer`,
    show: `Afficher`,
    'technical-contact': `Contact technique`,
    'noc-contact': `Contact NOC`,
    'contact-phone': `Numéro de téléphone du contact`,
    'select-type': `Sélectionner un type`,
    size: `Taille`,
    'step-unavailable': `Cette option n'est pas disponible tant que vous n'avez pas terminé les étapes précédentes.`,
    'not-available': `Non disponible`,
    'configure-thing': `Configurer {thing}`,
    'modification-delay': `Remarque : Il faut jusqu'à 2 minutes pour que les modifications appliquées apparaissent.`,
    billing: `Facturation`,
    'processing-approval': `Traitement de l'approbation...`,
    'processing-denial': `Traitement du rejet de la demande...`,
    unknown: `Inconnu`,
    yes: `Oui`,
    no: `Non`,
    untitled: `Sans titre`,
    'no-location': `Aucun emplacement sélectionné`,
    'select-location': `Sélectionnez un emplacement.`,
    'unknown-location': `Emplacement inconnu`,
    configure: `Configurer`,
    'no-matching-countries': `Aucun pays correspondant`,
    'no-matching-locations': `Aucun emplacement correspondant`,
    'country-filter': `Filtre de pays`,
    search: `Rechercher`,
    'pre-order': `Précommander`,
    details: `Détails`,
    name: `Nom`,
    success: `Succès`,
    disabled: `Désactivé`,
    enabled: `Activé`,
    'shut-down': `Désactivé`,
    term: `Durée`,
    red: `Rouge`,
    blue: `Bleu`,
    none: `Aucun`,
    new: `Nouveau`,
    general: `Général`,
    status: `Statut`,
    'status-unknown': `Statut inconnu`,
    'created-by': `Créé par`,
    'privacy-policy': `Politique de confidentialité`,
    'site-terms': `Conditions du site`,
    gsa: `Contrat de services global`,
    'acceptable-use': `Politique d'utilisation acceptable`,
    'please-select': `Sélectionner`,
    'important-information': `Informations importantes`,
    'agree-terms': `J'accepte les conditions générales.`,
    'view-details': `Afficher les détails`,
    'action-confirmation': `Voulez-vous vraiment effectuer cette opération ?`,
    'unknown-error': `Erreur inconnue`,
    edit: `Modifier`,
    remove: `Supprimer`,
    'request-failed': `Échec de la demande`,
    'company-name': `Nom de l'entreprise`,
    active: `Actif`,
    inactive: `Inactif`,
    na: `S. O.`,
    disable: `Désactiver`,
    pricing: `Tarification`,
    note: `Remarque`,
    website: `Site Web`,
    'click-to-visit': `Cliquez pour consulter.`,
    all: `Tout`,
    ipv4: `IPv4`,
    ipv6: `IPv6`,
    you: `Vous`,
    'your-company-admin': `L'administrateur de votre entreprise`,
    date: `Date`,
    mbps: `Mbits/s`,
    start: `Début`,
    end: `Fin`,
    duration: `Durée`,
    'loading-details': `Chargement des détails...`,
    'details-load-failed': `Échec de chargement des détails`,
    prefix: `Préfixe`,
    'next-hop': `Saut suivant`,
    'load-failed': `Échec de chargement`,
    to: `À`,
    from: `De`,
    message: `Message`,
    send: `Envoyer`,
    sort: `Trier`,
    'hide-details': `Cliquez pour masquer les détails.`,
    'show-details': `Cliquez pour afficher les détails.`,
    'logs-label': `Journaux`,
    'usage-label': `Utilisation`,
    'configuration-label': `Configuration`,
    actions: `Actions`,
    confirm: `Confirmer`,
    delete: `Supprimer`,
    'reload-data': `Recharger les données`,
    network: `Réseau`,
    location: `Emplacement`,
    selection: `Sélection`,
    'choose-file': `Sélectionner un fichier`,
    label: `Étiquette`,
    'single-use': `Usage unique`,
    'multi-use': `Usage multiple`,
    add: `Ajouter`,
    'select-date': `Sélectionner une date`,
    'loading-data': `Chargement des données`,
    description: `Description`,
    'click-copy': `Cliquez pour copier.`,
    token: `Jeton`,
    notice: `Avis`,
    'end-date': `Date de fin`,
    existing: `existant`,
    'no-info': `Aucune autre information disponible`,
    'error-updating': `Erreur de mise à jour de {thing}.`,
    'click-enable': `Cliquez pour activer.`,
    'updating-details': `Mise à jour des détails...`,
    approve: `Approuver`,
    view: `Afficher`,
    'save-as': `Enregistrer sous`,
    order: `Commander`,
    'show-actions': `Afficher les actions`,
    'hide-actions': `Masquer les actions`,
    'items-per-page': `{count} éléments par page`,
    'show-all': `Afficher tous les éléments`,
    origin: `Origine`,
    communities: `Communautés`,
    updated: `Mis à jour`,
    'all-types': `Tous les types`,
    static: `Statique`,
    connected: `Connecté`,
    local: `Local`,
    file: `Fichier`,
    clear: `Effacer`,
    'no-match-found': `Correspondance introuvable`,
    'list-not-available': `Aucune liste disponible`,
    'list-select': `Sélectionner une liste`,
    'password-toggle': `Basculer pour afficher le mot de passe`,
    'exact-label': `Exact`,
    'position-label': `Position`,
    'min-label': `Mini.`,
    'max-label': `Maxi.`,
    'operations-label': `Opérations`,
    'drag-position-label': `Cliquez et déposez pour placer.`,
    'example-abbr': `Par ex.`,
    star: `astérisque`,
    'standard-label': `Standard`,
    'advanced-label': `Avancé`,
    'filters-label': `Filtres`,
    'on-label': `Activé`,
    'off-label': `Désactivé`,
    'default-label': `Par défaut`,
    'deny-label': `Refuser`,
    'permit-label': `Autoriser`,
    'required-field-label': `Champ obligatoire`,
    'warning-label': `Avertissement`,
    'type-name': `Nom du {type}`,
    'save-error': `Impossible d'enregistrer vos modifications : `,
    'copy-thing-to-clipboard': `Copier {thing} dans le presse-papiers`,
    'error-creating': `Erreur de création de {thing}.`,
    'error-deleting': `Erreur de suppression de {thing}.`,
    'success-creating': `{thing} correctement créé(e).`,
    'success-updating': `{thing} correctement mis(e) à jour.`,
    'success-deleting': `{thing} correctement supprimé(e).`,
    'created-thing': `Création correcte de {thing}`,
    'updated-thing': `Mise à jour correcte de {thing}`,
    'word-counter': `{count} termes sur {maxWords}`,
    user: `Utilisateur`,
    load: `Charger`,
    update: `Mettre à jour`,
    'server-error': `Erreur de communication avec le serveur API.`,
    'context-switching-title': `Voulez-vous vraiment intervertir les comptes ?`,
    'context-switching-message': `Vous êtes sur le point de changer votre {accountTypeFrom} <strong>{fromCompany}</strong> en {accountTypeTo} <strong>{toCompany}</strong>`,
    'feature-flag-access-error': `{pageName} n’est pas accessible depuis un compte géré. Sélectionner votre compte partenaire pour accéder à {pageName}.`,
    'confirm-password-mismatch': `Le mot de passe ne correspond pas au mot de passe fourni ci-dessus.`,
    'open-sidebar': `Ouvrez la barre latérale`,
    'learn-megaport': `Découvrez-en plus sur les services ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ou visitez le ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
    'visit-documentation': `Accéder à ${process.env.VUE_APP_EN_COMPANYINFO_DOCUMENTATION_PORTAL}`,
    'visit-portal': `Visiter le ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}`,
    'login-trouble': `Vous rencontrez des difficultés pour vous connecter ? {link}`,
    'site-disclaimer': `En accédant au ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}/à la page d’accueil, vous êtes tenu(e) de respecter la politique de confidentialité, les conditions du site et la politique d’utilisation acceptable de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'partner-deals': `Accords de partenariat`,
    'cancel-reterm': `Cliquez sur Cancel (Annuler) si vous ne souhaitez prendre aucune mesure et passer à une période sans contrat au prix catalogue à la fin de la durée du contrat.`,
    'deleted-thing': `Élément {thing} supprimé avec succès`,
    'unexpected-error': `Une erreur imprévue s’est produite. Réessayez plus tard. Si cette erreur persiste, contactez ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'unsaved-changes-confirm-message': `Toutes les modifications non enregistrées seront perdues.`,
    'unsaved-changes-confirm-title': `Quitter la page sans enregistrer ?`,
    'unsaved-changes-confirm-cancel': `Rester sur la page`,
    'unsaved-changes-confirm-ok': `Quitter la page`,
    'not-now': `Pas maintenant`,
    up: `Actif`,
    down: `Inactif`,
    beta: `Bêta`,
    languages: `Langues`,
    expand: `Développer`,
    collapse: `Réduire`,
    here: `ici`,
    true: `vrai`,
    false: `faux`,
    'get-help': `Obtenir de l’aide`,
    'type-shutdown-state': `Remarque : Ce {type} est en état d’arrêt`,
    continue: `Continuer`,
    accept: `Accepter`,
    decline: `Refuser`,
    'terms-and-conditions': `Conditions générales`,
    'lag-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_LAGS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_LAGS}`,
    'mcr-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}`,
    'mve-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES}`,
    connection: `Connexion`,
    connections: `Connexions`,
    'move-to': `Déplacer vers`,
    'not-submitted': `Non envoyé`,
    processing: `Traitement en cours`,
    failure: `Échec`,
    move: `Déplacer`,
    'select-all': `Sélectionner tout`,
    'deselect-all': `Désélectionner tout`,
    'non-prod': `Il s’agit d’un environnement hors production`,
    auto: `Auto`,
    'preparing-download': `Préparation du téléchargement...`,
    'download-failed-title': `Échec de téléchargement`,
    'download-failed': `Le fichier n’a pas pu être téléchargé ; réessayez ultérieurement.`,
    mobile: `Mobile`,
    activate: `Activer`,
    deactivate: `Désactiver`,
    valid: `Valide`,
    invalid: `Non valide`,
    type: `Type`,
    expired: `Expiré`,
    comments: `Commentaires`,
    refresh: `Actualiser`,
    'pre-release': `Pré-version`,
    'logs-count': `1 entrée de journal reçue au cours des dernières 24 heures | {count} entrées de journal reçues au cours des dernières 24 heures`,
    'most-recent-n-events': `{count} événements les plus récents affichés`,
    'no-logs': `Aucun journal pour ce service`,
  },
  tour: {
    'start-tour': `Commencer la visite`,
    'find-location': `Rechercher un emplacement`,
    'location-selected': `Emplacement sélectionné`,
    'add-a-service': `Ajouter un service`,
    'actions-panel': `Panneau d'actions`,
    'live-chat': `Messagerie instantanée 24 h/24, 7 j/7`,
    'next-steps': `Étapes suivantes`,
    start: `C'est ici que vous pouvez créer, déployer et gérer des services tels que ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}, ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}, ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} et ${process.env.VUE_APP_EN_PRODUCTNAME_IXS}.<br/><br/>Cliquez sur Suivant pour continuer.`,
    'intro-port': `Un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} est votre connexion à l'infrastructure ${process.env.VUE_APP_EN_COMPANYINFO_NAME} et la passerelle vers une multitude de points de terminaison Cloud, Internet Exchange et autres.<br/><br/>Cliquez sur Suivant pour créer un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'select-port': `Votre première étape consiste à sélectionner l'emplacement de votre ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.<br/><br/>Cliquez sur Suivant pour sélectionner un emplacement.`,
    'location-selected-content': `Une fois l'emplacement sélectionné, configurez le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.<br/><br/>Cliquez sur Suivant pour configurer le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'port-configuration': `Configuration du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'configure-port': `Configurez votre ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} en saisissant les informations requises.<br/><br/>Cliquez sur Suivant pour ajouter des informations pour le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    price: `Une fois les informations sur le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} fournies, le prix apparaît.<br/><br/>Cliquez sur Suivant pour accéder à l'écran suivant.`,
    summary: `Il s'agit de l'étape finale pour configurer votre ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}. Vous ne serez pas facturé tant que vous ne l'aurez pas commandé.<br/><br/>Cliquez sur Suivant pour ajouter le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'port-configured': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} configuré`,
    'configured-port': `Informations sur le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} fournies.<br/><br/>Cliquez sur Suivant pour accéder à l’écran suivant.`,
    'add-service': `L'étape suivante consiste à ajouter un service au ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} que vous venez de configurer. Cliquez sur l'icône +Connexion à droite du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ou sélectionnez l'une des options ci-dessous.`,
    'actions-panel-content': `Lors de cette démo, votre ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} configuré apparaît ici avec toutes les autres actions requises pour l'activer et l'utiliser.<br/><br/>Si vous avez des actions en attente, elles apparaissent dans la barre latérale.`,
    chat: `Besoin d'aide ? Cliquez ici pour lancer une conversation en temps réel avec un spécialiste ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'next-steps-no-kb': `Vous êtes parvenu à la fin de la visite !<br/><br/>À présent, vous êtes prêt à accéder à la page Services et à créer votre premier ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'next-steps-kb-html': `Vous êtes parvenu à la fin de la visite !<br/><br/>N'oubliez pas de consulter notre <a href="https://docs.megaport.com/" target="_blank">Documentation</a> ou la <a href="https://www.youtube.com/channel/UCdVx98wdrhcTCIuw8UE5hIA" target="_blank">chaîne YouTube</a> pour obtenir davantage d'aide.<br/><br/>À présent, vous êtes prêt à accéder à la page Services et à créer votre premier ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'next-steps-partial-html': `Vous êtes parvenu à la fin de la visite !<br/><br/>N'oubliez pas de consulter notre <a href="https://docs.megaport.com/" target="_blank">Documentation</a> ou la <a href="https://www.youtube.com/channel/UCdVx98wdrhcTCIuw8UE5hIA" target="_blank">chaîne YouTube</a> pour obtenir davantage d'aide.<br/><br/>À présent, vous êtes prêt à créer votre premier ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    exit: `Terminer la visite`,
  },
  about: {
    why: `Pourquoi ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ?`,
    provisioning: `Provisionnez des connexions en 59 secondes sur le réseau ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    scalable: `Connectivité évolutive`,
    'scalable-content': `Augmentez et réduisez votre bande passante à tout moment en fonction des demandes de votre entreprise.`,
    'real-time': `Provisionnement en temps réel`,
    api: `Intégration d'API puissante`,
    'api-content': `Gérez votre connectivité de manière autonome et optimisez votre flux de travail en utilisant notre puissante API.`,
    'about-megaport': `À propos de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'about-megaport-video': `À propos de la vidéo ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'megaport-features': `Fonctions de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
  },
  authentication: {
    'about-company': `À propos de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'password-reset': `Votre compte nécessite la réinitialisation du mot de passe. Définissez un nouveau mot de passe ici pour vous connecter.`,
    'setting-up': `Configuration de votre compte...`,
    'password-strength': `Force du mot de passe`,
    'very-weak': `Très faible`,
    weak: `Faible`,
    moderate: `Modéré`,
    good: `Bon`,
    excellent: `Excellent`,
    'good-password': `Votre mot de passe est de bonne qualité.`,
    'excellent-password': `Votre mot de passe est d'excellente qualité.`,
    agreement: `Contrat`,
    'agree-to': `Accepter de `,
    'create-account': `Créer un compte`,
    google: `Se connecter via Google`,
    'have-account': `Vous avez déjà un compte ?`,
    'log-in': `Se connecter`,
    'one-time-token': `Jeton unique`,
    'new-password': `Votre nouveau mot de passe`,
    'target-username': `Nom d'utilisateur cible`,
    forgot: `Mot de passe oublié`,
    register: `S'enregistrer maintenant`,
    'processing-reset': `Traitement de la demande de réinitialisation...`,
    'send-reset': `Envoyer la réinitialisation`,
    resetting: `Réinitialisation du mot de passe...`,
    reset: `Réinitialiser le mot de passe`,
    'trying-login': `Tentative de connexion...`,
    'please-login': `Se connecter`,
    'failed-login': `Échec de connexion avec vos identifiants existants. Reconnectez-vous.`,
    'password-reset-title': `Réinitialisation du mot de passe`,
    'missing-reset-token': `Jeton de réinitialisation du mot de passe manquant. Accédez à la page de connexion pour envoyer une nouvelle demande de Mot de passe oublié.`,
    'logging-in': `Connexion...`,
    'need-to-reset': `Vous devrez réinitialiser votre mot de passe. Le formulaire a été mis à jour avec le champ obligatoire.`,
    failed: `Échec de connexion`,
    'error-registering': `Erreur d'enregistrement`,
    'unknown-error-registering': `Erreur inconnue lors de la tentative d'enregistrement`,
    'reset-email-sent': `Un e-mail vous a été envoyé pour réinitialiser votre mot de passe.`,
    'reset-request-failed': `Réinitialisation du mot de passe - Échec de la demande`,
    'unknown-error-reset': `Erreur inconnue lors de la tentative de réinitialisation du mot de passe`,
    'password-change-successful': `Le mot de passe a été correctement modifié. Vous pouvez maintenant vous connecter à l'aide de votre nouveau mot de passe.`,
    'password-reset-failed': `Échec de réinitialisation du mot de passe. Pour le réinitialiser de nouveau, accédez à la page de connexion et envoyez une demande de Mot de passe oublié.`,
    'auth-failed': `Échec d'authentification`,
    'auth-failed-message': `Échec d'authentification de votre compte`,
    'confirm-email-label': `Confirmation de l'adresse e-mail`,
    confirming: `Confirmation en cours. Veuillez patienter.`,
    success: `Votre e-mail a été confirmé.`,
    'failed-confirm': `Échec de confirmation de votre adresse e-mail. Réessayez ultérieurement.`,
    'return-portal': `Revenir au portail`,
    'registered-company': `Entreprise enregistrée : {name}`,
    'updated-company': `Entreprise {name} mise à jour`,
    'login-success-title': `Connexion réussie !`,
    'login-success-message': `{name} s'est connecté(e)`,
    'login-failed-token': `Échec de connexion avec votre jeton existant. Reconnectez-vous.`,
    'use-security-code': `Utiliser le code de sécurité`,
    'temp-password': `Votre mot de passe temporaire`,
    'confirm-password': `Confirmer le mot de passe`,
    'login-error': `Erreur de connexion`,
    'login-error-msg': `Impossible de vous connecter avec vos identifiants. Vous avez des difficultés à vous connecter ? <a href="https://www.megaport.com/contact/support/" target="_blank">Obtenir de l’aide</a>`,
    submit: `Envoyer`,
    'vendor-onboarding': {
      cisco: {
        'intro-header': `Bienvenue sur Cisco SD-WAN Cloud Interconnect avec ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
        'intro-content': `Nous sommes ravis de vous voir ici et nous espérons que votre expérience avec le produit sera satisfaisante. Vous n’êtes qu’à quelques pas de pouvoir créer des connexions dédiées à vos environnements cloud par le biais de vManage et de pouvoir tirer parti de Cisco SD-WAN superposé au réseau sous-jacent hautement disponible de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
        'temp-password-info': `<b>Entrez votre adresse e-mail et votre mot de passe temporaire</b> Après avoir modifié votre mot de passe, vous pouvez saisir vos informations d’identification dans Cisco vManage version 20.5 ou une version ultérieure pour passer commande pour des MVE, pour créer des VXC de MVE vers d’autres destinations sur le réseau Megaport et pour les supprimer, au lieu d’utiliser ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
        'new-password-info': `<b>Saisissez votre nouveau mot de passe.</b> Après avoir modifié votre mot de passe, vous pouvez saisir vos informations d’identification dans Cisco vManage version 20.5 ou une version ultérieure pour passer commande pour des MVE, pour créer des VXC de MVE vers d’autres destinations sur le réseau Megaport et pour les supprimer, au lieu d’utiliser ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
        'password-changed-info': `Quelle est la prochaine étape ? Saisissez ces informations d’identification dans Cisco vManage version 20.5 ou une version ultérieure pour provisionner des ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} ou pour créer des ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} entre les ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} et des fournisseurs de services cloud.`,
        'temp-password-intro': `Saisissez votre adresse e-mail et votre mot de passe temporaire. `,
        'new-password-intro': `Saisissez votre nouveau mot de passe. `,
        'password-changed-intro': `Votre mot de passe a été modifié ! `,
        'password-info': `Après avoir modifié votre mot de passe, vous pouvez saisir vos informations d’identification dans Cisco vManage version 20.5 ou une version ultérieure pour passer des commandes pour le ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}, pour créer des ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} à partir des ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} vers d’autres destinations sur le réseau ${process.env.VUE_APP_EN_COMPANYINFO_NAME} et pour les supprimer au lieu d’utiliser le ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
      },
    },
    'verify-email-heading': `Veuillez vérifier votre e-mail`,
    'verify-email-subheading': `Nous vous avons envoyé un lien pour confirmer votre compte`,
    'verify-email-description': `Vous n’avez pas reçu d’e-mail ? Veuillez vérifier votre dossier de courrier indésirable pour vous assurer qu’il ne s’y trouve pas.`,
    'verify-email-button': `Renvoyer l’e-mail`,
    'verify-email-resent-successful': `L’e-mail de confirmation a été renvoyé avec succès.`,
    'verify-email-resent-unsuccessful': `Un problème est survenu lors du renvoi de votre e-mail de confirmation.`,
    'password-must-include': `Votre mot de passe doit`,
    'at-least-8-char': `Contenir au moins 8 caractères`,
    'at-least-one-number': `Contenir au moins un numéro`,
    'at-least-one-symbol': `Contenir au moins un symbole`,
    'at-least-one-lower-case-letter': `Contenir au moins une lettre minuscule`,
    'at-least-one-upper-case-letter': `Contenir au moins une lettre majuscule`,
    'create-an-account': `Créer un compte`,
    or: `Ou`,
    'signup-email': `S’inscrire par e-mail`,
    'signup-google': `S’inscrire avec Google`,
    'login-to-your-account': `Connectez-vous à votre compte`,
    'signup-success': `L’inscription a réussi !`,
    'signup-failed': `L’inscription a échoué, veuillez réessayer.`,
    login: `Connexion`,
    'login-google': `Se connecter avec Google`,
    'setting-company': `Remarque : Après avoir enregistré le nouveau nom commercial de l’entreprise, votre session sera déconnectée. Vous devrez vous reconnecter pour être ajouté à la nouvelle entreprise.`,
    'reset-message': `Si ce compte existe, un e-mail vous a été envoyé pour réinitialiser votre mot de passe.`,
    'signup-google-instructions': `Pour continuer, saisissez une raison sociale d’entreprise et prenez connaissance des conditions ci-dessous.`,
    signup: `Sign up (S’inscrire)`,
    'create-account-email': `Create Account with email (Créer un compte avec un e-mail)`,
    'confirm-new-password': `Confirm New Password (Confirmer le nouveau mot de passe)`,
    'new-password-no-your': `Nouveau mot de passe`,
    'reset-expired-dialog-title': `The reset link used is no longer valid (Le lien de réinitialisation utilisé n’est plus valide).`,
    'reset-expired-dialog-desc': `Le lien utilisé dans l’e-mail a expiré ou n’est pas valide. Cliquez sur « Resend Link » (Renvoyer le lien) ou contactez ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'resend-link': `Resend Link (Renvoyer le lien)`,
    'unexpected-error-dialog-title': `An unexpected error has occured (Une erreur inattendue s’est produite). Réessayez ultérieurement.`,
    'unexpected-error-dialog-desc': `Si cette erreur persiste, contactez ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'login-megaport': `Se connecter à ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'email-verified': `Votre adresse e-mail a été vérifiée`,
    'invalid-link': `Ce lien n’est plus valable`,
    'request-link': `Cliquez sur le bouton pour recevoir un nouveau lien à votre adresse e-mail enregistrée.`,
    'verified-account': `Ce compte est déjà vérifié`,
    'verified-email': `Votre adresse e-mail a déjà été vérifiée`,
    'continue-to-megaport': `Passer à ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}`,
    'company-trading-name-instructions': `Pour continuer, saisissez une raison sociale d’entreprise et prenez connaissance des conditions ci-dessous.`,
    'change-email-desc': `Saisissez votre nouvelle adresse e-mail et cliquez sur Obtenir le code de vérification. Un code de vérification sera envoyé à votre nouvelle adresse e-mail à saisir ci-dessous. Ne fermez pas cette fenêtre.`,
    'change-email-warning': `Veuillez noter que vous devrez vous reconnecter après avoir modifié votre adresse e-mail.`,
    'verification-code': `Code de vérification`,
    'get-verification-code': `Obtenir le code de vérification`,
    'verify-email-taken': `Cette adresse e-mail est déjà utilisée par un autre utilisateur.`,
    'verification-code-expired': `Le code de vérification a expiré, veuillez demander un nouvel e-mail de vérification.`,
    'verification-code-sent': `Nous vous avons envoyé un code de vérification unique`,
    'verification-code-failed': `Un problème est survenu lors de la récupération d’un code de vérification`,
    'verification-code-wrong': `Le code de vérification est erroné`,
    'setup-mfa-cancel-confirm': `L’annulation vous ramènera à l’écran de connexion. Vous ne pourrez pas vous connecter tant que la MFA n’aura pas été configurée.`,
    'setup-mfa-reset': `L’administrateur de votre entreprise a réinitialisé et désactivé votre code d’authentification multifacteur (MFA). Pour pouvoir vous connecter, reconfigurez la MFA.`,
    'setup-mfa-enforced': `L’administrateur de votre entreprise a appliqué l’authentification multifacteur (MFA). Vous ne pourrez pas vous connecter tant que cela n’aura pas été configuré.`,
    'enabling-mfa': `Activation de la MFA`,
    'mfa-token-invalid': `Le code unique {code} n’est pas valide. Après 5 tentatives consécutives infructueuses, votre compte sera verrouillé.`,
    'mfa-long': `Authentification multifacteur`,
    'mfa-enter-token': `La MFA est activée pour votre compte. Pour vous connecter, saisissez un jeton.`,
    verify: `Vérifier`,
    'mfa-verify-token': `Vérification du jeton`,
    'mfa-instructions': `Scannez l’image ci-dessus avec l’application d’authentification multifacteur de votre téléphone. Si vous ne pouvez pas utiliser de code-barres, saisissez | ce code texte | dans l’application.`,
    'enable-mfa-failed': `Échec de l’activation de l’authentification multifacteur`,
    'mfa-token-required': `Un jeton d’authentification multifacteur unique est obligatoire. Le formulaire a été mis à jour avec le champ obligatoire.`,
    'login-cognito-unexpected-failure': `Une erreur imprévue s’est produite lors de l’authentification. Réessayez ou contactez votre entreprise pour demander de l’aide.`,
    'login-sso-assistance': `Si vous avez besoin d’aide, {docs} notre équipe d’assistance.`,
    contact: `contact`,
    'docs-portal': `Portail Docs`,
    'create-one': `en créer un`,
    'login-sso-help-reset-password': `Si vous ne parvenez pas à vous connecter avec votre adresse e-mail et votre mot de passe, essayez de réinitialiser votre mot de passe en utilisant le lien « Forgot Password » (Mot de passe oublié) ci-dessous.`,
    'login-sso-help-idp-config': `Si l’authentification unique (SSO) est activée, mais que vous ne pouvez pas vous connecter, il se peut qu’il y ait une erreur de configuration de l’IdP ou que votre IdP ne réponde pas. Il se peut que vous puissiez vous connecter directement via votre nom d’utilisateur et votre mot de passe ${process.env.VUE_APP_EN_COMPANYINFO_NAME} si vous êtes un administrateur de l’entreprise ou si l’authentification SSO est définie sur Optional (Facultative).`,
    'login-help': `Obtenir de l’aide pour se connecter`,
    'login-temp-password-expired': `Votre mot de passe temporaire a expiré. Contactez notre équipe d’assistance à l’adresse suivante : ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL} pour demander la réinitialisation d’un mot de passe temporaire.`,
    continue: `Continuer`,
    'create-new-account': `Créer un nouveau compte`,
    'join-company-account': `Joindre votre compte d’entreprise`,
    'error-resetting-password': `Erreur de réinitialisation du mot de passe`,
    'reset-password-sent-if-confirmed': `Si cette adresse e-mail figure dans notre système et que le compte a été préalablement confirmé, nous vous enverrons un e-mail vous permettant de réinitialiser votre mot de passe.`,
    'sso-first-login': `L’administrateur de votre entreprise a activé l’authentification SSO sur votre compte. Vous allez être redirigé(e) vers le fournisseur d’identité de votre entreprise pour vous authentifier.`,
    'sso-enforced-no-access': `Un administrateur de l’entreprise a mis en place l’authentification unique (SSO) et vous ne devez vous connecter que par ce biais. Si vous avez besoin d’aide concernant l’accès SSO, contactez l’administrateur de votre entreprise.`,
    'sso-email-mapping-error': `Il existe un problème avec le mappage des attributs de messagerie de votre configuration SSO. Pour le résoudre, nous vous recommandons de contacter l’administrateur de votre entreprise et de lui demander de revoir le mappage des attributs de messagerie. Pour obtenir de l’aide afin de corriger ce problème, reportez-vous à notre page FAQ SSO dans le portail de documents <a href="https://docs.megaport.com/troubleshooting/sso-faq/" target="_blank">.</a>`,
    'email-query-missing': `Il manque un paramètre de requête e-mail`,
    'email-not-sso': `L’e-mail n’est pas configuré pour l’authentification SSO`,
  },
  validations: {
    required: `{thing} est obligatoire.`,
    failed: `Échec de validation`,
    'correct-issues': `Corrigez les problèmes indiqués.`,
    'ix-selection-required': `La sélection de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} est obligatoire.`,
    'asn-validation': `L’ASN ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} doit être compris entre 2 et {maxAsn} (la valeur par défaut est {defaultAsn}).`,
    'asn-validation-plain': `L'ASN doit être compris entre {minAsn} et {maxAsn}.`,
    'asn-validation-range': `Plage d'ASN {minAsn}-{maxAsn} ou {minAsn2}-{maxAsn2}`,
    'mbps-value-range': `Saisissez une valeur comprise entre {min} et {max} Mbits/s.`,
    'value-too-high': `Supérieur au maximum autorisé. Saisissez une valeur inférieure ou égale à {max}.`,
    asn: `Les ASN commencent par « AS » ou « ASN » ou sont numériques.`,
    'min-length': `Longueur minimale 0 caractère | Longueur minimale 1 caractère | Longueur minimale {min} caractères`,
    'max-length': `Longueur maximale 0 caractère | Longueur maximale 1 caractère | Longueur maximale {max} caractères`,
    'min-max-length': `Doit contenir {min} à {max} caractères.`,
    'weak-password': `Votre mot de passe de passe n'est pas suffisamment fort.`,
    'invalid-mac': `Saisissez un adresse MAC valide.`,
    'terms-conditions': `Vous devez accepter les conditions générales.`,
    'new-password': `Saisissez votre nouveau mot de passe.`,
    'password-different': `Le nouveau mot de passe doit être différent de l'ancien.`,
    'email-invalid': `Saisissez une adresse e-mail valide.`,
    'key-invalid': `Échec de validation de la clé`,
    'service-key-invalid': `Saisissez une clé de service valide.`,
    'use-service-key': `Vous devez utiliser une clé de service pour créer une connexion.`,
    'nutanix-key-format': `Format : en option, trois caractères et trait d'union, puis UUID`,
    'azure-key-format': `Les clés de service Azure se composent de 32 à 36 caractères.`,
    'invalid-service-key': `Clé de service non valide`,
    'destination-type': `Sélectionnez le type de destination.`,
    'aws-connection-type': `Vous devez sélectionner le type de connexion AWS.`,
    'destination-provider': `Sélectionnez le fournisseur de destination.`,
    'connection-name': `Saisissez un nom pour la connexion.`,
    'connection-name-min': `Le nom doit être composé d'un ou de plusieurs termes commençant par une lettre.`,
    'select-peering': `Sélectionnez s'il faut effectuer l'appariement avec des serveurs de routage.`,
    'please-select': `Veuillez sélectionner le/la {thing}.`,
    'please-enter': `Saisissez le/la {thing}.`,
    'phone-format': `Format international, par ex. +61 4321 1234`,
    'aws-account': `Les comptes AWS sont des nombres de 12 chiffres.`,
    'ip-cidr-format': `Format d’adresse IP non valide, par exemple 192.0.1.0/30`,
    'ip-list': `Liste d'adresses IP, par exemple 191.0.2.0/24,191.0.2.0/30`,
    'select-peer-type': `Sélectionnez au moins un type de pair.`,
    'pairing-key-format-invalid': `Les clés d'appariement doivent correspondre à la spécification Google.`,
    'virtual-circuit-format-invalid': `Saisissez un ID de circuit virtuel valide.`,
    'pairing-key-invalid': `Clé d’appariement non valide`,
    'virtual-circuit-invalid': `ID de circuit virtuel non valide`,
    'google-pairing-key': `Pour terminer une commande d'une connexion Google, saisissez une clé d'appariement valide.`,
    'invalid-key': `Clé non valide`,
    'enter-valid-key': `Saisissez une clé valide.`,
    'vlan-required': `Valeur VLAN obligatoire`,
    'rate-limit': `Le débit maximal ne peut pas être supérieur au maximum indiqué.`,
    'select-location': `Vous devez sélectionner un emplacement.`,
    'enter-name': `Saisissez un nom.`,
    'select-rate-limit': `Sélectionnez le débit maximal.`,
    ge: `Saisissez une valeur supérieure ou égale à {value}.`,
    'mve-final-check': `Le contrôle final du VLAN ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} sera effectué lors du déploiement.`,
    'vlan-unavailable': `VLAN non disponible`,
    'vlan-available': `VLAN disponible`,
    'vlan-auto-assign': `Laisser le VLAN vide entraîne l'assignation aléatoire d'un VLAN.`,
    'vlan-no-untagged': `Ce VLAN ne peut pas être défini comme non balisé.`,
    'vlan-untagged-warning': `L’annulation du balisage d’un VLAN limite le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} à une seule connexion`,
    'vlan-range': `Plage de valeurs VLAN de {minVlan} à {maxVlan}`,
    'vlan-in-use': `Il existe déjà une connexion conçue sur ce ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} utilisant ce VLAN.`,
    'agree-terms': `Vous devez accepter les conditions.`,
    'insufficient-privileges': `Droits insuffisants`,
    'bio-too-long': `La biographie de l'entreprise contient trop de mots.`,
    'url-format': `Saisissez une URL {site} valide.`,
    'valid-company': `Nom d'entreprise valide obligatoire`,
    'description-too-long': `La description contient trop de mots.`,
    'ip-in-list': `Cette adresse IP figure déjà dans la liste.`,
    'invalid-network': `L'adresse n'est pas une adresse IPv4, IPv6 ou réseau valide.`,
    'not-valid-network': `L'adresse n'est pas une adresse réseau valide.`,
    'no-subnet': `L'adresse IP doit contenir un masque de sous-réseau.`,
    'no-file-selected': `Aucun fichier sélectionné`,
    'wrong-file-type': `Type de fichier erroné`,
    'select-text-file': `Sélectionnez un fichier texte à charger.`,
    'file-too-long': `Fichier trop long`,
    'file-max-length': `La longueur du fichier encodé doit être inférieure à {max} Ko.`,
    'file-read-error': `Erreur de lecture du fichier`,
    'new-password-retype': `Ressaisissez votre nouveau mot de passe.`,
    'new-password-different': `Le nouveau mot de passe et le mot de passe ressaisi doivent correspondre.`,
    'fill-details': `Complétez ces détails.`,
    'characters-not-allowed': `Les caractères suivants ne sont pas autorisés : {chars}`,
    'minimum-payment': `Le montant de paiement minimal est de {value}.`,
    'payment-description-none': `La description du paiement doit comprendre au moins un caractère alphabétique.`,
    'payment-enter-amount': `Saisissez un montant valide.`,
    'type-used': `{thing} déjà utilisé(e)`,
    'cannot-validate-type': `Impossible de valider la valeur de type : {thing}`,
    'ip-family-required': `Vous devez fournir une valeur de famille d'adresses IP.`,
    'ip-address-used': `Cette adresse est déjà utilisée.`,
    'ip-address-subnet-required': `L'adresse IP doit contenir un masque de sous-réseau.`,
    'ip-address-subnet-not-required': `Le masque de sous-réseau n'est pas obligatoire.`,
    'ip-address-overlap': `Le chevauchement d'adresses IP n'est pas autorisé dans l'interface.`,
    'network-broadcast-exception': `L'adresse est une adresse de diffusion ; il faut une adresse d'hôte.`,
    'network-address-exception': `L'adresse est une adresse réseau ; il faut une adresse d'hôte.`,
    'local-peer-ip-same-network': `L'adresse IP locale et l'adresse IP de pair ne figurent pas sur le même réseau IP.`,
    'peer-network-address': `L'adresse IP de pair ne peut pas être l'adresse réseau.`,
    'peer-broadcast-address': `L'adresse IP de pair ne peut pas être l'adresse de diffusion.`,
    'peer-same-local-address': `L'adresse IP de pair ne peut pas être la même que l'adresse IP locale.`,
    'ipv4-mismatch': `Les adresses IPv6 ne peuvent pas être ajoutées à la liste de filtres IPv4.`,
    'ipv6-mismatch': `Les adresses IPv4 ne peuvent pas être ajoutées à la liste de filtres IPv6.`,
    'number-required': `La valeur doit être un nombre.`,
    'range-min': `La plage doit commencer à partir de {min}.`,
    'range-max': `La plage doit se terminer à {max}.`,
    'range-min-exceed-max': `La valeur minimale ne peut pas être supérieure à la valeur maximale.`,
    'range-max-exceed-min': `La valeur maximale ne peut pas être inférieure à la valeur minimale.`,
    'range-min-exceeds-cidr': `La valeur minimale ne peut pas être inférieure à CIDR.`,
    'thing-between-mix-max': `{thing} doit être compris(e) entre {min} et {max}.`,
    'value-min': `La valeur minimale est {min}.`,
    'value-max': `La valeur maximale est {max}.`,
    'value-required': `Vous devez fournir une valeur.`,
    'value-integer': `La valeur doit être entière.`,
    'value-positive': `Valeur positive obligatoire`,
    'value-decimal-places': `Une valeur peut avoir {places} décimales.`,
    'broadcast-address': `Le saut suivant ne peut pas être une adresse de diffusion.`,
    'next-hop-same-ip-family': `Le saut suivant doit figurer dans la même famille d'adresses IP que le préfixe.`,
    'next-hop-same-network': `Le saut suivant doit figurer sur le même réseau que celui des adresses IP d'interface.`,
    'next-hop-not-interface-address': `Le saut suivant ne doit pas figurer dans la liste des adresses IP d'interface.`,
    'next-hop-network-address': `Le saut suivant est une adresse réseau. Il faut une adresse d'hôte.`,
    'bgp-password': `Le mot de passe peut contenir uniquement des caractères alphanumériques et spéciaux (!@#$%^&*+-=_).`,
    'bpg-password-length': `Le mot de passe doit contenir entre 0 et 25 caractères.`,
    'min-max-range': `Les valeurs doivent être comprises entre {mix} et {max}.`,
    'bpg-no-connections': `Connexions BGP introuvables`,
    'bfd-transmit': `L'intervalle de transmission doit être compris entre 300 et 9 000.`,
    'bfd-receive': `L'intervalle de réception doit être compris entre 300 et 9 000.`,
    'bfd-multiplier': `Multiplicateur minimal de 3 obligatoire`,
    'name-already-used': `Ce nom est actuellement utilisé.`,
    'address-family-required': `Famille d'adresses obligatoire`,
    'vlan-unique': `L'ID VLAN doit être unique et ne doit pas être supérieur à {max}`,
    'invalid-address-domain': `Veuillez entrer une adresse IP ou un domaine valide`,
    'vendor-image': `Veuillez sélectionner l'image du fournisseur`,
    'email-invalid-seperators': `Saisissez une seule adresse e-mail ou une liste d’adresses e-mail séparées par des virgules`,
    'no-approval-price-title': `Vous n’êtes pas autorisé à approuver des changements de prix.`,
    'no-approval-price-message': `Seuls les utilisateurs autorisés à commander peuvent effectuer des modifications affectant le prix d’un service.`,
    'enter-valid-auth-key': `Saisissez un/e {thing} valide.`,
    '128-chars': `La longueur doit être inférieure à 128 caractères.`,
    'min-number': `Doit contenir au moins 1 nombre | Doit contenir au moins {min} numéros`,
    'min-upper-case': `Doit contenir au moins 1 caractère majuscule | Doit contenir au moins {min} caractères majuscules`,
    'min-lower-case': `Doit contenir au moins 1 caractère minuscule | Doit contenir au moins {min} caractères minuscules`,
    'min-symbol': `Doit contenir au moins 1 caractère de symbole | Doit contenir au moins {min} caractères de symboles`,
    'as-path-prepend': `Saisissez un nombre AS Path Prepend (Ajout de chemin AS) compris entre 0 et 10.`,
    'api-keys-expiry': `Entrez un numéro de 5 à 1440`,
    'api-keys-expiry-decimal': `La valeur doit être entière.`,
    'min-max-alphanumeric': `Doit contenir {min} à {max} caractères alphanumériques`,
    'mfa-token': `Entrez votre jeton MFA`,
    vnic: `La sélection du plan vNIC est obligatoire.`,
    'bgp-password-valid-characters': `Les caractères valides sont : a-z A-Z 0-9 ! @ # ? . $ % ^ & * + = -`,
    'ix-peer-invalid-characters': `Lettres, chiffres, deux-points, soulignements et tirets uniquement`,
    'ix-peer-invalid-format': `Saisissez un ensemble d’ASN préfixés par AS et séparés par deux-points.`,
    'asn-validation-range-triple': `Plage ASN {minAsn}-{maxAsn}, {minAsn2}-{maxAsn2} ou {minAsn3}-{maxAsn3} ({defaultAsn} par défaut)`,
    'required-pattern': `La valeur doit suivre le modèle {thing}.`,
    'rfc-number-example': `Doit être composé de {total} caractères ({start} alphanumériques, puis 6 numériques, puis 3 alphanumériques).`,
    'idp-name-invalid-characters': `Le nom du fournisseur ne doit contenir que des lettres minuscules, des chiffres et des tirets.`,
    alphanumeric: `{thing} doit être composé uniquement de caractères alphanumériques.`,
    'reverse-dns-invalid-characters': `Les caractères valides sont : a-z A-Z 0-9 . -`,
    'invalid-field': `Saisissez un {thing} valide`,
  },
  connections: {
    'choose-type': `Sélectionnez un type de destination.`,
    cloud: `Cloud`,
    'internet-exchange': `Internet Exchange`,
    csp: `Connexion de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} à un fournisseur de services cloud`,
    private: `Connexion ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} à votre propre ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} ou ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'private-vxc': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} privé`,
    ix: `Se connecter à un ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    marketplace: `Connexion de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} à un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} partenaire`,
    'enter-service-key': `Saisir la clé de service`,
    'service-key': `Sélectionnez cette option si une clé de service ${process.env.VUE_APP_EN_COMPANYINFO_NAME} vous a été donnée.`,
    'select-destination-port': `Sélectionnez le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de destination.`,
    'awaiting-deployment': `La connexion est en attente de déploiement. Si le déploiement prend plus de temps que la normale, contactez l'Assistance ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'approval-error': `Erreur d'approbation de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'deny-error': `Erreur de refus de connexion de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'untag-vlan-tooltip': `Configurez ce service sans ID de VLAN. Cela limitera le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} à un seul service virtuel.`,
    'used-vlan': `Il existe déjà une connexion désignée sur ce ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} via ce VLAN. Si vous continuez, un VLAN vous sera attribué de manière aléatoire.`,
    'asn-from-mcr': `L'ASN utilise automatiquement un ASN ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.`,
    'azure-ports': `Faites votre choix parmi les ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} Azure disponibles.`,
    'select-destination-port-title': `Sélectionnez le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de destination.`,
    'no-available-target-ports': `Aucun ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} cible disponible.`,
    'please-select-port': `Veuillez sélectionner un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'no-available-ports-key': `Il n'existe aucun ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} disponible sur cette clé de service. Veuillez en essayer un autre.`,
    'all-ports-used-key': `Tous les ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} associés à cette clé de service ont été consommés par d'autres services. Veuillez en essayer un autre.`,
    'port-in-use-title': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} en cours d'utilisation`,
    'port-in-use-message': `Ce ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ne peut pas être sélectionné, car il est déjà utilisé.`,
    'google-ports': `Faites votre choix parmi les ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} Google disponibles.`,
    'no-google-services': `Il n'existe aucun service Google Cloud disponible dans la région du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} sélectionné.`,
    'no-google-ports': `Il n’existe aucun ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} disponible sur cette clé d’appariement. Veuillez en essayer un autre.`,
    'nutanix-ports': `Faites votre choix parmi les ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} Nutanix disponibles.`,
    'oracle-ports': `Faites votre choix parmi les ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} Oracle disponibles.`,
    'no-oracle-ports': `Il n’existe aucun ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} disponible sur ce circuit virtuel. Veuillez en essayer un autre.`,
    'all-oracle-ports-taken': `Tous les ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} de ce circuit virtuel ont été consommés par d'autres services. Essayez-en un autre.`,
    'list-all-oracle-ports': `Pour obtenir une liste de tous les ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} Oracle disponibles, consultez leur profil | ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}.`,
    'ix-details': `Détails de connexion du service ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'ix-asn-message': `Remarque : L'ASN ne peut pas être modifié pour les services ${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX} après le déploiement.`,
    'mcr-config': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} {end}-Fin des détails de la connexion`,
    'mcr-override-asn': `Remplacer l'ASN du ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'mcr-override-asn-tooltip': `L'ASN local peut être utilisé pour remplacer l'ASN du ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} pour ce pair BGP. Dans la plupart des cas, il doit être conservé comme la valeur par défaut.`,
    'mcr-setup-tooltip': `Pour chaque ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} connecté à ce ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}, vous pouvez configurer une ou plusieurs interfaces, adresses IP, connexions BGP ou routes statiques. La plupart des ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} utiliseront une seule interface ; cependant, vous pouvez en configurer plusieurs via « Q-in-Q » en sélectionnant une balise VLAN « interne » pour chaque interface. Le TPID sera défini sur 0x8 100.`,
    'mcr-ip-address-tooltip': `Adresse IP et masque de sous-réseau associé à configurer dans cette interface.<br /><br />Plusieurs adresses sont autorisées. IPv4 comme IPv6 sont pris en charge.<br /><br />Vous pouvez laisser ce champ vide pour différer la configuration de l'interface jusqu'à ce que le ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} soit provisionné.`,
    'aws-diversity-tooltip': `Commandez ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} dans des zones de couleurs différentes si vous souhaitez une diversité d'appareils.`,
    'no-matching-ports': `Aucun ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} cible correspondant`,
    'service-key-id': `ID de clé de service ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'service-key-unavailable': `La clé de service est pour le {product}source actif et ne peut pas être utilisée.`,
    'port-asn': `ASN du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'port-ip': `Adresse IP du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'aws-default-asn': `ASN de pair AWS par défaut. L'ASN sera remplacé si ce ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} est associé à une passerelle Direct Connect.`,
    'vxc-is-down': `Ce service ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}est inactif.`,
    'vxc-is-up': `Ce service ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}est actif.`,
    'vxc-status-unknown': `Le statut de ce service ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} est inconnu.`,
    'ix-is-down': `Ce service ${process.env.VUE_APP_EN_PRODUCTNAME_IX}est inactif.`,
    'ix-is-up': `Ce service ${process.env.VUE_APP_EN_PRODUCTNAME_IX}est actif.`,
    'ix-status-unknown': `Le statut de ce service ${process.env.VUE_APP_EN_PRODUCTNAME_IX} est inconnu.`,
    'speed-req-external-approval': `Remarque : Ce ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} appartient à une autre entreprise et tout changement de vitesse doit être approuvé par celle-ci.`,
    'ix-service': `Service ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'new-vxc': `Nouvelle connexion ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    'preferred-end-vlan': `VLAN {title} favori`,
    'amsix-contact-phone': `Un numéro de téléphone est obligatoire pour le {contactType} d'un service AMS-IX.`,
    'cancel-a-end': `Notez que ${process.env.VUE_APP_EN_COMPANYINFO_NAME} peut uniquement annuler la A-End de ce service. Pour éviter les frais continus de <b>{companyName}</b>, assurez-vous d'annuler votre service directement auprès de <b>{companyName}</b>.`,
    'no-matching-source-port': `Aucun ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} source correspondant`,
    'no-selected-source-port': `Sélectionnez le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} source.`,
    'non-edit-reason': `Remarque : Les détails de connexion ne sont pas modifiables, car | {reason}`,
    'pending-approval': `La connexion est en attente d'une approbation externe de {approvalFrom}.`,
    'pending-speed-approval': `La connexion est en attente d'une approbation externe de {approvalFrom} pour un changement de vitesse.`,
    'pending-speed-approval-to': `Le changement de débit maximal en attente sur {newSpeed} Mbits/s est en attente d'une approbation de {approvalFrom}.`,
    'connection-denied': `Le VXC vers {destination} a été rejeté.`,
    'connection-approved': `Le VXC vers {destination} a été approuvé.`,
    'peer-config': `Configuration du pair {type}`,
    'min-bfg-tx-interval': `L'intervalle de transmission minimal est de {min} ms.`,
    'min-bfg-rx-interval': `L'intervalle de réception minimal est de {min} ms.`,
    'min-bfg-multiplier': `Le multiplicateur minimal est de {min}.`,
    'key-expired': `Cette clé de service n'est plus valide. Contactez {company} pour résoudre ce problème.`,
    'key-used': `Cette clé de service à usage unique a déjà été utilisée. Contactez {company} pour résoudre ce problème.`,
    'key-invalid': `Cette clé de service n'est plus valide pour être utilisée. Contactez {company} pour résoudre ce problème.`,
    'ix-rate-limit-help': `Le débit maximal de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} est limité à la vitesse agrégée du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} pour les connexions en métropole et à 10 Gbits/s pour les connexions hors métropole.`,
    'vxc-rate-limit-help': `Le débit maximal d’un ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} dépend de la vitesse la plus faible à chaque extrémité et de la capacité du réseau sur le routage.`,
    'max-ip-addresses': `Maximum de {max} adresses IP`,
    'max-static-routes': `Maximum de {max} routes statiques`,
    'max-bgp-connections': `Maximum de {max} connexions BGP`,
    'mcr-end-visibility': `Cliquez pour {operation} les détails de la {end}-End${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.`,
    'mcr-end-details': `Détails de la {end}-End ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.`,
    'mcr-end': `{end}-End ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'missing-azure-service-key': `Il manque la clé de service au service Azure ExpressRouter. Pour commander ce service, fournissez la clé de service sur l'écran « Sélectionnez le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ».`,
    'missing-google-pairing-key': `Il manque la clé d'appariement à ce service Google. Pour commander ce service, fournissez la clé de service sur l'écran « Sélectionnez le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ».`,
    'new-bgp-state': `Les connexions BGP récemment créées seront {state}.`,
    'google-key-format': `Le format des clés Google est le suivant :`,
    'bgp-peer-asn': `ASN de pair`,
    'connection-details': `Détails de la connexion`,
    'connection-name': `Nom de connexion`,
    'vlan-fixed': `Remarque : Le VLAN ne peut pas être modifié pour ce type de service après le déploiement.`,
    'configure-azure-vlan': `Configurer le VLAN de peering Azure unique`,
    'azure-vlan': `VLAN de peering Azure unique`,
    'enter-vlan': `Saisissez le VLAN.`,
    'preferred-vlan': `VLAN favori`,
    asn: `ASN`,
    'asn-full': `Numéro de système autonome`,
    'mac-address': `Adresse MAC`,
    'mac-address-format': `Saisissez une adresse MAC dans l'un des formats suivants - 3c22.fb80.749c ou 3c:22:fb:80:74:9c`,
    'bgp-password': `Mot de passe BGP`,
    'peer-macro': `Macro de pair`,
    'graph-visibility': `Visibilité des graphiques`,
    untagged: `Non balisé`,
    shutdown: `Désactivé`,
    enabled: `Activé`,
    'end-not-configured': `{end}-La fin n’a pas encore été configurée.`,
    'interface-vlan': `VLAN d'interface`,
    'ip-addresses': `Adresses IP`,
    'static-routes': `Routes statiques`,
    'bgp-connections': `Connexions BGP`,
    'nat-ip-addresses': `Adresses IP NAT`,
    'b-vlan-service-key': `VLAN de B-End (d'une clé de service)`,
    'hide-cloud-details': `Cliquez pour masquer les détails cloud.`,
    'show-cloud-details': `Cliquez pour afficher les détails cloud.`,
    'cloud-details': `Détails cloud`,
    'peering-policy': `Politique de peering`,
    'policy-url': `URL de la politique`,
    'peering-email': `E-mail de peering`,
    'company-url': `URL de l’entreprise`,
    'company-email': `E-mail de l’entreprise`,
    'street-address': `Adresse postale`,
    city: `Ville`,
    'state-province': `État ou province`,
    'postcode-zip': `Code postal ou ZIP`,
    country: `Pays`,
    type: `Type`,
    'aws-connection-name': `Nom de la connexion AWS`,
    'aws-owner-acct-id': `ID de compte du propriétaire AWS`,
    'customer-ip': `Adresse IP du client`,
    'amazon-ip': `Adresse IP Amazon`,
    'alibaba-account': `ID de compte Alibaba`,
    'pairing-key': `Clé d’appariement`,
    'virtual-circuit-id': `ID de circuit virtuel`,
    deleted: `Connexion supprimée`,
    'select-destination': `Sélectionnez un destination.`,
    'configure-a-end': `Configurez une A-End.`,
    'configure-b-end': `Configurez une B-End.`,
    'vlan-value': `Valeur du VLAN`,
    save: `Enregistrer la connexion`,
    'approve-prompt': `Voulez-vous approuver cette connexion ?`,
    'approve-connection': `Approuver la connexion`,
    'deny-connection': `Rejeter la demande de connexion`,
    configuration: `Configuration de la connexion`,
    'awaiting-approval': `Cette connexion attend votre approbation.`,
    'approve-question': `Voulez-vous vraiment approuver cette demande ?`,
    'approve-title': `Approuver la demande`,
    'deny-question': `Voulez-vous vraiment rejeter cette demande ?`,
    'deny-title': `Rejeter la demande`,
    'cancel-question': `Voulez-vous vraiment annuler cette demande ?`,
    'cancel-title': `Annuler la demande`,
    'change-approved-title': `Changement de vitesse approuvé`,
    'change-approved-message': `Le nouveau débit maximal a été approuvé.`,
    'change-denied-title': `Changement de vitesse correctement rejeté`,
    'change-denied-message': `La demande de changement de débit maximal a été rejetée.`,
    'change-cancelled-title': `Changement de vitesse correctement annulé`,
    'change-cancelled-message': `La demande de changement de débit maximal a été annulée.`,
    'approved-title': `Connexion approuvée`,
    'denied-title': `Connexion correctement rejetée`,
    untag: `Annuler le balisage`,
    'auto-assigned': `Assigné automatiquement`,
    'auto-assign': `Assigner automatiquement le VLAN`,
    'vlan-checking': `Vérification de la disponibilité du VLAN...`,
    'add-connection': `Ajouter une connexion`,
    open: `Ouvert`,
    closed: `Fermé`,
    vlan: `VLAN`,
    'valid-service-key': `Clé de service valide`,
    'verifying-key': `Vérification de la clé`,
    'select-provider': `Sélectionner un fournisseur`,
    'no-matching-providers': `Aucun fournisseur correspondant`,
    'select-connection-type': `Sélectionner un type de connexion`,
    'bgp-statuses': `Statuts BGP`,
    'end-vlan': `VLAN de {end}-End`,
    'route-servers': `Serveurs de routage`,
    'bgp-down': `BGP désactivé`,
    'bgp-up': `BGP activé`,
    'bgp-unknown': `Le statut du BGP est inconnu.`,
    interface: `Interface`,
    'old-speed': `Ancienne vitesse`,
    'new-speed': `Nouvelle vitesse`,
    'approve-speed-question': `Voulez-vous approuver ce changement de vitesse ?`,
    'deny-speed-change': `Rejeter la demande de changement de vitesse`,
    'approve-speed-change': `Approuver le changement de vitesse`,
    'cancel-speed-request': `Annuler la demande de changement de vitesse`,
    'connections-html': `Pour plus de détails, voir {link}`,
    'speed-change-pending-your-approval': `En attente de votre approbation : Un changement de vitesse a été demandé pour cette connexion.`,
    'speed-change-pending-company-approval': `En attente d'approbation de l'entreprise : Un changement de vitesse a été demandé pour cette connexion (nécessite l'approbation de votre entreprise).`,
    connection: `Connexion`,
    'lag-full': `Ce ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ne peut pas accepter de nouvelle connexion.`,
    'port-full': `Ce ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ne peut pas accepter de nouvelle connexion.`,
    'show-connections': `Cliquez pour afficher les connexions.`,
    'hide-connections': `Cliquez pour masquer les connexions.`,
    'service-key-vlan-range': `VLAN 2-4094 (désactivé pour les clés de service à usage multiple)`,
    'third-party-owner': `Propriétaire tiers`,
    'b-end': `Vous vous trouvez à la B-End de cette connexion appartenant à une autre entreprise.`,
    'shut-down-by-user': `Désactivé par l'utilisateur`,
    'b-end-no-delete': ` L'extrémité de la connexion ne peut pas supprimer la connexion.`,
    'speed-change-from': `Changement de vitesse de`,
    'new-connection-from': `Nouvelle connexion de`,
    'best-route-explanation': `Une coche indique que ce ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} préfère cette route lors du choix entre deux routes vers la même destination.`,
    'as-path': `Chemin d'accès AS`,
    'local-preference': `Préférence locale`,
    med: `Multi-Exit Discriminator`,
    metric: `Métrique`,
    protocol: `Protocole`,
    distance: `Distance`,
    'gateway-ipv4': `Adresse IP de la passerelle (IPv4)`,
    'gateway-ipv6': `Adresse IP de la passerelle (IPv6)`,
    'match-label': `Faire correspondre`,
    'dont-match-label': `Ne pas faire correspondre`,
    'delete-route-label': `Supprimer la route`,
    'connected-port-removed': `Ce ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ou ce ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} auquel ce service était connecté a été supprimé des données. Redirection vers les services`,
    approving: `approbation`,
    denying: `refus`,
    'approval-issues': `Problèmes {action} ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}: {errorStr}`,
    'confirm-bgp-state-change': `Cela change l'état BGP en {newState} et valide toutes les modifications non enregistrées apportées à ce service. La prise en compte des modifications peut prendre jusqu’à deux minutes.`,
    'bgp-state-change-prompt': `Confirmer le changement de la modification de l'état BGP ?`,
    'awaiting-approval-or-denial': `En attente de votre approbation/refus de cette connexion`,
    'azure-key-unused-key': `Disponible à cet emplacement`,
    'azure-key-used-key': `Peut être utilisé plusieurs fois en spécifiant un VLAN de peering Azure unique`,
    'azure-key-unused': `Ce ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} n’a pas été utilisé avec cette clé de service`,
    'azure-key-used': `Ce ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} peut être utilisé plusieurs fois en spécifiant un VLAN de peering Azure unique`,
    'outscale-connection-name': `Nom de la connexion Outscale`,
    'outscale-account': `ID du compte propriétaire Outscale`,
    'outscale-ip': `Adresse IP Outscale`,
    transit: `Accès à la passerelle de transit via une connexion fournie par ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'vxc-name-default': `{aEnd} à {bEnd}`,
    'customer-asn': `ASN du client`,
    vnic: `vNIC`,
    'reverse-dns': `DNS inversé`,
    'virtual-interfaces-vnics': `Interfaces virtuelles (vNIC)`,
    'vnic-plane': `vNIC-{index} {plane} Plan`,
    'end-vnic': `{end}-vNIC de fin`,
    'create-ip-transit-vxc': `Créer ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}`,
    'mcr-full': `Ce ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} ne peut accepter aucune nouvelle connexion.`,
    'mve-full': `Ce ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} ne peut accepter aucune nouvelle connexion.`,
    'provider-ip': `Adresse IP du fournisseur`,
    'ibm-account': `ID du compte IBM Cloud`,
    'mve-untag-vlan-tooltip': `Configurer ce service sans ID VLAN.`,
    'no-transit-port-title': `Échec de la localisation du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} pour ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}`,
    'no-transit-port-message': `${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} introuvable. Contactez ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'a-end-vnic-help': `Interface virtuelle du service d’initiation`,
    'b-end-vnic-help': `Interface virtuelle du service d’arrêt`,
    'no-description': `Pas de description`,
    'select-connections': `Sélectionner une ou plusieurs connexions`,
    'move-connection': `Déplacer la connexion`,
    'select-new-end': `Sélectionner une nouvelle extrémité`,
    'move-connection-confirm': `Confirmez que vous souhaitez poursuivre et déplacer la ou les connexions sélectionnées de {currentEnd} vers {newEnd}.`,
    'move-connection-diversity': `Remarque : La zone de diversité passe de {currentDiv} à {newDiv}. Cela peut affecter votre nouvelle diversité.`,
    'move-connection-disclaimer': `Pendant le déplacement, la ou les connexions ne seront pas disponibles. Le déplacement peut prendre jusqu’à 2 minutes.`,
    'no-valid-connections': `Connexions valides introuvables`,
    'no-valid-destinations': `Destinations valides introuvables`,
    'invalid-move-existing-end': `La cible {type} est déjà associée à la connexion sélectionnée.`,
    'invalid-move-multiple-vnics': `La cible {type} a plusieurs vNIC.`,
    'invalid-move-too-small': `Capacité insuffisante sur la cible {type}`,
    'invalid-move-new-untagged': `La cible {type} ne peut pas accepter de connexion non balisée.`,
    'invalid-move-untagged': `La cible {type} a une connexion non balisée.`,
    'invalid-move-vlan-taken': `VLAN non disponible sur la cible {type}`,
    'search-for-providers': `Voir plus de fournisseurs`,
    'termed-delete-etf': `Des frais de résiliation anticipée (ETF) s’appliquent lors de la suppression d’une connexion d’une durée limitée conformément aux conditions de votre accord avec Megaport.`,
    'avoid-etf': `Pour éviter les ETF, sélectionnez « Aucune durée minimale » jusqu’à ce que les détails de la configuration soient corrects. Vous pouvez mettre à jour la connexion à tout moment pour activer une durée.`,
    'rate-limit-change-disallowed': `Il s’agit d’une connexion d’une durée limitée ; les modifications de limite de débit ne sont pas autorisées, sauf si la durée d’activation de la connexion est redéfinie et si la limite de débit est augmentée.`,
    'rate-limit-change-term': `Il s’agit d’une connexion d’une durée limitée ; la limite de débit ne peut pas être réduite en dessous de {termSpeed} Mbits/s, la vitesse sélectionnée au moment de l’activation de la durée.`,
    'private-vxc-no-valid-target': `Vous ne disposez d’aucun service valide pour établir une connexion privée à`,
    'vnic-details': `Détails vNIC`,
    'new-vnic-details': `Détails nouvelle vNIC`,
    'new-end-vnic': `Nouvelle fin {end} vNIC`,
    'new-preferred-end-vlan': `Nouveau VLAN {title} favori`,
    'move-connection-vnic-warning': `Remarque : La vNIC et le VLAN sur la destination ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} changeront également en fonction des valeurs spécifiées.`,
    'confirm-move': `Confirmer le déplacement`,
    'speed-req-external-approval-a-end': `Remarque : Ce ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} appartient à une autre entreprise et vous n’êtes pas autorisé(e) à modifier la vitesse de leurs ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'speed-req-external-approval-b-end': ` Remarque : Ce ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} est connecté à une autre entreprise et tout changement de vitesse doit également être approuvé par elle.`,
  },
  ports: {
    'port-deleted': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} supprimé`,
    'select-port': `Sélectionner le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'port-speed': `Vitesse du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'select-port-speed': `Sélectionner la vitesse du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'mcr-explanation': `Un ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MCR}) est une instance de routage virtuelle de couche 3 fournie et hébergée par ${process.env.VUE_APP_EN_COMPANYINFO_NAME} à des emplacements de points d'accès cloud dans le monde entier. Il ne nécessite pas de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} physique, mais vous pouvez vous y connecter, si vous le souhaitez.`,
    'port-main-tooltip': `Un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} physique est obligatoire si vous connectez votre équipement au nôtre. Nous prenons en charge les interfaces de fibre 1G, 10G ou 100G (généralement SMOF duplex - vérifiez votre LOA récupérée lors du déploiement du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} pour connaître les conditions spécifiques au site) aux emplacements connectés ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'diverse-from-existing': `Diversifié par rapport au ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} existant`,
    'ports-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'ports-type-count': `0 {type} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} | 1 {type} ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | {count} {type} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'pluralize-ports': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} | ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'services-attached-count': `0 service associé | 1 service associé | {count} services associés`,
    'percent-allocated': `{percent} % alloué`,
    'awaiting-resources': `Ce {type} est en attente d'allocation de ressource.`,
    'add-connection-to': `Ajoutez une nouvelle connexion à ce {type}.`,
    'no-mcr-speed-change': `Le débit maximal du ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} est fixé pour la durée de vie du service.`,
    'lacp-ports': `LACP & ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'lacp-on-port': `LACP sur ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'add-to-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} à ajouter à ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'ports-in-lag': `Nombre de ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} dans ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'mcr-asn': `ASN du ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'summary-adding-to-lag': `Ajout de ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} à ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'summary-ports-in-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} dans ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'no-slow-ports-in-lag': `Aucune vitesse du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} < 10 Gbits/s dans ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'mcr-rate-limit': `Débit maximal du ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'allocated-tooltip': `Vitesse du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} et débit maximal cumulatif total de ses services`,
    'port-removed': `Le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} a été supprimé des données. Redirection vers les services`,
    'contract-terms': `Les conditions du contrat s'afficheront lorsque le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} sera actif.`,
    statuses: `Statuts du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'configure-details': `Configurez les détails des ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ajoutés.`,
    'add-ports-to-lag': `Ajoutez de nouveaux ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} à ce ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'lag-full': `Ce ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ne peut plus accepter de ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}.`,
    'max-speed-tooltip': `Débit maximal en Mbits/s. Doit être inférieur ou égal à {speed}. Laisser cette valeur vide limitera le {productType} à la vitesse maximale disponible.`,
    'vxc-speed-positive-integer': `La vitesse du ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} doit être un entier positif.`,
    'vxc-speed-max': `La vitesse maximale du ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} est de {speed} Mbits/s`,
    'lag-primary-deletion-locked': `Le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} principal de ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ne peut pas être supprimé s'il existe des ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} verrouillés dans ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'deletion-insufficient-capacity': `La suppression de ce ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} laisserait une capacité insuffisante pour prendre en charge les connexions existantes sur ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'link-aggregation': `Agrégation de liens pour {portName}`,
    'attached-vxc-locked': `Le ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} associé est verrouillé.`,
    'attached-ix-locked': `Le ${process.env.VUE_APP_EN_PRODUCTNAME_IX} associé est verrouillé.`,
    'terminate-date': `Ce service prendra fin le {date}.`,
    'autoconfig-mcr': `La configuration de la connexion du ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} sera automatiquement générée. Une fois le service actif, vous pourrez modifier la configuration.`,
    'count-port-lag': `- | (1 ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}) | ({count} ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS})`,
    'live-lag-disclaimer': `Remarque : Lors de l'ajout de ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} à un ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} actif, le nouveau ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ou les nouveaux${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} sont automatiquement au même emplacement, à la même vitesse et à la même visibilité ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} que le reste du ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'lag-explanation': `Crée ce ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} avec le protocole LACP activé afin qu'il puisse être utilisé sur un ${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_LAG}) et vous permet de spécifier le nombre de ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} à inclure dans votre groupe de ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'add-lag-ports-disclaimer': `Cela ajoutera plusieurs ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} sur ce ${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_LAG}). La tarification affichée s'applique à tous les ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} ajoutés au ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'port-diversity': `Diversité du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'diversity-unavailable-location': `La diversité n'est actuellement pas disponible pour cette configuration. Contactez <a href="mailto:${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}">${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}</a> pour discuter de vos besoins en diversité.`,
    'diversity-explanation-html': `La diversité du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} permet à des ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} au même emplacement de figurer dans des zones de diversité différentes. La création de ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} redondants dans des zones diversifiées réduit le risque d’interruption de service due à un événement impactant un seul appareil. Pour plus de détails, voir {link}`,
    'select-lag-speed': `La vitesse minimale du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} pour activer LACP est de 10 Gbits/s.`,
    'select-speed-diversity': `Sélectionnez une vitesse de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} pour afficher les options de diversité.`,
    'diversity-lag-add': `- | Le nouveau ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} sera ajouté à la zone de diversité du ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}. | Les nouveaux ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} seront ajoutés à la zone de diversité du ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'diverse-pair': `Deux {things} seront configurés et ajoutés aux différentes zones de diversité.`,
    'diverse-from': `Diversifié par rapport au : {port}`,
    'port-diverse-from': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} doit être diversifié par rapport à`,
    'first-port-name': `Nom du {thing}`,
    'second-port-name': `Nom du deuxième {thing}`,
    'delete-diverse-from': `- | Le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} suivant est configuré pour être diversifié par rapport au ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} auquel vous mettez fin : | Les ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} suivants sont configurés pour être diversifiés par rapport au ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} auquel vous mettez fin :`,
    'reset-diversity': `- | Si vous mettez fin au ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, le ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} perdra son paramètre de diversité et n'aura plus de zone de diversité spécifiée. | Si vous mettez fin au ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, les ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} répertoriés perdront leur paramètre de diversité et n'auront plus de zone de diversité spécifiée.`,
    'mcr-prefix-filter': `La fonction de filtre de préfixe ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} prend en charge les filtres de routage BGP basés sur des préfixes entrants et sortants. <br/><br/> <strong>Vous pouvez ajouter et configurer des listes de préfixes lorsque votre service est configuré.</strong>`,
    'bgp-default-state-tooltip': `Décidez si les connexions BGP récemment créées sont activées ou désactivées par défaut. Sélectionnez Activé (valeur par défaut) si vous souhaitez que toutes les nouvelles sessions BGP que vous configurez soient actives dès que vous enregistrez la configuration. Sélectionnez Désactivé si vous voulez que toutes les nouvelles sessions BGP que vous configurez restent à l’état désactivé lorsque vous enregistrez la configuration.`,
    'mve-software': `Logiciel ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'new-prefix-filter-list': `Nouveau`,
    'open-port': `Ouvrir ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'prefix-filter-list': `Listes de filtres de préfixes`,
    'prefix-list-learn-more': `En savoir plus sur les listes de filtres de préfixes`,
    'prefix-list-loaded-success': `Chargement correct de la liste de préfixes sélectionnée`,
    'prefix-list-loaded-fail': `Échec de chargement de la liste de préfixes sélectionnée`,
    'lacp-enabled': `LACP activé`,
    'diverse-pair-label': `Pair diversifié`,
    'diverse-from-label': `Diversifié par rapport à`,
    'download-loa': `Télécharger une LOA`,
    failed: `Échec de mise à jour de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    vendor: `Fournisseur`,
    'mve-file-upload': `Chargement de fichier`,
    'cloud-init-vmanage': `Générez et chargez un fichier init cloud depuis votre système vManage`,
    'mve-init': `Fichier d'initialisation`,
    waiting: `En attente de l'interconnexion.`,
    'key-valid-until': `Valide jusqu'au`,
    'service-keys': `Clés de service`,
    'lag-up': `Ce ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} est actif.`,
    'lag-down': `Ce ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} est inactif.`,
    'key-saved': `Clé de service correctement enregistrée.`,
    'key-added': `Clé de service correctement ajoutée.`,
    'key-saved-message': `La clé de service [{description}] a été enregistrée.`,
    'key-add-error': `Erreur de création de la clé de service.`,
    'key-update-error': `Erreur de mise à jour de la clé de service.`,
    'minimum-term': `Durée minimale`,
    // La section cannotBeEdited est en gras, elle fait référence à la chaîne cannot-edit.
    'uneditable-asn-html': `Vous pouvez configurer le numéro de système autonome (Autonomous System Number - ASN) de ce ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} ou utiliser l’ASN public fourni par ${process.env.VUE_APP_EN_COMPANYINFO_NAME} {defaultAsn}. L’ASN sera utilisé pour les sessions de peering BGP sur toutes les ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} connectées à ce ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}. Il {cannotBeEdited} une fois le ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} commandé. Notez que certains services de cloud public nécessitent l’utilisation d’un ASN public. Avant de remplacer cette valeur par défaut, consultez la documentation relative à votre fournisseur de cloud.`,
    'bgp-default-state': `État par défaut du BGP`,
    'site-deployment': `Cet emplacement va bientôt S'ACTIVER. Précommandez maintenant pour recevoir votre LOA dès qu'elle devient disponible. Pour plus d'informations ou pour en discuter davantage, contactez votre gestionnaire de compte.`,
    terms: `Durées du contrat`,
    'end-date-display': `La date de fin du contrat sera affichée une fois le {productName} actif`,
    'change-term': `Modifier la durée du contrat`,
    'term-info': `En savoir plus sur les conditions de contrat {productName}`,
    monthly: `Mois à l'autre`,
    'existing-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_LAG} existant`,
    'thing-enabled': `{thing} activé(e)`,
    'thing-disabled': `{thing} désactivé(e)`,
    'address-family-label': `Famille d'adresses`,
    'local-ip-label': `IP locale`,
    'peer-ip-label': `IP pair`,
    'local-asn-label': `ASN local`,
    'bgp-state-label': `État BGP`,
    'bgp-peer-filter-label': `Filtre pair BGP`,
    'bgp-prefix-filter-import-label': `Importer un filtre de préfixe`,
    'bgp-prefix-filter-export-label': `Exporter un filtre de préfixe`,
    'bgp-confirm-delete': `Cette adresse IP est actuellement utilisée dans les connexions BGP ou les adresses IP NAT. Sa suppression supprimera ces configurations. Continuer ?`,
    'permit-list-label': `Autoriser la liste`,
    'deny-list-label': `Rejeter la liste`,
    'new-list-label': `Nouvelle liste`,
    'duplicate-list-label': `Dupliquer la liste`,
    'delete-list-label': `Supprimer la liste`,
    'delete-list-confirm': `Voulez-vous vraiment supprimer cette liste ?`,
    'filter-advertise-default-label': `Annoncer par défaut`,
    'filter-advertise-exception-label': `Annoncer par exception`,
    'bfd-switch-label': `Utiliser BFD`,
    'bfd-label': `BFD - Bidirectional Forwarding Detection`,
    'inbound-label': `Entrant`,
    'outbound-label': `Sortant`,
    'med-label': `MED (Métrique) - Multi-Exit Discriminator`,
    'bgp-prefix-filter-label': `Filtre de préfixe BGP`,
    'bgp-connection-filter-label': `Filtre de connexion BGP`,
    'no-connection-found': `Connexions {thing} introuvables`,
    'address-placeholder': `Saisissez une adresse IP (IPv4/IPv6).`,
    'network-address-placeholder': `Saisissez une adresse {thing} et un CIDR.`,
    'subnet-mask-range': `Plage de masques de sous-réseau`,
    'select-address': `Sélectionner une adresse`,
    'prefix-list-label': `Liste de préfixes`,
    'mve-explanation': `Un ${process.env.VUE_APP_EN_PRODUCTNAME_MVE_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MVE}) est une instance BYOL (Bring Your Own License - Apportez votre propre licence) d’une passerelle SD-WAN Partenaires fournie et hébergée par ${process.env.VUE_APP_EN_COMPANYINFO_NAME} à côté d’emplacements de points d’accès cloud dans le monde entier. Il permet un accès contrôlé aux ressources du réseau ${process.env.VUE_APP_EN_COMPANYINFO_NAME} via Internet. Il ne nécessite pas de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} physique, mais peut s’y connecter, si vous le souhaitez. L’ensemble des licences et de la gestion sont gérées par des solutions Partenaires.`,
    'ssh-key': `Clé SSH`,
    'ssh-key-rsa-help': `Clé publique SSH RSA 2 048 bits`,
    'mve-appliance-licence': `Licence d'appliance`,
    'mve-activation-code': `Code d'activation`,
    'mve-address': `Adresse de l’Orchestrateur`,
    // FQDN - Nom de domaine complet
    'mve-address-placeholder': `IPv4/IPv6 ou FQDN`,
    'mve-select-vendor': `Sélectionner un fournisseur/produit`,
    'mve-cloud-init-file-upload': `Fichier dans le Cloud`,
    'mve-cloud-init-file-upload-help': `Reportez-vous à la documentation du produit {title} pour générer un fichier cloud-init.`,
    'mve-appliance-licence-help': `Licence d'appliance`,
    'key-valid': `Valide`,
    'prefix-list-count': `{count} liste | {count} listes`,
    'mve-director-address': `Adresse Director`,
    'mve-controller-address': `Adresse du contrôleur`,
    'mve-local-auth': `Auth locale`,
    'mve-local-auth-help': `E-mail ou chaîne d’ID local(e)`,
    'mve-remote-auth': `Auth à distance`,
    'mve-remote-auth-help': `E-mail ou chaîne d’ID à distance`,
    'serial-number': `Numéro de série`,
    'prefix-list-save-confirm': `Voulez-vous enregistrer les modifications apportées à la liste de préfixes ?`,
    'how-to-create-port-text': `comment créer un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'how-to-create-port-paragraph': `Pour obtenir des instructions sur {0}, consultez la documentation ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'aruba-orchestrator-name': `Nom du compte Orchestrator`,
    'aruba-orchestrator-name-tt': `Nom de compte associé à l’instance Aruba Orchestrator`,
    'aruba-account-key': `Clé de compte`,
    'aruba-account-key-tt': `Clé de compte associée au nom de compte ci-dessus`,
    'aruba-system-tag': `Balise système`,
    'aruba-system-tag-tt': `Utilisé lors de l’enregistrement auprès de Aruba Cloud Portal et de Aruba Orchestrator`,
    'as-path-prepend-label': `AS Path Prepend (Ajout de chemin AS)`,
    'unbundled-mve-size-info': `Cette taille comprendra {cpuCount} vCPU, {ram} Go de RAM et {storage} Go de stockage.`,
    'mve-appliance-mode': `Mode d’appliance`,
    'mve-appliance-mode-tooltip': `<p>Le mode d’appliance détermine la façon dont vous configurez et gérez votre déploiement.</p>
      <ul>
      <li><strong>Mode autonome</strong> : via l’interface de ligne de commande (CLI)</li>
      <li><strong>Mode SD-WAN</strong> : via vManage</li>
      </ul>
      <p>${process.env.VUE_APP_EN_COMPANYINFO_NAME} ne prend pas en charge la modification des modes d’appliance après le déploiement - cela peut entraîner une perte de configuration.</p>
      `,
    'mve-license-data': `Données de licence`,
    'mve-admin-password': `Mot de passe administrateur`,
    'mve-admin-password-tooltip': `<p>Pour pouvoir accéder à l’appareil virtuel, vous devrez créer un mot de passe administrateur. Le mot de passe administrateur doit contenir :</p>
      <ul>
      <li>un minimum de huit caractères</li>
      <li>au moins une lettre majuscule</li>
      <li>au moins une lettre minuscule</li>
      <li>au moins un chiffre</li>
      <li>au moins un symbole</li>
      </ul>
      <p>Conservez le mot de passe administrateur en toute sécurité et ne le partagez avec personne. Si nécessaire, le mot de passe administrateur peut être réinitialisé lors de la première connexion à l’appareil configuré.</p>
      <p><strong>REMARQUE</strong> : ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ne conserve pas les mots de passe des clients.</p>
      `,
    'mve-vnic-prompt-info': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} vous invitera à commander un ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} pour le démarrage initial et la configuration une fois que la configuration de base de votre ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} sera terminée.`,
    'mve-vendor-vnic-count': `{vendor} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} ont un vNIC. | {vendor} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} ont deux vNIC (vNIC 0 et vNIC 1). | {vendor} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} ont plusieurs vNIC.`,
    'mve-ip-transit-vxc-prompt-success': `Vous avez correctement configuré le composant de calcul de votre {vendorName} ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}, qui comprend l’UC, la RAM et le stockage.`,
    'mve-ip-transit-vxc-prompt-recommend': `Pour terminer la configuration de votre {vendorName} ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}, nous vous recommandons de créer une connexion ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}.`,
    'mve-ip-transit-vxc-prompt-docs': `Pour plus d’informations, reportez-vous à notre {link}.`,
    'mcr-prefix-filter-support': `La fonction de filtre de préfixe ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} prend en charge les filtres de routage BGP basés sur des préfixes entrants et sortants.`,
    'mcr-prefix-filter-configure': `Lorsque votre service est configuré, vous pouvez ajouter et configurer des listes de préfixes.`,
    'cannot-edit': `ne peut pas être modifié`,
    'move-connections': `Déplacer des connexions`,
    'megaport-internet-restricted-location-reason': `Vous avez un(e) ou plusieurs ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} associé(e)s à ce {productType}. Ce {productType} et ses ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} associés doivent être configurés dans la même région.`,
    'megaport-internet-restricted-location-workaround': `Si vous souhaitez configurer votre {productType} dans une autre région, commencez par supprimer ses ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} associé(e)s.`,
    'mve-manage-locally': `Gérer localement`,
    'mve-manage-locally-tooltip': `Indique si cette instance ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} appelle localement le Centre de gestion des pare-feu ou non.`,
    'mve-fmc-ip-address': `Adresse IP FMC`,
    'mve-fmc-ip-address-tooltip': `Adresse réseau Cisco Secure FMC. Il peut s’agir de l’un des éléments suivants : nom d’hôte, adresse IPv4 ou IPv6.`,
    'mve-fmc-registration-key': `Clé d’enregistrement FMC`,
    'mve-nat-id': `ID NAT`,
    'mve-nat-id-tooltip': `Fournissez l’ID NAT si le Contrôleur de gestion des pare-feu n’est pas directement adressable.`,
    'mve-ion-key': `Clé ION`,
    'mve-secret-key': `Clé secrète`,
    'unbundled-mve-size-info-no-storage': `Cette taille inclura {cpuCount} vCPU et {ram} Go de RAM.`,
    'service-key-name': `Clés de service {name}`,
    'mve-cisco-vmanage': `vManage`,
    'mve-aviatrix-secure-edge': `Aviatrix Secure Edge`,
    'mve-select-version': `Sélectionner une version`,
    'mve-version': `Version {version}`,
    'mve-service-details': `Détails du service {vendor}`,
  },
  'port-term': {
    'funky-1': `La durée de ce service ne peut pas être automatiquement mise à jour.`,
    'funky-2': `Veuillez contacter votre représentant commercial ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    title: `Fixer la durée du contrat du {productType}`,
    choose: `Sélectionner la durée du contrat du {productType}`,
    'update-failure': `Échec de mise à jour de la durée du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'update-error': `Une erreur s'est produite lors de la tentative de mise à jour de la durée du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'update-unavailable': `La durée du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} sélectionnée n'est pas disponible.`,
    'update-success': `Durées du {productType} mises à jour`,
    'reterm-success': `- | Fixer la durée du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} « {portName} » à 1 mois | Fixer la durée du port ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} « {portName} » à {count} mois`,
    reterm: `La nouvelle durée du contrat de ce service (la Durée minimale) prend effet immédiatement et la remise de la nouvelle durée sera appliquée à la facture du mois civil suivant. Chaque arrête de la Durée minimale entraînera des frais de résiliation anticipée, conformément au contrat passé entre nous.`,
    'confirm-new-term': `Démarrer la nouvelle durée du contrat`,
    'no-change': `Aucune modification`,
    'funky-3': `Veuillez contacter votre partenaire ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'no-minimum-term': `Pas de durée minimale`,
    'minimum-term-tooltip-ports': `La durée minimale commencera une fois le service activé ou 14 jours après la configuration du service`,
    'minimum-term-tooltip': `La durée minimale commence le jour de la commande`,
    'reterm-immediate': `La nouvelle durée du contrat (la Durée minimale) est effective immédiatement. La durée minimale commencera le {start} et se terminera le {end}. Chaque arrête de la Durée minimale entraînera des frais de résiliation anticipée, conformément au contrat passé entre nous.`,
    'option-price': `- | Mensuellement : {cost} par mois | {count} Mois : {cost} par mois`,
  },
  dashboard: {
    navigation: `Découvrez les bases de la navigation au sein du ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL} et découvrez comment créer votre premier ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'visit-marketplace': `Rendez-vous sur ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}.`,
    'deep-dive': `Découvrez nos services et comment établir une mise en réseau, une connectivité cloud et des solutions ${process.env.VUE_APP_EN_PRODUCTNAME_IX} grâce à ces documents et des articles techniques.`,
    'ql-overview': `Introduction à ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'ql-vxc': `Comment configurer une connexion avec un ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    'ql-cloud': `Connexion à des fournisseurs de services cloud`,
    'ql-mcr': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} - Routage virtuel pour la connectivité privée de couche 3`,
    'marketplace-marketing': `Hub en ligne au sein duquel nos partenaires fournisseurs de services et entreprises clientes se rassemblent et partagent des services. Sur ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}, vous pouvez découvrir de nouvelles ressources et être découvert par de nouveaux clients.`,
    'marketplace-image': `Cliquez pour sélectionner une image pour le profil ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT} de votre entreprise.`,
    'get-started': `Démarrer`,
    'quick-links': `Liens rapides :`,
    documentation: `Documentation ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    video: `Visionner la vidéo`,
    'video-error': `Impossible de lire la vidéo`,
    'video-error-message': `Il semblerait que la lecture de vidéos ait été bloquée sur votre navigateur. Cela a été signalé à l'équipe de développement à des fins d'enquête. Veuillez nous excuser pour la gêne occasionnée.`,
  },
  menu: {
    dashboard: `Tableau de bord`,
    company: `Entreprise`,
    'ix-looking-glass': `Looking glass ${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX}`,
    'ix-telemetry': `Télémétrie ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'marketplace-profile': `Profil ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'mcr-looking-glass': `Looking glass ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'more-events': `Davantage d'événements sont disponibles :`,
    'company-profile': `Profil d'entreprise`,
    'billing-markets': `Pays de facturation`,
    'manage-users': `Gérer les utilisateurs`,
    tools: `Outils`,
    'api-documentation': `Documentation API`,
    'api-key-generator': `Générateur de clé API`,
    'event-log': `Journal des événements`,
    'view-event-log': `Afficher le journal des événements`,
    events: `Événements`,
    'bug-report': `Envoyer un rapport de bogues`,
    'user-profile': `Profil d'utilisateur`,
    'notification-settings': `Paramètres de notification`,
    logout: `Se déconnecter`,
    'partner-admin': `Administrateur partenaire`,
    'company-security-settings': `Paramètres de sécurité`,
    'technical-support-details': `Détails de l’assistance technique`,
    'account-contacts': `Contacts du compte`,
    entitlements: `Droits de l’entreprise`,
    feedback: `Envoyer des commentaires`,
  },
  marketplace: {
    'pluralize-provider-type': `Types de fournisseur | Type de fournisseur | Types de fournisseur`,
    'pluralize-locations': `Emplacements | Emplacement | Emplacements`,
    profile: `Profil ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'currently-private': `Votre profil ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} est actuellement privé, ce qui signifie que notre réseau de clients mondial ne peut pas vous trouver ni se connecter à vos services.`,
    'no-profile': `Vous ne disposez actuellement d'aucune configuration de profil ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}.`,
    visible: `Votre profil sera visible sur ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}.`,
    hidden: `Votre profil sera masqué aux autres sur ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}.`,
    services: `Services ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'create-vxc': `Créer un ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} pour ce ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'display-name': `Nom d'affichage ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'contact-company': `Contacter {company}`,
    'email-sent-to': `Votre e-mail a été envoyé à {recipient}.`,
    'not-ready-publishing': `Il semblerait que votre profil ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} ne soit pas prêt à être publié. Cliquez sur Créer un profil pour finaliser votre page, afin que notre réseau de clients mondial puisse vous trouver et se connecter à vos services.`,
    'easy-get-connected': `Il est facile de se connecter. Cliquez sur Modifier le profil et mettez-le à disposition sur notre ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}.`,
    'marketplace-intro': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} connecte votre entreprise à notre réseau de clients mondial. Cette plate-forme vous permet d'accéder à de nouveaux marchés pour détecter des flux de revenus auparavant inaccessibles. Le fait de compléter votre profil et de le maintenir à jour améliore considérablement votre potentiel commercial et l'exposition de votre marque à des clients prêts à acheter.`,
    'duplicate-name-warning': `Cela peut entraîner une confusion chez les personnes qui souhaitent se connecter à vos ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}.<br/><br/>Voulez-vous vraiment enregistrer ?`,
    sending: `Envoi du message...`,
    'general-info': `Informations générales du service`,
    'send-copy': `M'envoyer une copie du message`,
    message: `Saisissez votre message.`,
    sent: `Message envoyé`,
    'send-error': `Erreur d'envoi de votre message`,
    target: `Cible de connexion`,
    'connection-from': `Connexion depuis`,
    filter: `Filtrer par type de fournisseur`,
    'select-types': `Sélectionner les types et sous-types requis`,
    'clear-filter': `Effacer le filtre`,
    'hide-message': `Masquer ce message`,
    'hide-today': `Juste aujourd'hui`,
    'hide-week': `Pendant une semaine`,
    'hide-forever': `En permanence`,
    'edit-profile': `Modifier le profil`,
    'save-profile': `Enregistrer le profil`,
    'create-profile': `Créer un profil`,
    'sort-alpha': `A - Z`,
    'sort-location-count': `Nombre d'emplacements`,
    'grid-display': `Affichage de la grille`,
    'list-display': `Affichage de la liste`,
    'company-name-filter': `Filtre de nom d'entreprise`,
    'select-speeds': `Sélectionner des vitesses`,
    'select-provider-types': `Sélectionner des types de fournisseur`,
    'select-countries': `Sélectionner des pays`,
    'loading-companies': `Chargement d'entreprises`,
    'all-services': `tout`,
    'selected-services': `services sélectionnés`,
    'profile-message-hidden': `Message du profil masqué`,
    'hidden-day': `Ce message a été masqué pendant une journée.`,
    'hidden-week': `Ce message a été masqué pendant une semaine.`,
    'hidden-message': `Ce message ne sera plus affiché sur cet écran, mais vous pouvez créer ou modifier votre profil ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT} dans l’option de menu « Company (Entreprise) > Profile (Profil) ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} ».`,
    'hide-profile': `Masquer le message du profil`,
    'hidden-permanently': `Ce message a été définitivement masqué.`,
    'upload-logo': `Charger un logo`,
    'change-logo': `Modifier le logo`,
    social: `Social`,
    headquarters: `Siège social`,
    'address-1': `Adresse postale 1`,
    'address-2': `Adresse postale 2`,
    city: `Ville/Banlieue`,
    postcode: `Code postal`,
    'point-click': `Pointez, Cliquez, Connectez-vous.`,
    'no-edit-permission': `Vous n'êtes pas autorisé à modifier le profil. Pour le modifier, contactez un administrateur de l'entreprise.`,
    'loading-editor': `Chargement de l'éditeur de profil...`,
    'no-create-permission': `Vous n'êtes pas autorisé à créer un profil. Pour créer un profil, contactez l'administrateur de votre entreprise.`,
    'profile-url': `Profil disponible sur l'URL :`,
    contact: `Contacter`,
    visibility: `Visibilité du profil`,
    'company-bio': `Biographie de l'entreprise`,
    'bio-length': `Biographie de l'entreprise (200 mots)`,
    'contact-email': `E-mail de contact`,
    'recently-viewed': `Fournisseurs récemment consultés`,
    'similar-providers': `Fournisseurs de services similaires`,
    'duplicated-names': `Les noms d'affichage de services suivants sont dupliqués :`,
    'duplicate-name': `Nom d'affichage dupliqué`,
    description: `Description du service (200 mots)`,
    'service-contact-email': `E-mail de contact du service`,
    'service-types': `Types de service`,
    'company-profile': `Profil d'entreprise ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} de l'entreprise {company}`,
    'live-time': `Actif pendant {time}`,
    'company-at-location': `{company} à {location}`,
    state: `État`,
    country: `Pays`,
  },
  'mcr-looking-glass': {
    'no-services-message': `Vous n’avez aucun service ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MCR}) configuré.`,
    'no-bgp-sessions': `Il n'existe aucune session BGP pour ce ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.`,
    'no-services': `Services introuvables`,
    selection: `Sélection de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'routes-table': `Table de routes`,
    'bgp-table': `Table BGP`,
    'all-reachable': `Tous les réseaux accessibles dans la table de routage ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.`,
    'all-routes': `Toutes les routes`,
    'routes-by-ip': `Routes par adresse IP`,
    find: `Rechercher des routes`,
    loading: `Chargement de routes`,
    'enter-ip': `Saisissez une adresse IP ou une adresse réseau pour rechercher des routes.`,
    'no-routes': `Il n'existe aucune route pour cette adresse IP.`,
    'no-bgp': `Il n'existe aucune connexion BGP associée à cette interface.`,
    neighbour: `Routes voisines`,
    'bgp-sessions': `Sessions BGP`,
    'bgp-sessions-tooltip': `Toutes les sessions BGP configurées sur les ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} pour le ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} sélectionné.`,
    'filter-sessions': `Saisissez une chaîne pour filtrer les sessions.`,
    'toggle-details': `Activer des détails supplémentaires`,
    'total-routes': `Nombre total de routes`,
    'filter-routes': `Saisissez un texte pour filtrer les routes.`,
    advertised: `Annoncé`,
    received: `Reçu`,
    'sort-by-thing': `Trier par {thing}`,
    'clear-filter-routes': `Effacer les routes de filtre`,
    'contact-support': `Contactez notre équipe d’assistance pour voir ce que ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} peut vous apporter.`,
    'service-could-not-be-loaded': `Impossible de charger le service ({errorStatus})`,
    'service-loading-timeout': `Veuillez nous excuser pour la gêne occasionnée, mais il semble qu’il y ait eu un problème de chargement de ces informations. Si le problème persiste, contactez notre équipe d’assistance à l’adresse suivante : ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
  },
  'ix-flows': {
    'no-ix-found': `Service ${process.env.VUE_APP_EN_PRODUCTNAME_IX} introuvable`,
    'no-ix-message': `Vous n'avez pas de services ${process.env.VUE_APP_EN_PRODUCTNAME_IX}. Contactez votre représentant commercial si vous êtes intéressé par des services ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ${process.env.VUE_APP_EN_PRODUCTNAME_IX}.`,
    'date-select': `Sélection de date`,
    'select-peer': `Sélection d'un pair`,
    'no-data': `Aucune date disponible pour ce pair`,
    'flow-type': `Type de flux`,
    'loading-peers': `Chargement de pairs`,
    'sort-by': `Trier par`,
    'filter-by-name': `Filtrer par nom`,
    'total-peers': `Nombre total de pairs : {count}`,
    'top-5': `5 meilleurs pairs ({direction})`,
    'volume-in': `Volume (entrée)`,
    'volume-out': `Volume (sortie)`,
    'load-error': `Il y a un problème avec le chargement de ces données. Rechargez pour réessayer.`,
  },
  checkout: {
    'minimum-term-months': `Pas de durée minimale | De mois en mois | Durée minimale : {count} mois`,
    'unknown-validation-error': `Erreur de validation inconnue : « {message} »`,
    'hide-promo': `Masquer`,
    'add-promo': `Ajouter le code`,
    order: `Commander des services`,
    'set-up-company': `Configurer votre entreprise`,
    'register-markets': `Enregistrer des marchés`,
    'order-now': `Commander maintenant`,
    'need-setup': `Avant de pouvoir commander des services, vous devez terminer la configuration du compte.`,
    'need-company': `Vous devez configurer les informations de votre entreprise.`,
    'need-company-permissions': `Les informations de votre entreprise ne sont pas configurées, mais vous ne disposez pas de droits suffisants. Contactez l'administrateur de votre entreprise.`,
    'need-markets': `Vous devez activer les marchés d'entreprise requis.`,
    'need-markets-permissions': `Les marchés de votre entreprise ne sont pas activés, mais vous ne disposez pas de droits suffisants. Contactez l'administrateur de votre entreprise.`,
    validating: `Validation de votre commande`,
    deploying: `Déploiement...`,
    'no-term': `Aucune durée`,
    promo: `Promotion`,
    'add-promo-code': `Ajouter code promotionnel`,
    'promo-code': `Code promotionnel`,
    'checking-promo': `Contrôle du code promotionnel`,
    'promo-validated': `Code validé`,
    'no-deploy': `Remarque : Même si vous pouvez créer et enregistrer des paniers, les droits de votre compte vous empêchent de commander des services.`,
    'invalid-code': `Code non valide`,
    'code-not-confirmed': `Le code promotionnel n'a pas pu être confirmé.`,
    'save-failed': `Échec d'enregistrement des services configurés`,
    'error-deploying': `Erreur de déploiement des services.`,
    'unknown-error': `Une erreur inconnue s'est produite lors de la tentative de déploiement.`,
    deployed: `Services correctement déployés.`,
    success: `L'ID de commande {orderId} a correctement déployé les services :`,
    'null-code': `Veuillez entrer un code promotionnel`,
    'capture-tech-support-details': `Capturer les détails de l’assistance technique`,
    'set-up-tech-support-details': `Détails de l’assistance technique`,
    'need-tech-support-details': `Les coordonnées de l’assistance technique sont obligatoires avant que des commandes puissent être passées.`,
    'need-tech-support-details-by-admin': `Les coordonnées de l’assistance technique doivent être complétées par un administrateur de l’entreprise.`,
    'missing-tech-support-details': `Coordonnées de l’assistance technique manquantes`,
    'deleting-configuration': `Suppression des configurations enregistrées...`,
    'refreshing-services': `Actualisation des services...`,
  },
  sidebar: {
    'enable-market': `Activer le marché {country}`,
    'configured-services': `Services configurés`,
    'save-configured-services': `Enregistrer les services configurés`,
    'save-configured-services-later': `Enregistrez ces services configurés pour plus tard.`,
    'saved-configurations-alert': `Les configurations enregistrées le sont à l'échelle de l'entreprise et seront visibles par toute personne de votre entreprise.`,
    'existing-configuration-warning': `Il existe déjà une configuration portant ce nom. L'enregistrement sous le même nom remplace la configuration précédemment enregistrée.`,
    'clear-configured-services': `Effacer tous les services configurés`,
    'save-configured-name': `Saisissez un nom pour la configuration.`,
    'saved-configurations': `Configurations enregistrées`,
    'config-saved-title': `La configuration de {name} a été enregistrée`,
    'config-saved-message': `La configuration de {name} ({serviceOrder}) a bien été enregistrée sur le serveur.`,
    'config-save-error-title': `Erreur lors de l'enregistrement de la configuration de {name}.`,
    'config-deleted-title': `Configuration supprimée`,
    'config-delete-error-title': `Impossible de supprimer la configuration de {name}.`,
  },
  'billing-markets': {
    'vat-characters': `Saisissez un numéro de TVA de {length} caractères.`,
    'purchase-order-market': `Le numéro de bon de commande de facture apparaîtra sur l’en-tête de facture pour le marché {country} lorsque la facture est générée.`,
    'due-date-date': `Date d'échéance : {date}`,
    'remove-card-ending': `Supprimer la carte se terminant par {numbers}`,
    'edit-card-ending': `Modifier les options de la carte se terminant par {numbers}`,
    'pay-card-ending': `Effectuez un paiement à l'aide de votre carte enregistrée se terminant par {numbers}.`,
    'remove-card-confirmation': `Voulez-vous vraiment supprimer la carte de crédit se terminant par {numbers} du pays de facturation ${process.env.VUE_APP_EN_COMPANYINFO_NAME} {marketName} ?`,
    'dont-accept-currency': `Nous n'acceptons actuellement aucun paiement en {currency} via cette carte de crédit sur ce marché. Pour connaître les autres modes de paiement, consultez votre facture.`,
    title: `Pays de facturation de l'entreprise`,
    enabled: `Marchés activés`,
    none: `Aucun marché activé`,
    options: `Options`,
    'view-invoices': `Afficher les factures`,
    payment: `Effectuer un paiement`,
    edit: `Modifier le marché`,
    view: `Afficher le marché`,
    'download-invoices': `Télécharger toutes les factures`,
    'download-json': `Télécharger au format JSON`,
    'download-csv': `Télécharger CSV`,
    'enable-bm': `Activer un pays de facturation`,
    'none-available': `Aucun marché disponible`,
    enable: `Activer`,
    market: `Marché`,
    invoices: `Factures`,
    'next-invoice': `Facture suivante`,
    disclaimer: `(en supposant l'absence de changements de vitesse, hors taxes)`,
    balance: `Solde du compte`,
    'inc-tax': `(taxes incluses)`,
    'download-statement': `Télécharger la déclaration`,
    'no-bills': `Aucun service n'a encore été facturé sur ce marché.`,
    'invoice-no': `Facture n°`,
    'issue-date': `Date d'émission`,
    total: `Total`,
    payments: `Paiements`,
    'no-stripe': `Les paiements Stripe ne sont actuellement pas disponibles.`,
    'cc-history': `Historique de paiements de la carte de crédit`,
    'no-payments': `Aucun paiement enregistré`,
    description: `Description`,
    'payment-date': `Date de paiement`,
    'unavailable-market': `Les paiements par carte de crédit ne sont pas acceptés pour ce marché. Pour connaître les autres modes de paiement, consultez votre facture.`,
    'billing-currency': `Devise de facturation`,
    'currency-fixed': `Impossible de modifier la devise de facturation`,
    'no-currency-change': `Une fois le marché configuré, il est impossible de modifier la devise de facturation.`,
    language: `Langue de la facture`,
    'language-future-invoices': `Remarque : Les modifications apportées à la langue de la facture affectent uniquement les futures factures.`,
    'billing-name': `Nom du contact de facturation`,
    'billing-phone': `Téléphone du contact de facturation`,
    'phone-format': `Numéro de téléphone international. Exemple +61 4321 1234`,
    'billing-email': `E-mail du contact de facturation`,
    'email-format': `Une seule adresse e-mail ou une liste d'adresses e-mail séparées par des virgules`,
    'billing-address': `Adresse de facturation`,
    'address-2': `Ligne d'adresse 2`,
    city: `Ville`,
    province: `Province`,
    state: `État`,
    postcode: `Code postal`,
    'select-or-type': `Effectuez une sélection ou saisissez un texte pour filtrer les options.`,
    'company-registration': `Numéro d’enregistrement de l’entreprise`,
    'company-tooltip': `Numéro d'enregistrement de l'entreprise associé à l'entité d'achat.`,
    'vat-registration': `Avez-vous un numéro de TVA ?`,
    'vat-id': `N° de TVA`,
    'vat-number': `Numéro de TVA enregistré.`,
    po: `Numéro de bon de commande de facture`,
    currency: `Devise du marché`,
    'company-identifier': `Identificateur de l'entreprise`,
    'contact-name': `Nom du contact`,
    'phone-number': `Numéro de téléphone`,
    address: `Adresse`,
    'due-unavailable': `Non disponible`,
    vat: `TVA`,
    'register-card': `Enregistrer carte`,
    'register-card-status': `Statut de configuration de la carte : {status}`,
    'register-card-edit': `Modifier la carte enregistrée`,
    'register-card-fail': `Échec d'enregistrement de la carte`,
    'register-card-success': `Carte correctement enregistrée.`,
    'register-card-fail-message': `Échec d'enregistrement de votre carte.`,
    'register-card-message': `Enregistrez une carte avec un pays de facturation ${process.env.VUE_APP_EN_COMPANYINFO_NAME} pour effectuer des paiements automatiques (après l’émission de la facture) ou des paiements manuels ponctuels.`,
    'registering-card': `Enregistrement de la carte...`,
    'remove-card': `Supprimer la carte`,
    'removing-card': `Suppression de la carte...`,
    'remove-card-fail': `Erreur de suppression de la carte`,
    'remove-card-fail-message': `Échec de suppression de la carte : {error}`,
    'update-card': `Mettre la carte à jour`,
    'update-card-success': `La carte a été correctement mise à jour.`,
    'update-card-fail': `Erreur de mise à jour de la carte`,
    'update-card-fail-message': `Échec de mise à jour de la carte : {error}`,
    'remove-card-success': `Carte correctement supprimée.`,
    'save-card-fail': `Échec d'enregistrement de la carte`,
    'save-card-fail-message': `Impossible d'enregistrer les informations de la carte : {error}`,
    'payment-once': `Effectuer un paiement unique`,
    'payment-once-make': `Effectuez un seul paiement unique sur votre compte.`,
    'payment-processing': `Traitement du paiement`,
    'payment-confirm-fail': `Échec de confirmation de la configuration du paiement`,
    'payment-error': `Erreur de paiement imprévue`,
    'payment-success': `Paiement correctement effectué.`,
    'payment-success-message': `{amount} {currency} ont été payés sur le marché [{market}].`,
    'card-number': `Numéro de carte`,
    'card-expiry': `Date d'expiration`,
    'card-cvc': `CVC`,
    'auto-payments-on': `Paiements automatiques activés`,
    'auto-payments-off': `Paiements automatiques désactivés`,
    'payment-amount': `Montant du paiement`,
    'payment-description': `Description du paiement`,
    'payment-failed': `Échec de paiement`,
    'payment-create-failed': `Échec de création d'un mode de paiement`,
    'payment-create-failed-message': `Impossible de créer le mode de paiement Stripe : {error}`,
    'payment-attempt-failed': `Échec de tentative de paiement : {error}`,
    'payment-market-fail': `Aucun paiement n'a été effectué sur le marché [{market}]. {error}`,
    'processing-fees': `Des frais de traitement de carte de crédit seront ajoutés au montant du paiement et figureront sur votre prochaine facture. ${process.env.VUE_APP_EN_COMPANYINFO_NAME} facture ces frais directement auprès de notre prestataire de services.`,
    'account-balance': `Le solde actuel de votre compte est de | (taxes incluses).`,
    'enable-markets': `Activer les marchés`,
    'services-no-market': `Vous avez configuré des services pour des marchés qui n'ont pas encore été activés.`,
    'enable-markets-admin': `Les pays de facturation suivants doivent être activés par un administrateur de l'entreprise :`,
    'no-payment-method': `Aucune donnée de mode de paiement reçue.`,
    'stripe-error': `Erreur Stripe`,
    'update-success': `La fourniture du marché « {country} » a été correctement mise à jour.`,
    supply: `fourniture du marché`,
    'create-success': `La fourniture du marché « {country} » a été correctement créée.`,
    'payment-docs-html': `Vous pouvez obtenir des informations supplémentaires sur les frais de transaction par carte de crédit auprès de notre prestataire de services, Stripe, {link}`,
    'cc-autopay': `Les paiements automatiques seront traités le 20e jour de chaque mois pour le solde impayé de votre compte.`,
    'cc-support-html': `Si vous avez des questions relatives aux paiements par carte de crédit, contactez {email}`,
    download: `Téléchargement`,
    'due-date': `Date d’échéance`,
    'invoice-date': `Date de facture`,
    'invoice-amount': `Quantité facture`,
    'open-balance': `Solde d’ouverture`,
    'date-range': `Plage de dates`,
    'download-pdf': `Télécharger le PDF`,
    'download-invoices-info': `Remarque : La fonction Download All Invoices (Télécharger toutes les factures) n’est plus prise en charge. Téléchargez les factures individuelles à partir de l’icône View Invoices (Afficher les factures) ci-dessus et en sélectionnant une plage de dates.`,
    'billing-address-entry-rules': `Cette adresse doit être l’adresse commerciale à laquelle sont basés les principaux décideurs concernant l’acquisition des services de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}et leur mise en œuvre.`,
    UK_MOBQ: `Do you use our services in the UK? (Utilisez-vous nos services au Royaume-Uni ?)`,
    UK_MOBQ_TOOLTIP: `Confirmez si vous avez du personnel au Royaume-Uni qui utilise ces services.`,
    AU_MOBQ: `Are you registered for Australian GST? (Avez-vous un numéro de taxe sur les biens et services en Australie ?)`,
    AU_MOBQ_TOOLTIP: `Confirmez si l’entité juridique ou la succursale (il pourrait s’agir d’une entreprise affiliée de l’entité facturée par ${process.env.VUE_APP_EN_COMPANYINFO_NAME}) qui utilise les services a un numéro de taxe sur les biens et services en Australie.`,
    SG_MOBQ: `Are you registered for Singapore GST? (Avez-vous un numéro de taxe sur les biens et services à Singapour ?)`,
    SG_MOBQ_TOOLTIP: `Confirmez si l’entité juridique ou la succursale (il pourrait s’agir d’une entreprise affiliée de l’entité facturée par ${process.env.VUE_APP_EN_COMPANYINFO_NAME}) qui utilise les services a un numéro de taxe sur les biens et services à Singapour.`,
    'company-type-mexico': `Type d’entreprise`,
    'company-type-mexico-tooltip': `Type de contribuable (personne physique, personne morale ou entité étrangère)`,
    'tax-regime-code-mexico': `Régime fiscal`,
    'tax-regime-code-mexico-tooltip': `Régime fiscal - Sélectionnez le code de régime fiscal actuel enregistré auprès du Service d’administration fiscale (SAT) mexicain.`,
    'sat-purpose-mexico': `But fiscal`,
    'sat-purpose-mexico-tooltip': `But fiscal`,
    'rfc-mexico': `Numéro RFC`,
    'rfc-mexico-tooltip': `Attribuez le numéro de Registre fédéral des contribuables (RFC) actuel - tel qu’il est délivré par le Service d’administration fiscale (SAT) mexicain.`,
    'fusf-interstate-traffic-certification': `Certification de trafic interétatique Federal USF *`,
    'fusf-recommended-text': `* Il est vivement recommandé de lire l’info-bulle pour plus de contexte`,
    'fusf-tooltip-content': `Pour les produits de transmission de données* avec des points de terminaison de service dans le même état, Megaport est tenue par la Federal Communications Commission (FCC) de supposer que le trafic du client finit par traverser les frontières de l’état dans le cadre du réseau plus large du client, sauf si le client confirme le contraire.<br/><br/>
      En cochant la case, le client certifie que <strong>plus de 10 %</strong> du trafic est de nature interétatique en ce qui concerne l’ensemble des produits achetés auprès de Megaport. Dans ce cas, les services seront soumis aux frais du Federal Universal Service Fund (FUSF).<br/><br/>
      S’il ne coche pas la case, le client certifie que <strong>10 % ou moins</strong> du trafic transmis via lesdits produits traverse les frontières de l’état, ce qui signifie qu’aucun FUSF ne sera appliqué.<br/><br/>
      Un enregistrement électronique du nom et du titre de l’utilisateur du compte qui a fourni la certification ci-dessus sera généré pour les registres de Megaport et pourra être utilisé à des fins d’audit.<br /><br />
      *Les produits de transmission de données de Megaport comprennent VXC, Megaport Internet et Internet Exchange.`,
  },
  users: {
    'configure-destination-notifications': `Configurer des notifications {destination}`,
    'editing-user-name-you': `Modification de l'utilisateur {firstName} {lastName} {you}`,
    'user-not-found-redirect': `Impossible de trouver l'utilisateur {userId}. Vous allez être redirigé vers la page Gérer les utilisateurs.`,
    'position-not-found': `Cet utilisateur dispose d'un rôle ({position}) qui n'est plus pris en charge par le système. Sélectionnez l'une des options disponibles.`,
    'invalid-email': `{email} n'est pas une adresse e-mail valide.`,
    deactivated: `Afficher les utilisateurs désactivés`,
    notifications: `Notifications`,
    destinations: `Destinations disponibles`,
    'no-destinations': `Aucune destination disponible`,
    'detailed-notifications': `Afficher les notifications détaillées`,
    filter: `Filtrer`,
    'no-match': `Aucun utilisateur correspondant`,
    active: `Le compte d'utilisateur est actif.`,
    disabled: `Le compte d'utilisateur est désactivé.`,
    you: `(Vous)`,
    new: `Nouvel utilisateur`,
    'logged-in-as-user': `Vous êtes actuellement connecté sous cet utilisateur. Vous pouvez modifier vos détails d'utilisateur sur la page Profil d'utilisateur.`,
    state: `État de l'utilisateur`,
    'technical-admin': `Administrateur technique`,
    finance: `Finances`,
    'financial-contact': `Contact financier`,
    role: `rôle de l'utilisateur`,
    'role-not-found': `Rôle introuvable`,
    'user-not-found': `Utilisateur introuvable`,
    'missing-info': `Infos manquantes`,
    'change-email': `Changer l'adresse e-mail`,
    'changing-email': `Changement de l'adresse e-mail...`,
    'new-email': `Nouvelle adresse e-mail`,
    'email-preferences': `Préférences en matière d'e-mail`,
    'saving-email-preferences': `Enregistrement des préférences en matière d'e-mail...`,
    'receive-newsletter': `Recevoir le bulletin d’information ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'receive-promotional-material': `Recevoir le support promotionnel ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'change-password': `Changer le mot de passe`,
    'changing-password': `Changement du mot de passe...`,
    'old-password': `Ancien mot de passe`,
    'new-password': `Nouveau mot de passe`,
    'retype-new-password': `Ressaisissez le nouveau mot de passe.`,
    'saving-user-profile': `Enregistrement du profil d'utilisateur`,
    'account-password': `Mot de passe du compte`,
    username: `Nom d'utilisateur`,
    'loading-qr-code': `Chargement du code QR`,
    'account-activity': `Activité du compte`,
    'account-activity-none': `Aucune donnée d'activité`,
    'account-activity-loading': `Chargement des données d'activité...`,
    'activity-data-load-fail': `Échec de chargement des données d'activité`,
    'save-failed': `Échec d'enregistrement`,
    'save-user-profile-failed': `Échec d'enregistrement du profil d'utilisateur`,
    'save-email-failed': `Échec de changement de l'adresse e-mail`,
    'save-email-preference-failed': `Échec d'enregistrement des préférences en matière d'e-mail`,
    'profile-saved': `Profil correctement mis à jour.`,
    'email-change-success': `Préférences en matière d'e-mail modifiées.`,
    'save-password-failed': `Échec d'enregistrement du mot de passe`,
    'save-password-failed-message': `Échec d'enregistrement du mot de passe mis à jour`,
    'saving-user-profile-error': `Erreur de mise à jour du profil`,
    profile: `Profil`,
    'user-name': `Utilisateur {firstName} {lastName}`,
    'verification-email': `Un e-mail de vérification a été envoyé à votre nouvelle adresse.`,
    'verification-message': `Suivez les instructions sur l'e-mail.`,
    'user-role': `User Role (Rôle utilisateur)`,
    'viewing-user-name-you': `Visualisation de l’utilisateur {firstName} {lastName} {you}`,
    'cant-edit-invite-pending': `Ce profil d’utilisateur ne peut pas être modifié tant que l’utilisateur n’a pas accepté l’invitation. Veuillez supprimer l’utilisateur et le réinviter immédiatement si des changements sont nécessaires.`,
    'deleting-user': `Suppression de l’utilisateur...`,
    'disable-mfa': `Désactiver MFA`,
    'enable-mfa': `Activer MFA`,
    'reset-mfa': `Réinitialiser MFA`,
    'disable-mfa-dialog': `Désactiver l’authentification multifactorielle`,
    'enable-mfa-dialog': `Activer l’authentification multifactorielle`,
    reset: `Réinitialiser`,
    'mfa-long': `Authentification multifactorielle`,
    'reset-mfa-dialog': `Réinitialiser l’authentification multifactorielle`,
    'reset-mfa-success': `Réinitialisation de l’authentification multifactorielle réussie`,
    'reset-mfa-failed': `Erreur lors de la réinitialisation de l’authentification multifactorielle`,
    'reset-mfa-dialog-desc': `Vous êtes sur le point de réinitialiser votre secret d’authentification multifactorielle. Une fois que vous aurez scanné et confirmé le code QR, les nouveaux secrets deviendront actifs et tous les anciens secrets des appareils seront invalidés.`,
    'mfa-enforced-disable-tooltip': `L’administrateur de votre entreprise a mis en place une politique globale pour appliquer la MFA. Il n’est pas possible de désactiver la MFA tant qu’elle est en place.`,
    'position-header': `Rôle`,
    'mfa-header': `MFA`,
    'mfa-tooltip-not-set': `L’utilisateur n’a pas configuré l’authentification multifactorielle`,
    'mfa-tooltip-set': `L’utilisateur a configuré l’authentification multifactorielle`,
    set: `Défini`,
    'not-set': `Non défini`,
    enforced: `Appliqué`,
    optional: `En option`,
    'partner-only': `Partenaire uniquement`,
    'partner-only-tooltip': `Configurer les notifications par e-mail associées uniquement à votre compte partenaire`,
    'partner-and-managed': `Partenaire et géré`,
    'partner-and-managed-tooltip': `Configurez les notifications par e-mail associées à vos comptes partenaires et à tous les comptes gérés`,
    'company-admin-reset-mfa-confirm-title': `Voulez-vous vraiment réinitialiser l’authentification multifactorielle ?`,
    'company-admin-reset-mfa-confirm-message': `Assurez-vous que {username} pour qui vous demandez la réinitialisation est une personne réelle, et non un compte utilisé pour les intégrations Machine to Machine. Après la réinitialisation de la MFA, l’utilisateur sera invité à réinitialiser ses informations d’identification lors de la connexion.`,
    'company-admin-reset-help-text': `Aidez cet utilisateur à réinitialiser son compte. L’utilisateur devra alors inscrire un nouvel appareil`,
    'mfa-alt-tag': `Utilisez votre appareil photo pour scanner le code QR`,
    'mfa-app-suggestion': `Nous recommandons d’utiliser Google Authenticator comme application d’authentification.`,
    'mfa-token': `Saisissez le jeton unique généré`,
    'mfa-loading-failed': `Échec de chargement du code QR,`,
    'mfa-disabled': `Authentification multifactorielle désactivée`,
    'enable-mfa-success': `Authentification multifactorielle correctement activée.`,
    'disable-mfa-fail': `Erreur de désactivation de l’authentification multifactorielle`,
    'view-user-actions': `Voir toutes les actions utilisateur`,
    'mfa-warning-1': `Assurez-vous que le compte enregistré dans votre application d’authentification multifacteur est supprimé.`,
    'mfa-warning-2': `Lors de la désactivation, vous serez déconnecté(e).`,
    'loading-users': `Chargement des utilisateurs de l’entreprise...`,
    'sso-compatibility': `Compatibilité SSO`,
    'sso-enabled-tooltip': `Le domaine de messagerie des utilisateurs est lié à l’authentification SSO et ils utiliseront SSO par défaut pour se connecter`,
    'sso-enforced-no-access-tooltip': `L’authentification SSO est appliquée et l’utilisateur n’est PAS compatible avec SSO. Accès interdit`,
    'sso-optional-can-login-tooltip': `Le domaine de messagerie des utilisateurs n’est PAS compatible avec SSO, mais l’utilisateur peut se connecter via d’autres méthodes`,
    'active-api-keys-found': `Clés API actives trouvées`,
    'active-api-keys-inactive-if-continue': `Des clés API actives ont été trouvées pour cet utilisateur ; si vous continuez, elles deviendront inactives. Toutes les clés API peuvent être consultées via Tools (Outils) - API Key Generator (Générateur de clés API).`,
    'active-api-keys-input-label': `Pour continuer, saisissez Inactivate (Désactiver) et cliquez sur Continue (Continuer), ou cliquez sur Cancel (Annuler) pour abandonner.`,
    'active-api-keys-error': `Pour continuer, saisissez Inactivate (Désactiver).`,
    'active-api-keys-review': `Il est recommandé de vérifier si les clés API générées par cet utilisateur sont utilisées dans une intégration système critique entre votre entreprise et ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
  },
  'api-keys': {
    'description-intro': `Si vous devez accéder directement à l’API ${process.env.VUE_APP_EN_COMPANYINFO_NAME}, il vous faut une clé API.`,
    'description-html': `Les clés API sont valides uniquement dans l’environnement dans lequel elles ont été générées. Vous êtes actuellement connecté(e) dans l’environnement {environment}. C’est pourquoi toutes les clés créées et répertoriées ici fonctionneront dans l’environnement {environment}. S’il vous faut des clés pour accéder à un autre environnement, connectez-vous au ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_SHORT} de cet environnement et générez-y des clés.`,
    'learn-more-html': `Vous en saurez davantage sur les environnements dans {link}`,
    forbidden: `Vous ne pouvez pas créer ni gérer des clés API à moins d'être un administrateur d'une entreprise configurée avec un pays de facturation activé.`,
    'key-description': `La clé API est un identificateur unique qui authentifie les demandes à des fins d'utilisation et de facturation. Utilisez la clé pour générer un jeton permettant d'authentifier les demandes API.`,
    'key-generated-message': `La clé API {key} a été générée.`,
    'key-updated-message': `La clé API {key} a été mise à jour.`,
    'key-deleted-message': `La clé API {key} a été supprimée.`,
    'key-regenerated-message': `Un nouveau secret a été généré pour la clé API {key}.`,
    'key-dialog-description': `Votre clé API a été correctement créée. Copiez votre nouvelle clé API et votre nouveau secret de clé API et utilisez-les pour générer un jeton API.`,
    'role-change': `Voulez-vous vraiment remplacer le rôle <strong>{oldRole}</strong> par le rôle <strong>{newRole}</strong> ?<br/><br/>L'administrateur de l'entreprise a entièrement accès à l'API et peut créer, gérer et annuler des services. L'option En lecture seule permet d'afficher les données d'utilisation et les détails de configuration, mais pas de les modifier.`,
    'delete-key': `Voulez-vous vraiment supprimer cette clé ? Une fois supprimée, vous ne pourrez pas l'utiliser pour générer des jetons.`,
    'regenerate-secret': `La régénération de votre secret annulera toutes les sessions utilisant l'ancien secret. Vous devrez utiliser le nouveau secret pour générer des jetons afin d'utiliser cette clé. Voulez-vous vraiment régénérer votre secret ?`,
    'api-keys': `Clés API`,
    'read-only': `Read Only (En lecture seule) est le rôle le plus restrictif. Notez qu’un utilisateur ayant le rôle Read Only (En lecture seule) peut consulter les détails d’un service que vous souhaitez peut-être protéger et garder privé.`,
    admin: `Administrateur de l’entreprise`,
    'unknown-role': `Rôle inconnu`,
    'your-api-key': `Votre clé API`,
    'api-key': `Clé API`,
    secret: `Secret de clé API`,
    'secret-message': `Vous ne pourrez plus générer à nouveau ou afficher ce secret. Assurez-vous de le stocker en lieu sûr.`,
    history: `Historique des clés API`,
    'history-order': `Les modifications les plus récentes apparaissent en haut.`,
    'edited-by': `Modifié par`,
    'update-date': `Dernière mise à jour`,
    role: `Rôle`,
    created: `Clé créée`,
    updated: `Clé mise à jour`,
    regenerated: `Secret régénéré`,
    'unknown-action': `Action de clé inconnue`,
    'unknown-user': `Utilisateur inconnu`,
    'unable-to-read': `Impossible de lire les clés API`,
    key: `Clé`,
    'generate-key': `Générer la clé`,
    'generate-label': `Générer les clés API`,
    generating: `Génération de la clé API`,
    'read-only-impersonate': `Étant donné que vous empruntez l'identité d'un autre utilisateur, vous disposez d'un accès en lecture seule sur toutes les clés du compte.`,
    'success-generate': `Clé API correctement générée.`,
    'failed-generate': `Échec de génération de la clé API`,
    'success-update': `Clé API correctement mise à jour.`,
    'failed-update': `Échec de mise à jour de la clé API.`,
    'success-delete': `Clé API correctement supprimée.`,
    'failed-delete': `Échec de suppression de la clé API.`,
    'active-keys': `Clés API actives`,
    'no-active-keys': `Vous n'avez actuellement aucune clé active. Pour démarrer, générez une clé.`,
    'active-tooltip': `Pour générer le jeton d’accès, vous aurez besoin de la clé et du secret. Une fois que vous avez généré la clé ou un nouveau secret pour la clé, le secret apparaît une seule fois.`,
    'date-created': `Date de création`,
    'added-by': `Ajouté par`,
    'loading-user-data': `Chargement des données de l'utilisateur`,
    'loading-api-keys': `Chargement des clés API existantes`,
    'deleting-api-keys': `Suppression de la clé API`,
    'regenerating-secret': `Régénération du secret`,
    'saving-changes': `Enregistrement des modifications`,
    'regenerate-secret-label': `Régénérer le secret`,
    'view-history': `Afficher l'historique`,
    'confirm-role-change': `Confirmer le changement de rôle`,
    'delete-key-label': `Supprimer la clé`,
    'company-admin': `Les utilisateurs Company Admin (Administrateur de l’entreprise) ont accès à tous les privilèges utilisateur. Nous recommandons de limiter le nombre d’utilisateurs Company Admin (Administrateur de l’entreprise) à ceux qui ont besoin d’un accès complet, mais d’en définir au moins deux pour la redondance.`,
    'technical-admin': `Ce rôle est destiné aux utilisateurs techniques qui savent créer et approuver des commandes.`,
    'technical-contact': `Ce rôle est destiné aux utilisateurs techniques qui savent concevoir et modifier des services, mais qui ne sont pas habilités à approuver des commandes.`,
    finance: `Les utilisateurs Finance doivent avoir une responsabilité financière au sein de l’entreprise tout en comprenant les conséquences de leurs actions s’ils suppriment ou approuvent des services. {note}`,
    'financial-contact': `Ce rôle d’utilisateur est similaire au rôle Finance (Finances) sans la possibilité de passer et d’approuver des commandes, de supprimer des services ou de gérer des clés de service.`,
    'read-only-short': `En lecture seule`,
    'token-expiry': `Expiration du jeton (minutes)`,
    'expiry-min': `5 minutes min.`,
    'expiry-max': `24 heures max. (1440 minutes)`,
    'api-resource': `Ressource API`,
    action: `Action`,
    'name-min': `3 caractères min.`,
    'name-max': `90 caractères max.`,
    'name-chars': `Pas de caractères spéciaux, sauf les tirets et les traits de soulignement.`,
    'name-invalid': `Le nom n’est pas valide`,
    'api-documentation': `Documentation de l’API ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'finance-note': `Remarque : Lors de la création de services, les clients directs peuvent voir le coût du service avant de le déployer. Il est également possible de voir le coût des services déployés en sélectionnant un service dans le ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_SHORT} et en affichant l’onglet Details (Détails).`,
    'api-role': `API`,
    'key-active-tooltip': `La clé est active.`,
    'key-inactive-tooltip': `La clé est inactive en raison de la désactivation de l’utilisateur. Cette clé ne peut être utilisée que si l’utilisateur qui l’a créée est de nouveau actif. Vous pouvez envisager de supprimer cette clé et de créer une nouvelle clé API pour un utilisateur actif.`,
  },
  debug: {
    information: `Informations de débogage`,
    'include-info': `Si vous envisagez de créer un ticket, incluez les informations ci-dessus.`,
  },
  pricebook: {
    product: `Produit`,
    'monthly-rate': `Tarif mensuel`,
    'excludes-tax': `Prix hors taxes`,
    'contact-sales': `Pour une tarification personnalisée, contactez le service commercial.`,
    failed: `Échec d'obtention du prix du service {product}`,
    'monthly-charges-excl-taxes': `Frais mensuels hors taxes`,
    'price-change-1': `Les frais récurrents mensuels actuels pour ce service sont de {currentMonthlyPrice} (hors taxes). En l'absence d'autres changements de vitesse, ce service sera facturé pour un montant de {totalPrice} (hors taxes) pour la période de facturation en cours. Cela représente une {changeDirectionText} de {deltaPrice} (hors taxes). Les nouveaux frais récurrents mensuels estimés pour ce service sont de {longTermMonthlyPrice} (hors taxes).`,
    'price-change-2': `Les modifications apportées à ce service entraîneront un changement de prix. En l'absence d'autres changements de vitesse, le coût de ce service sera de {totalPrice} (hors taxes) pour cette période de facturation. Cela représente une {changeDirectionText} de {deltaPrice} (hors taxes).`,
    'price-change-3': `Les nouveaux frais mensuels pour ce service seront de {longTermMonthlyPrice} (hors taxes). Confirmez ces modifications.`,
  },
  'price-change': {
    detected: `Changement de tarif détecté`,
    cancel: `Non, annuler les modifications.`,
    accept: `Oui, accepter les modifications.`,
    'current-month': `Frais mensuels actuels`,
    'new-monthly': `Nouveaux frais mensuels`,
    'this-period': `Cette période de facturation`,
    change: `Modification`,
    'pricing-model': `Les modifications apportées à ce service entraîneront un changement de prix conformément à notre nouvelle mise à jour du modèle de tarification.`,
    'confirm-model-change': `Une fois ces modifications acceptées, il ne sera plus possible de revenir à l'ancienne tarification et la nouvelle facture suivante émise reflètera ces modifications. Confirmez que vous souhaitez accepter ces modifications.`,
    increase: `une <strong>augmentation</strong>`,
    decrease: `une <strong>réduction</strong>`,
    'current-monthly': `Frais mensuels actuels`,
  },
  cancel: {
    immediate: `Annulation immédiate`,
    'remove-configured': `Supprimer les services configurés`,
    terminate: `Mettre fin aux services`,
    'keep-configured-service': `Non, conserver le service configuré`,
    'keep-configured-services': `Non, conserver les services configurés`,
    'remove-configuration': `Oui, supprimer la configuration`,
    'keep-services': `Non, conserver les services`,
    'clear-configured': `Voulez-vous vraiment effacer l'ensemble de vos services configurés ?`,
    'remove-from-configured': `Voulez-vous vraiment supprimer ce service de vos services configurés ?`,
    'end-of-contract': `Y mettre fin à la fin du contrat`,
    later: `Plus tard`,
    'terminate-immediately': `Y mettre fin immédiatement`,
    now: `Maintenant`,
    'now-confirmation': `La sélection de « Terminate Services » (Mettre fin aux services) confirmera le processus d’arrêt. Ces services seront immédiatement supprimés. Des frais de résiliation anticipée s’appliqueront au(x) service(s) suivant(s) :`,
    'later-confirmation': `La sélection de « Terminate Services » (Mettre fin aux services) confirmera le processus d’arrêt. Ces services continueront à fonctionner normalement jusqu’à la date indiquée sur le service ci-dessous. Des frais de résiliation anticipée s’appliqueront au(x) service(s) suivant(s) :`,
    unable: `Impossible d'annuler :`,
    better: `Aurions-nous pu mieux faire ?`,
    'no-permission': `Vous n'êtes pas autorisé à supprimer des services.`,
    error: `Erreur de chargement des données d'annulation`,
    diversity: `Relation de diversité détectée`,
    'issues-terminating': `0 | Problèmes d'arrêt d'1 service. | Problèmes d'arrêt de {count} services.`,
    terminating: `0 | Arrêt d'1 service. | Arrêt de {count} services.`,
    terminated: `0 | 1 service arrêté. | {count} services arrêtés.`,
    pending: `En attente d'arrêt`,
    'do-not-restore': `Non, ne pas restaurer le service`,
    restore: `Oui, restaurer le service`,
    restoring: `Restauration du service...`,
    'cancel-termination-confirmation': `Ce service est prévu pour s'arrêter. Voulez-vous annuler cette demande d'arrêt et restaurer le service ?`,
    restored: `Service en attente d'arrêt restauré.`,
    'error-uncancelling': `Une erreur s'est produite lors de la restauration de ce service.`,
  },
  canada: {
    ab: `Alberta`,
    bc: `Colombie-Britannique`,
    mb: `Manitoba`,
    nb: `Nouveau-Brunswick`,
    nl: `Terre-Neuve-et-Labrador`,
    nt: `Territoires du Nord-Ouest`,
    ns: `Nouvelle-Écosse`,
    nu: `Nunavut`,
    on: `Ontario`,
    pe: `Île-du-Prince-Édouard`,
    qc: `Québec`,
    sk: `Saskatchewan`,
    yt: `Yukon`,
  },
  company: {
    'trading-name': `Nom commercial`,
    'view-markets': `Afficher les marchés de l'entreprise`,
    'company-trading-name': `Nom commercial de l’entreprise`,
    'company-legal-name': `Raison sociale de l’entreprise`,
  },
  alibaba: {
    'connection-details': `Détails de connexion du service Alibaba`,
    'account-id': `ID du compte Alibaba Cloud`,
    'account-tooltip': `Obtenez-le auprès de votre compte Alibaba.`,
    connection: `Connexion Alibaba`,
    'connection-id': `ID de connexion Alibaba`,
  },
  amsix: {
    'connection-details': `Détails de connexion du service AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    peer: `Appairer avec les serveurs de routage ?`,
    selective: `Sélectif`,
    'noc-contact': `Contact NOC`,
    'url-tooltip': `Les services AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX} nécessitent une URL Web pour faire la promotion des informations d’une entreprise sur ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'email-tooltip': `Les services AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX} nécessitent une adresse e-mail pour faire la promotion des informations d’une entreprise sur ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'select-country': `Sélectionner un pays`,
    'admin-phone-tooltip': `L'administrateur d'une entreprise peut ajouter un numéro de téléphone sur l'écran Gérer les utilisateurs ou un utilisateur peut le faire dans son profil d'utilisateur.`,
    'phone-tooltip': `Vous pouvez mettre à jour votre numéro de téléphone dans votre profil d'utilisateur ; sinon, contactez l'administrateur de votre entreprise.`,
  },
  aws: {
    'connection-details': `Détails de connexion du service AWS`,
    'edit-warning': `La modification des détails AWS de ce service lance une régénération et requiert l'acceptation d'une nouvelle demande de connexion VIF via la console AWS du compte de réception. Confirmez avant d'apporter des modifications.`,
    'warning-button': `Je comprends`,
    'start-editing': `Cliquez sur le bouton ci-dessus pour lancer la modification.`,
    'no-edit-hosted': `Remarque : Les détails AWS de la connexion hébergée ne sont pas modifiables.`,
    'connection-name': `Nom de connexion AWS`,
    'connection-name-tooltip': `Libeller le service dans AWS`,
    'account-id': `ID de compte AWS`,
    'account-id-tooltip': `Obtenez cette information du paramètre de votre compte AWS dans la console AWS.`,
    'bgp-asn': `ASN BGP public ou privé`,
    'amazon-asn': `ASN Amazon`,
    'default-amazon-asn': `Pour utiliser la valeur par défaut, laissez cette valeur vide.`,
    'bgp-key': `Clé d'authentification BGP`,
    'bgp-key-tooltip': `Saisissez une clé d'authentification/un mot de passe BGP (6 à 24 caractères, sans espace blanc). Si vous n'en fournissez pas, nous en générerons un/une pour vous.`,
    'amazon-prefixes': `Préfixes Amazon`,
    'amazon-prefixes-tooltip': `Préfixes pour effectuer une annonce sur AWS, plage d'adresses IPv4 assignées par RIR uniquement`,
    'connection-type': `Type de connexion AWS`,
    'hosted-vif': `VIF hébergée`,
    'hosted-vif-description': `Les VIF (Virtual Interfaces - interfaces virtuelles) hébergées peuvent se connecter à des services cloud AWS publics ou privés.`,
    'hosted-connection': `Connexion hébergée`,
    'documentation-html': `Pour plus d’informations sur les options de connexion AWS, cliquez ici : {link}`,
    'diversity-html': `Pour plus de détails, voir {link}`,
    'vif-connection': `Connexion VIF AWS`,
    'vif-id': `ID VIF`,
    label: `Étiquette AWS`,
    'customer-address': `Adresse du client`,
    'amazon-address': `Adresse Amazon`,
    'aws-hosted-connection': `Connexion hébergée AWS`,
    'aws-vif': `VIF AWS`,
    'connection-id': `ID de connexion AWS`,
    'manual-intervention': `Remarque : Les VIF publiques nécessitent une intervention manuelle d’Amazon et peuvent prendre jusqu’à 48 heures.`,
    'hosted-connection-description': `Chaque ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} de connexion hébergée prend en charge une seule VIF AWS vers une passerelle publique, privée ou de transit.`,
    'key-features': `Les principales caractéristiques sont les suivantes :`,
    'aws-vif-feature-list-1': `Les frais de port AWS sont inclus dans la connexion ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'aws-vif-feature-list-2': `Gestion et surveillance de la bande passante client partagée.`,
    'aws-vif-feature-list-3': `Possibilité de modifier la vitesse de la ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'aws-vif-feature-list-4': `Configurable par incréments ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} de 1 Mbit/s, de 1 Mbit/s à 5 Gbits/s.`,
    'aws-vif-feature-list-5': `Configuration automatique du ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG} associé.`,
    'aws-hosted-feature-list-1': `Les frais du ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} AWS sont facturés via Amazon.`,
    'aws-hosted-feature-list-2': `Bande passante dédiée.`,
    'aws-hosted-feature-list-3': `Définissez les incréments de vitesse de la ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} de 50 Mbits/s à 10 Gbits/s.`,
    'aws-hosted-feature-list-4': `Pas de changements de vitesse de la ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'aws-hosted-feature-list-5': `Prise en charge de ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} diversifiés à des fins de résilience. Le code de couleur met en évidence les ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} diversifiés.`,
    'documentation-link': `Présentation de la connexion AWS - Documentation ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'readonly-warning': `La modification des détails AWS de ce service lance une régénération et requiert l’acceptation d’une nouvelle demande de connexion VIF via la console AWS du compte de réception.`,
    onboarding: `Intégration AWS`,
    'onboarding-reachout': `Un membre de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} vous contactera dans un délai de 1 ou 2 jours ouvrables pour vous aider à effectuer l’intégration.`,
    'onboarding-questions': `Entretemps, si vous avez des questions, vous pouvez nous contacter à l’adresse suivante : {email}`,
    'onboarding-info': `Merci de fournir autant d’informations que possible, y compris l’ID de l’accord ou de l’offre AWS.`,
    'onboarding-success-title': `Intégration à AWS effectuée`,
    'onboarding-success': `Merci de votre envoi. Un membre de ${process.env.VUE_APP_EN_COMPANYINFO_NAME} vous contactera dans un délai de 1 ou 2 jours ouvrables pour vous aider à effectuer l’intégration.`,
  },
  azure: {
    'primary-subnet': `Sous-réseau principal`,
    'primary-subnet-placeholder': `Sous-réseau principal, par exemple 192.0.1.32/30`,
    'secondary-subnet': `Sous-réseau secondaire`,
    'secondary-subnet-placeholder': `Sous-réseau secondaire, par exemple 192.0.1.32/30`,
    'public-prefixes': `Préfixes publics`,
    'public-prefixes-placeholder': `Préfixes publics, par exemple 192.0.2.0/24,192.0.2.0/30`,
    'shared-key': `Clé partagée`,
    'update-peer': `Mettre à jour le pair`,
    'editing-message': `Les détails de connexion de ce service doivent être modifiés du côté Azure.`,
    'no-peers': `Aucun pair défini`,
    'peer-type': `Type de pair`,
    'disable-peering-type': `Voulez-vous vraiment désactiver ce type de peering ?`,
    'disable-peer-type': `Désactiver le type de pair`,
    configuration: `Configuration Azure`,
    'express-route-html': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} fournit des services ExpressRoute aux ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} Microsoft principaux ou secondaires sous forme de Q-in-Q (double balisage, ou 802.1ad). Assurez-vous de bien maîtriser les {link} sur le site Microsoft.`,
    'peering-html': `Les types de peering public, privé et Microsoft sont disponibles par le biais de cette méthode via une clé de service ExpressRoute unique. Consultez {link} pour plus d’informations sur ces types de peering et leur disponibilité.`,
    'get-service-key': `Comment obtenir une clé de service Azure ExpressRoute.`,
    'azure-service-key': `Clé de service Microsoft Azure`,
    'peering-type': `Type de peering`,
    'peering-type-explanation': `Le type de peering a déjà été défini pour cette clé de service sur un autre service.`,
    microsoft: `Microsoft`,
    'back-to-service-key': `Revenez à Clé de service pour saisir votre clé et créer la connexion.`,
    interface: `Interface Azure`,
    'q-in-q': `Définitions des interfaces Q-in-Q`,
    'ms-doc': `Documentation Microsoft`,
  },
  google: {
    'cloud-configuration': `Configuration Google Cloud`,
    'gci-configuration': `Configuration GCI`,
    'partner-peering-key': `Clé d'appariement Google Partner`,
    'show-all-locations': `Afficher tous les emplacements Google Partner`,
    'back-to-enter-peering': `Revenir pour saisir la clé d'appariement`,
    interface: `Interface GCI`,
    connection: `Connexion Google`,
  },
  ix: {
    'ecix-asn-fixed': `Remarque : L'ASN ne peut pas être modifié pour les services ECIX après le déploiement.`,
    'bgp-password-tooltip': `Si vous souhaitez en définir un, saisissez un mot de passe BGP.`,
    'bgp-password-warning': `Remarque : Le mot de passe BGP ne peut pas être modifié après le déploiement.`,
    'looking-glass-graphs': `Les graphiques d'utilisation seront visibles dans Looking Glass.`,
    'no-looking-glass-graphs': `Les graphiques d'utilisation ne seront pas visibles dans Looking Glass.`,
  },
  'mcr-config': {
    'enable-qinq': `Activer Q-in-Q ?`,
    invalid: `Non valide`,
    'add-vlan': `Ajouter un VLAN`,
    'virtual-lan': `LAN virtuel`,
    'unique-id': `Fournissez un ID unique pour le VLAN.`,
    'vlan-id': `ID du VLAN`,
    'delete-vlan': `Supprimer le VLAN`,
    'interface-ip-addresses': `Adresses IP de l'interface`,
    'ip-subnet': `Adresse IP et masque de sous-réseau`,
    nat: `Traduction d'adresse réseau (NAT)`,
    'nat-source': `Adresse IP source NAT`,
    'nat-source-tooltip': `Sélectionnez une adresse IPv4 pour activer la NAT source sur cette interface. Tous les paquets qui quittent cette interface auront leur adresse source réécrite en l'adresse sélectionnée.`,
    'nat-enabled': `NAT activée`,
    'bgp-connections-description': `Relations de peering BGP pour cette interface - maximum de cinq. Nécessite la création d'une adresse IP d'interface.`,
    'bgp-add': `Ajouter une connexion BGP`,
    'bfd-settings': `Paramètres Bidirectional Forwarding Detection (BFD)`,
    'no-bfd-enabled': `Aucune connexion BGP n'est compatible BFD.`,
    'transmit-interval': `Intervalle de transmission`,
    'receive-interval': `Intervalle de réception`,
    multiplier: `Multiplicateur`,
    'bgp-connection': `Connexion BGP`,
    'disable-qinq-message': `La désactivation de Q-in-Q supprimera tous les paramètres.`,
    confirm: `Voulez-vous vraiment continuer ?`,
    'delete-vlan-message': `Cette opération supprimera ce VLAN et l'ensemble de ses paramètres. Continuer ?`,
    'nat-is-enabled': `NAT activé`,
    'no-prefix-filter': `Pas de filtre de préfixe`,
  },
  nutanix: {
    configuration: `Configuration Nutanix`,
    'service-key': `Clé de service Nutanix`,
    'service-key-notice': `Pour vous connecter à un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} Nutanix, il vous faudra une clé de service Nutanix. Si vous n’avez pas de clé de service, consultez {link} pour en obtenir une.`,
    interface: `Interface Nutanix`,
    'accept-speed-change': `Remarque : Le paramètre de vitesse de votre clé de service Nutanix a été mis à jour. Cliquez sur Enregistrer pour accepter le nouveau débit maximal.`,
    nutanix: `Nutanix`,
  },
  oracle: {
    configuration: `Configuration Oracle`,
    'virtual-circuit-id': `ID de circuit virtuel Oracle`,
  },
  salesforce: {
    configuration: `Détails de connexion du service Salesforce`,
    approvals: `Les approbations de connexion Salesforce peuvent prendre jusqu'à deux jours ouvrables aux États-Unis.`,
    'provide-password': `Saisissez un mot de passe BGP. Si vous n'en fournissez pas un, nous en générerons un pour vous.`,
    prefixes: `Préfixes`,
    'prefixes-tooltip': `Préfixes pour effectuer une annonce sur Salesforce, plages d'adresses IPv4 assignées par RIR uniquement.`,
    connection: `Connexion Salesforce`,
    'permitted-prefixes': `Préfixes BGP autorisés`,
  },
  'target-select': {
    'no-bio': `Aucune biographie d'entreprise disponible.`,
    'marketplace-html': `Voir plus d’informations sur {link}.`,
    'hide-info': `Masquer le panneau d'informations`,
    'show-info': `Afficher les informations sur les options`,
  },
  'target-service-key': {
    'destination-found': `Destination trouvée`,
    'key-name': `Nom de clé`,
    'max-speed': `Vitesse maxi.`,
  },
  events: {
    session: `Journal des événements de la session`,
    clear: `Effacer tous les événements`,
    cleared: `Le journal des événements de la session a été effacé.`,
  },
  graph: {
    private: `Les données de cette entreprise sont privées.`,
    'load-error': `Échec de chargement des données du graphique`,
    'no-data': `Aucune donnée de graphique disponible`,
    'in-mbps': `Entrée Mbits/s`,
    'out-mbps': `Sortie Mbits/s`,
    'billing-month': `Mois de facturation :`,
    'a-end-not-owned': `Aucune information de facturation depuis que la A-End de cette connexion appartient à une autre entreprise.`,
    'load-failed': `Échec de chargement des données requises`,
    'no-billing-data': `Aucune donnée de facturation disponible`,
    estimated: `Prochaine facture estimée (hors taxes) pour ce service.`,
    calculated: `Facture (hors taxes) pour ce service.`,
    'too-many-changes': `Trop de changements de vitesse à afficher sur le graphique. Pour afficher les données, téléchargez le CSV.`,
    'configured-speed': `Vitesse configurée`,
    'billing-details': `Détails de facturation`,
    'estimated-next': `Prochaine facture estimée (hors taxes), en supposant aucun changement apporté au service.`,
    speed: `Vitesse (Mbits/s)`,
    download: `Télécharger CSV`,
    'service-graph': `Graphique de service`,
    'date-range': `Plage de dates :`,
    'last-12-h': `12 dernières heures`,
    'last-24-h': `Dernières 24 heures`,
    'last-7-d': `7 derniers jours`,
    'last-4-w': `4 dernières semaines`,
    metrics: `Métriques :`,
    source: `Source :`,
    timezone: `Fuseau horaire :`,
    'nothing-selected': `Aucun élément sélectionné à afficher sur le graphique`,
    'date-time': `Date/Heure`,
    in: `Entrée`,
    out: `Sortie`,
    'last-hour': `N/A (S. O.) | Last hour (Heure passée) | Last {count} hours (X dernières heures)`,
    'last-week': `N/A (S. O.)| Last week (Semaine dernière) | Last {count} weeks (X dernières semaines)`,
    'last-month': `N/A (S. O.) | Last month (Mois dernier) | Last {count} months (X derniers mois)`,
    // Temps universel coordonné
    utc: `UTC`,
    'export-csv': `Exporter CSV`,
    'downloading-csv': `Téléchargement CSV`,
  },
  'looking-glass': {
    'select-ix': `Sélectionner ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    title: `Looking Glass`,
    select: `Sélectionner Internet Exchange`,
    filter: `Type à filtrer par nom ou ASN`,
    'no-peers': `Aucun pair disponible`,
    'loading-peers': `Chargement de la liste de pairs...`,
    rs1: `RS1`,
    'route-server-1': `Serveur de routage 1`,
    rs2: `RS2`,
    'route-server-2': `Serveur de routage 2`,
    'extended-details': `Détails des pairs étendus`,
    routing: `Informations de routage`,
    routes: `Routes`,
    imported: `Importé`,
    best: `Optimal`,
    filtered: `Filtré`,
    loading: `Chargement des données du graphique...`,
    'loading-routes': `Chargement des routes...`,
    'route-load-failed': `Échec de chargement des données des routes`,
    'no-routes': `Aucune route disponible`,
    'local-pref': `Préférences locales`,
    'origin-asn': `ASN d'origine`,
    'asn-path': `Chemin d'accès ASN`,
    since: `Depuis`,
    'route-data-load-failed': `Échec de chargement des données des routes`,
    'detail-data-load-failed': `Échec de chargement des données des détails`,
    'ip-unavailable': `IPv4 et IPv6 non disponibles.`,
    'protocol-unavailable': `{protocol} non disponible.`,
    'ip-available': `IPv4 et IPv6 disponibles.`,
    asn: `ASN :`,
  },
  'image-upload': {
    select: `Sélectionner une image`,
    crop: `Rogner l'image`,
    'drop-image': `Déposez le fichier image ici ou cliquez pour charger.`,
    clear: `Supprimer l'image`,
  },
  'no-services': {
    message: `Une interconnectivité rapide et flexible en quelques clics.`,
    action: `Laissez-nous vous connecter`,
    instructions: `Pour démarrer, créez un ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} via les boutons ci-dessus ou cliquez sur « Commencer la visite » pour voir rapidement comment passer commande.`,
  },
  'google-login': {
    unavailable: `Connexion Google non disponible`,
    loading: `Les services Google sont encore en cours de chargement - veuillez réessayer dans quelques instants.`,
    problem: `Un problème s'est produit lors du chargement des services d'autorisation Google. Ce problème a été signalé à nos développeurs. Pour le moment, veuillez utiliser l'authentification par nom d'utilisateur et mot de passe.`,
    cancelled: `Connexion Google annulée`,
    'cancelled-message': `Vous avez annulé une tentative de connexion Google.`,
    blocked: `Connexion Google bloquée`,
    'blocked-message': `La connexion Google requiert une fenêtre contextuelle qui est bloquée par votre navigateur. Veuillez autoriser les fenêtres contextuelles sur ce site.`,
    'cookies-blocked-message': `Votre navigateur est configuré pour bloquer les cookies tiers. Pour utiliser la connexion Google, autorisez les cookies tiers, puis rechargez la page.`,
    'browser-blocked-message': `La connexion Google n'a pas pu être établie sur votre navigateur. Les détails sont les suivants : {details}`,
    failed: `Échec de connexion Google`,
    'failed-message': `Échec de votre connexion via Google.`,
    'token-received': `Jeton Google reçu, tentative de connexion...`,
    'no-details': `No additional details (Aucun détail supplémentaire).`,
    'token-failed-message': `There was an error verifying your Google login token. (Une erreur s’est produite lors de la vérification de votre jeton de connexion Google.) Please try logging in again or clear your browser cookies if the problem persists. (Réessayez de vous connecter ou effacez les cookies de votre navigateur si le problème persiste.)`,
  },
  'whitelabel-dlr': {
    welcome: `Bienvenue chez DLR`,
    'uptimes-blog': `Uptimes Blog`,
    twitter: `Suivez-nous sur Twitter`,
    linkedin: `Suivez-nous sur Linkedin`,
    conditions: `Voir nos conditions générales et nos règles de confidentialité`,
    contact: `Contact et assistance`,
    careers: `Carrières - Nous recrutons`,
  },
  tooltips: {
    'route-prefix': `Adresse réseau IPv4 ou IPv6 en notation CIDR.`,
    'route-next-hop': `Adresse IP du routeur du prochain saut.`,
    'required-item-start': `Les champs repérés par un`,
    'required-item-end': `sont obligatoires.`,
    'local-ip-address': `Adresse IPv4 ou IPv6 sur cette interface à utiliser pour la communication avec le pair BGP.`,
    'peer-ip-address': `Adresse IP du pair BGP.`,
    'peer-asn': `ASN du pair BGP distant. Les numéros ASN à 4 octets sont pris en charge au format asplain.`,
    'bgp-password': `Clé partagée utilisée pour authentifier le pair BGP, jusqu'à 25 caractères.`,
    'bgp-description': `Description de la connexion BGP, jusqu'à 100 caractères.`,
    'bgp-state': `Par défaut, les connexions BGP sont activées et tenteront activement de se connecter au pair. Sélectionnez Désactiver pour désactiver temporairement la session BGP sans la supprimer. Cela peut s'avérer utile à des fins de dépannage ou de test de scénarios de défaillance.`,
    'med-inbound': `Le MED sera appliqué à toutes les routes reçues sur cette connexion BGP. Laissez vide pour utiliser la valeur reçue par le pair BGP. La route ayant la valeur la plus faible sera préférée.`,
    'med-outbound': `Le MED sera appliqué à toutes les routes transmises sur cette connexion BGP. Le système autonome voisin pourrait préférer la valeur la plus faible à sa discrétion.`,
    bfd: `Bidirectional Forwarding Detection permet une détection rapide des défaillances sur cette connexion BGP. Lorsque la connectivité est perturbée, la session BGP est supprimée sans attendre l’expiration du minuteur de maintien BGP. L’utilisation de BFD peut améliorer les défaillances au fil du temps lorsque plusieurs routes sont disponibles. Remarque : Ces paramètres seront utilisés pour toutes les connexions BGP sur cette VXC où BFD est activé.`,
    'bfd-group': `Remarque : Ces paramètres seront utilisés pour toutes les connexions BGP sur cette interface où BFD est activé.`,
    'bfd-transmit': `Intervalle de temps minimal pour l’envoi de paquets BFD au voisin. La plage prise en charge est de 300 ms à 9 000 ms.`,
    'bfd-receive': `Intervalle de temps minimal pour les paquets BFD qu’un voisin doit envoyer. La plage prise en charge est de 300 ms à 9 000 ms.`,
    'bfd-multiplier': `La session BGP sera supprimée si le nombre de paquets BFD consécutifs non reçus du voisin est égal à ce nombre.`,
    med: `Multi-Exit Discriminator peut être utilisé pour influencer la préférence de route lorsque plusieurs routes vers la même destination sont disponibles. Il est couramment utilisé pour la préférence des chemins d'accès principaux et secondaires spécifiques. Les valeurs comprises entre 0 et 4 294 967 295 sont prises en charge.`,
    'bgp-config-description': `Les routes reçues depuis ce pair BGP seront annoncées vers d'autres pairs BGP sur ce ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} <br /> Vous pouvez annoncer des routes vers tous les pairs par défaut et filtrer des routes vers des pairs spécifiques. Ou vous pouvez filtrer des routes vers tous les pairs par défaut et annoncer des routes vers des pairs spécifiques.`,
    'bgp-prefix-filter': `<p>Appliquez un filtre de préfixe prédéfini à ce pair BGP pour limiter l’ensemble de routes reçues depuis ou annoncées vers le pair.</p>
      <ul>
      <li>Aucun filtre de préfixe n’autorise toutes les routes. Aucune route ne sera filtrée.</li>
      <li>Une liste d’autorisation autorise uniquement les routes correspondant à la liste de préfixes. D’autres routes seront filtrées.</li>
      <li>Une liste d’exclusion autorise toutes les routes sauf celles correspondant à la liste de préfixes sélectionnée.</li>
      </ul>
      <p>Pour définir des listes de filtres de préfixes, sélectionnez l’onglet Listes de filtres de préfixes sur la page de configuration ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.</p>
      `,
    'bgp-prefix-editor': `
          <p>
          La Liste de filtres de préfixes ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} est une liste nommée d'adresses réseau IP. Créez une liste de filtres de préfixes pour mettre en correspondance et filtrer les routes en fonction du préfixe de la route et appliquer le filtre aux
          routes reçues de ou annoncées vers des pairs BGP sur ce ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.
          Le ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} compare chaque préfixe de route aux conditions de la liste, une correspondance à la fois, de haut en bas, pour rejeter ou annoncer la route.
          </p>
          <ul>
          <li>Une liste de filtres de préfixes peut être appliquée plusieurs fois à différents pairs BGP.</li>
          <li>Il est possible de configurer jusqu'à 50 listes de filtres de préfixes sur chaque ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.</li>
          <li>Chaque liste peut contenir jusqu'à 200 entrées.</li>
          </ul>
         `,
    'company-trading-name': `Le nom de votre compte, de votre entreprise ou de votre organisation. Vous pouvez modifier ces informations ultérieurement.`,
    'as-path-prepend-tooltip': `L’ASN local sera ajouté au chemin AS des annonces de route BGP sortantes. Saisissez un nombre compris entre 0 et un maximum de 10 ajouts.`,
    'shutdown-state': `Mettez à jour l’état configuré sur Shut Down (À l’arrêt) pour tester la manière dont le service se comporterait sur le réseau ${process.env.VUE_APP_EN_COMPANYINFO_NAME} si le service était coupé.`,
    'move-connection-limits': `Seuls les ports du même type de produit et de la même zone métropolitaine peuvent être sélectionnés.`,
    'move-connection-vnic': `La désignation A-end ou B-end ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} a été déterminée lorsque le ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} a été créé.`,
  },
  time: {
    min: `min`,
    s: `s`,
    ms: `ms`,
    µs: `µs`,
  },
  'page-titles': {
    login: `Connexion`,
    'user-profile': `Profil d'utilisateur`,
    'user-notifications': `Notifications d'utilisateur`,
    services: `Services`,
    dashboard: `Tableau de bord`,
    'create-port': `Créer ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    mve: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'create-connection': `Créer une connexion`,
    'edit-port': `Modifier ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'edit-connection': `Modifier une connexion`,
    'company-profile': `Profil d'entreprise`,
    'company-users': `Utilisateurs de l'entreprise`,
    'email-confirmation': `Confirmation par e-mail`,
    'marketplace-profile': `Profil ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    debug: `Informations de débogage`,
    'looking-glass': `Looking Glass`,
    'mcr-looking-glass': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} Looking Glass`,
    'event-log': `Journal des événements de la session`,
    'ix-flows': `${process.env.VUE_APP_EN_PRODUCTNAME_IX} Flux`,
    'api-keys': `Clés API`,
    'partner-admin': `Administrateur partenaire`,
    'managed-account': `Compte géré`,
    'billing-markets': `Pays de facturation`,
    onboard: `Intégration`,
    signup: `Inscription`,
    'signup-google': `Inscription Google`,
    admin: `Admin`,
    'logging-in': `Connexion à`,
    'company-security-settings': `Paramètres de sécurité de l’entreprise`,
    'company-support': `Coordonnées de l’assistance technique`,
    'setup-mfa': `Configuration de l’authentification multifacteur`,
    mfa: `Authentification multifacteur`,
    'create-mcr': `Créer ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'edit-mcr': `Modifier ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'create-mve': `Créer ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'edit-mve': `Modifier ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'move-connections': `Déplacer des connexions`,
    'aws-entitlements': `Droits AWS`,
    'aws-onboarding': `Intégration AWS`,
    'service-status': `Statut du service`,
  },
  partner: {
    'learn-more': `Apprenez-en davantage sur l’administration des comptes gérés dans notre documentation partenaire`,
    'view-documentation': `Voir la documentation`,
    'managed-accounts': `Comptes gérés`,
    'create-managed-account': `Créer un compte géré`,
    'view-services': `Voir les services`,
    edit: `Modifier`,
    filter: `Filtrer les comptes`,
    'no-managed-accounts': `Il n’y a pas de comptes gérés. Cliquez sur le bouton « Créer un compte géré » pour créer votre premier compte.`,
    'invalid-company-uid': `Aucun compte géré trouvé avec l’UID correspondant.`,
    'no-match': `Aucun compte correspondant.`,
    'account-name': `Nom du compte`,
    'company-uid': `UID de l’entreprise`,
    'managed-account-ref': `UID du compte géré`,
    'managed-account-ref-placeholder': `Référence unique de l’entreprise`,
    actions: `Actions`,
    'managed-account': `Compte géré`,
    'managed-learn-more': `Apprenez-en davantage sur la création des comptes gérés dans notre documentation partenaire`,
    save: `Enregistrer`,
    create: `Créer`,
    cancel: `Annuler`,
    'account-created': `Compte géré créé.`,
    'account-saved': `Compte géré enregistré.`,
    'create-account-error': `Erreur lors de la création du compte géré.`,
    'update-account-error': `Erreur lors de la mise à jour du compte géré.`,
    error: `Erreur`,
    'error-retrieving-accounts': `Impossible d’accéder aux comptes gérés.`,
    'managed-accounts-info': `Afficher une liste des comptes, définir les autorisations et gérer les services.`,
    'edit-users': `Modifier les utilisateurs`,
    'edit-users-text': `Afficher et gérer la liste complète des utilisateurs et de leurs autorisations pour cette entreprise.`,
    'new-managed': `Nouveau compte géré`,
    'view-services-text': `Configurer, afficher et commander de nouveaux services pour cette entreprise.`,
    'manage-services': `Gérer les services`,
    'diversity-unavailable-location': `La diversité n’est actuellement pas disponible pour cette configuration. Contactez votre partenaire ${process.env.VUE_APP_EN_COMPANYINFO_NAME} pour discuter de vos besoins en diversité.`,
  },
  'partner-admin': {
    'partner-account': `Compte partenaire`,
    'managed-account': `Compte géré | Comptes gérés`,
    'managed-accounts-uid': `UID du compte géré`,
    'select-account': `Sélectionner un compte`,
    'account-filter': `Filtrer les comptes par nom ou référence`,
    partner: `L’interconnexion`,
    'viewing-as': `Affichage en tant que :`,
  },
  'partner-vantage': {
    subscription: `Abonnement`,
    'subscription-tooltip-ports': `L’abonnement commencera une fois le service activé ou 14 jours après la configuration du service`,
    'subscription-tooltip': `L’abonnement commencera à la commande`,
    'no-subscription': `Pas d’abonnement`,
    'change-subscription': `Modifier l’abonnement`,
    'subscription-title': `Définir un abonnement {productType}`,
    'subscription-choose': `Choisir un abonnement {productType}`,
    'confirm-new-subscription': `Démarrer un nouvel abonnement`,
    'subscription-months': `Pas d’abonnement | Abonnement : 1 mois | Abonnement : {count} mois`,
    'subscription-reterm': `La durée de cette commande (la durée minimale) prend effet immédiatement et la remise de la durée sera appliquée à la facture du mois civil suivant. La résiliation anticipée de la durée entraînera des frais de résiliation anticipée, conformément au contrat passé entre nous.`,
    'cancel-subscription': `Cliquez sur Cancel (Annuler) si vous ne souhaitez prendre aucune mesure et passer à aucun abonnement au prix catalogue à la fin de l’abonnement actuel.`,
    'subscription-term': `Durée d’abonnement`,
  },
  outscale: {
    connection: `Connexion Outscale`,
    'connection-details': `Détails de connexion du service Outscale`,
    'connection-name': `Nom de la connexion Outscale`,
    'connection-name-tooltip': `Étiquetter le service dans Outscale`,
    'account-id': `ID du compte Outscale`,
    'account-id-tooltip': `Entrez l’ID du compte propriétaire Outscale`,
  },
  'company-security-settings': {
    'security-settings': `Paramètres de sécurité`,
    'mfa-long': `Authentification multifactorielle`,
    'info-enforced': `Une fois appliquée, tous les utilisateurs de votre entreprise seront invités à configurer la MFA lors de la connexion.`,
    'info-optional': `Une fois configurée en tant qu’option, tous les utilisateurs de votre entreprise auront toujours la possibilité d’activer la MFA dans leur profil utilisateur.`,
    'mfa-now-enforced': `La MFA est désormais appliquée`,
    'mfa-now-optional': `La MFA est désormais en option`,
    'enforce-mfa-all-users': `Appliquer l’authentification multifactorielle (MFA) à tous les utilisateurs de votre entreprise.`,
    'error-updating-mfa': `Erreur de mise à jour du paramètre MFA`,
    'enforce-mfa-tooltip-on': `Si la MFA est activée (ON), tous les utilisateurs doivent l’utiliser lorsqu’ils se connectent à leur compte. Si un utilisateur n’a pas encore activé la MFA, il sera invité à le faire lors de sa prochaine connexion. Les utilisateurs ne pourront pas accéder à votre entreprise dans ${process.env.VUE_APP_EN_COMPANYINFO_NAME} tant qu’ils n’auront pas configuré la MFA.`,
    'enforce-mfa-tooltip-off': `Si MFA est en option (DÉSACTIVÉ), les utilisateurs peuvent choisir d’activer ou non la MFA sur leur compte.`,
    'enforce-mfa-tooltip-title': `Application de la MFA`,
    mfa: `MFA`,
    on: `ACTIVÉ`,
    off: `DÉSACTIVÉ`,
    sso: `SSO`,
    'sso-long': `Authentification unique`,
    'provider-identity': `Identité du fournisseur`,
    type: `Type`,
    'identity-provider-info': `Informations sur le fournisseur d’identité`,
    'provider-name': `Nom du fournisseur`,
    domains: `Domaines`,
    metadata: `URL des métadonnées`,
    'integration-details': `Détails d’intégration`,
    'audience-uri': `URI de l’audience`,
    'idp-response-url': `URL de la réponse de l’IdP`,
    'sso-fail': `Échec de récupération des configurations SSO.`,
    'sso-no-idps': `Pas de fournisseurs d’identité configurés.`,
    'sso-attribute-mapping': `Mappage des attributs`,
    'sso-user-pool-attribute': `Attribut du pool d’utilisateurs`,
    'sso-saml-attribute': `Attribut SAML`,
    'integration-details-desc': `Pour connecter un fournisseur SAML 2.0 à ${process.env.VUE_APP_EN_COMPANYINFO_NAME}, vous devrez fournir l’URI de l’audience et l’URL de la réponse de l’IdP à votre fournisseur d’identité.`,
    'details-for': `Détails de {thing}`,
    'accepts-csv-values': `Accepte une liste de valeurs séparées par des virgules`,
    'enforce-sso-all-users': `Appliquer l’authentification unique (SSO) à tous les utilisateurs de votre entreprise`,
    'enforce-sso-tooltip-title': `Application de SSO`,
    'enforce-sso-tooltip-on': `Si l’application de SSO est activée, tous les utilisateurs doivent s’authentifier à l’aide de l’IdP configuré par défaut. Tout utilisateur non associé au pool d’utilisateurs de l’IdP verra son accès bloqué. (Exception : Les administrateurs de l’entreprise pourront toujours se connecter avec d’autres méthodes, y compris ceux qui n’ont pas de domaine associé à SSO.)`,
    'enforce-sso-tooltip-off': `Si l’application de l’authentification SSO est désactivée, les utilisateurs sont autorisés à utiliser différentes méthodes d’authentification, notamment le nom d’utilisateur/mot de passe, Google ou SSO.`,
    'enforce-sso-info-enforced': `Une fois l’application de l’authentification SSO activée, tous les utilisateurs doivent s’authentifier à l’aide de l’IdP configuré par défaut. Tout utilisateur non associé au pool d’utilisateurs de l’IdP verra son accès bloqué. (Exception : Les administrateurs de l’entreprise pourront toujours se connecter avec d’autres méthodes, y compris ceux qui n’ont pas de domaine associé à SSO.)`,
    'enforce-sso-info-optional': `Une fois que l’application de l’authentification SSO est désactivée, les utilisateurs sont autorisés à utiliser différentes méthodes d’authentification, notamment le nom d’utilisateur/mot de passe, Google ou SSO. Tous les utilisateurs actifs de votre entreprise pourront de nouveau se connecter.`,
    'enforce-sso': `Appliquer SSO`,
    'sso-connections': `Connexions SSO`,
    'error-fetching-sso': `Erreur imprévue lors de la récupération de la configuration SSO`,
    'error-updating-sso': `Erreur imprévue lors de la mise à jour de la configuration SSO`,
    'configure-saml-idp': `Configurer un fournisseur d’identité (IdP) SAML 2.0`,
    'configure-saml-description': `Vous pouvez configurer votre compte ${process.env.VUE_APP_EN_COMPANYINFO_NAME} pour qu’il utilise l’authentification unique (SSO). Lorsque vous activez l’authentification SSO, les utilisateurs sont redirigés vers un fournisseur d’identité (IdP) externe pour s’authentifier, au lieu d’être invités à saisir un mot de passe lorsqu’ils tentent d’accéder au ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}. L’authentification SSO simplifie l’expérience de connexion et évite aux utilisateurs d’avoir à se souvenir de plusieurs jeux d’informations d’identification pour se connecter, ce qui facilite la gestion par les administrateurs et la rend plus conviviale.`,
    'add-saml-connection': `Ajouter une connexion SAML`,
    'register-saml-provider': `Enregistrez ${process.env.VUE_APP_EN_COMPANYINFO_NAME} auprès de votre fournisseur SAML`,
    'register-saml-provider-description': `Configurez votre fournisseur d’identité SAML avec ${process.env.VUE_APP_EN_COMPANYINFO_NAME} en fournissant les informations suivantes (y compris l’URL des métadonnées utilisée par votre fournisseur d’identité).`,
    'sso-update-success': `Paramètres SSO mis à jour pour {name}`,
    'maximum-domains-error': `Le nombre maximal de domaines est de {max}`,
    'invalid-domains': `Domaines non valides trouvés {domains}`,
    'duplicate-domains': `Domaines en double trouvés {domains}`,
    'idp-delete-confirmation-title': `Supprimer le fournisseur`,
    'idp-delete-confirmation-message': `<strong>En effectuant cette opération, vous reconnaissez que :</strong><ul><li>Aucun utilisateur associé à cette connexion ne pourra plus se connecter à l’aide de votre IdP externe.</li><li>Si l’application de l’authentification SSO est activée, elle sera désactivée.</li><li>Tous les utilisateurs actifs de votre entreprise pourront désormais se connecter à l’aide d’autres méthodes d’authentification.</li></ul>`,
    'sso-now-enforced': `L’authentification SSO est maintenant appliquée`,
    'sso-now-optional': `L’authentification SSO est maintenant facultative`,
    'new-user-non-sso-domain-warning': `Notez que l’authentification unique (SSO) est actuellement appliquée à votre compte. Bien que vous puissiez inviter n’importe quel utilisateur à se connecter à votre compte, les utilisateurs invités ne pourront pas utiliser leurs informations d’identification s’ils ne sont pas associés à un domaine SSO. Exceptionnellement, si l’utilisateur est un administrateur de l’entreprise, il pourra accéder à votre compte même s’il n’est pas associé à un domaine SSO.`,
    'sso-enforced-inactive-warning': `Notez que l’authentification unique (SSO) est actuellement appliquée à votre compte. Si vous réactivez le compte d’un utilisateur qui n’est pas associé à un domaine SSO, l’utilisateur continuera à être bloqué et à ne pas pouvoir se connecter. Exceptionnellement, si l’utilisateur est un administrateur de l’entreprise, il pourra accéder à son compte même s’il n’est pas associé à un domaine SSO.`,
    'sso-new-docs-message': `Avant d’ajouter l’authentification SSO à ce compte, consultez la page {docs}. Elle fournit des conseils sur la configuration et des informations importantes sur l’authentification unique.`,
    'sso-features-and-instructions-for-use': `Fonctionnalités SSO et instructions d’utilisation`,
    'sso-add-settings-for-account': `Vous avez ajouté des paramètres SSO pour ce compte.`,
    'sso-ensure-config-complete': `Pour vous assurer que votre configuration est complète, vous devez maintenant intégrer votre IdP avec l’URI de l’audience et l’URL de la réponse ci-dessous. Il s’agit de composants essentiels au bon fonctionnement de l’authentification SAML :`,
    'sso-info-accessed-again-at-any-time': `Ces informations sont de nouveau accessibles à tout moment via un clic sur le bouton Edit (Modifier) en regard de votre connexion SSO.`,
    'can-also-be-known-as': `Également connu sous le nom de :`,
    'entity-id': `ID de l’entité`,
    audience: `Audience`,
    'assertion-consumer-service': `URL ACS (Assertion Consumer Service)`,
    'single-sign-on-url': `URL de l’authentification unique`,
    'application-callback-url': `URL de rappel de l’application`,
    'reply-url': `URL de la réponse`,
  },
  tscd: {
    'tech-supp-phone': `Téléphone de l’assistance technique`,
    'tech-supp-phone-num': `Numéro de téléphone de l’équipe d’assistance technique`,
    'tech-supp-phone-placeholder': `Numéro de téléphone des équipes d’assistance internes des clients`,
    'tech-supp-email': `E-mail de l’équipe d’assistance technique`,
    'tech-supp-email-placeholder': `E-mail des équipes d’assistance internes des clients`,
  },
  'company-support': {
    'tech-supp-phone': `Numéro de téléphone de l’équipe d’assistance technique`,
    'tech-supp-phone-placeholder': `Numéro de téléphone de contact de votre équipe d’assistance interne`,
    'tech-supp-email': `E-mail de l’équipe d’assistance technique`,
    'tech-supp-email-placeholder': `E-mail de contact de votre équipe d’assistance interne`,
    'admin-warning': `Les utilisateurs Administrateur de l’entreprise peuvent saisir et modifier les détails de l’équipe d’assistance technique`,
    'customer-supp-heading': `Contacts du client`,
    'megaport-supp-heading': `Contacts de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'account-manager': `Responsable de compte`,
    'customer-success-manager': `Responsable Customer Success`,
    'click-chat-icon-msg': `Cliquez sur l’icône de chat pour démarrer une conversation`,
  },
  images: {
    'datacentre-logo': `Logo du centre de données`,
    'megaport-logo': `Logo ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'megaport-cloud-providers-logo': `Fournisseurs de cloud de ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'loading-logo': `Logo de chargement ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'role-icon': `Icône de rôle`,
    'docs-icon': `Icône de documentation`,
    'company-logo': `Logo de l’entreprise`,
    'resize-handle-icon': `Icône Redimensionner le descripteur`,
    'marketplace-logo': `Logo ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'marketplace-profile-logo': `Logo du profil ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'provider-logo': `Logo du fournisseur`,
    'copy-icon': `Icône Copier la valeur`,
    'partner-vantage-logo': `Logo Partner Vantage`,
    'profile-logo': `Logo du profil`,
    'sx-logo': `Logo d’échange de services`,
  },
  ibm: {
    connection: `Connexion IBM`,
    'connection-details': `Détails de la connexion au service IBM Cloud`,
    'account-id': `ID du compte IBM`,
    'account-id-tooltip': `Obtenez cette information auprès du paramètre de votre compte IBM dans le portail IBM.`,
    'customer-asn-tooltip': `ASN BGP public ou privé`,
    'account-id-validation': `Nombre hexadécimal de 32 chiffres`,
    'modal-title': `Connexion au portail IBM`,
    'order-request-sent': `La demande a été envoyée à votre compte sur le portail IBM.`,
    'order-request-reminder': `Il s’agit d’un rappel pour accepter/rejeter la demande de connexion via le portail IBM.`,
    'visit-portal': `Visiter le portail IBM`,
    'edit-modal-updated-api': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} a mis à jour les intégrations API avec IBM.`,
    'edit-modal-recommendation': `Nous recommandons aux utilisateurs de commander de nouveau un ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} IBM Cloud pour pouvoir accéder aux fonctionnalités mises à jour et mettre fin à la connexion existante.`,
    'edit-modal-docs': `Pour plus d’informations, reportez-vous à {link}.`,
  },
  'mexico-billing-market': {
    'company-type-mexico-01-legal-entity': `01 : Entité juridique`,
    'company-type-mexico-02-legal-person': `02 : Personne morale`,
    'company-type-mexico-03-foreign-company': `03 : Entreprise étrangère`,
    'tax-regime-code-mexico-601': `601 - Loi générale - Personnes morales`,
    'tax-regime-code-mexico-603': `603 - Entités juridiques à but non lucratif`,
    'tax-regime-code-mexico-605': `605 - Salaires et revenus assimilés à des salaires`,
    'tax-regime-code-mexico-606': `606 - Bail`,
    'tax-regime-code-mexico-607': `607 - Régime d’aliénation ou d’acquisition de biens`,
    'tax-regime-code-mexico-608': `608 - Autres revenus`,
    'tax-regime-code-mexico-610': `610 - Résidents à l’étranger sans établissement permanent au Mexique`,
    'tax-regime-code-mexico-611': `611 - Revenus de dividendes (partenaires et actionnaires)`,
    'tax-regime-code-mexico-612': `612 - Personnes exerçant des activités commerciales et professionnelles`,
    'tax-regime-code-mexico-614': `614 - Revenus d’intérêts`,
    'tax-regime-code-mexico-615': `615 - Régime de revenus provenant de l’obtention de prix`,
    'tax-regime-code-mexico-616': `616 - Pas d’obligations fiscales`,
    'tax-regime-code-mexico-620': `620 - Sociétés de production coopératives choisissant de différer leurs revenus`,
    'tax-regime-code-mexico-621': `621 - Incorporation fiscale`,
    'tax-regime-code-mexico-622': `622 - Activités agricoles, d’élevage, de sylviculture et de pêche`,
    'tax-regime-code-mexico-623': `623 - Facultatif pour les groupes d’entreprises`,
    'tax-regime-code-mexico-624': `624 - Coordonné`,
    'tax-regime-code-mexico-625': `625 - Régime d’activités commerciales générant des revenus par le biais de plates-formes technologiques`,
    'tax-regime-code-mexico-626': `626 - Régime simplifié de confiance (RESICO)`,
    'sat-purpose-mexico-cn01': `CN01 - Paie`,
    'sat-purpose-mexico-cp01': `CP01 - Paiements`,
    'sat-purpose-mexico-d01': `D01 - Frais médicaux, dentaires et hospitaliers`,
    'sat-purpose-mexico-d02': `D02 - Frais médicaux liés à une incapacité ou à une invalidité`,
    'sat-purpose-mexico-d03': `D03 - Frais d’obsèques`,
    'sat-purpose-mexico-d04': `D04 - Dons`,
    'sat-purpose-mexico-d05': `D05 - Intérêts réels effectivement payés pour des prêts hypothécaires (maison d’habitation)`,
    'sat-purpose-mexico-d06': `D06 - Contributions volontaires au SAR`,
    'sat-purpose-mexico-d07': `D07 - Primes d’assurance pour frais médicaux`,
    'sat-purpose-mexico-d08': `D08 - Frais de transport scolaire obligatoires`,
    'sat-purpose-mexico-d09': `D09 - Dépôts sur des comptes d’épargne, primes basées sur des plans de pension`,
    'sat-purpose-mexico-d10': `D10 - Paiements pour des services éducatifs (frais de scolarité)`,
    'sat-purpose-mexico-g01': `G01 - Acquisition de marchandises`,
    'sat-purpose-mexico-g02': `G02 - Retours, remises ou primes`,
    'sat-purpose-mexico-g03': `G03 - Dépenses en général`,
    'sat-purpose-mexico-i01': `I01 - Bâtiments`,
    'sat-purpose-mexico-i02': `I02 - Mobilier et équipement de bureau pour investissements`,
    'sat-purpose-mexico-i03': `I03 - Équipement de transport`,
    'sat-purpose-mexico-i04': `I04 - Équipement et accessoires informatiques`,
    'sat-purpose-mexico-i05': `I05 - Matrices, moules et outillage`,
    'sat-purpose-mexico-i06': `I06 - Communications téléphoniques`,
    'sat-purpose-mexico-i07': `I07 - Communications par satellite`,
    'sat-purpose-mexico-i08': `I08 - Autres machines et équipements`,
    'sat-purpose-mexico-s01': `S01 - Pas d’effets fiscaux (pas d’obligations fiscales)`,
  },
  'survey-banner': {
    'customer-survey-link': `Nous serions ravis d’avoir votre avis ! Cliquez ici pour répondre à notre enquête client.`,
  },
  'mp-one': {
    'platform-orchestrate-network-services': `UNE plate-forme pour orchestrer, mettre en réseau et activer des services cloud.`,
    'try-megaport-one': `Essayez Megaport ONE`,
    'learn-more': `En savoir plus`,
    'click-here-to-try-mp-one': `Cliquez ici pour tester la version bêta de Megaport ONE. Une plate-forme pour orchestrer, connecter et lancer des solutions cloud.`,
    'terms-and-conditions': `Vous venez d’accéder au Portail MegaportONE.nnEn accédant au Portail MegaportONE, vous acceptez d’être tenu(e) de respecter les conditions générales suivantes.nnLes informations et services fournis sur le Portail MegaportONE sont actuellement mises à la disposition des utilisateurs gratuitement.nnMegaport se réserve le droit d’ajouter des frais ou des charges pour certaines fonctionnalités ou certains services à l’avenir.nnLesdits frais ou charges seront clairement communiqués au préalable aux utilisateurs.nnNotez que le Portail MegaportONE est actuellement en phase de test bêta et qu’il se peut que toutes les fonctionnalités ou tous les services ne soient pas disponibles en permanence.nnMegaport se réserve le droit de modifier, de suspendre ou de mettre fin à toute partie du site Internet ou de ses fonctionnalités sans préavis.nnNous ne garantissons pas un accès ininterrompu ou sans erreur au Portail MegaportONE.`,
  },
  'view-mode': {
    tooltip: `Affichez vos services sous forme de tableau ou de carte`,
    tabular: `Tableau`,
    map: `Carte`,
  },
  map: {
    'all-locations': `Afficher tous les sites`,
    'occupied-locations': `Afficher uniquement les sites où j’ai des services`,
    'filtered-locations': `Filtrer l’affichage des sites où je n’ai pas de services`,
    'thing-available': `{thing} disponible`,
    'dc-provider': `Fournisseur de centre de données`,
    'locations-filtered': `Les sites affichés sont filtrés`,
    'zoom-in': `Zoom avant`,
    'zoom-in-lots': `Grand zoom avant`,
    'zoom-out': `Zoom arrière`,
    'zoom-out-lots': `Grand zoom arrière`,
    settings: `Paramètres`,
    key: `Légende de la carte`,
    help: `Aide et instructions`,
    'help-action': `Action`,
    'help-description': `Description`,
    'help-items': {
      'zoom-in-title': `Zoom avant`,
      'zoom-in-action-1': `Double-clic`,
      'zoom-in-action-2': `Touche =`,
      'zoom-in-action-3': `Touche +`,
      'zoom-in-action-4': `Maintenir la touche ⌘/CTRL et la molette de la souris enfoncées`,
      'zoom-in-action-5': `Maintenir la touche Alt/Option enfoncée et faire glisser vers le bas`,
      'zoom-in-action-6': `Pincement`,
      'double-zoom-in-title': `Zoom avant double vitesse`,
      'double-zoom-in-action-1': `Touche Maj =`,
      'double-zoom-in-action-2': `Touche Maj +`,
      'zoom-out-title': `Zoom arrière`,
      'zoom-out-action-1': `Maj double-clic`,
      'zoom-out-action-2': `Touche -`,
      'zoom-out-action-3': `Maintenir la touche ⌘/CTRL et la molette de la souris enfoncées`,
      'zoom-out-action-4': `Maintenir la touche Alt/Option enfoncée et faire glisser vers le haut`,
      'zoom-out-action-5': `Pincement`,
      'double-zoom-out-title': `Zoom arrière double vitesse`,
      'double-zoom-out-action-1': `Touche Maj -`,
      'area-zoom-in-title': `Zoom avant sur une zone`,
      'area-zoom-in-action-1': `Maj et glisser la zone`,
      'pan-title': `Vue panoramique de la carte`,
      'pan-action-1': `Glissement`,
      'pan-action-2': `Touches fléchées`,
      'orientation-title': `Changer l’orientation de la carte`,
      'orientation-action-1': `Glissement à deux doigts`,
      'orientation-action-2': `Maj ←, → pour pivoter, Maj ↑ pour réinitialiser`,
    },
    'ix-count': `0 IX | 1 IX | {count} IX`,
    'location-cluster': `Ce groupe représente {locationString}.`,
    'location-ix-cluster': `Ce groupe représente {locationString} et {ixString}.`,
    'click-expand': `Cliquez pour développer.`,
    'click-empty-location': `Cliquez pour afficher les détails et ajouter des services`,
    'supported-products': `Ce site prend en charge les produits suivants :`,
    'products-location': `Vous disposez des produits suivants sur ce site :`,
    'click-details': `Cliquez pour afficher les détails`,
    'line-connections-count': `- | Cette ligne représente 1 connexion : | Cette ligne représente {count} connexions`,
    'location-deployment': `Ce site sera bientôt actif. Vous pouvez dès à présent précommander vos services.`,
    'view-in-map': `Afficher ce service sur une carte`,
    'navigate-to': `Accéder à {serviceName}`,
    'search-tips': `Vous pouvez rechercher :<ul><li>Tout type de produit, par ex., VXC</li><li>Tout nom de service</li><li>ID de produit</li><li>Sites compatibles avec Megaport (si vous n’avez pas désactivé cette fonction dans les paramètres de votre carte)</li><li>Ou tout autre site dans le monde entier</li></ul>`,
    'key-items': {
      'occupied-location': `Un seul site pour vos services. Vous pouvez cliquer sur ces épingles pour afficher les détails des services que vous avez sur ce site et pour y commander des services supplémentaires.`,
      spiderified: `Lorsque vous cliquez sur un site où vous avez des services, l’écran se développe et affiche l’ensemble des ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}, ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} et ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} dont vous disposez sur ce site, ainsi que le site lui-même. Les icônes sont codées par couleur pour indiquer si le service est actif ou non. Les ports tiers auxquels vous êtes connecté(e) sont représentés par des icônes noires. Cliquez sur une icône pour afficher les détails.`,
      'occupied-location-cluster': `Un groupe de sites où vous avez des services. L’anneau autour est coloré pour indiquer la proportion des connexions commençant dans le groupe qui sont actives ou non. Cliquez pour développer.`,
      'unoccupied-location': `Un site unique avec des services ${process.env.VUE_APP_EN_COMPANYINFO_NAME} disponibles, mais où vous n’avez actuellement aucun service. Cliquez pour afficher les détails du site et y commander des services. Vous pouvez désactiver l’affichage des sites inoccupés dans les paramètres de la carte.`,
      'unoccupied-location-cluster': `Un groupe de sites où vous n’avez pas de services. Cliquez pour développer. Vous pouvez désactiver l’affichage des sites inoccupés dans les paramètres de la carte.`,
      'ix-pin': `Une épingle virtuelle représentant un ${process.env.VUE_APP_EN_PRODUCTNAME_IX} dans cette zone métropolitaine.`,
      'mid-connection': `Un marqueur représentant les connexions entre deux sites ou des groupes de sites. Une seule ligne peut représenter plusieurs connexions. Le marqueur est vert si toutes les connexions sont actives, rouge si toutes les connexions sont inactives, et moitié vert-moitié rouge si seules certaines connexions sont actives. Cliquez sur le marqueur pour afficher toutes les connexions représentées.`,
      connections: `Les lignes entre les sites représentent les connexions. La couleur de la ligne indique si la connexion est active ou non. Dans ce cas, il existe trois connexions actives et une connexion inactive.`,
    },
    'partner-count': `- | Vous avez 1 connexion ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} tierce sur ce site. | Vous avez {count} connexions ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} tierces sur ce site.`,
    'ix-incoming-count': `- | Vous avez 1 connexion ${process.env.VUE_APP_EN_PRODUCTNAME_IX} entrante. | Vous avez {count} connexions ${process.env.VUE_APP_EN_PRODUCTNAME_IX} entrantes.`,
    fullscreen: `Mode plein écran`,
    big: `Grand écran (widgets de service non visibles)`,
    small: `Petit écran (widgets de service visibles)`,
    'fullscreen-error-title': `Échec d’accès au mode plein écran`,
    'fullscreen-error-message': `Erreur lors de la tentative d’activation du mode plein écran : {errorMessage} ({errorName})`,
    'not-ready-title': `La carte n’est pas prête`,
    'not-ready-message': `Certains éléments de la carte sont encore en cours de chargement. Réessayez dans quelques secondes`,
    'initialization-error': `Impossible d’afficher la carte de services : {message}`,
    globe: `Afficher sous forme de globe`,
    mercator: `Afficher sous forme de projection (Mercator) à plat`,
  },
  'cancel-reason': {
    label: `Motif de l’annulation`,
    'moved-dc': `Déménagement du centre de données`,
    'order-error': `Commandé par erreur ou renouvellement de la commande`,
    'switch-provider': `Changement de fournisseur`,
    'proof-of-concept': `Démonstration de faisabilité/test`,
    other: `Motif non répertorié`,
    'project-ended': `Projet terminé`,
    'cloud-native-move': `Migration vers une solution cloud native`,
    pricing: `Tarification`,
    'product-performance': `Performances du produit`,
    'feedback-prompt': `Aidez-nous à nous améliorer en fournissant des informations plus détaillées sur le motif de votre annulation`,
  },
  entitlements: {
    description: `Cette page affiche les droits AWS qui ont été achetés via le marketplace AWS. Vous pouvez filtrer cette liste pour afficher/masquer les droits qui ont expiré. Notez que seuls les droits qui sont {inUse} ont un service qui leur est associé.`,
    'in-use': `En cours d’utilisation`,
    loading: `Chargement des droits`,
    'no-match': `Aucun droit correspondant`,
    'service-id': `ID du service`,
    'agreement-id': `ID de l’accord AWS`,
    'vendor-agreement-id': `ID de l’accord du fournisseur`,
    'vendor-account-id': `ID du compte du fournisseur`,
    sku: `UGS`,
    'start-date': `Date de début du contrat`,
    'end-date': `Date de fin du contrat`,
    status: `Statut`,
    'product-type': `Type de produit`,
    failed: `Échec de chargement des droits`,
    available: `Disponible`,
    expired: `Expiré`,
  },
  'service-status': {
    maintenance: `Maintenance`,
    outages: `Pannes`,
    timezone: `Fuseau horaire`,
    sort: `Trier`,
    filter: `Filtrer`,
    'search-event-placeholder': `Saisissez un texte pour filtrer des événements`,
    status: `Statut`,
    'start-date': `Date de début`,
    ascending: `Croissant`,
    descending: `Décroissant`,
    ongoing: `En cours`,
    scheduled: `Planifié`,
    completed: `Terminé`,
    cancelled: `Annulé`,
    resolved: `Résolu`,
    'in-last-7-days': `au cours des 7 derniers jours`,
    'no-data-text': `Résultats correspondant aux critères sélectionnés introuvables. Essayez d’ajuster vos critères de filtrage.`,
    'start-utc': `Début (UTC) :`,
    'end-utc': `Fin (UTC) :`,
    duration: `Durée :`,
    'resolution-details': `Détails de la résolution`,
    'root-cause': `Cause principale :`,
    resolution: `Résolution :`,
    'mitigation-actions': `Actions d’atténuation :`,
    'update-notifications': `Notifications de mise à jour`,
    'impacted-services': `Services impactés`,
    'hide-updates': `Masquer les mises à jour précédentes`,
    'show-updates': `Afficher les mises à jour précédentes`,
    more: `Voir plus`,
    less: `Voir moins`,
    'incident-notification': `Notification d’incident`,
    'status-update': `Suivant - Notification d’incident`,
    'resolved-status': `Résolu – Notification d’incident`,
    'latest-status': `Dernier - Notification d’incident`,
    'next-update-etr': `Prochaine mise à jour/ETR :`,
    'notification-description': `Notez qu’une panne affecte actuellement votre service. Nous travaillons activement à rétablir les services, c’est notre priorité absolue.`,
    'next-update-text': `Les mises à jour seront fournies dès qu’elles seront disponibles. Nous vous remercions de votre patience pendant que nous travaillons à résoudre cette panne.`,
    'maintenance-outages': `Maintenance et pannes`,
    'refresh-error': `Impossible d’actualiser les événements`,
    'type-label': `Type`,
    'name-label': `Nom`,
    'id-label': `ID`,
    'actions-label': `Actions`,
    details: `Détails`,
    map: `Carte`,
  },
}
