export default {
  productNames: {
    port: `${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    ports: `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    mcr: `${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    mcrs: `${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}`,
    mcrLong: `${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG}`,
    mve: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    mveLong: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE_LONG}`,
    vxc: `${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    vxcs: `${process.env.VUE_APP_EN_PRODUCTNAME_VXCS}`,
    ix: `${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    ixs: `${process.env.VUE_APP_EN_PRODUCTNAME_IXS}`,
    megaIx: `${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX}`,
    lag: `${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    lags: `${process.env.VUE_APP_EN_PRODUCTNAME_LAGS}`,
    lagLong: `${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG}`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    marketplaceShort: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    transitVxc: `${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}`,
    mves: `${process.env.VUE_APP_EN_PRODUCTNAME_MVES}`,
  },
  companyInfo: {
    name: `${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    portalUrl: `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_URL}`,
    salesEmail: `${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}`,
    arGlobalEmail: `${process.env.VUE_APP_EN_COMPANYINFO_AR_GLOBAL_EMAIL}`,
    portal: `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}`,
  },
  services: {
    'loading-services': `Carregando Serviços...`,
    'diversity-zone': `Zona de Diversidade`,
    'create-diverse-pair': `Criar Par Diverso`,
    'red-zone': `Zona Vermelha`,
    'blue-zone': `Zona Azul `,
    'invoice-reference': `Referência de Nível de Serviço`,
    'invoice-reference-explanation': `A Referência de Nível de Serviço aparecerá nas linhas da fatura do Produto / Serviço associado`,
    'rate-limit': `Limite de Taxa`,
    'select-rate-limit': `Escolha Limite de Taxa`,
    'max-rate-limit': `Limite Máximo de Taxa`,
    'service-key': `Chave de Serviço`,
    'service-deleted': `Este serviço foi removido dos dados. Redirecionando para serviços`,
    'configuration-details': `Detalhes da Configuração`,
    'service-logs': `Registros de Serviço`,
    reloading: `A informação de serviço está sendo recarregada. Por favor aguarde.`,
    'initial-bgp-state': `Estado BGP Inicial`,
    saved: `Sua configuração foi salva.`,
    'service-id': `ID de Serviço`,
    'provisioning-status': `Status do Provisionamento`,
    'loading-status': `Carregando status do serviço...`,
    'unknown-status': `Não podemos determinar o estado deste serviço`,
    media: `Mídia`,
    speed: `Velocidade`,
    allocated: `Alocado`,
    demarcation: `Demarcação`,
    up: `Serviço está ativo`,
    down: `Serviço está inativo`,
    'check-logs': `Confira os registros para mais informações`,
    'product-name': `Nome do produto`,
    'rate-limit-fixed': `Aviso: O Limite de Taxa não pode ser modificado para este tipo de serviço após o lançamento.`,
    'fixed-rate': `(taxa fixa)`,
    'service-status': `Status do Serviço`,
    'service-information': `Informação do Serviço`,
    'loading-resources': `Carregando recursos. Por favor aguarde.`,
    'loading-issue': `Houve um problema ao carregar os dados. Por favor fale com o suporte`,
    'identifier-copy': `Identificador de Serviço - clique para copiar`,
    decommissioned: `Este serviço já está desativado`,
    filter: `Filtrar Serviços`,
    'expand-collapse': `Expandir ou minimizar todos os serviços`,
    'some-speeds-disabled': `Aviso: Algumas velocidades podem estar desativadas porque um serviço anexo está acima dessa velocidade.`,
    'diversity-option': `Opção de Diversidade`,
    'loading-state-info': `Carregando informações atualizadas de serviço.`,
    'waiting-on-deploy': `Lançamento do serviço em progresso`,
    'no-company': `Nenhuma empresa disponível.`,
    'cancelled-when': `O serviço cancelado terminará em {when}`,
    'click-restore': `Clique para restaurar o serviço`,
    'cancel-unknown': `O serviço cancelado terminará numa data desconhecida.`,
    'pending-internal-speed-approval': `Esperando sua aprovação para uma solicitação de mudança de velocidade de {where}`,
    'pending-internal-connection-approval': `Esperando sua aprovação para uma conexão de {where}`,
    'pending-external-connection-approval': `Esperando aprovação externa para uma conexão de {where}`,
    'pending-3rd-party-speed-approval': `Esperando aprovação externa para uma mudança de velocidade de {where}`,
    'pending-3rd-party-connection-approval': `Esperando aprovação externa de uma conexão de {where}`,
    'gci-waiting-configure': `Esperando por sessão BGP que está chegando.`,
    'waiting-for-resources': `Este serviço está esperando ser ativado pela primeira vez`,
    'in-design-not-deployed': `Não lançado ainda. Edite os detalhes e complete o lançamento.`,
    'in-design-not-ordered': `Ainda não solicitado. Solicite usando a barra lateral à esquerda.`,
    'approve-connections': `Aprovar conexões`,
    'approve-connections-list': `Você tem as seguintes conexões esperando aprovação:`,
    'best-route': `Melhor Rota`,
    'next-hop-vxc': `Próximo hop ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    bgp: `BGP`,
    'type-locked': `Este {type} está travado`,
    'type-being-deployed': `Este {type} está sendo lançado`,
    'type-unlock': `Clique para destravar este {type} e permitir edições`,
    'type-unlock-notlive': `Este {type} não está ativo e não pode ser destravado`,
    'type-locked-admin': `Este {type} está travado por um admin e não é editável`,
    'type-lock': `Clique para travar este {type} e prevenir edições e apagamento`,
    'type-lock-notlive': `Este {type} não está ativo e não pode ser destravado`,
    'type-unlocked-admin': `Este {type} está destravado e só pode ser travado por um admin`,
    'type-locked-megaport': `Este {type} foi travado por ${process.env.VUE_APP_EN_COMPANYINFO_NAME} e não é editável. Entre em contato com o Suporte da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} para mais informações.`,
    'type-cancelled': `Este {type} foi cancelado`,
    'terminate-count': `N/A | Sim, Desativar 1 Serviço | Sim, Desativar {count} Serviços`,
    'service-locked': `Aviso: Este serviço está travado por ${process.env.VUE_APP_EN_COMPANYINFO_NAME} e não pode ser editado ou apagado. Entre em contato com o suporte da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} para mais informações.`,
    'service-admin-locked': `Aviso: Este serviço está travado e não pode ser editado ou apagado. Apenas um Admin da Empresa pode destravar este serviço.`,
    'edit-permission-denied': `Aviso: Você não tem permissão para editar este serviço.`,
    'ip-address': `Endereço de IP`,
    'peer-ip-address': `Endereço de IP do Peer`,
    'creation-date': `Data de Criação`,
    'replace-configured': `Substituir Serviços Configurados`,
    'replace-confirmation': `Isso removerá seus itens atualmente configurados e os substituirá por este conjunto salvo. Continuar?`,
    'delete-configuration': `Apagar Configuração Salva`,
    // O nome é o nome da porta/mcr/mve e o productType é o tipo de objeto semelhante a porta que foi atualizado (Porta, MCR, MVE)
    'port-like-updated-title': `{name} {productType} atualizado`,
    'port-like-updated-message': `Atualizações salvas para o {productType} '{name}'`,
    'click-setup': `Clique para configurar`,
    'new-mve-message': `Atenção! Conectividade à internet é obrigatória para o software/imagem do ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} ser validado. Você pode escolher entre ativar o ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}, ou conectar seu próprio acesso à internet.`,
    'connection-denied': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} para {destination} foi negado`,
    'connection-approved': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} para {destination} foi aprovado`,
    'amsix-contact-phone': `Um número de telefone é necessário para o {contactType} para um serviço de AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX}.`,
    'gbps-ix': `Gbps ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'gbps-vxc': `Gbps ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    'ibm-request-fail': `Não foi possível recuperar os limites de taxa para esta porta, por favor tente novamente.`,
    'type-state': `Estado do {type}`,
    'shutdown-dialog-title': `Tem certeza que você quer desativar este serviço?`,
    'shutdown-dialog-message': `O tráfego não fluirá por este serviço se ele for desativado. Ele agirá como se estivesse inativo na rede da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'shutdown-dialog-note': `Aviso: A cobrança referente a este serviço permanecerá ativa e você ainda será cobrado por esta conexão.`,
    'sub-uid': `Sub-UID`,
    'type-move-notlive': `Este {type} não está ativo e não pode ser movido`,
    'port-move-notlive': `Este {type} não está ativo e não pode ter suas conexões movidas`,
    'no-movable-type': `Não há conexões que possam ser movidas neste {type}`,
    'move-permission-denied': `Você não tem permissão para mover conexões`,
    'cant-move-multiple-vnics': `Não é possível mover conexões de uma ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} com múltiplas vNICs`,
    'cant-move-no-ports': `Não há outra ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} na mesma Metro`,
    'cant-move-no-mcrs': `Não há outra ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} na mesma região`,
    'cant-move-no-mves': `Não há outra ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} na mesma região`,
    'awaiting-approval': `Esperando por aprovação ou rejeição das mudanças pendentes`,
    'shutdown-confirm-message': `Para desligar, por favor clique em ‘Confirmar’ e depois em ‘Salvar’.`,
    'view-additional-filters': `Ver Filtros Adicionais`,
    'clear-filters': `Limpar Filtros`,
    'service-type': `Tipo de Serviço`,
    'contract-term': `Termo de Contrato`,
    'change-sort-direction': `Mudar Direção da Ordenação`,
    'matching-services': `0 serviços correspondentes ({total} total) | 1 serviço correspondente ({total} total) | {count} serviços correspondentes ({total} total)`,
    'no-matching': `Nenhum serviço correspondente encontrado.`,
    'diversity-location-tooltip': `Filtrar locais por Zonas de Diversidade disponíveis. Se deixado em 'Auto', nós escolheremos uma Zona de Diversidade para você. Para mais informações, veja nosso {link}`,
    'diversity-config-tooltip': `Zona de Diversidade à qual este serviço será alocado.`,
    'auto-zone': `Zona Auto`,
    'download-inventory-report': `Baixar Relatório de Inventário`,
    'expand-all': `Expandir Todos os Serviços`,
    'collapse-all': `Minimizar Todos os Serviços`,
    'extra-actions': `Ações Adicionais`,
    'type-status': `Tipo & Status`,
    'has-ongoing-maintenance': `Evento de manutenção em andamento.`,
    'has-ongoing-outage': `Evento de interrupção de serviço em andamento.`,
  },
  app: {
    'environment-unrecognized': `Ambiente não reconhecido`,
    'unrecognized-url': `Acesso de uma ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_URL} ou uma URL de desenvolvimento reconhecida`,
  },
  general: {
    'delete-type': `Apagar {type}`,
    'edit-type': `Editar {type}`,
    'remove-type': `Remover {type}`,
    'new-type': `Novo {type}`,
    'add-type': `Adicionar {type}`,
    'update-type': `Atualizar {type}`,
    'type-details': `Detalhes do {type}`,
    'type-configuration': `Configurações do {product}`,
    'edit-configured-type': `Editar {type} Configurado`,
    'select-type-location': `Selecionar Local do {type}`,
    'delete-permission-denied': `Você não tem permissão para apagar este {type}`,
    'type-not-found': `{type} não encontrado`,
    'type-not-found-redirect': `Este {type} não foi encontrado nos dados. Redirecionando para serviços`,
    'success-count': `0 sucessos| 1 sucesso | {count} sucessos`,
    'warning-count': `0 avisos | 1 aviso | {count} avisos`,
    'error-count': `0 erros | 1 erro | {count} erros`,
    'count-months': `0 Meses | 1 Mês| {count} Meses`,
    'locations-count': `0 Locais | 1 Local | {count} Locais`,
    'last-log': `Último registro recebido em {last}`,
    'max-rate-limit': `MAX: {maxRateLimit} Mbps`,
    'speed-gbps': `{speed} Gbps`,
    'missing-billing-market': `Mercado de faturamento ausente "{country}".`,
    'create-thing': `Criar {thing}`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    welcome: `Bem-vindo a ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    save: `Salvar`,
    revert: `Reverter`,
    close: `Fechar`,
    cancel: `Cancelar`,
    back: `Voltar`,
    next: `Próximo`,
    done: `Feito`,
    loading: `Carregando...`,
    services: `Serviços`,
    service: `Serviço`,
    price: `Preço`,
    summary: `Resumo`,
    copy: `Copiar`,
    'copy-to-clipboard': `Copiar para a Área de Transferência`,
    'characters-copied': `0 caracteres copiados | 1 caracter copiado | {count} caracteres copiados`,
    version: `Versão`,
    'first-name': `Primeiro Nome`,
    'last-name': `Sobrenome`,
    phone: `Telefone`,
    email: `Email`,
    password: `Senha`,
    ok: `OK`,
    'details-help': `Para mais detalhes, ver este tópico da ajuda:`,
    documentation: `Documentação`,
    saving: `Salvando...`,
    public: `Público`,
    private: `Privado`,
    unspecified: `Não Específicado`,
    'x-end': `{end}-Fim`,
    hide: `esconder`,
    show: `mostrar`,
    'technical-contact': `Contato Técnico`,
    'noc-contact': `Contato NOC`,
    'contact-phone': `telefone de contato`,
    'select-type': `Escolher Tipo`,
    size: `Tamanho`,
    'step-unavailable': `Esta opção não estará disponível enquanto você não completar as etapas anteriores.`,
    'not-available': `Não disponível`,
    'configure-thing': `Configurar {thing}`,
    'modification-delay': `Aviso: Modificações aplicadas podem levar até 2 minutos para aparecer.`,
    billing: `Cobrança`,
    'processing-approval': `Processando aprovação...`,
    'processing-denial': `Processando negação da solicitação...`,
    unknown: `Desconhecido`,
    yes: `Sim`,
    no: `Não`,
    untitled: `Sem título`,
    'no-location': `Nenhum Local Selecionado`,
    'select-location': `Escolher Local`,
    'unknown-location': `Local Desconhecido`,
    configure: `Configurar`,
    'no-matching-countries': `Nenhum país correspondente`,
    'no-matching-locations': `Nenhum local correspondente`,
    'country-filter': `Filtro de País`,
    search: `Buscar`,
    'pre-order': `Pré-encomendar`,
    details: `Detalhes`,
    name: `Nome`,
    success: `Sucesso`,
    disabled: `Desativado`,
    enabled: `Ativado`,
    'shut-down': `Desligar`,
    term: `Termo`,
    red: `Vermelho`,
    blue: `Azul`,
    none: `Nenhum`,
    new: `Novo`,
    general: `Geral`,
    status: `Status`,
    'status-unknown': `Status desconhecido`,
    'created-by': `Criado Por`,
    'privacy-policy': `Política de Privacidade`,
    'site-terms': `Termos do Site`,
    gsa: `Acordo Global de Serviços`,
    'acceptable-use': `Política de Uso Aceitável`,
    'please-select': `Selecionar`,
    'important-information': `Informações Importantes`,
    'agree-terms': `Eu concordo com os termos e condições`,
    'view-details': `Ver Detalhes`,
    'action-confirmation': `Tem certeza que deseja fazer isso?`,
    'unknown-error': `Erro desconhecido`,
    edit: `Editar`,
    remove: `Remover`,
    'request-failed': `Solicitação Falhou`,
    'company-name': `Nome da Empresa`,
    active: `Ativo`,
    inactive: `Inativo`,
    na: `N/A`,
    disable: `Desativar`,
    pricing: `Preços`,
    note: `Nota`,
    website: `Site`,
    'click-to-visit': `Clique para visitar`,
    all: `Todos`,
    ipv4: `IPv4`,
    ipv6: `IPv6`,
    you: `você`,
    'your-company-admin': `o admin da sua empresa`,
    date: `Data`,
    mbps: `Mbps`,
    start: `Início`,
    end: `Fim`,
    duration: `Duração`,
    'loading-details': `Carregando detalhes...`,
    'details-load-failed': `Não foi possível carregar os detalhes`,
    prefix: `Prefixo`,
    'next-hop': `Próximo Hop`,
    'load-failed': `Não Foi Possível Carregar`,
    to: `Para`,
    from: `De`,
    message: `Mensagem`,
    send: `Enviar`,
    sort: `Ordenar`,
    'hide-details': `Clique para esconder os detalhes`,
    'show-details': `Clique para mostrar os detalhes`,
    'logs-label': `Registros`,
    'usage-label': `Uso`,
    'configuration-label': `Configuração`,
    actions: `Ações`,
    confirm: `Confirmar`,
    delete: `Apagar`,
    'reload-data': `Recarregar Dados`,
    network: `Rede`,
    location: `Local`,
    selection: `Seleção`,
    'choose-file': `Escolher arquivo`,
    label: `Rótulo`,
    'single-use': `Uso Único`,
    'multi-use': `Multiuso`,
    add: `Adicionar`,
    'select-date': `Escolher Data`,
    'loading-data': `Carregando dados`,
    description: `Descrição`,
    'click-copy': `Clique para copiar`,
    token: `Token`,
    notice: `Aviso`,
    'end-date': `Data final`,
    existing: `existente`,
    'no-info': `Não há mais informações`,
    'error-updating': `Erro atualizando {thing}.`,
    'click-enable': `Clique para ativar`,
    'updating-details': `Atualizando detalhes...`,
    approve: `Aprovar`,
    view: `Ver`,
    'save-as': `Salvar Como`,
    order: `Ordenar`,
    'show-actions': `Mostrar ações`,
    'hide-actions': `Ocultar ações`,
    'items-per-page': `{count} itens por página`,
    'show-all': `Mostrar todos os itens`,
    origin: `Origem`,
    communities: `Comunidades`,
    updated: `Atualizado`,
    'all-types': `Todos os Tipos`,
    static: `Estático`,
    connected: `Conectado`,
    local: `Local`,
    file: `Arquivo`,
    clear: `Limpar`,
    'no-match-found': `Nenhuma correspondência encontrada`,
    'list-not-available': `Nenhuma lista disponível`,
    'list-select': `Escolher uma lista`,
    'password-toggle': `Marque para mostrar a senha`,
    'exact-label': `Exato`,
    'position-label': `Posição`,
    'min-label': `Min`,
    'max-label': `Max`,
    'operations-label': `Operações`,
    'drag-position-label': `Clique e arraste para posicionar`,
    'example-abbr': `Ex.`,
    star: `Estrela`,
    'standard-label': `Padrão`,
    'advanced-label': `Avançado`,
    'filters-label': `Filtros`,
    'on-label': `Ligado`,
    'off-label': `Desligado`,
    'default-label': `Padrão`,
    'deny-label': `Negar`,
    'permit-label': `Permitir`,
    'required-field-label': `Campo obrigatório`,
    'warning-label': `Aviso`,
    'type-name': `Nome do {type}`,
    'save-error': `Não foi possível salvar suas mudanças: `,
    'copy-thing-to-clipboard': `Copiar {thing} para a Área de Transferência`,
    'error-creating': `Erro ao criar {thing}.`,
    'error-deleting': `Erro ao apagar {thing}.`,
    'success-creating': `{thing} criado com sucesso.`,
    'success-updating': `{thing} atualizado com sucesso.`,
    'success-deleting': `{thing} apagado com sucesso.`,
    'created-thing': `{thing} criado com sucesso`,
    'updated-thing': `{thing} atualizado com sucesso`,
    'word-counter': `{count} palavras de {maxWords}`,
    user: `Usuário`,
    load: `Carregar`,
    update: `Atualizar`,
    'server-error': `Erro ao comunicar com servidor API`,
    'context-switching-title': `Tem certeza que deseja mudar de contas?`,
    'context-switching-message': `Você está prestes a mudar de sua conta da {accountTypeFrom} <strong>{fromCompany}</strong> para sua conta da {accountTypeTo} <strong>{toCompany}</strong>`,
    'feature-flag-access-error': `A {pageName} não pode ser acessada a partir de uma Conta Gerenciada. Selecione sua Conta de Parceiro para acessar a {pageName}.`,
    'open-sidebar': `Abrir barra lateral`,
    'learn-megaport': `Saiba mais sobre os serviços da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ou visite o ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
    'visit-documentation': `Ir para a ${process.env.VUE_APP_EN_COMPANYINFO_DOCUMENTATION_PORTAL}`,
    'visit-portal': `Visite o ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}`,
    'login-trouble': `Problemas para entrar? {link}`,
    'site-disclaimer': `Ao acessar a ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}/landing page você estará submetido à Política de Privacidade, Termos do Site e Política de Uso Aceitável da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'partner-deals': `Ofertas de Parceiros`,
    'cancel-reterm': `Clique em Cancelar se não desejar tomar nenhuma ação e passar para um contrato sem data final pelo preço de tabela ao fim da duração do contrato.`,
    'deleted-thing': `{thing} apagado com sucesso`,
    'unexpected-error': `Um erro inesperado ocorreu. Tente novamente mais tarde. Se este problema persistir, entre em contato com ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'unsaved-changes-confirm-message': `Todas as mudanças não salvas serão perdidas`,
    'unsaved-changes-confirm-title': `Sair da página sem salvar?`,
    'unsaved-changes-confirm-cancel': `Permanecer na página`,
    'unsaved-changes-confirm-ok': `Sair da página`,
    'not-now': `Agora não`,
    up: `Para cima`,
    down: `Para baixo`,
    beta: `Beta`,
    languages: `Idiomas`,
    expand: `Expandir`,
    collapse: `Minimizar`,
    here: `aqui`,
    true: `verdadeiro`,
    false: `falso`,
    'get-help': `Buscar Ajuda`,
    'type-shutdown-state': `Aviso: Este {type} está em estado desativado`,
    continue: `Continuar`,
    accept: `Aceitar`,
    decline: `Negar`,
    'terms-and-conditions': `Termos e Condições`,
    'lag-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_LAGS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_LAGS}`,
    'mcr-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}`,
    'mve-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES}`,
    connection: `Conexão`,
    connections: `Conexões`,
    'move-to': `Mover para`,
    'not-submitted': `Não enviado`,
    processing: `Processando`,
    failure: `Falha`,
    move: `Mover`,
    'select-all': `Selecionar Todos`,
    'deselect-all': `Desselecionar todos`,
    'non-prod': `Este não é um ambiente de produção`,
    auto: `Auto`,
    'preparing-download': `Preparando o download...`,
    'download-failed-title': `Falha no download`,
    'download-failed': `Não foi possível baixar o arquivo, por favor tente novamente mais tarde.`,
    mobile: `Mobile`,
    activate: `Ativar`,
    deactivate: `Desativar`,
    valid: `Válido`,
    invalid: `Inválido`,
    type: `Tipo`,
    expired: `Expirado`,
    comments: `Comentários`,
    refresh: `Recarregar`,
    'pre-release': `Pré–lançamento`,
    'logs-count': `1 entrada de registro recebida nas últimas 24 horas | {count} entradas de registro recebidas nas últimas 24 horas`,
    'most-recent-n-events': `mostrando os {count} eventos mais recentes`,
    'no-logs': `Não há registros para este serviço`,
  },
  tour: {
    'start-tour': `Iniciar o Tour`,
    'find-location': `Encontrar um Local`,
    'location-selected': `Local Selecionado`,
    'add-a-service': `Adicionar um Serviço`,
    'actions-panel': `Painel de Ações`,
    'live-chat': `Chat ao Vivo 24/7`,
    'next-steps': `Próximos Passos`,
    start: `É aqui que você pode criar, lançar e gerenciar serviços como ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}, ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}, ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS}, e ${process.env.VUE_APP_EN_PRODUCTNAME_IXS}.<br/><br/>Clique Próximo para continuar.`,
    'intro-port': `Uma ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} é a sua conexão à infraestrutura da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} e acesso a uma série de pontos finais (endpoints) de nuvem, troca de internet (IX), e outros.<br/><br/>Clique Próximo para criar uma ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'select-port': `Seu primeiro passo é escolher um local para a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.<br/><br/>Clique Próximo para escolher um local.`,
    'location-selected-content': `Com um local escolhido, configure a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.<br/><br/>Clique Próximo para configurar a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'port-configuration': `Configuração da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'configure-port': `Configure sua ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} enviando a informação necessária.<br/><br/>Clique Próximo para adicionar a informação referente à ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    price: `Com a informação sobre a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} fornecida, o preço aparece.<br/><br/>Clique Próximo para ir para a próxima tela.`,
    summary: `Esta é a etapa final para configurar sua ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}. Você não será cobrado até que você a encomente.<br/><br/>Clique Próximo para adicionar a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'port-configured': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} Configurada`,
    'configured-port': `Sua ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} configurada aparecerá na página de Serviços, onde você pode editar a configuração clicando na ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ou no ícone de engrenagem na direita.<br/><br/>Clique Próximo para ir para a tela seguinte.`,
    'add-service': `O próximo passo é adicionar um serviço à sua ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} recém-configurada. Clique no ícone +Conexão do lado direito da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ou escolha dentre as opções abaixo.`,
    'actions-panel-content': `Durante esta demonstração, a sua ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} configurada aparece aqui junto com quaisquer outras ações necessárias para fazê-la funcionar.<br/><br/>Se você tiver ações pendentes, elas aparecerão na barra lateral.`,
    chat: `Precisa de ajuda?? Clique aqui para começar uma conversa em tempo real com um especialista da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'next-steps-no-kb': `Você chegou ao final do  tour!<br/><br/>Agora você pode visitar a página de Serviços e criar a sua primeira ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'next-steps-kb-html': `Você chegou ao final do  tour!<br/><br/>Não se esqueça de visitar a nossa <a href="https://docs.megaport.com/" target="_blank">Documentação</a> ou <a href="https://www.youtube.com/channel/UCdVx98wdrhcTCIuw8UE5hIA" target="_blank">nosso canal do YouTube</a> para mais ajuda.<br/><br/>Agora você pode visitar a página de Serviços e criar a sua primeira ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'next-steps-partial-html': `Você chegou ao final da tour!<br/><br/>Não se esqueça de visitar a nossa <a href="https://docs.megaport.com/" target="_blank">Documentação</a> ou <a href="https://www.youtube.com/channel/UCdVx98wdrhcTCIuw8UE5hIA" target="_blank">nosso canal do YouTube</a> para mais ajuda.<br/><br/>Agora você pode criar a sua primeira ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    exit: `Sair do  Tour`,
  },
  about: {
    why: `Por que ${process.env.VUE_APP_EN_COMPANYINFO_NAME}?`,
    provisioning: `Provisione conexões em 59 segundos na Rede ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    scalable: `Conectividade Escalável`,
    'scalable-content': `Aumente e diminua a sua largura de banda a qualquer momento de acordo com as demandas do seu negócio.`,
    'real-time': `Provisionamento em Tempo Real`,
    api: `Integração API Poderosa`,
    'api-content': `Gerencie autonomamente sua conectividade e otimize seu fluxo de trabalho utilizando a nossa poderosa API.`,
    'about-megaport': `Sobre ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'about-megaport-video': `Vídeo sobre ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'megaport-features': `Recursos da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
  },
  authentication: {
    'about-company': `Sobre ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'password-reset': `Sua conta exige uma redefinição de senha. Crie uma nova senha aqui para fazer login.`,
    'setting-up': `Configurando sua conta...`,
    'password-strength': `Força da Senha`,
    'very-weak': `Muito fraca`,
    weak: `Fraca`,
    moderate: `Moderada`,
    good: `Boa`,
    excellent: `Excelente`,
    'good-password': `Essa é uma boa senha`,
    'excellent-password': `Essa é uma senha excelente`,
    agreement: `Acordo`,
    'agree-to': `Concordar`,
    'create-account': `Criar Conta`,
    google: `Entrar com Google`,
    'have-account': `Já tem uma conta?`,
    'log-in': `Login`,
    'one-time-token': `Token de Uso Único`,
    'new-password': `Sua Nova Senha`,
    'target-username': `Nome de Usuário Alvo`,
    forgot: `Esqueceu a Senha`,
    register: `Cadastrar Agora`,
    'processing-reset': `Processando pedido de redefinição...`,
    'send-reset': `Enviar Redefinição`,
    resetting: `Redefinindo Senha...`,
    reset: `Redefinir Senha`,
    'trying-login': `Tentando fazer login...`,
    'please-login': `Login`,
    'failed-login': `Não foi possível entrar com as suas credenciais existentes. Por favor faça login novamente.`,
    'password-reset-title': `Redefinir Senha`,
    'missing-reset-token': `Token de redefinição de senha não encontrado. Vá para a página de Login para enviar um novo pedido de Esqueceu Senha.`,
    'logging-in': `Entrando...`,
    'need-to-reset': `Você precisará redefinir sua senha. O formulário foi  atualizado com o campo necessário.`,
    failed: `Login Falhou`,
    'error-registering': `Erro ao Cadastrar`,
    'unknown-error-registering': `Erro desconhecido ao tentar cadastrar`,
    'reset-email-sent': `Um email foi enviado a você para redefinir sua senha.`,
    'reset-request-failed': `Redefinição de Senha - Solicitação Falhou`,
    'unknown-error-reset': `Erro desconhecido ao tentar redefinir a senha`,
    'password-change-successful': `A senha foi alterada com sucesso. Você agora  pode entrar com a sua nova senha.`,
    'password-reset-failed': `Não  foi possível redefinir a senha. Para redefinir novamente, vá para a página de Login e envie um novo pedido de Esqueceu Senha.`,
    'auth-failed': `Falha na Autenticação`,
    'auth-failed-message': `Não foi possível autenticar sua conta`,
    'confirm-email-label': `Confirmando Endereço de Email`,
    confirming: `Confirmando. Por Favor Aguarde`,
    success: `Seu email foi confirmado`,
    'failed-confirm': `Falha na confirmação do seu endereço de email. Tente novamente mais tarde.`,
    'return-portal': `Voltar ao Portal`,
    'registered-company': `Empresa cadastrada: {name}`,
    'updated-company': `Empresa atualizada: {name}`,
    'login-success-title': `Login com sucesso!`,
    'login-success-message': `{name} fez login`,
    'login-failed-token': `Falha ao fazer login com o seu token. Por favor faça login novamente.`,
    'use-security-code': `Usar código de segurança`,
    'temp-password': `Sua Senha temporária`,
    'confirm-password': `Confirmar Senha`,
    'login-error': `Erro de Login`,
    'login-error-msg': `Não conseguimos fazer login usando suas credenciais. Está tendo dificuldades com o login? <a href="https://www.megaport.com/contact/support/" target="_blank">Peça Ajuda</a>`,
    submit: `Enviar`,
    'vendor-onboarding': {
      cisco: {
        'intro-header': `Bem-vindo ao Cisco SD-WAN Cloud Interconnect com ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
        'intro-content': `Estamos muito felizes de te ver por aqui e esperamos que você tenha uma ótima experiência usando o produto. Você está a apenas alguns passos de ser capaz de criar conexões dedicadas aos seus ambientes de nuvem pelo vManage e aproveitar o Cisco SD-WAN rodando em cima da rede underlay de alta disponibilidade da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
        'temp-password-info': `<b>Insira seu email e senha temporária.</b> Após alterar sua senha você poderá colocar suas credenciais no Cisco vManage versão 20.5 ou mais recente para fazer pedidos ao MVE, criar VXCs de MVEs para outros destinos na rede Megaport, e para apagar o mesmo, em vez de usar o ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
        'new-password-info': `<b>Insira sua nova senha.</b> Após alterar sua senha você poderá colocar suas credenciais no Cisco vManage versão 20.5 ou mais recente para fazer pedidos ao MVE, criar VXCs de MVEs para outros destinos na rede Megaport, e para apagar o mesmo, em vez de usar o ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
        'password-changed-info': `E agora? Insira essas credenciais no Cisco vManage versão 20.5 ou mais recente para provisionar ${process.env.VUE_APP_EN_PRODUCTNAME_MVES}, ou para criar ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} entre ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} e provedores de serviços de nuvem.`,
        'temp-password-intro': `Insira seu email e senha temporária. `,
        'new-password-intro': `Insira sua nova senha. `,
        'password-changed-intro': `Sua senha foi alterada! `,
        'password-info': `Após alterar sua senha você poderá colocar suas credenciais no Cisco vManage versão 20.5 ou mais recente para fazer pedidos de ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}, criar  ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} de  ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} a outros destinos na rede da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}, e para apagar o mesmo, em vez de usar o ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
      },
    },
    'verify-email-heading': `Por favor verifique seu email`,
    'verify-email-subheading': `Enviamos a você um link para confirmar sua conta`,
    'verify-email-description': `Não recebeu o email? Por favor verifique sua pasta de spam para garantir que ele não foi para lá.`,
    'verify-email-button': `Reenviar Email`,
    'verify-email-resent-successful': `Email de confirmação reenviado com sucesso.`,
    'verify-email-resent-unsuccessful': `Algo deu errado ao reenviar seu email de confirmação.`,
    'password-must-include': `Sua senha deve`,
    'at-least-8-char': `Ter ao menos 8 caracteres`,
    'at-least-one-number': `Ter ao menos um número`,
    'at-least-one-symbol': `Ter ao menos um símbolo`,
    'at-least-one-lower-case-letter': `Ter ao menos uma letra minúscula`,
    'at-least-one-upper-case-letter': `Ter ao menos uma letra maiúscula`,
    'create-an-account': `Criar uma Conta`,
    or: `Ou`,
    'signup-email': `Entrar com Email`,
    'signup-google': `Entrar com Google`,
    'login-to-your-account': `Fazer login em sua Conta`,
    'signup-success': `Cadastrado com sucesso!`,
    'signup-failed': `Falha no cadastro, por favor tente novamente.`,
    login: `Login`,
    'login-google': `Login com Google`,
    'setting-company': `Aviso: Ao salvar o novo Nome Comercial da Empresa sua sessão será encerrada. Você precisará fazer login novamente para ser adicionado à nova Empresa.`,
    'reset-message': `Se essa conta existe, um email foi enviado para redefinir sua senha.`,
    'signup-google-instructions': `Por favor insira um nome comercial da empresa e revise os termos abaixo para continuar.`,
    signup: `Cadastrar`,
    'create-account-email': `Criar Conta com email `,
    'confirm-new-password': `Confirmar Nova Senha`,
    'new-password-no-your': `Nova Senha`,
    'reset-expired-dialog-title': `O link de redefinição usado não é mais válido.`,
    'reset-expired-dialog-desc': `O link usado no email expirou ou não está mais válido. Clique. "Reenviar Link" ou entre em contato com ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'resend-link': `Reenviar Link`,
    'unexpected-error-dialog-title': `Um erro inesperado ocorreu. Tente novamente mais tarde.`,
    'unexpected-error-dialog-desc': `Se esse erro persistir entre em contato com ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'login-megaport': `Entrar na ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'email-verified': `Seu email foi verificado`,
    'invalid-link': `Este link não é mais válido`,
    'request-link': `Clique no botão para receber um link novo em seu email cadastrado.`,
    'verified-account': `Esta conta já é verificada`,
    'verified-email': `Seu email já foi verificado`,
    'continue-to-megaport': `Continuar para ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}`,
    'company-trading-name-instructions': `Por favor insira um nome comercial da empresa e revise os termos abaixo para continuar.`,
    'change-email-desc': `Insira seu novo endereço de email e clique em Obter Código de Verificação. Um código de verificação será enviado para seu novo endereço de email e deve ser inserido abaixo. Não feche esta janela.`,
    'change-email-warning': `Você precisará fazer login novamente após alterar seu email..`,
    'verification-code': `Código de Verificação`,
    'get-verification-code': `Obter Código de Verificação`,
    'verify-email-taken': `Este email já está sendo usado por outro usuário.`,
    'verification-code-expired': `O Código de Verificação expirou, por favor solicite um novo email de verificação`,
    'verification-code-sent': `Enviamos um código de verificação único`,
    'verification-code-failed': `Algo deu errado ao recuperar um código de verificação`,
    'verification-code-wrong': `O código de verificação está incorreto`,
    'setup-mfa-cancel-confirm': `Ao cancelar você será levado de volta à tela de login. Você não conseguirá fazer login até que a autenticação de múltiplos fatores (MFA) esteja configurada.`,
    'setup-mfa-reset': `O Admin da sua Empresa redefiniu e desativou o seu código de autenticação de múltiplos fatores (MFA). Por favor configure a MFA novamente para fazer login.`,
    'setup-mfa-enforced': `O Admin da sua Empresa está exigindo Autenticação Multi-Fatores (MFA). Você não poderá fazer login até ter configurado a MFA.`,
    'enabling-mfa': `Ativando MFA`,
    'mfa-token-invalid': `O código de uso único {code} está inválido. Após 5 tentativas consecutivas sem sucesso, sua conta será travada.`,
    'mfa-long': `Autenticação Multi-Fatores`,
    'mfa-enter-token': `A MFA está ativa para a sua conta. Por favor insira um token para fazer login.`,
    verify: `Verificar`,
    'mfa-verify-token': `Verificando token`,
    'mfa-instructions': `Escaneie a imagem acima com o aplicativo de autenticação multi-fatores no seu celular. Se não puder usar um código de barras, insira | este código de texto | no aplicativo.`,
    'enable-mfa-failed': `Falha ao ativar autenticação multi-fatores`,
    'mfa-token-required': `Um token de uso único de autenticação multi-fatores é necessário. O formulário foi atualizado com este campo.`,
    'login-cognito-unexpected-failure': `Um erro inesperado ocorreu durante a autenticação. Por favor tente novamente, ou entre em contato com a sua organização para solicitar assistência.`,
    'login-sso-assistance': `Se você precisar de assistência {docs} nossa equipe de suporte.`,
    contact: `Contato`,
    'docs-portal': `Portal de Documentos`,
    'create-one': `Criar um`,
    'login-sso-help-reset-password': `Se estiver tendo problemas para entrar com seu email e senha, tente redefinir sua senha usando o link "Esqueceu a Senha" abaixo.`,
    'login-sso-help-idp-config': `Se você tiver o Single Sign-On (SSO) ativo mas não estiver conseguindo fazer login, pode haver um erro de configuração de IdP, ou o seu IdP pode não estar respondendo. Você pode conseguir fazer login diretamente usando o seu nome de usuário e senha da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} se você for um Admin da Empresa ou se o SSO estiver configurado como Opcional.`,
    'login-help': `Obter ajuda para entrar`,
    'login-temp-password-expired': `Sua Senha Temporária expirou, por favor entre em contato com o nosso time de suporte em ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL} para solicitar uma redefinição de Senha Temporária.`,
    continue: `Continuar`,
    'create-new-account': `Criar uma conta nova`,
    'join-company-account': `Entrar na conta da sua empresa`,
    'error-resetting-password': `Erro redefinindo senha`,
    'reset-password-sent-if-confirmed': `Se aquele endereço de email estiver em nosso sistema e a conta tiver sido confirmada anteriormente, enviaremos um email para redefinir sua senha.`,
    'sso-first-login': `O Admin da sua Empresa ativou SSO na sua conta. Você agora será redirecionado para o Provedor de Identidade (IdP) da sua empresa para autenticação.`,
    'sso-enforced-no-access': `Um Admin da Empresa tornou obrigatório o Single Sign-On (SSO) e você só deve fazer login via SSO. Por favor entre em contato com o Admin de sua Empresa se precisar de ajuda com acesso SSO.`,
    'sso-email-mapping-error': `Há um problema com o mapeamento de atributos de email da sua configuração de SSO. Para resolvê-lo, recomendamos entrar em contato com o Admin da sua Empresa e solicitar que eles revisem o mapeamento de atributos de email. Para obter ajuda para solucionar este problema, por favor veja a nossa página de Perguntas Frequentes sobre SSO no <a href="https://docs.megaport.com/troubleshooting/sso-faq/" target="_blank">portal de documentos</a>`,
    'email-query-missing': `Parâmetro de consulta do email não encontrado`,
    'email-not-sso': `Email não está configurado para SSO`,
  },
  validations: {
    required: `{thing} é necessário`,
    failed: `Falha na Validação`,
    'correct-issues': `Corrija os problemas indicados.`,
    'ix-selection-required': `Seleção de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} é necessária`,
    'asn-validation': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} A ASN deve ser entre 2 e {maxAsn} (default value is {defaultAsn})`,
    'asn-validation-plain': `A ASN deve ser entre {minAsn} e {maxAsn}`,
    'asn-validation-range': `Faixa de ASN {minAsn}-{maxAsn} ou {minAsn2}-{maxAsn2}`,
    'mbps-value-range': `Insira um valor entre {min} e {max} Mbps`,
    'value-too-high': `Maior que o máximo permitido. Insira um valor menor ou igual a {max}`,
    asn: `As ASNs começam com "AS" ou "ASN" ou são numéricas`,
    'min-length': `Comprimento mínimo 0 caracteres | Comprimento mínimo 1 caracter | Comprimento mínimo {min} caracteres`,
    'max-length': `Comprimento máximo 0 caracteres | Comprimento máximo 1 caracter | Comprimento máximo {max} caracteres`,
    'min-max-length': `Deve ter entre {min} e {max} caracteres`,
    'weak-password': `Sua senha não é suficientemente forte`,
    'invalid-mac': `Insira um endereço MAC válido`,
    'terms-conditions': `Você precisa concordar com os termos e condições`,
    'new-password': `Insira sua nova senha`,
    'password-different': `A nova senha deve ser diferente da anterior`,
    'email-invalid': `Insira um endereço de email válido`,
    'key-invalid': `Falha na validação da chave`,
    'service-key-invalid': `Insira uma chave de serviço válida`,
    'use-service-key': `Você precisa usar uma chave de serviço para criar uma conexão`,
    'nutanix-key-format': `Formato: três caracteres opcionais e hífen, depois UUID`,
    'azure-key-format': `Chaves de serviço da Azure tem entre 32 e 36 caracteres`,
    'invalid-service-key': `Chave de serviço inválida`,
    'destination-type': `Escolha o tipo de destino`,
    'aws-connection-type': `Você precisa selecionar o tipo de conexão da AWS`,
    'destination-provider': `Selecione o provedor de destino`,
    'connection-name': `Insira um nome para a conexão`,
    'connection-name-min': `O nome deve ter uma ou mais palavras começando com uma letra`,
    'select-peering': `Escolha se fará peering com servidores de rota`,
    'please-select': `Por favor escolha o {thing}`,
    'please-enter': `Insira o {thing}`,
    'phone-format': `Formato internacional. Ex: +61 4321 1234`,
    'aws-account': `Contas da AWS são números de 12 dígitos`,
    'ip-format': `Formato de endereço de IP, por exemplo 192.0.1.0/30`,
    'ip-list': `Lista de IPs, por exemplo 191.0.2.0/24,191.0.2.0/30`,
    'select-peer-type': `Selecione pelo menos um tipo de peer`,
    'pairing-key-format-invalid': `As chaves de pareamento devem corresponder às especificações do Google.`,
    'virtual-circuit-format-invalid': `Insira uma ID de circuito virtual válida`,
    'pairing-key-invalid': `Chave de pareamento inválida`,
    'virtual-circuit-invalid': `ID de circuito virtual inválida`,
    'google-pairing-key': `Para completar um pedido de conexão do Google, insira uma chave de pareamento válida.`,
    'invalid-key': `Chave Inválida`,
    'enter-valid-key': `Insira uma chave válida`,
    'vlan-required': `Valor de VLAN é necessário`,
    'rate-limit': `O limite de taxa não pode ser acima do máximo indicado.`,
    'select-location': `Você precisa escolher um local`,
    'enter-name': `Insira um nome`,
    'select-rate-limit': `Escolha o limite de taxa`,
    ge: `Insira um valor maior ou igual a {value}`,
    'mve-final-check': `A verificação final da VLAN do ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} será realizada durante o lançamento`,
    'vlan-unavailable': `VLAN indisponível`,
    'vlan-available': `VLAN está disponível`,
    'vlan-auto-assign': `Deixar a VLAN em branco resulta numa VLAN atribuída aleatoriamente`,
    'vlan-no-untagged': `Esta VLAN não pode ser definida como não marcada`,
    'vlan-untagged-warning': `Não marcar uma VLAN limita a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} a uma única conexão`,
    'vlan-range': `Os valores de VLAN vão de {minVlan} a {maxVlan}`,
    'vlan-in-use': `Já há uma conexão nesta ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} usando esta VLAN`,
    'agree-terms': `Você precisa concordar com os termos`,
    'insufficient-privileges': `Privilégios insuficientes`,
    'bio-too-long': `Palavras demais na bio da empresa`,
    'url-format': `Insira uma URL válida para o {site}`,
    'valid-company': `Nome de empresa válido é necessário`,
    'description-too-long': `Palavras demais na descrição`,
    'ip-in-list': `Este IP já está na lista`,
    'invalid-network': `O endereço não é um IPV4, IPV6, ou endereço de rede válido`,
    'not-valid-network': `O endereço não é um endereço de rede válido`,
    'no-subnet': `Os endereços de IP devem conter uma máscara de sub-rede`,
    'no-file-selected': `Nenhum arquivo selecionado`,
    'wrong-file-type': `Tipo errado de arquivo`,
    'select-text-file': `Escolha um arquivo de texto para subir`,
    'file-too-long': `Arquivo Longo Demais`,
    'file-max-length': `O tamanho do arquivo codificado deve ser menor do que {max}kb.`,
    'file-read-error': `Erro ao Ler o Arquivo`,
    'new-password-retype': `Digite novamente sua senha nova`,
    'new-password-different': `A nova senha e a senha redigitada devem ser iguais`,
    'fill-details': `Complete estes detalhes`,
    'characters-not-allowed': `Os seguintes caracteres não são permitidos: {chars}`,
    'minimum-payment': `O valor mínimo de pagamento é {value}`,
    'payment-description-none': `A descrição do pagamento deve ter pelo menos um caractere  alfabético`,
    'payment-enter-amount': `Insira uma quantia válida`,
    'type-used': `{thing} já está sendo usado`,
    'cannot-validate-type': `Não foi possível validar o valor do tipo: {thing}`,
    'ip-family-required': `Você precisa providenciar o valor da família de endereços de IP`,
    'ip-address-used': `Este endereço já está sendo usado`,
    'ip-address-subnet-required': `Endereços de IP devem conter uma máscara de sub-rede`,
    'ip-address-subnet-not-required': `A máscara de sub-rede não é necessária`,
    'ip-address-overlap': `IPs sobrepostos não são permitidos na interface`,
    'network-broadcast-exception': `O endereço é um endereço de broadcast; um endereço de host é esperado`,
    'network-address-exception': `O endereço é um endereço de rede; um endereço de host é esperado`,
    'local-peer-ip-same-network': `O endereço de IP Local e o endereço de IP do Peer não estão na mesma rede de IP`,
    'peer-network-address': `O endereço de IP do Peer não pode ser o endereço de rede`,
    'peer-broadcast-address': `O endereço de IP do Peer não pode ser o endereço de broadcast`,
    'peer-same-local-address': `O endereço de IP do Peer não pode ser igual ao endereço de IP Local`,
    'ipv4-mismatch': `Endereços IPv6 não podem ser adicionados à lista de endereços IPV4`,
    'ipv6-mismatch': `Endereços IPv4 não podem ser adicionados à lista de endereços IPV6`,
    'number-required': `O valor deve ser um número`,
    'range-min': `O intervalo deve começar em {min}`,
    'range-max': `O intervalo deve terminar em {max}`,
    'range-min-exceed-max': `O valor Mínimo não pode ser maior do que o Máximo`,
    'range-max-exceed-min': `O valor Máximo não pode ser menor do que o Mínimo`,
    'range-min-exceeds-cidr': `O valor Mínimo não pode ser menor do que o CIDR`,
    'thing-between-mix-max': `{thing} deve estar entre {min} e {max}`,
    'value-min': `O valor mínimo é {min}`,
    'value-max': `O valor máximo é {max}`,
    'value-required': `Você precisa inserir um valor`,
    'value-integer': `O valor precisa ser um número inteiro`,
    'value-positive': `O valor deve ser positivo`,
    'value-decimal-places': `Um valor pode ter {places} casas decimais`,
    'broadcast-address': `O próximo hop não pode ser um endereço de broadcast`,
    'next-hop-same-ip-family': `O próximo hop deve estar na mesma família de IPs que o prefixo`,
    'next-hop-same-network': `O próximo hop deve estar na mesma rede que um dos IPs da interface`,
    'next-hop-not-interface-address': `O próximo hop não pode estar listado entre os endereços de interface IP`,
    'next-hop-network-address': `O próximo hop é um endereço de rede. Um endereço de host é esperado`,
    'bgp-password': `A senha só pode incluir caracteres alfanuméricos e especiais (!@#$%^&*+-=_)`,
    'bpg-password-length': `A senha deve ter entre 0 e 25 caracteres`,
    'min-max-range': `Os valores devem estar entre {mix} e {max}`,
    'bpg-no-connections': `Nenhuma conexão BGP foi encontrada`,
    'bfd-transmit': `O Intervalo de Transmissão deve ser entre 300 e 9000`,
    'bfd-receive': `O intervalo de recepção deve ser entre 300 e 9000`,
    'bfd-multiplier': `É necessário um multiplicador mínimo de 3`,
    'name-already-used': `Esse nome já está sendo usado`,
    'address-family-required': `A família de endereços é obrigatória`,
    'vlan-unique': `O ID da VLAN deve ser único e não pode ser maior do que {max}`,
    'invalid-address-domain': `Por favor insira um endereço de IP ou domínio válido`,
    'vendor-image': `Por favor escolha a imagem do fornecedor`,
    'email-invalid-seperators': `Insira um único endereço de email, ou uma lista de endereços de email separados por vírgula`,
    'no-approval-price-title': `Você não tem permissão para aprovar mudanças de preço`,
    'no-approval-price-message': `Apenas usuários com permissões de pedido podem fazer mudanças que afetam o preço de um serviço`,
    'enter-valid-auth-key': `Insira {thing} válido`,
    '128-chars': `Deve ser menor do que 128 caracteres.`,
    'confirm-password-mismatch': `A senha não corresponde à senha inserida acima`,
    'min-number': `Deve ter pelo menos 1 número | Deve ter pelo menos {min} números`,
    'min-upper-case': `Deve ter pelo menos 1 caracter maiúsculo | deve ter pelo menos {min} caracteres maiúsculos`,
    'min-lower-case': `Deve ter pelo menos 1 caracter minúsculo | Deve ter pelo menos {min} carateres minúsculos`,
    'min-symbol': `Deve ter pelo menos 1 símbolo | Deve ter pelo menos {min} símbolos`,
    'as-path-prepend': `Insira a contagem de Prepend do caminho AS de 0 a 10`,
    'api-keys-expiry': `Insira um número de 5 a 1440`,
    'api-keys-expiry-decimal': `O termo deve ser um número inteiro`,
    'min-max-alphanumeric': `Deve ter de {min} a {max} caracteres alfanuméricos`,
    'mfa-token': `Insira seu token de MFA`,
    vnic: `Seleção do Plano vNIC é necessária`,
    'bgp-password-valid-characters': `Os caracteres válidos são:: a-z A-Z 0-9 ! @ # ? . $ % ^ & * + = -`,
    'ix-peer-invalid-characters': `Letras, números, dois pontos, linha inferior e travessões apenas`,
    'ix-peer-invalid-format': `Por favor insira um conjunto ASN com prefixo AS separado por dois pontos (:)`,
    'asn-validation-range-triple': `Faixa ASN {minAsn}-{maxAsn}, {minAsn2}-{maxAsn2} ou {minAsn3}-{maxAsn3} (o padrão é {defaultAsn})`,
    'required-pattern': `O valor deve estar no padrão {thing}`,
    'rfc-number-example': `Deve ter {total} caracteres({start} alfanuméricos, depois 6 números e então 3 alfanuméricos)`,
    'idp-name-invalid-characters': `O nome do provedor só deve conter letras minúsculas, números e travessões`,
    alphanumeric: `{thing} deve ter apenas caracteres alfanuméricos`,
    'reverse-dns-invalid-characters': `Os caracteres válidos são: a-z A-Z 0-9 . -`,
    'invalid-field': `Por favor insira um {thing} válido`,
  },
  connections: {
    'choose-type': `Escolha o Tipo de Destino`,
    cloud: `Nuvem`,
    'internet-exchange': `Troca de Internet (IX)`,
    csp: `Conexão ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} a um Provedor de Serviços de Nuvem`,
    private: `Conexão ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} à sua própria ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}, ou ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'private-vxc': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} Privado`,
    ix: `Conectar a um ${process.env.VUE_APP_EN_PRODUCTNAME_IX} da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} `,
    marketplace: `Conexão ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} a uma ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} parceira`,
    'enter-service-key': `Insira Chave de Serviço`,
    'service-key': `Escolha esta opção se você recebeu uma Chave de Serviço da${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'select-destination-port': `Escolha a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de destino`,
    'awaiting-deployment': `A conexão está aguardando lançamento. Se o lançamento estiver demorando mais do que o comum, entre em contato com o Suporte da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'approval-error': `Erro de Aprovação da ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'deny-error': `Erro de Negação da Conexão ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'untag-vlan-tooltip': `Configurar este serviço sem um ID de VLAN. Isso limitará a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} a um único serviço virtual.`,
    'used-vlan': `Já há uma conexão criada nesta ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} usando essa VLAN. Uma VLAN será atribuída aleatoriamente se você continuar.`,
    'asn-from-mcr': `A ASN automaticamente usa a ASN da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'azure-ports': `Escolha uma ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} da Azure disponível.`,
    'select-destination-port-title': `Escolha a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de Destino`,
    'no-available-target-ports': `Não há ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} alvo disponível`,
    'please-select-port': `Por favor escolha a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'no-available-ports-key': `Não há ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} disponíveis nesta chave de serviço. Por favor tente usar outra.`,
    'all-ports-used-key': `Todas as ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} associadas a essa chave de serviço foram consumidas por outros serviços. Por favor tente usar outra.`,
    'port-in-use-title': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} em Uso`,
    'port-in-use-message': `Esta ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} não está disponível para seleção porque ela já está em uso.`,
    'google-ports': `Escolha dentre as ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} do Google disponíveis`,
    'no-google-services': `Não há serviços do Google Cloud disponíveis na região da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}. selecionada`,
    'no-google-ports': `Não há ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} disponíveis nesta chave de pareamento. Por favor tente usar outra.`,
    'nutanix-ports': `Escolha dentre as ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} da Nutanix disponíveis`,
    'oracle-ports': `Escolha dentre as ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} da Oracle disponíveis`,
    'no-oracle-ports': `Não há ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} disponíveis neste circuito virtual. Por favor tente usar outro.`,
    'all-oracle-ports-taken': `Todas as ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} neste circuito virtual foram consumidas por outros serviços. Tente usar outra.`,
    'list-all-oracle-ports': `Para uma lista de todas as ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} da Oracle disponíveis, veja o Perfil da| ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'ix-details': `Detalhes de conexão para o Serviço de ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'ix-asn-message': `Aviso: O ASN não pode ser modificado para serviços de ${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX} após o lançamento.`,
    'mcr-config': `Detalhes da Conexão ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} de terminação {end}`,
    'mcr-override-asn': `Sobrepor o ASN da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'mcr-override-asn-tooltip': `O ASN local pode ser usado para substituir o ASN da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} para este peer BGP. Na maioria dos casos, deve ser deixado como padrão.`,
    'mcr-setup-tooltip': `Para cada ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} conectada a esta ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} você pode configurar uma ou mais interfaces, endereços de IP, conexões BGP, ou rotas estáticas. A maioria dos ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} usará uma interface. No entanto, você pode configurar múltiplas interfaces usando "Q-in-Q" selecionando uma tag VLAN "inner" para cada interface. O TPID será configurado para 0x8100.`,
    'mcr-ip-address-tooltip': `Endereços de IP e máscaras de sub-rede associadas que serão configurados nesta interface.<br /><br />Múltiplos endereços são permitidos. São suportados tanto IPv4 quanto IPv6.<br /><br />Você pode deixar esse campo em branco para adiar a configuração da interface até depois do ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} ser provisionado.`,
    'aws-diversity-tooltip': `Pedir ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} a diferentes zonas de cor se você quiser diversidade de dispositivos.`,
    'no-matching-ports': `Não há ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} alvo disponíveis`,
    'service-key-id': `ID de Chave de Serviço da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'service-key-unavailable': `A chave de serviço é para a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de origem atual e não pode ser usada.`,
    'port-asn': `ASN da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'port-ip': `Endereço de IP da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'aws-default-asn': `ANS de Peer padrão da AWS. O AWS será substituído se este ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} for anexado a esse gateway Direct Connect.`,
    'vxc-is-down': `Este serviço de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} está indisponível`,
    'vxc-is-up': `Este serviço de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} está disponível`,
    'vxc-status-unknown': `O status deste serviço de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} é desconhecido`,
    'ix-is-down': `Este serviço de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} está indisponível`,
    'ix-is-up': `Este serviço de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} está disponível`,
    'ix-status-unknown': `O status deste serviço de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} é desconhecido`,
    'speed-req-external-approval': `Aviso: Este ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} pertence a outra empresa, e quaisquer mudanças de velocidade devem ser aprovadas por ela.`,
    'ix-service': `Serviço de ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'new-vxc': `Nova Conexão ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    'select-source-port': `Escolha ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de Origem`,
    'preferred-end-vlan': `VLAN {title} Preferida`,
    'amsix-contact-phone': `Um número de telefone é necessário para o {contactType} para um serviço de AMS-IX.`,
    'cancel-a-end': `Note que a ${process.env.VUE_APP_EN_COMPANYINFO_NAME} só pode cancelar a Terminação-A deste serviço. Para prevenir mais cobranças da<b>{companyName}</b>, cancele o seu serviço diretamente com a <b>{companyName}</b>.`,
    'no-matching-source-port': `Nenhuma ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} de origem correspondente`,
    'no-selected-source-port': `Escolha a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de origem.`,
    'non-edit-reason': `Aviso: Os detalhes da conexão não são editáveis por | {reason}`,
    'pending-approval': `A conexão está esperando aprovação externa de {approvalFrom}.`,
    'pending-speed-approval': `A conexão está esperando aprovação externa de {approvalFrom} para uma mudança de velocidade.`,
    'pending-speed-approval-to': `A mudança de limite de taxa para {newSpeed} está aguardando aprovação de {approvalFrom}.`,
    'connection-denied': `VXC para {destination} foi negado`,
    'connection-approved': `VXC para {destination} foi aprovado`,
    'peer-config': `Configuração de Peer {type}`,
    'min-bfg-tx-interval': `Intervalo de transmissão mínimo é {min}ms`,
    'min-bfg-rx-interval': `Intervalo de recepção mínimo é {min}ms`,
    'min-bfg-multiplier': `O multiplicador mínimo é {min}`,
    'key-expired': `Esta chave de serviço não é mais válida. Entre em contato com {company} para solucionar este problema`,
    'key-used': `Esta chave de serviço de único já foi usada. Entre em contato com {company} para solucionar este problema`,
    'key-invalid': `Esta chave de serviço não é válida. Entre em contato com {company} para solucionar este problema`,
    'ix-rate-limit-help': `O limite de taxa da ${process.env.VUE_APP_EN_PRODUCTNAME_IX} está limitado à velocidade agregada da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} para conexões Metro, e a 10Gbps para conexões Long Haul.`,
    'vxc-rate-limit-help': `O limite de taxa máximo para um ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} é dado pela velocidade mais baixa em cada terminação e a capacidade da rede na rota.`,
    'max-ip-addresses': `Máximo de {max} Endereços de IP`,
    'max-static-routes': `Máximo de {max} rotas estáticas`,
    'max-bgp-connections': `Máximo de {max} conexões BGP`,
    'mcr-end-visibility': `Clique para {operation} detalhes da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} de terminação {end}`,
    'mcr-end-details': `Detalhes da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} de terminação {end}`,
    'mcr-end': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} de terminação {end}`,
    'missing-azure-service-key': `Este serviço de Azure ExpressRouter está sem a chave de serviço. Para pedir este serviço, forneça a chave de serviço na tela de "Escolher ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}".`,
    'missing-google-pairing-key': `Este serviço do Google está sem a chave de pareamento. Para pedir este serviço, forneça a chave de pareamento na ela de "Escolher ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}".`,
    'new-bgp-state': `Conexões BGP recém-criadas estarão {state}.`,
    'google-key-format': `Chaves do Google são no formato:`,
    'bgp-peer-asn': `ASN do Peer`,
    'connection-details': `Detalhes da Conexão`,
    'connection-name': `Nome da Conexão`,
    'vlan-fixed': `Aviso: A VLAN não pode ser modificada para este tipo de serviço após o lançamento.`,
    'configure-azure-vlan': `Configurar uma única VLAN de Peering da Azure`,
    'azure-vlan': `VLAN de Peering da Azure`,
    'enter-vlan': `Insira VLAN`,
    'preferred-vlan': `VLAN Preferida`,
    asn: `ASN`,
    'asn-full': `Sistema Autônomo de Números`,
    'mac-address': `Endereço MAC`,
    'mac-address-format': `Insira um endereço Address em um dos formatos a seguir - 3c22.fb80.749c ou 3c:22:fb:80:74:9c`,
    'bgp-password': `Senha BGP`,
    'peer-macro': `Macro de Peer`,
    'graph-visibility': `Visibilidade do Gráfico`,
    untagged: `Não marcado`,
    shutdown: `desligado`,
    enabled: `ativado`,
    'end-not-configured': `terminação {end} não foi configurada ainda.`,
    'interface-vlan': `VLAN de Interface`,
    'ip-addresses': `Endereços de IP`,
    'static-routes': `Rotas Estáticas`,
    'bgp-connections': `Conexões BGP`,
    'nat-ip-addresses': `Endereços de IP NAT`,
    'b-vlan-service-key': `VLAN de terminação B (da Chave de Serviço)`,
    'hide-cloud-details': `Clique para esconder os detalhes da nuvem`,
    'show-cloud-details': `Clique para mostrar os detalhes da nuvem`,
    'cloud-details': `Detalhes da nuvem`,
    'peering-policy': `Política de Peering`,
    'policy-url': `URL da Política`,
    'peering-email': `Email de Peering`,
    'company-url': `URL da Empresa`,
    'company-email': `Email da Empresa`,
    'street-address': `Endereço`,
    city: `Cidade`,
    'state-province': `Estado ou Província`,
    'postcode-zip': `Código Postal ou ZIP`,
    country: `País`,
    type: `Tipo`,
    'aws-connection-name': `Nome da Conexão AWS`,
    'aws-owner-acct-id': `ID da conta do proprietário AWS`,
    'customer-ip': `Endereço de IP do Cliente`,
    'amazon-ip': `Endereço de IP da Amazon`,
    'alibaba-account': `ID da conta da Alibaba`,
    'pairing-key': `Chave de pareamento`,
    'virtual-circuit-id': `ID do Circuito Virtual`,
    deleted: `Conexão apagada`,
    'select-destination': `Escolher Destino`,
    'configure-a-end': `Configurar terminação A`,
    'configure-b-end': `Configurar terminação B`,
    'vlan-value': `Valor da VLAN`,
    save: `Salvar Conexão`,
    'approve-prompt': `Você deseja aprovar esta conexão?`,
    'approve-connection': `Aprovar Conexão`,
    'deny-connection': `Negar Solicitação de Conexão`,
    configuration: `Configuração da Conexão`,
    'awaiting-approval': `Esta conexão está aguardando sua aprovação.`,
    'approve-question': `Tem certeza de que deseja aprovar esta solicitação?`,
    'approve-title': `Aprovar Solicitação`,
    'deny-question': `Tem certeza de que deseja negar esta solicitação?`,
    'deny-title': `Negar Solicitação`,
    'cancel-question': `Tem certeza de que deseja cancelar esta solicitação?`,
    'cancel-title': `Cancelar Solicitação`,
    'change-approved-title': `Mudança de Velocidade Aprovada`,
    'change-approved-message': `O novo limite de taxa foi aprovado.`,
    'change-denied-title': `Mudança de Velocidade Negada com Sucesso`,
    'change-denied-message': `A solicitação de mudança de limite de taxa foi negada.`,
    'change-cancelled-title': `Mudança de Velocidade Cancelada com Sucesso`,
    'change-cancelled-message': `A solicitação de mudança de limite de taxa foi cancelada.`,
    'approved-title': `Conexão Aprovada`,
    'denied-title': `Conexão Negada com Sucesso`,
    untag: `Desmarcar`,
    'auto-assigned': `Auto Atribuída`,
    'auto-assign': `Atribuir VLAN Automaticamente`,
    'vlan-checking': `Verificando disponibilidade da VLAN...`,
    'add-connection': `Adicionar Conexão`,
    open: `Aberta`,
    closed: `Fechada`,
    vlan: `VLAN`,
    'valid-service-key': `Chave de Serviço Válida`,
    'verifying-key': `Verificando chave`,
    'select-provider': `Escolher Provedor`,
    'no-matching-providers': `Nenhum provedor correspondente`,
    'select-connection-type': `Escolher o Tipo de Conexão`,
    'bgp-statuses': `Estados de BGP`,
    'end-vlan': `VLAN de terminação {end}`,
    'route-servers': `Servidores de Rota`,
    'bgp-down': `BGP está indisponível`,
    'bgp-up': `BGP está disponível`,
    'bgp-unknown': `Status do BGP é desconhecido`,
    interface: `Interface`,
    'old-speed': `Velha Velocidade`,
    'new-speed': `Nova Velocidade`,
    'approve-speed-question': `Você deseja aprovar essa mudança de velocidade?`,
    'deny-speed-change': `Negar Solicitação de Mudança de Velocidade`,
    'approve-speed-change': `Aprovar Mudança de Velocidade`,
    'cancel-speed-request': `Cancelar Solicitação de Mudança de Velocidade`,
    'connections-html': `Para mais detalhes, veja o {link}`,
    'speed-change-pending-your-approval': `Aguardando Sua Aprovação: Uma mudança de velocidade foi solicitada para esta conexão.`,
    'speed-change-pending-company-approval': `Aguardando Aprovação da Empresa: Uma mudança de velocidade foi solicitada para esta conexão (exige a aprovação de sua empresa).`,
    connection: `Conexão`,
    'lag-full': `Este  ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} não pode aceitar novas conexões`,
    'port-full': `Esta ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} não pode aceitar novas conexões`,
    'show-connections': `Clique para mostrar conexões`,
    'hide-connections': `Clique para ocultar conexões`,
    'service-key-vlan-range': `VLAN 2-4094 (desativado para chaves de serviço multiuso)`,
    'third-party-owner': `proprietário terceiro`,
    'b-end': `Você está na terminação B desta conexão de propriedade de outra empresa`,
    'shut-down-by-user': `desativado pelo usuário`,
    'b-end-no-delete': `terminação da conexão não pode apagar a conexão`,
    'speed-change-from': `Mudar velocidade de`,
    'new-connection-from': `Nova conexão de`,
    'best-route-explanation': `Uma marca de seleção indica que a ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} prefere essa rota ao escolher entre duas rotas para o mesmo destino.`,
    'as-path': `Caminho de AS`,
    'local-preference': `Preferência Local`,
    med: `Discriminador Multi-Saídas`,
    metric: `Métrica`,
    protocol: `Protocolo`,
    distance: `Distância`,
    'gateway-ipv4': `Endereço de IP Gateway (IPv4)`,
    'gateway-ipv6': `Endereço de IP Gateway (IPv6)`,
    'match-label': `Corresponder`,
    'dont-match-label': `Não corresponder`,
    'delete-route-label': `Apagar rota`,
    'connected-port-removed': `Esta ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ou ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} à qual este serviço estava conectado foi removido dos dados. Redirecionando para serviços`,
    approving: `aprovando`,
    denying: `negando`,
    'approval-issues': `Problemas {action} ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}: {errorStr}`,
    'confirm-bgp-state-change': `Isso mudará o Estado do BGP para {newState} e confirmará quaisquer mudanças não-salvas a esse serviço. As mudanças podem levar até dois minutos para aparecer..`,
    'bgp-state-change-prompt': `Confirmar mudança do estado do BGP?`,
    'awaiting-approval-or-denial': `Aguardando que você aprove/negue esta conexão`,
    'azure-key-unused-key': `Disponível neste local`,
    'azure-key-used-key': `Pode ser usada múltiplas vezes especificando uma VLAN de Peering da Azure única`,
    'azure-key-unused': `Esta ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} não foi usada com esta chave de serviço`,
    'azure-key-used': `Esta ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} pode ser usada múltiplas vezes especificando uma VLAN de Peering da Azure única`,
    'outscale-connection-name': `Nome da Conexão da Outscale`,
    'outscale-account': `ID da conta proprietária da Outscale`,
    'outscale-ip': `Endereço de IP da Outscale`,
    transit: `Acesso ao Gateway de Trânsito via uma conexão fornecida pela ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'vxc-name-default': `{aEnd} a {bEnd}`,
    'customer-asn': `ASN do Cliente`,
    vnic: `vNIC`,
    'reverse-dns': `DNS Reverso`,
    'virtual-interfaces-vnics': `Interfaces Virtuais(vNICs)`,
    'vnic-plane': `Plano de {plane} da vNIC-{index}`,
    'end-vnic': `vNIC de terminação {end}`,
    'create-ip-transit-vxc': `Criar ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}`,
    'mcr-full': `Esta ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} não pode aceitar novas conexões`,
    'mve-full': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} não pode aceitar novas conexões`,
    'provider-ip': `Endereço de IP do Provedor`,
    'ibm-account': `ID da Conta da IBM Cloud`,
    'mve-untag-vlan-tooltip': `Configurar este serviço sem uma ID da VLAN.`,
    'no-transit-port-title': `Falha ao localizar ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} para a ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}`,
    'no-transit-port-message': `Não foi possível encontrar uma {process.env.VUE_APP_EN_PRODUCTNAME_PORT} da ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}. Por favor entre em contato com ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'a-end-vnic-help': `Interface virtual do serviço de iniciação`,
    'b-end-vnic-help': `Interface virtual do serviço de terminação`,
    'no-description': `Sem descrição`,
    'select-connections': `Escolher Uma ou Mais Conexões`,
    'move-connection': `Mover Conexão`,
    'select-new-end': `Escolher Nova Terminação`,
    'move-connection-confirm': `Por favor confirme que você deseja proceder e mover as conexões selecionadas de {currentEnd} para {newEnd}.`,
    'move-connection-diversity': `Aviso: A zona de diversidade está mudando de {currentDiv} para {newDiv}. Isso pode afetar a sua nova diversidade.`,
    'move-connection-disclaimer': `Durante a mudança, as conexões ficarão indisponíveis. A mudança pode levar até 2 minutos para ser concluída.`,
    'no-valid-connections': `Nenhuma conexão válida encontrada`,
    'no-valid-destinations': `Nenhum destino válido encontrado`,
    'invalid-move-existing-end': `O {type} alvo já está anexado à conexão selecionada`,
    'invalid-move-multiple-vnics': `O {type} alvo tem múltiplos vNICs`,
    'invalid-move-too-small': `Capacidade insuficiente no {type} alvo`,
    'invalid-move-new-untagged': `O  {type} alvo não pode aceitar conexões desmarcadas`,
    'invalid-move-untagged': `O {type} alvo tem uma conexão desmarcada`,
    'invalid-move-vlan-taken': `A VLAN não está disponível no {type} alvo`,
    'search-for-providers': `Ver mais Provedores`,
    'termed-delete-etf': `Taxas de Rescisão Antecipada (ETFs) se aplicam ao apagar uma conexão vigente segundo os termos do seu acordo com a Megaport.`,
    'avoid-etf': `Para evitar taxas de rescisão antecipada (ETF), escolha ‘Sem Prazo Mínimo’ até que os detalhes da configuração estejam precisos. Você pode atualizar a conexão a qualquer momento para aceitar um termo.`,
    'rate-limit-change-disallowed': `Essa é uma conexão com prazo de vigência, mudanças de taxa não são permitidas a não ser que a conexão seja renovada e o limite de taxa seja aumentado.`,
    'rate-limit-change-term': `Esta é uma conexão com prazo de vigência, o limite de taxa não pode ser diminuído para menos do que {termSpeed} Mbps, a velocidade escolhida no momento da criação do prazo.`,
    'private-vxc-no-valid-target': `Você não tem serviços válidos com os quais estabelecer uma conexão privada`,
    'vnic-details': `Detalhes da vNIC`,
    'new-vnic-details': `Detalhes da Nova vNIC`,
    'new-end-vnic': `Nova vNIC da Terminação {end}`,
    'new-preferred-end-vlan': `Nova VLAN {title} preferida`,
    'move-connection-vnic-warning': `Aviso: A vNIC e VLAN no ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} de destino também mudarão para os valores especificados.`,
    'confirm-move': `Confirmar Mudança`,
    'speed-req-external-approval-a-end': `Aviso: Este ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} é de propriedade de outra empresa e você não tem permissão para fazer mudanças na velocidade do ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} dela.`,
    'speed-req-external-approval-b-end': ` Aviso: Este ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} está conectado a outra empresa e quaisquer mudanças de velocidade também precisarão ser aprovadas por ela.`,
  },
  ports: {
    'port-deleted': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} Apagada`,
    'select-port': `Escolher  ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'port-speed': `Velocidade da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'select-port-speed': `Escolha a velocidade da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'mcr-explanation': `Uma ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MCR}) é uma instância de roteamento virtual compatível com camada 3 oferecida e hospedada pela ${process.env.VUE_APP_EN_COMPANYINFO_NAME} em pontos de acesso à nuvem no mundo todo. Ela não exige uma ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} física, mas você pode se conectar a uma se desejar.`,
    'port-main-tooltip': `Uma ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} física é necessária se você for conectar o seu equipamento ao nosso. Suportamos interfaces de fibra de 1G, 10G e 100G (geralmente duplex SMOF - confira o seu LOA obtido durante o lançamento da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} para os requisitos específicos de cada local ) em locais conectados à ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'diverse-from-existing': `Diversificar da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} Existente`,
    'ports-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'ports-type-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} {type} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} {type} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} {type} `,
    'pluralize-ports': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} | ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'services-attached-count': `0 serviços anexos | 1 serviço anexo | {count} serviços anexos`,
    'percent-allocated': `{percent}% alocado`,
    'awaiting-resources': `Este {type} está aguardando alocação de recursos.`,
    'add-connection-to': `Adicionar nova conexão a este {type}`,
    'no-mcr-speed-change': `O limite de taxa da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} é fixada pela duração total do serviço`,
    'lacp-ports': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} LACP & ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} `,
    'lacp-on-port': `LACP na ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'add-to-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} para adicionar a ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'ports-in-lag': `Número de ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} no ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'mcr-asn': `ASN da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'summary-adding-to-lag': `Adicionando  ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} ao ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'summary-ports-in-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} em ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'no-slow-ports-in-lag': `Não há ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} com velocidade < 10Gbps em ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'mcr-rate-limit': `Limite de Taxa da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'allocated-tooltip': `A velocidade da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} e o limite total cumulativo de taxa de seus serviços`,
    'port-removed': `A ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} foi removida dos dados. Redirecionando para serviços`,
    'contract-terms': `Os termos do contrato aparecerão quando a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} estiver ativa`,
    statuses: `Estados da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'configure-details': `Configurar detalhes para as ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} adicionadas `,
    'add-ports-to-lag': `Adicionar novas ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} a esse ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'lag-full': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} não pode aceitar mais ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'max-speed-tooltip': `Limite de taxa em Mbps. Deve ser menor ou igual a {speed}. Deixar este campo em branco deixará a velocidade máxima disponível na ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} como limite.`,
    'vxc-speed-positive-integer': `Velocidade do ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} deve ser um número inteiro positivo`,
    'vxc-speed-max': `A velocidade máxima do ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} é de {speed} Mbps`,
    'lag-primary-deletion-locked': `A ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} primária do ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} não pode ser deletada enquanto há ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} travadas no ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'deletion-insufficient-capacity': `Apagar esta ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} deixaria capacidade insuficiente para suportar as conexões atuais da ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'link-aggregation': `Agregação de Links para {portName}`,
    'attached-vxc-locked': `O ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} anexo está travado`,
    'attached-ix-locked': `O ${process.env.VUE_APP_EN_PRODUCTNAME_IX} anexo está travado`,
    'terminate-date': `Este serviço se encerrará em {date}`,
    'autoconfig-mcr': `A configuração da conexão ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} será gerada automaticamente. Você poderá modificar a configuração quando o serviço estiver ativo.`,
    'count-port-lag': `- | (1 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}) ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} | ({count} ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}) ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'live-lag-disclaimer': `Aviso: Ao adicionar ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} a um ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ativo, a nova ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ou ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} recebem automaticamente o mesmo local, velocidade e visibilidade no ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} que as demais no ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'lag-explanation': `Cria esta ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} com o protocolo LACP ativo para que ela possa ser usada em um ${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_LAG}) e permite que você especifique o número de ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} a ser incluídas no seu grupo ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'add-lag-ports-disclaimer': `Isso acrescentará múltiplas ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} neste ${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_LAG}). O preço mostrado é para todas as ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} sendo adicionadas ao ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'port-diversity': `Diversidade da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'diversity-unavailable-location': `Diversidade não está disponível atualmente para essa configuração. Entre em contato com <a href="mailto:${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}">${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}</a> para falar sobre seus requisitos de diversidade.`,
    'diversity-explanation-html': `A diversidade da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} permite que ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} no mesmo local estejam em zonas de diversidade diferentes. Criar ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} redundantes em zonas diversas reduz o risco de interrupções no serviço devido a um evento impactante em um único dispositivo. Para mais detalhes, veja {link}`,
    'select-lag-speed': `A velocidade mínima da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} para ativar LACP é de 10Gbps`,
    'select-speed-diversity': `Escolha uma velocidade de  ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} para ver opções de diversidade.`,
    'diversity-lag-add': `- | A nova ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} será adicionada à zona de diversidade do ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}. | A nova ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} será adicionada à zona de diversidade do  ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}.`,
    'diverse-pair': `Dois  {things} serão configurados e adicionados a zonas de diversidade diferentes.`,
    'diverse-from': `Diverso de : {port}`,
    'port-diverse-from': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} da qual ser diversa`,
    'first-port-name': `Nome do primeiro {thing}`,
    'second-port-name': `Nome do segundo {thing}`,
    'delete-diverse-from': `- | A seguinte ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} está configurada para ser diversa da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} que você está encerrando: | As seguintes ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} estão configuradas para ser diversas da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} que você está encerrando:`,
    'reset-diversity': `- | Se você encerrar a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} perderá sua configuração de diversidade e não terá mais uma zona de diversidade especificada. | Se você encerrar a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, as ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} listadas perderão suas configurações de diversidade e não terão mais uma zona de diversidade especificada.`,
    'mcr-prefix-filter': `O recurso de Filtro de Prefixos da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} suporta filtros de rota BGP  baseados em prefixo de entrada (inbound) e saída (outbound). <br/><br/> <strong>Você pode adicionar e configurar listas de prefixos quando o seu serviço estiver configurado.</strong>`,
    'bgp-default-state-tooltip': `Escolher se conexões BGP recém-criadas serão habilitadas ou desabilitadas por padrão. Escolha Habilitada (o padrão) se quiser que quaisquer novas sessões BGP que você configure fiquem ativas assim que você salvar a configuração. Escolha Desabilitada se você quiser que quaisquer novas conexões BGP que você configure fiquem em estado inativo quando você salvar a configuração.`,
    'mve-software': `Software da ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'new-prefix-filter-list': `Novo`,
    'open-port': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} Aberta`,
    'prefix-filter-list': `Listas de Filtros de Prefixo`,
    'prefix-list-learn-more': `Saiba mais sobre as Listas de Filtros de Prefixo`,
    'prefix-list-loaded-success': `A lista de prefixos selecionada foi carregada com sucesso`,
    'prefix-list-loaded-fail': `Falha ao carregar a lista de prefixos selecionada`,
    'lacp-enabled': `LACP Ativado`,
    'diverse-pair-label': `Par Diverso`,
    'diverse-from-label': `Diverso De`,
    'download-loa': `Baixar LOA`,
    failed: `Falha ao atualizar ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    vendor: `Fornecedor`,
    'mve-file-upload': `Upload de Arquivo`,
    'cloud-init-vmanage': `Gerar e subir um arquivo cloud init a partir do seu sistema vManage`,
    'mve-init': `Arquivo de inicialização`,
    waiting: `Aguardando cross connect.`,
    'key-valid-until': `Válido Até`,
    'service-keys': `Chaves de Serviço`,
    'lag-up': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} está ativo`,
    'lag-down': `Este ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} está inativo`,
    'key-saved': `Chave de serviço salva com sucesso.`,
    'key-added': `Chave de serviço adicionada com sucesso.`,
    'key-saved-message': `A chave de serviço [{description}] foi salva.`,
    'key-add-error': `Erro ao criar chave de serviço.`,
    'key-update-error': `Erro ao atualizar chave de serviço.`,
    'minimum-term': `Vigência Mínima`,
    // a seção cannotBeEdited está em bold, ela refernciará o string cannot-edit
    'uneditable-asn-html': `Você pode configurar o Sistema Autônomo de Números (ASN) desta ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} ou usar o ASN público {defaultAsn} fornecido pela ${process.env.VUE_APP_EN_COMPANYINFO_NAME}. O ASN será usado para sessões de peering BGP em quaisquer ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} conectadas a esta ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}. Ele {cannotBeEdited} após a ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} ter sido pedida. Note que alguns serviços de nuvem pública exigem o uso de um ASN público. Consulte a documentação referente ao seu provedor de nuvem antes de substituir este valor padrão.`,
    'bgp-default-state': `Estado BGP Padrão`,
    'site-deployment': `Este local ficará ATIVO em breve. Peça agora para receber o seu LOA assim que ele ficar disponível. Para mais informações ou resolução de dúvidas, entre em contato com o gerente da sua conta.`,
    terms: `Termos do Contrato`,
    'end-date-display': `A data de término do contrato será mostrada após o {productName} ficar ativo`,
    'change-term': `Alterar Prazo do Contrato`,
    'term-info': `Saiba mais sobre os termos do contrato do {productName}`,
    monthly: `Mês a mês`,
    'existing-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_LAG} existente`,
    'thing-enabled': `{thing} Ativado`,
    'thing-disabled': `{thing} Desativado`,
    'address-family-label': `Família de Endereços`,
    'local-ip-label': `IP Local`,
    'peer-ip-label': `IP do Peer`,
    'local-asn-label': `ASN Local`,
    'bgp-state-label': `Estado BGP`,
    'bgp-peer-filter-label': `Filtro de Peer BGP`,
    'bgp-prefix-filter-import-label': `Importar Filtro de Prefixo`,
    'bgp-prefix-filter-export-label': `Exportar Filtro de Prefixo`,
    'bgp-confirm-delete': `Este endereço de IP está sendo usado em conexões BGP ou endereços de IP NAT. Apagá-lo removerá essas configurações. Continuar?`,
    'permit-list-label': `Lista de Permissões`,
    'deny-list-label': `Lista de Negações`,
    'new-list-label': `Nova Lista`,
    'duplicate-list-label': `Duplicar Lista`,
    'delete-list-label': `Apagar Lista`,
    'delete-list-confirm': `Tem certeza de que deseja apagar esta lista?`,
    'filter-advertise-default-label': `Anunciar por Padrão`,
    'filter-advertise-exception-label': `Anunciar por Exceção`,
    'bfd-switch-label': `Usar BFD`,
    'bfd-label': `BFD - Detecção de Encaminhamento Bidirecional`,
    'inbound-label': `Entrada`,
    'outbound-label': `Saída`,
    'med-label': `MED (Métrica) - Discriminador Multi-Saídas`,
    'bgp-prefix-filter-label': `Filtro de Prefixos BGP`,
    'bgp-connection-filter-label': `Filtro de Conexões BGP`,
    'no-connection-found': `Nenhuma conexão {thing} foi encontrada`,
    'address-placeholder': `Insira um endereço de IP (IPv4/IPv6)`,
    'network-address-placeholder': `Insira um endereço {thing} e CIDR`,
    'subnet-mask-range': `Faixa de Máscara de Sub-rede`,
    'select-address': `Escolher Endereço`,
    'prefix-list-label': `Lista de Prefixos`,
    'mve-explanation': `Um ${process.env.VUE_APP_EN_PRODUCTNAME_MVE_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MVE}) é uma instância BYOL de um gateway SD-WAN Parceiro fornecida e hospedada pela ${process.env.VUE_APP_EN_COMPANYINFO_NAME} adjacente a locais de ponto de acesso à nuvem no mundo todo. Ele permite acesso controlado a recursos de rede da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} pela Internet. Ele não exige uma ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} física mas pode se conectar a uma se você desejar. Todo o licenciamento e gestão fica a cargo de soluções Parceiras.`,
    'ssh-key': `Chave SSH`,
    'ssh-key-rsa-help': `Chave pública SSH RSA de 2048-bits`,
    'mve-appliance-licence': `Licença do Aparelho`,
    'mve-activation-code': `Código de Ativação`,
    'mve-address': `Endereço do Orquestrator`,
    // FQDN - Nome de Domínio Totalmente Qualificado
    'mve-address-placeholder': `IPv4/IPv6 ou FQDN`,
    'mve-select-vendor': `Escolha Fornecedor / Produto`,
    'mve-cloud-init-file-upload': `Arquivo Cloud-init`,
    'mve-cloud-init-file-upload-help': `Verifique a documentação de produto do vManage para gerar um arquivo cloud-init.`,
    'mve-appliance-licence-help': `Licença do Aparelho`,
    'key-valid': `Válida`,
    'prefix-list-count': `{count} Lista | {count} Listas`,
    'mve-director-address': `Endereço do Diretor`,
    'mve-controller-address': `Endereço do Controlador`,
    'mve-local-auth': `Auth Local`,
    'mve-local-auth-help': `String de identificação local ou email`,
    'mve-remote-auth': `Auth Remota`,
    'mve-remote-auth-help': `String de identificação remota ou email`,
    'serial-number': `Número de Série`,
    'prefix-list-save-confirm': `Deseja salvar as mudanças à lista de prefixos?`,
    'how-to-create-port-text': `Como criar uma ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'how-to-create-port-paragraph': `Para mais instruções sobre {0}, visite a documentação da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'aruba-orchestrator-name': `Nome da Conta do Orquestrator`,
    'aruba-orchestrator-name-tt': `O nome da conta associada à instância do Aruba Orchestrator`,
    'aruba-account-key': `Chave da Conta`,
    'aruba-account-key-tt': `A chave da conta ligada ao nome da conta acima`,
    'aruba-system-tag': `Tag do Sistema`,
    'aruba-system-tag-tt': `Usado ao se cadastrar no Aruba Cloud Portal e no Aruba Orchestrator`,
    'as-path-prepend-label': `Prepend do Caminho AS`,
    'unbundled-mve-size-info': `Este tamanho incluirá {cpuCount} vCPUs, {ram}GB de RAM, e {storage}GB de armazenamento.`,
    'mve-appliance-mode': `Modo do Aparelho`,
    'mve-appliance-mode-tooltip': `<p>O Modo do Aparelho determinará como você fará para configurar e gerenciar o seu lançamento.</p>
      <ul>
      <li><strong>Modo Autônomo</strong>: via Interface de Linha de Comando (CLI)</li>
      <li><strong>Modo SD-WAN</strong>: via vManage</li>
      </ul>
      <p>A ${process.env.VUE_APP_EN_COMPANYINFO_NAME} não suporta mudanças de modos do aparelho após o lançamento – fazer isso pode resultar na perda de configurações.</p>
      `,
    'mve-license-data': `Dados da Licença`,
    'mve-admin-password': `Senha de Admin`,
    'mve-admin-password-tooltip': `<p>Você precisará criar uma senha de admin para acessar o dispositivo virtual. A senha de admin deve conter:</p>
      <ul>
      <li>no mínimo oito caracteres</li>
      <li>pelo menos uma letra maiúscula</li>
      <li>pelo menos uma letra minúscula</li>
      <li>pelo menos um número</li>
      <li>pelo menos um símbolo</li>
      </ul>
      <p>Por favor tome cuidado para armazenar a senha de admin com segurança e nunca a compartilhe com outras pessoas. Se necessário, a senha de admin pode ser reiniciada ao fazer login pela primeira vez no dispositivo configurado.</p>
      <p><strong>AVISO</strong>: A ${process.env.VUE_APP_EN_COMPANYINFO_NAME} não armazena senhas de clientes.</p>
      `,
    'mve-vnic-prompt-info': `A ${process.env.VUE_APP_EN_COMPANYINFO_NAME} lhe pedirá para solicitar um ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} para o boot inicial e configuração uma vez que a configuração base do seu ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} for finalizada.`,
    'mve-vendor-vnic-count': `${process.env.VUE_APP_EN_PRODUCTNAME_MVES} {vendor} têm um vNIC. | ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} {vendor} têm dois vNICs (vNIC 0 & vNIC 1). | ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} {vendor} têm múltiplas vNICs.`,
    'mve-ip-transit-vxc-prompt-success': `Você configurou com sucesso o componente de computação do seu ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} {vendorName}, que inclui CPU, RAM & armazenamento.`,
    'mve-ip-transit-vxc-prompt-recommend': `Para finalizar a configuração da sua ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} {vendorName}, recomendamos que você crie uma conexão ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}.`,
    'mve-ip-transit-vxc-prompt-docs': `Para mais detalhes, veja nosso {link}.`,
    'mcr-prefix-filter-support': `O recurso de Filtro de Prefixos do ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} suporta filtros de rota BGP de entrada (inbound) e saída (outbound) baseados em prefixos.`,
    'mcr-prefix-filter-configure': `Você poderá adicionar e configurar listas de prefixos quando o seu serviço estiver configurado.`,
    'cannot-edit': `não pode ser editado`,
    'move-connections': `Mover Conexões`,
    'megaport-internet-restricted-location-reason': `Você tem um ou mais ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} associados a este {productType}. Este {productType} e seus ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} associados devem ser configurados dentro da mesma região.`,
    'megaport-internet-restricted-location-workaround': `Se desejar configurar seu {productType} em uma região diferente, por favor primeiro apague o ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} associado.`,
    'mve-manage-locally': `Gerenciar Localmente`,
    'mve-manage-locally-tooltip': `Indica se esta instância do ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} faz o processo de “phone home” (conexões periódicas) para o Centro de Gestão do Firewall ou não.`,
    'mve-fmc-ip-address': `Endereço de IP do Centro de Gestão do Firewall (FMC)`,
    'mve-fmc-ip-address-tooltip': `Endereço de rede Cisco Secure do Centro de Gestão do Firewall (FMC). Pode  ser um endereço IPv4, IPv6 ou : hostname`,
    'mve-fmc-registration-key': `Chave de Registro do FMC`,
    'mve-nat-id': `ID NAT`,
    'mve-nat-id-tooltip': `Inserir o ID NAT se o Controlador de Gestão do Firewall não puder ser endereçado diretamente.`,
    'mve-ion-key': `Chave ION`,
    'mve-secret-key': `Chave Secreta`,
    'unbundled-mve-size-info-no-storage': `Este tamanho incluirá {cpuCount} vCPUs e {ram}GB de RAM.`,
    'service-key-name': `Chaves de Serviço {name}`,
    'mve-cisco-vmanage': `vManage`,
    'mve-aviatrix-secure-edge': `Aviatrix Secure Edge`,
    'mve-select-version': `Escolher Versão`,
    'mve-version': `Versão {version}`,
    'mve-service-details': `Detalhes de Serviço do {vendor}`,
  },
  'port-term': {
    'funky-1': `O prazo deste serviço não pode ser atualizado automaticamente.`,
    'funky-2': `Por favor entre em contato com o seu representante de Vendas da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    title: `Determinar Prazo do Contrato do {productType}`,
    choose: `Escolher Prazo do Contrato do {productType}`,
    'update-failure': `Falha ao atualizar o prazo da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'update-error': `Um erro ocorreu ao tentar atualizar o prazo da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'update-unavailable': `O prazo da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} selecionada não está disponível.`,
    'update-success': `Prazos do {productType} Atualizados`,
    'reterm-success': `- | Definir o prazo da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} '{portName}' para 1 mês | Definir o prazo da ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} '{portName}' para {count} meses`,
    reterm: `O novo prazo de contrato para este serviço (o Prazo Mínimo) tem efeito imediatamente e o desconto de novo prazo será aplicado entre as cobranças do próximo mês. Encerramento antes do Prazo Mínimo gerará taxas de encerramento prévio segundo o acordo entre nós.`,
    'confirm-new-term': `Iniciar Novo Prazo de Contrato`,
    'no-change': `Sem Mudança`,
    'funky-3': `Por favor entre em contato com o seu Parceiro da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'no-minimum-term': `Sem Prazo Mínimo`,
    'minimum-term-tooltip-ports': `O prazo mínimo começará assim que os serviços ficarem ativos ou 14 dias após a configuração do serviço`,
    'minimum-term-tooltip': `O prazo mínimo começará com a realização do pedido`,
    'reterm-immediate': `O novo prazo de contrato para este serviço tem efeito imediatamente. O Prazo Mínimo começará em {start} e terminará em {end}. Encerramento antes do Prazo Mínimo gerará taxas de encerramento prévio segundo o acordo entre nós.`,
    'option-price': `- | Mensal: {cost} por mês | {count} Meses: {cost} por mês`,
  },
  dashboard: {
    navigation: `Aprenda o básico para navegar pelo ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}, e saiba como criar a sua primeira ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.`,
    'visit-marketplace': `Visite o ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'deep-dive': `Aprenda sobre nossos serviços e descubra como criar redes, conectividade em nuvem, e soluções de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} com artigos e documentos técnicos.`,
    'ql-overview': `Uma introdução à ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'ql-vxc': `Como estabelecer uma conexão com um ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    'ql-cloud': `Conectando-se a Provedores de Serviços de Nuvem`,
    'ql-mcr': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} - Roteamento virtual para conectividade privada em camada 3`,
    'marketplace-marketing': `O centro online onde nossos parceiros provedores de serviços e clientes se unem para compartilhar serviços. No ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}, você pode descobrir novos recursos e ser descoberto por novos clientes.`,
    'marketplace-image': `Clique para escolher uma imagem para o perfil da sua empresa no ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}.`,
    'get-started': `Começar`,
    'quick-links': `Links Rápidos:`,
    documentation: `Documentação da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    video: `Ver Vídeo`,
    'video-error': `Não Foi Possível Transmitir o Vídeo`,
    'video-error-message': `A execução de vídeos parece ter sido bloqueada no seu navegador. Relataremos isso ao time de desenvolvimento para investigação. Perdão pela inconveniência.`,
  },
  menu: {
    dashboard: `Painel`,
    company: `Empresa`,
    'ix-looking-glass': `${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX} Looking Glass`,
    'ix-telemetry': `Telemetria da ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'marketplace-profile': `Perfil do ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'mcr-looking-glass': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} Looking Glass`,
    'more-events': `Mais eventos estão disponíveis:`,
    'company-profile': `Perfil da Empresa`,
    'billing-markets': `Mercados de Cobrança`,
    'manage-users': `Gerenciar Usuários`,
    tools: `Ferramentas`,
    'api-documentation': `Documentação da API`,
    'api-key-generator': `Gerador de Chaves API`,
    'event-log': `Registro de Eventos`,
    'view-event-log': `Ver Registro de Eventos`,
    events: `Eventos`,
    'bug-report': `Enviar Relatório de Bug`,
    'user-profile': `Perfil do Usuário`,
    'notification-settings': `Configurações de Notificação`,
    logout: `Sair`,
    'partner-admin': `Admin Parceiro`,
    'company-security-settings': `Configurações de Segurança`,
    'technical-support-details': `Detalhes do Suporte Técnico`,
    'account-contacts': `Contatos da Conta`,
    entitlements: `Direitos da Empresa`,
    feedback: `Enviar Feedback`,
  },
  marketplace: {
    'pluralize-provider-type': `Tipos de Provedores | Tipo de Provedor | Tipos de Provedores `,
    'pluralize-locations': `Locais | Local | Locais`,
    profile: `Perfil do ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'currently-private': `Seu perfil do ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} atualmente é privado, o que quer dizer que a nossa rede global de clientes não pode te encontrar e se conectar aos seus serviços.`,
    'port-count': `Contagem de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'no-profile': `Atualmente você não tem um perfil do ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT} configurado.`,
    visible: `Seu perfil ficará visível no ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    hidden: `Seu perfil ficará oculto para outros no ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    services: `Serviços do ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'create-vxc': `Criar um novo ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} para essa ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'no-deployed-ports': `Você não tem ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} disponíveis nesta região. Clique para voltar aos seus serviços e criar uma.`,
    'display-name': `Nome mostrado no ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'select-services-port': `Escolha serviços que esta ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} pode fornecer`,
    'no-ports-title': `Não há ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} disponíveis`,
    'no-ports-message': `Você não tem ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} lançadas disponíveis nesta região. Isso pode ocorrer geralmente por causa de um desencontro entre regiões de rede ou porque ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} que poderiam ser escolhidas têm conexões não-marcadas. Clique OK para voltar aos seus serviços e criar uma.`,
    'contact-company': `Entrar em contato com {company}`,
    'email-sent-to': `Seu email foi enviado para {recipient}.`,
    'not-ready-publishing': `Parece que o seu perfil no ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} não está pronto para publicação. Clique em Criar Perfil para finalizar sua página para que a nossa rede global de clientes possa te encontrar e se conectar aos seus serviços.`,
    'easy-get-connected': `É fácil se conectar. Clique em Editar Perfil e torne-o disponível no nosso ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}.`,
    'marketplace-intro': `O  ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} conecta o seu negócio à nossa rede global de clientes. Essa plataforma permite que você chegue a novos mercados para encontrar fontes de renda previamente inatingíveis. Completar seu perfil e mantê-lo atualizado aumenta em muito o potencial de vendas e a exposição de sua marca a clientes prontos para fazer negócios.`,
    'private-ports': `Serviços cinzas não estão marcados como ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} públicas. Para ativá-los, mude a opção de visibilidade do ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} no editor de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}. Apenas ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} públicas serão visíveis a outros usuários que visualizarem seu perfil.`,
    'duplicate-name-warning': `Isso pode causar confusão para pessoas que desejem se conectar às suas ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}.<br/><br/>Tem certeza de que deseja salvar?`,
    sending: `Enviando Mensagem...`,
    'general-info': `Informações Gerais de Serviço`,
    'send-copy': `Envie-me uma cópia desta mensagem`,
    message: `Insira sua mensagem`,
    sent: `Mensagem Enviada`,
    'send-error': `Erro ao enviar sua mensagem`,
    target: `Alvo da Conexão`,
    'connection-from': `Origem da Conexão`,
    filter: `Filtrar por Tipo de Provedor`,
    'select-types': `Escolher os tipos e sub-tipos necessários`,
    'clear-filter': `Limpar Filtro`,
    'hide-message': `Ocultar Esta Mensagem`,
    'hide-today': `Apenas Hoje`,
    'hide-week': `Por Uma Semana`,
    'hide-forever': `Para Sempre`,
    'edit-profile': `Editar Perfil`,
    'save-profile': `Salvar Perfil`,
    'create-profile': `Criar Perfil`,
    'sort-alpha': `A - Z`,
    'sort-location-count': `Contagem de Locais`,
    'grid-display': `Mostrar em grade`,
    'list-display': `Mostrar em lista`,
    'company-name-filter': `Filtro de Nome de Empresa`,
    'select-speeds': `Escolher Velocidades`,
    'select-provider-types': `Escolher Tipos de Provedores`,
    'select-countries': `Escolher Países`,
    'loading-companies': `Carregando empresas`,
    'all-services': `todos`,
    'selected-services': `serviços escolhidos`,
    'profile-message-hidden': `Mensagem de Perfil Ocultada`,
    'hidden-day': `Esta mensagem foi ocultada por um dia.`,
    'hidden-week': `Esta mensagem foi ocultada por uma semana.`,
    'hidden-message': `Esta mensagem não será mais mostrada nesta tela, mas você pode criar ou editar seu perfil do ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT} na opção de menu "Empresa >Perfil do ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}".`,
    'hide-profile': `Ocultar Mensagem de Perfil`,
    'hidden-permanently': `Esta mensagem foi ocultada permanentemente.`,
    'upload-logo': `Fazer Upload do Logo`,
    'change-logo': `Mudar Logo`,
    social: `Social`,
    headquarters: `Sede`,
    'address-1': `Endereço 1`,
    'address-2': `Endereço 2`,
    city: `Cidade`,
    postcode: `Código Postal`,
    'point-click': `Apontar, Clicar, Conectar.`,
    'no-edit-permission': `Você não tem permissão para editar o perfil. Entre em contato com um usuário admin da empresa para editá-lo.`,
    'loading-editor': `Carregando o editor de perfil...`,
    'no-create-permission': `Você não tem permissões para criar um perfil. Entre em contato com um usuário admin da empresa para criar um perfil.`,
    'profile-url': `Perfil disponível na URL:`,
    contact: `Contato`,
    visibility: `Visibilidade do Perfil`,
    'company-bio': `Bio da Empresa`,
    'bio-length': `Bio da Empresa (200 palavras)`,
    'contact-email': ` Email de Contato`,
    'recently-viewed': `Fornecedores Vistos Recentemente`,
    'similar-providers': `Provedores de Serviços Semelhantes`,
    'company-list': `Lista de Empresas`,
    'duplicated-names': `Os seguintes nomes de serviço estão duplicados:`,
    'duplicate-name': `Duplicar  Nome Mostrado`,
    description: `Descrição do Serviço (200 palavras)`,
    'service-contact-email': `Email de Contato do Serviço`,
    'service-types': `Tipos de Serviço`,
    'company-profile': `Perfil empresarial do ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} para a empresa {company}`,
    'live-time': `Ativo por {time}`,
    'company-at-location': `{company} em {location}`,
    state: `Estado`,
    country: `País`,
  },
  'mcr-looking-glass': {
    'no-services-message': `Você não tem serviços de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MCR}) configurados.`,
    'no-bgp-sessions': `Não há sessões BGP para essa ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'no-services': `Nenhum serviço encontrado`,
    selection: `Seleção de ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'routes-table': `Tabela de Rotas`,
    'bgp-table': `Tabela de BGP`,
    'all-reachable': `Todas as redes alcançáveis na tabela de roteamento da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.`,
    'all-routes': `Todas as Rotas`,
    'routes-by-ip': `Rotas por IP`,
    find: `Encontrar Rotas`,
    loading: `Carregando Rotas`,
    'enter-ip': `Insira um endereço de IP ou de rede para encontrar rotas`,
    'no-routes': `Não há rotas para este endereço de IP`,
    'no-bgp': `Não há conexões BGP anexadas a esta interface`,
    neighbour: `Rotas Vizinhas`,
    'bgp-sessions': `Sessões BGP`,
    'bgp-sessions-tooltip': `Todas as sessões BGP configuradas nos ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} para a ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} selecionada.`,
    'filter-sessions': `Insira um string para filtrar as sessões`,
    'toggle-details': `Alternar detalhes adicionais`,
    'total-routes': `Rotas totais`,
    'filter-routes': `Insira texto para filtrar rotas`,
    advertised: `Divulgada`,
    received: `Recebida`,
    'sort-by-thing': `Ordenar por {thing}`,
    'clear-filter-routes': `Limpar rotas de filtro`,
    'contact-support': `Entre em contato com nossa equipe de Suporte para discutir como a ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} pode lhe beneficiar.`,
    'service-could-not-be-loaded': `Serviço não pode ser carregado ({errorStatus})`,
    'service-loading-timeout': `Pedimos desculpas pela inconveniência, mas parece que houve um problema ao carregar esta informação. Se o problema continuar, por favor entre em contato com a nossa equipe de suporte para solicitar ajuda em ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
  },
  'ix-flows': {
    'no-ix-found': `Serviço de ${process.env.VUE_APP_EN_PRODUCTNAME_IX} não encontrado`,
    'no-ix-message': `Você não tem nenhum serviço de ${process.env.VUE_APP_EN_PRODUCTNAME_IX}. Entre em contato com o seu representante de venda se estiver interessado em serviços de {process.env.VUE_APP_EN_PRODUCTNAME_IX} da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'date-select': `Escolher data`,
    'select-peer': `Escolher um peer`,
    'no-data': `Não há dados disponíveis para este peer`,
    'flow-type': `Tipo de fluxo`,
    'loading-peers': `Carregando peers`,
    'sort-by': `Ordenar por`,
    'filter-by-name': `Filtrar por nome`,
    'total-peers': `Peers totais: {count}`,
    'top-5': `Top 5 peers ({direction})`,
    'volume-in': `Volume (entrando)`,
    'volume-out': `Volume (saindo)`,
    'load-error': `Há um problema ao carregar estes dados. Por favor recarregue para tentar novamente.`,
  },
  checkout: {
    'minimum-term-months': `Sem Prazo Mínimo | Mês a Mês | Prazo Mínimo: {count} Meses`,
    'unknown-validation-error': `Erro de validação desconhecido: '{message}'`,
    'hide-promo': `Ocultar`,
    'add-promo': `Adicionar Código`,
    order: `Pedir Serviços`,
    'set-up-company': `Configure sua empresa`,
    'register-markets': `Cadastrar mercados`,
    'order-now': `Pedir agora`,
    'need-setup': `Antes de poder pedir serviços, você precisa completar a configuração da conta.`,
    'need-company': `Você precisa configurar a informação da sua empresa.`,
    'need-company-permissions': `A informação da sua empresa não está configurada, mas você não tem privilégios suficientes. Entre em contato com o administrador da sua empresa.`,
    'need-markets': `Você precisa ativar os mercados da empresa exigidos.`,
    'need-markets-permissions': `Os mercados da sua empresa não estão ativados, mas você não tem privilégios suficientes. Entre em contato com o administrador da sua empresa.`,
    validating: `Validando o seu pedido`,
    deploying: `Lançando...`,
    'no-term': `Sem Prazo`,
    promo: `Promo`,
    'add-promo-code': `Adicionar Código Promocional`,
    'promo-code': `Código Promocional`,
    'checking-promo': `Verificando Código Promocional`,
    'promo-validated': `Código Validado`,
    'no-deploy': `Aviso: Embora você possa criar e salvar carrinhos, as permissões da sua conta não permitem que você encomende serviços.`,
    'invalid-code': `Código Inválido`,
    'code-not-confirmed': `Código promocional não pode ser confirmado`,
    'save-failed': `Falha ao salvar os serviços configurados`,
    'error-deploying': `Erro ao lançar os serviços.`,
    'unknown-error': `Um erro desconhecido ocorreu durante a tentativa de lançamento`,
    deployed: `Serviços lançados com sucesso.`,
    success: `Serviços do Pedido de ID {orderId} lançados com sucesso:`,
    'null-code': `Por favor insira um Código Promocional`,
    'capture-tech-support-details': `Capturar Detalhes de Suporte Técnico`,
    'set-up-tech-support-details': `Detalhes do Suporte Técnico`,
    'need-tech-support-details': `Detalhes de Contato do Suporte Técnico são necessários antes de que pedidos possam ser enviados.`,
    'need-tech-support-details-by-admin': `Detalhes de Contato do Suporte Técnico deverão ser completados por um admin da empresa.`,
    'missing-tech-support-details': `Faltando Detalhes de Contato do Suporte Técnico`,
    'deleting-configuration': `Apagando configurações salvas...`,
    'refreshing-services': `Recarregando serviços...`,
  },
  sidebar: {
    'enable-market': `Ativar mercado de {country}`,
    'configured-services': `Serviços Configurados`,
    'save-configured-services': `Salvar Serviços Configurados`,
    'save-configured-services-later': `Salvar estes serviços configurados para depois.`,
    'saved-configurations-alert': `Configurações salvas afetam toda a empresa e ficarão visíveis para qualquer pessoa na sua organização.`,
    'existing-configuration-warning': `Uma configuração com este nome já existe. Salvar com o mesmo nome substituirá a configuração salva anteriormente.`,
    'clear-configured-services': `Limpar todos os serviços configurados`,
    'save-configured-name': `Insira um nome para a configuração`,
    'saved-configurations': `Configurações Salvas`,
    'config-saved-title': `A configuração {name} foi salva`,
    'config-saved-message': `A configuração {name} ({serviceOrder}) foi salva no servidor com sucesso.`,
    'config-save-error-title': `Erro ao salvar a configuração {name}.`,
    'config-deleted-title': `Configuração apagada`,
    'config-delete-error-title': `Não foi possível apagar a configuração {name}.`,
  },
  'billing-markets': {
    'vat-characters': `Insira um VAT com tamanho de {length} caracteres`,
    'purchase-order-market': `O número do Pedido de Compra da fatura aparecerá no cabeçalho da fatura para o mercado {country} quando a fatura for gerada`,
    'due-date-date': `Prazo: {date}`,
    'remove-card-ending': `remover cartão que termina com {numbers}`,
    'edit-card-ending': `Editar opções para o cartão que termina com {numbers}`,
    'pay-card-ending': `Fazer um pagamento usando o seu cartão cadastrado que termina com {numbers}.`,
    'remove-card-confirmation': `Você quer mesmo remover o cartão de crédito que termina com {numbers} do mercado de cobrança da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} {marketName}?`,
    'dont-accept-currency': `Nós não aceitamos pagamentos em {currency} via cartão de crédito neste mercado. Por favor verifique sua fatura para ver outros métodos de pagamento.`,
    title: `Mercados de faturamento da empresa`,
    enabled: `Mercados Ativados`,
    none: `Não há mercados ativados`,
    options: `Opções`,
    'view-invoices': `Ver Faturas`,
    payment: `Fazer Pagamento`,
    edit: `Editar Mercado`,
    view: `Ver Mercado`,
    'download-invoices': `Baixar Todas as Faturas`,
    'download-json': `Baixar como JSON`,
    'download-csv': `Baixar CSV`,
    'enable-bm': `Ativar um Mercado de Cobrança`,
    'none-available': `Não há mercados disponíveis`,
    enable: `Ativar`,
    market: `Mercado`,
    invoices: `Faturas`,
    'next-invoice': `Próxima Fatura`,
    disclaimer: `(assumindo que não haverá mudanças de velocidade, excl. impostos)`,
    balance: `Saldo da Conta`,
    'inc-tax': `(incluindo Impostos)`,
    'download-statement': `Baixar Declaração`,
    'no-bills': `Nenhum Serviço foi faturado neste mercado ainda.`,
    'invoice-no': `# da Fatura`,
    'issue-date': `Data de Emissão`,
    total: `Total`,
    payments: `Pagamentos`,
    'no-stripe': `Pagamentos Stripe não estão disponíveis no momento`,
    'cc-history': `Histórico de Pagamentos de Cartão de Crédito`,
    'no-payments': `Não há pagamentos registrados`,
    description: `Descrição`,
    'payment-date': `Data do Pagamento`,
    'unavailable-market': `Pagamentos por cartão de crédito não são aceitos para este mercado. Verifique sua fatura para ver outros métodos de pagamento.`,
    'billing-currency': `Moeda de Cobrança`,
    'currency-fixed': `A moeda de cobrança não pode ser alterada`,
    'no-currency-change': `A moeda de cobrança não pode ser alterada depois que o Mercado foi configurado`,
    language: `Idioma da Fatura`,
    'language-future-invoices': `Aviso: Mudanças ao idioma da fatura só afetam as próximas faturas.`,
    'billing-name': `Nome do Contato de Cobrança`,
    'billing-phone': `Telefone do Contato de Cobrança`,
    'phone-format': `Telefone Internacional. Exemplo: +61 4321 1234`,
    'billing-email': `Email do Contato de Cobrança`,
    'email-format': `Pode ser um endereço de email único ou uma lista de endereços de email separados por vírgula`,
    'billing-address': `Endereço de Cobrança`,
    'address-2': `Linha de Endereço 2`,
    city: `Cidade`,
    province: `Província`,
    state: `Estado`,
    postcode: `Código Postal / Zip`,
    'select-or-type': `Escolha ou digite para filtrar opções`,
    'company-registration': `Número de Registro da Empresa`,
    'company-tooltip': `Número de Registro da Empresa associado  à entidade compradora.`,
    'vat-registration': `Você está registrado para VAT?`,
    'vat-id': `Número de Identificação do VAT.`,
    'vat-number': `Número de identificação do VAT cadastrado.`,
    po: `Número do Pedido de Compra da Fatura`,
    currency: `Moeda do mercado`,
    'company-identifier': `Identificador da empresa`,
    'contact-name': `Nome do contato`,
    'phone-number': `Telefone`,
    address: `Endereço`,
    'due-unavailable': `Indisponível`,
    vat: `VAT`,
    'register-card': `Cadastrar Cartão`,
    'register-card-status': `Status de configuração do cartão: {status}`,
    'register-card-edit': `Editar Cartão Cadastrado`,
    'register-card-fail': `Falha ao Cadastrar Cartão`,
    'register-card-success': `Cartão cadastrado com sucesso.`,
    'register-card-fail-message': `Falha ao cadastrar seu cartão.`,
    'register-card-message': `Cadastre um cartão para um mercado de cobrança da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} para fazer pagamentos automáticos (após a fatura ser emitida) ou pagamentos individuais manuais.`,
    'registering-card': `Cadastrando Cartão...`,
    'remove-card': `Remover Cartão`,
    'removing-card': `Removendo cartão...`,
    'remove-card-fail': `Erro ao remover cartão`,
    'remove-card-fail-message': `Falha ao remover cartão: {error}`,
    'update-card': `Atualizar cartão`,
    'update-card-success': `Cartão atualizado com sucesso.`,
    'update-card-fail': `Erro ao atualizar cartão`,
    'update-card-fail-message': `Falha na atualização do cartão: {error}`,
    'remove-card-success': `Cartão removido com sucesso.`,
    'save-card-fail': `Falha ao Salvar Cartão`,
    'save-card-fail-message': `Não foi possível salvar a informação do cartão: {error}`,
    'payment-once': `Fazer Pagamento Individual`,
    'payment-once-make': `Fazer um pagamento individual único à sua conta.`,
    'payment-processing': `Processando o Pagamento`,
    'payment-confirm-fail': `Falha ao confirmar configuração de pagamento`,
    'payment-error': `Erro inesperado de pagamento`,
    'payment-success': `Pagamento feito com sucesso.`,
    'payment-success-message': `{amount} {currency} foi pago ao mercado [{market}]`,
    'card-number': `Número do Cartão`,
    'card-expiry': `Validade`,
    'card-cvc': `CVC`,
    'auto-payments-on': `Pagamentos Automáticos Ativados`,
    'auto-payments-off': `Pagamentos Automáticos Desativados`,
    'payment-amount': `Valor do Pagamento`,
    'payment-description': `Descrição do Pagamento`,
    'payment-failed': `Falha no Pagamento`,
    'payment-create-failed': `Falha ao Criar Método de Pagamento`,
    'payment-create-failed-message': `Não foi possível criar o método de pagamento Stripe: {error}`,
    'payment-attempt-failed': `Falha na tentativa de pagamento: {error}`,
    'payment-market-fail': `Nenhum pagamento foi feito ao mercado [{market}]. {error}`,
    'processing-fees': `Taxas de processamento do Cartão de Crédito são adicionadas ao valor do pagamentos e refletidas na sua próxima fatura. A ${process.env.VUE_APP_EN_COMPANYINFO_NAME} passa essas taxas diretamente do nosso provedor de serviços.`,
    'account-balance': `O saldo atual da sua conta é | (inc. impostos).`,
    'enable-markets': `Ativar Mercados`,
    'services-no-market': `Você tem serviços configurados para mercados que não foram ativados ainda.`,
    'enable-markets-admin': `Os seguintes mercados de cobrança precisarão ser ativados por um admin da empresa:`,
    'no-payment-method': `Nenhum dado de método de pagamento recebido.`,
    'stripe-error': `Erro do Stripe`,
    'update-success': `A oferta do mercado "{country}" foi atualizada com sucesso.`,
    supply: `Mercado de cobrança`,
    'create-success': `Oferta para o mercado "{country}" foi criada com sucesso.`,
    'payment-docs-html': `Você pode obter informações adicionais sobre as taxas de transações de cartão de crédito do nosso provedor de serviços, Stripe, {link}`,
    'cc-autopay': `Pagamentos automáticos serão processados no dia 20 de cada mês para o saldo pendente em sua conta.`,
    'cc-support-html': `Se tiver quaisquer dúvidas relacionadas a pagamentos em cartão de crédito, por favor entre em contato com {email}`,
    download: `Baixar`,
    'due-date': `Vencimento`,
    'invoice-date': `Data da Fatura`,
    'invoice-amount': `Valor da Fatura`,
    'open-balance': `Saldo Aberto`,
    'date-range': `Período`,
    'download-pdf': `Baixar PDF`,
    'download-invoices-info': `Note por favor: A função de Baixar Todas as Faturas não é mais suportada. Por favor baixe faturas individuais a partir do ícone de Ver Faturas acima e escolhendo um período.`,
    'billing-address-entry-rules': `Este endereço deve ser o local do negócio onde os principais tomadores de decisão relacionados à aquisição de serviços da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} e ao seu uso estão localizados`,
    UK_MOBQ: `Você usa nossos serviços no Reino Unido?`,
    UK_MOBQ_TOOLTIP: `Por favor confirme se você tem pessoas no Reino Unido que usam esses serviços`,
    AU_MOBQ: `Você está cadastrado no GST australiano?`,
    AU_MOBQ_TOOLTIP: `Por favor confirme se a entidade legal ou filial que usa os serviços (pode ser uma afiliada da entidade faturada pela ${process.env.VUE_APP_EN_COMPANYINFO_NAME}) está cadastrada no GST australiano`,
    SG_MOBQ: `Você está cadastrado no GST de Singapura?`,
    SG_MOBQ_TOOLTIP: `Por favor confirme  se a entidade legal ou filial que usa os serviços (pode ser uma afiliada da entidade faturada pela ${process.env.VUE_APP_EN_COMPANYINFO_NAME}) está cadastrada no GST de Singapura`,
    'company-type-mexico': `Nome da Empresa`,
    'company-type-mexico-tooltip': `Tipo de contribuinte (pessoa física, jurídica, ou entidade estrangeira)`,
    'tax-regime-code-mexico': `Regime Tributário`,
    'tax-regime-code-mexico-tooltip': `Regime Tributário - Escolha o atual código de Regime Fiscal cadastrado junto ao Serviço de Administração de Impostos mexicano (SAT)`,
    'sat-purpose-mexico': `Finalidade Fiscal`,
    'sat-purpose-mexico-tooltip': `Finalidade Fiscal`,
    'rfc-mexico': `Número RFC`,
    'rfc-mexico-tooltip': `Atribua o atual número do Registro Federal de Contribuintes - conforme emitido pelo Serviço mexicano de Admnistração de Impostos (SAT)`,
    'fusf-interstate-traffic-certification': `Certificação Federal de Tráfego Interestadual da USF *`,
    'fusf-recommended-text': `* Recomendamos fortemente a leitura da dica de ferramenta abaixo para mais contexto`,
    'fusf-tooltip-content': `Para serviços de transmissão de dados* com pontos finais no mesmo estado, a Megaport é obrigada pela Federal Communications Commission (FCC) a assumir que o tráfego do cliente cruza linhas de estado como parte da rede mais ampla do cliente, a não ser que o cliente confirme o contrário.<br/><br/>
      Ao marcar a caixa, o cliente certifica que <strong>mais de 10%</strong> do tráfego é interestadual em sua natureza em relação a todos os produtos deste tipo adquiridos da Megaport. Onde esse for o caso, os serviços estarão sujeitos a cobranças do Federal Universal Service Fund (FUSF).<br/><br/>
      Ao não marcar a caixa, o cliente certifica que <strong>10% ou menos</strong> do tráfego transmitido por meio de tais produtos cruza linhas de estado, o que significa que cobranças de FUSF não se aplicarão.<br/><br/>
       Um registro eletrônico do nome e cargo do usuário da conta que forneceu a certificação acima será gerado para os registros da Megaport e pode ser usado para fins de auditoria.<br /><br />
      *Os produtos de transmissão de dados da Megaport incluem VXC, Megaport Internet e Internet Exchange`,
  },
  users: {
    'configure-destination-notifications': `Configurar Notificações {destination}`,
    'editing-user-name-you': `Editando Usuário {firstName} {lastName} {you}`,
    'user-not-found-redirect': `Não foi possível encontrar o usuário {userId}. Redirecionando para a página de Gerenciar Usuários.`,
    'position-not-found': `Este usuário tem um papel ({position}), que não é mais suportado pelo sistema. Escolha dentre as opções disponíveis.`,
    'invalid-email': `{email} não é um endereço de email válido`,
    deactivated: `Mostrar usuários desativados`,
    notifications: `Notificações`,
    destinations: `Destinos Disponíveis`,
    'no-destinations': `Não há Destinos Disponíveis`,
    'detailed-notifications': `Mostrar Notificações Detalhadas`,
    filter: `Filtrar`,
    'no-match': `Não Há Usuários Correspondentes`,
    active: `A conta do usuário está ativa`,
    disabled: `A conta do usuário está desativada`,
    you: `(Você)`,
    new: `Novo Usuário`,
    'logged-in-as-user': `Você atualmente fez login como este usuário. Você pode modificar os seus detalhes de usuário na página de Perfil de Usuário.`,
    state: `Estado do Usuário`,
    'technical-admin': `Admin Técnico`,
    finance: `Finanças`,
    'financial-contact': `Contato Financeiro`,
    role: `papel do usuário`,
    'role-not-found': `Papel Não Encontrado`,
    'user-not-found': `Usuário Não Encontrado`,
    'missing-info': `Informação Faltante`,
    'change-email': `Mudar Email`,
    'changing-email': `Mudando Email...`,
    'new-email': `Novo Email`,
    'email-preferences': `Preferências de Email`,
    'saving-email-preferences': `Salvando Preferências de Email...`,
    'receive-newsletter': `Receber a Newsletter da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'receive-promotional-material': `Receber Material Promocional da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'change-password': `Mudar Senha`,
    'changing-password': `Mudando Senha...`,
    'old-password': `Senha Antiga`,
    'new-password': `Nova Senha`,
    'retype-new-password': `Digitar a Nova Senha de Novo`,
    'saving-user-profile': `Salvando Perfil do Usuário`,
    'account-password': `Senha da Conta`,
    username: `Nome de Usuário`,
    'loading-qr-code': `Carregando código QR`,
    'account-activity': `Atividade da Conta`,
    'account-activity-none': `Não há dados de atividade`,
    'account-activity-loading': `Carregando dados de atividade...`,
    'activity-data-load-fail': `Falha ao carregar dados de atividade`,
    'save-failed': `Falha ao Salvar`,
    'save-user-profile-failed': `Falha ao salvar perfil de usuário`,
    'save-email-failed': `Falha ao alterar email`,
    'save-email-preference-failed': `Falha ao salvar preferências de email`,
    'profile-saved': `Perfil atualizado com sucesso.`,
    'email-change-success': `Preferências de email alteradas.`,
    'save-password-failed': `Falha ao Salvar Senha`,
    'save-password-failed-message': `Falha ao Salvar Senha atualizada`,
    'saving-user-profile-error': `Erro ao atualizar o perfil`,
    profile: `Perfil`,
    'user-name': `Usuário {firstName} {lastName}`,
    'verification-email': `Um email de verificação foi enviado para o seu novo endereço.`,
    'verification-message': `Siga as etapas listadas no email.`,
    'user-role': `Papel do Usuário`,
    'viewing-user-name-you': `Visualizando Usuário {firstName} {lastName} {you}`,
    'cant-edit-invite-pending': `Este perfil de usuário pode ser editado até que o usuário aceite o convite. Por favor apague o usuário e convide novamente se for necessário fazer alterações imediatamente.`,
    'deleting-user': `Apagando usuário...`,
    'disable-mfa': `Desativar MFA`,
    'enable-mfa': `Ativar MFA`,
    'reset-mfa': `Resetar MFA`,
    'disable-mfa-dialog': `Desativar Autenticação Multi-Fatores`,
    'enable-mfa-dialog': `Ativar Autenticação Multi-Fatores`,
    reset: `Resetar`,
    'mfa-long': `Autenticação Multi-Fatores`,
    'reset-mfa-dialog': `Resetar Autenticação Multi-Fatores`,
    'reset-mfa-success': `Autenticação Multi-Fatores resetada com sucesso`,
    'reset-mfa-failed': `Erro ao resetar a Autenticação Multi-Fatores`,
    'reset-mfa-dialog-desc': `Você está prestes a resetar o seu segredo de Autenticação Multi-Fatores. Após escanear o código QR e confirmá-lo, os novos segredos ficarão ativos e todos os velhos segredos em dispositivos serão invalidados.`,
    'mfa-enforced-disable-tooltip': `O Admin da sua empresa definiu uma política global exigindo MFA. Você não pode desativar MFA enquanto ela estiver em vigência.`,
    'position-header': `Papel`,
    'mfa-header': `MFA`,
    'mfa-tooltip-not-set': `O usuário não configurou a Autenticação Multi-Fatores`,
    'mfa-tooltip-set': `O usuário configurou a Autenticação Multi-Fatores`,
    set: `Definida`,
    'not-set': `Não Definida`,
    enforced: `Exigida`,
    optional: `Opcional`,
    'partner-only': `Apenas Parceira`,
    'partner-only-tooltip': `Configurar notificações de email associadas apenas à sua conta Parceira`,
    'partner-and-managed': `Parceira e Gerenciada`,
    'partner-and-managed-tooltip': `Configurar notificações de email associadas tanto com a sua conta Parceira quanto a todas as suas contas Gerenciadas`,
    'company-admin-reset-mfa-confirm-title': `Tem certeza de que deseja resetar a Autenticação Multi-Fatores?`,
    'company-admin-reset-mfa-confirm-message': `Garanta que o {username} para o qual você está solicitando a redefinição é uma pessoa de verdade, e não uma conta usada para integrações Máquina a Máquina. Após a MFA ser resetada, o usuário deverá redefinir suas credenciais ao fazer login.`,
    'company-admin-reset-help-text': `Ajudar este usuário a redefinir sua conta. Isso ajudará o usuário a cadastrar um novo dispositivo`,
    'mfa-alt-tag': `Use sua câmera para escanear o código QR`,
    'mfa-app-suggestion': `O Google Authenticator é o nosso aplicativo de autenticação recomendado.`,
    'mfa-token': `Insira o token de uso único gerado`,
    'mfa-loading-failed': `Falha ao carregar código QR,`,
    'mfa-disabled': `Autenticação Multi-Fatores desativada`,
    'enable-mfa-success': `Autenticação Multi-Fatores ativada com sucesso.`,
    'disable-mfa-fail': `Erro ao desativar Autenticação Multi-Fatores`,
    'view-user-actions': `Ver todas as ações de usuário`,
    'mfa-warning-1': `Certifique-se de que a conta cadastrada no seu aplicativo de autenticação multi-fatores foi removida.`,
    'mfa-warning-2': `Você será desconectado ao desativar.`,
    'loading-users': `Carregando usuários da empresa...`,
    'sso-compatibility': `Compatibilidade SSO`,
    'sso-enabled-tooltip': `O domínio de email dos usuários está ligado ao SSO e eles utilizarão SSO por padrão para entrar.`,
    'sso-enforced-no-access-tooltip': `SSO é necessário e o usuário NÃO é compatível com SSO. Acesso Proibido`,
    'sso-optional-can-login-tooltip': `O domínio de email de usuários NÃO é compatível com SSO, mas os usuários podem fazer login com outros métodos`,
    'active-api-keys-found': `Chaves API ativas encontradas`,
    'active-api-keys-inactive-if-continue': `Chaves API ativas foram encontradas para este usuário; elas se tornarão inativas se você continuar. Todas as Chaves API podem ser revisadas em Ferramentas - Gerador de Chaves API`,
    'active-api-keys-input-label': `Para prosseguir, digite Inativo e então clique em Continuar, ou clique em Cancelar para interromper.`,
    'active-api-keys-error': `Digite Inativo para prosseguir`,
    'active-api-keys-review': `É recomendado revisar se as Chaves API geradas por este usuário são usadas em uma integração crítica para o sistema entre a sua empresa e a ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
  },
  'api-keys': {
    'description-intro': `Se você precisa de acesso direto à API da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}, você precisará de uma chave API.`,
    'description-html': `Chaves API só são válidas para o ambiente em que elas foram geradas. Você atualmente está cadastrado no ambiente {environment}, então quaisquer chaves criadas e listadas aqui funcionarão no ambiente {environment}. Se precisar de chaves para acessar um ambiente diferente, entre no ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_SHORT} naquele ambiente e gere as chaves por lá.`,
    'learn-more-html': `Saiba mais sobre ambientes no {link}`,
    forbidden: `Você não pode criar ou gerenciar chaves API a não ser que você seja um administrador de uma empresa configurada com um mercado de cobrança ativo.`,
    'key-description': `A chave API é um identificador único que autentica pedidos para fins de uso e cobrança. Use a chave para gerar um token para autenticar pedidos de API.`,
    'key-generated-message': `A chave API {key} foi gerada`,
    'key-updated-message': `A chave API {key} foi atualizada`,
    'key-deleted-message': `A chave API {key} foi apagada`,
    'key-regenerated-message': `Um novo segredo foi gerado para a chave API {key}`,
    'key-dialog-description': `Sua chave API foi criada com sucesso. Copie sua nova Chave API e Segredo da Chave API e use-os para gerar um token API.`,
    'role-change': `Tem certeza de que deseja mudar o papel <strong>{oldRole}</strong> para <strong>{newRole}</strong>?<br/><br/>O Admin da Empresa tem acesso API total e pode criar, gerenciar e cancelar serviços. Leitura Apenas só pode ver dados de uso e detalhes da configuração, mas não pode fazer mudanças.`,
    'delete-key': `Tem certeza de que deseja apagar esta chave? Uma vez apagada, você não poderá usar a chave para gerar novos tokens.`,
    'regenerate-secret': `Re-gerar seu segredo cancelará todas as sessões que estão usando o segredo antigo. Você precisará usar o novo segredo para gerar tokens para usar esta chave. Tem certeza de que deseja re-gerar seu segredo?`,
    'api-keys': `Chaves API`,
    'read-only': `Leitura Apenas é o papel mais restritivo. Note que um usuário de Leitura Apenas pode ver detalhes dos serviços que você talvez queira manter seguros e privados.`,
    admin: `Admin da empresa`,
    'unknown-role': `Papel desconhecido`,
    'your-api-key': `Sua Chave API`,
    'api-key': `Chave API`,
    secret: `Segredo da Chave API`,
    'secret-message': `Você não poderá re-gerar ou ver este segredo novamente. Certifique-se de armazená-lo em um lugar seguro.`,
    history: `Histórico da Chave API`,
    'history-order': `Mudanças mais recentes estão no topo`,
    'edited-by': `Editado Por`,
    'update-date': `Última Atualização`,
    role: `Papel`,
    created: `Chave criada`,
    updated: `Chave atualizada`,
    regenerated: `Segredo Re-gerado`,
    'unknown-action': `Ação desconhecida de chave`,
    'unknown-user': `usuário desconhecido`,
    'unable-to-read': `Não foi possível ler as chaves API`,
    key: `Chave`,
    'generate-key': `Gerar Chave`,
    'generate-label': `Gerar Chaves API`,
    generating: `Gerando Chave API`,
    'read-only-impersonate': `Como você está imitando outro usuário, você apenas tem acesso de leitura a quaisquer chaves na conta.`,
    'success-generate': `Chave API gerada com sucesso.`,
    'failed-generate': `Falha na geração de Chave API`,
    'success-update': `Chave API  atualizada com sucesso.`,
    'failed-update': `Falha na atualização de Chave API.`,
    'success-delete': `Chave API apagada com sucesso.`,
    'failed-delete': `Falha ao apagar Chave API.`,
    'active-keys': `Chaves API Ativas`,
    'no-active-keys': `Você não tem nenhuma chave ativa atualmente. Gere uma chave para começar.`,
    'active-tooltip': `Você precisará da chave e do segredo para gerar o token de acesso. Você deve ter visto o segredo uma vez ao gerar a chave ou ao gerar um novo segredo para a chave.`,
    'date-created': `Data de Criação`,
    'added-by': `Adicionado por`,
    'loading-user-data': `Carregando dados de usuário`,
    'loading-api-keys': `Carregando chaves API existentes`,
    'deleting-api-keys': `Apagando chave API`,
    'regenerating-secret': `Re-gerando segredo`,
    'saving-changes': `Salvando mudanças`,
    'regenerate-secret-label': `Re-gerar Segredo`,
    'view-history': `Ver Histórico`,
    'confirm-role-change': `Confirmar Mudança de Papel`,
    'delete-key-label': `Apagar Chave`,
    'company-admin': `Usuários Admins da empresa tem acesso a todos os privilégios de usuário. Recomendamos limitar o número de usuários Admins da empresa apenas àqueles que necessitam de acesso total, mas definir ao menos dois para redundância.`,
    'technical-admin': `Este papel é para usuários técnicos que sabem como criar e aprovar pedidos.`,
    'technical-contact': `Este papel é para usuários técnicos que sabem como criar e modificar serviços mas que não têm autoridade para aprovar pedidos.`,
    finance: `Usuários de Finanças devem ter responsabilidade financeira dentro da organização, mas também entender as consequências de suas ações caso apaguem ou aprovem serviços. {note}`,
    'financial-contact': `Este papel de usuário é semelhante ao de Finanças mas sem a capacidade de fazer ou aprovar pedidos, apagar serviços, ou administrar chaves de serviço.`,
    'read-only-short': `Leitura Apenas`,
    'token-expiry': `Validade do Token (Minutos)`,
    'expiry-min': `Mínimo de 5 minutos`,
    'expiry-max': `Máximo de 24 horas (1440 minutos)`,
    'api-resource': `Recurso API`,
    action: `Ação`,
    'name-min': `Mínimo de 3 caracteres`,
    'name-max': `Máximo de 90 caracteres`,
    'name-chars': `Não são permitidos caracteres especiais, exceto travessões e linhas inferiores`,
    'name-invalid': `Nome inválido`,
    'api-documentation': `Documentação da API da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} API`,
    'finance-note': `Aviso: Ao criar serviços, clientes diretos podem ver o custo do serviço antes de lançá-lo. Também é possível ver o custo de serviços lançados selecionando um serviço no ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_SHORT} e vendo a aba Detalhes.`,
    'api-role': `API`,
    'key-active-tooltip': `Chave está ativa`,
    'key-inactive-tooltip': `Chave está inativa devido a desativação de usuário. Esta chave não poderá ser usada exceto se o usuário que criou esta chave seja ativado novamente. Você pode considerar apagar esta chave e criar uma Chave API nova para um usuário ativo.`,
  },
  debug: {
    information: `Informação de Debug`,
    'include-info': `Inclua a informação acima se pretende criar um ticket.`,
  },
  pricebook: {
    product: `Produto`,
    'monthly-rate': `Taxa Mensal`,
    'excludes-tax': `Preço Não Inclui Impostos`,
    'contact-sales': `Entre em contato com Vendas para preços customizados.`,
    failed: `Falha ao obter o preço para o serviço {product}`,
    'monthly-charges-excl-taxes': `Cobranças mensais excluindo impostos`,
    'price-change-1': `A atual cobrança mensal recorrente para este serviço é {currentMonthlyPrice} (excl. impostos). Se não houver mais mudanças de velocidade, será cobrado {totalPrice} (excl. impostos) por este serviço para o atual período de cobrança. Isto é {changeDirectionText} de {deltaPrice} (excl. impostos). A nova cobrança mensal recorrente para este serviço será {longTermMonthlyPrice} (excl. impostos).`,
    'price-change-2': `As mudanças a este serviço resultarão em uma mudança de preço. Se não houver mais mudanças de velocidade, o custo para este serviço será de {totalPrice} (excl. Impostos) para este período de cobrança. Isto é {changeDirectionText} de {deltaPrice} (exc. Impostos).`,
    'price-change-3': `A nova cobrança mensal por este serviço será de {longTermMonthlyPrice} (excl. Impostos). Confirme estas mudanças.`,
  },
  'price-change': {
    detected: `Mudança de Taxa Detectada`,
    cancel: `Não, cancelar mudanças`,
    accept: `Sim, aceitar mudanças`,
    'current-month': `Atual Cobrança do Mês`,
    'new-monthly': `Nova Cobrança Mensal`,
    'this-period': `Este Período de Cobrança`,
    change: `Mudar`,
    'pricing-model': `Fazer atualizações a este serviço resultará numa mudança de preço de acordo com o nosso modelo de preços atualizado.`,
    'confirm-model-change': `Uma vez que essa mudança for aceita, não será possível voltar ao preço antigo, e a próxima nova fatura emitida refletirá isso. Confirme que você deseja aceitar essas mudanças.`,
    increase: `um <strong>aumento</strong>`,
    decrease: `uma <strong>redução</strong>`,
    'current-monthly': `Atual Cobrança Mensal`,
  },
  cancel: {
    immediate: `Cancelamento Imediato`,
    'remove-configured': `Remover Serviços Configurados`,
    terminate: `Encerrar Serviços`,
    'keep-configured-service': `Não, Manter Serviço Configurado`,
    'keep-configured-services': `Não, Manter Serviços Configurados`,
    'remove-configuration': `Sim, Remover Configuração`,
    'keep-services': `Não, Manter Serviços`,
    'clear-configured': `Tem certeza de que deseja limpar todos os seus serviços configurados?`,
    'remove-from-configured': `Tem certeza de que deseja remover este serviço dos seus serviços configurados?`,
    'end-of-contract': `Encerrar ao fim do contrato`,
    later: `Depois`,
    'terminate-immediately': `Encerrar imediatamente`,
    now: `Agora`,
    'now-confirmation': `Escolher "Encerrar Serviços" confirmará o processo de encerramento. Estes serviços serão removidos imediatamente. Uma Taxa de Rescisão Antecipada (ETF) será aplicada ao(s) seguinte(s) serviço(s):`,
    'later-confirmation': `Escolher "Encerrar Serviços" confirmará o processo de encerramento. Estes serviços seguirão operando normalmente até a data listada no serviço abaixo. Uma Taxa de Rescisão Antecipada (ETF) será aplicada ao(s) seguinte(s) serviço(s):`,
    unable: `Não foi possível cancelar:`,
    better: `Há algo que poderíamos ter feito melhor?`,
    'no-permission': `Você não tem permissão para apagar serviços`,
    error: `Erro ao carregar dados de cancelamento`,
    diversity: `Relação de Diversidade Encontrada`,
    'issues-terminating': `0 | Problemas encerrando 1 Serviço. | Problemas encerrando {count} Serviços.`,
    terminating: `0 | Encerrando 1 Serviço. | Encerrando {count} Serviços.`,
    terminated: `0 | 1 Serviço Encerrado. | {count} Serviços Encerrados.`,
    pending: `Encerramento Pendente`,
    'do-not-restore': `Não, Não Restaurar Serviço`,
    restore: `Sim, Restaurar Serviço`,
    restoring: `Restaurando Serviço...`,
    'cancel-termination-confirmation': `Este serviço teve seu encerramento agendado. Você gostaria de cancelar essa solicitação de encerramento e restaurar o serviço?`,
    restored: `Serviço de encerramento pendente restaurado.`,
    'error-uncancelling': `Erro ocorreu ao descancelar o serviço.`,
  },
  canada: {
    ab: `Alberta`,
    bc: `British Columbia`,
    mb: `Manitoba`,
    nb: `New Brunswick`,
    nl: `Newfoundland and Labrador`,
    nt: `Northwest Territories`,
    ns: `Nova Scotia`,
    nu: `Nunavut`,
    on: `Ontario`,
    pe: `Prince Edward Island`,
    qc: `Quebec`,
    sk: `Saskatchewan`,
    yt: `Yukon`,
  },
  company: {
    'trading-name': `Nome Comercial`,
    'view-markets': `Ver Mercados da Empresa`,
    'company-trading-name': `Nome Comercial da Empresa`,
    'company-legal-name': `Razão Social da Empresa`,
  },
  alibaba: {
    'connection-details': `Detalhes de conexão para o Serviço Alibaba`,
    'account-id': `ID da Conta da Alibaba Cloud`,
    'account-tooltip': `Obtenha isso da sua conta da Alibaba`,
    connection: `Conexão da Alibaba`,
    'connection-id': `ID da Conexão da Alibaba`,
  },
  amsix: {
    'connection-details': `Detalhes da conexão para o Serviço de AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    peer: `Fazer Peering com Servidores de Rota?`,
    selective: `Seletivo`,
    'noc-contact': `Contato NOC`,
    'url-tooltip': `Serviços de AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX} exigem uma URL web para divulgar informações da empresa no ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'email-tooltip': `Serviços de AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX} exigem um email para divulgar informações da empresa no ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'select-country': `Escolher País`,
    'admin-phone-tooltip': `Um número de telefone pode ser adicionado por um admin da empresa na tela de Gerenciar Usuários, ou pelo próprio usuário ao seu perfil de usuário.`,
    'phone-tooltip': `Você pode atualizar o seu número de telefone no seu perfil de usuário; se não, entre em contato com o admin da sua empresa.`,
  },
  aws: {
    'connection-details': `Detalhes da Conexão para o Serviço AWS`,
    'edit-warning': `Editar os detalhes da AWS neste serviço inicia uma reconfiguração e exige  aceitação como um novo pedido de conexão VIF por meio do console AWS da conta receptora. Confirme antes de fazer mudanças.`,
    'warning-button': `Eu Entendo`,
    'start-editing': `Clique no botão acima para começar a editar`,
    'no-edit-hosted': `Aviso: Detalhes da AWS da conexão hospedada não são editáveis.`,
    'connection-name': `Nome da Conexão AWS`,
    'connection-name-tooltip': `Rotule o serviço na AWS`,
    'account-id': `ID da Conta AWS`,
    'account-id-tooltip': `Obtenha isso das suas configurações de conta AWS no console AWS`,
    'bgp-asn': `ASN BGP Público ou Privado`,
    'amazon-asn': `ASN da Amazon`,
    'default-amazon-asn': `Deixe em branco para usar o padrão`,
    'bgp-key': `Chave de Autorização BGP`,
    'bgp-key-tooltip': `Insira uma Chave de Autorização BGP / Senha (6-24 caracteres, sem espaços em branco). Caso você não providencie isso, geraremos uma para você`,
    'amazon-prefixes': `Prefixos da Amazon`,
    'amazon-prefixes-tooltip': `Prefixos para anunciar à AWS, somente intervalos de endereços IPv4 atribuídos por RIR`,
    'connection-type': `Tipo de Conexão AWS`,
    'hosted-vif': `VIF Hospedada`,
    'hosted-vif-description': `VIFs Hospedadas (Interfaces Virtuais Hospedadas) podem se conectar a serviços de nuvem públicos ou privados da AWS.`,
    'hosted-connection': `Conexão Hospedada`,
    'documentation-html': `Para mais informações sobre opções de conexão da AWS clique aqui: {link}`,
    'diversity-html': `Para mais detalhes, veja o {link}`,
    'vif-connection': `Conexão VIF AWS`,
    'vif-id': `ID da VIF`,
    label: `Label da AWS`,
    'customer-address': `Endereço do Cliente`,
    'amazon-address': `Endereço da Amazon`,
    'aws-hosted-connection': `Conexão Hospedada AWS`,
    'aws-vif': `VIF da AWS`,
    'connection-id': `ID da Conexão da AWS`,
    'manual-intervention': `Aviso: VIFs públicos exigem intervenção manual da Amazon e podem levar até 48 horas.`,
    'hosted-connection-description': `Cada ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} de Conexão Hospedada suporta uma única VIF AWS para um gateway público, privado, ou de trânsito.`,
    'key-features': `Principais recursos incluem:`,
    'aws-vif-feature-list-1': `A taxa de porta da AWS está inclusa na conexão da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'aws-vif-feature-list-2': `Largura de banda compartilhada dos clientes gerenciada e monitorada.`,
    'aws-vif-feature-list-3': `Capacidade de mudar a velocidade do ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'aws-vif-feature-list-4': `Configurável em incrementos de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} de 1Mbps entre 1 Mbps - 5 Gbps.`,
    'aws-vif-feature-list-5': `Configuração automática da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG} anexa.`,
    'aws-hosted-feature-list-1': `Taxa de ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} da AWS é faturada pela Amazon.`,
    'aws-hosted-feature-list-2': `Largura de banda dedicada.`,
    'aws-hosted-feature-list-3': `Definir incrementos de velocidade do ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} de 50 Mbps a 10 Gbps.`,
    'aws-hosted-feature-list-4': `Sem mudanças de velocidade no ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}.`,
    'aws-hosted-feature-list-5': `Suporte a diversas ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} para mais resiliência. O código de cor destaca as ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} diversas.`,
    'documentation-link': `Visão Geral da Conexão AWS - Documentação da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'readonly-warning': `Editar os detalhes da AWS neste serviço inicia uma reconfiguração e exige aceitação como novo pedido de conexão VIF pelo console da AWS da conta receptora.`,
    onboarding: `Onboarding da AWS`,
    'onboarding-reachout': `Um membro da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} entrará em contato para lhe ajudar com o onboarding nos próximos 1-2 dias úteis.`,
    'onboarding-questions': `Se você tiver quaisquer dúvidas neste meio tempo, pode nos alcançar em {email}`,
    'onboarding-info': `Por favor envie o máximo possível de informações, incluindo a oferta ou ID do acordo da AWS.`,
    'onboarding-success-title': `Onboarding da AWS com Sucesso`,
    'onboarding-success': `Obrigado por enviar. Uma pessoa da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} entrará em contato para lhe ajudar com onboarding nos próximos 1-2 dias úteis.`,
  },
  azure: {
    'primary-subnet': `Sub-rede Primária`,
    'primary-subnet-placeholder': `Sub-rede Primária, por exemplo 192.0.1.32/30`,
    'secondary-subnet': `Sub-rede Secundária`,
    'secondary-subnet-placeholder': `Sub-rede Secundária, por exemplo 192.0.1.32/30`,
    'public-prefixes': `Prefixos Públicos`,
    'public-prefixes-placeholder': `Prefixos Públicos, por exemplo 192.0.2.0/24,192.0.2.0/30`,
    'shared-key': `Chave Compartilhada`,
    'update-peer': `Atualizar  Peer`,
    'editing-message': `Os detalhes de conexão deste serviço precisam ser editados pela Azure.`,
    'no-peers': `Nenhum Peer Definido`,
    'peer-type': `Tipo de Peer`,
    'disable-peering-type': `Tem certeza de que deseja desabilitar este tipo de peering?`,
    'disable-peer-type': `Desabilitar Tipo de Peer`,
    configuration: `Configuração da Azure`,
    'express-route-html': `A ${process.env.VUE_APP_EN_COMPANYINFO_NAME} entrega serviços ExpressRoute a ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} primárias ou secundárias da Microsoft como “Q in Q” (double tagged, ou 802.1ad). Certifique-se de estar familiarizado com {link} no site da Microsoft.`,
    'peering-html': `Tipos de peering  Público, Privado, e Microsoft estão disponíveis neste método usando uma única chave de serviço ExpressRoute. Veja em {link} mais informações sobre esses tipos de peering e disponibilidade.`,
    'get-service-key': `Como conseguir uma chave de serviço ExpressRoute da Azure.`,
    'azure-service-key': `Chave de Serviço da Microsoft Azure`,
    'peering-type': `Tipo de Peering`,
    'peering-type-explanation': `O Tipo de Peering já foi definido para esta chave de serviço em outro dispositivo.`,
    microsoft: `Microsoft`,
    'back-to-service-key': `Volte a Chave de Serviço para inserir sua chave e criar a conexão.`,
    interface: `Interface Azure`,
    'q-in-q': `Definições de interface Q-in-Q`,
    'ms-doc': `Documentação da Microsoft`,
  },
  google: {
    'cloud-configuration': `Configuração do Google Cloud`,
    'gci-configuration': `Configuração do GCI`,
    'partner-peering-key': `Chave de Pareamento de Google Partner`,
    'show-all-locations': `Ver todos os locais do Google Partner`,
    'back-to-enter-peering': `Voltar a inserir Chave de Pareamento`,
    interface: `Interface do GCI`,
    connection: `Conexão do Google`,
  },
  ix: {
    'ecix-asn-fixed': `Aviso: O ASN não pode ser modificado para serviços ECIX após o lançamento.`,
    'bgp-password-tooltip': `Digite uma Senha BGP se quiser definir uma.`,
    'bgp-password-warning': `Aviso: a senha BGP não pode ser modificada após o lançamento.`,
    'looking-glass-graphs': `Os gráficos de uso ficarão visíveis no Looking Glass.`,
    'no-looking-glass-graphs': `Os gráficos de uso não ficarão visíveis no Looking Glass.`,
  },
  'mcr-config': {
    'enable-qinq': `Ativar  QinQ?`,
    invalid: `Inválido`,
    'add-vlan': `Adicionar VLAN`,
    'virtual-lan': `LAN Virtual`,
    'unique-id': `Insira uma ID única para a VLAN`,
    'vlan-id': `ID da VLAN`,
    'delete-vlan': `Apagar VLAN`,
    'interface-ip-addresses': `Endereços de IP de Interface`,
    'ip-subnet': `Endereço de IP e máscara de sub-rede`,
    nat: `Tradução de Endereço de Rede (NAT)`,
    'nat-source': `Endereço de IP de NAT de origem`,
    'nat-source-tooltip': `Selecione um endereço IPv4 para ativar NAT de origem nesta interface. Todos os pacotes saindo desta interface terão seus endereços de origem reescritos para o endereço selecionado.`,
    'nat-enabled': `NAT Ativado`,
    'bgp-connections-description': `Relações de peering BGP para esta interface - máximo de cinco. Exige que um Endereço de IP de Interface seja criado.`,
    'bgp-add': `Adicionar Conexão BGP`,
    'bfd-settings': `Configurações de Detecção de Encaminhamento Bidirecional (BFD)`,
    'no-bfd-enabled': `Não há conexões BGP com BFD ativado`,
    'transmit-interval': `Intervalo de Transmissão`,
    'receive-interval': `Intervalo de Recepção`,
    multiplier: `Multiplicador`,
    'bgp-connection': `Conexão BGP`,
    'disable-qinq-message': `Desativar Q-in-Q removerá todas as configurações.`,
    confirm: `Tem certeza de que deseja continuar?`,
    'delete-vlan-message': `Isto apagará esta VLAN e todas as configurações dela. Continuar?`,
    'nat-is-enabled': `NAT está ativo`,
    'no-prefix-filter': `Sem Filtro de Prefixo`,
  },
  nutanix: {
    configuration: `Configuração da Nutanix`,
    'service-key': `Chave de Serviço da Nutanix`,
    'service-key-notice': `Você precisará de uma chave de serviço da Nutanix para se conectar a uma ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} da Nutanix. Se você não tem uma chave de serviço, visite {link} para obter uma.`,
    interface: `Interface da Nutanix`,
    'accept-speed-change': `Aviso: A configuração de velocidade na sua chave de serviço da Nutanix foi atualizada. Clique em Salvar para aceitar o novo limite de taxa.`,
    nutanix: `Nutanix`,
  },
  oracle: {
    configuration: `Configuração da Oracle`,
    'virtual-circuit-id': `ID do Circuito Virtual da Oracle`,
  },
  salesforce: {
    configuration: `Detalhes de conexão do serviço Salesforce`,
    approvals: `Aprovações de conexão do Salesforce podem levar até dois dias úteis dos EUA.`,
    'provide-password': `Insira uma Senha BGP. Se você não fornecer uma, nós geraremos uma para você.`,
    prefixes: `Prefixos`,
    'prefixes-tooltip': `Prefixos para anunciar para o Salesforce, somente intervalos de endereços IPv4 atribuídos por RIR.`,
    connection: `Conexão do Salesforce`,
    'permitted-prefixes': `Prefixos BGP Permitidos`,
  },
  'target-select': {
    'no-bio': `Nenhuma bio da empresa disponível.`,
    'marketplace-html': `Ver mais informações no {link}.`,
    'hide-info': `Ocultar painel de informações`,
    'show-info': `Mostrar informações sobre as opções`,
  },
  'target-service-key': {
    'destination-found': `Destino Encontrado`,
    'key-name': `Nome da Chave`,
    'max-speed': `Velocidade Máxima`,
  },
  events: {
    session: `Registro de Eventos da Sessão`,
    clear: `Limpar Todos os Eventos`,
    cleared: `O registro de eventos da sessão foi limpo.`,
  },
  graph: {
    private: `Os dados desta empresa são privados`,
    'load-error': `Falha ao carregar dados de gráfico`,
    'no-data': `Não há dados de gráfico disponíveis`,
    'in-mbps': `Mbps (Entrada)`,
    'out-mbps': `Mbps (Saída)`,
    'billing-month': `Mês de Cobrança:`,
    'a-end-not-owned': `Nenhuma informação de cobrança porque a terminação A desta conexão é de propriedade de outra empresa.`,
    'load-failed': `Falha ao carregar os dados necessários`,
    'no-billing-data': `Não há dados de cobrança disponíveis`,
    estimated: `Próxima Cobrança Estimada (excl. Impostos) para este serviço.`,
    calculated: `Cobrança (excl. Impostos) para este serviço.`,
    'too-many-changes': `Há mudanças de velocidade demais para o gráfico. Baixe o CSV para ver os dados.`,
    'configured-speed': `Velocidade Configurada`,
    'billing-details': `Detalhes de Cobrança`,
    'estimated-next': `Próxima cobrança estimada (excl. Impostos), assumindo que não haverá mudanças no serviço.`,
    speed: `Velocidade (Mbps)`,
    download: `Baixar CSV`,
    'service-graph': `Gráfico de Serviço`,
    'date-range': `Período:`,
    'last-12-h': `Últimas 12 Horas`,
    'last-24-h': `Últimas 24 Horas`,
    'last-7-d': `Últimos 7 Dias`,
    'last-4-w': `Últimas 4 Semanas`,
    metrics: `Métricas:`,
    source: `Fonte:`,
    timezone: `Fuso horário:`,
    'nothing-selected': `Não há nada selecionado para mostrar no gráfico`,
    'date-time': `Data / Hora`,
    in: `Entrando`,
    out: `Saindo`,
    'last-hour': `N/A | Última Hora | Últimas {count} horas`,
    'last-week': `N/A | Última semana | Últimas {count} semanas`,
    'last-month': `N/A | Último mês | Últimos {count} meses`,
    // Tempo Universal Coordenado. Horário de Brasília é UST-03:00.
    utc: `UTC`,
    'export-csv': `Exportar CSV`,
    'downloading-csv': `Baixando CSV`,
  },
  'looking-glass': {
    'select-ix': `Escolher ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    title: `Looking Glass`,
    select: `Escolher Ponto de Troca de Internet (IX)`,
    filter: `Digite para filtrar por nome ou ASN`,
    'no-peers': `Não há peers disponíveis`,
    'loading-peers': `Carregando Lista de Peers...`,
    rs1: `RS1`,
    'route-server-1': `Servidor de Rota 1`,
    rs2: `RS2`,
    'route-server-2': `Servidor de Rota 2`,
    'extended-details': `Detalhes do Peer Extendido (Extended)`,
    routing: `Informação de Roteamento`,
    routes: `Rotas`,
    imported: `Importado`,
    best: `Melhor`,
    filtered: `Filtrado`,
    loading: `Carregando dados do gráfico...`,
    'loading-routes': `Carregando rotas...`,
    'route-load-failed': `Falha ao carregar dados da rota`,
    'no-routes': `Não há rotas disponíveis`,
    'local-pref': `Preferência Local`,
    'origin-asn': `ASN de Origem`,
    'asn-path': `Caminho ASN`,
    since: `Desde`,
    'route-data-load-failed': `Falha ao carregar os dados da rota`,
    'detail-data-load-failed': `Falha ao carregar os dados dos detalhes`,
    'ip-unavailable': `IPv4 e IPv6 indisponíveis.`,
    'protocol-unavailable': `{protocol} indisponível.`,
    'ip-available': `IPv4 e IPv6 estão disponíveis.`,
    asn: `ASN:`,
  },
  'image-upload': {
    select: `Escolher Imagem`,
    crop: `Cortar Imagem`,
    'drop-image': `Solte o arquivo de imagem aqui ou clique para fazer upload`,
    clear: `Limpar Imagem`,
  },
  'no-services': {
    message: `Interconectividade rápida e flexível a apenas alguns cliques.`,
    action: `Vamos te conectar`,
    instructions: `Para começar, crie uma ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} com os botões acima ou clique em “Começar Tour” para  uma rápida descrição de como fazer seu pedido.`,
  },
  'google-login': {
    unavailable: `Login com Google Indisponível`,
    loading: `Os serviços do Google ainda estão carregando - por favor tente novamente em breve.`,
    problem: `Houve um problema ao carregar os serviços de autorização do Google. Esse problema foi reportado aos nossos desenvolvedores. Por favor use a autenticação com nome de usuário e senha por enquanto.`,
    cancelled: `Login do Google Cancelado`,
    'cancelled-message': `A tentativa de login do Google foi cancelada por você.`,
    blocked: `Login do Google Bloqueado`,
    'blocked-message': `O login do Google exige uma janela popup que está sendo bloqueada pelo seu navegador. Por favor permita popups neste site.`,
    'cookies-blocked-message': `Seu navegador está configurado para bloquear cookies de terceiros. Para usar o login do Google, permita cookies de terceiros, e então recarregue a página.`,
    'browser-blocked-message': `O login do Google não pode ser executado no seu navegador. Os detalhes são: {details}`,
    failed: `Falha no Login do Google`,
    'failed-message': `Falha ao entrar usando o Google.`,
    'token-received': `Token do Google recebido, tentando fazer login...`,
    'no-details': `Não há detalhes adicionais.`,
    'token-failed-message': `Houve um erro na verificação do seu token de login do Google. Por favor tente fazer login novamente, ou limpe os cookies do seu navegador se o problema continuar.`,
  },
  'whitelabel-dlr': {
    welcome: `Bem-vindo ao DLR`,
    'uptimes-blog': `Blog Uptimes`,
    twitter: `Siga a gente no Twitter`,
    linkedin: `Siga a gente no  Linkedin`,
    conditions: `Veja nossos termos e condições de privacidade`,
    contact: `Contato e suporte`,
    careers: `Carreiras - Estamos contratando`,
  },
  tooltips: {
    'route-prefix': `Um endereço de rede IPv4 ou IPv6 em notação CIDR.`,
    'route-next-hop': `O endereço de IP do roteador do próximo hop.`,
    'required-item-start': `Itens marcados com um`,
    'required-item-end': `são campos obrigatórios.`,
    'local-ip-address': `O endereço IPv4 ou IPv6 dessa interface a ser usado para comunicação com o peer BGP.`,
    'peer-ip-address': `O endereço de IP do peer BGP.`,
    'peer-asn': `O ASN do peer BGP remoto. Números AS de 4 bytes são suportados no formato asplain.`,
    'bgp-password': `Uma chave compartilhada usada para autenticar o peer BGP, até 25 caracteres.`,
    'bgp-description': `Uma descrição da conexão BGP, até 100 caracteres.`,
    'bgp-state': `Por padrão, conexões BGP ficam ativas e tentarão se conectar ativamente ao peer. Escolha desligar para temporariamente desativar a sessão BGP sem removê-la. Isso pode ser útil para resolver problemas ou testar situações de failover.`,
    'med-inbound': `O MED será aplicado a todas as rotas recebidas nesta conexão BGP. Deixe em branco para usar o valor recebido do peer BGP. A rota com o menor valor será preferida.`,
    'med-outbound': `O MED será aplicado a todas as rotas recebidas nesta conexão BGP. O sistema autônomo vizinho pode preferir o menor valor caso queira.`,
    bfd: `Detecção de Encaminhamento Bidirecional (BFD) permite detectar falhas rapidamente nesta conexão BGP. Quando a conectividade é interrompida, a sessão BGP é desmontada sem esperar o timer de hold do BGP expirar. Usar o BFD pode melhorar o tempo de failover quando múltiplas rotas estiverem disponíveis. Aviso: Estas configurações serão usadas para todas as conexões BGP neste VXC onde o BFD estiver ativado.`,
    'bfd-group': `Aviso: Estas configurações serão usadas para todas as conexões BGP neste VXC onde o BFD estiver ativado.`,
    'bfd-transmit': `O tempo mínimo entre enviar pacotes BFD ao vizinho. A faixa suportada é de 300ms a 9000ms.`,
    'bfd-receive': `O tempo mínimo entre pacotes BFD que um vizinho deve enviar. A faixa suportada é de 300ms a 9000ms.`,
    'bfd-multiplier': `A sessão BGP será desmontada se esse número de pacotes BFD em sequência não forem recebidos do vizinho.`,
    med: `O discriminador multi-saídas (MED) pode ser usado para influenciar preferências de rota quando múltiplas rotas estiverem disponíveis para o mesmo destino. É usado comumente para dar preferência a caminhos primários e secundários específicos. Valores entre 0 e 4,294,967,295 são suportados.`,
    'bgp-config-description': `Rotas recebidas deste peer BGP serão divulgadas a outros peers BGP nesta ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} <br /> Você pode divulgar rotas a todos os peers por padrão e filtrar rotas para peers específicos. Ou você pode filtrar rotas para todos os peers por padrão e anunciar rotas para peers específicos.`,
    'bgp-prefix-filter': `<p>Aplique um filtro de prefixos predefinido a este peer BGP para limitar o conjunto de rotas que será recebido dele, ou que será anunciado a ele.</p>
      <ul>
      <li>Nenhum Filtro de Prefixo permite todas as rotas. Nenhuma rota será filtrada.</li>
      <li>Lista de Permissão permite apenas rotas que correspondam à lista de prefixos. Outras rotas serão filtradas.</li>
      <li>Lista de Negação permite todas as rotas exceto aquelas que correspondam a lista de prefixos selecionada.</li>
      </ul>
      <p>Para definir listas de filtro de prefixo, escolha a aba Filtro de Prefixos na página de Configuração da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.</p>
      `,
    'bgp-prefix-editor': `
              <p>
              A Lista de Prefixos da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} é uma lista nomeada de endereços de IP de rede. Crie uma lista de filtro de prefixos para corresponder e filtrar rotas com base em seus prefixos, e aplique o filtro a
              rotas recebidas por (ou anunciadas a) peers BGP nesta ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.
              A ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} compara cada prefixo de rota com as condições na lista, uma correspondência por vez, de cima para baixo, para negar ou divulgar a rota.
              </p>
              <ul>
              <li>Uma lista de prefixos pode ser aplicada múltiplas vezes a diferentes peers BGP.</li>
              <li>Até 50 listas de prefixos podem ser configuradas em cada ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}.</li>
              <li>Cada lista pode conter até 200 entradas.</li>
              </ul>
         `,
    'company-trading-name': `O nome da sua conta, empresa ou organização. Você pode alterar isto depois.`,
    'as-path-prepend-tooltip': `O ASN local será anexado ao caminho AS dos anúncios de rota BGP de saída. Insira um número de 0 até o máximo de 10 prefixos.`,
    'shutdown-state': `Atualize o estado configurado para Desativado para testar como o serviço se comportaria na rede da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} se o serviço fosse desativado.`,
    'move-connection-limits': `Apenas portas do mesmo tipo de produto e dentro da mesma Metro estão disponíveis para seleção`,
    'move-connection-vnic': `A designação da terminação A ou terminação B do ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} foi determinada quando o ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} foi criado.`,
  },
  time: {
    min: `min`,
    s: `s`,
    ms: `ms`,
    µs: `µs`,
  },
  'page-titles': {
    login: `Login`,
    'user-profile': `Perfil de Usuário`,
    'user-notifications': `Notificações de Usuário`,
    services: `Serviços`,
    dashboard: `Painel`,
    'create-port': `Criar ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    mve: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'create-connection': `Criar Conexão`,
    'edit-port': `Editar ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'edit-connection': `Editar Conexão`,
    'company-profile': `Perfil da Empresa`,
    'company-users': `Usuários da Empresa`,
    'email-confirmation': `Confirmação de Email`,
    'marketplace-profile': `Perfil no ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    debug: `Informações de Depuração`,
    'looking-glass': `Looking Glass`,
    'mcr-looking-glass': `Looking Glass da ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'event-log': `Registro de Eventos da Sessão`,
    'ix-flows': `Fluxos da ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'api-keys': `Chaves API`,
    'partner-admin': `Admin Parceiro`,
    'managed-account': `Conta Gerenciada`,
    'billing-markets': `Mercados de Cobrança`,
    onboard: `Iniciar`,
    signup: `Cadastro`,
    'signup-google': `Cadastro do Google`,
    admin: `Admin`,
    'logging-in': `Entrando`,
    'company-security-settings': `Configurações de Segurança da Empresa`,
    'company-support': `Detalhes do Contato de Suporte Técnico`,
    'setup-mfa': `Configuração da Autenticação Multi-Fatores (MFA)`,
    mfa: `Autenticação Multi-Fatores (MFA)`,
    'create-mcr': `Criar ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'edit-mcr': `Editar ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    'create-mve': `Criar ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'edit-mve': `Editar ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'move-connections': `Mover Conexões`,
    'aws-entitlements': `Direitos da AWS`,
    'aws-onboarding': `Iniciação da AWS`,
  },
  partner: {
    'learn-more': `Saiba mais sobre Administração de Contas Gerenciadas na nossa Documentação de Parceiros`,
    'view-documentation': `Ver Documentação`,
    'managed-accounts': `Contas Gerenciadas`,
    'create-managed-account': `Criar Conta Gerenciada`,
    'view-services': `Ver Serviços`,
    edit: `Editar`,
    filter: `Filtrar Contas`,
    'no-managed-accounts': `Não há contas gerenciadas. Clique no botão ‘Criar Conta Gerenciada’ para criar a sua primeira conta.`,
    'invalid-company-uid': `Não há conta gerenciada com a UID correspondente.`,
    'no-match': `Nenhuma Conta Correspondente.`,
    'account-name': `Nome da Conta`,
    'company-uid': `UID da Empresa`,
    'managed-account-ref': `UID da Conta Gerenciada`,
    'managed-account-ref-placeholder': `Referência Única da Empresa`,
    actions: `Ações`,
    'managed-account': `Conta Gerenciada`,
    'managed-learn-more': `Saiba mais sobre a Criação de Contas Gerenciadas na nossa Documentação de Parceiros`,
    save: `Salvar`,
    create: `Criar`,
    cancel: `Cancelar`,
    'account-created': `Conta gerenciada criada.`,
    'account-saved': `Conta gerenciada salva.`,
    'create-account-error': `Erro ao criar conta gerenciada.`,
    'update-account-error': `Erro ao atualizar conta gerenciada.`,
    error: `Erro`,
    'error-retrieving-accounts': `Não foi possível recuperar as contas gerenciadas.`,
    'managed-accounts-info': `Ver uma lista de contas, definir permissões, e gerenciar serviços.`,
    'edit-users': `Editar Usuários`,
    'edit-users-text': `Ver e gerenciar uma lista completa de usuários e suas permissões para esta empresa.`,
    'new-managed': `Nova Conta Gerenciada`,
    'view-services-text': `Criar, ver e fazer pedidos de novos serviços para esta empresa.`,
    'manage-services': `Gerenciar Serviços`,
    'diversity-unavailable-location': `Diversidade não está disponível atualmente para esta configuração. Entre em contato com seu Parceiro da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} para falar sobre suas exigências de diversidade.`,
  },
  'partner-admin': {
    'partner-account': `Conta de Parceiro`,
    'managed-account': `Conta Gerenciada | Contas Gerenciadas`,
    'managed-accounts-uid': `UID da Conta Gerenciada`,
    'select-account': `Escolher Conta`,
    'account-filter': `Filtrar contas por nome ou referência`,
    partner: `Parceiro`,
    'viewing-as': `Visualizando Como:`,
  },
  'partner-vantage': {
    subscription: `Assinatura`,
    'subscription-tooltip-ports': `A assinatura começará quando o serviço ficar ativo ou 14 dias após a configuração do serviço`,
    'subscription-tooltip': `A assinatura começará ao realizar o pedido`,
    'no-subscription': `Sem Assinatura`,
    'change-subscription': `Mudar Assinatura`,
    'subscription-title': `Definir Assinatura do {productType}`,
    'subscription-choose': `Escolher Assinatura do {productType}`,
    'confirm-new-subscription': `Iniciar Nova Assinatura`,
    'subscription-months': `Sem Assinatura | Assinatura: 1 Mês | Assinatura: {count} Meses`,
    'subscription-reterm': `O prazo para este pedido (o Prazo Mínimo) entra em efeito imediatamente e o desconto a prazo será aplicado à fatura do próximo mês. Encerramento prévio do contrato gerará taxas de encerramento prévio segundo o acordo entre nós.`,
    'cancel-subscription': `Clique em Cancelar se não quiser tomar nenhuma ação e passar para uma conta sem assinatura pelo preço de tabela ao fim da assinatura atual.`,
    'subscription-term': `Prazo da Assinatura`,
  },
  outscale: {
    connection: `Conexão Outscale`,
    'connection-details': `Detalhes da Conexão para o serviço Outscale`,
    'connection-name': `Nome da Conexão Outscale`,
    'connection-name-tooltip': `Rotular o serviço no Outscale`,
    'account-id': `ID da Conta do Outscale`,
    'account-id-tooltip': `Insira o ID da conta proprietária do Outscale`,
  },
  'company-security-settings': {
    'security-settings': `Configurações de Segurança`,
    'mfa-long': `Autenticação Multi-Fatores (MFA)`,
    'info-enforced': `Uma vez exigida, todos os usuários da sua empresa serão solicitados a configurar a MFA durante o login.`,
    'info-optional': `Uma vez tornada opcional, todos os usuários da sua empresa ainda poderão ativar a MFA em seus perfis de usuário.`,
    'mfa-now-enforced': `Autenticação Multi-Fatores (MFA) agora é exigida`,
    'mfa-now-optional': `Autenticação Multi-Fatores (MFA) agora é opcional`,
    'enforce-mfa-all-users': `Exigir Autenticação Multi-Fatores (MFA) para todos os usuários na sua empresa`,
    'error-updating-mfa': `Erro ao atualizar configurações de MFA`,
    'enforce-mfa-tooltip-on': `Se a MFA for exigida (LIGADO), todos os usuários deverão usar MFA ao entrar em suas contas. Se um usuário não tem MFA ativada atualmente, eles serão solicitados a configurá-la durante seu próximo login. Usuários não poderão acessar a sua empresa na ${process.env.VUE_APP_EN_COMPANYINFO_NAME} até terem configurado a MFA.`,
    'enforce-mfa-tooltip-off': `Se a MFA for opcional (DESLIGADO), usuários poderão escolher se desejam ou não ativar MFA em suas contas.`,
    'enforce-mfa-tooltip-title': `Exigindo MFA`,
    mfa: `MFA`,
    on: `LIGADO`,
    off: `DESLIGADO`,
    sso: `SSO`,
    'sso-long': `Single Sign-On`,
    'provider-identity': `Provedor de Identidade`,
    type: `Tipo`,
    'identity-provider-info': `Informação do provedor de identidade`,
    'provider-name': `Nome do Provedor`,
    domains: `Domínios`,
    metadata: `URL de Metadados`,
    'integration-details': `Detalhes de Integração`,
    'audience-uri': `Audience URI`,
    'idp-response-url': `URL de resposta do IdP`,
    'sso-fail': `Falha ao recuperar configurações de SSO.`,
    'sso-no-idps': `Nenhum provedor de identidade configurado.`,
    'sso-attribute-mapping': `Mapeamento de atributos`,
    'sso-user-pool-attribute': `Atributo do grupo de usuários`,
    'sso-saml-attribute': `Atributo SAML`,
    'integration-details-desc': `Para conectar um provedor SAML 2.0 à ${process.env.VUE_APP_EN_COMPANYINFO_NAME}, você precisará fornecer o Audience URI e a URL de Resposta do IDP ao seu provedor de identidade`,
    'details-for': `Detalhes de {thing}`,
    'accepts-csv-values': `Aceita uma lista de valores separados por vírgula`,
    'enforce-sso-all-users': `Exigir Single Sign-On (SSO) para todos os usuários na sua empresa`,
    'enforce-sso-tooltip-title': `Exigindo SSO`,
    'enforce-sso-tooltip-on': `Se a exigência de SSO estiver LIGADA, todos os usuários deverão se autenticar usando o IdP configurado por padrão. Qualquer usuário não associado ao grupo de usuários do IdP terá o acesso bloqueado. (Exceção: Admins da Empresa ainda poderão fazer login com outros métodos, incluindo Admins da Empresa que não tiverem um domínio associado de SSO.)`,
    'enforce-sso-tooltip-off': `Se a exigência de SSO estiver DESLIGADA, usuários poderão usar métodos diferentes de autenticação, incluindo nome de usuário e senha, Google ou SSO.`,
    'enforce-sso-info-enforced': `Uma vez que a exigência de SSO estiver LIGADA, todos os usuários deverão se autenticar usando o IdP configurado por padrão. Qualquer usuário não associado ao grupo de usuários do IdP terá o acesso bloqueado. (Exceção: Admins da Empresa ainda poderão fazer login com outros métodos, incluindo Admins da Empresa que não tiverem um domínio associado de SSO.)`,
    'enforce-sso-info-optional': `Uma vez que a exigência de SSO estiver DESLIGADA, usuários poderão usar métodos diferentes de autenticação, incluindo nome de usuário e senha, Google ou SSO. Todos os usuários ativos na sua empresa poderão fazer login novamente.`,
    'enforce-sso': `Exigir SSO`,
    'sso-connections': `Conexões SSO`,
    'error-fetching-sso': `Erro inesperado ao buscar configuração SSO`,
    'error-updating-sso': `Erro inesperado ao atualizar configuração SSO`,
    'configure-saml-idp': `Configurar um provedor de identidade (IdP) SAML 2.0`,
    'configure-saml-description': `Você pode configurar sua conta da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} para usar autenticação Single Sign-On (SSO). Quando você ativa o SSO, usuários são redirecionados a um provedor de identidade (IdP) externo para se autenticar, em vez de serem solicitados a inserir uma senha para acessar o ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}. O SSO simplifica a experiência de login e elimina a necessidade de que usuários se lembrem de diversas credenciais de login, tornando-o mais simples para os usuários e mais fácil de gerenciar para os gestores.`,
    'add-saml-connection': `Adicionar Conexão SAML`,
    'register-saml-provider': `Cadastrar a ${process.env.VUE_APP_EN_COMPANYINFO_NAME} com o seu provedor SAML`,
    'register-saml-provider-description': `Configurar o seu provedor de identidade SAML com a ${process.env.VUE_APP_EN_COMPANYINFO_NAME} providenciando os seguintes detalhes (incluindo a URL de metadados usada pelo seu provedor de identidade)`,
    'sso-update-success': `Definições de SSO atualizadas para {name}`,
    'maximum-domains-error': `Número máximo de domínios é {max}`,
    'invalid-domains': `Domínios inválidos encontrados {domains}`,
    'duplicate-domains': `Domínios duplicados encontrados {domains}`,
    'idp-delete-confirmation-title': `Apagar Provedor`,
    'idp-delete-confirmation-message': `<strong>Ao seguir adiante com essa ação você reconhece que:</strong><ul><li>Todos os usuários associados a esta conexção não poderão mais fazer login usando o seu IdP externo.</li><li>Se a exigência de SSO estiver ativa, ela se tornará inativa.</li><li>Todos os usuários ativos da sua empresa passarão a poder fazer login com outros métodos de autenticação.</li></ul>`,
    'sso-now-enforced': `SSO agora é exigido`,
    'sso-now-optional': `SSO agora é opcional`,
    'new-user-non-sso-domain-warning': `Por favor esteja ciente de que o Single Sign-On (SSO) atualmente é exigido para a sua conta. Embora você possa convidar qualquer usuário para a sua conta, usuários convidados não poderão usar suas credenciais sem estar associados a um domínio de SSO. Como exceção, se o usuário for um Admin da Empresa, ele poderá acessar sua conta mesmo que não esteja associado a um domínio de SSO.`,
    'sso-enforced-inactive-warning': `Por favor esteja ciente de que o Single Sign-On (SSO) atualmente é exigido para a sua conta. Se você estiver reativando uma conta de usuário que não está associada a um domínio de SSO, o usuário ainda ficará bloqueado e incapaz de fazer login. Como exceção, se o usuário for um Admin da Empresa, ele poderá acessar sua conta mesmo que não esteja  associado a um domínio de SSO.`,
    'sso-new-docs-message': `Antes de adicionar SSO a esta conta por favor revise a página de {docs}. Isso oferecerá informações importantes sobre a configuração e em torno do Single Sign-On.`,
    'sso-features-and-instructions-for-use': `Recursos e Instruções de Uso do SSO`,
    'sso-add-settings-for-account': `Você adicionou definições de SSO para esta conta.`,
    'sso-ensure-config-complete': `Para garantir que a sua configuração está completa, você agora precisará integrar seu IdP com o Audience URI e as URL de Resposta abaixo. Eles são componentes essenciais que são exigidos para que autenticação SAML funcione corretamente:`,
    'sso-info-accessed-again-at-any-time': `Esta informação pode ser acessada de novo a qualquer momento clicando no botão Editar ao lado da sua Conexão SSO.`,
    'can-also-be-known-as': `Também pode ser conhecido como:`,
    'entity-id': `ID da Entidade`,
    audience: `Audiência`,
    'assertion-consumer-service': `URL de Serviço de Consumidor de Asserção (ACS)`,
    'single-sign-on-url': `URL de Single Sign-On`,
    'application-callback-url': `URL de Callback da Aplicação`,
    'reply-url': `URL de Resposta (Reply)`,
  },
  tscd: {
    'tech-supp-phone': `Telefone de suporte técnico`,
    'tech-supp-phone-num': `Número de Telefone da Equipe de Suporte Técnico`,
    'tech-supp-phone-placeholder': `O número de telefone da equipe interna de suporte do cliente`,
    'tech-supp-email': `Email da Equipe de Suporte Técnico`,
    'tech-supp-email-placeholder': `O endereço de email da equipe de suporte interna do cliente`,
  },
  'company-support': {
    'tech-supp-phone': `Número de Telefone da Equipe de Suporte Técnico`,
    'tech-supp-phone-placeholder': `Número de telefone de contato da sua equipe interna de suporte`,
    'tech-supp-email': `Email da Equipe de Suporte Técnico`,
    'tech-supp-email-placeholder': `Email de contato da sua equipe interna de suporte`,
    'admin-warning': `Usuários Admins da Empresa podem inserir e editar detalhes da equipe de suporte técnico`,
    'customer-supp-heading': `Contatos do Cliente`,
    'megaport-supp-heading': `Contatos da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'account-manager': `Gerente da Conta`,
    'customer-success-manager': `Gerente de Sucesso do Cliente`,
    'click-chat-icon-msg': `Clique no ícone de bate-papo para iniciar uma conversa`,
  },
  images: {
    'datacentre-logo': `Logo do Data Center`,
    'megaport-logo': `Logo da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'megaport-cloud-providers-logo': `Provedores de nuvem da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'loading-logo': `Logo de carregamento da ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'role-icon': `Ícone de função/papel`,
    'docs-icon': `Ícone de Documentação`,
    'company-logo': `Logo da empresa`,
    'resize-handle-icon': `Ícone de redimensionar a alça`,
    'marketplace-logo': `Logo do ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'marketplace-profile-logo': `Logo do perfil do ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'provider-logo': `Logo do provedor`,
    'copy-icon': `Ícone de copiar valor`,
    'partner-vantage-logo': `Logo da Partner Vantage`,
    'profile-logo': `Logo de Perfil`,
    'sx-logo': `Logo de troca de serviço (service exchange)`,
  },
  ibm: {
    connection: `Conexão IBM`,
    'connection-details': `Detalhes de conexão para o IBM Cloud Service`,
    'account-id': `ID da Conta da IBM`,
    'account-id-tooltip': `Obtenha isso da sua configuração da conta no portal da IBM`,
    'customer-asn-tooltip': `ASN de BGP público ou privado`,
    'account-id-validation': `Número hexadecimal de 32 dígitos`,
    'modal-title': `Conexão do Portal IBM`,
    'order-request-sent': `A solicitação foi enviada à sua conta do portal IBM.`,
    'order-request-reminder': `Este é um lembrete para aceitar/rejeitar a solicitação de conexão por meio do portal da IBM.`,
    'visit-portal': `Visitar Portal da IBM`,
    'edit-modal-updated-api': `A ${process.env.VUE_APP_EN_COMPANYINFO_NAME} atualizou integrações de API com a IBM.`,
    'edit-modal-recommendation': `Recomendamos que usuários refaçam o pedido de ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} IBM Cloud para acessar recursos atualizados e encerrar a conexão existente.`,
    'edit-modal-docs': `Para mais informação - ver o {link}.`,
  },
  'mexico-billing-market': {
    'company-type-mexico-01-legal-entity': `01: Entidade Legal`,
    'company-type-mexico-02-legal-person': `02: Pessoa Legal`,
    'company-type-mexico-03-foreign-company': `03: Empresa Estrangeira`,
    'tax-regime-code-mexico-601': `601 - Geral de Pessoas Morais`,
    'tax-regime-code-mexico-603': `603 - Entidades Legais Sem Fins Lucrativos`,
    'tax-regime-code-mexico-605': `605 - Salários e Vencimentos e Rendimentos Similares a Salários`,
    'tax-regime-code-mexico-606': `606 - Aluguel`,
    'tax-regime-code-mexico-607': `607 - regime de Alienação ou Aquisição de Bens`,
    'tax-regime-code-mexico-608': `608 - Outra Renda`,
    'tax-regime-code-mexico-610': `610 - Residentes no Exterior Sem Estabelecimento Permanente no México`,
    'tax-regime-code-mexico-611': `611 - Renda de Dividendos (parceiros e acionistas)`,
    'tax-regime-code-mexico-612': `612 - Indivíduos com Negócios e Atividades Profissionais`,
    'tax-regime-code-mexico-614': `614 - Rendimentos de juros`,
    'tax-regime-code-mexico-615': `615 - Regime de receitas provenientes da obtenção de prêmios`,
    'tax-regime-code-mexico-616': `616 - Sem obrigações tributárias`,
    'tax-regime-code-mexico-620': `620 - Sociedades de Produção Cooperativa que optam por adiar seus rendimentos`,
    'tax-regime-code-mexico-621': `621 - Incorporação Fiscal`,
    'tax-regime-code-mexico-622': `622 - Atividades Agrícolas, Pecuárias, Florestais e de Pesca`,
    'tax-regime-code-mexico-623': `623 - Opcional Para Grupos de Empresas`,
    'tax-regime-code-mexico-624': `624 - Coordenado`,
    'tax-regime-code-mexico-625': `625 - Regime de Atividades de Negócio com renda por Plataformas Tecnológicas`,
    'tax-regime-code-mexico-626': `626 - Regime de Confiança Simplificado`,
    'sat-purpose-mexico-cn01': `CN01 - Folha de Pagamento`,
    'sat-purpose-mexico-cp01': `CP01 - Pagamentos`,
    'sat-purpose-mexico-d01': `D01 - Despesas médicas, dentais e hospitalares`,
    'sat-purpose-mexico-d02': `D02 - Despesas médicas relacionadas a incapacidade ou deficiência`,
    'sat-purpose-mexico-d03': `D03 - Despesas de funeral`,
    'sat-purpose-mexico-d04': `D04 - Doações`,
    'sat-purpose-mexico-d05': `D05 - Juros reais efetivamente pagos em créditos hipotecários (casa de habitação)`,
    'sat-purpose-mexico-d06': `D06 - Contribuições Voluntárias ao SAR`,
    'sat-purpose-mexico-d07': `D07 - Prêmios de seguro de despesas médicas`,
    'sat-purpose-mexico-d08': `D08 - Despesas compulsórias de transporte escolar`,
    'sat-purpose-mexico-d09': `D09 - Depósitos em contas de poupança, prêmios baseados em planos de pensão`,
    'sat-purpose-mexico-d10': `D10 - Pagamentos de serviços educacionais (taxas escolares)`,
    'sat-purpose-mexico-g01': `G01 - Aquisição de mercadoria`,
    'sat-purpose-mexico-g02': `G02 - Devoluções, descontos ou bônus`,
    'sat-purpose-mexico-g03': `G03 - Despesas em geral`,
    'sat-purpose-mexico-i01': `I01 - Edifícios`,
    'sat-purpose-mexico-i02': `I02 - Mobiliário de escritório e equipamento para investimentos`,
    'sat-purpose-mexico-i03': `I03 - Equipamento de transporte`,
    'sat-purpose-mexico-i04': `I04 - Equipamento de computadores e acessórios`,
    'sat-purpose-mexico-i05': `I05 - Matrizes, moldes e ferramentas`,
    'sat-purpose-mexico-i06': `I06 - Comunicações por telefone`,
    'sat-purpose-mexico-i07': `I07 - Comunicações por satélite`,
    'sat-purpose-mexico-i08': `I08 - Outro maquinário e equipamento`,
    'sat-purpose-mexico-s01': `S01 - Sem efeitos fiscais (sem obrigações fiscais)`,
  },
  'survey-banner': {
    'customer-survey-link': `Adoraríamos receber o seu feedback! Clique aqui para completar a nossa pesquisa com clientes.`,
  },
  'mp-one': {
    'platform-orchestrate-network-services': `Plataforma ONE para Orquestrar, Redes e Ativação de Serviços de Nuvem.`,
    'try-megaport-one': `Experimente Megaport ONE`,
    'learn-more': `Saiba Mais`,
    'click-here-to-try-mp-one': `Clique aqui para testar a versão Beta da Megaport ONE. Uma plataforma para orquestrar, conectar e lançar soluções de nuvem.`,
    'terms-and-conditions': `Você está entrando no portal da MegaportONE.
      
      Ao entrar no Portal da MegaportONE, você concorda com os seguintes termos e condições.
      
      As informações e serviços fornecidos no Portal da Megaport ONE atualmente são disponibilizadas sem custo aos usuários.
      
      A Megaport se reserva o direito de introduzir taxas ou cobranças para determinados recursos ou serviços no futuro.
      
      Quaisquer taxas ou cobranças serão comunicadas claramente aos usuários com antecedência.
      
      Por favor note que o Portal da MegaportONE atualmente está em fase de testes beta e nem todos os recursos e serviços poderão estar disponíveis em todos os momentos.
      
      A Megaport se reserva o direito de modificar, suspender ou descontinuar qualquer parte do site ou sua funcionalidade sem aviso prévio.
      
      Não garantimos acesso ininterrupto ou sem erros ao Portal da MegaportONE.`,
  },
  'view-mode': {
    tooltip: `Ver seus serviços em visão Tabular ou de Mapa`,
    tabular: `Tabular`,
    map: `Mapa`,
  },
  map: {
    'all-locations': `Mostrar todos os locais`,
    'occupied-locations': `Mostrar apenas locais onde tenho serviços`,
    'filtered-locations': `Filtrar mostragem de locais onde não tenho serviços`,
    'thing-available': `{thing} Disponível`,
    'dc-provider': `Provedor de Data Center`,
    'locations-filtered': `Os locais mostrados estão filtrados`,
    'zoom-in': `Aproximar`,
    'zoom-in-lots': `Aproximar muito`,
    'zoom-out': `Afastar`,
    'zoom-out-lots': `Afastar muito`,
    settings: `Configuração`,
    key: `Chave de Mapa`,
    help: `Ajuda e Instruções`,
    'help-action': `Ação`,
    'help-description': `Descrição`,
    'help-items': {
      'zoom-in-title': `Dar mais zoom`,
      'zoom-in-action-1': `Duplo clique`,
      'zoom-in-action-2': `= tecla`,
      'zoom-in-action-3': `+ tecla`,
      'zoom-in-action-4': `Segure a tecla ⌘/CTRL e a roda do mouse`,
      'zoom-in-action-5': `Segure a tecla Alt/Option e arraste para baixo`,
      'zoom-in-action-6': `Juntar dois dedos`,
      'double-zoom-in-title': `Dar mais zoom no dobro da velocidade`,
      'double-zoom-in-action-1': `Shift = tecla`,
      'double-zoom-in-action-2': `Shift + tecla`,
      'zoom-out-title': `Dar menos zoom`,
      'zoom-out-action-1': `Shift clique duplo`,
      'zoom-out-action-2': `- tecla`,
      'zoom-out-action-3': `Segure a tecla ⌘/CTRL e Roda do mouse`,
      'zoom-out-action-4': `Segure a tecla Alt/Option e arraste para cima`,
      'zoom-out-action-5': `Juntar dois dedos`,
      'double-zoom-out-title': `Dar menos zoom no dobro da velocidade`,
      'double-zoom-out-action-1': `Shift - tecla`,
      'area-zoom-in-title': `Dar zoom em uma área`,
      'area-zoom-in-action-1': `Shift arrastar área`,
      'pan-title': `Girar o mapa`,
      'pan-action-1': `Arrastar`,
      'pan-action-2': `Teclas de seta`,
      'orientation-title': `Mudar a orientação do mapa`,
      'orientation-action-1': `Arrastar com dois dedos`,
      'orientation-action-2': `Shift ←, → para rodar, Shift ↑ para resetar`,
    },
    'ix-count': `0 IXs | 1 IX | {count} IXs`,
    'location-cluster': `Este cluster representa {locationString}.`,
    'location-ix-cluster': `Este cluster representa {locationString} e {ixString}.`,
    'click-expand': `Clique para expandir.`,
    'click-empty-location': `Clique para ver detalhes e adicionar serviços`,
    'supported-products': `Este local suporta os seguintes produtos:`,
    'products-location': `Você tem os seguintes produtos neste local:`,
    'click-details': `Clique para ver detalhes`,
    'line-connections-count': `- | Esta linha representa 1 conexão: | Esta linha representa {count} conexões`,
    'location-deployment': `Este local ficará ativo em breve. Você pode fazer o pedido de serviços desde já.`,
    'view-in-map': `Ver este serviço em um mapa`,
    'navigate-to': `Navegar para {serviceName}`,
    'search-tips': `Você pode buscar por:<ul><li>Qualquer tipo de produto, como VXC</li><li>Qualquer nome de serviço</li><li>ID de Produto</li><li>Locais ativos da Megaport (se você não tiver desativado isso nas configurações do seu mapa)</li><li>Ou qualquer outro lugar no mundo todo</li></ul>`,
    'key-items': {
      'occupied-location': `Um único local em que você tem serviço. Você pode clicar nestes pinos para ver os detalhes dos serviços que tem naquele local, e para pedir mais serviços lá.`,
      spiderified: `Quando você clica em um local onde tem serviços, ele se expandirá para mostrar todas as ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}, ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} e ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} que você tiver naquele local, bem como o local em si. Os ícones tem cores diferentes para indicar se os serviços estão ativos ou inativos. Portas de terceiros às quais você está conectado são representadas com ícones pretos. Clique em um ícone para ver os detalhes.`,
      'occupied-location-cluster': `Um cluster de locais em que você tem serviços. O anel em torno dele é colorido para indicar qual proporção de conexões iniciadas neste cluster estão em estado ativo ou inativo. Clique para expandir.`,
      'unoccupied-location': `Um local único no qual serviços da ${process.env.VUE_APP_EN_COMPANYINFO_NAME} estão disponíveis, mas no qual você atualmente não tem nenhum serviço. Clique para ver os detalhes do local e fazer pedidos de serviços lá. Você pode desativar a mostragem de locais desocupados nas configurações do mapa.`,
      'unoccupied-location-cluster': `Um cluster de locais onde você não tem serviços. Clique para expandir. Você pode desativar a mostragem de locais desocupados nas configurações do mapa.`,
      'ix-pin': `Um pino virtual representando uma ${process.env.VUE_APP_EN_PRODUCTNAME_IX} naquela Metro.`,
      'mid-connection': `Um marcador representando conexões entre dois locais, ou clusters de locais. Uma linha única pode representar múltiplas conexões. O marcador ficará verde se todas as conexões estiverem ativas, vermelho se todas estiverem inativas, ou meio a meio se apenas algumas estiverem ativas. Clique no marcador para ver todas as conexões representadas.`,
      connections: `Linhas entre locais representam conexões. A cor da linha indica se a conexão está ativa ou inativa. Neste caso há três conexões ativas e uma conexão inativa.`,
    },
    'partner-count': `- | Você tem 1 conexão a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de terceiros neste local. | Você tem {count} conexões a ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} de terceiros neste local.`,
    'ix-incoming-count': `- | Você tem 1 conexão ${process.env.VUE_APP_EN_PRODUCTNAME_IX} entrando. | Você tem {count} conexões ${process.env.VUE_APP_EN_PRODUCTNAME_IX} entrando.`,
    fullscreen: `Modo tela cheia`,
    big: `Visualização grande (widgets de serviço não visíveis)`,
    small: `Visualização pequena (widgets de serviço visíveis)`,
    'fullscreen-error-title': `Falha ao entrar no modo de tela cheia`,
    'fullscreen-error-message': `Erro ao tentar ativar o modo de tela cheia: {errorMessage} ({errorName})`,
    'not-ready-title': `Mapa Não Está Preparado`,
    'not-ready-message': `Alguns elementos do mapa ainda estão carregando. Por favor tente novamente em alguns segundos`,
    'initialization-error': `Não foi possível mostrar o mapa de serviços: {message}`,
    globe: `Ver como globo`,
    mercator: `Ver como projeção plana (Mercator)`,
  },
  'cancel-reason': {
    label: `Motivo do Cancelamento`,
    'moved-dc': `Saiu do Data Center`,
    'order-error': `Solicitado erroneamente ou re-solicitado`,
    'switch-provider': `Trocando para provedor alternativo`,
    'proof-of-concept': `Teste / Prova de Conceito (PoC)`,
    other: `Razão Não Listada`,
    'project-ended': `Projeto Encerrado`,
    'cloud-native-move': `Mudando para uma solução nativa na nuvem`,
    pricing: `Preço`,
    'product-performance': `Performance do Produto`,
    'feedback-prompt': `Por favor nos ajude a melhorar oferecendo mais detalhes sobre o motivo do seu cancelamento`,
  },
  entitlements: {
    description: `Esta página mostra Privilégios AWS que foram comprados pelo marketplace da AWS. Você pode filtrar a lista para mostrar/ocultar quaisquer privilégios que tenham expirado. Note que apenas privilégios que estiverem {inUse} terão um serviço associado a eles.`,
    'in-use': `Em Uso`,
    loading: `Carregando privilégios`,
    'no-match': `Nenhum Privilégio Correspondente`,
    'service-id': `ID de Serviço`,
    'agreement-id': `ID do Acordo da AWS`,
    'vendor-agreement-id': `ID do Acordo do Provedor`,
    'vendor-account-id': `ID da Conta do Provedor`,
    sku: `SKU`,
    'start-date': `Data de Início do Contrato`,
    'end-date': `Data de Término do Contrato`,
    status: `Status`,
    'product-type': `Tipo de Produto`,
    failed: `Falha ao carregar privilégios`,
    available: `Disponível`,
    expired: `Expirado`,
  },
  'service-status': {
    maintenance: `Manutenção`,
    outages: `Interrupções`,
    timezone: `Fuso horário`,
    sort: `Organizar`,
    filter: `Filtrar`,
    'search-event-placeholder': `Insira texto para filtrar eventos`,
    status: `Status`,
    'start-date': `Data de Início`,
    ascending: `Ascendente`,
    descending: `Descendente`,
    ongoing: `Em Andamento`,
    scheduled: `Agendado`,
    completed: `Completado`,
    cancelled: `Cancelado`,
    resolved: `Resolvido`,
    'in-last-7-days': `nos últimos 7 dias`,
    'no-data-text': `Nenhum resultado encontrado correspondente aos critérios selecionados. Tente ajustar os seus critérios de filtragem.`,
    'start-utc': `Início (UTC):`,
    'end-utc': `Fim (UTC):`,
    duration: `Duração:`,
    'resolution-details': `Detalhes da Resolução`,
    'root-cause': `Causa raiz:`,
    resolution: `Resolução:`,
    'mitigation-actions': `Ações de Mitigação:`,
    'update-notifications': `Notificações de Atualização`,
    'impacted-services': `Serviços Impactados`,
    'hide-updates': `Ocultar Atualizações Anteriores`,
    'show-updates': `Mostrar Atualizações Anteriores`,
    more: `Ver Mais`,
    less: `Ver Menos`,
    'incident-notification': `Notificação de Incidentes`,
    'status-update': `Seguinte - Notificação de Incidente`,
    'resolved-status': `Resolvido - Notificação de Incidente`,
    'latest-status': `Último - Notificação de Incidente`,
    'next-update-etr': `Próxima Atualização/Tempo Estimado de Resolução (ETR):`,
    'notification-description': `Por favor esteja ciente de que uma interrupção está atualmente impactando o seu serviço. Estamos trabalhando ativamente na restauração dos serviços como nossa primeira prioridade.`,
    'next-update-text': `Atualizações serão enviadas assim que se tornarem disponíveis. Agradecemos desde já pela paciência enquanto resolvemos este problema.`,
    'maintenance-outages': `Manutenção & Interrupções`,
    'refresh-error': `Não foi possível atualizar os eventos`,
    'type-label': `Tipo`,
    'name-label': `Nome`,
    'id-label': `ID`,
    'actions-label': `Ações`,
    details: `Detalhes`,
    map: `Mapa`,
  },
}
